import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  Date: string;
  /** An ISO 8601-encoded datetime */
  DateTime: string;
  Duration: string;
  /** Represents an email */
  Email: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  SignedGlobalID: string;
};

export type Abeet__CurrentMembership = {
  abeetCurrentMembership?: Maybe<Abeet__Membership>;
};

export type Abeet__CurrentMembershipAbeetCurrentMembershipArgs = {
  familyName: Scalars['String'];
};

export type Abeet__Membership = {
  __typename?: 'Abeet__Membership';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  variant?: Maybe<Abeet__Variant>;
};

export type Abeet__Variant = {
  __typename?: 'Abeet__Variant';
  id: Scalars['ID'];
  name: Scalars['String'];
  variables?: Maybe<Scalars['JSON']>;
  weight: Scalars['Int'];
};

export type Account = Abeet__CurrentMembership &
  BillingBillableInterface & {
    __typename?: 'Account';
    abeetCurrentMembership?: Maybe<Abeet__Membership>;
    address: Address;
    addresses: Array<Address>;
    balance: Scalars['Float'];
    banReason?: Maybe<Scalars['String']>;
    bannedFromBooking: Scalars['Boolean'];
    billable: Scalars['Boolean'];
    bookingCancellationDisclaimer: Scalars['String'];
    claimsSummary: ClaimsSummary;
    coupon?: Maybe<Coupon>;
    currentPaymentPlanSubscriptions: Array<PaymentPlan__Subscription>;
    currentProtectionSubscription?: Maybe<Subscription>;
    currentSelfStorageFees: Array<SelfStorage__Fee>;
    currentSelfStorageRentals: Array<SelfStorage__Rental>;
    currentSelfStorageSubscriptions: Array<SelfStorage__Subscription>;
    currentStorageSubscription?: Maybe<Subscription>;
    customer: Customer;
    customers: Array<Customer>;
    delinquent: Scalars['Boolean'];
    email?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    ingestedDocuments: Array<ActiveStorageAttachment>;
    laborSummary: Account__LaborSummary;
    latestProtectionSubscription?: Maybe<Subscription>;
    latestSelfStorageIdentification?: Maybe<SelfStorage__Identification>;
    latestSelfStoragePolicy?: Maybe<SelfStorage__Policy>;
    latestTermCommitment: Account__TermCommitment;
    name: Scalars['String'];
    number: Scalars['String'];
    onboardable: Scalars['Boolean'];
    phone?: Maybe<Scalars['String']>;
    policy: AccountPolicy;
    priceMatchKind?: Maybe<PriceMatch__Kind>;
    pricingOptions: Account__PricingOptions;
    promoCode: PromoCode;
    rateAdjustments: Array<RateAdjustment__Scheduling>;
    referable: Scalars['Boolean'];
    region: Region;
    settings: Settings;
    shortDisplay: Scalars['String'];
    signInCount: Scalars['Int'];
    sources: Array<Billing__Source>;
    state: Account__State;
    storageLadderPricings?: Maybe<Array<Pricing>>;
    storageTreasuresAuctionsHeld: Array<StorageTreasures__AuctionType>;
    subscriptions: Array<Subscription>;
    termCommitments: Array<Account__TermCommitment>;
    terminationAgreement?: Maybe<Account__TerminationAgreement>;
  };

export type AccountAbeetCurrentMembershipArgs = {
  familyName: Scalars['String'];
};

export type AccountAddressArgs = {
  id: Scalars['ID'];
};

export type AccountBookingCancellationDisclaimerArgs = {
  orderServiceType?: InputMaybe<OrderServiceTypeEnum>;
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
};

export type AccountMutationInterface = {
  accountAddressUpsert: Account__AddressUpsertPayload;
  accountBanUpdate?: Maybe<Account__BanUpdatePayload>;
  accountImpersonateCustomerLinkCreate: ImpersonateCustomerLinkCreatePayload;
  accountReferralCodeUpdate: Account__ReferralCodeUpdatePayload;
  cancelAccount?: Maybe<Account__CancelPayload>;
  suspendAccount?: Maybe<Account__SuspendPayload>;
  updateAccountPricing: Account__UpdatePricingPayload;
};

export type AccountMutationInterfaceAccountAddressUpsertArgs = {
  accountID: Scalars['ID'];
  input: AddressInput;
};

export type AccountMutationInterfaceAccountBanUpdateArgs = {
  accountID: Scalars['ID'];
  input: Account__BanUpdateInput;
};

export type AccountMutationInterfaceAccountImpersonateCustomerLinkCreateArgs = {
  customerID: Scalars['ID'];
};

export type AccountMutationInterfaceAccountReferralCodeUpdateArgs = {
  accountID: Scalars['ID'];
  code: Scalars['String'];
};

export type AccountMutationInterfaceCancelAccountArgs = {
  accountID: Scalars['ID'];
};

export type AccountMutationInterfaceSuspendAccountArgs = {
  accountID: Scalars['ID'];
};

export type AccountMutationInterfaceUpdateAccountPricingArgs = {
  accountID: Scalars['ID'];
  input: Account__UpdatePricingInput;
};

export type AccountPackage = {
  __typename?: 'AccountPackage';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  kind: PackageKindEnum;
  name: Scalars['String'];
  packageSetEntryId: Scalars['ID'];
};

export type AccountPolicy = {
  __typename?: 'AccountPolicy';
  bookMaintenancePickup: Scalars['Boolean'];
  bookOrders: Scalars['Boolean'];
  bookWarehouseReturn: Scalars['Boolean'];
  overrideBookOrders: Scalars['Boolean'];
};

export type AccountQueryInterface = {
  account: Account;
  paginatedAccounts: PaginatedAccount;
};

export type AccountQueryInterfaceAccountArgs = {
  accountID: Scalars['ID'];
};

export type AccountQueryInterfacePaginatedAccountsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  referable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Account__State>;
};

export type AccountRuleSet = {
  __typename?: 'AccountRuleSet';
  accountID: Scalars['ID'];
  id: Scalars['ID'];
  itemBarcodeFormat?: Maybe<Scalars['String']>;
};

export type AccountSettingsInput = {
  accountID: Scalars['ID'];
  dicentralAccountID?: InputMaybe<Scalars['String']>;
  emailReceipts: Scalars['Boolean'];
  internal: Scalars['Boolean'];
  isBusiness: Scalars['Boolean'];
  isThirdPartyLogistics: Scalars['Boolean'];
  skipIncomingIvr: Scalars['Boolean'];
  skuTeachInOptOut: Scalars['Boolean'];
};

/** Autogenerated return type of Account__AddressUpsert */
export type Account__AddressUpsertPayload = {
  __typename?: 'Account__AddressUpsertPayload';
  address?: Maybe<Address>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Account__BanUpdateInput = {
  banned: Scalars['Boolean'];
  reason?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Account__BanUpdate */
export type Account__BanUpdatePayload = {
  __typename?: 'Account__BanUpdatePayload';
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Account__Cancel */
export type Account__CancelPayload = {
  __typename?: 'Account__CancelPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Account__LaborSummary = {
  __typename?: 'Account__LaborSummary';
  availableSubsequentAccountLaborCreditsCount: Scalars['Int'];
  hasFinalLaborCredit: Scalars['Boolean'];
  hasOnboardingLaborCredit: Scalars['Boolean'];
  laborBundleCustomer: Scalars['Boolean'];
  laborRate: LaborRate;
  subsequentAccountLaborCreditsCount: Scalars['Int'];
};

export type Account__PricingOptions = {
  __typename?: 'Account__PricingOptions';
  id: Scalars['ID'];
  protectionPricings: Array<Pricing>;
  storagePricings: Array<Pricing>;
};

/** Autogenerated return type of Account__ReferralCodeUpdate */
export type Account__ReferralCodeUpdatePayload = {
  __typename?: 'Account__ReferralCodeUpdatePayload';
  error?: Maybe<Error>;
  promoCode?: Maybe<PromoCode>;
  status: Status;
};

export enum Account__State {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
}

/** Autogenerated return type of Account__Suspend */
export type Account__SuspendPayload = {
  __typename?: 'Account__SuspendPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Account__TermCommitment = {
  __typename?: 'Account__TermCommitment';
  contractEndDate?: Maybe<Scalars['Date']>;
  fulfilled: Scalars['Boolean'];
  term: Scalars['Int'];
  type: Account__TermCommitment__Type;
};

export enum Account__TermCommitment__Type {
  PaymentPlanMonthlyFeeTerm = 'PAYMENT_PLAN_MONTHLY_FEE_TERM',
  PaymentPlanStorageTerm = 'PAYMENT_PLAN_STORAGE_TERM',
  StorageTerm = 'STORAGE_TERM',
}

export type Account__TerminationAgreement = {
  __typename?: 'Account__TerminationAgreement';
  contractDuration: Scalars['Int'];
  contractEndDate?: Maybe<Scalars['Date']>;
  earlyTerminationFee: Scalars['Float'];
};

export type Account__UpdatePricingInput = {
  couponID?: InputMaybe<Scalars['ID']>;
  dimensions?: InputMaybe<CustomDimensionInput>;
  price?: InputMaybe<Scalars['Float']>;
  priceMatchKind?: InputMaybe<PriceMatch__Kind>;
  promoCode?: InputMaybe<Scalars['String']>;
  protectionPricingID?: InputMaybe<Scalars['ID']>;
  storagePricingID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of Account__UpdatePricing */
export type Account__UpdatePricingPayload = {
  __typename?: 'Account__UpdatePricingPayload';
  error?: Maybe<Error>;
  status: Status;
};

export type ActiveStorageAttachment = {
  __typename?: 'ActiveStorageAttachment';
  filename: Scalars['String'];
  id: Scalars['String'];
  imgixURL: Scalars['String'];
  url: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  addressableId?: Maybe<Scalars['ID']>;
  addressableType?: Maybe<Scalars['String']>;
  aptsuite?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  details?: Maybe<Detail>;
  /** The street, apartment number, city, state, and zip code of the address. */
  formatted: Scalars['String'];
  id: Scalars['ID'];
  kinds: Array<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  map: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Order>>;
  outsideServiceArea: Scalars['Boolean'];
  region?: Maybe<Region>;
  safeAndSanitary?: Maybe<Scalars['Boolean']>;
  schedulable: Scalars['Boolean'];
  serviceable: Scalars['Boolean'];
  state: Scalars['String'];
  stateAbbreviation: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export enum AddressDetailEnvironmentEnum {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
}

export type AddressDetailInput = {
  accessUnknown?: InputMaybe<Scalars['Boolean']>;
  additionalNotes?: InputMaybe<Scalars['String']>;
  buildingClosingHour?: InputMaybe<Scalars['Int']>;
  buildingOpeningHour?: InputMaybe<Scalars['Int']>;
  buildingRestrictionsAnswer?: InputMaybe<Address__BuildingRestrictionsAnswerEnum>;
  buildingSubtype?: InputMaybe<Scalars['String']>;
  buildingType?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Boolean']>;
  codeValue?: InputMaybe<Scalars['String']>;
  elevator?: InputMaybe<Scalars['Boolean']>;
  environment?: InputMaybe<AddressDetailEnvironmentEnum>;
  floor?: InputMaybe<Scalars['Int']>;
  frontDoorSteps?: InputMaybe<Scalars['Int']>;
  gated?: InputMaybe<Scalars['Boolean']>;
  loadingDock?: InputMaybe<Scalars['Boolean']>;
  longCarry?: InputMaybe<Scalars['Boolean']>;
  maxTruckHeight?: InputMaybe<Scalars['Int']>;
  maxTruckHeightUnknown?: InputMaybe<Scalars['Boolean']>;
  paperwork?: InputMaybe<Scalars['Boolean']>;
  paperworkUnknown?: InputMaybe<Scalars['Boolean']>;
  parkingAlley?: InputMaybe<Scalars['Boolean']>;
  parkingDriveway?: InputMaybe<Scalars['Boolean']>;
  parkingInFront?: InputMaybe<Scalars['Boolean']>;
  parkingInstructions?: InputMaybe<Scalars['String']>;
  parkingLatitude?: InputMaybe<Scalars['Float']>;
  parkingLongitude?: InputMaybe<Scalars['Float']>;
  parkingLot?: InputMaybe<Scalars['Boolean']>;
  parkingOnSpecificStreet?: InputMaybe<Scalars['String']>;
  parkingPermit?: InputMaybe<Scalars['Boolean']>;
  parkingStreet?: InputMaybe<Scalars['Boolean']>;
  parkingUnknown?: InputMaybe<Scalars['Boolean']>;
  secureLocationDetails?: InputMaybe<Scalars['String']>;
  serviceElevator?: InputMaybe<Scalars['Boolean']>;
  serviceEntrance?: InputMaybe<Scalars['Boolean']>;
  squareFeet?: InputMaybe<Scalars['Int']>;
  stairs?: InputMaybe<Scalars['Boolean']>;
  steep?: InputMaybe<Scalars['Boolean']>;
  stories?: InputMaybe<Scalars['Int']>;
  unitSize?: InputMaybe<AddressDetailUnitSizeEnum>;
};

export enum AddressDetailUnitSizeEnum {
  Size_5X5 = 'SIZE_5X5',
  Size_5X10 = 'SIZE_5X10',
  Size_10X10 = 'SIZE_10X10',
  Size_10X15 = 'SIZE_10X15',
  Size_10X20 = 'SIZE_10X20',
  Size_10X25 = 'SIZE_10X25',
  Size_10X30 = 'SIZE_10X30',
  SizeLocker = 'SIZE_LOCKER',
}

export type AddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  details: AddressDetailInput;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressPolicy = {
  __typename?: 'AddressPolicy';
  create: Scalars['Boolean'];
};

export enum Address__BuildingRestrictionsAnswerEnum {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES',
}

export enum Address__UnitSizeEnum {
  Size_5X5 = 'SIZE_5X5',
  Size_5X10 = 'SIZE_5X10',
  Size_10X10 = 'SIZE_10X10',
  Size_10X15 = 'SIZE_10X15',
  Size_10X20 = 'SIZE_10X20',
  Size_10X25 = 'SIZE_10X25',
  Size_10X30 = 'SIZE_10X30',
  SizeLocker = 'SIZE_LOCKER',
}

export type Adjustment = {
  __typename?: 'Adjustment';
  id: Scalars['ID'];
  kind: Scalars['String'];
  subscription: Subscription;
};

export type Admin__Document = {
  __typename?: 'Admin__Document';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Admin__ItemCategory = {
  __typename?: 'Admin__ItemCategory';
  categoryDefaults?: Maybe<ItemCategoryDefault>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  disassemblyRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  isBox: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  moveAndPackVisible: Scalars['Boolean'];
  name: Scalars['String'];
  packingStandard?: Maybe<PackingStandard>;
  parentID?: Maybe<Scalars['ID']>;
  prepopulatedDisassemblyParts: Array<PrepopulatedDisassemblyPart>;
  requiredPlanID?: Maybe<Scalars['ID']>;
  sizingMetric?: Maybe<SizingMetric>;
  synonyms?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Admin__RoomCategory = {
  __typename?: 'Admin__RoomCategory';
  commonItemCategories: Array<Admin__ItemCategory>;
  countable: Scalars['Boolean'];
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type AppVersionRequirement = {
  __typename?: 'AppVersionRequirement';
  appName: AppVersionRequirementAppNameEnum;
  currentVersion: Scalars['String'];
  id: Scalars['ID'];
  manifestURL: Scalars['String'];
  minimumSupportedVersion: Scalars['String'];
};

export enum AppVersionRequirementAppNameEnum {
  Dock = 'DOCK',
  Field = 'FIELD',
  Forklift = 'FORKLIFT',
  Punch = 'PUNCH',
}

export type AppointmentQuote = {
  __typename?: 'AppointmentQuote';
  id: Scalars['ID'];
};

export enum AppointmentStateEnum {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type Assignment = {
  __typename?: 'Assignment';
  id: Scalars['ID'];
  role: Assignment__Role;
  user: User;
};

export enum Assignment__Role {
  Driver = 'DRIVER',
  Lead = 'LEAD',
  Member = 'MEMBER',
  Trainee = 'TRAINEE',
}

/** Autogenerated return type of AttendancePolicyViolation__Create */
export type AttendancePolicyViolation__CreatePayload = {
  __typename?: 'AttendancePolicyViolation__CreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type AttendancePolicyViolation__Create__Input = {
  actualTime: Scalars['DateTime'];
  confirmedNoCall: Scalars['Boolean'];
  expectedTime: Scalars['DateTime'];
  kind: AttendancePolicyViolation__Kind;
  note?: InputMaybe<Scalars['String']>;
  shiftDate: Scalars['Date'];
  userID: Scalars['ID'];
};

export enum AttendancePolicyViolation__Kind {
  EndedEarly = 'ENDED_EARLY',
  NoShow = 'NO_SHOW',
  StartedLate = 'STARTED_LATE',
}

/** Autogenerated return type of AttendancePolicyViolation__Review */
export type AttendancePolicyViolation__ReviewPayload = {
  __typename?: 'AttendancePolicyViolation__ReviewPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type AttendancePolicyViolation__Review__Input = {
  confirmedNoCall: Scalars['Boolean'];
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  waived: Scalars['Boolean'];
};

export type AttendancePolicyViolation__Type = {
  __typename?: 'AttendancePolicyViolation__Type';
  actualTime?: Maybe<Scalars['DateTime']>;
  confirmedNoCall: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expectedTime: Scalars['DateTime'];
  id: Scalars['ID'];
  kind: AttendancePolicyViolation__Kind;
  note?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  reviewer?: Maybe<User>;
  shiftDate: Scalars['Date'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  waived: Scalars['Boolean'];
};

export type AttestationEvent = {
  __typename?: 'AttestationEvent';
  comment?: Maybe<Scalars['String']>;
  correctedShiftStart?: Maybe<Scalars['DateTime']>;
  correctedWorkBreaks?: Maybe<Scalars['JSON']>;
  flagged: Scalars['Boolean'];
  id: Scalars['ID'];
  kind: Attestation__EventKind;
  reviewDueDate: Scalars['Date'];
  reviewedAt?: Maybe<Scalars['DateTime']>;
  shift: Workforce__Shift;
};

export type AttestationEventFiltersInput = {
  from?: InputMaybe<Scalars['Date']>;
  query?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  till?: InputMaybe<Scalars['Date']>;
  timeClockPlus?: InputMaybe<TimeClockPlusEnum>;
};

export type AttestationEventPaginated = {
  __typename?: 'AttestationEventPaginated';
  pagination: Pagination;
  results: Array<AttestationEvent>;
};

/** Autogenerated return type of AttestationEventResolve */
export type AttestationEventResolvePayload = {
  __typename?: 'AttestationEventResolvePayload';
  attestationEvent?: Maybe<AttestationEvent>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum Attestation__EventKind {
  EndOfShiftMealBreakCheck = 'END_OF_SHIFT_MEAL_BREAK_CHECK',
  EndOfShiftTimeCheck = 'END_OF_SHIFT_TIME_CHECK',
  MealBreakEndedEarly = 'MEAL_BREAK_ENDED_EARLY',
  MealBreakStartedLate = 'MEAL_BREAK_STARTED_LATE',
  RestBreakCheck = 'REST_BREAK_CHECK',
}

export type AuctionMutationInterface = {
  auctionUploadCreate: Auction__Upload__CreatePayload;
};

export type AuctionMutationInterfaceAuctionUploadCreateArgs = {
  input: Auction__UploadInput;
};

export type AuctionQueryInterface = {
  auctionPaginatedUploads: Auction__PaginatedUpload;
  auctionUpload: Auction__Upload;
  auctionUploadPolicy: Auction__Upload__Policy;
};

export type AuctionQueryInterfaceAuctionPaginatedUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type AuctionQueryInterfaceAuctionUploadArgs = {
  id: Scalars['ID'];
};

export type Auction__PaginatedUpload = {
  __typename?: 'Auction__PaginatedUpload';
  pagination: Pagination;
  results: Array<Auction__Upload>;
};

export type Auction__Upload = {
  __typename?: 'Auction__Upload';
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  failureCSV?: Maybe<ActiveStorageAttachment>;
  id: Scalars['ID'];
  originalCSV: ActiveStorageAttachment;
  processedAt?: Maybe<Scalars['DateTime']>;
  processingAt?: Maybe<Scalars['DateTime']>;
  state: Auction__Upload__State;
  user: User;
};

export type Auction__UploadInput = {
  csvID: Scalars['ID'];
  description: Scalars['String'];
};

/** Autogenerated return type of Auction__Upload__Create */
export type Auction__Upload__CreatePayload = {
  __typename?: 'Auction__Upload__CreatePayload';
  status: Status;
  upload?: Maybe<Auction__Upload>;
};

export type Auction__Upload__Policy = {
  __typename?: 'Auction__Upload__Policy';
  create: Scalars['Boolean'];
  view: Scalars['Boolean'];
};

export enum Auction__Upload__State {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export type AuditOrderEvent = {
  __typename?: 'AuditOrderEvent';
  eventName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  orderId: Scalars['ID'];
  timestamp?: Maybe<Scalars['DateTime']>;
  titleizedEventName: Scalars['String'];
};

export type AutomationMutationInterface = {
  automationPickupComplete: PickupCompletePayload;
  automationReturnRoute: ReturnRoutePayload;
  buildTestOrderRequest: BuildTestOrderRequestPayload;
  generateTestPassword: GenerateTestPasswordPayload;
  signatureApprovalCreate: SignatureApprovalCreatePayload;
};

export type AutomationMutationInterfaceAutomationPickupCompleteArgs = {
  orderID: Scalars['ID'];
};

export type AutomationMutationInterfaceAutomationReturnRouteArgs = {
  orderID: Scalars['ID'];
};

export type AutomationMutationInterfaceBuildTestOrderRequestArgs = {
  input: Automation__TestOrderRequestInput;
};

export type AutomationMutationInterfaceGenerateTestPasswordArgs = {
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
};

export type AutomationMutationInterfaceSignatureApprovalCreateArgs = {
  orderId: Scalars['ID'];
};

export type AutomationQueryInterface = {
  paginatedTestOrderRequests: Automation__PaginatedTestOrderRequest;
};

export type AutomationQueryInterfacePaginatedTestOrderRequestsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type Automation__PaginatedTestOrderRequest = {
  __typename?: 'Automation__PaginatedTestOrderRequest';
  pagination: Pagination;
  results: Array<Automation__TestOrderRequest>;
};

export type Automation__TestOrderRequest = {
  __typename?: 'Automation__TestOrderRequest';
  cardToken?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processingAt?: Maybe<Scalars['DateTime']>;
  simultaneousPickup: Scalars['Boolean'];
  state: Automation__TestOrderRequest__State;
  subtype: Scalars['String'];
  type: Scalars['String'];
  user: User;
};

export type Automation__TestOrderRequestInput = {
  cardToken?: InputMaybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  simultaneousPickup: Scalars['Boolean'];
  subtype: Scalars['String'];
  type: Scalars['String'];
};

export enum Automation__TestOrderRequest__State {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export type AvailabilitiesInput = {
  from: Scalars['DateTime'];
  kind: AvailabilitiesInputKind;
  moverOverride?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderInput>;
  orderID?: InputMaybe<Scalars['ID']>;
  subscriptions?: InputMaybe<Array<SubscriptionInput>>;
  till: Scalars['DateTime'];
};

export enum AvailabilitiesInputKind {
  Booking = 'BOOKING',
  Editing = 'EDITING',
}

export type Availability = {
  __typename?: 'Availability';
  appointmentFee?: Maybe<Scalars['String']>;
  availabilityTierType?: Maybe<AvailabilityTierTypeEnum>;
  available: Scalars['Boolean'];
  datetime: Scalars['DateTime'];
  duration?: Maybe<Scalars['Duration']>;
  feeAmount?: Maybe<MonetaryAmount>;
  laborBillingFormat?: Maybe<LaborBillingFormatEnum>;
  laborRate?: Maybe<Pricing__LaborRate>;
  perMoverHourAdjustmentAmount?: Maybe<Scalars['Float']>;
};

export type AvailabilityMutationInterface = {
  availabilityFacilityCuftLimitsCreate: Availability__FacilityCuftLimitsCreatePayload;
  availabilityFacilityCuftLimitsDestroy: Availability__FacilityCuftLimitsDestroyPayload;
  availabilityGlobalUnavailabilitiesCreate: Availability__GlobalUnavailabilitiesCreatePayload;
  availabilityGlobalUnavailabilitiesDestroy: Availability__GlobalUnavailabilitiesDestroyPayload;
  availabilityJobAvailabilitiesCreate: Availability__JobAvailabilitiesCreatePayload;
  availabilityJobAvailabilitiesDestroy: Availability__JobAvailabilitiesDestroyPayload;
  availabilityLaborAdjustmentsCreate: Availability__LaborAdjustmentsCreatePayload;
  availabilityLaborAdjustmentsDelete: Availability__LaborAdjustmentsDeletePayload;
  availabilityOperatingScheduleTemplateCreate: Availability__OperatingScheduleTemplateCreatePayload;
  availabilityRescheduleOffersCreate: Availability__RescheduleOffersCreatePayload;
  availabilityRescheduleOffersDelete: Availability__RescheduleOffersDeletePayload;
};

export type AvailabilityMutationInterfaceAvailabilityFacilityCuftLimitsCreateArgs = {
  input: Availability__FacilityCuftLimitsCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityFacilityCuftLimitsDestroyArgs = {
  IDs: Array<Scalars['ID']>;
};

export type AvailabilityMutationInterfaceAvailabilityGlobalUnavailabilitiesCreateArgs = {
  input: Availability__GlobalUnavailabilitiesCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityGlobalUnavailabilitiesDestroyArgs = {
  IDs: Array<Scalars['ID']>;
};

export type AvailabilityMutationInterfaceAvailabilityJobAvailabilitiesCreateArgs = {
  input: Availability__JobAvailabilitiesCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityJobAvailabilitiesDestroyArgs = {
  input: Availability__JobAvailabilitiesDestroyInput;
};

export type AvailabilityMutationInterfaceAvailabilityLaborAdjustmentsCreateArgs = {
  input: Availability__LaborAdjustmentsCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityLaborAdjustmentsDeleteArgs = {
  IDs: Array<Scalars['ID']>;
};

export type AvailabilityMutationInterfaceAvailabilityOperatingScheduleTemplateCreateArgs = {
  input: Availability__OperatingScheduleTemplateCreate__Input;
};

export type AvailabilityMutationInterfaceAvailabilityRescheduleOffersCreateArgs = {
  input: Availability__RescheduleOffersCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityRescheduleOffersDeleteArgs = {
  IDs: Array<Scalars['ID']>;
};

export type AvailabilityQueryInterface = {
  availabilityFacilityCuftLimitPolicy: Availability__ManagementPolicy;
  availabilityFacilityCuftLimits: Availability__PaginatedFacilityCuftLimit;
  availabilityGlobalUnavailability: Availability__PaginatedGlobalUnavailability;
  availabilityGlobalUnavailabilityPolicy: Availability__ManagementPolicy;
  availabilityJobAvailability: Availability__PaginatedJobAvailability;
  availabilityJobAvailabilityPolicy: Availability__ManagementPolicy;
  availabilityLaborAdjustments: Availability__PaginatedLaborAdjustment;
  availabilityLaborAdjustmentsPolicy: Availability__ManagementPolicy;
  availabilityRescheduleOffers: Availability__PaginatedRescheduleOffer;
  availabilityRescheduleOffersPolicy: Availability__ManagementPolicy;
};

export type AvailabilityQueryInterfaceAvailabilityFacilityCuftLimitsArgs = {
  filters: Availability__FacilityCuftLimitFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type AvailabilityQueryInterfaceAvailabilityGlobalUnavailabilityArgs = {
  filters: Availability__GlobalUnavailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type AvailabilityQueryInterfaceAvailabilityJobAvailabilityArgs = {
  filters: Availability__JobAvailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type AvailabilityQueryInterfaceAvailabilityLaborAdjustmentsArgs = {
  filters: Availability__LaborAdjustmentFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type AvailabilityQueryInterfaceAvailabilityRescheduleOffersArgs = {
  filters: Availability__RescheduleOfferFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export enum AvailabilityTierTypeEnum {
  Base = 'BASE',
  Priority = 'PRIORITY',
  Standard = 'STANDARD',
}

export enum Availability__BlockReasonEnum {
  DemandPlanning = 'DEMAND_PLANNING',
  Holiday = 'HOLIDAY',
  Other = 'OTHER',
  WeeklySchedule = 'WEEKLY_SCHEDULE',
}

export type Availability__FacilityCuftLimit = {
  __typename?: 'Availability__FacilityCuftLimit';
  creator: User;
  date: Scalars['Date'];
  facility: Warehouse;
  id: Scalars['ID'];
  maximumInboundCuft?: Maybe<Scalars['Int']>;
  maximumInboundOutboundCuft?: Maybe<Scalars['Int']>;
  maximumInboundTransferCuft?: Maybe<Scalars['Int']>;
  maximumOutboundCuft?: Maybe<Scalars['Int']>;
  maximumOutboundDisposalCuft?: Maybe<Scalars['Int']>;
  maximumOutboundFacilityCuft?: Maybe<Scalars['Int']>;
  reason?: Maybe<Availability__BlockReasonEnum>;
};

export type Availability__FacilityCuftLimitFiltersInput = {
  fromDate?: InputMaybe<Scalars['Date']>;
  tillDate?: InputMaybe<Scalars['Date']>;
  warehouseIDs: Array<Scalars['ID']>;
};

export type Availability__FacilityCuftLimitsCreateInput = {
  dates: Array<Scalars['Date']>;
  maximumInboundCuft?: InputMaybe<Scalars['Int']>;
  maximumInboundOutboundCuft?: InputMaybe<Scalars['Int']>;
  maximumInboundTransferCuft?: InputMaybe<Scalars['Int']>;
  maximumOutboundCuft?: InputMaybe<Scalars['Int']>;
  maximumOutboundDisposalCuft?: InputMaybe<Scalars['Int']>;
  maximumOutboundFacilityCuft?: InputMaybe<Scalars['Int']>;
  reason: Scalars['String'];
  warehouseIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Availability__FacilityCuftLimitsCreate */
export type Availability__FacilityCuftLimitsCreatePayload = {
  __typename?: 'Availability__FacilityCuftLimitsCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Availability__FacilityCuftLimitsDestroy */
export type Availability__FacilityCuftLimitsDestroyPayload = {
  __typename?: 'Availability__FacilityCuftLimitsDestroyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__GlobalUnavailabilitiesCreateInput = {
  capacityReductionPercentage: Scalars['Float'];
  dates: Array<Scalars['Date']>;
  name: Scalars['String'];
  reason: Availability__GlobalUnavailabilityReasonEnum;
  regionIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Availability__GlobalUnavailabilitiesCreate */
export type Availability__GlobalUnavailabilitiesCreatePayload = {
  __typename?: 'Availability__GlobalUnavailabilitiesCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Availability__GlobalUnavailabilitiesDestroy */
export type Availability__GlobalUnavailabilitiesDestroyPayload = {
  __typename?: 'Availability__GlobalUnavailabilitiesDestroyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__GlobalUnavailability = {
  __typename?: 'Availability__GlobalUnavailability';
  capacityReductionPercentage: Scalars['Float'];
  creator: User;
  date: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  reason: Availability__GlobalUnavailabilityReasonEnum;
  region: Region;
};

export type Availability__GlobalUnavailabilityFiltersInput = {
  fromDate: Scalars['Date'];
  name?: InputMaybe<Scalars['String']>;
  reasons: Array<Availability__GlobalUnavailabilityReasonEnum>;
  regionIDs: Array<Scalars['ID']>;
  tillDate: Scalars['Date'];
};

export enum Availability__GlobalUnavailabilityReasonEnum {
  Admin = 'ADMIN',
  CompanyMeeting = 'COMPANY_MEETING',
  Holiday = 'HOLIDAY',
}

export type Availability__JobAvailabilitiesCreateInput = {
  dates: Array<Scalars['Date']>;
  kinds: Array<Availability__JobAvailabilityKindEnum>;
  maximumMoverMinutes: Scalars['Int'];
  reason: Scalars['String'];
  regionIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Availability__JobAvailabilitiesCreate */
export type Availability__JobAvailabilitiesCreatePayload = {
  __typename?: 'Availability__JobAvailabilitiesCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__JobAvailabilitiesDestroyInput = {
  jobTypeAvailabilityIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Availability__JobAvailabilitiesDestroy */
export type Availability__JobAvailabilitiesDestroyPayload = {
  __typename?: 'Availability__JobAvailabilitiesDestroyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__JobAvailabilityFiltersInput = {
  fromDate: Scalars['Date'];
  kinds: Array<Availability__JobAvailabilityKindEnum>;
  regionIDs: Array<Scalars['ID']>;
  tillDate: Scalars['Date'];
};

export enum Availability__JobAvailabilityKindEnum {
  Moving = 'MOVING',
  PickupOnboarding = 'PICKUP_ONBOARDING',
  PickupSubsequent = 'PICKUP_SUBSEQUENT',
  ReturnFinal = 'RETURN_FINAL',
  ReturnSubsequent = 'RETURN_SUBSEQUENT',
}

export type Availability__JobTypeAvailability = {
  __typename?: 'Availability__JobTypeAvailability';
  bookedMoverMinutes: Scalars['Int'];
  creator: User;
  date: Scalars['Date'];
  displayKind: Availability__JobAvailabilityKindEnum;
  id: Scalars['ID'];
  jobSubtype: Availability__JobTypeAvailabilityJobSubtypeEnum;
  jobType: Availability__JobTypeAvailabilityJobTypeEnum;
  maximumMoverMinutes: Scalars['Int'];
  reason?: Maybe<Availability__BlockReasonEnum>;
  region: Region;
};

export enum Availability__JobTypeAvailabilityJobSubtypeEnum {
  Final = 'FINAL',
  None = 'NONE',
  Onboarding = 'ONBOARDING',
  Subsequent = 'SUBSEQUENT',
}

export enum Availability__JobTypeAvailabilityJobTypeEnum {
  MovingMove = 'MOVING_MOVE',
  Pickup = 'PICKUP',
  Return = 'RETURN',
}

export type Availability__LaborAdjustment = {
  __typename?: 'Availability__LaborAdjustment';
  creator: User;
  date: Scalars['Date'];
  highThresholdPercentage: Scalars['Float'];
  id: Scalars['ID'];
  lowThresholdPercentage: Scalars['Float'];
  orderClassification: Availability__OrderClassificationEnum;
  perMoverHourAdjustmentAmount: Scalars['Float'];
  region: Region;
};

export type Availability__LaborAdjustmentFiltersInput = {
  fromDate: Scalars['Date'];
  orderClassifications: Array<Availability__OrderClassificationEnum>;
  regionIDs: Array<Scalars['ID']>;
  tillDate: Scalars['Date'];
};

export type Availability__LaborAdjustmentsCreateInput = {
  adjustmentRanges: Array<Availability__LaborAdjustmentsRangeInput>;
  dates: Array<Scalars['Date']>;
  orderClassifications: Array<Availability__OrderClassificationEnum>;
  regionIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Availability__LaborAdjustmentsCreate */
export type Availability__LaborAdjustmentsCreatePayload = {
  __typename?: 'Availability__LaborAdjustmentsCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Availability__LaborAdjustmentsDelete */
export type Availability__LaborAdjustmentsDeletePayload = {
  __typename?: 'Availability__LaborAdjustmentsDeletePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__LaborAdjustmentsRangeInput = {
  highThresholdPercentage: Scalars['Float'];
  lowThresholdPercentage: Scalars['Float'];
  perMoverHourAdjustmentAmount: Scalars['Float'];
};

export type Availability__ManagementPolicy = {
  __typename?: 'Availability__ManagementPolicy';
  create: Scalars['Boolean'];
  destroy: Scalars['Boolean'];
};

/** Autogenerated return type of Availability__OperatingScheduleTemplateCreate */
export type Availability__OperatingScheduleTemplateCreatePayload = {
  __typename?: 'Availability__OperatingScheduleTemplateCreatePayload';
  status: Status;
};

export type Availability__OperatingScheduleTemplateCreate__Input = {
  effectiveDate: Scalars['Date'];
  jobKinds?: InputMaybe<Array<Availability__JobAvailabilityKindEnum>>;
  operatingDays: Array<Day>;
  schedulableIDs: Array<Scalars['ID']>;
  schedulableType: Availability__OperatingScheduleTemplateCreate__SchedulableType;
};

export enum Availability__OperatingScheduleTemplateCreate__SchedulableType {
  Region = 'REGION',
  Warehouse = 'WAREHOUSE',
}

export enum Availability__OrderClassificationEnum {
  PickupOnboarding = 'PICKUP_ONBOARDING',
  PickupSubsequent = 'PICKUP_SUBSEQUENT',
  ReturnFinal = 'RETURN_FINAL',
  ReturnSubsequent = 'RETURN_SUBSEQUENT',
}

export type Availability__PaginatedFacilityCuftLimit = {
  __typename?: 'Availability__PaginatedFacilityCuftLimit';
  pagination: Pagination;
  results: Array<Availability__FacilityCuftLimit>;
};

export type Availability__PaginatedGlobalUnavailability = {
  __typename?: 'Availability__PaginatedGlobalUnavailability';
  pagination: Pagination;
  results: Array<Availability__GlobalUnavailability>;
};

export type Availability__PaginatedJobAvailability = {
  __typename?: 'Availability__PaginatedJobAvailability';
  pagination: Pagination;
  results: Array<Availability__JobTypeAvailability>;
};

export type Availability__PaginatedLaborAdjustment = {
  __typename?: 'Availability__PaginatedLaborAdjustment';
  pagination: Pagination;
  results: Array<Availability__LaborAdjustment>;
};

export type Availability__PaginatedRescheduleOffer = {
  __typename?: 'Availability__PaginatedRescheduleOffer';
  pagination: Pagination;
  results: Array<Availability__RescheduleOffer>;
};

export type Availability__RescheduleOffer = {
  __typename?: 'Availability__RescheduleOffer';
  creator: User;
  expireAt: Scalars['DateTime'];
  expired: Scalars['Boolean'];
  id: Scalars['ID'];
  orderClassification: Availability__OrderClassificationEnum;
  orderMinimumMoverMinutes: Scalars['Float'];
  originDate: Scalars['Date'];
  originTargetAvailabilityThreshold: Scalars['Float'];
  region: Region;
  rescheduleOfferItems: Array<Availability__RescheduleOfferItem>;
  startAt: Scalars['DateTime'];
  targetDates: Array<Scalars['Date']>;
};

export type Availability__RescheduleOfferFiltersInput = {
  fromDate: Scalars['Date'];
  orderClassifications: Array<Availability__OrderClassificationEnum>;
  regionIDs: Array<Scalars['ID']>;
  tillDate: Scalars['Date'];
};

export type Availability__RescheduleOfferItem = {
  __typename?: 'Availability__RescheduleOfferItem';
  coupon: Coupon;
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
};

export type Availability__RescheduleOffersCreateInput = {
  couponID: Scalars['ID'];
  expireAt: Scalars['DateTime'];
  orderClassifications: Array<Availability__OrderClassificationEnum>;
  orderMinimumMoverMinutes: Scalars['Float'];
  originDate: Scalars['Date'];
  originTargetAvailabilityThreshold: Scalars['Float'];
  regionIDs: Array<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  targetDates: Array<Scalars['Date']>;
};

/** Autogenerated return type of Availability__RescheduleOffersCreate */
export type Availability__RescheduleOffersCreatePayload = {
  __typename?: 'Availability__RescheduleOffersCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Availability__RescheduleOffersDelete */
export type Availability__RescheduleOffersDeletePayload = {
  __typename?: 'Availability__RescheduleOffersDeletePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Availability__WaitlistRequest = {
  __typename?: 'Availability__WaitlistRequest';
  date: Scalars['Date'];
  id: Scalars['ID'];
};

/** Autogenerated return type of AvatarDelete */
export type AvatarDeletePayload = {
  __typename?: 'AvatarDeletePayload';
  user?: Maybe<User>;
};

/** Autogenerated return type of AvatarUpload */
export type AvatarUploadPayload = {
  __typename?: 'AvatarUploadPayload';
  user?: Maybe<User>;
};

export type Barcode = {
  __typename?: 'Barcode';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type BarcodeInput = {
  value: Scalars['String'];
};

export type BillingBillableInterface = {
  sources: Array<Billing__Source>;
};

export type BillingMutationInterface = {
  billingAuthorizationHoldCancel: Billing__AuthorizationHold__CancelPayload;
  billingAuthorizationHoldUncancel: Billing__AuthorizationHold__UncancelPayload;
  billingBuildCharge: Billing__BuildChargePayload;
  billingChargeRefund: RefundPayload;
  billingInvoiceForgive: Billing__Invoice__ForgivePayload;
  billingInvoiceMarkAsPaid: Billing__Invoice__MarkAsPaidPayload;
  billingInvoiceReBill: Billing__Invoice__ReBillPayload;
  billingReceiptSend: SendPayload;
  billingSourceBuild: Billing__Source__BuildPayload;
  billingSourceDefault: Billing__Source__DefaultPayload;
  billingSourceDelete: Billing__Source__DeletePayload;
};

export type BillingMutationInterfaceBillingAuthorizationHoldCancelArgs = {
  orderID: Scalars['ID'];
};

export type BillingMutationInterfaceBillingAuthorizationHoldUncancelArgs = {
  orderID: Scalars['ID'];
};

export type BillingMutationInterfaceBillingBuildChargeArgs = {
  input: Billing__ChargeInput;
};

export type BillingMutationInterfaceBillingChargeRefundArgs = {
  id: Scalars['ID'];
  input: Billing__Charge__RefundInput;
};

export type BillingMutationInterfaceBillingInvoiceForgiveArgs = {
  input: Billing__Invoice__ForgiveInput;
  invoiceID: Scalars['ID'];
};

export type BillingMutationInterfaceBillingInvoiceMarkAsPaidArgs = {
  input: Billing__Invoice__MarkAsPaidInput;
  invoiceID: Scalars['ID'];
};

export type BillingMutationInterfaceBillingInvoiceReBillArgs = {
  input: Billing__Invoice__ReBillInput;
  invoiceID: Scalars['ID'];
};

export type BillingMutationInterfaceBillingReceiptSendArgs = {
  input: Billing__Receipt__SendInput;
};

export type BillingMutationInterfaceBillingSourceBuildArgs = {
  accountID: Scalars['ID'];
  token: Scalars['ID'];
};

export type BillingMutationInterfaceBillingSourceDefaultArgs = {
  id: Scalars['ID'];
};

export type BillingMutationInterfaceBillingSourceDeleteArgs = {
  id: Scalars['ID'];
};

export type BillingQueryInterface = {
  billing: Billing__Context;
  billingAuthorizationHolds: Array<Billing__AuthorizationHold>;
  billingForgivenessReasons: Array<Billing__ForgivenessReason>;
  billingReBillReasons: Array<Billing__ReBillReason>;
  billingUnattemptedAuthorizationHold?: Maybe<Billing__UnattemptedAuthorizationHold>;
};

export type BillingQueryInterfaceBillingArgs = {
  accountID: Scalars['ID'];
};

export type BillingQueryInterfaceBillingAuthorizationHoldsArgs = {
  orderID: Scalars['ID'];
};

export type BillingQueryInterfaceBillingReBillReasonsArgs = {
  invoiceID: Scalars['ID'];
};

export type BillingQueryInterfaceBillingUnattemptedAuthorizationHoldArgs = {
  orderID: Scalars['ID'];
};

export type Billing__AuthorizationHold = {
  __typename?: 'Billing__AuthorizationHold';
  charge: Billing__Charge;
  id: Scalars['ID'];
};

/** Autogenerated return type of Billing__AuthorizationHold__Cancel */
export type Billing__AuthorizationHold__CancelPayload = {
  __typename?: 'Billing__AuthorizationHold__CancelPayload';
  status: Status;
  unattemptedAuthorizationHold?: Maybe<Billing__UnattemptedAuthorizationHold>;
};

/** Autogenerated return type of Billing__AuthorizationHold__Uncancel */
export type Billing__AuthorizationHold__UncancelPayload = {
  __typename?: 'Billing__AuthorizationHold__UncancelPayload';
  status: Status;
  unattemptedAuthorizationHold?: Maybe<Billing__UnattemptedAuthorizationHold>;
};

/** Autogenerated return type of Billing__BuildCharge */
export type Billing__BuildChargePayload = {
  __typename?: 'Billing__BuildChargePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Billing__Charge = {
  __typename?: 'Billing__Charge';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  outcomeReason?: Maybe<Scalars['String']>;
  source: Billing__Source;
  status: Billing__Charge__Status;
};

export type Billing__ChargeInput = {
  accountID: Scalars['ID'];
  amount: Scalars['Float'];
  autoAdvance: Scalars['Boolean'];
  kind: Scalars['String'];
  note: Scalars['String'];
  orderID?: InputMaybe<Scalars['ID']>;
  product: Scalars['String'];
  rentalID?: InputMaybe<Scalars['ID']>;
  selfStorageFacilityID?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Billing__Invoice__OfflinePaymentChargeInput>;
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type Billing__Charge__RefundInput = {
  amount: Scalars['Float'];
  notes: Scalars['String'];
};

export enum Billing__Charge__Status {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

export type Billing__Context = {
  __typename?: 'Billing__Context';
  invoiceEvent: Billing__InvoiceEvent;
  invoiceItemKinds: Array<Scalars['String']>;
};

export type Billing__ContextInvoiceEventArgs = {
  attemptID: Scalars['ID'];
};

export type Billing__ForgivenessReason = {
  __typename?: 'Billing__ForgivenessReason';
  id: Scalars['ID'];
  kind: Scalars['String'];
  parentID?: Maybe<Scalars['String']>;
};

export type Billing__Invoice = {
  __typename?: 'Billing__Invoice';
  amountDue: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Billing__Invoice__Status;
  summary?: Maybe<Scalars['String']>;
};

export type Billing__InvoiceEvent = {
  __typename?: 'Billing__InvoiceEvent';
  completed: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type Billing__Invoice__ForgiveInput = {
  notes: Scalars['String'];
  reasonID: Scalars['String'];
};

/** Autogenerated return type of Billing__Invoice__Forgive */
export type Billing__Invoice__ForgivePayload = {
  __typename?: 'Billing__Invoice__ForgivePayload';
  attemptID: Scalars['ID'];
};

export type Billing__Invoice__MarkAsPaidInput = {
  amount: Scalars['Float'];
  kind: Billing__OfflinePayment__Kind;
  newDueDate?: InputMaybe<Scalars['Date']>;
  notes: Scalars['String'];
  transactionNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Billing__Invoice__MarkAsPaid */
export type Billing__Invoice__MarkAsPaidPayload = {
  __typename?: 'Billing__Invoice__MarkAsPaidPayload';
  attemptID: Scalars['ID'];
};

export type Billing__Invoice__OfflinePaymentChargeInput = {
  kind: Billing__OfflinePayment__Kind;
  transactionNumber?: InputMaybe<Scalars['String']>;
};

export type Billing__Invoice__ReBillInput = {
  notes: Scalars['String'];
  reasonID: Scalars['ID'];
};

/** Autogenerated return type of Billing__Invoice__ReBill */
export type Billing__Invoice__ReBillPayload = {
  __typename?: 'Billing__Invoice__ReBillPayload';
  attemptID: Scalars['ID'];
};

export enum Billing__Invoice__Status {
  Failed = 'FAILED',
  FinalAttemptFailed = 'FINAL_ATTEMPT_FAILED',
  NotAttempted = 'NOT_ATTEMPTED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
}

export enum Billing__OfflinePayment__Kind {
  Ach = 'ACH',
  Cash = 'CASH',
  Check = 'CHECK',
  MoneyOrder = 'MONEY_ORDER',
  Other = 'OTHER',
}

export type Billing__ReBillReason = {
  __typename?: 'Billing__ReBillReason';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Billing__Receipt__SendInput = {
  id: Scalars['ID'];
  type: Billing__Receipt__Transaction;
};

export enum Billing__Receipt__Transaction {
  Charge = 'CHARGE',
  OfflinePayment = 'OFFLINE_PAYMENT',
  Refund = 'REFUND',
}

export type Billing__Source = {
  __typename?: 'Billing__Source';
  active: Scalars['Boolean'];
  billable: Scalars['Boolean'];
  brand?: Maybe<Scalars['String']>;
  cvcCheck?: Maybe<Billing__Source__CvcCheck>;
  default: Scalars['Boolean'];
  expiration?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
  fundingType: Billing__Source__FundingType;
  id: Scalars['ID'];
  kind: Billing__Source__Kind;
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  stripeID: Scalars['ID'];
  zipCheck?: Maybe<Billing__Source__ZipCheck>;
};

/** Autogenerated return type of Billing__Source__Build */
export type Billing__Source__BuildPayload = {
  __typename?: 'Billing__Source__BuildPayload';
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
  source?: Maybe<Billing__Source>;
  status: Status;
};

export enum Billing__Source__CvcCheck {
  Fail = 'FAIL',
  Pass = 'PASS',
  Unavailable = 'UNAVAILABLE',
  Unchecked = 'UNCHECKED',
}

/** Autogenerated return type of Billing__Source__Default */
export type Billing__Source__DefaultPayload = {
  __typename?: 'Billing__Source__DefaultPayload';
  source?: Maybe<Billing__Source>;
};

/** Autogenerated return type of Billing__Source__Delete */
export type Billing__Source__DeletePayload = {
  __typename?: 'Billing__Source__DeletePayload';
  source?: Maybe<Billing__Source>;
};

export enum Billing__Source__FundingType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export enum Billing__Source__Kind {
  BankAccount = 'BANK_ACCOUNT',
  Card = 'CARD',
}

export enum Billing__Source__ZipCheck {
  Fail = 'FAIL',
  Pass = 'PASS',
  Unavailable = 'UNAVAILABLE',
  Unchecked = 'UNCHECKED',
}

export type Billing__UnattemptedAuthorizationHold = {
  __typename?: 'Billing__UnattemptedAuthorizationHold';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  orderID: Scalars['ID'];
  source?: Maybe<Billing__Source>;
  status: Billing__UnattemptedAuthorizationHoldStatus;
};

export enum Billing__UnattemptedAuthorizationHoldStatus {
  Canceled = 'CANCELED',
  Pending = 'PENDING',
}

/** Autogenerated return type of BuildDepot */
export type BuildDepotPayload = {
  __typename?: 'BuildDepotPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildFromCall */
export type BuildFromCallPayload = {
  __typename?: 'BuildFromCallPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildInteractionFromCall */
export type BuildInteractionFromCallPayload = {
  __typename?: 'BuildInteractionFromCallPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildMessage */
export type BuildMessagePayload = {
  __typename?: 'BuildMessagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildNote */
export type BuildNotePayload = {
  __typename?: 'BuildNotePayload';
  note?: Maybe<Note>;
  status: Status;
};

/** Autogenerated return type of BuildOpsAuditAnswers */
export type BuildOpsAuditAnswersPayload = {
  __typename?: 'BuildOpsAuditAnswersPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildPalletRequest */
export type BuildPalletRequestPayload = {
  __typename?: 'BuildPalletRequestPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildPalletType */
export type BuildPalletTypePayload = {
  __typename?: 'BuildPalletTypePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Build */
export type BuildPayload = {
  __typename?: 'BuildPayload';
  customerTicket?: Maybe<CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildTestOrderRequest */
export type BuildTestOrderRequestPayload = {
  __typename?: 'BuildTestOrderRequestPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  testOrderRequest?: Maybe<Automation__TestOrderRequest>;
};

export type BuildTicketInput = {
  assigneeID?: InputMaybe<Scalars['ID']>;
  attachments?: InputMaybe<Array<TicketAttachmentInput>>;
  category: Scalars['String'];
  description: Scalars['String'];
  dueDate: Scalars['DateTime'];
  goLiveTime?: InputMaybe<Scalars['DateTime']>;
  groupID?: InputMaybe<Scalars['ID']>;
  liveNow: Scalars['Boolean'];
  notify: Scalars['Boolean'];
  subCategory?: InputMaybe<Scalars['String']>;
  ticketableID: Scalars['ID'];
  ticketableType: Ticket__TicketableEnum;
};

/** Autogenerated return type of BuildTicket */
export type BuildTicketPayload = {
  __typename?: 'BuildTicketPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildWarehouse */
export type BuildWarehousePayload = {
  __typename?: 'BuildWarehousePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildZendeskTicket */
export type BuildZendeskTicketPayload = {
  __typename?: 'BuildZendeskTicketPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  zendeskTicketAssociation?: Maybe<ZendeskTicketAssociation>;
};

export type Crm__LeadInput = {
  email?: InputMaybe<Scalars['Email']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  serviceNeeds?: InputMaybe<Array<Lead__ServiceNeeds>>;
  source?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CalculatePackageSetEntryAmountInterface = {
  calculatePackageSetEntryAmount?: Maybe<Scalars['Int']>;
};

export type CalculatePackageSetEntryAmountInterfaceCalculatePackageSetEntryAmountArgs = {
  cuft: Scalars['Float'];
  packageKind: Scalars['String'];
  packageSetName: Scalars['String'];
};

export type CallGroup = {
  __typename?: 'CallGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Callable = {
  __typename?: 'Callable';
  assignmentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  number: Scalars['String'];
  phoneId?: Maybe<Scalars['ID']>;
  role: Scalars['String'];
};

/** Autogenerated return type of ChangeAccountSettings */
export type ChangeAccountSettingsPayload = {
  __typename?: 'ChangeAccountSettingsPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Changed__Associated = {
  __typename?: 'Changed__Associated';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Changed__Association = {
  __typename?: 'Changed__Association';
  associated: Changed__Associated;
  id: Scalars['ID'];
  kind: Changed__Association__Kind;
  name: Scalars['String'];
};

export enum Changed__Association__Kind {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type Changed__Audit = {
  __typename?: 'Changed__Audit';
  audited: Changed__Audited;
  changer: Changed__Changer;
  event: Changed__Event;
  fields: Array<Changed__Field>;
  id: Scalars['ID'];
  relationships: Array<Changed__Relationship>;
  timestamp: Scalars['DateTime'];
};

export type Changed__Audited = Changed__Customer | Changed__Phone | Changed__Record | LaborClock;

export type Changed__Changer = {
  __typename?: 'Changed__Changer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Changed__Customer = {
  __typename?: 'Changed__Customer';
  email: Scalars['String'];
  id: Scalars['ID'];
};

export enum Changed__Event {
  Create = 'CREATE',
  Destroy = 'DESTROY',
  Update = 'UPDATE',
}

export type Changed__Field = {
  __typename?: 'Changed__Field';
  name: Scalars['String'];
  now?: Maybe<Scalars['JSON']>;
  was?: Maybe<Scalars['JSON']>;
};

export type Changed__Phone = {
  __typename?: 'Changed__Phone';
  id: Scalars['ID'];
  number: Scalars['String'];
};

export type Changed__Record = {
  __typename?: 'Changed__Record';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Changed__Relationship = {
  __typename?: 'Changed__Relationship';
  associations: Array<Changed__Association>;
  name: Scalars['String'];
};

export type Claim = {
  __typename?: 'Claim';
  account: Account;
  closedAt?: Maybe<Scalars['DateTime']>;
  expiredReason?: Maybe<Scalars['String']>;
  hasDamagedItem: Scalars['Boolean'];
  hasDamagedProperty: Scalars['Boolean'];
  hasIssue: Scalars['Boolean'];
  hasLostItem: Scalars['Boolean'];
  id: Scalars['ID'];
  issueSelection?: Maybe<Claim__IssueSelection>;
  itemSelections: Array<Claim__ItemSelection>;
  kind: Scalars['String'];
  number: Scalars['String'];
  offeredAt?: Maybe<Scalars['DateTime']>;
  offers: Array<Claim__Offer>;
  openedAt?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<User>;
  propertySelection?: Maybe<Claim__PropertySelection>;
  settlementAgreement?: Maybe<ActiveStorageAttachment>;
  status: Claim__Status;
  uuid: Scalars['String'];
};

export type ClaimQueryInterface = {
  accountClaims: Array<Claim>;
  claim: Claim;
  itemSelection: Claim__ItemSelection;
  paginatedClaims: PaginatedClaim;
};

export type ClaimQueryInterfaceAccountClaimsArgs = {
  accountID: Scalars['ID'];
};

export type ClaimQueryInterfaceClaimArgs = {
  id: Scalars['ID'];
};

export type ClaimQueryInterfaceItemSelectionArgs = {
  claimItemSelectionID: Scalars['ID'];
};

export type ClaimQueryInterfacePaginatedClaimsArgs = {
  filters: Claim__FiltersInput;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type Claim__Assessment = {
  __typename?: 'Claim__Assessment';
  approvedAmount?: Maybe<Scalars['Float']>;
  classification: Claim__Assessment__Classification;
  coverage?: Maybe<Claim__Assessment__Coverage>;
  id: Scalars['ID'];
  verifiedValue: Scalars['Float'];
  weight?: Maybe<Scalars['Float']>;
};

export enum Claim__Assessment__Classification {
  MajorDamage = 'MAJOR_DAMAGE',
  MinorDamage = 'MINOR_DAMAGE',
  TotalLoss = 'TOTAL_LOSS',
}

export enum Claim__Assessment__Coverage {
  DollarPerPound = 'DOLLAR_PER_POUND',
  Property_1000 = 'PROPERTY_1000',
  Protection_1000 = 'PROTECTION_1000',
  Protection_2000 = 'PROTECTION_2000',
  Protection_2500 = 'PROTECTION_2500',
  Protection_5000 = 'PROTECTION_5000',
}

export type Claim__Assessment__Input = {
  classification?: InputMaybe<Claim__Assessment__Classification>;
  selectionID: Scalars['ID'];
  verifiedValue?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type Claim__FiltersInput = {
  hasDamagedItem?: InputMaybe<Scalars['Boolean']>;
  hasDamagedProperty?: InputMaybe<Scalars['Boolean']>;
  hasIssue?: InputMaybe<Scalars['Boolean']>;
  hasLostItem?: InputMaybe<Scalars['Boolean']>;
  ownerID?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Claim__Status>;
};

export type Claim__IssueSelection = {
  __typename?: 'Claim__IssueSelection';
  compensationRequested?: Maybe<Scalars['Boolean']>;
  declaration?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind?: Maybe<Claim__IssueSelection__Kind>;
  offeredResolutions: Array<Claim__Resolution>;
  photos: Array<ActiveStorageAttachment>;
  resolution?: Maybe<Claim__Resolution>;
};

export enum Claim__IssueSelection__Kind {
  Appointment = 'APPOINTMENT',
  Billing = 'BILLING',
  Other = 'OTHER',
}

export type Claim__ItemSelection = {
  __typename?: 'Claim__ItemSelection';
  assessment?: Maybe<Claim__Assessment>;
  declaration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  item?: Maybe<Item>;
  kind: Claim__ItemSelectionKind;
  name?: Maybe<Scalars['String']>;
  offeredResolutions: Array<Claim__Resolution>;
  photos: Array<ActiveStorageAttachment>;
  productURL?: Maybe<Scalars['String']>;
  receipts: Array<ActiveStorageAttachment>;
  resolution?: Maybe<Claim__Resolution>;
};

export enum Claim__ItemSelectionKind {
  Damaged = 'DAMAGED',
  Lost = 'LOST',
}

export type Claim__Offer = {
  __typename?: 'Claim__Offer';
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  expiry: Scalars['DateTime'];
  id: Scalars['ID'];
  totalAmount: Scalars['Float'];
  user: User;
};

export type Claim__OfferResolutionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  selectionID: Scalars['ID'];
};

export type Claim__PropertyOwner = {
  __typename?: 'Claim__PropertyOwner';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Claim__PropertySelection = {
  __typename?: 'Claim__PropertySelection';
  assessment?: Maybe<Claim__Assessment>;
  declaration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  offeredResolutions: Array<Claim__Resolution>;
  owner?: Maybe<Claim__PropertyOwner>;
  ownership?: Maybe<Claim__PropertySelectionOwnership>;
  photos: Array<ActiveStorageAttachment>;
  quotes: Array<ActiveStorageAttachment>;
  resolution?: Maybe<Claim__Resolution>;
};

export enum Claim__PropertySelectionOwnership {
  Customer = 'CUSTOMER',
  Landlord = 'LANDLORD',
}

export type Claim__Resolution = {
  __typename?: 'Claim__Resolution';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  kind: Claim__ResolutionKind;
  offeredAt?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum Claim__ResolutionKind {
  Compensated = 'COMPENSATED',
  Denied = 'DENIED',
}

export enum Claim__Status {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
}

export type Claim__VerifyIssueSelectionInput = {
  id: Scalars['ID'];
  kind?: InputMaybe<Claim__IssueSelection__Kind>;
  resolutionKind?: InputMaybe<Claim__ResolutionKind>;
  resolutionReason?: InputMaybe<Scalars['String']>;
};

export type Claim__VerifyItemSelectionInput = {
  id: Scalars['ID'];
  itemID?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Claim__ItemSelectionKind>;
  resolutionKind?: InputMaybe<Claim__ResolutionKind>;
  resolutionReason?: InputMaybe<Scalars['String']>;
};

export type Claim__VerifyPropertySelectionInput = {
  id: Scalars['ID'];
  resolutionKind?: InputMaybe<Claim__ResolutionKind>;
  resolutionReason?: InputMaybe<Scalars['String']>;
};

export type Claim__Verify__Input = {
  claimID: Scalars['ID'];
  issueSelection?: InputMaybe<Claim__VerifyIssueSelectionInput>;
  itemSelections?: InputMaybe<Array<Claim__VerifyItemSelectionInput>>;
  propertySelection?: InputMaybe<Claim__VerifyPropertySelectionInput>;
};

export type ClaimsMutationInterface = {
  closeClaim: ClosePayload;
  extendOffer: Claims__ExtendOfferPayload;
  modifyClaimAssessments: Claims__ModifyAssessmentsPayload;
  modifyClaimItemSelections: Claims__ModifyItemSelectionsPayload;
  modifyClaimOwner: Claims__ModifyOwnerPayload;
  reopenClaim: Claims__ReopenPayload;
  saveOffer: Claims__SaveOfferPayload;
  verifyClaim: Claims__VerifyPayload;
};

export type ClaimsMutationInterfaceCloseClaimArgs = {
  claimID: Scalars['ID'];
  reason: Scalars['String'];
};

export type ClaimsMutationInterfaceExtendOfferArgs = {
  input: Claims__ExtendOffer__Input;
};

export type ClaimsMutationInterfaceModifyClaimAssessmentsArgs = {
  input: Claims__ModifyAssessments__Input;
};

export type ClaimsMutationInterfaceModifyClaimItemSelectionsArgs = {
  claimID: Scalars['ID'];
  itemSelections: Array<Claims__ItemSelectionInput>;
};

export type ClaimsMutationInterfaceModifyClaimOwnerArgs = {
  claimID: Scalars['ID'];
  ownerID?: InputMaybe<Scalars['ID']>;
};

export type ClaimsMutationInterfaceReopenClaimArgs = {
  claimID: Scalars['ID'];
};

export type ClaimsMutationInterfaceSaveOfferArgs = {
  input: Claims__SaveOffer__Input;
};

export type ClaimsMutationInterfaceVerifyClaimArgs = {
  input: Claim__Verify__Input;
};

export type ClaimsSummary = {
  __typename?: 'ClaimsSummary';
  latestProtectionPlan: ProtectionPlan;
  paidItemCompensation: Scalars['Float'];
  paidPropertyCompensation: Scalars['Float'];
  pendingItemCompensation: Scalars['Float'];
  pendingPropertyCompensation: Scalars['Float'];
  totalItemCompensation: Scalars['Float'];
  totalPropertyCompensation: Scalars['Float'];
};

/** Autogenerated return type of Claims__ExtendOffer */
export type Claims__ExtendOfferPayload = {
  __typename?: 'Claims__ExtendOfferPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Claims__ExtendOffer__Input = {
  claimID: Scalars['ID'];
  details: Scalars['String'];
  issueResolution?: InputMaybe<Claim__OfferResolutionInput>;
  itemResolutions?: InputMaybe<Array<Claim__OfferResolutionInput>>;
  propertyResolution?: InputMaybe<Claim__OfferResolutionInput>;
};

export type Claims__ItemSelectionInput = {
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  kind: Claim__ItemSelectionKind;
};

/** Autogenerated return type of Claims__ModifyAssessments */
export type Claims__ModifyAssessmentsPayload = {
  __typename?: 'Claims__ModifyAssessmentsPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Claims__ModifyAssessments__Input = {
  claimID: Scalars['ID'];
  itemAssessments?: InputMaybe<Array<Claim__Assessment__Input>>;
  propertyAssessment?: InputMaybe<Claim__Assessment__Input>;
};

/** Autogenerated return type of Claims__ModifyItemSelections */
export type Claims__ModifyItemSelectionsPayload = {
  __typename?: 'Claims__ModifyItemSelectionsPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Claims__ModifyOwner */
export type Claims__ModifyOwnerPayload = {
  __typename?: 'Claims__ModifyOwnerPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Claims__Reopen */
export type Claims__ReopenPayload = {
  __typename?: 'Claims__ReopenPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Claims__SaveOffer */
export type Claims__SaveOfferPayload = {
  __typename?: 'Claims__SaveOfferPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Claims__SaveOffer__Input = {
  claimID: Scalars['ID'];
  issueResolution?: InputMaybe<Claim__OfferResolutionInput>;
  itemResolutions?: InputMaybe<Array<Claim__OfferResolutionInput>>;
  propertyResolution?: InputMaybe<Claim__OfferResolutionInput>;
};

/** Autogenerated return type of Claims__Verify */
export type Claims__VerifyPayload = {
  __typename?: 'Claims__VerifyPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Close */
export type ClosePayload = {
  __typename?: 'ClosePayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum ClutterGeoEnum {
  Boston = 'BOSTON',
  Dc = 'DC',
  Midwest = 'MIDWEST',
  Norcal = 'NORCAL',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  Socal = 'SOCAL',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST',
}

export type Communication = Message | PhoneCall;

export type ComplianceQueryInterface = {
  ordersMissingSignature: Array<Order>;
};

export type ComplianceQueryInterfaceOrdersMissingSignatureArgs = {
  accountID: Scalars['ID'];
};

/** Autogenerated return type of ConfigurationModify */
export type ConfigurationModifyPayload = {
  __typename?: 'ConfigurationModifyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ConnectPhoneCallInput = {
  accountID: Scalars['ID'];
  callSID: Scalars['String'];
};

/** Autogenerated return type of ConnectPhoneCall */
export type ConnectPhoneCallPayload = {
  __typename?: 'ConnectPhoneCallPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ContainersMutationInterface = {
  containersAccountCreate: Containers__AccountCreatePayload;
};

export type ContainersMutationInterfaceContainersAccountCreateArgs = {
  customerInput: Containers__CustomerInput;
  zip: Scalars['String'];
};

/** Autogenerated return type of Containers__AccountCreate */
export type Containers__AccountCreatePayload = {
  __typename?: 'Containers__AccountCreatePayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

export type Containers__CustomerInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ContractorPasswordResetRequest = {
  __typename?: 'ContractorPasswordResetRequest';
  id: Scalars['ID'];
  user: User;
};

/** Autogenerated return type of ContractorPasswordResetRequest__Approve */
export type ContractorPasswordResetRequest__ApprovePayload = {
  __typename?: 'ContractorPasswordResetRequest__ApprovePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ContractorPasswordResetRequest__Approve__Input = {
  mfaRequestID: Scalars['ID'];
  verificationCode: Scalars['String'];
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID'];
  recording?: Maybe<Recording>;
  user: User;
};

export type Coupon = {
  __typename?: 'Coupon';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  code: Scalars['String'];
  currency: Currency;
  discountDescription: Scalars['String'];
  duration: Coupon__Duration;
  durationInMonths?: Maybe<Scalars['Int']>;
  effective?: Maybe<Scalars['String']>;
  eligibleServices: Array<Coupon__Service>;
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  scheduledCutoff?: Maybe<Scalars['Date']>;
  stripeId: Scalars['String'];
  subject: Coupon__Subject;
};

export enum Coupon__Duration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING',
}

export type Coupon__FiltersInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Coupon__Subject>;
};

export type Coupon__Input = {
  active: Scalars['Boolean'];
  amount?: InputMaybe<Scalars['Int']>;
  duration: Coupon__Duration;
  durationInMonths?: InputMaybe<Scalars['Int']>;
  eligibleServices: Array<Coupon__Service>;
  name: Scalars['String'];
  percent?: InputMaybe<Scalars['Float']>;
  promoCode?: InputMaybe<Marketing__PromoCode__Input>;
  scheduledCutoff?: InputMaybe<Scalars['Date']>;
  stripeId: Scalars['String'];
  subject: Coupon__Subject;
};

export type Coupon__PaginatedType = {
  __typename?: 'Coupon__PaginatedType';
  pagination: Pagination;
  results: Array<Coupon>;
};

export enum Coupon__Service {
  Moving = 'MOVING',
  StorageOnboarding = 'STORAGE_ONBOARDING',
}

export enum Coupon__Subject {
  Credit = 'CREDIT',
  Order = 'ORDER',
  Subscription = 'SUBSCRIPTION',
}

/** Autogenerated return type of CreateAppointmentQuote */
export type CreateAppointmentQuotePayload = {
  __typename?: 'CreateAppointmentQuotePayload';
  error?: Maybe<Scalars['String']>;
  finalPackageSetEntries: Array<PackageSetEntry>;
  laborRate: LaborRate;
  onboardingPackageSetEntries: Array<PackageSetEntry>;
  quote?: Maybe<AppointmentQuote>;
  status: Status;
};

/** Autogenerated return type of CreateAuditException */
export type CreateAuditExceptionPayload = {
  __typename?: 'CreateAuditExceptionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type CreateLaborClockInput = {
  ended?: InputMaybe<Scalars['DateTime']>;
  orderID: Scalars['ID'];
  role: Assignment__Role;
  started?: InputMaybe<Scalars['DateTime']>;
  userID: Scalars['ID'];
};

/** Autogenerated return type of CreateLaborClock */
export type CreateLaborClockPayload = {
  __typename?: 'CreateLaborClockPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type CrmMutationInterface = {
  leadCreate?: Maybe<LeadCreatePayload>;
};

export type CrmMutationInterfaceLeadCreateArgs = {
  input: Crm__LeadInput;
};

export enum Currency {
  Cad = 'CAD',
  Usd = 'USD',
}

export type CustomDimension = {
  __typename?: 'CustomDimension';
  cuft: Scalars['Int'];
  height: Scalars['Float'];
  id: Scalars['ID'];
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export type CustomDimensionInput = {
  height?: InputMaybe<Scalars['Float']>;
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export type Customer = {
  __typename?: 'Customer';
  cellPhone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
};

export type CustomerAccessHours = {
  __typename?: 'CustomerAccessHours';
  close?: Maybe<Scalars['String']>;
  day: Scalars['String'];
  open?: Maybe<Scalars['String']>;
};

export type CustomerAccessHoursInput = {
  close: Scalars['String'];
  day: Scalars['String'];
  open: Scalars['String'];
};

export type CustomerInteraction = {
  __typename?: 'CustomerInteraction';
  id: Scalars['ID'];
  interactable: InteractableUnion;
  interactableType: Scalars['String'];
};

export type CustomerTicket = {
  __typename?: 'CustomerTicket';
  actionRequiredAt?: Maybe<Scalars['DateTime']>;
  allowIncomingCalls: Scalars['Boolean'];
  assignee?: Maybe<User>;
  category: Scalars['String'];
  contactPreference?: Maybe<CustomerTicket__ContactPreference>;
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  customerTicketable: CustomerTicketable;
  customerTicketableType: Scalars['String'];
  customerUpdatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  files: Array<ActiveStorageAttachment>;
  group?: Maybe<Ticket__Group>;
  id: Scalars['ID'];
  interactions: Array<CustomerInteraction>;
  leadInteractions: Array<LeadInteraction>;
  priority?: Maybe<CustomerTicket__Priority>;
  state: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  zendeskTicketAssociations: Array<ZendeskTicketAssociation>;
};

export type CustomerTicketFiltersInput = {
  assigneeID?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['String']>;
  contactPreference?: InputMaybe<CustomerTicket__ContactPreference>;
  customerTicketableID?: InputMaybe<Scalars['ID']>;
  customerTicketableType?: InputMaybe<Scalars['String']>;
  groupID?: InputMaybe<Scalars['ID']>;
  mode?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<CustomerTicket__Priority>;
  state?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<Scalars['String']>;
};

export type CustomerTicketSortInput = {
  column?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<OrderingDirectionEnum>;
};

export enum CustomerTicket__ContactPreference {
  Chat = 'CHAT',
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export enum CustomerTicket__Priority {
  Extreme = 'EXTREME',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

/** Resources that can have CustomerTickets */
export type CustomerTicketable = Account | Lead;

export type CustomerTicketsMutationInterface = {
  buildCustomerTicket: BuildPayload;
  buildCustomerTicketFromCall: BuildFromCallPayload;
  buildInteractionFromCall: BuildInteractionFromCallPayload;
  buildZendeskTicket: BuildZendeskTicketPayload;
  modifyCustomerTicket: ModifyPayload;
  processCustomerTicketFromCall: ProcessFromCallPayload;
  reopenCustomerTicket: ReopenPayload;
  resolveCustomerTicket: ResolvePayload;
};

export type CustomerTicketsMutationInterfaceBuildCustomerTicketArgs = {
  input: CustomerTickets__BuildInput;
};

export type CustomerTicketsMutationInterfaceBuildCustomerTicketFromCallArgs = {
  input: CustomerTickets__BuildFromCallInput;
};

export type CustomerTicketsMutationInterfaceBuildInteractionFromCallArgs = {
  input: CustomerTickets__BuildInteractionFromCallInput;
};

export type CustomerTicketsMutationInterfaceBuildZendeskTicketArgs = {
  customerTicketID: Scalars['ID'];
};

export type CustomerTicketsMutationInterfaceModifyCustomerTicketArgs = {
  input: CustomerTickets__ModifyInput;
};

export type CustomerTicketsMutationInterfaceProcessCustomerTicketFromCallArgs = {
  input: CustomerTickets__ProcessFromCallInput;
};

export type CustomerTicketsMutationInterfaceReopenCustomerTicketArgs = {
  actionRequiredAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CustomerTicketsMutationInterfaceResolveCustomerTicketArgs = {
  id: Scalars['ID'];
};

export type CustomerTicketsQueryInterface = {
  customerTicket: CustomerTicket;
  customerTicketByCall: CustomerTicket;
  paginatedCustomerTicketList: PaginatedCustomerTicket;
  zendeskTicket: ZendeskTicket;
};

export type CustomerTicketsQueryInterfaceCustomerTicketArgs = {
  id: Scalars['ID'];
};

export type CustomerTicketsQueryInterfaceCustomerTicketByCallArgs = {
  sid: Scalars['String'];
};

export type CustomerTicketsQueryInterfacePaginatedCustomerTicketListArgs = {
  filters?: InputMaybe<CustomerTicketFiltersInput>;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<CustomerTicketSortInput>;
};

export type CustomerTicketsQueryInterfaceZendeskTicketArgs = {
  maxComments?: InputMaybe<Scalars['Int']>;
  zdTicketID: Scalars['ID'];
};

export type CustomerTickets__BuildFromCallInput = {
  category: Scalars['String'];
  customerTicketableID: Scalars['ID'];
  customerTicketableType: Scalars['String'];
  sid: Scalars['String'];
  subCategory?: InputMaybe<Scalars['String']>;
};

export type CustomerTickets__BuildInput = {
  actionRequiredAt: Scalars['DateTime'];
  assigneeID?: InputMaybe<Scalars['ID']>;
  category: Scalars['String'];
  customerTicketableID: Scalars['ID'];
  customerTicketableType: Scalars['String'];
  description: Scalars['String'];
  groupID: Scalars['ID'];
  priority: CustomerTicket__Priority;
  subCategory?: InputMaybe<Scalars['String']>;
};

export type CustomerTickets__BuildInteractionFromCallInput = {
  customerTicketId: Scalars['ID'];
  customerTicketableID: Scalars['ID'];
  customerTicketableType: Scalars['String'];
  sid: Scalars['String'];
};

export type CustomerTickets__ModifyInput = {
  actionRequiredAt?: InputMaybe<Scalars['DateTime']>;
  allowIncomingCalls?: InputMaybe<Scalars['Boolean']>;
  assigneeId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  priority?: InputMaybe<CustomerTicket__Priority>;
  subCategory?: InputMaybe<Scalars['String']>;
};

export type CustomerTickets__ProcessFromCallInput = {
  actionRequiredAt?: InputMaybe<Scalars['DateTime']>;
  assigneeID?: InputMaybe<Scalars['ID']>;
  category: Scalars['String'];
  groupID?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  noteAttributes: NoteInput;
  priority?: InputMaybe<CustomerTicket__Priority>;
  sid: Scalars['String'];
  subCategory?: InputMaybe<Scalars['String']>;
};

export type DamagedItem = {
  __typename?: 'DamagedItem';
  id: Scalars['ID'];
  images: Array<Image>;
  kind: DamagedItem__Kind;
  reason?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  user: User;
};

export enum DamagedItem__Kind {
  ClutterCaused = 'CLUTTER_CAUSED',
  Preexisting = 'PREEXISTING',
}

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type Declaration = {
  __typename?: 'Declaration';
  description: Scalars['String'];
  id: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteLaborClock */
export type DeleteLaborClockPayload = {
  __typename?: 'DeleteLaborClockPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DeletePalletRequest */
export type DeletePalletRequestPayload = {
  __typename?: 'DeletePalletRequestPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Depot = {
  __typename?: 'Depot';
  abbreviation: Scalars['String'];
  active: Scalars['Boolean'];
  address: Address;
  id: Scalars['ID'];
  name: Scalars['String'];
  regions: Array<Region>;
};

export type DepotInput = {
  abbreviation: Scalars['String'];
  active: Scalars['Boolean'];
  address: NoDetailAddressInput;
  name: Scalars['String'];
  regionIDs: Array<Scalars['ID']>;
};

export type Detail = {
  __typename?: 'Detail';
  accessUnknown: Scalars['Boolean'];
  additionalNotes?: Maybe<Scalars['String']>;
  buildingClosingHour?: Maybe<Scalars['Int']>;
  buildingOpeningHour?: Maybe<Scalars['Int']>;
  buildingRestrictionsAnswer?: Maybe<Address__BuildingRestrictionsAnswerEnum>;
  buildingSubtype?: Maybe<Scalars['String']>;
  buildingType?: Maybe<Scalars['String']>;
  code: Scalars['Boolean'];
  codeValue?: Maybe<Scalars['String']>;
  elevator: Scalars['Boolean'];
  environment?: Maybe<EnvironmentEnum>;
  floor?: Maybe<Scalars['Int']>;
  frontDoorSteps?: Maybe<Scalars['Int']>;
  gated: Scalars['Boolean'];
  id: Scalars['ID'];
  loadingDock: Scalars['Boolean'];
  longCarry: Scalars['Boolean'];
  maxTruckHeight?: Maybe<Scalars['Int']>;
  maxTruckHeightUnknown: Scalars['Boolean'];
  paperwork: Scalars['Boolean'];
  paperworkUnknown: Scalars['Boolean'];
  parkingAlley: Scalars['Boolean'];
  parkingDriveway: Scalars['Boolean'];
  parkingInFront: Scalars['Boolean'];
  parkingInstructions?: Maybe<Scalars['String']>;
  parkingLatitude?: Maybe<Scalars['Float']>;
  parkingLongitude?: Maybe<Scalars['Float']>;
  parkingLot: Scalars['Boolean'];
  parkingOnSpecificStreet?: Maybe<Scalars['String']>;
  parkingPermit: Scalars['Boolean'];
  parkingStreet: Scalars['Boolean'];
  parkingUnknown: Scalars['Boolean'];
  secureLocationDetails?: Maybe<Scalars['String']>;
  serviceElevator: Scalars['Boolean'];
  serviceEntrance: Scalars['Boolean'];
  squareFeet?: Maybe<Scalars['Int']>;
  stairs: Scalars['Boolean'];
  steep: Scalars['Boolean'];
  stories?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Address__UnitSizeEnum>;
};

export type DetailedImage = {
  __typename?: 'DetailedImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  source: Scalars['String'];
  state: ImageState;
  user?: Maybe<User>;
};

export type Dispatch = {
  __typename?: 'Dispatch';
  arrival: Scalars['DateTime'];
  assignments: Array<Assignment>;
  callables: Array<Callable>;
  canDropMover: Scalars['Boolean'];
  clockout?: Maybe<Scalars['DateTime']>;
  cutoff: Scalars['DateTime'];
  dispatchesVehicles: Array<DispatchesVehicle>;
  estimatedInboundItemMetrics: Dispatch__ItemMetrics;
  events: Array<Dispatch__Event>;
  id: Scalars['ID'];
  inboundWarehouse?: Maybe<Warehouse>;
  orders: Array<Order>;
  outboundItemMetrics: Dispatch__ItemMetrics;
  paginatedCommunications: PaginatedCommunication;
  preTripAssessment?: Maybe<PreTripAssessment>;
  region: Region;
  simulation?: Maybe<DispatchSimulation>;
  tasks: Array<Dispatch__Task>;
  vehiclePreferences: Array<Dispatch__VehiclePreference>;
  vehicleRecipe?: Maybe<Dispatch__VehicleRecipe>;
  vehicles: Array<Vehicle>;
};

export type DispatchOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
};

export type DispatchPaginatedCommunicationsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type DispatchFiltersInput = {
  date?: InputMaybe<Scalars['Date']>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type DispatchInput = {
  inboundWarehouseID?: InputMaybe<Scalars['ID']>;
  vehicleIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type DispatchSimulation = {
  __typename?: 'DispatchSimulation';
  duration: Array<Scalars['Duration']>;
  errors: Array<SimulationError>;
};

/** Autogenerated return type of DispatchUpdate */
export type DispatchUpdatePayload = {
  __typename?: 'DispatchUpdatePayload';
  dispatch?: Maybe<Dispatch>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Dispatch__Event = {
  __typename?: 'Dispatch__Event';
  createdAt: Scalars['DateTime'];
  dispatch: Dispatch;
  id: Scalars['ID'];
  name: Dispatch__EventNameEnum;
  timestamp: Scalars['DateTime'];
  user: User;
};

export enum Dispatch__EventNameEnum {
  OutboundTeamAssigned = 'OUTBOUND_TEAM_ASSIGNED',
  TeamAssigned = 'TEAM_ASSIGNED',
}

export type Dispatch__ItemMetrics = {
  __typename?: 'Dispatch__ItemMetrics';
  count?: Maybe<Scalars['Int']>;
  cuft: Scalars['Float'];
};

export type Dispatch__Task = {
  __typename?: 'Dispatch__Task';
  canceledAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  dispatch: Dispatch;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  isClaimer: Scalars['Boolean'];
  kind: Dispatch__TaskKindEnum;
  user: User;
  vehicle?: Maybe<Vehicle>;
};

export enum Dispatch__TaskKindEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export type Dispatch__VehiclePreference = {
  __typename?: 'Dispatch__VehiclePreference';
  dispatch: Dispatch;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  vehicleType: VehicleType;
};

export type Dispatch__VehicleRecipe = {
  __typename?: 'Dispatch__VehicleRecipe';
  allowedVehicleTypeIds?: Maybe<Array<Scalars['Int']>>;
  allowedVehicleTypes: Array<VehicleType>;
  id: Scalars['ID'];
  maxQuantity?: Maybe<Scalars['Int']>;
  minQuantity?: Maybe<Scalars['Int']>;
  minimumCuft?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

export type DispatchesVehicle = {
  __typename?: 'DispatchesVehicle';
  id: Scalars['ID'];
  sealAt?: Maybe<Scalars['DateTime']>;
  sealImage?: Maybe<Image>;
  user?: Maybe<User>;
  vehicle: Vehicle;
};

export type DockApiMutationInterface = {
  buildOrModifyPalletGrade: DockApi__BuildOrModifyPalletGradePayload;
  buildOrderEventAuditImage: DockApi__BuildOrderEventAuditImagePayload;
  buildPallet: DockApi__BuildPalletPayload;
  dispatchEventCreate: DockApi__Dispatch__Event__EventCreatePayload;
  dispatchTaskTeamUpsert: DockApi__Dispatch__Task__TeamUpsertPayload;
  disposalItemStage: DockApi__DisposalItemStagePayload;
  itemDispose: DockApi__ItemDisposePayload;
  itemOrderDelete: DockApi__ItemOrderDeletePayload;
  modifyLocation: DockApi__ModifyLocationPayload;
  modifyPallet: DockApi__ModifyPalletPayload;
  outboundFacilityOrderComplete: DockApi__OutboundFacilityOrderCompletePayload;
  routeRetailDelivery: DockApi__RouteRetailDeliveryPayload;
  shipmentImageUpdate: DockApi__ShipmentImageUpdatePayload;
  shipmentItemCreate: DockApi__ShipmentItemCreatePayload;
  startLaborClock: DockApi__StartLaborClockPayload;
  transfersSealTrailer: DockApi__Transfers__SealTrailerPayload;
  vehicleTripMaterialCountCreate: DockApi__VehicleTrip__MaterialCount__CreatePayload;
  vehicleTripMaterialCountTrust: DockApi__VehicleTrip__MaterialCount__TrustPayload;
};

export type DockApiMutationInterfaceBuildOrModifyPalletGradeArgs = {
  input: DockApi__PalletGradeInput;
};

export type DockApiMutationInterfaceBuildOrderEventAuditImageArgs = {
  input: DockApi__OrderEventInput;
};

export type DockApiMutationInterfaceBuildPalletArgs = {
  input: PalletInput;
};

export type DockApiMutationInterfaceDispatchEventCreateArgs = {
  input: DockApi__Dispatch__Event__EventCreateInput;
};

export type DockApiMutationInterfaceDispatchTaskTeamUpsertArgs = {
  input: DockApi__Dispatch__Task__TeamUpsertInput;
};

export type DockApiMutationInterfaceDisposalItemStageArgs = {
  itemBarcode?: InputMaybe<Scalars['String']>;
  itemID?: InputMaybe<Scalars['ID']>;
  locationValue: Scalars['String'];
};

export type DockApiMutationInterfaceItemDisposeArgs = {
  ensureOnDisposal?: InputMaybe<Scalars['Boolean']>;
  itemBarcode?: InputMaybe<Scalars['String']>;
  itemID?: InputMaybe<Scalars['ID']>;
};

export type DockApiMutationInterfaceItemOrderDeleteArgs = {
  input: DockApi__ItemOrderDelete__Input;
};

export type DockApiMutationInterfaceModifyLocationArgs = {
  input: DockApi__LocationInput;
};

export type DockApiMutationInterfaceModifyPalletArgs = {
  id: Scalars['ID'];
  input: PalletInput;
};

export type DockApiMutationInterfaceOutboundFacilityOrderCompleteArgs = {
  input: DockApi__OutboundFacilityOrderCompleteInput;
};

export type DockApiMutationInterfaceRouteRetailDeliveryArgs = {
  orderID: Scalars['ID'];
};

export type DockApiMutationInterfaceShipmentImageUpdateArgs = {
  input: DockApi__ShipmentImageUpdateInput;
};

export type DockApiMutationInterfaceShipmentItemCreateArgs = {
  input: DockApi__ShipmentItemCreateInput;
};

export type DockApiMutationInterfaceStartLaborClockArgs = {
  input: DockApi__StartLaborClockInput;
};

export type DockApiMutationInterfaceTransfersSealTrailerArgs = {
  input: DockApi__Transfers__SealTrailerInput;
};

export type DockApiMutationInterfaceVehicleTripMaterialCountCreateArgs = {
  input: DockApi__VehicleTrip__MaterialCount__CreateInput;
};

export type DockApiMutationInterfaceVehicleTripMaterialCountTrustArgs = {
  materialCountID: Scalars['ID'];
};

export type DockApiQueryInterface = {
  dispatch: Dispatch;
  documents: Array<Document>;
  itemTransferRequests: Array<ItemTransferRequest>;
  itemsToRewrap: DockApi__ItemsToRewrap;
  palletizeSummary: DockApi__PalletizeSummary;
  pulledToBeStored: Array<Item>;
  stagedDisposalItems: Array<Item>;
  transfers: Array<Transfer>;
  usersAvailableForDispatchTask: Array<User>;
  warehouseOrders: DockApi__WarehouseOrders;
};

export type DockApiQueryInterfaceDispatchArgs = {
  dispatchID: Scalars['ID'];
};

export type DockApiQueryInterfaceDocumentsArgs = {
  kinds: Array<DocumentKindEnum>;
};

export type DockApiQueryInterfaceItemTransferRequestsArgs = {
  input: DockApi__Transfers__ItemTransferRequestsFilterInput;
};

export type DockApiQueryInterfaceItemsToRewrapArgs = {
  orderID: Scalars['ID'];
};

export type DockApiQueryInterfacePalletizeSummaryArgs = {
  orderID: Scalars['ID'];
};

export type DockApiQueryInterfacePulledToBeStoredArgs = {
  orderID: Scalars['ID'];
};

export type DockApiQueryInterfaceStagedDisposalItemsArgs = {
  warehouseID: Scalars['ID'];
};

export type DockApiQueryInterfaceTransfersArgs = {
  input: DockApi__Transfers__FilterInput;
};

export type DockApiQueryInterfaceUsersAvailableForDispatchTaskArgs = {
  warehouseID: Scalars['ID'];
};

export type DockApiQueryInterfaceWarehouseOrdersArgs = {
  warehouseID: Scalars['ID'];
};

/** Autogenerated return type of DockApi__BuildOrModifyPalletGrade */
export type DockApi__BuildOrModifyPalletGradePayload = {
  __typename?: 'DockApi__BuildOrModifyPalletGradePayload';
  error?: Maybe<Scalars['String']>;
  grade?: Maybe<PalletGrade>;
  status: Status;
};

/** Autogenerated return type of DockApi__BuildOrderEventAuditImage */
export type DockApi__BuildOrderEventAuditImagePayload = {
  __typename?: 'DockApi__BuildOrderEventAuditImagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DockApi__BuildPallet */
export type DockApi__BuildPalletPayload = {
  __typename?: 'DockApi__BuildPalletPayload';
  error?: Maybe<Scalars['String']>;
  pallet?: Maybe<Pallet>;
  status: Status;
};

export type DockApi__Dispatch__Event__EventCreateInput = {
  dispatchID: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Dispatch__EventNameEnum;
  timestamp: Scalars['DateTime'];
};

/** Autogenerated return type of DockApi__Dispatch__Event__EventCreate */
export type DockApi__Dispatch__Event__EventCreatePayload = {
  __typename?: 'DockApi__Dispatch__Event__EventCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__Dispatch__Task__TeamUpsertInput = {
  addUserIDs?: InputMaybe<Array<Scalars['ID']>>;
  dispatchID: Scalars['ID'];
  removeUserIDs?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of DockApi__Dispatch__Task__TeamUpsert */
export type DockApi__Dispatch__Task__TeamUpsertPayload = {
  __typename?: 'DockApi__Dispatch__Task__TeamUpsertPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DockApi__DisposalItemStage */
export type DockApi__DisposalItemStagePayload = {
  __typename?: 'DockApi__DisposalItemStagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DockApi__ItemDispose */
export type DockApi__ItemDisposePayload = {
  __typename?: 'DockApi__ItemDisposePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DockApi__ItemOrderDelete */
export type DockApi__ItemOrderDeletePayload = {
  __typename?: 'DockApi__ItemOrderDeletePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__ItemOrderDelete__Input = {
  itemID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type DockApi__ItemsToRewrap = {
  __typename?: 'DockApi__ItemsToRewrap';
  packingGroups: Array<DockApi__ItemsToRewrap__RewrapPackingGroup>;
};

export type DockApi__ItemsToRewrap__RewrapPackingGroup = {
  __typename?: 'DockApi__ItemsToRewrap__RewrapPackingGroup';
  items: Array<Item>;
  packingStandard: PackingStandard;
};

export type DockApi__LocationInput = {
  damaged?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inactive?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DockApi__ModifyLocation */
export type DockApi__ModifyLocationPayload = {
  __typename?: 'DockApi__ModifyLocationPayload';
  error?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  status: Status;
};

/** Autogenerated return type of DockApi__ModifyPallet */
export type DockApi__ModifyPalletPayload = {
  __typename?: 'DockApi__ModifyPalletPayload';
  error?: Maybe<Scalars['String']>;
  pallet?: Maybe<Pallet>;
  status: Status;
};

export type DockApi__OrderEventInput = {
  description?: InputMaybe<Scalars['String']>;
  eventName: Scalars['String'];
  imageUrl: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  orderID: Scalars['ID'];
  scheduled?: InputMaybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
};

export type DockApi__OutboundFacilityOrderCompleteInput = {
  description?: InputMaybe<Scalars['String']>;
  eventName: Scalars['String'];
  imageUrl: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  orderID: Scalars['ID'];
  scheduled?: InputMaybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
};

/** Autogenerated return type of DockApi__OutboundFacilityOrderComplete */
export type DockApi__OutboundFacilityOrderCompletePayload = {
  __typename?: 'DockApi__OutboundFacilityOrderCompletePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__PalletGradeInput = {
  backImageUrl?: InputMaybe<Scalars['String']>;
  frontImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  palletId: Scalars['ID'];
};

export type DockApi__PalletizeSummary = {
  __typename?: 'DockApi__PalletizeSummary';
  accountRuleSet?: Maybe<AccountRuleSet>;
  auditOrderEvents: Array<AuditOrderEvent>;
  completed: Array<DockApi__PalletizeSummary__Completed>;
  flagged: Array<Item>;
  inProgress: Array<DockApi__PalletizeSummary__InProgress>;
  shipments: Array<Shipment>;
  unstarted: Array<DockApi__PalletizeSummary__Unstarted>;
};

export enum DockApi__PalletizeSummary__ActionLabelEnum {
  ReshootItem = 'RESHOOT_ITEM',
  RewrapItem = 'REWRAP_ITEM',
}

export type DockApi__PalletizeSummary__Completed = {
  __typename?: 'DockApi__PalletizeSummary__Completed';
  completedPalletizations: Array<DockApi__PalletizeSummary__InProgress>;
  palletType: PalletType;
};

export type DockApi__PalletizeSummary__InProgress = {
  __typename?: 'DockApi__PalletizeSummary__InProgress';
  items: Array<Item>;
  pallet: Pallet;
};

export type DockApi__PalletizeSummary__InboundDefectItemType = {
  __typename?: 'DockApi__PalletizeSummary__InboundDefectItemType';
  actionLabels: Array<DockApi__PalletizeSummary__ActionLabelEnum>;
  defectMessages: Array<Scalars['String']>;
  item: Item;
};

export enum DockApi__PalletizeSummary__RequirementKindEnum {
  NoRequirement = 'NO_REQUIREMENT',
  Recommended = 'RECOMMENDED',
  Required = 'REQUIRED',
}

export type DockApi__PalletizeSummary__Unstarted = {
  __typename?: 'DockApi__PalletizeSummary__Unstarted';
  items: Array<Item>;
  itemsAndDefects: Array<DockApi__PalletizeSummary__InboundDefectItemType>;
  name: Scalars['String'];
  recommendation?: Maybe<Scalars['String']>;
  requirement: DockApi__PalletizeSummary__RequirementKindEnum;
};

/** Autogenerated return type of DockApi__RouteRetailDelivery */
export type DockApi__RouteRetailDeliveryPayload = {
  __typename?: 'DockApi__RouteRetailDeliveryPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum DockApi__ShipmentImageTypeEnum {
  LabelImage = 'LABEL_IMAGE',
}

export type DockApi__ShipmentImageUpdateInput = {
  imageType: DockApi__ShipmentImageTypeEnum;
  imageUrl: Scalars['String'];
  shipmentId: Scalars['ID'];
};

/** Autogenerated return type of DockApi__ShipmentImageUpdate */
export type DockApi__ShipmentImageUpdatePayload = {
  __typename?: 'DockApi__ShipmentImageUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__ShipmentItemCreateInput = {
  barcodeValue: Scalars['String'];
  imageURLs: Array<Scalars['String']>;
  shipmentID: Scalars['ID'];
};

/** Autogenerated return type of DockApi__ShipmentItemCreate */
export type DockApi__ShipmentItemCreatePayload = {
  __typename?: 'DockApi__ShipmentItemCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__StartLaborClockInput = {
  orderID: Scalars['ID'];
};

/** Autogenerated return type of DockApi__StartLaborClock */
export type DockApi__StartLaborClockPayload = {
  __typename?: 'DockApi__StartLaborClockPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__Transfers__FilterInput = {
  destinationID?: InputMaybe<Scalars['ID']>;
  originID: Scalars['ID'];
  scheduledDate: Scalars['Date'];
};

export type DockApi__Transfers__ItemTransferRequestsFilterInput = {
  destinationID?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  earliestDueDate?: InputMaybe<Scalars['Date']>;
  latestDueDate?: InputMaybe<Scalars['Date']>;
  originID: Scalars['ID'];
};

export type DockApi__Transfers__SealTrailerInput = {
  fullTrailerImageURL: Scalars['String'];
  sealCode: Scalars['String'];
  transferID: Scalars['ID'];
};

/** Autogenerated return type of DockApi__Transfers__SealTrailer */
export type DockApi__Transfers__SealTrailerPayload = {
  __typename?: 'DockApi__Transfers__SealTrailerPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__VehicleTrip__MaterialCount__CreateInput = {
  count: Scalars['Int'];
  direction: DockApi__VehicleTrip__MaterialCount__DirectionTypeEnum;
  imageUrl: Scalars['String'];
  preTripItemId: Scalars['ID'];
  vehicleTripId: Scalars['ID'];
};

/** Autogenerated return type of DockApi__VehicleTrip__MaterialCount__Create */
export type DockApi__VehicleTrip__MaterialCount__CreatePayload = {
  __typename?: 'DockApi__VehicleTrip__MaterialCount__CreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum DockApi__VehicleTrip__MaterialCount__DirectionTypeEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

/** Autogenerated return type of DockApi__VehicleTrip__MaterialCount__Trust */
export type DockApi__VehicleTrip__MaterialCount__TrustPayload = {
  __typename?: 'DockApi__VehicleTrip__MaterialCount__TrustPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DockApi__WarehouseOrders = {
  __typename?: 'DockApi__WarehouseOrders';
  completed: Array<Order>;
  inProgress: Array<Order>;
  toDo: Array<Order>;
};

export type DockApi__WarehouseWithItemCount = {
  __typename?: 'DockApi__WarehouseWithItemCount';
  itemCount: Scalars['Int'];
  warehouse?: Maybe<Warehouse>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum DocumentKindEnum {
  AppliancesPackingStandards = 'APPLIANCES_PACKING_STANDARDS',
  BedFramePackingStandards = 'BED_FRAME_PACKING_STANDARDS',
  BillOfLading = 'BILL_OF_LADING',
  BillOfLadingCaOnDemandESign = 'BILL_OF_LADING_CA_ON_DEMAND_E_SIGN',
  BillOfLadingCaOnDemandESignC = 'BILL_OF_LADING_CA_ON_DEMAND_E_SIGN_C',
  BillOfLadingIlOnDemandESign = 'BILL_OF_LADING_IL_ON_DEMAND_E_SIGN',
  BillOfLadingIlOnDemandESignC = 'BILL_OF_LADING_IL_ON_DEMAND_E_SIGN_C',
  BillOfLadingNjOnDemandESign = 'BILL_OF_LADING_NJ_ON_DEMAND_E_SIGN',
  BillOfLadingNjOnDemandESignC = 'BILL_OF_LADING_NJ_ON_DEMAND_E_SIGN_C',
  BillOfLadingNyOnDemandESign = 'BILL_OF_LADING_NY_ON_DEMAND_E_SIGN',
  BillOfLadingNyOnDemandESignC = 'BILL_OF_LADING_NY_ON_DEMAND_E_SIGN_C',
  BillOfLadingWaOnDemandESign = 'BILL_OF_LADING_WA_ON_DEMAND_E_SIGN',
  BillOfLadingWaOnDemandESignC = 'BILL_OF_LADING_WA_ON_DEMAND_E_SIGN_C',
  BoxPackingStandards = 'BOX_PACKING_STANDARDS',
  CaliforniaBillOfLading = 'CALIFORNIA_BILL_OF_LADING',
  CaliforniaEstimatedCosts = 'CALIFORNIA_ESTIMATED_COSTS',
  CompletionOfServices = 'COMPLETION_OF_SERVICES',
  CompletionOfServicesOnDemandESign = 'COMPLETION_OF_SERVICES_ON_DEMAND_E_SIGN',
  ConsumerBrochure = 'CONSUMER_BROCHURE',
  ConsumerBrochureReceipt = 'CONSUMER_BROCHURE_RECEIPT',
  CubeSheet = 'CUBE_SHEET',
  DelawareBillOfLading = 'DELAWARE_BILL_OF_LADING',
  DelawareEstimatedCosts = 'DELAWARE_ESTIMATED_COSTS',
  DelawareOrderForService = 'DELAWARE_ORDER_FOR_SERVICE',
  DisposalAndDonationAgreement = 'DISPOSAL_AND_DONATION_AGREEMENT',
  ElectronicSignature = 'ELECTRONIC_SIGNATURE',
  ElectronicSignatureCaOnDemandESign = 'ELECTRONIC_SIGNATURE_CA_ON_DEMAND_E_SIGN',
  ElectronicSignatureIlOnDemandESign = 'ELECTRONIC_SIGNATURE_IL_ON_DEMAND_E_SIGN',
  ElectronicSignatureNjOnDemandESign = 'ELECTRONIC_SIGNATURE_NJ_ON_DEMAND_E_SIGN',
  ElectronicSignatureNyOnDemandESign = 'ELECTRONIC_SIGNATURE_NY_ON_DEMAND_E_SIGN',
  ElectronicSignatureWaOnDemandESign = 'ELECTRONIC_SIGNATURE_WA_ON_DEMAND_E_SIGN',
  EstimatedCosts = 'ESTIMATED_COSTS',
  EstimatedCostsCaOnDemandESign = 'ESTIMATED_COSTS_CA_ON_DEMAND_E_SIGN',
  EstimatedCostsCaOnDemandESignC = 'ESTIMATED_COSTS_CA_ON_DEMAND_E_SIGN_C',
  EstimatedCostsIlOnDemandESign = 'ESTIMATED_COSTS_IL_ON_DEMAND_E_SIGN',
  EstimatedCostsIlOnDemandESignC = 'ESTIMATED_COSTS_IL_ON_DEMAND_E_SIGN_C',
  EstimatedCostsNjOnDemandESign = 'ESTIMATED_COSTS_NJ_ON_DEMAND_E_SIGN',
  EstimatedCostsNjOnDemandESignC = 'ESTIMATED_COSTS_NJ_ON_DEMAND_E_SIGN_C',
  EstimatedCostsNyOnDemandESign = 'ESTIMATED_COSTS_NY_ON_DEMAND_E_SIGN',
  EstimatedCostsNyOnDemandESignC = 'ESTIMATED_COSTS_NY_ON_DEMAND_E_SIGN_C',
  EstimatedCostsWaOnDemandESign = 'ESTIMATED_COSTS_WA_ON_DEMAND_E_SIGN',
  EstimatedCostsWaOnDemandESignC = 'ESTIMATED_COSTS_WA_ON_DEMAND_E_SIGN_C',
  EstimatedInventory = 'ESTIMATED_INVENTORY',
  FragilePackingStandards = 'FRAGILE_PACKING_STANDARDS',
  IllinoisBillOfLading = 'ILLINOIS_BILL_OF_LADING',
  IllinoisEstimatedCosts = 'ILLINOIS_ESTIMATED_COSTS',
  IndefiniteStorageAcknowledgment = 'INDEFINITE_STORAGE_ACKNOWLEDGMENT',
  InjuryAndDamageWaiver = 'INJURY_AND_DAMAGE_WAIVER',
  InjuryAndDamageWaiverForFacilityAppointments = 'INJURY_AND_DAMAGE_WAIVER_FOR_FACILITY_APPOINTMENTS',
  InjuryAndDamageWaiverM = 'INJURY_AND_DAMAGE_WAIVER_M',
  InterstateBillOfLading = 'INTERSTATE_BILL_OF_LADING',
  InterstateEstimatedCosts = 'INTERSTATE_ESTIMATED_COSTS',
  InterstateOrderForService = 'INTERSTATE_ORDER_FOR_SERVICE',
  InventoryForm = 'INVENTORY_FORM',
  InventoryFormOnDemandESign = 'INVENTORY_FORM_ON_DEMAND_E_SIGN',
  InventoryFormOnDemandESignC = 'INVENTORY_FORM_ON_DEMAND_E_SIGN_C',
  InventoryFormOnDemandESignCM = 'INVENTORY_FORM_ON_DEMAND_E_SIGN_C_M',
  InventoryFormOnDemandESignM = 'INVENTORY_FORM_ON_DEMAND_E_SIGN_M',
  Invoice = 'INVOICE',
  JobSignature = 'JOB_SIGNATURE',
  LayerCountingInstructions = 'LAYER_COUNTING_INSTRUCTIONS',
  MarylandBillOfLading = 'MARYLAND_BILL_OF_LADING',
  MarylandEstimatedCosts = 'MARYLAND_ESTIMATED_COSTS',
  MarylandOrderForService = 'MARYLAND_ORDER_FOR_SERVICE',
  MassachusettsBillOfLading = 'MASSACHUSETTS_BILL_OF_LADING',
  MattressPackingStandards = 'MATTRESS_PACKING_STANDARDS',
  MiscellaneousPackingStandards = 'MISCELLANEOUS_PACKING_STANDARDS',
  MovingAgreementReceipt = 'MOVING_AGREEMENT_RECEIPT',
  MovingPackingStandards = 'MOVING_PACKING_STANDARDS',
  MovingUnpackingStandards = 'MOVING_UNPACKING_STANDARDS',
  NewJerseyBillOfLading = 'NEW_JERSEY_BILL_OF_LADING',
  NewJerseyEstimatedCosts = 'NEW_JERSEY_ESTIMATED_COSTS',
  NewJerseyOrderForService = 'NEW_JERSEY_ORDER_FOR_SERVICE',
  NewYorkBillOfLading = 'NEW_YORK_BILL_OF_LADING',
  NewYorkEstimatedCosts = 'NEW_YORK_ESTIMATED_COSTS',
  NewYorkOrderForService = 'NEW_YORK_ORDER_FOR_SERVICE',
  OrderForService = 'ORDER_FOR_SERVICE',
  OrderForServiceNjOnDemandESign = 'ORDER_FOR_SERVICE_NJ_ON_DEMAND_E_SIGN',
  OrderForServiceNjOnDemandESignC = 'ORDER_FOR_SERVICE_NJ_ON_DEMAND_E_SIGN_C',
  OrderForServiceNyOnDemandESign = 'ORDER_FOR_SERVICE_NY_ON_DEMAND_E_SIGN',
  OrderForServiceNyOnDemandESignC = 'ORDER_FOR_SERVICE_NY_ON_DEMAND_E_SIGN_C',
  PennsylvaniaBillOfLading = 'PENNSYLVANIA_BILL_OF_LADING',
  PennsylvaniaEstimatedCosts = 'PENNSYLVANIA_ESTIMATED_COSTS',
  PennsylvaniaInformationForShippers = 'PENNSYLVANIA_INFORMATION_FOR_SHIPPERS',
  PermanentStorageAcknowledgment = 'PERMANENT_STORAGE_ACKNOWLEDGMENT',
  Plans = 'PLANS',
  PrivacyPolicy = 'PRIVACY_POLICY',
  ReleaseAgreement = 'RELEASE_AGREEMENT',
  ReleaseAgreementOnDemandESign = 'RELEASE_AGREEMENT_ON_DEMAND_E_SIGN',
  ReleaseAgreementOnDemandESignM = 'RELEASE_AGREEMENT_ON_DEMAND_E_SIGN_M',
  RhodeIslandBillOfLading = 'RHODE_ISLAND_BILL_OF_LADING',
  SeatingPackingStandards = 'SEATING_PACKING_STANDARDS',
  Shift = 'SHIFT',
  StorageFurniturePackingStandards = 'STORAGE_FURNITURE_PACKING_STANDARDS',
  StoragePickupInventory = 'STORAGE_PICKUP_INVENTORY',
  TableOfMeasurements = 'TABLE_OF_MEASUREMENTS',
  TablePackingStandards = 'TABLE_PACKING_STANDARDS',
  TelevisionPackingStandards = 'TELEVISION_PACKING_STANDARDS',
  TermsOfService = 'TERMS_OF_SERVICE',
  TexasBillOfLading = 'TEXAS_BILL_OF_LADING',
  TexasEstimatedCosts = 'TEXAS_ESTIMATED_COSTS',
  TexasOrderForService = 'TEXAS_ORDER_FOR_SERVICE',
  WarehouseReceipt = 'WAREHOUSE_RECEIPT',
  WarehouseReceiptCaOnDemandESign = 'WAREHOUSE_RECEIPT_CA_ON_DEMAND_E_SIGN',
  WarehouseReceiptCaOnDemandESignC = 'WAREHOUSE_RECEIPT_CA_ON_DEMAND_E_SIGN_C',
  WarehouseReceiptIlOnDemandESign = 'WAREHOUSE_RECEIPT_IL_ON_DEMAND_E_SIGN',
  WarehouseReceiptIlOnDemandESignC = 'WAREHOUSE_RECEIPT_IL_ON_DEMAND_E_SIGN_C',
  WarehouseReceiptNjOnDemandESign = 'WAREHOUSE_RECEIPT_NJ_ON_DEMAND_E_SIGN',
  WarehouseReceiptNjOnDemandESignC = 'WAREHOUSE_RECEIPT_NJ_ON_DEMAND_E_SIGN_C',
  WarehouseReceiptNyOnDemandESign = 'WAREHOUSE_RECEIPT_NY_ON_DEMAND_E_SIGN',
  WarehouseReceiptNyOnDemandESignC = 'WAREHOUSE_RECEIPT_NY_ON_DEMAND_E_SIGN_C',
  WarehouseReceiptWaOnDemandESign = 'WAREHOUSE_RECEIPT_WA_ON_DEMAND_E_SIGN',
  WarehouseReceiptWaOnDemandESignC = 'WAREHOUSE_RECEIPT_WA_ON_DEMAND_E_SIGN_C',
  WashingtonBillOfLading = 'WASHINGTON_BILL_OF_LADING',
  WashingtonEstimatedCosts = 'WASHINGTON_ESTIMATED_COSTS',
}

export type DoorAssignment = {
  __typename?: 'DoorAssignment';
  location: Location;
};

export type Draft = {
  __typename?: 'Draft';
  fields: Scalars['JSON'];
  id: Scalars['ID'];
};

export type DraftInput = {
  fields: Scalars['JSON'];
  uniqueID: Scalars['ID'];
};

export type DropOffQueryInterface = {
  dropOffAppointment?: Maybe<DropOff__Appointment>;
};

export type DropOffQueryInterfaceDropOffAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
  orderID?: InputMaybe<Scalars['ID']>;
};

export type DropOff__Access = {
  __typename?: 'DropOff__Access';
  customerCode: Scalars['String'];
  id: Scalars['ID'];
  moverCode: Scalars['String'];
};

export type DropOff__Appointment = {
  __typename?: 'DropOff__Appointment';
  access?: Maybe<DropOff__Access>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  expectedAt: Scalars['DateTime'];
  facility: DropOff__Facility;
  files: Array<ActiveStorageAttachment>;
  id: Scalars['ID'];
  state: AppointmentStateEnum;
  storageArrangement?: Maybe<DropOff__StorageArrangement>;
};

export type DropOff__Facility = {
  __typename?: 'DropOff__Facility';
  active: Scalars['Boolean'];
  address: Address;
  fulfiller?: Maybe<DropOff__Fulfiller>;
  hours: Array<Scalars['String']>;
  hoursHash: Hours;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region: Region;
  releaseAgreementUrl?: Maybe<Scalars['String']>;
  zips: Array<Scalars['String']>;
};

export type DropOff__Fulfiller = DropOff__ThirdPartySelfStorageFacility | SelfStorage__Facility;

export type DropOff__StorageArrangement = DropOff__ThirdPartySelfStorageUnit | SelfStorage__Hold;

export type DropOff__ThirdPartySelfStorageFacility = {
  __typename?: 'DropOff__ThirdPartySelfStorageFacility';
  accessCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operator: Scalars['String'];
  units: Array<DropOff__ThirdPartySelfStorageUnit>;
};

export type DropOff__ThirdPartySelfStorageUnit = {
  __typename?: 'DropOff__ThirdPartySelfStorageUnit';
  accessCode?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  length: Scalars['Float'];
  name: Scalars['String'];
  width: Scalars['Float'];
};

/** Autogenerated return type of EditAuditException */
export type EditAuditExceptionPayload = {
  __typename?: 'EditAuditExceptionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type EmailEvent = IterableEvent | SendgridEvent;

export enum EnvironmentEnum {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
}

export type Error = {
  __typename?: 'Error';
  code: ErrorCodeEnum;
  expected?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ErrorCodeEnum {
  ExistingPromoCode = 'EXISTING_PROMO_CODE',
  InvalidCouponChange = 'INVALID_COUPON_CHANGE',
  Unknown = 'UNKNOWN',
}

export type EstimatedItem = {
  __typename?: 'EstimatedItem';
  categoryDisplayName?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  cuft?: Maybe<Scalars['Int']>;
  estimatedMovers?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  length?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  width?: Maybe<Scalars['Int']>;
};

export type EstimatedItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  customCategoryName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  length?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  sizingSelection?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type EstimationCategory = {
  __typename?: 'EstimationCategory';
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numMovers?: Maybe<Scalars['Int']>;
  packable: Scalars['Boolean'];
};

export type EstimationInput = {
  categoryID: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};

export type EstimationMutationInterface = {
  estimationEstimatedItemsCreate?: Maybe<Estimation__EstimatedItemsCreatePayload>;
  estimationOverrideEstimatedCuft?: Maybe<Estimation__OverrideEstimatedCuftPayload>;
};

export type EstimationMutationInterfaceEstimationEstimatedItemsCreateArgs = {
  input: Estimation__EstimatedItemsCreateInput;
};

export type EstimationMutationInterfaceEstimationOverrideEstimatedCuftArgs = {
  estimatedCuft?: InputMaybe<Scalars['Int']>;
  orderID: Scalars['ID'];
  reason?: InputMaybe<Estimation__EstimatedCuftOverrideReason>;
};

export type EstimationQueryInterface = {
  estimationAlgorithmForOrder?: Maybe<Estimation__Algorithm>;
  estimationAlgorithms: Array<Estimation__Algorithm>;
  estimationBoxItemCategories: Array<Estimation__CategoryType>;
  estimationItems: Array<Estimation__ItemType>;
  estimationOtherItemCategories: Array<Estimation__CategoryType>;
  estimationSelectedRooms: Array<Estimation__SelectedRoomType>;
  estimationUploads: Array<Estimation__UploadType>;
  estimationsForOrder: Estimation__OrderEstimation;
  pickupMoverCount: Scalars['Int'];
};

export type EstimationQueryInterfaceEstimationAlgorithmForOrderArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationBoxItemCategoriesArgs = {
  algorithmID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationItemsArgs = {
  algorithmID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationOtherItemCategoriesArgs = {
  algorithmID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationSelectedRoomsArgs = {
  algorithmID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationUploadsArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationsForOrderArgs = {
  algorithmID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfacePickupMoverCountArgs = {
  inventory: PickupInventoryInput;
  orderID: Scalars['ID'];
};

export type EstimationSelection = {
  __typename?: 'EstimationSelection';
  category: EstimationCategory;
  categoryID: Scalars['ID'];
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type Estimation__Algorithm = {
  __typename?: 'Estimation__Algorithm';
  id: Scalars['ID'];
  name: Estimation__AlgorithmName;
};

export enum Estimation__AlgorithmName {
  MovingDisassemblyFlow = 'MOVING_DISASSEMBLY_FLOW',
  OnsiteItemization = 'ONSITE_ITEMIZATION',
  V1 = 'V1',
  V2 = 'V2',
  VirtualWalkthrough = 'VIRTUAL_WALKTHROUGH',
}

export type Estimation__CategoryThemeType = {
  __typename?: 'Estimation__CategoryThemeType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Estimation__CategoryType = {
  __typename?: 'Estimation__CategoryType';
  defaultCuft?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Estimation__CategoryThemeType>;
};

export enum Estimation__EstimatedCuftOverrideReason {
  EstimateSeemsOffBasedOnItemCategories = 'ESTIMATE_SEEMS_OFF_BASED_ON_ITEM_CATEGORIES',
  EstimateSeemsOffBasedOnNotes = 'ESTIMATE_SEEMS_OFF_BASED_ON_NOTES',
  EstimateSeemsOffBasedOnPlanSize = 'ESTIMATE_SEEMS_OFF_BASED_ON_PLAN_SIZE',
  EstimateSeemsOffBasedOnRoomCounts = 'ESTIMATE_SEEMS_OFF_BASED_ON_ROOM_COUNTS',
  EstimateSeemsOffBasedOnWalkthroughPhotos = 'ESTIMATE_SEEMS_OFF_BASED_ON_WALKTHROUGH_PHOTOS',
  EstimateSeemsOffDueToMissingItemMeasurements = 'ESTIMATE_SEEMS_OFF_DUE_TO_MISSING_ITEM_MEASUREMENTS',
  EstimateSeemsOffDueToNewItemAdditions = 'ESTIMATE_SEEMS_OFF_DUE_TO_NEW_ITEM_ADDITIONS',
  ThereIsNoInitialEstimatedCuft = 'THERE_IS_NO_INITIAL_ESTIMATED_CUFT',
}

export type Estimation__EstimatedItemInput = {
  categoryID: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Estimation__EstimatedItemsCreateInput = {
  algorithmID?: InputMaybe<Scalars['ID']>;
  boxCategoryName?: InputMaybe<Scalars['String']>;
  itemInputs: Array<Estimation__EstimatedItemInput>;
  orderID: Scalars['ID'];
  otherItemCategoryName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Estimation__EstimatedItemsCreate */
export type Estimation__EstimatedItemsCreatePayload = {
  __typename?: 'Estimation__EstimatedItemsCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Estimation__ItemType = {
  __typename?: 'Estimation__ItemType';
  category: Estimation__CategoryType;
  disassemblyQuantity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Estimation__OrderEstimation = {
  __typename?: 'Estimation__OrderEstimation';
  agentBoxEstimation?: Maybe<Estimation__OrderItemEstimation>;
  agentOtherItemEstimation?: Maybe<Estimation__OrderItemEstimation>;
  allItemsInListedRooms: Scalars['Boolean'];
  customerBoxEstimation?: Maybe<Estimation__OrderItemEstimation>;
  customerOtherItemEstimation?: Maybe<Estimation__OrderItemEstimation>;
  itemEstimations: Array<Estimation__OrderItemEstimation>;
  itemsRequireMoreThanOneMover?: Maybe<Order__Metadata__ItemsRequireMoreThanOneMover>;
  roomEstimations: Array<Estimation__OrderRoomEstimation>;
};

export type Estimation__OrderItemEstimation = {
  __typename?: 'Estimation__OrderItemEstimation';
  agentQuantity: Scalars['Int'];
  categoryID: Scalars['ID'];
  cuft?: Maybe<Scalars['Float']>;
  customerQuantity: Scalars['Int'];
  name: Scalars['String'];
};

export type Estimation__OrderRoomEstimation = {
  __typename?: 'Estimation__OrderRoomEstimation';
  categoryID: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

/** Autogenerated return type of Estimation__OverrideEstimatedCuft */
export type Estimation__OverrideEstimatedCuftPayload = {
  __typename?: 'Estimation__OverrideEstimatedCuftPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Estimation__RoomCategoryType = {
  __typename?: 'Estimation__RoomCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Estimation__SelectedRoomType = {
  __typename?: 'Estimation__SelectedRoomType';
  id: Scalars['ID'];
  packingRequested: Scalars['Boolean'];
  roomCategory: Estimation__RoomCategoryType;
};

export type Estimation__UploadMediaType = {
  __typename?: 'Estimation__UploadMediaType';
  filename: Scalars['String'];
  id: Scalars['String'];
  imgixURL: Scalars['String'];
  isImage: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  url: Scalars['String'];
};

export type Estimation__UploadType = {
  __typename?: 'Estimation__UploadType';
  id: Scalars['ID'];
  media: Estimation__UploadMediaType;
};

export enum EventNameEnum {
  AllDaySlaNarrowed = 'all_day_sla_narrowed',
  ArrivedAtDestination = 'arrived_at_destination',
  DepartedForDestination = 'departed_for_destination',
  EndOrderApproved = 'end_order_approved',
  EndOrderOverride = 'end_order_override',
  EndOrderRequested = 'end_order_requested',
  MoveLoadTruckImage = 'move_load_truck_image',
  MoveOrderPacked = 'move_order_packed',
  MoveOrderUnpacked = 'move_order_unpacked',
  OutboundFacilityOrderCompletePhoto = 'outbound_facility_order_complete_photo',
  Parked = 'parked',
  Rescheduled = 'rescheduled',
  SignedApproval = 'signed_approval',
  SlaAdjustedToLaterTime = 'sla_adjusted_to_later_time',
  StartOrderApproved = 'start_order_approved',
  StartOrderOverride = 'start_order_override',
  StartOrderRequested = 'start_order_requested',
  TapelinePhoto = 'tapeline_photo',
  TaskLaborEnded = 'task_labor_ended',
  TaskLaborStarted = 'task_labor_started',
  TaskNavigationEnded = 'task_navigation_ended',
  TaskNavigationStarted = 'task_navigation_started',
  VerifiedCustomer = 'verified_customer',
}

export type Facility = Depot | Warehouse;

export type FacilityFiltersInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  geo?: InputMaybe<ClutterGeoEnum>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type FacilityInput = {
  id: Scalars['ID'];
  type: FacilityInput__Kind;
};

export enum FacilityInput__Kind {
  Depot = 'Depot',
  Warehouse = 'Warehouse',
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resourceName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  whitelistedObjects: Array<FeatureFlagWhitelistObject>;
};

export type FeatureFlagInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  resourceName: Scalars['String'];
  whitelistedIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of FeatureFlagUpsert */
export type FeatureFlagUpsertPayload = {
  __typename?: 'FeatureFlagUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<FeatureFlag>;
  status: Status;
};

export type FeatureFlagWhitelistObject = Account | Region | User | Warehouse;

export type FieldApiMutationInterface = {
  disposalCreate: FieldApi__DisposalCreatePayload;
  restockUnexpectedItem: FieldApi__RestockUnexpectedItemPayload;
  returnUnexpectedItem: FieldApi__ReturnUnexpectedItemPayload;
};

export type FieldApiMutationInterfaceDisposalCreateArgs = {
  parentID: Scalars['ID'];
};

export type FieldApiMutationInterfaceRestockUnexpectedItemArgs = {
  input: FieldApi__RestockUnexpectedItem__Input;
};

export type FieldApiMutationInterfaceReturnUnexpectedItemArgs = {
  input: FieldApi__ReturnUnexpectedItem__Input;
};

export type FieldApiQueryInterface = {
  fieldContactReasons: Array<Field__ContactReason>;
  itemByBarcode?: Maybe<Item>;
  moverTickets: Array<Ticket>;
  ticket: Ticket;
};

export type FieldApiQueryInterfaceItemByBarcodeArgs = {
  barcodeValue: Scalars['String'];
};

export type FieldApiQueryInterfaceTicketArgs = {
  id: Scalars['ID'];
};

/** Autogenerated return type of FieldApi__DisposalCreate */
export type FieldApi__DisposalCreatePayload = {
  __typename?: 'FieldApi__DisposalCreatePayload';
  status: Status;
};

/** Autogenerated return type of FieldApi__RestockUnexpectedItem */
export type FieldApi__RestockUnexpectedItemPayload = {
  __typename?: 'FieldApi__RestockUnexpectedItemPayload';
  status: Status;
};

export type FieldApi__RestockUnexpectedItem__Input = {
  /** The item needing to be returned to the warehouse */
  itemID: Scalars['ID'];
  /** The return order being worked on when the item was discovered */
  orderID: Scalars['ID'];
};

/** Autogenerated return type of FieldApi__ReturnUnexpectedItem */
export type FieldApi__ReturnUnexpectedItemPayload = {
  __typename?: 'FieldApi__ReturnUnexpectedItemPayload';
  status: Status;
};

export type FieldApi__ReturnUnexpectedItem__Input = {
  /** The item needing to be returned to the customer */
  itemID: Scalars['ID'];
  /** The return order being worked on when the item was discovered */
  orderID: Scalars['ID'];
};

export type Field__ContactReason = {
  __typename?: 'Field__ContactReason';
  description?: Maybe<Scalars['String']>;
  faqURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentID?: Maybe<Scalars['ID']>;
  priority: Scalars['Int'];
  ticketTemplate?: Maybe<Ticket__Template>;
};

export enum ForkliftApi__InboundMoveTypeEnum {
  DockOrPndToStorage = 'DOCK_OR_PND_TO_STORAGE',
  DockToPnd = 'DOCK_TO_PND',
  PndToStorage = 'PND_TO_STORAGE',
}

export type ForkliftApi__ValidateNewInboundTaskInput = {
  moveType: ForkliftApi__InboundMoveTypeEnum;
  palletBarcodeValue: Scalars['String'];
};

export type ForkliftApiQueryInterface = {
  activePalletRequests: Array<PalletRequest>;
  currentUserActivePalletTasks: Array<PalletTask>;
  validateNewInboundTask: ValidationResponse;
};

export type ForkliftApiQueryInterfaceActivePalletRequestsArgs = {
  kind?: InputMaybe<PalletRequestKindEnum>;
  manuallyRequested?: InputMaybe<Scalars['Boolean']>;
  warehouseId: Scalars['ID'];
};

export type ForkliftApiQueryInterfaceCurrentUserActivePalletTasksArgs = {
  warehouseId: Scalars['ID'];
};

export type ForkliftApiQueryInterfaceValidateNewInboundTaskArgs = {
  input: ForkliftApi__ValidateNewInboundTaskInput;
};

/** Autogenerated return type of GenerateTestPassword */
export type GenerateTestPasswordPayload = {
  __typename?: 'GenerateTestPasswordPayload';
  error?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status: Status;
};

export type GenericCategory = Admin__ItemCategory | MaterialCategory | PartCategory;

export type Geocode = {
  __typename?: 'Geocode';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  potentiallyInvalid: Scalars['Boolean'];
  region: Region;
};

export type Geocode__Input = {
  city: Scalars['String'];
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type HrsMutationInterface = {
  hrsLocationUpsert?: Maybe<Hrs__LocationUpsertPayload>;
  hrsProfileDefaultUpsert?: Maybe<Hrs__ProfileDefaultUpsertPayload>;
  hrsRoleDefaultUpsert?: Maybe<Hrs__RoleDefaultUpsertPayload>;
};

export type HrsMutationInterfaceHrsLocationUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__LocationInput;
};

export type HrsMutationInterfaceHrsProfileDefaultUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__ProfileDefaultInput;
};

export type HrsMutationInterfaceHrsRoleDefaultUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__RoleDefaultInput;
};

export type HrsQueryInterface = {
  hrsBusinessTitles: Array<Scalars['String']>;
  hrsJobTitles: Array<Hrs__JobTitleType>;
  hrsLocations: Array<Hrs__Location>;
  hrsProfileDefaults: Array<Hrs__ProfileDefault>;
  hrsRoleDefaults: Array<Hrs__RoleDefault>;
};

export type Hrs__JobTitleType = {
  __typename?: 'HRS__JobTitleType';
  code: Scalars['String'];
  title: Scalars['String'];
};

export type Hrs__Location = {
  __typename?: 'HRS__Location';
  code: Scalars['String'];
  id: Scalars['ID'];
  region?: Maybe<Region>;
};

export type Hrs__LocationInput = {
  code: Scalars['String'];
  regionID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of HRS__LocationUpsert */
export type Hrs__LocationUpsertPayload = {
  __typename?: 'HRS__LocationUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Hrs__Location>;
  status: Status;
};

export type Hrs__ProfileDefault = {
  __typename?: 'HRS__ProfileDefault';
  businessTitle: Scalars['String'];
  id: Scalars['ID'];
  location?: Maybe<Hrs__Location>;
  orgLevel1?: Maybe<Scalars['String']>;
  orgLevel2?: Maybe<Scalars['String']>;
  orgLevel3?: Maybe<Scalars['String']>;
  orgLevel4?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
};

export type Hrs__ProfileDefaultInput = {
  businessTitle: Scalars['String'];
  locationID?: InputMaybe<Scalars['ID']>;
  orgLevel1?: InputMaybe<Scalars['String']>;
  orgLevel2?: InputMaybe<Scalars['String']>;
  orgLevel3?: InputMaybe<Scalars['String']>;
  orgLevel4?: InputMaybe<Scalars['String']>;
  teamID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of HRS__ProfileDefaultUpsert */
export type Hrs__ProfileDefaultUpsertPayload = {
  __typename?: 'HRS__ProfileDefaultUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Hrs__ProfileDefault>;
  status: Status;
};

export type Hrs__RoleDefault = {
  __typename?: 'HRS__RoleDefault';
  businessTitle: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<User__Role>;
};

export type Hrs__RoleDefaultInput = {
  businessTitle: Scalars['String'];
  roles: Array<User__Role>;
};

/** Autogenerated return type of HRS__RoleDefaultUpsert */
export type Hrs__RoleDefaultUpsertPayload = {
  __typename?: 'HRS__RoleDefaultUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Hrs__RoleDefault>;
  status: Status;
};

export type History = {
  __typename?: 'History';
  resource: History__Resource;
  tag?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export type History__Resource = Changed__Audit | LaborClock | MarketingEvent | Note | PhoneCall | Subscribe | Usage;

export enum History__Subject {
  Account = 'ACCOUNT',
  Address = 'ADDRESS',
  Affiliate = 'AFFILIATE',
  Claim = 'CLAIM',
  Coupon = 'COUPON',
  CustomerTicket = 'CUSTOMER_TICKET',
  Dispatch = 'DISPATCH',
  Incident = 'INCIDENT',
  Item = 'ITEM',
  ItemGroup = 'ITEM_GROUP',
  Lead = 'LEAD',
  Location = 'LOCATION',
  LogisticsLoad = 'LOGISTICS_LOAD',
  LogisticsSku = 'LOGISTICS_SKU',
  Order = 'ORDER',
  Pallet = 'PALLET',
  PaymentPlanOffer = 'PAYMENT_PLAN_OFFER',
  PhoneCall = 'PHONE_CALL',
  Redirect = 'REDIRECT',
  Region = 'REGION',
  SelfStorageFacility = 'SELF_STORAGE_FACILITY',
  SelfStorageReservation = 'SELF_STORAGE_RESERVATION',
  SelfStorageUnit = 'SELF_STORAGE_UNIT',
  Shift = 'SHIFT',
  Ticket = 'TICKET',
  Transfer = 'TRANSFER',
  User = 'USER',
  Vehicle = 'VEHICLE',
  Warehouse = 'WAREHOUSE',
}

export type History__SubjectInput = {
  id: Scalars['ID'];
  type: History__Subject;
};

export type Hours = {
  __typename?: 'Hours';
  friday: OpenClose;
  monday: OpenClose;
  saturday: OpenClose;
  sunday: OpenClose;
  thursday: OpenClose;
  tuesday: OpenClose;
  wednesday: OpenClose;
};

export enum IrmClusterEnum {
  Canada = 'CANADA',
  East = 'EAST',
  West = 'WEST',
}

export enum IrmTerritoryEnum {
  California = 'CALIFORNIA',
  CanadaCentral = 'CANADA_CENTRAL',
  Gateway = 'GATEWAY',
  GreatLakes = 'GREAT_LAKES',
  MidAtlantic = 'MID_ATLANTIC',
  MidSouth = 'MID_SOUTH',
  MountainWest = 'MOUNTAIN_WEST',
  Northeast = 'NORTHEAST',
  NyMetro = 'NY_METRO',
  Southeast = 'SOUTHEAST',
  Texas = 'TEXAS',
}

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  source: Scalars['String'];
  state: ImageState;
};

export type ImageAttributesInput = {
  url: Scalars['String'];
};

export enum ImageState {
  /** INITIALIZED */
  Initialized = 'initialized',
  /** REMOVED */
  Removed = 'removed',
  /** TRANSFERRING */
  Transferring = 'transferring',
  /** UPLOADED */
  Uploaded = 'uploaded',
}

/** Autogenerated return type of ImpersonateCustomerLinkCreate */
export type ImpersonateCustomerLinkCreatePayload = {
  __typename?: 'ImpersonateCustomerLinkCreatePayload';
  url: Scalars['String'];
};

export type Incident = {
  __typename?: 'Incident';
  createdAt: Scalars['DateTime'];
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  kind: IncidentKindEnum;
  lastKnownLocation?: Maybe<Location>;
  resolution?: Maybe<Scalars['String']>;
  state: IncidentStateEnum;
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum IncidentKindEnum {
  Auction = 'AUCTION',
  Damaged = 'DAMAGED',
  Duplicate = 'DUPLICATE',
  Inaccessible = 'INACCESSIBLE',
  Missing = 'MISSING',
  NotRouted = 'NOT_ROUTED',
  NoScanReturn = 'NO_SCAN_RETURN',
  Ped = 'PED',
}

export enum IncidentStateEnum {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type IncludedPart = {
  __typename?: 'IncludedPart';
  category: IncludedPart__Category;
  id: Scalars['ID'];
  resource: IncludedPart__Resource;
};

export type IncludedPart__Category = ItemCategory | MaterialCategory | PartCategory;

export type IncludedPart__Resource = Item | PartialItem;

export type IngestionMutationInterface = {
  ingestionUploadSave: Ingestion__Upload__SavePayload;
};

export type IngestionMutationInterfaceIngestionUploadSaveArgs = {
  input: Ingestion__UploadInput;
};

export type IngestionQueryInterface = {
  ingestionUpload: Ingestion__Upload;
  paginatedIngestionUploads: Ingestion__PaginatedUpload;
};

export type IngestionQueryInterfaceIngestionUploadArgs = {
  id: Scalars['ID'];
};

export type IngestionQueryInterfacePaginatedIngestionUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type Ingestion__PaginatedUpload = {
  __typename?: 'Ingestion__PaginatedUpload';
  pagination: Pagination;
  results: Array<Ingestion__Upload>;
};

export type Ingestion__Upload = {
  __typename?: 'Ingestion__Upload';
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  processingAt?: Maybe<Scalars['DateTime']>;
  spreadsheet: ActiveStorageAttachment;
  state: Ingestion__Upload__State;
  user: User;
};

export type Ingestion__UploadInput = {
  description: Scalars['String'];
  spreadsheetID: Scalars['ID'];
};

/** Autogenerated return type of Ingestion__Upload__Save */
export type Ingestion__Upload__SavePayload = {
  __typename?: 'Ingestion__Upload__SavePayload';
  status: Status;
  upload?: Maybe<Ingestion__Upload>;
};

export enum Ingestion__Upload__State {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export type InteractableUnion = PhoneCall;

export type InventoryMetrics = {
  __typename?: 'InventoryMetrics';
  cuftUsed: Scalars['Float'];
  staleUsage: Scalars['Boolean'];
  unsizedItemsCount: Scalars['Int'];
};

export type Item = {
  __typename?: 'Item';
  account: Account;
  accountID: Scalars['ID'];
  active: Scalars['Boolean'];
  barcode: Barcode;
  category?: Maybe<ItemCategory>;
  contents?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuft?: Maybe<Scalars['Float']>;
  customCategoryName?: Maybe<Scalars['String']>;
  dimensionsOverwritten: Scalars['Boolean'];
  /** @deprecated Items should not have any enterprise relations. Pallet Inventories should be used to track enterprise items. */
  fulfillmentExpectation?: Maybe<Logistics__FulfillmentExpectation>;
  group?: Maybe<ItemGroup>;
  height?: Maybe<Scalars['Int']>;
  hero: Scalars['Int'];
  id: Scalars['ID'];
  images: Array<Image>;
  includedParts: Array<IncludedPart>;
  latestOpenIncidentKind?: Maybe<IncidentKindEnum>;
  length?: Maybe<Scalars['Int']>;
  materialCategory?: Maybe<MaterialCategory>;
  name?: Maybe<Scalars['String']>;
  numMovers?: Maybe<Scalars['Int']>;
  packaging?: Maybe<ItemPackagingEnum>;
  pallet?: Maybe<Pallet>;
  partCategory?: Maybe<PartCategory>;
  selectableForReturn: Scalars['Boolean'];
  sizingSelection?: Maybe<Scalars['String']>;
  /** @deprecated Items should not have any enterprise sku relations. Pallet Inventories should be used to track enterprise items. */
  sku?: Maybe<Logistics__Sku>;
  state: ItemStateEnum;
  upcomingDispatchLabel?: Maybe<Scalars['String']>;
  upcomingReturnDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Warehouse>;
  warehouseID?: Maybe<Scalars['ID']>;
  width?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ItemActivate */
export type ItemActivatePayload = {
  __typename?: 'ItemActivatePayload';
  item: Item;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  categoryDefaults?: Maybe<ItemCategoryDefault>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  moveAndPackVisible: Scalars['Boolean'];
  name: Scalars['String'];
  packingStandard?: Maybe<PackingStandard>;
  parentID?: Maybe<Scalars['ID']>;
  requiredPlanID?: Maybe<Scalars['ID']>;
  sizingMetric?: Maybe<SizingMetric>;
  synonyms?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type ItemCategoryDefault = {
  __typename?: 'ItemCategoryDefault';
  defaults: Array<ItemCategoryDefaultValue>;
};

export type ItemCategoryDefaultValue = {
  __typename?: 'ItemCategoryDefaultValue';
  cuft: Scalars['Float'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  numMovers?: Maybe<Scalars['Int']>;
  numMoversIfDisassembled?: Maybe<Scalars['Int']>;
  numMoversWithDolly?: Maybe<Scalars['Int']>;
  sizingSelection: Scalars['String'];
  width: Scalars['Float'];
};

/** Autogenerated return type of ItemDeactivate */
export type ItemDeactivatePayload = {
  __typename?: 'ItemDeactivatePayload';
  item: Item;
};

export type ItemException = {
  __typename?: 'ItemException';
  createdAt: Scalars['DateTime'];
  creator: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  reason: Scalars['String'];
};

export type ItemGroup = {
  __typename?: 'ItemGroup';
  additionalImages: Array<Image>;
  category: ItemCategory;
  height?: Maybe<Scalars['Int']>;
  heroImage: Image;
  id: Scalars['ID'];
  items: Array<Item>;
  length?: Maybe<Scalars['Int']>;
  partialItems: Array<PartialItem>;
  sizingSelection?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ItemManualReturn */
export type ItemManualReturnPayload = {
  __typename?: 'ItemManualReturnPayload';
  item: Item;
};

export enum ItemPackagingEnum {
  Boxed = 'BOXED',
  Crated = 'CRATED',
  Wrapped = 'WRAPPED',
}

export enum ItemStateEnum {
  Auctioned = 'AUCTIONED',
  Disposed = 'DISPOSED',
  Enroute = 'ENROUTE',
  Flagged = 'FLAGGED',
  Lost = 'LOST',
  OnTrailer = 'ON_TRAILER',
  Packed = 'PACKED',
  Prepared = 'PREPARED',
  Pulled = 'PULLED',
  Returned = 'RETURNED',
  Stored = 'STORED',
}

export type ItemTransferRequest = {
  __typename?: 'ItemTransferRequest';
  destination: Warehouse;
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  item: Item;
  origin: Warehouse;
  transfer?: Maybe<Transfer>;
  user: User;
};

export type IterableEvent = {
  __typename?: 'IterableEvent';
  event: Scalars['String'];
  id: Scalars['ID'];
  sender: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export enum LaborBillingFormatEnum {
  PerAppointmentHour = 'per_appointment_hour',
  PerMoverHour = 'per_mover_hour',
}

export type LaborClock = {
  __typename?: 'LaborClock';
  ended?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  orderID: Scalars['ID'];
  role: Assignment__Role;
  started?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type LaborClockInput = {
  ended?: InputMaybe<Scalars['DateTime']>;
  started?: InputMaybe<Scalars['DateTime']>;
};

export type LaborPolicy = {
  __typename?: 'LaborPolicy';
  id: Scalars['ID'];
  laborBillingFormat: LaborBillingFormatEnum;
  laborRate: LaborRate;
  perItemPricing: Scalars['Boolean'];
};

export type LaborRate = {
  __typename?: 'LaborRate';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LandingLocationMutationInterface = {
  landingLocationCityUpsert?: Maybe<Landing__Location__CityUpsertPayload>;
  landingLocationContentUpsert?: Maybe<Landing__Location__ContentUpsertPayload>;
  landingLocationNeighborhoodUpsert?: Maybe<Landing__Location__NeighborhoodUpsertPayload>;
  landingLocationRouteUpsert?: Maybe<Landing__Location__RouteUpsertPayload>;
  landingLocationStateUpsert?: Maybe<Landing__Location__StateUpsertPayload>;
};

export type LandingLocationMutationInterfaceLandingLocationCityUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__CityInput;
};

export type LandingLocationMutationInterfaceLandingLocationContentUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__ContentInput;
};

export type LandingLocationMutationInterfaceLandingLocationNeighborhoodUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__NeighborhoodInput;
};

export type LandingLocationMutationInterfaceLandingLocationRouteUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__RouteInput;
};

export type LandingLocationMutationInterfaceLandingLocationStateUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__StateInput;
};

export type LandingLocationQueryInterface = {
  landingLocationCities: Array<Landing__Location__City>;
  landingLocationCity: Landing__Location__City;
  landingLocationContent: Landing__Location__Content;
  landingLocationNeighborhood: Landing__Location__Neighborhood;
  landingLocationNeighborhoods: Array<Landing__Location__Neighborhood>;
  landingLocationRoute: Landing__Location__Route;
  landingLocationState: Landing__Location__State;
  landingLocationStates: Array<Landing__Location__State>;
  paginatedLandingLocationCities: Landing__Location__PaginatedCity;
  paginatedLandingLocationNeighborhoods: Landing__Location__PaginatedNeighborhood;
  paginatedLandingLocationRoutes: Landing__Location__PaginatedRoute;
  paginatedLandingLocationStates: Landing__Location__PaginatedState;
};

export type LandingLocationQueryInterfaceLandingLocationCityArgs = {
  id: Scalars['ID'];
};

export type LandingLocationQueryInterfaceLandingLocationContentArgs = {
  id: Scalars['ID'];
};

export type LandingLocationQueryInterfaceLandingLocationNeighborhoodArgs = {
  id: Scalars['ID'];
};

export type LandingLocationQueryInterfaceLandingLocationRouteArgs = {
  id: Scalars['ID'];
};

export type LandingLocationQueryInterfaceLandingLocationStateArgs = {
  id: Scalars['ID'];
};

export type LandingLocationQueryInterfacePaginatedLandingLocationCitiesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type LandingLocationQueryInterfacePaginatedLandingLocationNeighborhoodsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type LandingLocationQueryInterfacePaginatedLandingLocationRoutesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type LandingLocationQueryInterfacePaginatedLandingLocationStatesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type Landing__Location__City = {
  __typename?: 'Landing__Location__City';
  contents: Array<Landing__Location__Content>;
  globalID: Scalars['SignedGlobalID'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  neighborhoods: Array<Landing__Location__Neighborhood>;
  region: Region;
  services: Array<Landing__Location__Service>;
  slug: Scalars['ID'];
  state: Landing__Location__State;
};

export type Landing__Location__CityInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  regionID: Scalars['ID'];
  slug: Scalars['String'];
  stateID: Scalars['ID'];
};

/** Autogenerated return type of Landing__Location__CityUpsert */
export type Landing__Location__CityUpsertPayload = {
  __typename?: 'Landing__Location__CityUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Landing__Location__City>;
  status: Status;
};

export type Landing__Location__Content = {
  __typename?: 'Landing__Location__Content';
  blurb: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  heroAlt?: Maybe<Scalars['String']>;
  heroImage?: Maybe<ActiveStorageAttachment>;
  id: Scalars['ID'];
  reviews: Array<Landing__Location__Review>;
  service: Landing__Location__Service;
  title?: Maybe<Scalars['String']>;
};

export type Landing__Location__ContentInput = {
  blurb: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  heroAlt?: InputMaybe<Scalars['String']>;
  heroImage?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** a signed location (city / neighborhood) ID */
  locationID?: InputMaybe<Scalars['SignedGlobalID']>;
  reviews: Array<Landing__Location__ReviewInput>;
  service: Landing__Location__Service;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Landing__Location__ContentUpsert */
export type Landing__Location__ContentUpsertPayload = {
  __typename?: 'Landing__Location__ContentUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Landing__Location__Content>;
  status: Status;
};

export enum Landing__Location__Country {
  Ca = 'CA',
  Us = 'US',
}

export type Landing__Location__Neighborhood = {
  __typename?: 'Landing__Location__Neighborhood';
  city: Landing__Location__City;
  contents: Array<Landing__Location__Content>;
  globalID: Scalars['SignedGlobalID'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['ID'];
};

export type Landing__Location__NeighborhoodInput = {
  cityID: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** Autogenerated return type of Landing__Location__NeighborhoodUpsert */
export type Landing__Location__NeighborhoodUpsertPayload = {
  __typename?: 'Landing__Location__NeighborhoodUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Landing__Location__Neighborhood>;
  status: Status;
};

export type Landing__Location__PaginatedCity = {
  __typename?: 'Landing__Location__PaginatedCity';
  pagination: Pagination;
  results: Array<Landing__Location__City>;
};

export type Landing__Location__PaginatedNeighborhood = {
  __typename?: 'Landing__Location__PaginatedNeighborhood';
  pagination: Pagination;
  results: Array<Landing__Location__Neighborhood>;
};

export type Landing__Location__PaginatedRoute = {
  __typename?: 'Landing__Location__PaginatedRoute';
  pagination: Pagination;
  results: Array<Landing__Location__Route>;
};

export type Landing__Location__PaginatedState = {
  __typename?: 'Landing__Location__PaginatedState';
  pagination: Pagination;
  results: Array<Landing__Location__State>;
};

export type Landing__Location__Review = {
  __typename?: 'Landing__Location__Review';
  author: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  rating: Scalars['Int'];
};

export type Landing__Location__ReviewInput = {
  author: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
  rating: Scalars['Int'];
};

export type Landing__Location__Route = {
  __typename?: 'Landing__Location__Route';
  contents: Array<Landing__Location__Content>;
  destination: RouteLocation;
  featured: Scalars['Boolean'];
  globalID: Scalars['SignedGlobalID'];
  id: Scalars['ID'];
  origin: RouteLocation;
  slug: Scalars['ID'];
};

export type Landing__Location__RouteInput = {
  destination: Landing__Location__RouteLocationInput;
  featured: Scalars['Boolean'];
  origin: Landing__Location__RouteLocationInput;
  slug: Scalars['String'];
};

export type Landing__Location__RouteLocationInput = {
  id: Scalars['ID'];
  kind: Landing__Location__RouteLocationKind;
};

export enum Landing__Location__RouteLocationKind {
  City = 'CITY',
  Neighborhood = 'NEIGHBORHOOD',
  State = 'STATE',
}

/** Autogenerated return type of Landing__Location__RouteUpsert */
export type Landing__Location__RouteUpsertPayload = {
  __typename?: 'Landing__Location__RouteUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Landing__Location__Route>;
  status: Status;
};

export enum Landing__Location__Service {
  Moving = 'MOVING',
  SelfStorage = 'SELF_STORAGE',
  SmartStorage = 'SMART_STORAGE',
}

export type Landing__Location__State = {
  __typename?: 'Landing__Location__State';
  cities: Array<Landing__Location__City>;
  code: Scalars['String'];
  country: Landing__Location__Country;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['ID'];
};

export type Landing__Location__StateInput = {
  code: Scalars['String'];
  country: Landing__Location__Country;
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** Autogenerated return type of Landing__Location__StateUpsert */
export type Landing__Location__StateUpsertPayload = {
  __typename?: 'Landing__Location__StateUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<Landing__Location__State>;
  status: Status;
};

export type Lead = {
  __typename?: 'Lead';
  accountID?: Maybe<Scalars['ID']>;
  closeCrmLink?: Maybe<Scalars['String']>;
  closeLeadID?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latestPhoneCall?: Maybe<PhoneCall>;
  name?: Maybe<Scalars['String']>;
  objectionReason?: Maybe<Scalars['String']>;
  phone?: Maybe<Phone>;
  shortDisplay: Scalars['String'];
  status: Lead__Status;
  visitors: Array<Wt__VisitorType>;
  zip?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LeadCreate */
export type LeadCreatePayload = {
  __typename?: 'LeadCreatePayload';
  error?: Maybe<Scalars['String']>;
  lead?: Maybe<Lead>;
  status: Status;
};

export type LeadFiltersInput = {
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Lead__Status>;
};

export type LeadInteraction = {
  __typename?: 'LeadInteraction';
  id: Scalars['ID'];
  interactable: InteractableUnion;
  interactableType: Scalars['String'];
};

export enum Lead__ServiceNeeds {
  Containers = 'CONTAINERS',
  Moving = 'MOVING',
  SelfStorage = 'SELF_STORAGE',
  SmartStorage = 'SMART_STORAGE',
}

export enum Lead__Status {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Disqualified = 'DISQUALIFIED',
  Lost = 'LOST',
  Qualified = 'QUALIFIED',
  Reserved = 'RESERVED',
}

export enum LoadTypeEnum {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export type LocatablePoint = {
  __typename?: 'LocatablePoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Location = {
  __typename?: 'Location';
  aisle?: Maybe<Scalars['Int']>;
  bay?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  damaged?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  directableKind?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  elevation?: Maybe<Scalars['Int']>;
  floor: Scalars['Boolean'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inactive?: Maybe<Scalars['Boolean']>;
  isFull?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  row?: Maybe<Scalars['Int']>;
  stack?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
  warehouse: Warehouse;
};

export type LogisticsMutationInterface = {
  addDoorAssignmentToLoad: Logistics__AddDoorAssignmentToLoadPayload;
  addPalletBarcodedItems: Logistics__AddPalletBarcodedItemsPayload;
  addPalletItems: Logistics__AddPalletItemsPayload;
  assignLoadPallet: Logistics__AssignLoadPalletPayload;
  attachPalletLabel: Logistics__AttachPalletLabelPayload;
  buildFulfillmentExpectation: Logistics__BuildFulfillmentExpectationPayload;
  buildLogisticsAccount: Logistics__BuildAccountPayload;
  buildLogisticsLoad: Logistics__BuildLoadPayload;
  buildLogisticsPurchaseOrder: Logistics__BuildPurchaseOrderPayload;
  buildLogisticsSku: Logistics__BuildSkuPayload;
  buildParcelCarrierAccount: Logistics__BuildParcelCarrierAccountPayload;
  cancelLogisticsLoad: Logistics__CancelLoadPayload;
  completeLoad: Logistics__CompleteLoadPayload;
  deletePalletItems: Logistics__DeletePalletItemsPayload;
  inboundLoadImageUpdate: Logistics__InboundLoadImageUpdatePayload;
  inboundLoadUnloadTeamCreate: Logistics__InboundLoadUnloadTeamCreatePayload;
  modifyLogisticsLoad: Logistics__ModifyLoadPayload;
  modifyLogisticsPurchaseOrder: Logistics__ModifyPurchaseOrderPayload;
  modifyLogisticsSku: Logistics__ModifySkuPayload;
  modifyParcelCarrierAccount: Logistics__ModifyParcelCarrierAccountPayload;
  moveItems: Logistics__MoveItemsPayload;
  moveItemsByCount: Logistics__MoveItemsByCountPayload;
  movePalletFromTrailer: Logistics__MovePalletFromTrailerPayload;
  movePalletToLoading: Logistics__MovePalletToLoadingPayload;
  movePalletToTrailer: Logistics__MovePalletToTrailerPayload;
  removeDoorAssignmentFromLoad: Logistics__RemoveDoorAssignmentFromLoadPayload;
  reopenLogisticsLoad: Logistics__ReopenLoadPayload;
  reteachLogisticsSku: Logistics__ReteachSkuPayload;
  skuBulkUpload: Logistics__Sku__BulkUploadPayload;
  skuDelete: Logistics__SkuDeletePayload;
  skuMerge: Logistics__SkuMergePayload;
  skuOptOutBuild: Logistics__SkuOptOutBuildPayload;
  updateLoadImages: Logistics__UpdateLoadImagesPayload;
  updateLoadTextField: Logistics__UpdateLoadTextFieldPayload;
  updateLogisticsSkuImage: Logistics__UpdateSkuImagePayload;
  updateLogisticsSkuImages: Logistics__UpdateSkuImagesPayload;
  updateOutboundLoadImage: Logistics__UpdateOutboundLoadImagePayload;
  updatePalletImage: Logistics__UpdatePalletImagePayload;
  updatePalletWeight: Logistics__UpdatePalletWeightPayload;
};

export type LogisticsMutationInterfaceAddDoorAssignmentToLoadArgs = {
  input: Logistics__AddDoorAssignmentToLoadInput;
};

export type LogisticsMutationInterfaceAddPalletBarcodedItemsArgs = {
  input: Logistics__AddPalletBarcodedItemsInput;
};

export type LogisticsMutationInterfaceAddPalletItemsArgs = {
  input: Logistics__AddPalletItemsInput;
};

export type LogisticsMutationInterfaceAssignLoadPalletArgs = {
  loadID: Scalars['ID'];
  palletBarcode: Scalars['String'];
  unassign?: InputMaybe<Scalars['Boolean']>;
};

export type LogisticsMutationInterfaceAttachPalletLabelArgs = {
  input: Logistics__AttachPalletLabelInput;
};

export type LogisticsMutationInterfaceBuildFulfillmentExpectationArgs = {
  input: Logistics__FulfillmentExpectationInputType;
};

export type LogisticsMutationInterfaceBuildLogisticsAccountArgs = {
  input: Logistics__AccountInput;
};

export type LogisticsMutationInterfaceBuildLogisticsLoadArgs = {
  input: Logistics__LoadInputType;
};

export type LogisticsMutationInterfaceBuildLogisticsPurchaseOrderArgs = {
  input: Logistics__PurchaseOrderInput;
};

export type LogisticsMutationInterfaceBuildLogisticsSkuArgs = {
  input: Logistics__BuildSkuInput;
};

export type LogisticsMutationInterfaceBuildParcelCarrierAccountArgs = {
  input: Logistics__ParcelCarrierAccountInput;
};

export type LogisticsMutationInterfaceCancelLogisticsLoadArgs = {
  input: Logistics__CancelLoadInput;
};

export type LogisticsMutationInterfaceCompleteLoadArgs = {
  input: Logistics__CompleteLoadInput;
};

export type LogisticsMutationInterfaceDeletePalletItemsArgs = {
  input: Logistics__DeletePalletItemsInput;
};

export type LogisticsMutationInterfaceInboundLoadImageUpdateArgs = {
  input: Logistics__InboundLoadImageUpdateInput;
};

export type LogisticsMutationInterfaceInboundLoadUnloadTeamCreateArgs = {
  input: Logistics__InboundLoadUnloadTeamCreateInput;
};

export type LogisticsMutationInterfaceModifyLogisticsLoadArgs = {
  input: Logistics__LoadInputType;
};

export type LogisticsMutationInterfaceModifyLogisticsPurchaseOrderArgs = {
  input: Logistics__PurchaseOrderInput;
};

export type LogisticsMutationInterfaceModifyLogisticsSkuArgs = {
  input: Logistics__ModifySkuInput;
};

export type LogisticsMutationInterfaceModifyParcelCarrierAccountArgs = {
  input: Logistics__ParcelCarrierAccountInput;
};

export type LogisticsMutationInterfaceMoveItemsArgs = {
  input: Logistics__MoveItemsInput;
};

export type LogisticsMutationInterfaceMoveItemsByCountArgs = {
  input: Logistics__MoveItemsByCountInput;
};

export type LogisticsMutationInterfaceMovePalletFromTrailerArgs = {
  input: Logistics__MovePalletFromTrailerInput;
};

export type LogisticsMutationInterfaceMovePalletToLoadingArgs = {
  input: Logistics__MovePalletToLoadingInput;
};

export type LogisticsMutationInterfaceMovePalletToTrailerArgs = {
  input: Logistics__MovePalletToTrailerInput;
};

export type LogisticsMutationInterfaceRemoveDoorAssignmentFromLoadArgs = {
  input: Logistics__RemoveDoorAssignmentFromLoadInput;
};

export type LogisticsMutationInterfaceReopenLogisticsLoadArgs = {
  id: Scalars['ID'];
};

export type LogisticsMutationInterfaceReteachLogisticsSkuArgs = {
  id: Scalars['ID'];
};

export type LogisticsMutationInterfaceSkuBulkUploadArgs = {
  input: Logistics__Sku__BulkUploadInput;
};

export type LogisticsMutationInterfaceSkuDeleteArgs = {
  skuID: Scalars['ID'];
};

export type LogisticsMutationInterfaceSkuMergeArgs = {
  duplicateSkuID: Scalars['ID'];
  skuID: Scalars['ID'];
};

export type LogisticsMutationInterfaceSkuOptOutBuildArgs = {
  input: Logistics__Sku__OptOutBuildInputType;
};

export type LogisticsMutationInterfaceUpdateLoadImagesArgs = {
  input: Logistics__UpdateLoadImagesInput;
};

export type LogisticsMutationInterfaceUpdateLoadTextFieldArgs = {
  input: Logistics__UpdateLoadTextFieldInput;
};

export type LogisticsMutationInterfaceUpdateLogisticsSkuImageArgs = {
  input: Logistics__UpdateSkuImageInput;
};

export type LogisticsMutationInterfaceUpdateLogisticsSkuImagesArgs = {
  input: Logistics__UpdateSkuImagesInput;
};

export type LogisticsMutationInterfaceUpdateOutboundLoadImageArgs = {
  input: Logistics__UpdateOutboundLoadImageInput;
};

export type LogisticsMutationInterfaceUpdatePalletImageArgs = {
  input: Logistics__UpdatePalletImageInput;
};

export type LogisticsMutationInterfaceUpdatePalletWeightArgs = {
  input: Logistics__UpdatePalletWeightInput;
};

export type LogisticsQueryInterface = {
  duplicateLoadNumbers: Array<Logistics__Load>;
  fulfillmentExpectations: Array<Logistics__FulfillmentExpectation>;
  insufficientSkuInventory: Array<Logistics__InsufficientSkuInventoryType>;
  insufficientSkuTruckCuft: Logistics__InsufficientSkuTruckCuftType;
  logisticsAccounts: Array<Account>;
  logisticsAvailableWarehouseWorkers: Array<Logistics__WarehouseWorker>;
  logisticsLoad: Logistics__Load;
  logisticsLoadInventory: Array<Pallet>;
  logisticsLoads: Array<Logistics__Load>;
  logisticsOptimalPalletComposition: Logistics__OptimalPalletComposition;
  logisticsOutboundTaskList: Logistics__OutboundTaskListType;
  logisticsOutboundValidateNewTask: ValidationResponse;
  logisticsPurchaseOrder: Logistics__PurchaseOrder;
  logisticsPurchaseOrders: Array<Logistics__PurchaseOrder>;
  logisticsSku: Logistics__Sku;
  logisticsSkuBulkUploadPreview: Logistics__Sku__BulkUploadPreview;
  logisticsSkuGtinSearch?: Maybe<Logistics__Sku>;
  logisticsSkuUpcSearch?: Maybe<Logistics__Sku>;
  logisticsSkus: Array<Logistics__Sku>;
  logisticsTicketTemplates: Array<Logistics__TicketTemplate>;
  logisticsValidateItemMoveScan: ValidationResponse;
  paginatedLoads: Logistics__PaginatedLoadListType;
  paginatedLogisticsPurchaseOrders: Logistics__PaginatedPurchaseOrder;
  paginatedLogisticsSkus: Logistics__PaginatedSku;
  pallet: Pallet;
  palletByBarcode: Pallet;
  parcelCarrierAccounts: Array<Logistics__ParcelCarrierAccount>;
};

export type LogisticsQueryInterfaceDuplicateLoadNumbersArgs = {
  loadNumber: Scalars['String'];
};

export type LogisticsQueryInterfaceFulfillmentExpectationsArgs = {
  fulfillmentID: Scalars['ID'];
  fulfillmentType: Scalars['String'];
};

export type LogisticsQueryInterfaceInsufficientSkuInventoryArgs = {
  input: Logistics__InsufficientSkuInventoryInput;
};

export type LogisticsQueryInterfaceInsufficientSkuTruckCuftArgs = {
  input: Logistics__InsufficientSkuTruckCuftInput;
};

export type LogisticsQueryInterfaceLogisticsAvailableWarehouseWorkersArgs = {
  input: Logistics__AvailableWarehouseWorkersInput;
};

export type LogisticsQueryInterfaceLogisticsLoadArgs = {
  id: Scalars['ID'];
};

export type LogisticsQueryInterfaceLogisticsLoadInventoryArgs = {
  id: Scalars['ID'];
};

export type LogisticsQueryInterfaceLogisticsLoadsArgs = {
  input: Logistics__LoadsFilterInputType;
};

export type LogisticsQueryInterfaceLogisticsOptimalPalletCompositionArgs = {
  input: Logistics__OptimalPalletCompositionInput;
};

export type LogisticsQueryInterfaceLogisticsOutboundTaskListArgs = {
  input: Logistics__OutboundTaskList__InputType;
};

export type LogisticsQueryInterfaceLogisticsOutboundValidateNewTaskArgs = {
  input: Logistics__OutboundTaskList__ValidateNewTaskInputType;
};

export type LogisticsQueryInterfaceLogisticsPurchaseOrderArgs = {
  id: Scalars['ID'];
};

export type LogisticsQueryInterfaceLogisticsSkuArgs = {
  id: Scalars['ID'];
};

export type LogisticsQueryInterfaceLogisticsSkuBulkUploadPreviewArgs = {
  input: Logistics__Sku__BulkUploadPreviewInput;
};

export type LogisticsQueryInterfaceLogisticsSkuGtinSearchArgs = {
  gtinValue: Scalars['String'];
};

export type LogisticsQueryInterfaceLogisticsSkuUpcSearchArgs = {
  upcValue: Scalars['String'];
};

export type LogisticsQueryInterfaceLogisticsSkusArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Logistics__SkusFilterInputType>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LogisticsQueryInterfaceLogisticsTicketTemplatesArgs = {
  ticketableType?: InputMaybe<Ticket__TicketableEnum>;
};

export type LogisticsQueryInterfaceLogisticsValidateItemMoveScanArgs = {
  input: Logistics__ValidateItemMoveScanInputType;
};

export type LogisticsQueryInterfacePaginatedLoadsArgs = {
  filters?: InputMaybe<Logistics__LoadsFilterInputType>;
  page?: InputMaybe<Scalars['Int']>;
};

export type LogisticsQueryInterfacePaginatedLogisticsPurchaseOrdersArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type LogisticsQueryInterfacePaginatedLogisticsSkusArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type LogisticsQueryInterfacePalletArgs = {
  id: Scalars['ID'];
};

export type LogisticsQueryInterfacePalletByBarcodeArgs = {
  barcodeValue: Scalars['String'];
};

export type LogisticsQueryInterfaceParcelCarrierAccountsArgs = {
  id: Scalars['ID'];
};

export type Logistics__AccountInput = {
  communicationConsent: Scalars['Boolean'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  warehouseID: Scalars['ID'];
};

export type Logistics__AddDoorAssignmentToLoadInput = {
  doorBarcodeValue: Scalars['String'];
  loadId: Scalars['ID'];
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__AddDoorAssignmentToLoad */
export type Logistics__AddDoorAssignmentToLoadPayload = {
  __typename?: 'Logistics__AddDoorAssignmentToLoadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__AddPalletBarcodedItemsInput = {
  barcodes: Array<Scalars['String']>;
  fulfillmentExpectationID?: InputMaybe<Scalars['ID']>;
  loadID: Scalars['ID'];
  palletID: Scalars['ID'];
  selectionKind?: InputMaybe<Logistics__InventorySkuSelectionKindEnum>;
  skuID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of Logistics__AddPalletBarcodedItems */
export type Logistics__AddPalletBarcodedItemsPayload = {
  __typename?: 'Logistics__AddPalletBarcodedItemsPayload';
  error?: Maybe<Scalars['String']>;
  pallet?: Maybe<Pallet>;
  status: Status;
};

export type Logistics__AddPalletItemsInput = {
  fulfillmentExpectationId?: InputMaybe<Scalars['ID']>;
  loadId: Scalars['ID'];
  palletId: Scalars['ID'];
  quantity: Scalars['Int'];
  selectionKind?: InputMaybe<Logistics__InventorySkuSelectionKindEnum>;
  skuId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of Logistics__AddPalletItems */
export type Logistics__AddPalletItemsPayload = {
  __typename?: 'Logistics__AddPalletItemsPayload';
  error?: Maybe<Scalars['String']>;
  pallet?: Maybe<Pallet>;
  status: Status;
};

export type Logistics__AddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

/** Autogenerated return type of Logistics__AssignLoadPallet */
export type Logistics__AssignLoadPalletPayload = {
  __typename?: 'Logistics__AssignLoadPalletPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__AttachPalletLabelInput = {
  loadId: Scalars['ID'];
  palletId: Scalars['ID'];
  shippingLabelBarcodeValue: Scalars['String'];
};

/** Autogenerated return type of Logistics__AttachPalletLabel */
export type Logistics__AttachPalletLabelPayload = {
  __typename?: 'Logistics__AttachPalletLabelPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__AvailableWarehouseWorkersInput = {
  warehouseID: Scalars['ID'];
};

/** Autogenerated return type of Logistics__BuildAccount */
export type Logistics__BuildAccountPayload = {
  __typename?: 'Logistics__BuildAccountPayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

/** Autogenerated return type of Logistics__BuildFulfillmentExpectation */
export type Logistics__BuildFulfillmentExpectationPayload = {
  __typename?: 'Logistics__BuildFulfillmentExpectationPayload';
  error?: Maybe<Scalars['String']>;
  fulfillmentExpectation?: Maybe<Logistics__FulfillmentExpectation>;
  status: Status;
};

/** Autogenerated return type of Logistics__BuildLoad */
export type Logistics__BuildLoadPayload = {
  __typename?: 'Logistics__BuildLoadPayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

/** Autogenerated return type of Logistics__BuildParcelCarrierAccount */
export type Logistics__BuildParcelCarrierAccountPayload = {
  __typename?: 'Logistics__BuildParcelCarrierAccountPayload';
  error?: Maybe<Scalars['String']>;
  parcelCarrierAccount?: Maybe<Logistics__ParcelCarrierAccount>;
  status: Status;
};

/** Autogenerated return type of Logistics__BuildPurchaseOrder */
export type Logistics__BuildPurchaseOrderPayload = {
  __typename?: 'Logistics__BuildPurchaseOrderPayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

export type Logistics__BuildSkuInput = {
  accountID: Scalars['ID'];
  countingUnit?: InputMaybe<Logistics__Sku__CountingUnitEnum>;
  defaultPalletHeight?: InputMaybe<PalletHeightInput>;
  defaultPalletTypeID?: InputMaybe<Scalars['ID']>;
  description: Scalars['String'];
  gtinBarcode?: InputMaybe<Scalars['String']>;
  hasBarcode: Scalars['Boolean'];
  itemHeight?: InputMaybe<Scalars['Float']>;
  itemLength?: InputMaybe<Scalars['Float']>;
  itemWeight?: InputMaybe<Scalars['Float']>;
  itemWidth?: InputMaybe<Scalars['Float']>;
  itemsPerPallet?: InputMaybe<Scalars['Int']>;
  nmfcClass?: InputMaybe<Scalars['String']>;
  nmfcNumber?: InputMaybe<Scalars['String']>;
  teachInStatus?: InputMaybe<Logistics__SkuTeachInStatusEnum>;
  /** Deprecated: Use gtin_barcode instead */
  upc?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
  vbwEligible?: InputMaybe<Scalars['Boolean']>;
  vbwOptOutReason?: InputMaybe<Logistics__Sku__VbwOptOutReasonEnum>;
  verified: Scalars['Boolean'];
  weightPerPallet?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of Logistics__BuildSku */
export type Logistics__BuildSkuPayload = {
  __typename?: 'Logistics__BuildSkuPayload';
  error?: Maybe<Scalars['String']>;
  sku?: Maybe<Logistics__Sku>;
  status: Status;
};

export type Logistics__CancelLoadInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of Logistics__CancelLoad */
export type Logistics__CancelLoadPayload = {
  __typename?: 'Logistics__CancelLoadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__CarrierDetail = {
  __typename?: 'Logistics__CarrierDetail';
  businessName?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  scac?: Maybe<Scalars['String']>;
  transportationArrangedBy?: Maybe<Logistics__TransportationArrangedByEnum>;
};

export type Logistics__CarrierDetailInput = {
  businessName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  scac?: InputMaybe<Scalars['String']>;
  transportationArrangedBy?: InputMaybe<Logistics__TransportationArrangedByEnum>;
};

export type Logistics__CompleteLoadInput = {
  loadId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__CompleteLoad */
export type Logistics__CompleteLoadPayload = {
  __typename?: 'Logistics__CompleteLoadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__DeletePalletItemsInput = {
  loadId?: InputMaybe<Scalars['ID']>;
  palletId: Scalars['ID'];
  quantity: Scalars['Int'];
  skuId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__DeletePalletItems */
export type Logistics__DeletePalletItemsPayload = {
  __typename?: 'Logistics__DeletePalletItemsPayload';
  error?: Maybe<Scalars['String']>;
  pallet?: Maybe<Pallet>;
  status: Status;
};

export type Logistics__Fulfillment = Logistics__Load;

export type Logistics__FulfillmentExpectation = {
  __typename?: 'Logistics__FulfillmentExpectation';
  damagedCount: Scalars['Int'];
  fulfilledQuantity?: Maybe<Scalars['Int']>;
  fulfillment: Logistics__Fulfillment;
  id: Scalars['ID'];
  /** @deprecated Deprecated in favour of fulfilled_quantity */
  ingestedCount: Scalars['Int'];
  loadedCount: Scalars['Int'];
  palletLabels?: Maybe<Array<Logistics__ShippingLabel>>;
  preparedCount: Scalars['Int'];
  purchaseOrder?: Maybe<Logistics__PurchaseOrder>;
  quantity: Scalars['Int'];
  sku: Logistics__Sku;
};

export type Logistics__FulfillmentExpectationInputType = {
  fulfillmentExpectationID?: InputMaybe<Scalars['ID']>;
  fulfillmentID?: InputMaybe<Scalars['ID']>;
  fulfillmentType?: InputMaybe<Logistics__FulfillmentTypeEnum>;
  purchaseOrderID?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  skuID: Scalars['ID'];
};

export type Logistics__FulfillmentExpectationWithItemCount = {
  __typename?: 'Logistics__FulfillmentExpectationWithItemCount';
  fulfillmentExpectation: Logistics__FulfillmentExpectation;
  itemCount: Scalars['Int'];
};

export enum Logistics__FulfillmentMethodTypeEnum {
  Freight = 'FREIGHT',
  Parcel = 'PARCEL',
}

export enum Logistics__FulfillmentTypeEnum {
  Load = 'Load',
}

export enum Logistics__InboundLoadImageTypeEnum {
  ProofOfDeliveryImage = 'PROOF_OF_DELIVERY_IMAGE',
  UnloadedTrailerNumberImage = 'UNLOADED_TRAILER_NUMBER_IMAGE',
}

export type Logistics__InboundLoadImageUpdateInput = {
  imageType: Logistics__InboundLoadImageTypeEnum;
  imageURL: Scalars['String'];
  loadID: Scalars['ID'];
};

/** Autogenerated return type of Logistics__InboundLoadImageUpdate */
export type Logistics__InboundLoadImageUpdatePayload = {
  __typename?: 'Logistics__InboundLoadImageUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__InboundLoadUnloadTeamCreateInput = {
  loadID: Scalars['ID'];
  userIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of Logistics__InboundLoadUnloadTeamCreate */
export type Logistics__InboundLoadUnloadTeamCreatePayload = {
  __typename?: 'Logistics__InboundLoadUnloadTeamCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__InsufficientSkuExpectationSuggestionType = {
  __typename?: 'Logistics__InsufficientSKUExpectationSuggestionType';
  quantity: Scalars['Int'];
  sku: Logistics__Sku;
};

export type Logistics__InsufficientSkuInventoryInput = {
  fulfillmentExpectationInputs: Array<Logistics__FulfillmentExpectationInputType>;
  loadId?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['String'];
  warehouseId: Scalars['ID'];
};

export type Logistics__InsufficientSkuInventoryType = {
  __typename?: 'Logistics__InsufficientSKUInventoryType';
  id: Scalars['ID'];
  storedCount: Scalars['Int'];
  upcomingLoadsNeedingSku: Array<Logistics__Load>;
  value: Scalars['String'];
};

export type Logistics__InsufficientSkuLoadSuggestionType = {
  __typename?: 'Logistics__InsufficientSKULoadSuggestionType';
  suggestedExpectations: Array<Logistics__InsufficientSkuExpectationSuggestionType>;
};

export type Logistics__InsufficientSkuTruckCuftInput = {
  fulfillmentExpectationInputs: Array<Logistics__FulfillmentExpectationInputType>;
  loadId?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['String'];
};

export type Logistics__InsufficientSkuTruckCuftType = {
  __typename?: 'Logistics__InsufficientSKUTruckCuftType';
  skusWithoutDimensions: Array<Logistics__Sku>;
  suggestedLoads: Array<Logistics__InsufficientSkuLoadSuggestionType>;
  validForKnownDimensions: Scalars['Boolean'];
};

export enum Logistics__InventorySkuSelectionKindEnum {
  Auto = 'AUTO',
  Scan = 'SCAN',
  Tap = 'TAP',
}

export type Logistics__Load = {
  __typename?: 'Logistics__Load';
  appointmentDate?: Maybe<Scalars['DateTime']>;
  billOfLadingNumber?: Maybe<Scalars['String']>;
  billTo?: Maybe<Address>;
  carrierDetails?: Maybe<Logistics__CarrierDetail>;
  /** @deprecated Access this value through carrierDetails.businessName */
  carrierName?: Maybe<Scalars['String']>;
  /** @deprecated This is always required for outbound loads and never for inbound */
  digitalBolSignatureRequired: Scalars['Boolean'];
  disableVbw: Scalars['Boolean'];
  doorAssignment?: Maybe<DoorAssignment>;
  driverSignatureImage?: Maybe<Image>;
  drop: Scalars['Boolean'];
  expectedItems: Scalars['Int'];
  expectedOrders: Scalars['Int'];
  expectedPallets: Scalars['Int'];
  freightChargeTerms: Scalars['String'];
  fulfillmentExpectations: Array<Logistics__FulfillmentExpectation>;
  fullTrailerImages: Array<Image>;
  handlingMethod?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemLabelsRequired: Scalars['Boolean'];
  itemsCount: Scalars['Int'];
  mode: Scalars['String'];
  mustShipBy?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  openTrailerImages: Array<Image>;
  palletHeightPreference?: Maybe<Logistics__PalletHeightPreferenceEnum>;
  palletInventories: Array<Logistics__PalletInventory>;
  palletLabelsRequired: Scalars['Boolean'];
  pallets: Array<Pallet>;
  proNumber?: Maybe<Scalars['String']>;
  purchaseOrders: Array<Logistics__PurchaseOrder>;
  reference1?: Maybe<Scalars['String']>;
  reference2?: Maybe<Scalars['String']>;
  reference3?: Maybe<Scalars['String']>;
  reference4?: Maybe<Scalars['String']>;
  reference5?: Maybe<Scalars['String']>;
  releaseNumber?: Maybe<Scalars['String']>;
  requestedAppointmentDate: Scalars['Date'];
  requestedAppointmentWindow: Logistics__RequestedAppointmentWindowEnum;
  requiresTeachIn: Scalars['Boolean'];
  reservedPallets: Array<Pallet>;
  sealCode?: Maybe<Scalars['String']>;
  sealCodeImage?: Maybe<Image>;
  sealedTrailerImage?: Maybe<Image>;
  shipTo?: Maybe<Address>;
  shipperSignatureImage?: Maybe<Image>;
  shippingLabels: Array<Logistics__ShippingLabel>;
  source: Logistics__LoadSourceEnum;
  specialInstructions?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  /** Height in inches */
  targetPalletHeight?: Maybe<Scalars['Float']>;
  trailerNumber?: Maybe<Scalars['String']>;
  trailerNumberImage?: Maybe<Image>;
  trailerSealRequired: Scalars['Boolean'];
  type: LoadTypeEnum;
  unloadedTrailerNumberImageRequired: Scalars['Boolean'];
  warehouse: Warehouse;
};

export enum Logistics__LoadImagesTypeEnum {
  FullTrailerImages = 'FULL_TRAILER_IMAGES',
  OpenTrailerImages = 'OPEN_TRAILER_IMAGES',
}

export type Logistics__LoadInputType = {
  appointmentDate?: InputMaybe<Scalars['DateTime']>;
  billToAddress?: InputMaybe<Logistics__AddressInput>;
  bolNumber?: InputMaybe<Scalars['String']>;
  carrierDetails?: InputMaybe<Logistics__CarrierDetailInput>;
  /** Deprecated: Carrier name should be set on carrierDetails.businessName */
  carrierName?: InputMaybe<Scalars['String']>;
  draft?: Scalars['Boolean'];
  drop: Scalars['Boolean'];
  freightChargeTerms: Scalars['String'];
  fulfillmentExpectations?: InputMaybe<Array<Logistics__FulfillmentExpectationInputType>>;
  handlingMethod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  itemLabelsRequired: Scalars['Boolean'];
  mode: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
  palletHeightPreference?: InputMaybe<Logistics__PalletHeightPreferenceEnum>;
  reference1?: InputMaybe<Scalars['String']>;
  reference2?: InputMaybe<Scalars['String']>;
  reference3?: InputMaybe<Scalars['String']>;
  reference4?: InputMaybe<Scalars['String']>;
  reference5?: InputMaybe<Scalars['String']>;
  releaseNumber?: InputMaybe<Scalars['String']>;
  requestedAppointmentDate?: InputMaybe<Scalars['Date']>;
  requestedAppointmentWindow?: InputMaybe<Logistics__RequestedAppointmentWindowEnum>;
  shipToAddress?: InputMaybe<Logistics__AddressInput>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  /** Height in inches */
  targetPalletHeight?: InputMaybe<Scalars['Float']>;
  trailerNumber?: InputMaybe<Scalars['String']>;
  trailerSealRequired: Scalars['Boolean'];
  type: LoadTypeEnum;
  usePalletLabels: Scalars['Boolean'];
  validationUuid?: InputMaybe<Scalars['String']>;
  warehouseID: Scalars['String'];
};

export enum Logistics__LoadSourceEnum {
  ClutterAdminTeal = 'CLUTTER_ADMIN_TEAL',
  DiCentral = 'DI_CENTRAL',
}

export enum Logistics__LoadTextFieldTypeEnum {
  ProNumber = 'PRO_NUMBER',
  SealCode = 'SEAL_CODE',
  TrailerNumber = 'TRAILER_NUMBER',
}

export type Logistics__LoadsFilterInputType = {
  appointmentDate?: InputMaybe<Scalars['Date']>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LoadTypeEnum>;
  warehouseID?: InputMaybe<Scalars['Int']>;
  warehouseIDs?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of Logistics__ModifyLoad */
export type Logistics__ModifyLoadPayload = {
  __typename?: 'Logistics__ModifyLoadPayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

/** Autogenerated return type of Logistics__ModifyParcelCarrierAccount */
export type Logistics__ModifyParcelCarrierAccountPayload = {
  __typename?: 'Logistics__ModifyParcelCarrierAccountPayload';
  error?: Maybe<Scalars['String']>;
  parcelCarrierAccount?: Maybe<Logistics__ParcelCarrierAccount>;
  status: Status;
};

/** Autogenerated return type of Logistics__ModifyPurchaseOrder */
export type Logistics__ModifyPurchaseOrderPayload = {
  __typename?: 'Logistics__ModifyPurchaseOrderPayload';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Status;
};

export type Logistics__ModifySkuInput = {
  countingUnit?: InputMaybe<Logistics__Sku__CountingUnitEnum>;
  defaultPalletHeight?: InputMaybe<PalletHeightInput>;
  defaultPalletTypeID?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  gtinBarcode?: InputMaybe<Scalars['String']>;
  hasBarcode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  itemHeight?: InputMaybe<Scalars['Float']>;
  itemLength?: InputMaybe<Scalars['Float']>;
  itemWeight?: InputMaybe<Scalars['Float']>;
  itemWidth?: InputMaybe<Scalars['Float']>;
  itemsPerPallet?: InputMaybe<Scalars['Int']>;
  nmfcClass?: InputMaybe<Scalars['String']>;
  nmfcNumber?: InputMaybe<Scalars['String']>;
  teachInStatus?: InputMaybe<Logistics__SkuTeachInStatusEnum>;
  /** Deprecated: Use gtin_barcode instead */
  upc?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  vbwEligible?: InputMaybe<Scalars['Boolean']>;
  vbwOptOutReason?: InputMaybe<Logistics__Sku__VbwOptOutReasonEnum>;
  verified?: InputMaybe<Scalars['Boolean']>;
  weightPerPallet?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of Logistics__ModifySku */
export type Logistics__ModifySkuPayload = {
  __typename?: 'Logistics__ModifySkuPayload';
  error?: Maybe<Scalars['String']>;
  sku?: Maybe<Logistics__Sku>;
  status: Status;
};

export type Logistics__MoveItemsByCountInput = {
  destinationPalletId: Scalars['ID'];
  originPalletId: Scalars['ID'];
  quantity: Scalars['Int'];
  skuId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__MoveItemsByCount */
export type Logistics__MoveItemsByCountPayload = {
  __typename?: 'Logistics__MoveItemsByCountPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__MoveItemsInput = {
  destinationPalletId: Scalars['ID'];
  itemBarcodes: Array<Scalars['String']>;
};

/** Autogenerated return type of Logistics__MoveItems */
export type Logistics__MoveItemsPayload = {
  __typename?: 'Logistics__MoveItemsPayload';
  destinationPallet?: Maybe<Pallet>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__MovePalletFromTrailerInput = {
  dockBarcodeValue: Scalars['String'];
  palletId: Scalars['ID'];
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__MovePalletFromTrailer */
export type Logistics__MovePalletFromTrailerPayload = {
  __typename?: 'Logistics__MovePalletFromTrailerPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__MovePalletToLoadingInput = {
  locationBarcodeValue: Scalars['String'];
  palletId: Scalars['ID'];
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__MovePalletToLoading */
export type Logistics__MovePalletToLoadingPayload = {
  __typename?: 'Logistics__MovePalletToLoadingPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__MovePalletToTrailerInput = {
  dockBarcodeValue: Scalars['String'];
  palletId: Scalars['ID'];
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__MovePalletToTrailer */
export type Logistics__MovePalletToTrailerPayload = {
  __typename?: 'Logistics__MovePalletToTrailerPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__OptimalPalletComposition =
  | Logistics__OptimalPalletComposition__Composition
  | Logistics__OptimalPalletComposition__Error;

export type Logistics__OptimalPalletCompositionInput = {
  palletTypeID: Scalars['ID'];
  skuIDs: Array<Scalars['ID']>;
};

export type Logistics__OptimalPalletComposition__Box = {
  __typename?: 'Logistics__OptimalPalletComposition__Box';
  /** RGB hexadecimal format */
  color: Scalars['String'];
  length: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Logistics__OptimalPalletComposition__Composition = {
  __typename?: 'Logistics__OptimalPalletComposition__Composition';
  boxes: Array<Logistics__OptimalPalletComposition__Box>;
  heuristicType: Scalars['String'];
  palletDimensions: Logistics__OptimalPalletComposition__Dimensions;
  palletType: PalletType;
};

export type Logistics__OptimalPalletComposition__Dimensions = {
  __typename?: 'Logistics__OptimalPalletComposition__Dimensions';
  length: Scalars['Int'];
  width: Scalars['Int'];
};

export type Logistics__OptimalPalletComposition__Error = {
  __typename?: 'Logistics__OptimalPalletComposition__Error';
  message: Scalars['String'];
};

export enum Logistics__OutboundLoadImageTypeEnum {
  DriverSignatureImage = 'DRIVER_SIGNATURE_IMAGE',
  SealedTrailerImage = 'SEALED_TRAILER_IMAGE',
  SealCodeImage = 'SEAL_CODE_IMAGE',
  ShipperSignatureImage = 'SHIPPER_SIGNATURE_IMAGE',
  TrailerNumberImage = 'TRAILER_NUMBER_IMAGE',
}

export type Logistics__OutboundTaskListType = {
  __typename?: 'Logistics__OutboundTaskListType';
  inProgressTask?: Maybe<PalletTask>;
  suggestions: Array<Logistics__OutboundTaskList__SuggestionType>;
};

export type Logistics__OutboundTaskList__InputType = {
  appointmentDate: Scalars['Date'];
  moveType: Logistics__OutboundTaskList__MoveTypeEnum;
  palletTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  warehouseId: Scalars['ID'];
};

export enum Logistics__OutboundTaskList__MoveTypeEnum {
  RepackingToDock = 'REPACKING_TO_DOCK',
  StorageOrRepackingToDock = 'STORAGE_OR_REPACKING_TO_DOCK',
  StorageToRepacking = 'STORAGE_TO_REPACKING',
}

export type Logistics__OutboundTaskList__SuggestionType = {
  __typename?: 'Logistics__OutboundTaskList__SuggestionType';
  load?: Maybe<Logistics__Load>;
  /** Suggested, non-reserved pallets */
  pallets: Array<Pallet>;
  reservedPallets: Array<Pallet>;
};

export type Logistics__OutboundTaskList__ValidateNewTaskInputType = {
  loadIds: Array<Scalars['ID']>;
  moveType: Logistics__OutboundTaskList__MoveTypeEnum;
  palletBarcodeValue: Scalars['String'];
};

export type Logistics__PaginatedLoadListType = {
  __typename?: 'Logistics__PaginatedLoadListType';
  pagination: Pagination;
  results: Array<Logistics__Load>;
};

export type Logistics__PaginatedPurchaseOrder = {
  __typename?: 'Logistics__PaginatedPurchaseOrder';
  pagination: Pagination;
  results: Array<Logistics__PurchaseOrder>;
};

export type Logistics__PaginatedSku = {
  __typename?: 'Logistics__PaginatedSku';
  pagination: Pagination;
  results: Array<Logistics__Sku>;
};

export enum Logistics__PalletHeightPreferenceEnum {
  CloseAsPossible = 'CLOSE_AS_POSSIBLE',
  DoNotExceed = 'DO_NOT_EXCEED',
}

export enum Logistics__PalletImageTypeEnum {
  SecuredPalletImage = 'SECURED_PALLET_IMAGE',
}

export type Logistics__PalletInventory = {
  __typename?: 'Logistics__PalletInventory';
  id: Scalars['ID'];
  pallet: Pallet;
  quantity: Scalars['Int'];
  sku: Logistics__Sku;
};

export type Logistics__ParcelCarrierAccount = {
  __typename?: 'Logistics__ParcelCarrierAccount';
  account: Account;
  accountNumber: Scalars['String'];
  carrier: Logistics__ParcelCarrierEnum;
  country: Scalars['String'];
  id: Scalars['ID'];
  postalCode: Scalars['String'];
};

export type Logistics__ParcelCarrierAccountInput = {
  accountID?: InputMaybe<Scalars['ID']>;
  accountNumber: Scalars['String'];
  carrier: Scalars['String'];
  country: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  postalCode: Scalars['String'];
};

export enum Logistics__ParcelCarrierEnum {
  FedEx = 'FedEx',
  Ups = 'UPS',
}

export type Logistics__PurchaseOrder = {
  __typename?: 'Logistics__PurchaseOrder';
  account: Account;
  additionalInfo?: Maybe<Scalars['String']>;
  /** @deprecated You can grab this through account's customer name */
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fulfillmentExpectations: Array<Logistics__FulfillmentExpectation>;
  fulfillmentMethod: Logistics__FulfillmentMethodTypeEnum;
  id: Scalars['ID'];
  /** @deprecated No longer used */
  mustShipBy?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  /** @deprecated No longer used */
  orderDate?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use fulfillment expectations */
  quantities: Array<PurchaseOrderQuantities>;
  shipTo?: Maybe<Address>;
};

export type Logistics__PurchaseOrderInput = {
  accountID: Scalars['ID'];
  fulfillmentExpectations?: InputMaybe<Array<Logistics__FulfillmentExpectationInputType>>;
  fulfillmentMethod: Logistics__FulfillmentMethodTypeEnum;
  id?: InputMaybe<Scalars['ID']>;
  number?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  shipToAddress?: InputMaybe<Logistics__AddressInput>;
};

export type Logistics__RemoveDoorAssignmentFromLoadInput = {
  loadId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__RemoveDoorAssignmentFromLoad */
export type Logistics__RemoveDoorAssignmentFromLoadPayload = {
  __typename?: 'Logistics__RemoveDoorAssignmentFromLoadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Logistics__ReopenLoad */
export type Logistics__ReopenLoadPayload = {
  __typename?: 'Logistics__ReopenLoadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum Logistics__RequestedAppointmentWindowEnum {
  Afternoon = 'AFTERNOON',
  Morning = 'MORNING',
  NoPreference = 'NO_PREFERENCE',
}

/** Autogenerated return type of Logistics__ReteachSku */
export type Logistics__ReteachSkuPayload = {
  __typename?: 'Logistics__ReteachSkuPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__SkuWithItemCount = {
  __typename?: 'Logistics__SKUWithItemCount';
  itemCount: Scalars['Int'];
  sku: Logistics__Sku;
};

export type Logistics__ShippingLabel = {
  __typename?: 'Logistics__ShippingLabel';
  id: Scalars['ID'];
  itemsPerPallet: Scalars['Int'];
  purchaseOrder: Logistics__PurchaseOrder;
  sku: Logistics__Sku;
};

export type Logistics__Sku = {
  __typename?: 'Logistics__Sku';
  account: Account;
  accountID: Scalars['ID'];
  countingUnit: Logistics__Sku__CountingUnitEnum;
  defaultPalletHeight?: Maybe<PalletHeight>;
  defaultPalletTypeId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  gtinBarcode?: Maybe<Scalars['String']>;
  hasBarcode: Scalars['Boolean'];
  id: Scalars['ID'];
  itemHeight?: Maybe<Scalars['Float']>;
  itemLength?: Maybe<Scalars['Float']>;
  itemWeight?: Maybe<Scalars['Float']>;
  itemWidth?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Int']>;
  measuredWeightImage?: Maybe<DetailedImage>;
  nmfcClass?: Maybe<Scalars['String']>;
  nmfcNumber?: Maybe<Scalars['String']>;
  optOuts: Array<Logistics__Sku__OptOutType>;
  optimalPalletComposition: Logistics__OptimalPalletComposition;
  requiresTeachIn: Scalars['Boolean'];
  teachInStatus: Logistics__SkuTeachInStatusEnum;
  /** @deprecated Use gtin_barcode instead */
  upc?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  vbwEligible: Scalars['Boolean'];
  /** @deprecated Use opt outs instead */
  vbwOptOutReason?: Maybe<Logistics__Sku__VbwOptOutReasonEnum>;
  verificationImages: Array<DetailedImage>;
  verified: Scalars['Boolean'];
  weightPerPallet?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of Logistics__SkuDelete */
export type Logistics__SkuDeletePayload = {
  __typename?: 'Logistics__SkuDeletePayload';
  error?: Maybe<Scalars['String']>;
  sku?: Maybe<Logistics__Sku>;
  status: Status;
};

export enum Logistics__SkuImageTypeEnum {
  MeasuredWeightImage = 'MEASURED_WEIGHT_IMAGE',
  VerificationImage = 'VERIFICATION_IMAGE',
}

export enum Logistics__SkuImagesTypeEnum {
  VerificationImage = 'VERIFICATION_IMAGE',
}

/** Autogenerated return type of Logistics__SkuMerge */
export type Logistics__SkuMergePayload = {
  __typename?: 'Logistics__SkuMergePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Logistics__SkuOptOutBuild */
export type Logistics__SkuOptOutBuildPayload = {
  __typename?: 'Logistics__SkuOptOutBuildPayload';
  error?: Maybe<Scalars['String']>;
  optOut?: Maybe<Logistics__Sku__OptOutType>;
  status: Status;
};

export enum Logistics__SkuTeachInStatusEnum {
  NotRequired = 'NOT_REQUIRED',
  Pending = 'PENDING',
  Taught = 'TAUGHT',
}

export type Logistics__Sku__BulkUploadInput = {
  accountID: Scalars['ID'];
  file: Scalars['ID'];
};

/** Autogenerated return type of Logistics__Sku__BulkUpload */
export type Logistics__Sku__BulkUploadPayload = {
  __typename?: 'Logistics__Sku__BulkUploadPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__Sku__BulkUploadPreview =
  | Logistics__Sku__BulkUploadPreview__Error
  | Logistics__Sku__BulkUploadPreview__Preview;

export type Logistics__Sku__BulkUploadPreviewInput = {
  accountID: Scalars['ID'];
  file: Scalars['ID'];
};

export type Logistics__Sku__BulkUploadPreview__Error = {
  __typename?: 'Logistics__Sku__BulkUploadPreview__Error';
  message: Scalars['String'];
};

export type Logistics__Sku__BulkUploadPreview__Preview = {
  __typename?: 'Logistics__Sku__BulkUploadPreview__Preview';
  skus: Array<Logistics__Sku__BulkUploadPreview__Sku>;
};

export type Logistics__Sku__BulkUploadPreview__Sku = {
  __typename?: 'Logistics__Sku__BulkUploadPreview__Sku';
  accountID: Scalars['ID'];
  countingUnit: Logistics__Sku__CountingUnitEnum;
  defaultPalletHeightID?: Maybe<Scalars['ID']>;
  defaultPalletTypeID?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  gtinBarcode?: Maybe<Scalars['String']>;
  hasBarcode: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  itemHeight?: Maybe<Scalars['Float']>;
  itemLength?: Maybe<Scalars['Float']>;
  itemWeight?: Maybe<Scalars['Float']>;
  itemWidth?: Maybe<Scalars['Float']>;
  itemsPerPallet?: Maybe<Scalars['Int']>;
  nmfcClass?: Maybe<Scalars['String']>;
  nmfcNumber?: Maybe<Scalars['String']>;
  teachInStatus: Logistics__SkuTeachInStatusEnum;
  value: Scalars['String'];
  vbwEligible: Scalars['Boolean'];
  vbwOptOutReason?: Maybe<Logistics__Sku__VbwOptOutReasonEnum>;
  verified: Scalars['Boolean'];
  weightPerPallet?: Maybe<Scalars['Float']>;
};

export enum Logistics__Sku__CountingUnitEnum {
  MasterCarton = 'MASTER_CARTON',
  Pallet = 'PALLET',
}

export type Logistics__Sku__OptOutBuildInputType = {
  description?: InputMaybe<Scalars['String']>;
  optOutType: Logistics__Sku__OptOutTypeEnum;
  reasonCode: Logistics__Sku__OptOutReasonCodeEnum;
  skuID: Scalars['ID'];
};

export enum Logistics__Sku__OptOutReasonCodeEnum {
  Dangerous = 'DANGEROUS',
  Other = 'OTHER',
  PoOrFbaNumber = 'PO_OR_FBA_NUMBER',
  SingleItemPallet = 'SINGLE_ITEM_PALLET',
  TooLarge = 'TOO_LARGE',
  VariableWeight = 'VARIABLE_WEIGHT',
}

export type Logistics__Sku__OptOutType = {
  __typename?: 'Logistics__Sku__OptOutType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  optOutType: Logistics__Sku__OptOutTypeEnum;
  reasonCode: Logistics__Sku__OptOutReasonCodeEnum;
  sku: Logistics__Sku;
  user: User;
};

export enum Logistics__Sku__OptOutTypeEnum {
  TeachInDimension = 'TEACH_IN_DIMENSION',
  TeachInWeight = 'TEACH_IN_WEIGHT',
  Vbw = 'VBW',
}

export enum Logistics__Sku__VbwOptOutReasonEnum {
  Other = 'OTHER',
  VariableWeight = 'VARIABLE_WEIGHT',
}

export type Logistics__SkusFilterInputType = {
  includeUnknown?: InputMaybe<Scalars['Boolean']>;
  search: Scalars['String'];
};

export type Logistics__TicketTemplate = {
  __typename?: 'Logistics__TicketTemplate';
  category: Scalars['String'];
  groupID: Scalars['ID'];
  subCategory: Scalars['String'];
  subtitle: Scalars['String'];
  ticketableType: Ticket__TicketableEnum;
  title: Scalars['String'];
};

export enum Logistics__TransportationArrangedByEnum {
  Clutter = 'CLUTTER',
  Shipper = 'SHIPPER',
}

export type Logistics__UpdateLoadImagesInput = {
  imageUrls: Array<Scalars['String']>;
  imagesType: Logistics__LoadImagesTypeEnum;
  loadId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__UpdateLoadImages */
export type Logistics__UpdateLoadImagesPayload = {
  __typename?: 'Logistics__UpdateLoadImagesPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdateLoadTextFieldInput = {
  loadId: Scalars['ID'];
  text: Scalars['String'];
  textFieldType: Logistics__LoadTextFieldTypeEnum;
};

/** Autogenerated return type of Logistics__UpdateLoadTextField */
export type Logistics__UpdateLoadTextFieldPayload = {
  __typename?: 'Logistics__UpdateLoadTextFieldPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdateOutboundLoadImageInput = {
  imageType: Logistics__OutboundLoadImageTypeEnum;
  imageUrl: Scalars['String'];
  loadId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__UpdateOutboundLoadImage */
export type Logistics__UpdateOutboundLoadImagePayload = {
  __typename?: 'Logistics__UpdateOutboundLoadImagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdatePalletImageInput = {
  imageType: Logistics__PalletImageTypeEnum;
  imageUrl: Scalars['String'];
  palletId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__UpdatePalletImage */
export type Logistics__UpdatePalletImagePayload = {
  __typename?: 'Logistics__UpdatePalletImagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdatePalletWeightInput = {
  measuredWeightImageUrl: Scalars['String'];
  overrideValidation: Scalars['Boolean'];
  palletId: Scalars['ID'];
  weight: Scalars['Float'];
};

/** Autogenerated return type of Logistics__UpdatePalletWeight */
export type Logistics__UpdatePalletWeightPayload = {
  __typename?: 'Logistics__UpdatePalletWeightPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdateSkuImageInput = {
  imageType: Logistics__SkuImageTypeEnum;
  imageUrl: Scalars['String'];
  skuID: Scalars['ID'];
};

/** Autogenerated return type of Logistics__UpdateSkuImage */
export type Logistics__UpdateSkuImagePayload = {
  __typename?: 'Logistics__UpdateSkuImagePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__UpdateSkuImagesInput = {
  imageType: Logistics__SkuImagesTypeEnum;
  imageUrls: Array<Scalars['String']>;
  skuId: Scalars['ID'];
};

/** Autogenerated return type of Logistics__UpdateSkuImages */
export type Logistics__UpdateSkuImagesPayload = {
  __typename?: 'Logistics__UpdateSkuImagesPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Logistics__ValidateItemMoveScanInputType = {
  expectedSkuId: Scalars['ID'];
  itemBarcodeValue: Scalars['String'];
  originPalletId: Scalars['ID'];
};

export type Logistics__WarehouseWorker = {
  __typename?: 'Logistics__WarehouseWorker';
  currentAssignedLogisticsLoad?: Maybe<Logistics__Load>;
  id: Scalars['ID'];
  user: User;
};

/** Autogenerated return type of MarkMessagesRead */
export type MarkMessagesReadPayload = {
  __typename?: 'MarkMessagesReadPayload';
  messages?: Maybe<Array<Ticket__Message>>;
  status: Status;
};

export type MarketingDataInput = {
  channel?: InputMaybe<Scalars['String']>;
  impactClickID?: InputMaybe<Scalars['String']>;
};

export type MarketingEvent = {
  __typename?: 'MarketingEvent';
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
};

export type MarketingMutationInterface = {
  couponActivate: Marketing__CouponActivatePayload;
  couponCreate: Marketing__CouponCreatePayload;
  couponDeactivate: Marketing__CouponDeactivatePayload;
  couponUpdate: Marketing__CouponUpdatePayload;
};

export type MarketingMutationInterfaceCouponActivateArgs = {
  id: Scalars['ID'];
};

export type MarketingMutationInterfaceCouponCreateArgs = {
  input: Coupon__Input;
};

export type MarketingMutationInterfaceCouponDeactivateArgs = {
  id: Scalars['ID'];
};

export type MarketingMutationInterfaceCouponUpdateArgs = {
  id: Scalars['ID'];
  input: Coupon__Input;
};

export type MarketingQueryInterface = {
  coupon: Coupon;
  coupons: Array<Coupon>;
  paginatedCoupons: Coupon__PaginatedType;
};

export type MarketingQueryInterfaceCouponArgs = {
  id: Scalars['ID'];
};

export type MarketingQueryInterfaceCouponsArgs = {
  filters?: InputMaybe<Coupon__FiltersInput>;
};

export type MarketingQueryInterfacePaginatedCouponsArgs = {
  filters?: InputMaybe<Coupon__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of Marketing__CouponActivate */
export type Marketing__CouponActivatePayload = {
  __typename?: 'Marketing__CouponActivatePayload';
  coupon?: Maybe<Coupon>;
};

/** Autogenerated return type of Marketing__CouponCreate */
export type Marketing__CouponCreatePayload = {
  __typename?: 'Marketing__CouponCreatePayload';
  coupon?: Maybe<Coupon>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Marketing__CouponDeactivate */
export type Marketing__CouponDeactivatePayload = {
  __typename?: 'Marketing__CouponDeactivatePayload';
  coupon?: Maybe<Coupon>;
};

/** Autogenerated return type of Marketing__CouponUpdate */
export type Marketing__CouponUpdatePayload = {
  __typename?: 'Marketing__CouponUpdatePayload';
  coupon?: Maybe<Coupon>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Marketing__PromoCode__Input = {
  id?: InputMaybe<Scalars['ID']>;
  value: Scalars['String'];
};

export type MaterialCategory = {
  __typename?: 'MaterialCategory';
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  moveAndPackVisible: Scalars['Boolean'];
  name: Scalars['String'];
  packingStandard?: Maybe<PackingStandard>;
  width?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  body: Scalars['String'];
  consentBlocked: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  iterableStatus?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  nonUserNumber: Scalars['String'];
  recipientName: Scalars['String'];
  to: Scalars['String'];
  twilioStatus?: Maybe<Scalars['String']>;
  user: User;
  userName: Scalars['String'];
};

/** Autogenerated return type of MessageCreate */
export type MessageCreatePayload = {
  __typename?: 'MessageCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  ticketMessage?: Maybe<Ticket__Message>;
};

export type MessageInput = {
  body: Scalars['String'];
  orderId?: InputMaybe<Scalars['ID']>;
  phoneId: Scalars['ID'];
  recipientId: Scalars['ID'];
  recipientType: Scalars['String'];
};

/** Autogenerated return type of MessageUpdate */
export type MessageUpdatePayload = {
  __typename?: 'MessageUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  ticketMessage?: Maybe<Ticket__Message>;
};

/** Autogenerated return type of ModifyAuction */
export type ModifyAuctionPayload = {
  __typename?: 'ModifyAuctionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ModifyDepot */
export type ModifyDepotPayload = {
  __typename?: 'ModifyDepotPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ModifyItemCategory */
export type ModifyItemCategoryPayload = {
  __typename?: 'ModifyItemCategoryPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ModifyLaborClock */
export type ModifyLaborClockPayload = {
  __typename?: 'ModifyLaborClockPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ModifyMovingClocks */
export type ModifyMovingClocksPayload = {
  __typename?: 'ModifyMovingClocksPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ModifyNote */
export type ModifyNotePayload = {
  __typename?: 'ModifyNotePayload';
  note?: Maybe<Note>;
  status: Status;
};

/** Autogenerated return type of ModifyPalletType */
export type ModifyPalletTypePayload = {
  __typename?: 'ModifyPalletTypePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Modify */
export type ModifyPayload = {
  __typename?: 'ModifyPayload';
  customerTicket?: Maybe<CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ModifyRegionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  defaultDepotID?: InputMaybe<Scalars['ID']>;
  defaultWarehouseID?: InputMaybe<Scalars['ID']>;
  fulfiller?: InputMaybe<RegionFulfillerEnum>;
  geo?: InputMaybe<ClutterGeoEnum>;
  hexColor?: InputMaybe<Scalars['String']>;
  irmCluster?: InputMaybe<IrmClusterEnum>;
  irmTerritory?: InputMaybe<IrmTerritoryEnum>;
  movingExtendedServiceAreaPostalCodeLabels?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  netsuiteRegionID?: InputMaybe<Scalars['ID']>;
  postalCodeLabels?: InputMaybe<Array<Scalars['String']>>;
  storageExtendedServiceAreaPostalCodeLabels?: InputMaybe<Array<Scalars['String']>>;
  tz?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ModifyRegion */
export type ModifyRegionPayload = {
  __typename?: 'ModifyRegionPayload';
  error?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  status: Status;
};

/** Autogenerated return type of ModifyWarehouse */
export type ModifyWarehousePayload = {
  __typename?: 'ModifyWarehousePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  warehouse?: Maybe<Warehouse>;
};

export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  currency: Currency;
  formatted: Scalars['String'];
  value: Scalars['Float'];
};

export type MovingCategory = {
  __typename?: 'MovingCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MovingMutationInterface = {
  createFollowUpMove?: Maybe<Moving__CreateFollowUpMovePayload>;
  modifyMovingClocks: ModifyMovingClocksPayload;
  movingTransportWeightUpsert?: Maybe<Moving__LongDistance__TransportWeightUpsertPayload>;
  movingWalkthroughDelete: Moving__WalkthroughDeletePayload;
  movingWalkthroughUpdate: Moving__WalkthroughUpdatePayload;
};

export type MovingMutationInterfaceCreateFollowUpMoveArgs = {
  input: Moving__CreateFollowUpMoveInput;
};

export type MovingMutationInterfaceModifyMovingClocksArgs = {
  input: Moving__ClocksInput;
  orderID: Scalars['ID'];
};

export type MovingMutationInterfaceMovingTransportWeightUpsertArgs = {
  input: Moving__LongDistance__TransportWeightUpsertInput;
};

export type MovingMutationInterfaceMovingWalkthroughDeleteArgs = {
  orderID: Scalars['ID'];
};

export type MovingMutationInterfaceMovingWalkthroughUpdateArgs = {
  input: Moving__WalkthroughInput;
  orderID: Scalars['ID'];
};

export type MovingOperation = {
  __typename?: 'MovingOperation';
  arrivedAtDestinationAt?: Maybe<Scalars['DateTime']>;
  /**
   * This is the assumed empty truck weight, in pounds, based on the assigned
   * vehicles used for packing. Avoid using this field with bulk order queries.
   */
  assumedEmptyTruckWeightInPounds: Scalars['Float'];
  departedForDestinationAt?: Maybe<Scalars['DateTime']>;
  destinationAddress: Address;
  /** This is the entered empty truck weight, in pounds. */
  emptyTruckWeightInPounds?: Maybe<Scalars['Float']>;
  /** This is a suggestion for what to enter for the empty truck weight. Avoid using this field with bulk order queries. */
  emptyTruckWeightInPoundsSuggestion: Scalars['String'];
  id: Scalars['ID'];
  loadedTruckWeightInPounds?: Maybe<Scalars['Float']>;
  longDistanceTransportationRate?: Maybe<Moving__LongDistance__TransportationRate>;
  moveSize?: Maybe<Moving__MoveSizeEnum>;
  originAddress: Address;
  packedAt?: Maybe<Scalars['DateTime']>;
  packing?: Maybe<Moving__PackingEnum>;
  quote?: Maybe<Moving__Quote>;
  unpackedAt?: Maybe<Scalars['DateTime']>;
};

export type MovingQueryInterface = {
  movingConfiguration: Moving__Configuration;
};

export type MovingQueryInterfaceMovingConfigurationArgs = {
  movingConfigurationID: Scalars['ID'];
};

export type MovingSelection = {
  __typename?: 'MovingSelection';
  id: Scalars['ID'];
  movingCategory: MovingCategory;
  quantity: Scalars['Int'];
};

export type Moving__ClocksInput = {
  arrivedAtDestinationAt?: InputMaybe<Scalars['DateTime']>;
  departedForDestinationAt?: InputMaybe<Scalars['DateTime']>;
  ended?: InputMaybe<Scalars['DateTime']>;
  packedAt?: InputMaybe<Scalars['DateTime']>;
  started?: InputMaybe<Scalars['DateTime']>;
  unpackedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Moving__Configuration = {
  __typename?: 'Moving__Configuration';
  id: Scalars['ID'];
  laborRate: LaborRate;
  travelRateMultiplier: Scalars['Float'];
};

export type Moving__ConfigurationLaborRateArgs = {
  movers: Scalars['Int'];
};

export type Moving__CreateFollowUpMoveInput = {
  destinationAddressID: Scalars['ID'];
  moverCount: Scalars['Int'];
  originAddressID: Scalars['ID'];
  regionID: Scalars['ID'];
  scheduledTime: Scalars['DateTime'];
  sourceOrderID: Scalars['ID'];
  subtype: OrderSubtypeEnum;
};

/** Autogenerated return type of Moving__CreateFollowUpMove */
export type Moving__CreateFollowUpMovePayload = {
  __typename?: 'Moving__CreateFollowUpMovePayload';
  error?: Maybe<Error>;
  orderID?: Maybe<Scalars['ID']>;
  status: Status;
};

export type Moving__LongDistance__TransportWeightUpsertInput = {
  emptyTruckWeightInPounds: Scalars['Float'];
  loadedTruckWeightInPounds: Scalars['Float'];
  orderID: Scalars['ID'];
};

/** Autogenerated return type of Moving__LongDistance__TransportWeightUpsert */
export type Moving__LongDistance__TransportWeightUpsertPayload = {
  __typename?: 'Moving__LongDistance__TransportWeightUpsertPayload';
  error?: Maybe<Error>;
  status: Status;
};

export type Moving__LongDistance__TransportationRate = {
  __typename?: 'Moving__LongDistance__TransportationRate';
  id: Scalars['ID'];
};

export enum Moving__MoveSizeEnum {
  Everything = 'EVERYTHING',
  Few = 'FEW',
  Half = 'HALF',
}

export type Moving__PackageSetEntry = {
  __typename?: 'Moving__PackageSetEntry';
  fixedCost: Scalars['Float'];
  id: Scalars['ID'];
  package: Package;
  packageSet?: Maybe<PackageSet>;
};

export enum Moving__PackingEnum {
  AllItems = 'ALL_ITEMS',
  EverythingIsPacked = 'EVERYTHING_IS_PACKED',
  SomeItems = 'SOME_ITEMS',
}

export type Moving__Quote = {
  __typename?: 'Moving__Quote';
  /** Distance in meters */
  deadtimeDriveDistance?: Maybe<Scalars['Int']>;
  deadtimeDriveDuration?: Maybe<Scalars['Int']>;
  dispatchDurations?: Maybe<Array<Scalars['Int']>>;
  /** Distance in meters */
  driveDistance?: Maybe<Scalars['Int']>;
  drivetimeDuration?: Maybe<Scalars['Int']>;
  eligibility: Scalars['Boolean'];
  estimatedCuft: Scalars['Int'];
  id: Scalars['ID'];
  laborRate?: Maybe<LaborRate>;
  materialPackageSetEntry?: Maybe<Moving__PackageSetEntry>;
  moverSelection?: Maybe<Scalars['Int']>;
  movingConfiguration?: Maybe<Moving__Configuration>;
  orderDurations?: Maybe<Array<Scalars['Int']>>;
  packDurations: Array<Scalars['Int']>;
  reasons: Array<Scalars['String']>;
  reservationDepositAmount?: Maybe<Scalars['Float']>;
  suggestedMoverCount?: Maybe<Scalars['Int']>;
  truckCount?: Maybe<Scalars['Int']>;
  unpackDurations: Array<Scalars['Int']>;
};

/** Autogenerated return type of Moving__WalkthroughDelete */
export type Moving__WalkthroughDeletePayload = {
  __typename?: 'Moving__WalkthroughDeletePayload';
  order: OrderOrError;
};

export type Moving__WalkthroughInput = {
  flatRateAmount?: InputMaybe<Scalars['Float']>;
  walkthroughEstimatedDuration?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of Moving__WalkthroughUpdate */
export type Moving__WalkthroughUpdatePayload = {
  __typename?: 'Moving__WalkthroughUpdatePayload';
  order: OrderOrError;
};

export type MultiDayContext = {
  __typename?: 'MultiDayContext';
  currentDay: Scalars['Int'];
  id: Scalars['ID'];
  multiDay: Scalars['Boolean'];
  totalDays: Scalars['Int'];
};

export type Mutation = AccountMutationInterface &
  AuctionMutationInterface &
  AutomationMutationInterface &
  AvailabilityMutationInterface &
  BillingMutationInterface &
  ClaimsMutationInterface &
  ContainersMutationInterface &
  CrmMutationInterface &
  CustomerTicketsMutationInterface &
  DockApiMutationInterface &
  EstimationMutationInterface &
  FieldApiMutationInterface &
  HrsMutationInterface &
  IngestionMutationInterface &
  LandingLocationMutationInterface &
  LogisticsMutationInterface &
  MarketingMutationInterface &
  MovingMutationInterface &
  NightTransportMutationInterface &
  OpsAuditMutationInterface &
  OrderMutationInterface &
  PaymentPlanMutationInterface &
  PricingMutationInterface &
  RateAdjustmentMutationInterface &
  SelfStorageMutationInterface &
  StorageTreasuresMutationInterface &
  TicketMutationInterface &
  WorkforceMutationInterface & {
    __typename?: 'Mutation';
    acceptPaymentPlanOffer: PaymentPlan__Offer__AcceptPayload;
    accountAddressUpsert: Account__AddressUpsertPayload;
    accountBanUpdate?: Maybe<Account__BanUpdatePayload>;
    accountImpersonateCustomerLinkCreate: ImpersonateCustomerLinkCreatePayload;
    accountReferralCodeUpdate: Account__ReferralCodeUpdatePayload;
    addDoorAssignmentToLoad: Logistics__AddDoorAssignmentToLoadPayload;
    addPalletBarcodedItems: Logistics__AddPalletBarcodedItemsPayload;
    addPalletItems: Logistics__AddPalletItemsPayload;
    assignLoadPallet: Logistics__AssignLoadPalletPayload;
    attachPalletLabel: Logistics__AttachPalletLabelPayload;
    attendancePolicyViolationCreate: AttendancePolicyViolation__CreatePayload;
    attendancePolicyViolationReview: AttendancePolicyViolation__ReviewPayload;
    attestationEventResolve: AttestationEventResolvePayload;
    auctionUploadCreate: Auction__Upload__CreatePayload;
    automationPickupComplete: PickupCompletePayload;
    automationReturnRoute: ReturnRoutePayload;
    availabilityFacilityCuftLimitsCreate: Availability__FacilityCuftLimitsCreatePayload;
    availabilityFacilityCuftLimitsDestroy: Availability__FacilityCuftLimitsDestroyPayload;
    availabilityGlobalUnavailabilitiesCreate: Availability__GlobalUnavailabilitiesCreatePayload;
    availabilityGlobalUnavailabilitiesDestroy: Availability__GlobalUnavailabilitiesDestroyPayload;
    availabilityJobAvailabilitiesCreate: Availability__JobAvailabilitiesCreatePayload;
    availabilityJobAvailabilitiesDestroy: Availability__JobAvailabilitiesDestroyPayload;
    availabilityLaborAdjustmentsCreate: Availability__LaborAdjustmentsCreatePayload;
    availabilityLaborAdjustmentsDelete: Availability__LaborAdjustmentsDeletePayload;
    availabilityOperatingScheduleTemplateCreate: Availability__OperatingScheduleTemplateCreatePayload;
    availabilityRescheduleOffersCreate: Availability__RescheduleOffersCreatePayload;
    availabilityRescheduleOffersDelete: Availability__RescheduleOffersDeletePayload;
    avatarDelete: AvatarDeletePayload;
    avatarUpload: AvatarUploadPayload;
    billingAuthorizationHoldCancel: Billing__AuthorizationHold__CancelPayload;
    billingAuthorizationHoldUncancel: Billing__AuthorizationHold__UncancelPayload;
    billingBuildCharge: Billing__BuildChargePayload;
    billingChargeRefund: RefundPayload;
    billingInvoiceForgive: Billing__Invoice__ForgivePayload;
    billingInvoiceMarkAsPaid: Billing__Invoice__MarkAsPaidPayload;
    billingInvoiceReBill: Billing__Invoice__ReBillPayload;
    billingReceiptSend: SendPayload;
    billingSourceBuild: Billing__Source__BuildPayload;
    billingSourceDefault: Billing__Source__DefaultPayload;
    billingSourceDelete: Billing__Source__DeletePayload;
    bookSelfStorageReservation?: Maybe<SelfStorage__Reservation__BuildPayload>;
    buildCustomerTicket: BuildPayload;
    buildCustomerTicketFromCall: BuildFromCallPayload;
    buildDepot: BuildDepotPayload;
    buildFulfillmentExpectation: Logistics__BuildFulfillmentExpectationPayload;
    buildInteractionFromCall: BuildInteractionFromCallPayload;
    buildLogisticsAccount: Logistics__BuildAccountPayload;
    buildLogisticsLoad: Logistics__BuildLoadPayload;
    buildLogisticsPurchaseOrder: Logistics__BuildPurchaseOrderPayload;
    buildLogisticsSku: Logistics__BuildSkuPayload;
    buildMessage: BuildMessagePayload;
    buildNote: BuildNotePayload;
    buildOpsAuditAnswers: BuildOpsAuditAnswersPayload;
    buildOrModifyPalletGrade: DockApi__BuildOrModifyPalletGradePayload;
    buildOrderEventAuditImage: DockApi__BuildOrderEventAuditImagePayload;
    buildPallet: DockApi__BuildPalletPayload;
    buildPalletRequest: BuildPalletRequestPayload;
    buildPalletType: BuildPalletTypePayload;
    buildParcelCarrierAccount: Logistics__BuildParcelCarrierAccountPayload;
    buildTestOrderRequest: BuildTestOrderRequestPayload;
    buildTicket: BuildTicketPayload;
    buildWarehouse: BuildWarehousePayload;
    buildZendeskTicket: BuildZendeskTicketPayload;
    cancelAccount?: Maybe<Account__CancelPayload>;
    cancelLogisticsLoad: Logistics__CancelLoadPayload;
    cancelPaymentPlanOffer: PaymentPlan__Offer__CancelPayload;
    cancelRateAdjustmentScheduling: RateAdjustment__CancelSchedulingPayload;
    cancelSelfStorageRental: SelfStorage__Rental__CancelPayload;
    cancelSelfStorageReservation: SelfStorage__Reservation__CancelPayload;
    changeAccountSettings: ChangeAccountSettingsPayload;
    closeClaim: ClosePayload;
    completeLoad: Logistics__CompleteLoadPayload;
    confirmRateAdjustmentUpload: RateAdjustment__ConfirmUploadPayload;
    connectPhoneCall: ConnectPhoneCallPayload;
    containersAccountCreate: Containers__AccountCreatePayload;
    contractorPasswordResetRequestApprove: ContractorPasswordResetRequest__ApprovePayload;
    couponActivate: Marketing__CouponActivatePayload;
    couponCreate: Marketing__CouponCreatePayload;
    couponDeactivate: Marketing__CouponDeactivatePayload;
    couponUpdate: Marketing__CouponUpdatePayload;
    createAppointmentQuote?: Maybe<CreateAppointmentQuotePayload>;
    createAuditException: CreateAuditExceptionPayload;
    createFollowUpMove?: Maybe<Moving__CreateFollowUpMovePayload>;
    createLaborClock: CreateLaborClockPayload;
    deleteLaborClock: DeleteLaborClockPayload;
    deletePalletItems: Logistics__DeletePalletItemsPayload;
    deletePalletRequest: DeletePalletRequestPayload;
    destroySelfStoragePromotionBatch: SelfStorage__DestroyPromotionBatchPayload;
    dispatchEventCreate: DockApi__Dispatch__Event__EventCreatePayload;
    dispatchTaskTeamUpsert: DockApi__Dispatch__Task__TeamUpsertPayload;
    dispatchUpdate: DispatchUpdatePayload;
    disposalCreate: FieldApi__DisposalCreatePayload;
    disposalItemStage: DockApi__DisposalItemStagePayload;
    editAuditException: EditAuditExceptionPayload;
    estimationEstimatedItemsCreate?: Maybe<Estimation__EstimatedItemsCreatePayload>;
    estimationOverrideEstimatedCuft?: Maybe<Estimation__OverrideEstimatedCuftPayload>;
    extendOffer: Claims__ExtendOfferPayload;
    extendPaymentPlanOffer: PaymentPlan__Offer__ExtendPayload;
    featureFlagUpsert?: Maybe<FeatureFlagUpsertPayload>;
    generateSelfStorageGateCode: SelfStorage__GenerateGateCodePayload;
    generateSelfStorageSigning: SelfStorage__GenerateSigningPayload;
    generateTestPassword: GenerateTestPasswordPayload;
    hrsLocationUpsert?: Maybe<Hrs__LocationUpsertPayload>;
    hrsProfileDefaultUpsert?: Maybe<Hrs__ProfileDefaultUpsertPayload>;
    hrsRoleDefaultUpsert?: Maybe<Hrs__RoleDefaultUpsertPayload>;
    inboundLoadImageUpdate: Logistics__InboundLoadImageUpdatePayload;
    inboundLoadUnloadTeamCreate: Logistics__InboundLoadUnloadTeamCreatePayload;
    ingestionUploadSave: Ingestion__Upload__SavePayload;
    itemActivate: ItemActivatePayload;
    itemDeactivate: ItemDeactivatePayload;
    itemDispose: DockApi__ItemDisposePayload;
    itemManualReturn: ItemManualReturnPayload;
    itemOrderDelete: DockApi__ItemOrderDeletePayload;
    landingLocationCityUpsert?: Maybe<Landing__Location__CityUpsertPayload>;
    landingLocationContentUpsert?: Maybe<Landing__Location__ContentUpsertPayload>;
    landingLocationNeighborhoodUpsert?: Maybe<Landing__Location__NeighborhoodUpsertPayload>;
    landingLocationRouteUpsert?: Maybe<Landing__Location__RouteUpsertPayload>;
    landingLocationStateUpsert?: Maybe<Landing__Location__StateUpsertPayload>;
    leadCreate?: Maybe<LeadCreatePayload>;
    markMessagesRead: MarkMessagesReadPayload;
    messageCreate: MessageCreatePayload;
    messageUpdate: MessageUpdatePayload;
    modifyAuction: ModifyAuctionPayload;
    modifyClaimAssessments: Claims__ModifyAssessmentsPayload;
    modifyClaimItemSelections: Claims__ModifyItemSelectionsPayload;
    modifyClaimOwner: Claims__ModifyOwnerPayload;
    modifyCustomerTicket: ModifyPayload;
    modifyDepot: ModifyDepotPayload;
    modifyItemCategory: ModifyItemCategoryPayload;
    modifyLaborClock: ModifyLaborClockPayload;
    modifyLocation: DockApi__ModifyLocationPayload;
    modifyLogisticsLoad: Logistics__ModifyLoadPayload;
    modifyLogisticsPurchaseOrder: Logistics__ModifyPurchaseOrderPayload;
    modifyLogisticsSku: Logistics__ModifySkuPayload;
    modifyMovingClocks: ModifyMovingClocksPayload;
    modifyNote: ModifyNotePayload;
    modifyPallet: DockApi__ModifyPalletPayload;
    modifyPalletType: ModifyPalletTypePayload;
    modifyParcelCarrierAccount: Logistics__ModifyParcelCarrierAccountPayload;
    modifyRegion: ModifyRegionPayload;
    modifySelfStorageBilling: SelfStorage__Billing__ModifyPayload;
    modifySelfStorageDefaultSource: SelfStorage__Billing__DefaultSource__ModifyPayload;
    modifySelfStorageReservation: SelfStorage__Reservation__ModifyPayload;
    modifySelfStorageThirdPartyReservation: SelfStorage__Reservation__ThirdPartyModifyPayload;
    modifySelfStorageUnit: SelfStorage__Unit__ModifyPayload;
    modifyWarehouse: ModifyWarehousePayload;
    moveItems: Logistics__MoveItemsPayload;
    moveItemsByCount: Logistics__MoveItemsByCountPayload;
    movePalletFromTrailer: Logistics__MovePalletFromTrailerPayload;
    movePalletToLoading: Logistics__MovePalletToLoadingPayload;
    movePalletToTrailer: Logistics__MovePalletToTrailerPayload;
    movingTransportWeightUpsert?: Maybe<Moving__LongDistance__TransportWeightUpsertPayload>;
    movingWalkthroughDelete: Moving__WalkthroughDeletePayload;
    movingWalkthroughUpdate: Moving__WalkthroughUpdatePayload;
    nightTransportRouteUpsert?: Maybe<RouteUpsertPayload>;
    notAuditableOpsAudit: NotAuditableOpsAuditPayload;
    noteDestroy?: Maybe<Note__DestroyPayload>;
    opsAuditConfigurationModify: ConfigurationModifyPayload;
    orderCancel?: Maybe<Order__CancelPayload>;
    orderComplete?: Maybe<Order__CompletePayload>;
    orderCouponUpdate?: Maybe<Order__CouponUpdatePayload>;
    orderEventCreate: OrderEventCreatePayload;
    orderLeadTimeUpsert?: Maybe<OrderLeadTimeUpsertPayload>;
    orderReschedule?: Maybe<ReschedulePayload>;
    orderTaskOrderCreate?: Maybe<Order__TaskOrderCreatePayload>;
    orderTasksUpdate?: Maybe<Order__TasksUpdatePayload>;
    orderUpdate?: Maybe<Order__UpdatePayload>;
    orderUserUpdate?: Maybe<Order__UserUpdatePayload>;
    outboundFacilityOrderComplete: DockApi__OutboundFacilityOrderCompletePayload;
    overlockSelfStorageRental: SelfStorage__Rental__OverlockPayload;
    pauseConfiguration: PauseConfigurationPayload;
    previewRateAdjustmentUpload: RateAdjustment__PreviewUploadPayload;
    processCustomerTicketFromCall: ProcessFromCallPayload;
    ptoRequestGroupReview: Workforce__PtoRequestGroupReviewTypePayload;
    regenerateDocuments: RegenerateDocumentsPayload;
    regionCreate: RegionCreatePayload;
    removeDoorAssignmentFromLoad: Logistics__RemoveDoorAssignmentFromLoadPayload;
    rentSelfStorage: SelfStorage__RentPayload;
    reopenClaim: Claims__ReopenPayload;
    reopenCustomerTicket: ReopenPayload;
    reopenLogisticsLoad: Logistics__ReopenLoadPayload;
    resendIterableNotification: ResendIterableNotificationPayload;
    resendNotification: ResendNotificationPayload;
    resendTodayAppUrl: ResendTodayAppUrlPayload;
    resolveCustomerTicket: ResolvePayload;
    restockUnexpectedItem: FieldApi__RestockUnexpectedItemPayload;
    reteachLogisticsSku: Logistics__ReteachSkuPayload;
    returnUnexpectedItem: FieldApi__ReturnUnexpectedItemPayload;
    routeRetailDelivery: DockApi__RouteRetailDeliveryPayload;
    saveDraft: SaveDraftPayload;
    saveOffer: Claims__SaveOfferPayload;
    savePaymentPlanOffer: PaymentPlan__Offer__SavePayload;
    savePricingUpload: Pricing__Upload__SavePayload;
    saveSelfStoragePromotionBatch: SelfStorage__SavePromotionBatchPayload;
    saveSelfStorageUpload: SelfStorage__Upload__SavePayload;
    sendgridSuppressionReset: ResetPayload;
    shiftCreate: Workforce__ShiftCreatePayload;
    shiftDelete: Workforce__ShiftDeletePayload;
    shiftUpdate: Workforce__ShiftUpdatePayload;
    shipmentImageUpdate: DockApi__ShipmentImageUpdatePayload;
    shipmentItemCreate: DockApi__ShipmentItemCreatePayload;
    signatureApprovalCreate: SignatureApprovalCreatePayload;
    skipOpsAudit: SkipOpsAuditPayload;
    skuBulkUpload: Logistics__Sku__BulkUploadPayload;
    skuDelete: Logistics__SkuDeletePayload;
    skuMerge: Logistics__SkuMergePayload;
    skuOptOutBuild: Logistics__SkuOptOutBuildPayload;
    startLaborClock: DockApi__StartLaborClockPayload;
    storageTreasuresAuctionCancel: StorageTreasures__AuctionCancelPayload;
    suspendAccount?: Maybe<Account__SuspendPayload>;
    trainingUploadAttestationCreate: TrainingUpload__AttestationCreatePayload;
    trainingUploadCreate: TrainingUpload__CreatePayload;
    trainingUploadStatusUpdate: TrainingUpload__StatusUpdatePayload;
    transfersSealTrailer: DockApi__Transfers__SealTrailerPayload;
    unpauseConfiguration: UnpauseConfigurationPayload;
    updateAccountPricing: Account__UpdatePricingPayload;
    updateLoadImages: Logistics__UpdateLoadImagesPayload;
    updateLoadTextField: Logistics__UpdateLoadTextFieldPayload;
    updateLogisticsSkuImage: Logistics__UpdateSkuImagePayload;
    updateLogisticsSkuImages: Logistics__UpdateSkuImagesPayload;
    updateOutboundLoadImage: Logistics__UpdateOutboundLoadImagePayload;
    updatePalletImage: Logistics__UpdatePalletImagePayload;
    updatePalletWeight: Logistics__UpdatePalletWeightPayload;
    userActivate: UserActivatePayload;
    userCreate: UserCreatePayload;
    userDelete: UserDeletePayload;
    userForceLeaveCallCenter: UserForceLeaveCallCenterPayload;
    userUpdate: UserUpdatePayload;
    vehicleTripMaterialCountCreate: DockApi__VehicleTrip__MaterialCount__CreatePayload;
    vehicleTripMaterialCountTrust: DockApi__VehicleTrip__MaterialCount__TrustPayload;
    vehicleTypeCreate: VehicleTypeCreatePayload;
    vehicleTypeUpdate: VehicleTypeUpdatePayload;
    verifyClaim: Claims__VerifyPayload;
    zendeskUserBulkMerge: ZendeskUserBulkMergePayload;
  };

export type MutationAcceptPaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
  offlineSource?: InputMaybe<Billing__Invoice__OfflinePaymentChargeInput>;
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type MutationAccountAddressUpsertArgs = {
  accountID: Scalars['ID'];
  input: AddressInput;
};

export type MutationAccountBanUpdateArgs = {
  accountID: Scalars['ID'];
  input: Account__BanUpdateInput;
};

export type MutationAccountImpersonateCustomerLinkCreateArgs = {
  customerID: Scalars['ID'];
};

export type MutationAccountReferralCodeUpdateArgs = {
  accountID: Scalars['ID'];
  code: Scalars['String'];
};

export type MutationAddDoorAssignmentToLoadArgs = {
  input: Logistics__AddDoorAssignmentToLoadInput;
};

export type MutationAddPalletBarcodedItemsArgs = {
  input: Logistics__AddPalletBarcodedItemsInput;
};

export type MutationAddPalletItemsArgs = {
  input: Logistics__AddPalletItemsInput;
};

export type MutationAssignLoadPalletArgs = {
  loadID: Scalars['ID'];
  palletBarcode: Scalars['String'];
  unassign?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAttachPalletLabelArgs = {
  input: Logistics__AttachPalletLabelInput;
};

export type MutationAttendancePolicyViolationCreateArgs = {
  input: AttendancePolicyViolation__Create__Input;
};

export type MutationAttendancePolicyViolationReviewArgs = {
  input: AttendancePolicyViolation__Review__Input;
};

export type MutationAttestationEventResolveArgs = {
  eventId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  shiftInput?: InputMaybe<Workforce__ShiftInput>;
};

export type MutationAuctionUploadCreateArgs = {
  input: Auction__UploadInput;
};

export type MutationAutomationPickupCompleteArgs = {
  orderID: Scalars['ID'];
};

export type MutationAutomationReturnRouteArgs = {
  orderID: Scalars['ID'];
};

export type MutationAvailabilityFacilityCuftLimitsCreateArgs = {
  input: Availability__FacilityCuftLimitsCreateInput;
};

export type MutationAvailabilityFacilityCuftLimitsDestroyArgs = {
  IDs: Array<Scalars['ID']>;
};

export type MutationAvailabilityGlobalUnavailabilitiesCreateArgs = {
  input: Availability__GlobalUnavailabilitiesCreateInput;
};

export type MutationAvailabilityGlobalUnavailabilitiesDestroyArgs = {
  IDs: Array<Scalars['ID']>;
};

export type MutationAvailabilityJobAvailabilitiesCreateArgs = {
  input: Availability__JobAvailabilitiesCreateInput;
};

export type MutationAvailabilityJobAvailabilitiesDestroyArgs = {
  input: Availability__JobAvailabilitiesDestroyInput;
};

export type MutationAvailabilityLaborAdjustmentsCreateArgs = {
  input: Availability__LaborAdjustmentsCreateInput;
};

export type MutationAvailabilityLaborAdjustmentsDeleteArgs = {
  IDs: Array<Scalars['ID']>;
};

export type MutationAvailabilityOperatingScheduleTemplateCreateArgs = {
  input: Availability__OperatingScheduleTemplateCreate__Input;
};

export type MutationAvailabilityRescheduleOffersCreateArgs = {
  input: Availability__RescheduleOffersCreateInput;
};

export type MutationAvailabilityRescheduleOffersDeleteArgs = {
  IDs: Array<Scalars['ID']>;
};

export type MutationAvatarDeleteArgs = {
  userID: Scalars['ID'];
};

export type MutationAvatarUploadArgs = {
  url: Scalars['String'];
  userID: Scalars['ID'];
};

export type MutationBillingAuthorizationHoldCancelArgs = {
  orderID: Scalars['ID'];
};

export type MutationBillingAuthorizationHoldUncancelArgs = {
  orderID: Scalars['ID'];
};

export type MutationBillingBuildChargeArgs = {
  input: Billing__ChargeInput;
};

export type MutationBillingChargeRefundArgs = {
  id: Scalars['ID'];
  input: Billing__Charge__RefundInput;
};

export type MutationBillingInvoiceForgiveArgs = {
  input: Billing__Invoice__ForgiveInput;
  invoiceID: Scalars['ID'];
};

export type MutationBillingInvoiceMarkAsPaidArgs = {
  input: Billing__Invoice__MarkAsPaidInput;
  invoiceID: Scalars['ID'];
};

export type MutationBillingInvoiceReBillArgs = {
  input: Billing__Invoice__ReBillInput;
  invoiceID: Scalars['ID'];
};

export type MutationBillingReceiptSendArgs = {
  input: Billing__Receipt__SendInput;
};

export type MutationBillingSourceBuildArgs = {
  accountID: Scalars['ID'];
  token: Scalars['ID'];
};

export type MutationBillingSourceDefaultArgs = {
  id: Scalars['ID'];
};

export type MutationBillingSourceDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationBookSelfStorageReservationArgs = {
  input: SelfStorage__Reservation__BuildInput;
};

export type MutationBuildCustomerTicketArgs = {
  input: CustomerTickets__BuildInput;
};

export type MutationBuildCustomerTicketFromCallArgs = {
  input: CustomerTickets__BuildFromCallInput;
};

export type MutationBuildDepotArgs = {
  input: DepotInput;
};

export type MutationBuildFulfillmentExpectationArgs = {
  input: Logistics__FulfillmentExpectationInputType;
};

export type MutationBuildInteractionFromCallArgs = {
  input: CustomerTickets__BuildInteractionFromCallInput;
};

export type MutationBuildLogisticsAccountArgs = {
  input: Logistics__AccountInput;
};

export type MutationBuildLogisticsLoadArgs = {
  input: Logistics__LoadInputType;
};

export type MutationBuildLogisticsPurchaseOrderArgs = {
  input: Logistics__PurchaseOrderInput;
};

export type MutationBuildLogisticsSkuArgs = {
  input: Logistics__BuildSkuInput;
};

export type MutationBuildMessageArgs = {
  input: MessageInput;
};

export type MutationBuildNoteArgs = {
  input: NoteInput;
  notable: NoteNotableInput;
};

export type MutationBuildOpsAuditAnswersArgs = {
  auditAnswers: Array<OpsAudit__AuditAnswersInputType>;
};

export type MutationBuildOrModifyPalletGradeArgs = {
  input: DockApi__PalletGradeInput;
};

export type MutationBuildOrderEventAuditImageArgs = {
  input: DockApi__OrderEventInput;
};

export type MutationBuildPalletArgs = {
  input: PalletInput;
};

export type MutationBuildPalletRequestArgs = {
  input: PalletRequestInput;
};

export type MutationBuildPalletTypeArgs = {
  input: PalletTypeInput;
};

export type MutationBuildParcelCarrierAccountArgs = {
  input: Logistics__ParcelCarrierAccountInput;
};

export type MutationBuildTestOrderRequestArgs = {
  input: Automation__TestOrderRequestInput;
};

export type MutationBuildTicketArgs = {
  input: BuildTicketInput;
};

export type MutationBuildWarehouseArgs = {
  input: WarehouseInput;
};

export type MutationBuildZendeskTicketArgs = {
  customerTicketID: Scalars['ID'];
};

export type MutationCancelAccountArgs = {
  accountID: Scalars['ID'];
};

export type MutationCancelLogisticsLoadArgs = {
  input: Logistics__CancelLoadInput;
};

export type MutationCancelPaymentPlanOfferArgs = {
  offerID: Scalars['ID'];
};

export type MutationCancelRateAdjustmentSchedulingArgs = {
  cancelReason: RateAdjustment__Scheduling__CancelReason;
  id: Scalars['ID'];
};

export type MutationCancelSelfStorageRentalArgs = {
  id: Scalars['ID'];
  prorate: Scalars['Boolean'];
  skipDelinquentVerification?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCancelSelfStorageReservationArgs = {
  id: Scalars['ID'];
};

export type MutationChangeAccountSettingsArgs = {
  input: AccountSettingsInput;
};

export type MutationCloseClaimArgs = {
  claimID: Scalars['ID'];
  reason: Scalars['String'];
};

export type MutationCompleteLoadArgs = {
  input: Logistics__CompleteLoadInput;
};

export type MutationConfirmRateAdjustmentUploadArgs = {
  id: Scalars['ID'];
};

export type MutationConnectPhoneCallArgs = {
  input: ConnectPhoneCallInput;
};

export type MutationContainersAccountCreateArgs = {
  customerInput: Containers__CustomerInput;
  zip: Scalars['String'];
};

export type MutationContractorPasswordResetRequestApproveArgs = {
  input: ContractorPasswordResetRequest__Approve__Input;
};

export type MutationCouponActivateArgs = {
  id: Scalars['ID'];
};

export type MutationCouponCreateArgs = {
  input: Coupon__Input;
};

export type MutationCouponDeactivateArgs = {
  id: Scalars['ID'];
};

export type MutationCouponUpdateArgs = {
  id: Scalars['ID'];
  input: Coupon__Input;
};

export type MutationCreateAppointmentQuoteArgs = {
  cuft: Scalars['Float'];
  quotableId: Scalars['ID'];
  quotableType: Pricing__QuotableEnum;
  rateGroupId: Scalars['ID'];
  source: Pricing__SourceEnum;
  zip: Scalars['String'];
};

export type MutationCreateAuditExceptionArgs = {
  input: OpsAudit__AuditExceptionInputType;
};

export type MutationCreateFollowUpMoveArgs = {
  input: Moving__CreateFollowUpMoveInput;
};

export type MutationCreateLaborClockArgs = {
  input: CreateLaborClockInput;
};

export type MutationDeleteLaborClockArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePalletItemsArgs = {
  input: Logistics__DeletePalletItemsInput;
};

export type MutationDeletePalletRequestArgs = {
  id: Scalars['ID'];
};

export type MutationDestroySelfStoragePromotionBatchArgs = {
  input: SelfStorage__DestroyPromotionBatchInput;
};

export type MutationDispatchEventCreateArgs = {
  input: DockApi__Dispatch__Event__EventCreateInput;
};

export type MutationDispatchTaskTeamUpsertArgs = {
  input: DockApi__Dispatch__Task__TeamUpsertInput;
};

export type MutationDispatchUpdateArgs = {
  id: Scalars['ID'];
  input: DispatchInput;
};

export type MutationDisposalCreateArgs = {
  parentID: Scalars['ID'];
};

export type MutationDisposalItemStageArgs = {
  itemBarcode?: InputMaybe<Scalars['String']>;
  itemID?: InputMaybe<Scalars['ID']>;
  locationValue: Scalars['String'];
};

export type MutationEditAuditExceptionArgs = {
  input: OpsAudit__AuditExceptionInputType;
};

export type MutationEstimationEstimatedItemsCreateArgs = {
  input: Estimation__EstimatedItemsCreateInput;
};

export type MutationEstimationOverrideEstimatedCuftArgs = {
  estimatedCuft?: InputMaybe<Scalars['Int']>;
  orderID: Scalars['ID'];
  reason?: InputMaybe<Estimation__EstimatedCuftOverrideReason>;
};

export type MutationExtendOfferArgs = {
  input: Claims__ExtendOffer__Input;
};

export type MutationExtendPaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
};

export type MutationFeatureFlagUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: FeatureFlagInput;
};

export type MutationGenerateSelfStorageGateCodeArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  facilityID: Scalars['ID'];
};

export type MutationGenerateSelfStorageSigningArgs = {
  channel: Scalars['ID'];
  input: SelfStorage__RentInput;
};

export type MutationGenerateTestPasswordArgs = {
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
};

export type MutationHrsLocationUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__LocationInput;
};

export type MutationHrsProfileDefaultUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__ProfileDefaultInput;
};

export type MutationHrsRoleDefaultUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__RoleDefaultInput;
};

export type MutationInboundLoadImageUpdateArgs = {
  input: Logistics__InboundLoadImageUpdateInput;
};

export type MutationInboundLoadUnloadTeamCreateArgs = {
  input: Logistics__InboundLoadUnloadTeamCreateInput;
};

export type MutationIngestionUploadSaveArgs = {
  input: Ingestion__UploadInput;
};

export type MutationItemActivateArgs = {
  id: Scalars['ID'];
};

export type MutationItemDeactivateArgs = {
  id: Scalars['ID'];
};

export type MutationItemDisposeArgs = {
  ensureOnDisposal?: InputMaybe<Scalars['Boolean']>;
  itemBarcode?: InputMaybe<Scalars['String']>;
  itemID?: InputMaybe<Scalars['ID']>;
};

export type MutationItemManualReturnArgs = {
  itemID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type MutationItemOrderDeleteArgs = {
  input: DockApi__ItemOrderDelete__Input;
};

export type MutationLandingLocationCityUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__CityInput;
};

export type MutationLandingLocationContentUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__ContentInput;
};

export type MutationLandingLocationNeighborhoodUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__NeighborhoodInput;
};

export type MutationLandingLocationRouteUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__RouteInput;
};

export type MutationLandingLocationStateUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__StateInput;
};

export type MutationLeadCreateArgs = {
  input: Crm__LeadInput;
};

export type MutationMarkMessagesReadArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationMessageCreateArgs = {
  input: Ticket__MessageInput;
  sendPushNotification: Scalars['Boolean'];
};

export type MutationMessageUpdateArgs = {
  id: Scalars['ID'];
  input: Ticket__MessageInput;
};

export type MutationModifyAuctionArgs = {
  id: Scalars['ID'];
};

export type MutationModifyClaimAssessmentsArgs = {
  input: Claims__ModifyAssessments__Input;
};

export type MutationModifyClaimItemSelectionsArgs = {
  claimID: Scalars['ID'];
  itemSelections: Array<Claims__ItemSelectionInput>;
};

export type MutationModifyClaimOwnerArgs = {
  claimID: Scalars['ID'];
  ownerID?: InputMaybe<Scalars['ID']>;
};

export type MutationModifyCustomerTicketArgs = {
  input: CustomerTickets__ModifyInput;
};

export type MutationModifyDepotArgs = {
  id: Scalars['ID'];
  input: DepotInput;
};

export type MutationModifyItemCategoryArgs = {
  input: OpsAudit__ModifyItemCategoryInput;
};

export type MutationModifyLaborClockArgs = {
  id: Scalars['ID'];
  input: LaborClockInput;
};

export type MutationModifyLocationArgs = {
  input: DockApi__LocationInput;
};

export type MutationModifyLogisticsLoadArgs = {
  input: Logistics__LoadInputType;
};

export type MutationModifyLogisticsPurchaseOrderArgs = {
  input: Logistics__PurchaseOrderInput;
};

export type MutationModifyLogisticsSkuArgs = {
  input: Logistics__ModifySkuInput;
};

export type MutationModifyMovingClocksArgs = {
  input: Moving__ClocksInput;
  orderID: Scalars['ID'];
};

export type MutationModifyNoteArgs = {
  body: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationModifyPalletArgs = {
  id: Scalars['ID'];
  input: PalletInput;
};

export type MutationModifyPalletTypeArgs = {
  id: Scalars['ID'];
  input: PalletTypeInput;
};

export type MutationModifyParcelCarrierAccountArgs = {
  input: Logistics__ParcelCarrierAccountInput;
};

export type MutationModifyRegionArgs = {
  id: Scalars['ID'];
  input: ModifyRegionInput;
};

export type MutationModifySelfStorageBillingArgs = {
  accountID: Scalars['ID'];
  inputs: Array<SelfStorage__BillingLineInput>;
};

export type MutationModifySelfStorageDefaultSourceArgs = {
  billing: SelfStorage__Rental__BillingEnum;
  sourceID?: InputMaybe<Scalars['ID']>;
  subscriptionID: Scalars['ID'];
};

export type MutationModifySelfStorageReservationArgs = {
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ModifyInput;
};

export type MutationModifySelfStorageThirdPartyReservationArgs = {
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ThirdPartyModifyInput;
};

export type MutationModifySelfStorageUnitArgs = {
  id: Scalars['ID'];
  input: SelfStorage__UnitInput;
};

export type MutationModifyWarehouseArgs = {
  id: Scalars['ID'];
  input: WarehouseInput;
};

export type MutationMoveItemsArgs = {
  input: Logistics__MoveItemsInput;
};

export type MutationMoveItemsByCountArgs = {
  input: Logistics__MoveItemsByCountInput;
};

export type MutationMovePalletFromTrailerArgs = {
  input: Logistics__MovePalletFromTrailerInput;
};

export type MutationMovePalletToLoadingArgs = {
  input: Logistics__MovePalletToLoadingInput;
};

export type MutationMovePalletToTrailerArgs = {
  input: Logistics__MovePalletToTrailerInput;
};

export type MutationMovingTransportWeightUpsertArgs = {
  input: Moving__LongDistance__TransportWeightUpsertInput;
};

export type MutationMovingWalkthroughDeleteArgs = {
  orderID: Scalars['ID'];
};

export type MutationMovingWalkthroughUpdateArgs = {
  input: Moving__WalkthroughInput;
  orderID: Scalars['ID'];
};

export type MutationNightTransportRouteUpsertArgs = {
  input: NightTransport__RouteUpsertInput;
};

export type MutationNotAuditableOpsAuditArgs = {
  auditID: Scalars['ID'];
};

export type MutationNoteDestroyArgs = {
  id: Scalars['ID'];
};

export type MutationOpsAuditConfigurationModifyArgs = {
  input: OpsAudit__ConfigurationModifyInput;
};

export type MutationOrderCancelArgs = {
  input: Order__CancelInput;
};

export type MutationOrderCompleteArgs = {
  orderID: Scalars['ID'];
};

export type MutationOrderCouponUpdateArgs = {
  couponID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
};

export type MutationOrderEventCreateArgs = {
  input: OrderEventInput;
};

export type MutationOrderLeadTimeUpsertArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: OrderLeadTimeInput;
};

export type MutationOrderRescheduleArgs = {
  input: OrderRescheduleInput;
};

export type MutationOrderTaskOrderCreateArgs = {
  input: Order__TaskOrderInput;
};

export type MutationOrderTasksUpdateArgs = {
  input: Order__TasksUpdateInput;
};

export type MutationOrderUpdateArgs = {
  input: Order__Update__Input;
};

export type MutationOrderUserUpdateArgs = {
  orderID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type MutationOutboundFacilityOrderCompleteArgs = {
  input: DockApi__OutboundFacilityOrderCompleteInput;
};

export type MutationOverlockSelfStorageRentalArgs = {
  id: Scalars['ID'];
  overlocked: Scalars['Boolean'];
};

export type MutationPauseConfigurationArgs = {
  configurationID: Scalars['ID'];
};

export type MutationPreviewRateAdjustmentUploadArgs = {
  input: RateAdjustment__UploadInput;
};

export type MutationProcessCustomerTicketFromCallArgs = {
  input: CustomerTickets__ProcessFromCallInput;
};

export type MutationPtoRequestGroupReviewArgs = {
  input: Workforce__PtoRequestGroupReview__Input;
};

export type MutationRegenerateDocumentsArgs = {
  signatureRequestID: Scalars['ID'];
};

export type MutationRegionCreateArgs = {
  input: RegionCreate__Input;
};

export type MutationRemoveDoorAssignmentFromLoadArgs = {
  input: Logistics__RemoveDoorAssignmentFromLoadInput;
};

export type MutationRentSelfStorageArgs = {
  input: SelfStorage__RentInput;
};

export type MutationReopenClaimArgs = {
  claimID: Scalars['ID'];
};

export type MutationReopenCustomerTicketArgs = {
  actionRequiredAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type MutationReopenLogisticsLoadArgs = {
  id: Scalars['ID'];
};

export type MutationResendIterableNotificationArgs = {
  accountId: Scalars['ID'];
  kind: Scalars['String'];
};

export type MutationResendNotificationArgs = {
  notificationId: Scalars['ID'];
};

export type MutationResendTodayAppUrlArgs = {
  orderId: Scalars['ID'];
};

export type MutationResolveCustomerTicketArgs = {
  id: Scalars['ID'];
};

export type MutationRestockUnexpectedItemArgs = {
  input: FieldApi__RestockUnexpectedItem__Input;
};

export type MutationReteachLogisticsSkuArgs = {
  id: Scalars['ID'];
};

export type MutationReturnUnexpectedItemArgs = {
  input: FieldApi__ReturnUnexpectedItem__Input;
};

export type MutationRouteRetailDeliveryArgs = {
  orderID: Scalars['ID'];
};

export type MutationSaveDraftArgs = {
  input: DraftInput;
};

export type MutationSaveOfferArgs = {
  input: Claims__SaveOffer__Input;
};

export type MutationSavePaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
};

export type MutationSavePricingUploadArgs = {
  input: Pricing__UploadInput;
};

export type MutationSaveSelfStoragePromotionBatchArgs = {
  input: SelfStorage__SavePromotionBatchInput;
};

export type MutationSaveSelfStorageUploadArgs = {
  input: SelfStorage__UploadInput;
};

export type MutationSendgridSuppressionResetArgs = {
  email: Scalars['String'];
};

export type MutationShiftCreateArgs = {
  input: Workforce__ShiftInput;
};

export type MutationShiftDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationShiftUpdateArgs = {
  input: Workforce__ShiftInput;
};

export type MutationShipmentImageUpdateArgs = {
  input: DockApi__ShipmentImageUpdateInput;
};

export type MutationShipmentItemCreateArgs = {
  input: DockApi__ShipmentItemCreateInput;
};

export type MutationSignatureApprovalCreateArgs = {
  orderId: Scalars['ID'];
};

export type MutationSkipOpsAuditArgs = {
  auditID: Scalars['ID'];
};

export type MutationSkuBulkUploadArgs = {
  input: Logistics__Sku__BulkUploadInput;
};

export type MutationSkuDeleteArgs = {
  skuID: Scalars['ID'];
};

export type MutationSkuMergeArgs = {
  duplicateSkuID: Scalars['ID'];
  skuID: Scalars['ID'];
};

export type MutationSkuOptOutBuildArgs = {
  input: Logistics__Sku__OptOutBuildInputType;
};

export type MutationStartLaborClockArgs = {
  input: DockApi__StartLaborClockInput;
};

export type MutationStorageTreasuresAuctionCancelArgs = {
  reason: Scalars['String'];
  tealAuctionID: Scalars['ID'];
};

export type MutationSuspendAccountArgs = {
  accountID: Scalars['ID'];
};

export type MutationTrainingUploadAttestationCreateArgs = {
  input: TrainingUpload__AttestationCreate__Input;
};

export type MutationTrainingUploadCreateArgs = {
  input: TrainingUpload__Create__Input;
};

export type MutationTrainingUploadStatusUpdateArgs = {
  input: TrainingUpload__StatusUpdate__Input;
};

export type MutationTransfersSealTrailerArgs = {
  input: DockApi__Transfers__SealTrailerInput;
};

export type MutationUnpauseConfigurationArgs = {
  configurationID: Scalars['ID'];
};

export type MutationUpdateAccountPricingArgs = {
  accountID: Scalars['ID'];
  input: Account__UpdatePricingInput;
};

export type MutationUpdateLoadImagesArgs = {
  input: Logistics__UpdateLoadImagesInput;
};

export type MutationUpdateLoadTextFieldArgs = {
  input: Logistics__UpdateLoadTextFieldInput;
};

export type MutationUpdateLogisticsSkuImageArgs = {
  input: Logistics__UpdateSkuImageInput;
};

export type MutationUpdateLogisticsSkuImagesArgs = {
  input: Logistics__UpdateSkuImagesInput;
};

export type MutationUpdateOutboundLoadImageArgs = {
  input: Logistics__UpdateOutboundLoadImageInput;
};

export type MutationUpdatePalletImageArgs = {
  input: Logistics__UpdatePalletImageInput;
};

export type MutationUpdatePalletWeightArgs = {
  input: Logistics__UpdatePalletWeightInput;
};

export type MutationUserActivateArgs = {
  id: Scalars['ID'];
};

export type MutationUserCreateArgs = {
  input: UserInput;
};

export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationUserForceLeaveCallCenterArgs = {
  id: Scalars['ID'];
};

export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  input: UserInput;
};

export type MutationVehicleTripMaterialCountCreateArgs = {
  input: DockApi__VehicleTrip__MaterialCount__CreateInput;
};

export type MutationVehicleTripMaterialCountTrustArgs = {
  materialCountID: Scalars['ID'];
};

export type MutationVehicleTypeCreateArgs = {
  input: VehicleTypeInput;
};

export type MutationVehicleTypeUpdateArgs = {
  id: Scalars['ID'];
  input: VehicleTypeInput;
};

export type MutationVerifyClaimArgs = {
  input: Claim__Verify__Input;
};

export type MutationZendeskUserBulkMergeArgs = {
  signedID: Scalars['ID'];
};

export type NightTransportMutationInterface = {
  nightTransportRouteUpsert?: Maybe<RouteUpsertPayload>;
};

export type NightTransportMutationInterfaceNightTransportRouteUpsertArgs = {
  input: NightTransport__RouteUpsertInput;
};

export type NightTransportQueryInterface = {
  nightTransportRequiredDriveTasks: Array<NightTransport__DriveTask>;
  nightTransportRouteTimelines: Array<NightTransport__RouteTimeline>;
  nightTransportRoutes: Array<NightTransport__Route>;
};

export type NightTransportQueryInterfaceNightTransportRequiredDriveTasksArgs = {
  input: NightTransport__DriveTask__Input;
};

export type NightTransportQueryInterfaceNightTransportRouteTimelinesArgs = {
  input: NightTransport__RouteTimelineInput;
};

export type NightTransportQueryInterfaceNightTransportRoutesArgs = {
  filters: NightTransport__Route__FiltersInput;
};

export type NightTransport__Carpool = {
  __typename?: 'NightTransport__Carpool';
  drive: NightTransport__Drive;
  id: Scalars['ID'];
};

export type NightTransport__Drive = {
  __typename?: 'NightTransport__Drive';
  destination: Facility;
  dispatch?: Maybe<Dispatch>;
  id: Scalars['ID'];
  origin: Facility;
  predecessor?: Maybe<NightTransport__Drive>;
  vehicle?: Maybe<Vehicle>;
};

export type NightTransport__DriveTask = {
  __typename?: 'NightTransport__DriveTask';
  destination: Facility;
  dispatch: Dispatch;
  origin?: Maybe<Facility>;
  vehicleSuggestion: NightTransport__DriveTask__VehicleSuggestion;
};

export type NightTransport__DriveTask__Input = {
  date: Scalars['Date'];
  geo: ClutterGeoEnum;
};

export type NightTransport__DriveTask__VehicleSuggestion = Dispatch__VehiclePreference | Vehicle;

export type NightTransport__Resupply = {
  __typename?: 'NightTransport__Resupply';
  dispatch: Dispatch;
  drive: NightTransport__Drive;
  id: Scalars['ID'];
  vehicle?: Maybe<Vehicle>;
};

export type NightTransport__Route = {
  __typename?: 'NightTransport__Route';
  arrival: Scalars['DateTime'];
  driver?: Maybe<User>;
  id: Scalars['ID'];
  origin: Facility;
  tasks: Array<NightTransport__Task>;
};

export type NightTransport__RouteTimeline = {
  __typename?: 'NightTransport__RouteTimeline';
  arrival: Scalars['DateTime'];
  driver: User;
  taskEvents: Array<NightTransport__RouteTimeline__TaskEvent>;
};

export type NightTransport__RouteTimelineInput = {
  routes: Array<NightTransport__RouteTimeline__RouteInput>;
};

export type NightTransport__RouteTimeline__ActionInput = {
  carpool?: InputMaybe<NightTransport__RouteTimeline__CarpoolInput>;
  drive?: InputMaybe<NightTransport__RouteTimeline__DriveInput>;
  resupply?: InputMaybe<NightTransport__RouteTimeline__ResupplyInput>;
};

export type NightTransport__RouteTimeline__CarpoolInput = {
  driveUUID: Scalars['ID'];
};

export type NightTransport__RouteTimeline__DriveInput = {
  destination: FacilityInput;
  dispatchID?: InputMaybe<Scalars['ID']>;
  origin: FacilityInput;
  predecessorUUID?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['ID'];
  vehicleID?: InputMaybe<Scalars['ID']>;
};

export type NightTransport__RouteTimeline__ResupplyInput = {
  dispatchID: Scalars['ID'];
  driveUUID: Scalars['ID'];
  vehicleID?: InputMaybe<Scalars['ID']>;
};

export type NightTransport__RouteTimeline__RouteInput = {
  arrival: Scalars['DateTime'];
  driverID?: InputMaybe<Scalars['ID']>;
  origin: FacilityInput;
  tasks: Array<NightTransport__RouteTimeline__TaskInput>;
};

export type NightTransport__RouteTimeline__TaskEvent = {
  __typename?: 'NightTransport__RouteTimeline__TaskEvent';
  category: NightTransport__RouteTimeline__TaskEvent__CategoryEnum;
  dispatchID?: Maybe<Scalars['ID']>;
  endTimestamp: Scalars['DateTime'];
  origin: Facility;
  startTimestamp: Scalars['DateTime'];
  uuid?: Maybe<Scalars['ID']>;
  vehicleName?: Maybe<Scalars['String']>;
};

export enum NightTransport__RouteTimeline__TaskEvent__CategoryEnum {
  Break = 'BREAK',
  Carpool = 'CARPOOL',
  Clockin = 'CLOCKIN',
  Clockout = 'CLOCKOUT',
  Deadtime = 'DEADTIME',
  Drive = 'DRIVE',
  Resupply = 'RESUPPLY',
}

export type NightTransport__RouteTimeline__TaskInput = {
  action: NightTransport__RouteTimeline__ActionInput;
  position: Scalars['Int'];
  uuid: Scalars['ID'];
};

export type NightTransport__RouteUpsertInput = {
  date: Scalars['Date'];
  geo: ClutterGeoEnum;
  routes: Array<NightTransport__RouteUpsert__RouteInput>;
};

export type NightTransport__RouteUpsert__ActionInput = {
  carpool?: InputMaybe<NightTransport__RouteUpsert__CarpoolInput>;
  drive?: InputMaybe<NightTransport__RouteUpsert__DriveInput>;
  resupply?: InputMaybe<NightTransport__RouteUpsert__ResupplyInput>;
};

export type NightTransport__RouteUpsert__CarpoolInput = {
  driveUUID: Scalars['ID'];
};

export type NightTransport__RouteUpsert__DriveInput = {
  destination: FacilityInput;
  dispatchID?: InputMaybe<Scalars['ID']>;
  origin: FacilityInput;
  predecessorUUID?: InputMaybe<Scalars['ID']>;
  uuid: Scalars['ID'];
  vehicleID?: InputMaybe<Scalars['ID']>;
};

export type NightTransport__RouteUpsert__ResupplyInput = {
  dispatchID: Scalars['ID'];
  driveUUID: Scalars['ID'];
  vehicleID?: InputMaybe<Scalars['ID']>;
};

export type NightTransport__RouteUpsert__RouteInput = {
  arrival: Scalars['DateTime'];
  driverID?: InputMaybe<Scalars['ID']>;
  origin: FacilityInput;
  tasks: Array<NightTransport__RouteUpsert__TaskInput>;
};

export type NightTransport__RouteUpsert__TaskInput = {
  action: NightTransport__RouteUpsert__ActionInput;
  position: Scalars['Int'];
  uuid: Scalars['ID'];
};

export type NightTransport__Route__FiltersInput = {
  date?: InputMaybe<Scalars['Date']>;
  geo?: InputMaybe<ClutterGeoEnum>;
};

export type NightTransport__Task = {
  __typename?: 'NightTransport__Task';
  action: NightTransport__Task__Action;
  id: Scalars['ID'];
  position: Scalars['Int'];
  route: NightTransport__Route;
};

export type NightTransport__Task__Action = NightTransport__Carpool | NightTransport__Drive | NightTransport__Resupply;

export type NoDetailAddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

/** Autogenerated return type of NotAuditableOpsAudit */
export type NotAuditableOpsAuditPayload = {
  __typename?: 'NotAuditableOpsAuditPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Note = {
  __typename?: 'Note';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  files: Array<ActiveStorageAttachment>;
  id: Scalars['ID'];
  notable: NoteNotable;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type NoteInput = {
  body: Scalars['String'];
  files?: InputMaybe<Array<Scalars['ID']>>;
};

export type NoteNotable = {
  __typename?: 'NoteNotable';
  id: Scalars['ID'];
  type: NoteNotableEnum;
};

export enum NoteNotableEnum {
  Account = 'ACCOUNT',
  Affiliate = 'AFFILIATE',
  Claim = 'CLAIM',
  CustomerTicket = 'CUSTOMER_TICKET',
  Dispatch = 'DISPATCH',
  Invoice = 'INVOICE',
  Item = 'ITEM',
  ItemGroup = 'ITEM_GROUP',
  Lead = 'LEAD',
  LogisticsLoad = 'LOGISTICS_LOAD',
  OpsAuditAudit = 'OPS_AUDIT_AUDIT',
  OpsAuditException = 'OPS_AUDIT_EXCEPTION',
  Order = 'ORDER',
  Pallet = 'PALLET',
  Payment = 'PAYMENT',
  PhoneCall = 'PHONE_CALL',
  SelfStorageReservation = 'SELF_STORAGE_RESERVATION',
  SelfStorageUnit = 'SELF_STORAGE_UNIT',
  Ticket = 'TICKET',
}

export type NoteNotableInput = {
  id: Scalars['ID'];
  type: NoteNotableEnum;
};

/** Autogenerated return type of Note__Destroy */
export type Note__DestroyPayload = {
  __typename?: 'Note__DestroyPayload';
  status: Status;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  deliveries: Array<NotificationDelivery>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  objectName?: Maybe<Scalars['String']>;
  provider: Notification__ProviderEnum;
  updatedAt: Scalars['DateTime'];
};

export type NotificationDelivery = {
  __typename?: 'NotificationDelivery';
  createdAt: Scalars['DateTime'];
  emailEvents: Array<EmailEvent>;
  id: Scalars['ID'];
  scheduled?: Maybe<Scalars['DateTime']>;
  sent?: Maybe<Scalars['DateTime']>;
  state: NotificationDelivery__State;
  triggerer?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum NotificationDelivery__State {
  Canceled = 'CANCELED',
  Processed = 'PROCESSED',
  Queued = 'QUEUED',
}

export enum Notification__ProviderEnum {
  Iterable = 'ITERABLE',
  System = 'SYSTEM',
}

export type OpenClose = {
  __typename?: 'OpenClose';
  /** e.g. 6:00 PM */
  close: Scalars['String'];
  /** e.g. 10:00 AM */
  open: Scalars['String'];
};

export type OperatingScheduleTemplate = {
  __typename?: 'OperatingScheduleTemplate';
  deletedAt: Scalars['DateTime'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['ID'];
  operatesFriday: Scalars['Boolean'];
  operatesMonday: Scalars['Boolean'];
  operatesSaturday: Scalars['Boolean'];
  operatesSunday: Scalars['Boolean'];
  operatesThursday: Scalars['Boolean'];
  operatesTuesday: Scalars['Boolean'];
  operatesWednesday: Scalars['Boolean'];
  orderSubtype?: Maybe<OrderSubtypeEnum>;
  orderType?: Maybe<OrderTypeEnum>;
};

export type OpsAuditMutationInterface = {
  buildOpsAuditAnswers: BuildOpsAuditAnswersPayload;
  createAuditException: CreateAuditExceptionPayload;
  editAuditException: EditAuditExceptionPayload;
  modifyItemCategory: ModifyItemCategoryPayload;
  notAuditableOpsAudit: NotAuditableOpsAuditPayload;
  opsAuditConfigurationModify: ConfigurationModifyPayload;
  pauseConfiguration: PauseConfigurationPayload;
  skipOpsAudit: SkipOpsAuditPayload;
  unpauseConfiguration: UnpauseConfigurationPayload;
};

export type OpsAuditMutationInterfaceBuildOpsAuditAnswersArgs = {
  auditAnswers: Array<OpsAudit__AuditAnswersInputType>;
};

export type OpsAuditMutationInterfaceCreateAuditExceptionArgs = {
  input: OpsAudit__AuditExceptionInputType;
};

export type OpsAuditMutationInterfaceEditAuditExceptionArgs = {
  input: OpsAudit__AuditExceptionInputType;
};

export type OpsAuditMutationInterfaceModifyItemCategoryArgs = {
  input: OpsAudit__ModifyItemCategoryInput;
};

export type OpsAuditMutationInterfaceNotAuditableOpsAuditArgs = {
  auditID: Scalars['ID'];
};

export type OpsAuditMutationInterfaceOpsAuditConfigurationModifyArgs = {
  input: OpsAudit__ConfigurationModifyInput;
};

export type OpsAuditMutationInterfacePauseConfigurationArgs = {
  configurationID: Scalars['ID'];
};

export type OpsAuditMutationInterfaceSkipOpsAuditArgs = {
  auditID: Scalars['ID'];
};

export type OpsAuditMutationInterfaceUnpauseConfigurationArgs = {
  configurationID: Scalars['ID'];
};

export type OpsAuditQueryInterface = {
  audit: OpsAudit__AuditType;
  auditResults: Array<OpsAudit__AuditType>;
  configurationAuditReview?: Maybe<OpsAudit__AuditType>;
  opsAuditConfiguration?: Maybe<OpsAudit__ConfigurationType>;
  paginatedConfigurationList: OpsAudit__PaginatedConfigurationListType;
};

export type OpsAuditQueryInterfaceAuditArgs = {
  auditID: Scalars['ID'];
};

export type OpsAuditQueryInterfaceAuditResultsArgs = {
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
};

export type OpsAuditQueryInterfaceConfigurationAuditReviewArgs = {
  configurationID?: InputMaybe<Scalars['ID']>;
};

export type OpsAuditQueryInterfaceOpsAuditConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OpsAuditQueryInterfacePaginatedConfigurationListArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type OpsAudit__AnswerInputType = {
  questionID: Scalars['ID'];
  response: Scalars['String'];
};

export type OpsAudit__AnswerType = {
  __typename?: 'OpsAudit__AnswerType';
  audit: OpsAudit__AuditType;
  createdAt: Scalars['DateTime'];
  failed: Scalars['Boolean'];
  id: Scalars['ID'];
  question: OpsAudit__QuestionType;
  response: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OpsAudit__AuditAnswersInputType = {
  answerInputs: Array<OpsAudit__AnswerInputType>;
  auditID: Scalars['ID'];
};

export type OpsAudit__AuditException = {
  __typename?: 'OpsAudit__AuditException';
  creator: User;
  id: Scalars['ID'];
  reason: Scalars['String'];
  status: OpsAudit__ExceptionStatus;
};

export type OpsAudit__AuditExceptionInputType = {
  auditID: Scalars['ID'];
  initialNote?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type OpsAudit__AuditType = {
  __typename?: 'OpsAudit__AuditType';
  answers: Array<OpsAudit__AnswerType>;
  auditor?: Maybe<User>;
  children: Array<OpsAudit__AuditType>;
  configuration: OpsAudit__ConfigurationType;
  createdAt: Scalars['DateTime'];
  exception?: Maybe<OpsAudit__AuditException>;
  failed: Scalars['Boolean'];
  id: Scalars['ID'];
  images: Array<Image>;
  parentID?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['Int']>;
  questionGroup?: Maybe<Scalars['String']>;
  questions: Array<OpsAudit__QuestionType>;
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
  skippedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OpsAudit__ConfigurationModifyInput = {
  dataQuery?: InputMaybe<Scalars['String']>;
  exceptionReasonCodes?: InputMaybe<Array<Scalars['String']>>;
  hoursUntilExpired?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<OpsAudit__QuestionInput>>;
  refreshIntervalMinutes: Scalars['Int'];
};

export type OpsAudit__ConfigurationType = {
  __typename?: 'OpsAudit__ConfigurationType';
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  dataQuery: Scalars['String'];
  exceptionReasonCodes: Array<Scalars['String']>;
  hoursUntilExpired?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastRanAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pausedAt?: Maybe<Scalars['DateTime']>;
  pendingCount: Scalars['Int'];
  questions: Array<OpsAudit__QuestionType>;
  refreshIntervalMinutes?: Maybe<Scalars['Int']>;
  resourceType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum OpsAudit__ExceptionStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export type OpsAudit__ModifyItemCategoryInput = {
  categoryID: Scalars['ID'];
  itemID: Scalars['ID'];
  sizingSelection?: InputMaybe<Scalars['String']>;
};

export type OpsAudit__PaginatedConfigurationListType = {
  __typename?: 'OpsAudit__PaginatedConfigurationListType';
  pagination: Pagination;
  results: Array<OpsAudit__ConfigurationType>;
};

export type OpsAudit__QuestionInput = {
  answerChoices: Array<Scalars['String']>;
  defectAnswers?: InputMaybe<Array<Scalars['String']>>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  failedAnswers?: InputMaybe<Array<Scalars['String']>>;
  failedMessage?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  ordering?: InputMaybe<Scalars['Int']>;
  prompt: Scalars['String'];
  slug: Scalars['String'];
};

export type OpsAudit__QuestionType = {
  __typename?: 'OpsAudit__QuestionType';
  answerChoices: Array<Scalars['String']>;
  configurationID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  defaultAnswer?: Maybe<Scalars['Int']>;
  defectAnswers: Array<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  failedAnswers: Array<Scalars['String']>;
  failedMessage?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  ordering?: Maybe<Scalars['Int']>;
  prompt: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Order = {
  __typename?: 'Order';
  account: Account;
  accountID: Scalars['ID'];
  accountPackages?: Maybe<Array<AccountPackage>>;
  address: Address;
  assessment?: Maybe<Order__AssessmentType>;
  auctionPickup: Scalars['Boolean'];
  bookedAt: Scalars['DateTime'];
  cancelReason?: Maybe<Order__CancelReason>;
  cancellationFeeDescription?: Maybe<Scalars['String']>;
  children: Array<Order>;
  contact?: Maybe<Order__Contact>;
  coupon?: Maybe<Coupon>;
  cuft?: Maybe<Scalars['Float']>;
  cuftPerHourPerMover?: Maybe<Scalars['Float']>;
  customerPinCode: Scalars['String'];
  /** Signature requests where the role is `customer` or `signer` */
  customerSignatureRequests: Array<SignatureRequest>;
  damagedItems: Array<DamagedItem>;
  dispatch?: Maybe<Dispatch>;
  dispatchOffset: Scalars['Int'];
  dispatched: Scalars['Boolean'];
  dropOffAppointment?: Maybe<DropOff__Appointment>;
  ended?: Maybe<Scalars['DateTime']>;
  estimatedCuft: Scalars['Float'];
  estimatedCuftOverrideReason?: Maybe<Estimation__EstimatedCuftOverrideReason>;
  estimatedCuftPerHourPerMover?: Maybe<Scalars['Float']>;
  estimatedDuration?: Maybe<Scalars['Int']>;
  estimatedItems: Array<EstimatedItem>;
  eta?: Maybe<Scalars['DateTime']>;
  expectedStartTime?: Maybe<Scalars['DateTime']>;
  flaggedAt?: Maybe<Scalars['DateTime']>;
  /** The booked arrival window */
  formattedArrivalWindow: Scalars['String'];
  id: Scalars['ID'];
  ingestedDocuments: Array<ActiveStorageAttachment>;
  internalScheduled?: Maybe<Scalars['DateTime']>;
  items: Array<Item>;
  itemsCount: Scalars['Int'];
  laborClocks: Array<LaborClock>;
  laborRate: LaborRate;
  leadUser?: Maybe<User>;
  maintenance: Scalars['Boolean'];
  movers: Scalars['Int'];
  moving: Scalars['Boolean'];
  movingOperation?: Maybe<MovingOperation>;
  movingSelections?: Maybe<Array<MovingSelection>>;
  multiDayContext: MultiDayContext;
  name: Scalars['String'];
  number: Scalars['String'];
  orderEvents: Array<OrderEvent>;
  orderEventsExcludingNonLeadAssignments: Array<OrderEvent>;
  otherNote?: Maybe<Scalars['String']>;
  parent?: Maybe<Order>;
  parentID?: Maybe<Scalars['ID']>;
  perItemPricingInfo?: Maybe<OrderPerItemPricing>;
  permissions: OrderPermissions;
  pickupVehicleKind?: Maybe<Order__PickupVehicleKind>;
  policy: Order__Policy;
  predecessor?: Maybe<Order>;
  region: Region;
  /** The retail delivery items associated with the order */
  retailDeliveryItems: Array<Retail__DeliveryItem>;
  review?: Maybe<Review>;
  scheduled: Scalars['DateTime'];
  serviceType?: Maybe<OrderServiceTypeEnum>;
  services: Array<OrderService>;
  shipmentValuationDeclaration?: Maybe<Declaration>;
  shipments: Array<Shipment>;
  shipped: Scalars['Boolean'];
  signatureRequests: Array<SignatureRequest>;
  signatures: Array<Signature>;
  simulatedOrderEvents: Array<OrderEvent>;
  simulatedSignedApprovalTime?: Maybe<Scalars['DateTime']>;
  simultaneous: Scalars['Boolean'];
  slaWindowEnd: Scalars['DateTime'];
  slaWindowSize: Scalars['Int'];
  /** This returns the order and all of its successors. This field does not support batch loading. */
  sourceAndSuccessors: Array<Order>;
  started?: Maybe<Scalars['DateTime']>;
  state: OrderStateEnum;
  status: OrderStatusEnum;
  storage: Scalars['Boolean'];
  subtype: OrderSubtypeEnum;
  successor?: Maybe<Order>;
  tags: Array<Scalars['String']>;
  tasks?: Maybe<Array<TaskOrder__Task>>;
  todayApp?: Maybe<TodayApp>;
  type: OrderTypeEnum;
  user?: Maybe<User>;
  waitlistRequests: Array<Availability__WaitlistRequest>;
  walkthroughs: Array<Walkthrough>;
  /** The warehouses that the items on an order are located at, along with the count of items on the order at each warehouse */
  warehousesWithItemCounts: Array<DockApi__WarehouseWithItemCount>;
  window: Scalars['Duration'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  category: Scalars['String'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  isBreakEvent: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
};

/** Autogenerated return type of OrderEventCreate */
export type OrderEventCreatePayload = {
  __typename?: 'OrderEventCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type OrderEventInput = {
  category: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  eventName: EventNameEnum;
  imageAttributes?: InputMaybe<ImageAttributesInput>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  orderID: Scalars['ID'];
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
};

export type OrderFilterInput = {
  external?: InputMaybe<Scalars['Boolean']>;
  forWarehouse?: InputMaybe<Scalars['Boolean']>;
};

export type OrderFiltersInput = {
  date?: InputMaybe<Scalars['Date']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
  service?: InputMaybe<OrderServiceTypeEnum>;
  states?: InputMaybe<Array<OrderStateEnum>>;
  subtype?: InputMaybe<OrderSubtypeEnum>;
  type?: InputMaybe<OrderTypeEnum>;
};

export type OrderInput = {
  accountCancelIntentID?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<AddressInput>;
  addressID?: InputMaybe<Scalars['ID']>;
  confirmedDate?: InputMaybe<Scalars['Boolean']>;
  contact?: InputMaybe<Order__ContactInput>;
  customerShipmentPrice?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Duration']>;
  estimatedItems?: InputMaybe<Array<EstimatedItemInput>>;
  estimations?: InputMaybe<Array<EstimationInput>>;
  isFullMoveOut?: InputMaybe<Scalars['Boolean']>;
  itemIDs?: InputMaybe<Array<Scalars['ID']>>;
  laborRateID?: InputMaybe<Scalars['ID']>;
  maxCustomerShipmentPrice?: InputMaybe<Scalars['Float']>;
  needsPackingHelp?: InputMaybe<Scalars['Boolean']>;
  perMoverHourAdjustmentAmount?: InputMaybe<Scalars['Float']>;
  predecessorID?: InputMaybe<Scalars['ID']>;
  regionID?: InputMaybe<Scalars['ID']>;
  requestedMovers?: InputMaybe<Scalars['Int']>;
  rooms?: InputMaybe<Array<RoomInput>>;
  scheduled?: InputMaybe<Scalars['DateTime']>;
  serviceType?: InputMaybe<OrderServiceTypeEnum>;
  shipments?: InputMaybe<Array<ShipmentInput>>;
  type: OrderTypeEnum;
  waitlistedDates?: InputMaybe<Array<Scalars['Date']>>;
};

export type OrderLeadTime = {
  __typename?: 'OrderLeadTime';
  id: Scalars['ID'];
  ignoreNonOperatingDays: Scalars['Boolean'];
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
  processingDayCutoffTime: Scalars['DateTime'];
  processingDaysRequired: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  warehouse: Warehouse;
};

export type OrderLeadTimeInput = {
  ignoreNonOperatingDays: Scalars['Boolean'];
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
  processingDayCutoffTime: Scalars['String'];
  processingDaysRequired: Scalars['Int'];
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of OrderLeadTimeUpsert */
export type OrderLeadTimeUpsertPayload = {
  __typename?: 'OrderLeadTimeUpsertPayload';
  error?: Maybe<Scalars['String']>;
  resource?: Maybe<OrderLeadTime>;
  status: Status;
};

export type OrderMovingOperationPolicy = {
  __typename?: 'OrderMovingOperationPolicy';
  submitVirtualWalkthrough: Scalars['Boolean'];
};

export type OrderMutationInterface = {
  orderCancel?: Maybe<Order__CancelPayload>;
  orderComplete?: Maybe<Order__CompletePayload>;
  orderCouponUpdate?: Maybe<Order__CouponUpdatePayload>;
  orderReschedule?: Maybe<ReschedulePayload>;
  orderTaskOrderCreate?: Maybe<Order__TaskOrderCreatePayload>;
  orderTasksUpdate?: Maybe<Order__TasksUpdatePayload>;
  orderUpdate?: Maybe<Order__UpdatePayload>;
  orderUserUpdate?: Maybe<Order__UserUpdatePayload>;
};

export type OrderMutationInterfaceOrderCancelArgs = {
  input: Order__CancelInput;
};

export type OrderMutationInterfaceOrderCompleteArgs = {
  orderID: Scalars['ID'];
};

export type OrderMutationInterfaceOrderCouponUpdateArgs = {
  couponID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
};

export type OrderMutationInterfaceOrderRescheduleArgs = {
  input: OrderRescheduleInput;
};

export type OrderMutationInterfaceOrderTaskOrderCreateArgs = {
  input: Order__TaskOrderInput;
};

export type OrderMutationInterfaceOrderTasksUpdateArgs = {
  input: Order__TasksUpdateInput;
};

export type OrderMutationInterfaceOrderUpdateArgs = {
  input: Order__Update__Input;
};

export type OrderMutationInterfaceOrderUserUpdateArgs = {
  orderID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type OrderOrError = Error | Order;

export type OrderPerItemPricing = {
  __typename?: 'OrderPerItemPricing';
  /** Formatted like "$49" */
  appointmentFee: Scalars['String'];
  /** Formatted like "$9" */
  perItemFee: Scalars['String'];
  pickupEstimate?: Maybe<PickupEstimate>;
  price: Scalars['String'];
  returnedItemsCount: Scalars['Int'];
};

export type OrderPermissions = {
  __typename?: 'OrderPermissions';
  active: Scalars['Boolean'];
  addDisposal: Scalars['Boolean'];
  cancelable: Scalars['Boolean'];
  cancellationFeeDisclaimer?: Maybe<Scalars['String']>;
  changeablePickupInventory: Scalars['Boolean'];
  changeableReturnInventory: Scalars['Boolean'];
  /** Deprecated: Use 'cancellation_fee_disclaimer' instead */
  chargeForCancellation: Scalars['Boolean'];
  chargeLateFee: Scalars['Boolean'];
  customerChangeableMoverCount: Scalars['Boolean'];
  id: Scalars['ID'];
  landingCheckoutPageTestEligible: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  priceMatchGuaranteeEligible: Scalars['Boolean'];
  relocatable: Scalars['Boolean'];
  reschedulable: Scalars['Boolean'];
  whiteGloveTestEligible: Scalars['Boolean'];
};

export type OrderQueryInterface = {
  orderCurrentCancellationFee?: Maybe<Scalars['String']>;
};

export type OrderQueryInterfaceOrderCurrentCancellationFeeArgs = {
  orderID: Scalars['ID'];
};

export type OrderRescheduleInput = {
  duration: Scalars['Duration'];
  laborRateID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
  perMoverHourAdjustmentAmount?: InputMaybe<Scalars['Float']>;
  scheduled: Scalars['DateTime'];
};

export type OrderService = {
  __typename?: 'OrderService';
  accountID: Scalars['ID'];
  cuft?: Maybe<Scalars['Float']>;
  cuftPerHourPerMover?: Maybe<Scalars['Float']>;
  ended?: Maybe<Scalars['DateTime']>;
  estimatedCuft: Scalars['Float'];
  estimatedCuftPerHourPerMover?: Maybe<Scalars['Float']>;
  estimatedItems: Array<EstimatedItem>;
  estimations: Array<EstimationSelection>;
  id: Scalars['ID'];
  isFullMoveOut?: Maybe<Scalars['Boolean']>;
  items: Array<Item>;
  needsPackingHelp?: Maybe<Scalars['Boolean']>;
  parentID?: Maybe<Scalars['ID']>;
  requestedMovers?: Maybe<Scalars['Int']>;
  rooms: Array<RoomSelection>;
  serviceType?: Maybe<OrderServiceTypeEnum>;
  started?: Maybe<Scalars['DateTime']>;
  subtype: OrderSubtypeEnum;
  type: OrderTypeEnum;
};

export enum OrderServiceTypeEnum {
  Auction = 'AUCTION',
  /** @deprecated The capsule service is no longer offered. Do not remove from display components */
  Capsule = 'CAPSULE',
  CurbsidePickup = 'CURBSIDE_PICKUP',
  Disposal = 'DISPOSAL',
  /** @deprecated The doorstep service is no longer offered. Do not remove from display components */
  Doorstep = 'DOORSTEP',
  /** @deprecated The drop_off service is no longer offered. Do not remove from display components */
  DropOff = 'DROP_OFF',
  Facility = 'FACILITY',
  FullService = 'FULL_SERVICE',
  LongDistance = 'LONG_DISTANCE',
  Shipment = 'SHIPMENT',
  ThirdPartyReturn = 'THIRD_PARTY_RETURN',
}

export enum OrderStateEnum {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Enroute = 'ENROUTE',
  Graded = 'GRADED',
  Packed = 'PACKED',
  Palletized = 'PALLETIZED',
  Pending = 'PENDING',
  Prepared = 'PREPARED',
  Returned = 'RETURNED',
  Unconfirmed = 'UNCONFIRMED',
}

export enum OrderStatusEnum {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
}

export enum OrderSubject {
  Account = 'ACCOUNT',
  Item = 'ITEM',
}

export type OrderSubjectInput = {
  id: Scalars['ID'];
  type: OrderSubject;
};

export enum OrderSubtypeEnum {
  Cancelation = 'CANCELATION',
  Final = 'FINAL',
  None = 'NONE',
  Onboarding = 'ONBOARDING',
  Packing = 'PACKING',
  Subsequent = 'SUBSEQUENT',
  SupplyKit = 'SUPPLY_KIT',
  Travel = 'TRAVEL',
  Unpacking = 'UNPACKING',
}

export enum OrderTypeEnum {
  Disposal = 'DISPOSAL',
  Move = 'MOVE',
  Pickup = 'PICKUP',
  PickupReturn = 'PICKUP_RETURN',
  Restock = 'RESTOCK',
  RetailDelivery = 'RETAIL_DELIVERY',
  Return = 'RETURN',
  SelfStorageShuttle = 'SELF_STORAGE_SHUTTLE',
  TaskOrder = 'TASK_ORDER',
}

export type Order__AssessmentType = {
  __typename?: 'Order__AssessmentType';
  id: Scalars['ID'];
  otherReason?: Maybe<Scalars['String']>;
  rating: Rating;
  reasons: Scalars['JSON'];
  user: User;
};

export type Order__CancelInput = {
  cancelReasonID?: InputMaybe<Scalars['ID']>;
  chargeCancelationFee?: InputMaybe<Scalars['Boolean']>;
  orderID: Scalars['ID'];
  silenceCancelNotification?: InputMaybe<Scalars['Boolean']>;
  suppressSuccessorCancellations?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of Order__Cancel */
export type Order__CancelPayload = {
  __typename?: 'Order__CancelPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Order__CancelReason = {
  __typename?: 'Order__CancelReason';
  id: Scalars['ID'];
  kind: Scalars['String'];
};

/** Autogenerated return type of Order__Complete */
export type Order__CompletePayload = {
  __typename?: 'Order__CompletePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Order__Contact = {
  __typename?: 'Order__Contact';
  id: Scalars['ID'];
  kind?: Maybe<Order__ContactKind>;
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type Order__ContactInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum Order__ContactKind {
  Individual = 'INDIVIDUAL',
  MovingCompany = 'MOVING_COMPANY',
}

/** Autogenerated return type of Order__CouponUpdate */
export type Order__CouponUpdatePayload = {
  __typename?: 'Order__CouponUpdatePayload';
  order: OrderOrError;
  status: Status;
};

export enum Order__Metadata__ItemsRequireMoreThanOneMover {
  No = 'NO',
  NotSure = 'NOT_SURE',
  Yes = 'YES',
}

export enum Order__PickupVehicleKind {
  PersonalVehicle = 'PERSONAL_VEHICLE',
  ProfessionalMovingTruck = 'PROFESSIONAL_MOVING_TRUCK',
  RentalTruck = 'RENTAL_TRUCK',
}

export type Order__Policy = {
  __typename?: 'Order__Policy';
  cancel: Scalars['Boolean'];
  completeManually: Scalars['Boolean'];
  createFollowUp: Scalars['Boolean'];
  dispatch: Scalars['Boolean'];
  modify: Scalars['Boolean'];
  review: Scalars['Boolean'];
  ship: Scalars['Boolean'];
  uncancel: Scalars['Boolean'];
  viewSelfStorageAuction: Scalars['Boolean'];
};

export type Order__TaskInput = {
  addressID: Scalars['ID'];
  contact?: InputMaybe<Order__ContactInput>;
  id?: InputMaybe<Scalars['ID']>;
  instructions?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Order__TaskOrderCreate */
export type Order__TaskOrderCreatePayload = {
  __typename?: 'Order__TaskOrderCreatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Order__TaskOrderInput = {
  accountID: Scalars['ID'];
  addressID: Scalars['ID'];
  estimatedDuration: Scalars['Duration'];
  movers: Scalars['Int'];
  regionID?: InputMaybe<Scalars['ID']>;
  scheduled: Scalars['DateTime'];
};

export type Order__TasksUpdateInput = {
  orderID: Scalars['ID'];
  tasks: Array<Order__TaskInput>;
};

/** Autogenerated return type of Order__TasksUpdate */
export type Order__TasksUpdatePayload = {
  __typename?: 'Order__TasksUpdatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

/** Autogenerated return type of Order__Update */
export type Order__UpdatePayload = {
  __typename?: 'Order__UpdatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Order__Update__Input = {
  estimatedDuration: Scalars['Duration'];
  moverCount: Scalars['Int'];
  movingDestinationAddressID?: InputMaybe<Scalars['ID']>;
  movingOriginAddressID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
  regionID: Scalars['ID'];
  scheduledTime: Scalars['DateTime'];
  skipAvailabilityCheck?: InputMaybe<Scalars['Boolean']>;
  skipRescheduleFee?: InputMaybe<Scalars['Boolean']>;
  subtype?: InputMaybe<OrderSubtypeEnum>;
};

/** Autogenerated return type of Order__UserUpdate */
export type Order__UserUpdatePayload = {
  __typename?: 'Order__UserUpdatePayload';
  order: OrderOrError;
  status: Status;
};

export enum OrderingDirectionEnum {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type Pto__KindType = {
  __typename?: 'PTO__KindType';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Pto__PaginatedRequestGroupType = {
  __typename?: 'PTO__PaginatedRequestGroupType';
  pagination: Pagination;
  results: Array<Pto__RequestGroupType>;
};

export type Pto__RequestFiltersInput = {
  states?: InputMaybe<Array<Pto__RequestState>>;
  teamIDs?: InputMaybe<Array<Scalars['ID']>>;
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type Pto__RequestGroupType = {
  __typename?: 'PTO__RequestGroupType';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  kind: Pto__KindType;
  requests: Array<Pto__RequestType>;
  reviewerNote?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  state: Pto__RequestState;
  totalHours: Scalars['Float'];
  user: User;
};

export enum Pto__RequestState {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type Pto__RequestType = {
  __typename?: 'PTO__RequestType';
  conflictingShiftAssignmentExists: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  hours: Scalars['Float'];
  id: Scalars['ID'];
  kind: Pto__KindType;
  requestDate: Scalars['Date'];
  reviewerNote?: Maybe<Scalars['String']>;
  state: Pto__RequestState;
  user: User;
};

export type Package = {
  __typename?: 'Package';
  fullPack: Scalars['Boolean'];
  id: Scalars['ID'];
  kind: PackageKindEnum;
  name: Scalars['String'];
};

export enum PackageKindEnum {
  AuctionFee = 'AUCTION_FEE',
  Core = 'CORE',
  DisposalDonation = 'DISPOSAL_DONATION',
  EarlyReturn = 'EARLY_RETURN',
  ExtendedServiceArea = 'EXTENDED_SERVICE_AREA',
  FinalReturn = 'FINAL_RETURN',
  FlatRateService = 'FLAT_RATE_SERVICE',
  FlexibleScheduling = 'FLEXIBLE_SCHEDULING',
  GasFee = 'GAS_FEE',
  LongDistanceTransport = 'LONG_DISTANCE_TRANSPORT',
  LongTerm = 'LONG_TERM',
  OnboardingService = 'ONBOARDING_SERVICE',
  OrderFee = 'ORDER_FEE',
  PackingSupplies = 'PACKING_SUPPLIES',
  Plus = 'PLUS',
  PreferredSchedulingFee = 'PREFERRED_SCHEDULING_FEE',
  SmallAppointment = 'SMALL_APPOINTMENT',
  TimeSlotSelectionFee = 'TIME_SLOT_SELECTION_FEE',
  TruckAndDriver = 'TRUCK_AND_DRIVER',
  WarehouseService = 'WAREHOUSE_SERVICE',
}

export type PackageSet = {
  __typename?: 'PackageSet';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PackageSetEntry = {
  __typename?: 'PackageSetEntry';
  amount?: Maybe<Scalars['Int']>;
  cappedAtOnboardingAmount: Scalars['Boolean'];
  costPerCuft: Scalars['Float'];
  fixedCost: Scalars['Float'];
  id: Scalars['ID'];
  minimumTermMonths: Scalars['Int'];
  orderSubtype: Scalars['String'];
  package: Package;
  packageSet?: Maybe<PackageSet>;
  storageCostMultiplier: Scalars['Float'];
};

export type PackageSetEntryAmountArgs = {
  cuft: Scalars['Float'];
  storagePrice?: InputMaybe<Scalars['Float']>;
};

export type PackingStandard = {
  __typename?: 'PackingStandard';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  document: Admin__Document;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaginatedAccount = {
  __typename?: 'PaginatedAccount';
  pagination: Pagination;
  results: Array<Account>;
};

export type PaginatedClaim = {
  __typename?: 'PaginatedClaim';
  pagination: Pagination;
  results: Array<Claim>;
};

export type PaginatedCommunication = {
  __typename?: 'PaginatedCommunication';
  pagination: Pagination;
  results: Array<Communication>;
};

export type PaginatedCustomerTicket = {
  __typename?: 'PaginatedCustomerTicket';
  pagination: Pagination;
  results: Array<CustomerTicket>;
};

export type PaginatedHistory = {
  __typename?: 'PaginatedHistory';
  pagination: Pagination;
  results: Array<History>;
};

export type PaginatedLead = {
  __typename?: 'PaginatedLead';
  pagination: Pagination;
  results: Array<Lead>;
};

export type PaginatedNotification = {
  __typename?: 'PaginatedNotification';
  pagination: Pagination;
  results: Array<Notification>;
};

export type PaginatedOrder = {
  __typename?: 'PaginatedOrder';
  pagination: Pagination;
  results: Array<Order>;
};

export type PaginatedTicket = {
  __typename?: 'PaginatedTicket';
  pagination: Pagination;
  results: Array<Ticket>;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  pagination: Pagination;
  results: Array<User>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Pallet = {
  __typename?: 'Pallet';
  barcode: Barcode;
  categoryIDs?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['DateTime'];
  densityMetrics: PalletDensityMetrics;
  /** Makespace specific display name */
  displayName?: Maybe<Scalars['String']>;
  grade?: Maybe<PalletGrade>;
  height?: Maybe<PalletHeight>;
  id: Scalars['ID'];
  /**
   * Fulfillment expectations on the pallet with the count of corresponding items
   * matching that fulfillment expectation on the pallet (Fulfillment expectations
   * are assigned to an item when inbounding)
   */
  inboundFulfillmentExpectationsWithItemCounts: Array<Logistics__FulfillmentExpectationWithItemCount>;
  incidents: Array<Incident>;
  /** Whether the pallet has an open 'damaged' incident */
  isDamaged: Scalars['Boolean'];
  items: Array<Item>;
  itemsCount: Scalars['Int'];
  latestOpenIncidentKind?: Maybe<IncidentKindEnum>;
  /** All loads associated with a pallet */
  loads?: Maybe<Array<Logistics__Load>>;
  location?: Maybe<Location>;
  /** Items on the pallet without SKUs */
  nonSkuItems: Array<Item>;
  notes: Array<Note>;
  /** The outbound load associated with a pallet */
  outboundLoad?: Maybe<Logistics__Load>;
  palletInventories: Array<Logistics__PalletInventory>;
  palletizedAt: Scalars['DateTime'];
  /** Load pallet is reserved for */
  reservedLoad?: Maybe<Logistics__Load>;
  resizedAt?: Maybe<Scalars['DateTime']>;
  securedPalletImage?: Maybe<Image>;
  shippingLabel?: Maybe<Logistics__ShippingLabel>;
  skuValues: Array<Scalars['String']>;
  /** SKUs on the pallet with the count of corresponding items matching that SKU on the pallet */
  skusWithItemCounts: Array<Logistics__SkuWithItemCount>;
  state: PalletStateEnum;
  type: PalletType;
  updatedAt: Scalars['DateTime'];
  warehouse: Warehouse;
  /** The weight of a pallet */
  weight?: Maybe<Scalars['Float']>;
};

export type PalletCategory = {
  __typename?: 'PalletCategory';
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  kind?: Maybe<PalletCategoryKindEnum>;
  name: Scalars['String'];
  parentID?: Maybe<Scalars['ID']>;
};

export enum PalletCategoryKindEnum {
  Height = 'HEIGHT',
  Width = 'WIDTH',
}

export type PalletDensityMetrics = {
  __typename?: 'PalletDensityMetrics';
  itemCuft: Scalars['Float'];
  palletCuft: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type PalletGrade = {
  __typename?: 'PalletGrade';
  backImage?: Maybe<Image>;
  createdAt: Scalars['DateTime'];
  frontImage?: Maybe<Image>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  palletID: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type PalletHeight = {
  __typename?: 'PalletHeight';
  unit: PalletHeightUnitEnum;
  value: Scalars['Int'];
};

export type PalletHeightInput = {
  unit: PalletHeightUnitEnum;
  value: Scalars['Int'];
};

export enum PalletHeightUnitEnum {
  Inches = 'INCHES',
}

export type PalletInput = {
  barcodeAttributes?: InputMaybe<BarcodeInput>;
  heightAttributes?: InputMaybe<PalletHeightInput>;
  palletTypeID?: InputMaybe<Scalars['ID']>;
  warehouseID?: InputMaybe<Scalars['ID']>;
};

export type PalletRequest = {
  __typename?: 'PalletRequest';
  canceler?: Maybe<User>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  kind: PalletRequestKindEnum;
  pallet: Pallet;
  requestor: User;
};

export type PalletRequestInput = {
  kind: PalletRequestKindEnum;
  manualRequestReason?: InputMaybe<Scalars['String']>;
  manuallyRequested: Scalars['Boolean'];
  palletID: Scalars['ID'];
  requestorID: Scalars['ID'];
};

export enum PalletRequestKindEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum PalletSizeEnum {
  Crated = 'CRATED',
  Double = 'DOUBLE',
  Oversized = 'OVERSIZED',
  Single = 'SINGLE',
}

export enum PalletStateEnum {
  Built = 'BUILT',
  Discarded = 'DISCARDED',
  Enroute = 'ENROUTE',
  Flagged = 'FLAGGED',
  OnTrailer = 'ON_TRAILER',
  Pulled = 'PULLED',
  Shipped = 'SHIPPED',
  Stored = 'STORED',
}

export type PalletTask = {
  __typename?: 'PalletTask';
  canceledAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  destinationLocationId?: Maybe<Scalars['ID']>;
  destinationType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  location?: Maybe<Location>;
  originScanNeeded: Scalars['Boolean'];
  originType?: Maybe<Scalars['String']>;
  oversized: Scalars['Boolean'];
  /** A description of the oversized pallet including the category and dimensions of the item on it */
  oversizedDetails?: Maybe<Scalars['String']>;
  pallet: Pallet;
  secondLocation?: Maybe<Location>;
  secondPallet?: Maybe<Pallet>;
  user?: Maybe<User>;
};

export type PalletType = {
  __typename?: 'PalletType';
  active: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  itemCategories?: Maybe<Array<ItemCategory>>;
  legacySize: PalletSizeEnum;
  materialCategories?: Maybe<Array<MaterialCategory>>;
  name: Scalars['String'];
  partCategories?: Maybe<Array<PartCategory>>;
  position: Scalars['Int'];
};

export type PalletTypeInput = {
  active: Scalars['Boolean'];
  capacity?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  itemCategoryIDs?: InputMaybe<Array<Scalars['ID']>>;
  legacySize: PalletSizeEnum;
  materialCategoryIDs?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  partCategoryIDs?: InputMaybe<Array<Scalars['ID']>>;
  position: Scalars['Int'];
};

export type PartCategory = {
  __typename?: 'PartCategory';
  id: Scalars['ID'];
  moveAndPackVisible: Scalars['Boolean'];
  name: Scalars['String'];
  packingStandard?: Maybe<PackingStandard>;
  parentID?: Maybe<Scalars['ID']>;
};

export type PartialItem = {
  __typename?: 'PartialItem';
  account: Account;
  accountID: Scalars['ID'];
  barcode?: Maybe<Barcode>;
  category?: Maybe<ItemCategory>;
  createdAt: Scalars['DateTime'];
  cuft?: Maybe<Scalars['Float']>;
  customCategoryName?: Maybe<Scalars['String']>;
  dimensionsOverwritten: Scalars['Boolean'];
  group?: Maybe<ItemGroup>;
  height?: Maybe<Scalars['Int']>;
  hero?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  images: Array<Image>;
  includedParts: Array<IncludedPart>;
  length?: Maybe<Scalars['Int']>;
  materialCategory?: Maybe<MaterialCategory>;
  packaging?: Maybe<ItemPackagingEnum>;
  partCategory?: Maybe<PartCategory>;
  sizingSelection?: Maybe<Scalars['String']>;
  state: PartialItemStateEnum;
  uuid: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export enum PartialItemStateEnum {
  Barcoded = 'BARCODED',
  BecameChild = 'BECAME_CHILD',
  Deleted = 'DELETED',
  DoesNotExist = 'DOES_NOT_EXIST',
  Planning = 'PLANNING',
  PostwrapPhotosTaken = 'POSTWRAP_PHOTOS_TAKEN',
  PrewrapPhotosTaken = 'PREWRAP_PHOTOS_TAKEN',
  Unstarted = 'UNSTARTED',
}

/** Autogenerated return type of PauseConfiguration */
export type PauseConfigurationPayload = {
  __typename?: 'PauseConfigurationPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type PaymentPlanMutationInterface = {
  acceptPaymentPlanOffer: PaymentPlan__Offer__AcceptPayload;
  cancelPaymentPlanOffer: PaymentPlan__Offer__CancelPayload;
  extendPaymentPlanOffer: PaymentPlan__Offer__ExtendPayload;
  savePaymentPlanOffer: PaymentPlan__Offer__SavePayload;
};

export type PaymentPlanMutationInterfaceAcceptPaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
  offlineSource?: InputMaybe<Billing__Invoice__OfflinePaymentChargeInput>;
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type PaymentPlanMutationInterfaceCancelPaymentPlanOfferArgs = {
  offerID: Scalars['ID'];
};

export type PaymentPlanMutationInterfaceExtendPaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
};

export type PaymentPlanMutationInterfaceSavePaymentPlanOfferArgs = {
  input: PaymentPlan__Offer__SaveInput;
};

export type PaymentPlanQueryInterface = {
  accountPaymentPlanOffers: Array<PaymentPlan__Offer>;
  paymentPlanOffer: PaymentPlan__Offer;
  unforgivenInvoices: Array<Billing__Invoice>;
};

export type PaymentPlanQueryInterfaceAccountPaymentPlanOffersArgs = {
  accountID: Scalars['ID'];
};

export type PaymentPlanQueryInterfacePaymentPlanOfferArgs = {
  accountID: Scalars['ID'];
  offerID: Scalars['ID'];
};

export type PaymentPlanQueryInterfaceUnforgivenInvoicesArgs = {
  accountID: Scalars['ID'];
  statuses?: InputMaybe<Array<Billing__Invoice__Status>>;
};

export type PaymentPlan__Offer = {
  __typename?: 'PaymentPlan__Offer';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  account: Account;
  createdAt: Scalars['DateTime'];
  expired: Scalars['Boolean'];
  expiry: Scalars['DateTime'];
  id: Scalars['ID'];
  monthlyFee?: Maybe<Scalars['Float']>;
  monthlyFeeTerm?: Maybe<Scalars['Int']>;
  monthlyFeeTermEndDate?: Maybe<Scalars['Date']>;
  oneTimeFee?: Maybe<Scalars['Float']>;
  settledInvoices: Array<Billing__Invoice>;
  state: PaymentPlan__Offer__State;
  storageTerm?: Maybe<Scalars['Int']>;
  storageTermEndDate?: Maybe<Scalars['Date']>;
  type: PaymentPlan__Offer__Type;
  user: User;
};

/** Autogenerated return type of PaymentPlan__Offer__Accept */
export type PaymentPlan__Offer__AcceptPayload = {
  __typename?: 'PaymentPlan__Offer__AcceptPayload';
  error?: Maybe<Scalars['String']>;
  offer?: Maybe<PaymentPlan__Offer>;
  status: Status;
};

/** Autogenerated return type of PaymentPlan__Offer__Cancel */
export type PaymentPlan__Offer__CancelPayload = {
  __typename?: 'PaymentPlan__Offer__CancelPayload';
  error?: Maybe<Scalars['String']>;
  offer?: Maybe<PaymentPlan__Offer>;
  status: Status;
};

/** Autogenerated return type of PaymentPlan__Offer__Extend */
export type PaymentPlan__Offer__ExtendPayload = {
  __typename?: 'PaymentPlan__Offer__ExtendPayload';
  error?: Maybe<Scalars['String']>;
  offer?: Maybe<PaymentPlan__Offer>;
  status: Status;
};

export type PaymentPlan__Offer__SaveInput = {
  accountID: Scalars['ID'];
  expiry?: InputMaybe<Scalars['DateTime']>;
  monthlyFee?: InputMaybe<Scalars['Float']>;
  monthlyFeeTerm?: InputMaybe<Scalars['Int']>;
  offerID?: InputMaybe<Scalars['ID']>;
  oneTimeFee?: InputMaybe<Scalars['Float']>;
  settledInvoiceIDs?: InputMaybe<Array<Scalars['ID']>>;
  storageTerm?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<PaymentPlan__Offer__Type>;
};

/** Autogenerated return type of PaymentPlan__Offer__Save */
export type PaymentPlan__Offer__SavePayload = {
  __typename?: 'PaymentPlan__Offer__SavePayload';
  error?: Maybe<Scalars['String']>;
  offer?: Maybe<PaymentPlan__Offer>;
  status: Status;
};

export enum PaymentPlan__Offer__State {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
}

export enum PaymentPlan__Offer__Type {
  AutopayOptIn = 'AUTOPAY_OPT_IN',
  LumpSum = 'LUMP_SUM',
  PayToStay = 'PAY_TO_STAY',
  PayToVacate = 'PAY_TO_VACATE',
  SelfStorageAuction = 'SELF_STORAGE_AUCTION',
}

export type PaymentPlan__Subscription = {
  __typename?: 'PaymentPlan__Subscription';
  activatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  canceledAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offer: PaymentPlan__Offer;
  status?: Maybe<Scalars['String']>;
  term: Scalars['Int'];
};

export type Phone = {
  __typename?: 'Phone';
  busy: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type PhoneCall = {
  __typename?: 'PhoneCall';
  conversations: Array<Conversation>;
  createdAt: Scalars['DateTime'];
  direction: Scalars['String'];
  duration: Scalars['Int'];
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  latestConnectedUser?: Maybe<User>;
  note?: Maybe<Note>;
  notes: Array<Note>;
  phone: Phone;
  reasons: Array<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  touchTaxons: Array<TouchTaxon>;
  updatedAt: Scalars['DateTime'];
  voicemails: Array<Voicemail>;
};

export type PhoneCallPolicy = {
  __typename?: 'PhoneCallPolicy';
  download: Scalars['Boolean'];
};

/** Autogenerated return type of PickupComplete */
export type PickupCompletePayload = {
  __typename?: 'PickupCompletePayload';
  order: Order;
};

export type PickupEstimate = {
  __typename?: 'PickupEstimate';
  /** The highest number of estimated items */
  highestEstimatedItems?: Maybe<Scalars['Int']>;
  /** The lowest number of estimated items */
  lowestEstimatedItems?: Maybe<Scalars['Int']>;
};

export type PickupInventoryInput = {
  estimatedItems: Array<EstimatedItemInput>;
  estimations: Array<EstimationInput>;
  isFullMoveOut: Scalars['Boolean'];
  needsPackingHelp?: InputMaybe<Scalars['Boolean']>;
  requestedMovers: Scalars['Int'];
  rooms: Array<RoomInput>;
};

export type Plan = {
  __typename?: 'Plan';
  cuft: Scalars['Int'];
  id: Scalars['ID'];
  kind: PlanKindEnum;
  name: Scalars['String'];
  type: PlanTypeEnum;
};

export enum PlanKindEnum {
  Custom = 'CUSTOM',
  Fixed = 'FIXED',
  Item = 'ITEM',
}

export enum PlanTypeEnum {
  ProtectionPlan = 'PROTECTION_PLAN',
  StoragePlan = 'STORAGE_PLAN',
}

export type PreTripAssessment = {
  __typename?: 'PreTripAssessment';
  other?: Maybe<Scalars['String']>;
  unresolvedSelections?: Maybe<Array<PreTripSelection>>;
};

export type PreTripItem = {
  __typename?: 'PreTripItem';
  name: Scalars['String'];
};

export type PreTripOption = {
  __typename?: 'PreTripOption';
  name: Scalars['String'];
};

export type PreTripSelection = {
  __typename?: 'PreTripSelection';
  preTripItem: PreTripItem;
  preTripOption: PreTripOption;
};

export type PrepopulatedDisassemblyPart = {
  __typename?: 'PrepopulatedDisassemblyPart';
  id: Scalars['ID'];
  resource: GenericCategory;
};

export enum PriceMatch__Kind {
  CustomPlan = 'CUSTOM_PLAN',
  PriceMatch = 'PRICE_MATCH',
  SwitchNSave = 'SWITCH_N_SAVE',
}

export type Pricing = {
  __typename?: 'Pricing';
  amount: Scalars['Float'];
  currency: Currency;
  id: Scalars['ID'];
  plan: Plan;
};

export type PricingGroupLaborEntry = {
  __typename?: 'PricingGroupLaborEntry';
  id: Scalars['ID'];
  laborPolicy: LaborPolicy;
  rateGroup?: Maybe<RateGroup>;
  storageTerm: StorageTerm;
};

export type PricingGroupPricingEntry = {
  __typename?: 'PricingGroupPricingEntry';
  id: Scalars['ID'];
  pricing: Pricing;
  rateGroup?: Maybe<RateGroup>;
};

export type PricingMutationInterface = {
  createAppointmentQuote?: Maybe<CreateAppointmentQuotePayload>;
  savePricingUpload: Pricing__Upload__SavePayload;
};

export type PricingMutationInterfaceCreateAppointmentQuoteArgs = {
  cuft: Scalars['Float'];
  quotableId: Scalars['ID'];
  quotableType: Pricing__QuotableEnum;
  rateGroupId: Scalars['ID'];
  source: Pricing__SourceEnum;
  zip: Scalars['String'];
};

export type PricingMutationInterfaceSavePricingUploadArgs = {
  input: Pricing__UploadInput;
};

export type PricingPolicy = {
  __typename?: 'PricingPolicy';
  modify: Scalars['Boolean'];
};

export type PricingQueryInterface = {
  formattedAccountLaborAgreement: Array<Scalars['String']>;
  formattedAppointmentLaborAgreement: Scalars['String'];
  formattedLaborAgreement: Array<Scalars['String']>;
  paginatedPricingUploads: Pricing__PaginatedUpload;
  pricingPerItemFee?: Maybe<Pricing__PerItemFee>;
  pricingPolicy: PricingPolicy;
  pricingSetAppointmentData: PricingSetAppointmentData;
  pricingUpload: Pricing__Upload;
};

export type PricingQueryInterfaceFormattedAccountLaborAgreementArgs = {
  accountId: Scalars['ID'];
};

export type PricingQueryInterfaceFormattedAppointmentLaborAgreementArgs = {
  orderId: Scalars['ID'];
};

export type PricingQueryInterfaceFormattedLaborAgreementArgs = {
  cuft: Scalars['Float'];
  laborRateId?: InputMaybe<Scalars['ID']>;
  maxLaborAmount?: InputMaybe<Scalars['Float']>;
  orderZip: Scalars['String'];
  packageSetEntryIds?: InputMaybe<Array<Scalars['ID']>>;
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
};

export type PricingQueryInterfacePaginatedPricingUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type PricingQueryInterfacePricingPerItemFeeArgs = {
  input: Pricing__PerItemFeeInput;
};

export type PricingQueryInterfacePricingSetAppointmentDataArgs = {
  cuft: Scalars['Float'];
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
};

export type PricingQueryInterfacePricingUploadArgs = {
  id: Scalars['ID'];
};

export type PricingSet = {
  __typename?: 'PricingSet';
  id: Scalars['ID'];
  laborPricingGroupEntries: Array<PricingGroupLaborEntry>;
  marketPricingVariantLabel?: Maybe<Scalars['String']>;
  protectionPricingGroupEntries: Array<PricingGroupPricingEntry>;
  quoteId?: Maybe<Scalars['ID']>;
  storagePricingGroupEntries: Array<PricingGroupPricingEntry>;
};

export type PricingSetAppointmentData = {
  __typename?: 'PricingSetAppointmentData';
  laborRate: LaborRate;
  packageSetEntries: Array<PackageSetEntry>;
};

export type Pricing__LaborRate = {
  __typename?: 'Pricing__LaborRate';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Pricing__PaginatedUpload = {
  __typename?: 'Pricing__PaginatedUpload';
  pagination: Pagination;
  results: Array<Pricing__Upload>;
};

export type Pricing__PerItemFee = {
  __typename?: 'Pricing__PerItemFee';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  largeAmount: Scalars['Float'];
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
};

export type Pricing__PerItemFeeInput = {
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
  regionID: Scalars['ID'];
};

export enum Pricing__QuotableEnum {
  Account = 'ACCOUNT',
  Lead = 'LEAD',
}

export enum Pricing__SourceEnum {
  AdminCurbsideUpgrade = 'ADMIN_CURBSIDE_UPGRADE',
  AdminEditPricing = 'ADMIN_EDIT_PRICING',
  AdminIterableDraftDataFeed = 'ADMIN_ITERABLE_DRAFT_DATA_FEED',
  AdminIterableUpdateBuilderService = 'ADMIN_ITERABLE_UPDATE_BUILDER_SERVICE',
  AdminLeadCheckout = 'ADMIN_LEAD_CHECKOUT',
  AdminLeadQuote = 'ADMIN_LEAD_QUOTE',
  AdminReonboarding = 'ADMIN_REONBOARDING',
  AdminSubscriptionAndFeesAdjustmentService = 'ADMIN_SUBSCRIPTION_AND_FEES_ADJUSTMENT_SERVICE',
}

export type Pricing__Upload = {
  __typename?: 'Pricing__Upload';
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  processingAt?: Maybe<Scalars['DateTime']>;
  service: Pricing__Upload__Service;
  spreadsheet: ActiveStorageAttachment;
  state: Pricing__Upload__State;
  user: User;
};

export type Pricing__UploadInput = {
  description: Scalars['String'];
  service: Pricing__Upload__Service;
  spreadsheetID: Scalars['ID'];
};

/** Autogenerated return type of Pricing__Upload__Save */
export type Pricing__Upload__SavePayload = {
  __typename?: 'Pricing__Upload__SavePayload';
  upload: Pricing__Upload;
};

export enum Pricing__Upload__Service {
  Moving = 'MOVING',
  Storage = 'STORAGE',
}

export enum Pricing__Upload__State {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

/** Autogenerated return type of ProcessFromCall */
export type ProcessFromCallPayload = {
  __typename?: 'ProcessFromCallPayload';
  customerTicket?: Maybe<CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ProtectionPlan = {
  __typename?: 'ProtectionPlan';
  maxCovered: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PurchaseOrderQuantities = {
  __typename?: 'PurchaseOrderQuantities';
  itemCount: Scalars['Int'];
  loadId: Scalars['ID'];
  palletCount: Scalars['Int'];
  skuID: Scalars['ID'];
  skuValue: Scalars['String'];
};

export type Query = AccountQueryInterface &
  AuctionQueryInterface &
  AutomationQueryInterface &
  AvailabilityQueryInterface &
  BillingQueryInterface &
  CalculatePackageSetEntryAmountInterface &
  ClaimQueryInterface &
  ComplianceQueryInterface &
  CustomerTicketsQueryInterface &
  DockApiQueryInterface &
  DropOffQueryInterface &
  EstimationQueryInterface &
  FieldApiQueryInterface &
  ForkliftApiQueryInterface &
  HrsQueryInterface &
  IngestionQueryInterface &
  LandingLocationQueryInterface &
  LogisticsQueryInterface &
  MarketingQueryInterface &
  MovingQueryInterface &
  NightTransportQueryInterface &
  OpsAuditQueryInterface &
  OrderQueryInterface &
  PaymentPlanQueryInterface &
  PricingQueryInterface &
  ReasonQueryInterface &
  SelfStorageQueryInterface &
  StorageTreasuresQueryInterface &
  TicketsQueryInterface &
  WorkforceQueryInterface & {
    __typename?: 'Query';
    account: Account;
    accountClaims: Array<Claim>;
    accountPaymentPlanOffers: Array<PaymentPlan__Offer>;
    activeAuctionOrder?: Maybe<Order>;
    activePalletRequests: Array<PalletRequest>;
    addressPolicy: AddressPolicy;
    addresses: Array<Address>;
    appVersionRequirements: Array<AppVersionRequirement>;
    attendancePolicyViolations: Array<AttendancePolicyViolation__Type>;
    attestationEventsPaginated: AttestationEventPaginated;
    auctionPaginatedUploads: Auction__PaginatedUpload;
    auctionUpload: Auction__Upload;
    auctionUploadPolicy: Auction__Upload__Policy;
    audit: OpsAudit__AuditType;
    auditItem?: Maybe<Item>;
    auditResults: Array<OpsAudit__AuditType>;
    availabilities: Array<Availability>;
    availabilityFacilityCuftLimitPolicy: Availability__ManagementPolicy;
    availabilityFacilityCuftLimits: Availability__PaginatedFacilityCuftLimit;
    availabilityGlobalUnavailability: Availability__PaginatedGlobalUnavailability;
    availabilityGlobalUnavailabilityPolicy: Availability__ManagementPolicy;
    availabilityJobAvailability: Availability__PaginatedJobAvailability;
    availabilityJobAvailabilityPolicy: Availability__ManagementPolicy;
    availabilityLaborAdjustments: Availability__PaginatedLaborAdjustment;
    availabilityLaborAdjustmentsPolicy: Availability__ManagementPolicy;
    availabilityRescheduleOffers: Availability__PaginatedRescheduleOffer;
    availabilityRescheduleOffersPolicy: Availability__ManagementPolicy;
    billing: Billing__Context;
    billingAuthorizationHolds: Array<Billing__AuthorizationHold>;
    billingForgivenessReasons: Array<Billing__ForgivenessReason>;
    billingReBillReasons: Array<Billing__ReBillReason>;
    billingUnattemptedAuthorizationHold?: Maybe<Billing__UnattemptedAuthorizationHold>;
    calculatePackageSetEntryAmount?: Maybe<Scalars['Int']>;
    callGroups: Array<CallGroup>;
    claim: Claim;
    configurationAuditReview?: Maybe<OpsAudit__AuditType>;
    contractorPasswordResetRequest?: Maybe<ContractorPasswordResetRequest>;
    coupon: Coupon;
    coupons: Array<Coupon>;
    currentUser: User;
    currentUserActivePalletTasks: Array<PalletTask>;
    customItemCategory: ItemCategory;
    customerTicket: CustomerTicket;
    customerTicketByCall: CustomerTicket;
    customerTicketGroups: Array<Ticket__Group>;
    depot: Depot;
    depots: Array<Depot>;
    dispatch: Dispatch;
    dispatchAuditOrderEvents?: Maybe<Array<AuditOrderEvent>>;
    dispatches: Array<Dispatch>;
    documents: Array<Document>;
    draft?: Maybe<Draft>;
    dropOffAppointment?: Maybe<DropOff__Appointment>;
    duplicateLoadNumbers: Array<Logistics__Load>;
    estimationAlgorithmForOrder?: Maybe<Estimation__Algorithm>;
    estimationAlgorithms: Array<Estimation__Algorithm>;
    estimationBoxItemCategories: Array<Estimation__CategoryType>;
    estimationCategories: Array<EstimationCategory>;
    estimationItems: Array<Estimation__ItemType>;
    estimationOtherItemCategories: Array<Estimation__CategoryType>;
    estimationSelectedRooms: Array<Estimation__SelectedRoomType>;
    estimationUploads: Array<Estimation__UploadType>;
    estimationsForOrder: Estimation__OrderEstimation;
    facilities: Array<Facility>;
    facility: Facility;
    featureFlags: Array<FeatureFlag>;
    fieldContactReasons: Array<Field__ContactReason>;
    formattedAccountLaborAgreement: Array<Scalars['String']>;
    formattedAppointmentLaborAgreement: Scalars['String'];
    formattedLaborAgreement: Array<Scalars['String']>;
    fulfillmentExpectations: Array<Logistics__FulfillmentExpectation>;
    geoTimezone: Scalars['String'];
    geocode?: Maybe<Geocode>;
    hrsBusinessTitles: Array<Scalars['String']>;
    hrsJobTitles: Array<Hrs__JobTitleType>;
    hrsLocations: Array<Hrs__Location>;
    hrsProfileDefaults: Array<Hrs__ProfileDefault>;
    hrsRoleDefaults: Array<Hrs__RoleDefault>;
    inProgressManualPalletRequest?: Maybe<PalletRequest>;
    ingestionUpload: Ingestion__Upload;
    insufficientSkuInventory: Array<Logistics__InsufficientSkuInventoryType>;
    insufficientSkuTruckCuft: Logistics__InsufficientSkuTruckCuftType;
    internalTicketGroups: Array<Ticket__Group>;
    inventoryMetrics?: Maybe<InventoryMetrics>;
    item?: Maybe<Item>;
    itemByBarcode?: Maybe<Item>;
    itemCategories: Array<Admin__ItemCategory>;
    itemExceptions: Array<ItemException>;
    itemGroup: ItemGroup;
    itemSelection: Claim__ItemSelection;
    itemTransferRequests: Array<ItemTransferRequest>;
    itemsToRewrap: DockApi__ItemsToRewrap;
    landingLocationCities: Array<Landing__Location__City>;
    landingLocationCity: Landing__Location__City;
    landingLocationContent: Landing__Location__Content;
    landingLocationNeighborhood: Landing__Location__Neighborhood;
    landingLocationNeighborhoods: Array<Landing__Location__Neighborhood>;
    landingLocationRoute: Landing__Location__Route;
    landingLocationState: Landing__Location__State;
    landingLocationStates: Array<Landing__Location__State>;
    lead: Lead;
    logisticsAccounts: Array<Account>;
    logisticsAvailableWarehouseWorkers: Array<Logistics__WarehouseWorker>;
    logisticsLoad: Logistics__Load;
    logisticsLoadInventory: Array<Pallet>;
    logisticsLoads: Array<Logistics__Load>;
    logisticsOptimalPalletComposition: Logistics__OptimalPalletComposition;
    logisticsOutboundTaskList: Logistics__OutboundTaskListType;
    logisticsOutboundValidateNewTask: ValidationResponse;
    logisticsPurchaseOrder: Logistics__PurchaseOrder;
    logisticsPurchaseOrders: Array<Logistics__PurchaseOrder>;
    logisticsSku: Logistics__Sku;
    logisticsSkuBulkUploadPreview: Logistics__Sku__BulkUploadPreview;
    logisticsSkuGtinSearch?: Maybe<Logistics__Sku>;
    logisticsSkuUpcSearch?: Maybe<Logistics__Sku>;
    logisticsSkus: Array<Logistics__Sku>;
    logisticsTicketTemplates: Array<Logistics__TicketTemplate>;
    logisticsValidateItemMoveScan: ValidationResponse;
    materialCategories: Array<MaterialCategory>;
    moveCancelReasons: Array<Order__CancelReason>;
    moverTickets: Array<Ticket>;
    movingConfiguration: Moving__Configuration;
    movingItemCategories: Array<Admin__ItemCategory>;
    movingOperationPolicy: OrderMovingOperationPolicy;
    movingRoomCategories: Array<Admin__RoomCategory>;
    nightTransportRequiredDriveTasks: Array<NightTransport__DriveTask>;
    nightTransportRouteTimelines: Array<NightTransport__RouteTimeline>;
    nightTransportRoutes: Array<NightTransport__Route>;
    notes: Array<Note>;
    onboardingCancelReasons: Array<Order__CancelReason>;
    opsAuditConfiguration?: Maybe<OpsAudit__ConfigurationType>;
    order: Order;
    orderCurrentCancellationFee?: Maybe<Scalars['String']>;
    orderLeadTimes: Array<OrderLeadTime>;
    orders: Array<Order>;
    ordersMissingSignature: Array<Order>;
    packingStandards: Array<PackingStandard>;
    paginatedAccounts: PaginatedAccount;
    paginatedClaims: PaginatedClaim;
    paginatedConfigurationList: OpsAudit__PaginatedConfigurationListType;
    paginatedCoupons: Coupon__PaginatedType;
    paginatedCustomerTicketList: PaginatedCustomerTicket;
    paginatedHistoryList: PaginatedHistory;
    paginatedIngestionUploads: Ingestion__PaginatedUpload;
    paginatedLandingLocationCities: Landing__Location__PaginatedCity;
    paginatedLandingLocationNeighborhoods: Landing__Location__PaginatedNeighborhood;
    paginatedLandingLocationRoutes: Landing__Location__PaginatedRoute;
    paginatedLandingLocationStates: Landing__Location__PaginatedState;
    paginatedLeads: PaginatedLead;
    paginatedLoads: Logistics__PaginatedLoadListType;
    paginatedLogisticsPurchaseOrders: Logistics__PaginatedPurchaseOrder;
    paginatedLogisticsSkus: Logistics__PaginatedSku;
    paginatedNotificationList: PaginatedNotification;
    paginatedOrders: PaginatedOrder;
    paginatedPricingUploads: Pricing__PaginatedUpload;
    paginatedPtoRequestGroups: Pto__PaginatedRequestGroupType;
    paginatedRateAdjustmentSchedulings: RateAdjustment__PaginatedScheduling;
    paginatedSelfStorageFacilities: SelfStorage__PaginatedFacility;
    paginatedSelfStoragePartners: SelfStorage__PaginatedPartner;
    paginatedSelfStorageRentals: SelfStorage__PaginatedRental;
    paginatedSelfStorageReservations: SelfStorage__PaginatedReservation;
    paginatedSelfStorageUnits: SelfStorage__PaginatedUnit;
    paginatedTestOrderRequests: Automation__PaginatedTestOrderRequest;
    paginatedTicketList: PaginatedTicket;
    paginatedUsers: PaginatedUser;
    pallet: Pallet;
    palletByBarcode: Pallet;
    palletCategories: Array<PalletCategory>;
    palletGrade?: Maybe<PalletGrade>;
    palletGrades?: Maybe<Array<PalletGrade>>;
    palletTask?: Maybe<PalletTask>;
    palletTasks?: Maybe<Array<PalletTask>>;
    palletType: PalletType;
    palletTypes: Array<PalletType>;
    palletizeSummary: DockApi__PalletizeSummary;
    parcelCarrierAccounts: Array<Logistics__ParcelCarrierAccount>;
    partCategories: Array<PartCategory>;
    paymentPlanOffer: PaymentPlan__Offer;
    phone: Phone;
    phoneCallPolicy: PhoneCallPolicy;
    pickupMoverCount: Scalars['Int'];
    pricingPerItemFee?: Maybe<Pricing__PerItemFee>;
    pricingPolicy: PricingPolicy;
    pricingSet?: Maybe<PricingSet>;
    pricingSetAppointmentData: PricingSetAppointmentData;
    pricingSetRateGroups: Array<RateGroup>;
    pricingUpload: Pricing__Upload;
    ptoRequestGroup: Pto__RequestGroupType;
    pulledToBeStored: Array<Item>;
    rateGroup: RateGroup;
    rateGroups: Array<RateGroup>;
    region: Region;
    regions: Array<Region>;
    reservableSelfStorageUnitGroups: Array<SelfStorage__UnitGroup>;
    reservableSelfStorageUnitsCount: Scalars['Int'];
    roomCategories: Array<RoomCategory>;
    selfStorageFacilities: Array<SelfStorage__Facility>;
    selfStorageFacility: SelfStorage__Facility;
    selfStorageFees: Array<SelfStorage__Fee>;
    selfStoragePartners: Array<SelfStorage__Partner>;
    selfStorageProration?: Maybe<SelfStorage__Proration>;
    selfStorageProtections: Array<SelfStorage__Protection>;
    selfStorageRental: SelfStorage__Rental;
    selfStorageRentalPolicy: SelfStorage__RentalPolicy;
    selfStorageRentals: Array<SelfStorage__Rental>;
    selfStorageReservation: SelfStorage__Reservation;
    selfStorageReservationPolicy: SelfStorage__ReservationPolicy;
    selfStorageShuttle?: Maybe<SelfStorage__Shuttle>;
    selfStorageSubscription: SelfStorage__Subscription;
    selfStorageUnit: SelfStorage__Unit;
    selfStorageUpcomingReservations: Array<SelfStorage__Reservation>;
    sendgridSuppressionBlocks: Array<SendGrid__Suppression__Block>;
    sendgridSuppressionBounces: Array<SendGrid__Suppression__Bounce>;
    sentIterableEmail: SentIterableEmail;
    sourcePolicy: SourcePolicy;
    stagedDisposalItems: Array<Item>;
    storagePlans: Array<Plan>;
    storageSubscriptions: Array<Subscription>;
    storageTerms: Array<StorageTerm>;
    storageTreasuresAuctionPolicy: StorageTreasures__AuctionPolicyType;
    storageTreasuresAuctionsPaginated: StorageTreasures__PaginatedAuctionType;
    teams: Array<Team>;
    ticket: Ticket;
    ticketCategories: Scalars['JSON'];
    tickets: Array<Ticket>;
    touchTaxons: Array<TouchTaxon>;
    trainingUploadAttestationsPaginated: TrainingUploadAttestationPaginated;
    trainingUploadsNotRequiringReview: Array<TrainingUpload>;
    trainingUploadsPaginated: TrainingUploadPaginated;
    trainingUploadsRequiringReview: Array<TrainingUpload>;
    transfers: Array<Transfer>;
    unforgivenInvoices: Array<Billing__Invoice>;
    user: User;
    users: Array<User>;
    usersAvailableForDispatchTask: Array<User>;
    validEmail: Scalars['Boolean'];
    validPhone: Scalars['Boolean'];
    validateNewInboundTask: ValidationResponse;
    vehicleType: VehicleType;
    vehicleTypes: Array<VehicleType>;
    vehicles: Array<Vehicle>;
    warehouse: Warehouse;
    warehouseOrders: DockApi__WarehouseOrders;
    warehouses: Array<Warehouse>;
    workforceFacilities: Array<Workforce__Facility>;
    workforceJobCodes: Array<Workforce__JobCode>;
    workforceReviewableUsers: Array<User>;
    workforceShift: Workforce__Shift;
    workforceShiftAssignment?: Maybe<Workforce__ShiftAssignment>;
    zendeskTicket: ZendeskTicket;
  };

export type QueryAccountArgs = {
  accountID: Scalars['ID'];
};

export type QueryAccountClaimsArgs = {
  accountID: Scalars['ID'];
};

export type QueryAccountPaymentPlanOffersArgs = {
  accountID: Scalars['ID'];
};

export type QueryActiveAuctionOrderArgs = {
  accountID: Scalars['ID'];
};

export type QueryActivePalletRequestsArgs = {
  kind?: InputMaybe<PalletRequestKindEnum>;
  manuallyRequested?: InputMaybe<Scalars['Boolean']>;
  warehouseId: Scalars['ID'];
};

export type QueryAddressesArgs = {
  accountID: Scalars['ID'];
};

export type QueryAttendancePolicyViolationsArgs = {
  from?: InputMaybe<Scalars['Date']>;
  teamID?: InputMaybe<Scalars['ID']>;
  teamReviewMode?: InputMaybe<Scalars['Boolean']>;
  till?: InputMaybe<Scalars['Date']>;
  timeClockPlusUsersOnly?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type QueryAttestationEventsPaginatedArgs = {
  filters?: InputMaybe<AttestationEventFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAuctionPaginatedUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAuctionUploadArgs = {
  id: Scalars['ID'];
};

export type QueryAuditArgs = {
  auditID: Scalars['ID'];
};

export type QueryAuditItemArgs = {
  itemID: Scalars['ID'];
};

export type QueryAuditResultsArgs = {
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
};

export type QueryAvailabilitiesArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  input: AvailabilitiesInput;
};

export type QueryAvailabilityFacilityCuftLimitsArgs = {
  filters: Availability__FacilityCuftLimitFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailabilityGlobalUnavailabilityArgs = {
  filters: Availability__GlobalUnavailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailabilityJobAvailabilityArgs = {
  filters: Availability__JobAvailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailabilityLaborAdjustmentsArgs = {
  filters: Availability__LaborAdjustmentFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailabilityRescheduleOffersArgs = {
  filters: Availability__RescheduleOfferFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryBillingArgs = {
  accountID: Scalars['ID'];
};

export type QueryBillingAuthorizationHoldsArgs = {
  orderID: Scalars['ID'];
};

export type QueryBillingReBillReasonsArgs = {
  invoiceID: Scalars['ID'];
};

export type QueryBillingUnattemptedAuthorizationHoldArgs = {
  orderID: Scalars['ID'];
};

export type QueryCalculatePackageSetEntryAmountArgs = {
  cuft: Scalars['Float'];
  packageKind: Scalars['String'];
  packageSetName: Scalars['String'];
};

export type QueryClaimArgs = {
  id: Scalars['ID'];
};

export type QueryConfigurationAuditReviewArgs = {
  configurationID?: InputMaybe<Scalars['ID']>;
};

export type QueryContractorPasswordResetRequestArgs = {
  id: Scalars['ID'];
};

export type QueryCouponArgs = {
  id: Scalars['ID'];
};

export type QueryCouponsArgs = {
  filters?: InputMaybe<Coupon__FiltersInput>;
};

export type QueryCurrentUserActivePalletTasksArgs = {
  warehouseId: Scalars['ID'];
};

export type QueryCustomerTicketArgs = {
  id: Scalars['ID'];
};

export type QueryCustomerTicketByCallArgs = {
  sid: Scalars['String'];
};

export type QueryDepotArgs = {
  id: Scalars['ID'];
};

export type QueryDepotsArgs = {
  filters?: InputMaybe<FacilityFiltersInput>;
};

export type QueryDispatchArgs = {
  dispatchID: Scalars['ID'];
};

export type QueryDispatchAuditOrderEventsArgs = {
  dispatchID: Scalars['ID'];
};

export type QueryDispatchesArgs = {
  filters?: InputMaybe<DispatchFiltersInput>;
};

export type QueryDocumentsArgs = {
  kinds: Array<DocumentKindEnum>;
};

export type QueryDraftArgs = {
  uniqueID: Scalars['ID'];
};

export type QueryDropOffAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
  orderID?: InputMaybe<Scalars['ID']>;
};

export type QueryDuplicateLoadNumbersArgs = {
  loadNumber: Scalars['String'];
};

export type QueryEstimationAlgorithmForOrderArgs = {
  orderID: Scalars['ID'];
};

export type QueryEstimationBoxItemCategoriesArgs = {
  algorithmID: Scalars['ID'];
};

export type QueryEstimationItemsArgs = {
  algorithmID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
};

export type QueryEstimationOtherItemCategoriesArgs = {
  algorithmID: Scalars['ID'];
};

export type QueryEstimationSelectedRoomsArgs = {
  algorithmID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type QueryEstimationUploadsArgs = {
  orderID: Scalars['ID'];
};

export type QueryEstimationsForOrderArgs = {
  algorithmID: Scalars['ID'];
  orderID: Scalars['ID'];
};

export type QueryFacilitiesArgs = {
  filters?: InputMaybe<FacilityFiltersInput>;
};

export type QueryFacilityArgs = {
  facility: FacilityInput;
};

export type QueryFeatureFlagsArgs = {
  resourceName?: InputMaybe<Scalars['String']>;
};

export type QueryFormattedAccountLaborAgreementArgs = {
  accountId: Scalars['ID'];
};

export type QueryFormattedAppointmentLaborAgreementArgs = {
  orderId: Scalars['ID'];
};

export type QueryFormattedLaborAgreementArgs = {
  cuft: Scalars['Float'];
  laborRateId?: InputMaybe<Scalars['ID']>;
  maxLaborAmount?: InputMaybe<Scalars['Float']>;
  orderZip: Scalars['String'];
  packageSetEntryIds?: InputMaybe<Array<Scalars['ID']>>;
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
};

export type QueryFulfillmentExpectationsArgs = {
  fulfillmentID: Scalars['ID'];
  fulfillmentType: Scalars['String'];
};

export type QueryGeoTimezoneArgs = {
  geo: ClutterGeoEnum;
};

export type QueryGeocodeArgs = {
  input: Geocode__Input;
};

export type QueryInProgressManualPalletRequestArgs = {
  palletID: Scalars['ID'];
};

export type QueryIngestionUploadArgs = {
  id: Scalars['ID'];
};

export type QueryInsufficientSkuInventoryArgs = {
  input: Logistics__InsufficientSkuInventoryInput;
};

export type QueryInsufficientSkuTruckCuftArgs = {
  input: Logistics__InsufficientSkuTruckCuftInput;
};

export type QueryInventoryMetricsArgs = {
  accountID: Scalars['ID'];
};

export type QueryItemArgs = {
  accountID: Scalars['ID'];
  barcode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryItemByBarcodeArgs = {
  barcodeValue: Scalars['String'];
};

export type QueryItemExceptionsArgs = {
  itemId: Scalars['ID'];
};

export type QueryItemGroupArgs = {
  id: Scalars['ID'];
};

export type QueryItemSelectionArgs = {
  claimItemSelectionID: Scalars['ID'];
};

export type QueryItemTransferRequestsArgs = {
  input: DockApi__Transfers__ItemTransferRequestsFilterInput;
};

export type QueryItemsToRewrapArgs = {
  orderID: Scalars['ID'];
};

export type QueryLandingLocationCityArgs = {
  id: Scalars['ID'];
};

export type QueryLandingLocationContentArgs = {
  id: Scalars['ID'];
};

export type QueryLandingLocationNeighborhoodArgs = {
  id: Scalars['ID'];
};

export type QueryLandingLocationRouteArgs = {
  id: Scalars['ID'];
};

export type QueryLandingLocationStateArgs = {
  id: Scalars['ID'];
};

export type QueryLeadArgs = {
  id: Scalars['ID'];
};

export type QueryLogisticsAvailableWarehouseWorkersArgs = {
  input: Logistics__AvailableWarehouseWorkersInput;
};

export type QueryLogisticsLoadArgs = {
  id: Scalars['ID'];
};

export type QueryLogisticsLoadInventoryArgs = {
  id: Scalars['ID'];
};

export type QueryLogisticsLoadsArgs = {
  input: Logistics__LoadsFilterInputType;
};

export type QueryLogisticsOptimalPalletCompositionArgs = {
  input: Logistics__OptimalPalletCompositionInput;
};

export type QueryLogisticsOutboundTaskListArgs = {
  input: Logistics__OutboundTaskList__InputType;
};

export type QueryLogisticsOutboundValidateNewTaskArgs = {
  input: Logistics__OutboundTaskList__ValidateNewTaskInputType;
};

export type QueryLogisticsPurchaseOrderArgs = {
  id: Scalars['ID'];
};

export type QueryLogisticsSkuArgs = {
  id: Scalars['ID'];
};

export type QueryLogisticsSkuBulkUploadPreviewArgs = {
  input: Logistics__Sku__BulkUploadPreviewInput;
};

export type QueryLogisticsSkuGtinSearchArgs = {
  gtinValue: Scalars['String'];
};

export type QueryLogisticsSkuUpcSearchArgs = {
  upcValue: Scalars['String'];
};

export type QueryLogisticsSkusArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Logistics__SkusFilterInputType>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryLogisticsTicketTemplatesArgs = {
  ticketableType?: InputMaybe<Ticket__TicketableEnum>;
};

export type QueryLogisticsValidateItemMoveScanArgs = {
  input: Logistics__ValidateItemMoveScanInputType;
};

export type QueryMovingConfigurationArgs = {
  movingConfigurationID: Scalars['ID'];
};

export type QueryNightTransportRequiredDriveTasksArgs = {
  input: NightTransport__DriveTask__Input;
};

export type QueryNightTransportRouteTimelinesArgs = {
  input: NightTransport__RouteTimelineInput;
};

export type QueryNightTransportRoutesArgs = {
  filters: NightTransport__Route__FiltersInput;
};

export type QueryNotesArgs = {
  notable: NoteNotableInput;
};

export type QueryOpsAuditConfigurationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryOrderArgs = {
  orderID: Scalars['ID'];
};

export type QueryOrderCurrentCancellationFeeArgs = {
  orderID: Scalars['ID'];
};

export type QueryOrderLeadTimesArgs = {
  warehouseID: Scalars['ID'];
};

export type QueryOrdersArgs = {
  accountID: Scalars['ID'];
};

export type QueryOrdersMissingSignatureArgs = {
  accountID: Scalars['ID'];
};

export type QueryPaginatedAccountsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  referable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Account__State>;
};

export type QueryPaginatedClaimsArgs = {
  filters: Claim__FiltersInput;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedConfigurationListArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedCouponsArgs = {
  filters?: InputMaybe<Coupon__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedCustomerTicketListArgs = {
  filters?: InputMaybe<CustomerTicketFiltersInput>;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<CustomerTicketSortInput>;
};

export type QueryPaginatedHistoryListArgs = {
  page?: InputMaybe<Scalars['Int']>;
  subject: History__SubjectInput;
};

export type QueryPaginatedIngestionUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLandingLocationCitiesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedLandingLocationNeighborhoodsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedLandingLocationRoutesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedLandingLocationStatesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedLeadsArgs = {
  filters?: InputMaybe<LeadFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLoadsArgs = {
  filters?: InputMaybe<Logistics__LoadsFilterInputType>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLogisticsPurchaseOrdersArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLogisticsSkusArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedNotificationListArgs = {
  accountId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<OrderSubjectInput>;
};

export type QueryPaginatedPricingUploadsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedPtoRequestGroupsArgs = {
  filters?: InputMaybe<Pto__RequestFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedRateAdjustmentSchedulingsArgs = {
  filters?: InputMaybe<RateAdjustment__Scheduling__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSelfStorageFacilitiesArgs = {
  filters?: InputMaybe<SelfStorage__Facility__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSelfStoragePartnersArgs = {
  filters?: InputMaybe<SelfStorage__PartnerFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSelfStorageRentalsArgs = {
  filters?: InputMaybe<SelfStorage__RentalFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSelfStorageReservationsArgs = {
  filters?: InputMaybe<SelfStorage__Reservation__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSelfStorageUnitsArgs = {
  filters?: InputMaybe<SelfStorage__Unit__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedTestOrderRequestsArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedTicketListArgs = {
  filters?: InputMaybe<TicketFiltersInput>;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedUsersArgs = {
  filters?: InputMaybe<UserFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPalletArgs = {
  id: Scalars['ID'];
};

export type QueryPalletByBarcodeArgs = {
  barcodeValue: Scalars['String'];
};

export type QueryPalletGradeArgs = {
  gradeID: Scalars['ID'];
};

export type QueryPalletGradesArgs = {
  palletID: Scalars['ID'];
};

export type QueryPalletTaskArgs = {
  palletTaskID: Scalars['ID'];
};

export type QueryPalletTasksArgs = {
  destinationLocationID: Scalars['ID'];
};

export type QueryPalletTypeArgs = {
  id: Scalars['ID'];
};

export type QueryPalletizeSummaryArgs = {
  orderID: Scalars['ID'];
};

export type QueryParcelCarrierAccountsArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentPlanOfferArgs = {
  accountID: Scalars['ID'];
  offerID: Scalars['ID'];
};

export type QueryPhoneArgs = {
  id: Scalars['ID'];
};

export type QueryPickupMoverCountArgs = {
  inventory: PickupInventoryInput;
  orderID: Scalars['ID'];
};

export type QueryPricingPerItemFeeArgs = {
  input: Pricing__PerItemFeeInput;
};

export type QueryPricingSetArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type QueryPricingSetAppointmentDataArgs = {
  cuft: Scalars['Float'];
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
};

export type QueryPricingSetRateGroupsArgs = {
  pricingSetId: Scalars['ID'];
};

export type QueryPricingUploadArgs = {
  id: Scalars['ID'];
};

export type QueryPtoRequestGroupArgs = {
  id: Scalars['ID'];
};

export type QueryPulledToBeStoredArgs = {
  orderID: Scalars['ID'];
};

export type QueryRateGroupArgs = {
  rateGroupID: Scalars['ID'];
};

export type QueryRegionArgs = {
  regionID: Scalars['ID'];
};

export type QueryRegionsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
};

export type QueryReservableSelfStorageUnitGroupsArgs = {
  facilityID: Scalars['ID'];
};

export type QueryReservableSelfStorageUnitsCountArgs = {
  ids: Array<SelfStorage__ClassificationFacilityInput>;
};

export type QuerySelfStorageFacilityArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageFeesArgs = {
  facilityID: Scalars['ID'];
};

export type QuerySelfStorageProrationArgs = {
  accountID: Scalars['ID'];
  facilityID: Scalars['ID'];
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type QuerySelfStorageProtectionsArgs = {
  facilityID: Scalars['ID'];
};

export type QuerySelfStorageRentalArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageRentalsArgs = {
  accountID: Scalars['ID'];
};

export type QuerySelfStorageReservationArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageReservationPolicyArgs = {
  reservationID?: InputMaybe<Scalars['ID']>;
};

export type QuerySelfStorageShuttleArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageSubscriptionArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageUnitArgs = {
  id: Scalars['ID'];
};

export type QuerySelfStorageUpcomingReservationsArgs = {
  facilityID: Scalars['ID'];
};

export type QuerySendgridSuppressionBlocksArgs = {
  email: Scalars['String'];
};

export type QuerySendgridSuppressionBouncesArgs = {
  email: Scalars['String'];
};

export type QuerySentIterableEmailArgs = {
  notificationId: Scalars['ID'];
};

export type QueryStagedDisposalItemsArgs = {
  warehouseID: Scalars['ID'];
};

export type QueryStorageSubscriptionsArgs = {
  accountID: Scalars['ID'];
};

export type QueryStorageTreasuresAuctionsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryTicketArgs = {
  id: Scalars['ID'];
};

export type QueryTicketsArgs = {
  ticketable: Ticket__TicketableInput;
};

export type QueryTouchTaxonsArgs = {
  root?: InputMaybe<Scalars['String']>;
};

export type QueryTrainingUploadAttestationsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>;
  trainingUploadID: Scalars['ID'];
  userQuery?: InputMaybe<Scalars['String']>;
};

export type QueryTrainingUploadsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryTransfersArgs = {
  input: DockApi__Transfers__FilterInput;
};

export type QueryUnforgivenInvoicesArgs = {
  accountID: Scalars['ID'];
  statuses?: InputMaybe<Array<Billing__Invoice__Status>>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  filters?: InputMaybe<UserFiltersInput>;
};

export type QueryUsersAvailableForDispatchTaskArgs = {
  warehouseID: Scalars['ID'];
};

export type QueryValidEmailArgs = {
  email: Scalars['String'];
};

export type QueryValidPhoneArgs = {
  phone: Scalars['String'];
};

export type QueryValidateNewInboundTaskArgs = {
  input: ForkliftApi__ValidateNewInboundTaskInput;
};

export type QueryVehicleTypeArgs = {
  id: Scalars['ID'];
};

export type QueryVehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
};

export type QueryWarehouseArgs = {
  id: Scalars['ID'];
};

export type QueryWarehouseOrdersArgs = {
  warehouseID: Scalars['ID'];
};

export type QueryWarehousesArgs = {
  filters?: InputMaybe<FacilityFiltersInput>;
};

export type QueryWorkforceShiftArgs = {
  id: Scalars['ID'];
};

export type QueryWorkforceShiftAssignmentArgs = {
  date: Scalars['Date'];
  userID: Scalars['ID'];
};

export type QueryZendeskTicketArgs = {
  maxComments?: InputMaybe<Scalars['Int']>;
  zdTicketID: Scalars['ID'];
};

export type RateAdjustmentMutationInterface = {
  cancelRateAdjustmentScheduling: RateAdjustment__CancelSchedulingPayload;
  confirmRateAdjustmentUpload: RateAdjustment__ConfirmUploadPayload;
  previewRateAdjustmentUpload: RateAdjustment__PreviewUploadPayload;
};

export type RateAdjustmentMutationInterfaceCancelRateAdjustmentSchedulingArgs = {
  cancelReason: RateAdjustment__Scheduling__CancelReason;
  id: Scalars['ID'];
};

export type RateAdjustmentMutationInterfaceConfirmRateAdjustmentUploadArgs = {
  id: Scalars['ID'];
};

export type RateAdjustmentMutationInterfacePreviewRateAdjustmentUploadArgs = {
  input: RateAdjustment__UploadInput;
};

/** Autogenerated return type of RateAdjustment__CancelScheduling */
export type RateAdjustment__CancelSchedulingPayload = {
  __typename?: 'RateAdjustment__CancelSchedulingPayload';
  error?: Maybe<Scalars['String']>;
  rateAdjustment?: Maybe<RateAdjustment__Scheduling>;
  status: Status;
};

/** Autogenerated return type of RateAdjustment__ConfirmUpload */
export type RateAdjustment__ConfirmUploadPayload = {
  __typename?: 'RateAdjustment__ConfirmUploadPayload';
  status: Status;
};

export type RateAdjustment__Draft = {
  __typename?: 'RateAdjustment__Draft';
  accountID: Scalars['ID'];
  bestOffer?: Maybe<Scalars['Float']>;
  effectiveMonthYear: Scalars['Date'];
  excludeFromEmails: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  newPrice: Scalars['Float'];
  rentalID?: Maybe<Scalars['ID']>;
};

export type RateAdjustment__PaginatedScheduling = {
  __typename?: 'RateAdjustment__PaginatedScheduling';
  pagination: Pagination;
  results: Array<RateAdjustment__Scheduling>;
};

/** Autogenerated return type of RateAdjustment__PreviewUpload */
export type RateAdjustment__PreviewUploadPayload = {
  __typename?: 'RateAdjustment__PreviewUploadPayload';
  drafts?: Maybe<Array<RateAdjustment__Draft>>;
  error?: Maybe<Scalars['String']>;
  status: Status;
  upload?: Maybe<RateAdjustment__Upload>;
};

export type RateAdjustment__Scheduling = {
  __typename?: 'RateAdjustment__Scheduling';
  account: Account;
  bestOffer?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<RateAdjustment__Scheduling__CancelReason>;
  createdAt: Scalars['DateTime'];
  effectiveAt: Scalars['DateTime'];
  excludeFromEmails: Scalars['Boolean'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  newPrice: Scalars['Float'];
  notified: Scalars['Boolean'];
  oldPrice: Scalars['Float'];
  percentageWithSign: Scalars['String'];
  selfStorageRental?: Maybe<SelfStorage__Rental>;
  state: RateAdjustment__Scheduling__State;
};

export enum RateAdjustment__Scheduling__CancelReason {
  AccountCanceled = 'ACCOUNT_CANCELED',
  Admin = 'ADMIN',
  RentalCanceled = 'RENTAL_CANCELED',
  Waived = 'WAIVED',
}

export type RateAdjustment__Scheduling__FiltersInput = {
  facilityIDs?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['Date']>;
  newPrice?: InputMaybe<RateAdjustment__Scheduling__Filters__RangeInput>;
  oldPrice?: InputMaybe<RateAdjustment__Scheduling__Filters__RangeInput>;
  product?: InputMaybe<RateAdjustment__Upload__Product>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<RateAdjustment__Scheduling__State>;
  till?: InputMaybe<Scalars['Date']>;
  unitClassification?: InputMaybe<SelfStorage__Unit__Filters__ClassificationInput>;
};

export type RateAdjustment__Scheduling__Filters__RangeInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export enum RateAdjustment__Scheduling__State {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
}

export type RateAdjustment__Upload = {
  __typename?: 'RateAdjustment__Upload';
  id: Scalars['ID'];
};

export type RateAdjustment__UploadInput = {
  product: RateAdjustment__Upload__Product;
  spreadsheetID: Scalars['ID'];
};

export enum RateAdjustment__Upload__Product {
  DoorToDoor = 'DOOR_TO_DOOR',
  SelfStorage = 'SELF_STORAGE',
}

export type RateGroup = {
  __typename?: 'RateGroup';
  curbside: Scalars['Boolean'];
  id: Scalars['ID'];
  name: RateGroupKindEnum;
};

export enum RateGroupKindEnum {
  /** @deprecated The Capsule Mover rate group is no longer offered */
  CapsuleMover = 'CAPSULE_MOVER',
  CurbsideFlexer = 'CURBSIDE_FLEXER',
  CurbsideMover = 'CURBSIDE_MOVER',
  CurbsideSaver = 'CURBSIDE_SAVER',
  /** @deprecated The Drop-off Flexer rate group is no longer offered */
  DropOffFlexer = 'DROP_OFF_FLEXER',
  /** @deprecated The Drop-off Mover rate group is no longer offered */
  DropOffMover = 'DROP_OFF_MOVER',
  /** @deprecated The Drop-off Saver rate group is no longer offered */
  DropOffSaver = 'DROP_OFF_SAVER',
  Flexer = 'FLEXER',
  LongTerm = 'LONG_TERM',
  Mover = 'MOVER',
  Saver = 'SAVER',
  ShortTerm = 'SHORT_TERM',
}

export enum Rating {
  Good = 'GOOD',
  Poor = 'POOR',
}

export type ReasonQueryInterface = {
  moveCancelReasons: Array<Order__CancelReason>;
  onboardingCancelReasons: Array<Order__CancelReason>;
};

export type Recording = {
  __typename?: 'Recording';
  id: Scalars['ID'];
  playbackURL?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Refund */
export type RefundPayload = {
  __typename?: 'RefundPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of RegenerateDocuments */
export type RegenerateDocumentsPayload = {
  __typename?: 'RegenerateDocumentsPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Region = {
  __typename?: 'Region';
  active: Scalars['Boolean'];
  center: LocatablePoint;
  coordinates: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  defaultDepot?: Maybe<Depot>;
  defaultWarehouse?: Maybe<Warehouse>;
  fulfiller: RegionFulfillerEnum;
  geo: ClutterGeoEnum;
  hexColor: Scalars['String'];
  id: Scalars['ID'];
  irmCluster: IrmClusterEnum;
  irmTerritory: IrmTerritoryEnum;
  movingExtendedServiceAreaPostalCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  netsuiteRegionID?: Maybe<Scalars['Int']>;
  operatingScheduleTemplates: Array<OperatingScheduleTemplate>;
  parent?: Maybe<Region>;
  parentID?: Maybe<Scalars['ID']>;
  storageExtendedServiceAreaPostalCodes: Array<Scalars['String']>;
  tz: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCodes: Array<Scalars['String']>;
};

/** Autogenerated return type of RegionCreate */
export type RegionCreatePayload = {
  __typename?: 'RegionCreatePayload';
  error?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  status: Status;
};

export type RegionCreate__Input = {
  defaultWarehouseID: Scalars['ID'];
  fulfiller: RegionFulfillerEnum;
  geo: ClutterGeoEnum;
  hexColor: Scalars['String'];
  irmCluster: IrmClusterEnum;
  irmTerritory: IrmTerritoryEnum;
  name: Scalars['String'];
  netsuiteRegionID: Scalars['ID'];
  tz: Scalars['String'];
};

export enum RegionFulfillerEnum {
  Clutter = 'CLUTTER',
  Irm = 'IRM',
}

/** Autogenerated return type of Reopen */
export type ReopenPayload = {
  __typename?: 'ReopenPayload';
  customerTicket?: Maybe<CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Reschedule */
export type ReschedulePayload = {
  __typename?: 'ReschedulePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

/** Autogenerated return type of ResendIterableNotification */
export type ResendIterableNotificationPayload = {
  __typename?: 'ResendIterableNotificationPayload';
  status: Status;
};

/** Autogenerated return type of ResendNotification */
export type ResendNotificationPayload = {
  __typename?: 'ResendNotificationPayload';
  notification?: Maybe<Notification>;
  status: Status;
};

/** Autogenerated return type of ResendTodayAppUrl */
export type ResendTodayAppUrlPayload = {
  __typename?: 'ResendTodayAppUrlPayload';
  status: Status;
};

/** Autogenerated return type of Reset */
export type ResetPayload = {
  __typename?: 'ResetPayload';
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Resolve */
export type ResolvePayload = {
  __typename?: 'ResolvePayload';
  customerTicket?: Maybe<CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Retail__DeliveryItem = {
  __typename?: 'Retail__DeliveryItem';
  id: Scalars['ID'];
  /** The number of material kits associated with the delivery item */
  quantity: Scalars['Int'];
  summedComponentSummaries: Array<Scalars['String']>;
  /** The material kit associated with the delivery item */
  unit: Retail__MaterialKit;
};

export type Retail__MaterialKit = {
  __typename?: 'Retail__MaterialKit';
  id: Scalars['ID'];
  /** The items belonging to the kit */
  items: Array<Retail__MaterialKitItem>;
  /** The name of the kit */
  name: Scalars['String'];
};

export type Retail__MaterialKitItem = {
  __typename?: 'Retail__MaterialKitItem';
  id: Scalars['ID'];
  /** The material of the item (e.g., 'Tape', 'Bubble Wrap') */
  material: Scalars['String'];
  /** The material kit the items belong to */
  materialKit: Retail__MaterialKit;
  /** The number of items */
  quantity: Scalars['Int'];
  /** A pluraized description of the items (e.g., '1x roll of tape', '2x rolls of tape') */
  summary: Scalars['String'];
  /** The unit the items come in (e.g., 'Sheet', 'Roll') */
  unit?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReturnRoute */
export type ReturnRoutePayload = {
  __typename?: 'ReturnRoutePayload';
  order: Order;
};

export type Review = {
  __typename?: 'Review';
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  rating: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RoomCategory = {
  __typename?: 'RoomCategory';
  countable: Scalars['Boolean'];
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RoomInput = {
  categoryID: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};

export type RoomSelection = {
  __typename?: 'RoomSelection';
  category: RoomCategory;
  categoryID: Scalars['ID'];
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type RouteLocation = Landing__Location__City | Landing__Location__Neighborhood | Landing__Location__State;

/** Autogenerated return type of RouteUpsert */
export type RouteUpsertPayload = {
  __typename?: 'RouteUpsertPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of SaveDraft */
export type SaveDraftPayload = {
  __typename?: 'SaveDraftPayload';
  status: Status;
};

export type SelfStorageMutationInterface = {
  bookSelfStorageReservation?: Maybe<SelfStorage__Reservation__BuildPayload>;
  cancelSelfStorageRental: SelfStorage__Rental__CancelPayload;
  cancelSelfStorageReservation: SelfStorage__Reservation__CancelPayload;
  destroySelfStoragePromotionBatch: SelfStorage__DestroyPromotionBatchPayload;
  generateSelfStorageGateCode: SelfStorage__GenerateGateCodePayload;
  generateSelfStorageSigning: SelfStorage__GenerateSigningPayload;
  modifySelfStorageBilling: SelfStorage__Billing__ModifyPayload;
  modifySelfStorageDefaultSource: SelfStorage__Billing__DefaultSource__ModifyPayload;
  modifySelfStorageReservation: SelfStorage__Reservation__ModifyPayload;
  modifySelfStorageThirdPartyReservation: SelfStorage__Reservation__ThirdPartyModifyPayload;
  modifySelfStorageUnit: SelfStorage__Unit__ModifyPayload;
  overlockSelfStorageRental: SelfStorage__Rental__OverlockPayload;
  rentSelfStorage: SelfStorage__RentPayload;
  saveSelfStoragePromotionBatch: SelfStorage__SavePromotionBatchPayload;
  saveSelfStorageUpload: SelfStorage__Upload__SavePayload;
};

export type SelfStorageMutationInterfaceBookSelfStorageReservationArgs = {
  input: SelfStorage__Reservation__BuildInput;
};

export type SelfStorageMutationInterfaceCancelSelfStorageRentalArgs = {
  id: Scalars['ID'];
  prorate: Scalars['Boolean'];
  skipDelinquentVerification?: InputMaybe<Scalars['Boolean']>;
};

export type SelfStorageMutationInterfaceCancelSelfStorageReservationArgs = {
  id: Scalars['ID'];
};

export type SelfStorageMutationInterfaceDestroySelfStoragePromotionBatchArgs = {
  input: SelfStorage__DestroyPromotionBatchInput;
};

export type SelfStorageMutationInterfaceGenerateSelfStorageGateCodeArgs = {
  accountID?: InputMaybe<Scalars['ID']>;
  facilityID: Scalars['ID'];
};

export type SelfStorageMutationInterfaceGenerateSelfStorageSigningArgs = {
  channel: Scalars['ID'];
  input: SelfStorage__RentInput;
};

export type SelfStorageMutationInterfaceModifySelfStorageBillingArgs = {
  accountID: Scalars['ID'];
  inputs: Array<SelfStorage__BillingLineInput>;
};

export type SelfStorageMutationInterfaceModifySelfStorageDefaultSourceArgs = {
  billing: SelfStorage__Rental__BillingEnum;
  sourceID?: InputMaybe<Scalars['ID']>;
  subscriptionID: Scalars['ID'];
};

export type SelfStorageMutationInterfaceModifySelfStorageReservationArgs = {
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ModifyInput;
};

export type SelfStorageMutationInterfaceModifySelfStorageThirdPartyReservationArgs = {
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ThirdPartyModifyInput;
};

export type SelfStorageMutationInterfaceModifySelfStorageUnitArgs = {
  id: Scalars['ID'];
  input: SelfStorage__UnitInput;
};

export type SelfStorageMutationInterfaceOverlockSelfStorageRentalArgs = {
  id: Scalars['ID'];
  overlocked: Scalars['Boolean'];
};

export type SelfStorageMutationInterfaceRentSelfStorageArgs = {
  input: SelfStorage__RentInput;
};

export type SelfStorageMutationInterfaceSaveSelfStoragePromotionBatchArgs = {
  input: SelfStorage__SavePromotionBatchInput;
};

export type SelfStorageMutationInterfaceSaveSelfStorageUploadArgs = {
  input: SelfStorage__UploadInput;
};

export type SelfStorageQueryInterface = {
  paginatedSelfStorageFacilities: SelfStorage__PaginatedFacility;
  paginatedSelfStoragePartners: SelfStorage__PaginatedPartner;
  paginatedSelfStorageRentals: SelfStorage__PaginatedRental;
  paginatedSelfStorageReservations: SelfStorage__PaginatedReservation;
  paginatedSelfStorageUnits: SelfStorage__PaginatedUnit;
  reservableSelfStorageUnitGroups: Array<SelfStorage__UnitGroup>;
  reservableSelfStorageUnitsCount: Scalars['Int'];
  selfStorageFacilities: Array<SelfStorage__Facility>;
  selfStorageFacility: SelfStorage__Facility;
  selfStorageFees: Array<SelfStorage__Fee>;
  selfStoragePartners: Array<SelfStorage__Partner>;
  selfStorageProration?: Maybe<SelfStorage__Proration>;
  selfStorageProtections: Array<SelfStorage__Protection>;
  selfStorageRental: SelfStorage__Rental;
  selfStorageRentalPolicy: SelfStorage__RentalPolicy;
  selfStorageRentals: Array<SelfStorage__Rental>;
  selfStorageReservation: SelfStorage__Reservation;
  selfStorageReservationPolicy: SelfStorage__ReservationPolicy;
  selfStorageShuttle?: Maybe<SelfStorage__Shuttle>;
  selfStorageSubscription: SelfStorage__Subscription;
  selfStorageUnit: SelfStorage__Unit;
  selfStorageUpcomingReservations: Array<SelfStorage__Reservation>;
};

export type SelfStorageQueryInterfacePaginatedSelfStorageFacilitiesArgs = {
  filters?: InputMaybe<SelfStorage__Facility__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorageQueryInterfacePaginatedSelfStoragePartnersArgs = {
  filters?: InputMaybe<SelfStorage__PartnerFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorageQueryInterfacePaginatedSelfStorageRentalsArgs = {
  filters?: InputMaybe<SelfStorage__RentalFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorageQueryInterfacePaginatedSelfStorageReservationsArgs = {
  filters?: InputMaybe<SelfStorage__Reservation__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorageQueryInterfacePaginatedSelfStorageUnitsArgs = {
  filters?: InputMaybe<SelfStorage__Unit__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorageQueryInterfaceReservableSelfStorageUnitGroupsArgs = {
  facilityID: Scalars['ID'];
};

export type SelfStorageQueryInterfaceReservableSelfStorageUnitsCountArgs = {
  ids: Array<SelfStorage__ClassificationFacilityInput>;
};

export type SelfStorageQueryInterfaceSelfStorageFacilityArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageFeesArgs = {
  facilityID: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageProrationArgs = {
  accountID: Scalars['ID'];
  facilityID: Scalars['ID'];
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type SelfStorageQueryInterfaceSelfStorageProtectionsArgs = {
  facilityID: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageRentalArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageRentalsArgs = {
  accountID: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageReservationArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageReservationPolicyArgs = {
  reservationID?: InputMaybe<Scalars['ID']>;
};

export type SelfStorageQueryInterfaceSelfStorageShuttleArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageSubscriptionArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageUnitArgs = {
  id: Scalars['ID'];
};

export type SelfStorageQueryInterfaceSelfStorageUpcomingReservationsArgs = {
  facilityID: Scalars['ID'];
};

export type SelfStorage__AddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type SelfStorage__AddressesInput = {
  billing?: InputMaybe<SelfStorage__AddressInput>;
  billingID?: InputMaybe<Scalars['ID']>;
  mailing?: InputMaybe<SelfStorage__AddressInput>;
  mailingID?: InputMaybe<Scalars['ID']>;
};

export type SelfStorage__BillingLineInput = {
  feeIDs?: InputMaybe<Array<Scalars['ID']>>;
  protectionID?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['Float']>;
  removePromotion?: InputMaybe<Scalars['Boolean']>;
  rentalID: Scalars['ID'];
};

/** Autogenerated return type of SelfStorage__Billing__DefaultSource__Modify */
export type SelfStorage__Billing__DefaultSource__ModifyPayload = {
  __typename?: 'SelfStorage__Billing__DefaultSource__ModifyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  subscription?: Maybe<SelfStorage__Subscription>;
};

/** Autogenerated return type of SelfStorage__Billing__Modify */
export type SelfStorage__Billing__ModifyPayload = {
  __typename?: 'SelfStorage__Billing__ModifyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type SelfStorage__Classification = {
  __typename?: 'SelfStorage__Classification';
  amenities: Array<Scalars['String']>;
  category: SelfStorage__Classification__Category;
  climate: Scalars['Boolean'];
  electricity: Scalars['Boolean'];
  firstFloor: Scalars['Boolean'];
  height: Scalars['Float'];
  id: Scalars['ID'];
  indoors: Scalars['Boolean'];
  kind: SelfStorage__Kind;
  length: Scalars['Float'];
  obstructed: Scalars['Boolean'];
  status: SelfStorage__Unit__Status;
  width: Scalars['Float'];
};

export type SelfStorage__ClassificationFacilityInput = {
  classificationID: Scalars['ID'];
  facilityID: Scalars['ID'];
};

export type SelfStorage__ClassificationInput = {
  amenities: Array<SelfStorage__Classification__Amenity>;
  category: SelfStorage__Classification__Category;
  height: Scalars['Float'];
  length: Scalars['Float'];
  obstructed: Scalars['Boolean'];
  width: Scalars['Float'];
};

export enum SelfStorage__Classification__Amenity {
  ClimateControlled = 'CLIMATE_CONTROLLED',
  Electricity = 'ELECTRICITY',
  FirstFloor = 'FIRST_FLOOR',
  Indoors = 'INDOORS',
}

export enum SelfStorage__Classification__Category {
  Locker = 'LOCKER',
  ParkingMotorcycle = 'PARKING_MOTORCYCLE',
  ParkingVehicle = 'PARKING_VEHICLE',
  Unit = 'UNIT',
}

export type SelfStorage__CustomerInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type SelfStorage__DestroyPromotionBatchInput = {
  IDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of SelfStorage__DestroyPromotionBatch */
export type SelfStorage__DestroyPromotionBatchPayload = {
  __typename?: 'SelfStorage__DestroyPromotionBatchPayload';
  status: Status;
};

export type SelfStorage__Document = {
  __typename?: 'SelfStorage__Document';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SelfStorage__Facility = {
  __typename?: 'SelfStorage__Facility';
  accessCode?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  address: Address;
  bookingLink: Scalars['String'];
  documents: Array<SelfStorage__Document>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paginatedSelfStorageUnits: SelfStorage__PaginatedUnit;
  partner?: Maybe<SelfStorage__Partner>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
};

export type SelfStorage__FacilityPaginatedSelfStorageUnitsArgs = {
  filters?: InputMaybe<SelfStorage__Unit__FiltersInput>;
  orderings?: InputMaybe<Array<SelfStorage__Unit__OrderingAttribute>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SelfStorage__Facility__FiltersInput = {
  company?: InputMaybe<SelfStorage__Partner__Company>;
  partnerIDs?: InputMaybe<Array<Scalars['ID']>>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type SelfStorage__Fee = {
  __typename?: 'SelfStorage__Fee';
  facilityID: Scalars['ID'];
  id: Scalars['ID'];
  kind: SelfStorage__Fee__Kind;
  price: Scalars['Float'];
  recurring: Scalars['Boolean'];
  waivable: Scalars['Boolean'];
};

export enum SelfStorage__Fee__Kind {
  Admin = 'ADMIN',
  Convenience = 'CONVENIENCE',
  Electricity = 'ELECTRICITY',
  ExtendedAccess = 'EXTENDED_ACCESS',
  Lock = 'LOCK',
  UnlimitedAccess = 'UNLIMITED_ACCESS',
}

/** Autogenerated return type of SelfStorage__GenerateGateCode */
export type SelfStorage__GenerateGateCodePayload = {
  __typename?: 'SelfStorage__GenerateGateCodePayload';
  value: Scalars['String'];
};

/** Autogenerated return type of SelfStorage__GenerateSigning */
export type SelfStorage__GenerateSigningPayload = {
  __typename?: 'SelfStorage__GenerateSigningPayload';
  code: Scalars['String'];
  signatures: Array<SelfStorage__Signature>;
  url: Scalars['String'];
};

export type SelfStorage__Hold = {
  __typename?: 'SelfStorage__Hold';
  activatedAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  unit: SelfStorage__Unit;
};

export type SelfStorage__Identification = {
  __typename?: 'SelfStorage__Identification';
  birthday: Scalars['Date'];
  id: Scalars['ID'];
  number: Scalars['String'];
  state: Scalars['String'];
};

export type SelfStorage__IdentificationInput = {
  birthday: Scalars['Date'];
  number: Scalars['String'];
  state: Scalars['String'];
};

export type SelfStorage__Kind = {
  __typename?: 'SelfStorage__Kind';
  formattedName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  subtype?: Maybe<SelfStorage__Kind__Subtype>;
};

export enum SelfStorage__Kind__Subtype {
  Motorcycle = 'MOTORCYCLE',
  Vehicle = 'VEHICLE',
}

export type SelfStorage__PaginatedFacility = {
  __typename?: 'SelfStorage__PaginatedFacility';
  pagination: Pagination;
  results: Array<SelfStorage__Facility>;
};

export type SelfStorage__PaginatedPartner = {
  __typename?: 'SelfStorage__PaginatedPartner';
  pagination: Pagination;
  results: Array<SelfStorage__Partner>;
};

export type SelfStorage__PaginatedRental = {
  __typename?: 'SelfStorage__PaginatedRental';
  pagination: Pagination;
  results: Array<SelfStorage__Rental>;
};

export type SelfStorage__PaginatedReservation = {
  __typename?: 'SelfStorage__PaginatedReservation';
  pagination: Pagination;
  results: Array<SelfStorage__Reservation>;
};

export type SelfStorage__PaginatedUnit = {
  __typename?: 'SelfStorage__PaginatedUnit';
  pagination: Pagination;
  results: Array<SelfStorage__Unit>;
};

export type SelfStorage__Partner = {
  __typename?: 'SelfStorage__Partner';
  company?: Maybe<SelfStorage__Partner__Company>;
  facilitiesCount: Scalars['Int'];
  id: Scalars['ID'];
  isThirdParty: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SelfStorage__PartnerFiltersInput = {
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export enum SelfStorage__Partner__Company {
  Clutter = 'CLUTTER',
  CubeSmart = 'CUBE_SMART',
  DevonSelfStorage = 'DEVON_SELF_STORAGE',
  ExtraSpace = 'EXTRA_SPACE',
  PublicStorage = 'PUBLIC_STORAGE',
}

export type SelfStorage__PhonesInput = {
  cell?: InputMaybe<Scalars['String']>;
  home?: InputMaybe<Scalars['String']>;
  work?: InputMaybe<Scalars['String']>;
};

export type SelfStorage__Policy = {
  __typename?: 'SelfStorage__Policy';
  amount: Scalars['Float'];
  company: Scalars['String'];
  from: Scalars['Date'];
  id: Scalars['ID'];
  kind: SelfStorage__Policy__Kind;
  number: Scalars['String'];
  till: Scalars['Date'];
};

export type SelfStorage__PolicyInput = {
  amount: Scalars['Float'];
  company: Scalars['String'];
  from: Scalars['Date'];
  kind: SelfStorage__Policy__Kind;
  number: Scalars['String'];
  till: Scalars['Date'];
};

export enum SelfStorage__Policy__Kind {
  AutoInsurance = 'AUTO_INSURANCE',
  Commercial = 'COMMERCIAL',
  GeneralLiability = 'GENERAL_LIABILITY',
  Homeowner = 'HOMEOWNER',
  Renter = 'RENTER',
  UmbrellaCoverage = 'UMBRELLA_COVERAGE',
}

export type SelfStorage__Price = {
  __typename?: 'SelfStorage__Price';
  id: Scalars['ID'];
  pushRate: Scalars['Float'];
  quotedRate: Scalars['Float'];
  rate: Scalars['Float'];
};

export type SelfStorage__PriceInput = {
  pushRate: Scalars['Float'];
  rate: Scalars['Float'];
};

export type SelfStorage__PriceOverrideInput = {
  rate: Scalars['Float'];
  reason: Scalars['String'];
};

export type SelfStorage__Promotion = {
  __typename?: 'SelfStorage__Promotion';
  /** The number of months until the promotion is applied. */
  delay: Scalars['Int'];
  id: Scalars['ID'];
  kind: SelfStorage__Promotion__Kind;
  /** A summary of the promotion (i.e. "$30/month for 2 months"). */
  name: Scalars['String'];
  /** The number of months the promotion discounts a subscription for. */
  term: Scalars['Int'];
  /** The percent or amount applied (i.e. 20% or $30) to the promotion. */
  value: Scalars['Float'];
};

export enum SelfStorage__Promotion__Kind {
  Amount = 'AMOUNT',
  Override = 'OVERRIDE',
  Percent = 'PERCENT',
}

export type SelfStorage__Proration = {
  __typename?: 'SelfStorage__Proration';
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  ratio: Scalars['Float'];
};

export type SelfStorage__Protection = {
  __typename?: 'SelfStorage__Protection';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type SelfStorage__RentInput = {
  accountID?: InputMaybe<Scalars['ID']>;
  addresses: SelfStorage__AddressesInput;
  customer?: InputMaybe<SelfStorage__CustomerInput>;
  feeIDs: Array<Scalars['ID']>;
  military?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<SelfStorage__RentalNotesInput>;
  phones: SelfStorage__PhonesInput;
  priceOverride?: InputMaybe<SelfStorage__PriceOverrideInput>;
  promotionID?: InputMaybe<Scalars['ID']>;
  protectionID?: InputMaybe<Scalars['ID']>;
  rental: SelfStorage__RentalInput;
  reservationID?: InputMaybe<Scalars['ID']>;
  sourceID?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Stripe__TokenInput>;
  unitID: Scalars['ID'];
};

/** Autogenerated return type of SelfStorage__Rent */
export type SelfStorage__RentPayload = {
  __typename?: 'SelfStorage__RentPayload';
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type SelfStorage__Rental = {
  __typename?: 'SelfStorage__Rental';
  activatedAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  fees: Array<SelfStorage__Fee>;
  id: Scalars['ID'];
  name: Scalars['String'];
  overlocked: Scalars['Boolean'];
  promotion?: Maybe<SelfStorage__Promotion>;
  protection?: Maybe<SelfStorage__Protection>;
  rate: Scalars['Float'];
  rateAdjustmentSchedulings: Array<RateAdjustment__Scheduling>;
  signatures: Array<SelfStorage__Signature>;
  status: SelfStorage__Status;
  subscription: SelfStorage__Subscription;
  subscriptionItems: Array<SelfStorage__SubscriptionItem>;
  unit: SelfStorage__Unit;
  unitRate: Scalars['Float'];
};

export type SelfStorage__RentalFiltersInput = {
  accountID?: InputMaybe<Scalars['String']>;
  facilityIDs?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SelfStorage__Status>;
};

export type SelfStorage__RentalInput = {
  gateCode: Scalars['String'];
  identification: SelfStorage__IdentificationInput;
  policy?: InputMaybe<SelfStorage__PolicyInput>;
  signatureIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type SelfStorage__RentalNotesInput = {
  waiveAdminFeeReason?: InputMaybe<Scalars['String']>;
};

export type SelfStorage__RentalPolicy = {
  __typename?: 'SelfStorage__RentalPolicy';
  modify: Scalars['Boolean'];
  new: Scalars['Boolean'];
};

export enum SelfStorage__Rental__BillingEnum {
  ChargeAutomatically = 'CHARGE_AUTOMATICALLY',
  SendInvoice = 'SEND_INVOICE',
}

/** Autogenerated return type of SelfStorage__Rental__Cancel */
export type SelfStorage__Rental__CancelPayload = {
  __typename?: 'SelfStorage__Rental__CancelPayload';
  error?: Maybe<Scalars['String']>;
  rental?: Maybe<SelfStorage__Rental>;
  status: Status;
};

/** Autogenerated return type of SelfStorage__Rental__Overlock */
export type SelfStorage__Rental__OverlockPayload = {
  __typename?: 'SelfStorage__Rental__OverlockPayload';
  rental: SelfStorage__Rental;
};

export type SelfStorage__Reservation = {
  __typename?: 'SelfStorage__Reservation';
  account?: Maybe<Account>;
  classification?: Maybe<SelfStorage__Classification>;
  confirmationAttemptDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  externalConfirmationNumber?: Maybe<Scalars['String']>;
  facility: SelfStorage__Facility;
  id: Scalars['ID'];
  lead: Lead;
  length?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  order?: Maybe<Order>;
  phone: Scalars['String'];
  price?: Maybe<SelfStorage__Price>;
  promotion?: Maybe<SelfStorage__Promotion>;
  quotedPrice: Scalars['Float'];
  reservedAt?: Maybe<Scalars['DateTime']>;
  reserver?: Maybe<User>;
  shuttleBookingUrl?: Maybe<ShortenedUrl>;
  smsSubscribed: Scalars['Boolean'];
  state: SelfStorage__Reservation__State;
  tags: Array<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  width?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['String']>;
};

export type SelfStorage__ReservationPolicy = {
  __typename?: 'SelfStorage__ReservationPolicy';
  modify: Scalars['Boolean'];
  new: Scalars['Boolean'];
};

export type SelfStorage__Reservation__BuildInput = {
  classificationID: Scalars['ID'];
  confirmationNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  email?: InputMaybe<Scalars['String']>;
  facilityID: Scalars['ID'];
  marketingData?: InputMaybe<MarketingDataInput>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  priceID: Scalars['ID'];
  promotionID?: InputMaybe<Scalars['ID']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SelfStorage__Reservation__Build */
export type SelfStorage__Reservation__BuildPayload = {
  __typename?: 'SelfStorage__Reservation__BuildPayload';
  error?: Maybe<Scalars['String']>;
  reservation?: Maybe<SelfStorage__Reservation>;
  status: Status;
};

/** Autogenerated return type of SelfStorage__Reservation__Cancel */
export type SelfStorage__Reservation__CancelPayload = {
  __typename?: 'SelfStorage__Reservation__CancelPayload';
  reservation?: Maybe<SelfStorage__Reservation>;
};

export type SelfStorage__Reservation__FiltersInput = {
  company?: InputMaybe<SelfStorage__Partner__Company>;
  facilityIDs?: InputMaybe<Array<Scalars['ID']>>;
  from?: InputMaybe<Scalars['Date']>;
  leadID?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  till?: InputMaybe<Scalars['Date']>;
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type SelfStorage__Reservation__ModifyInput = {
  classificationID?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  priceID?: InputMaybe<Scalars['ID']>;
  promotionID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of SelfStorage__Reservation__Modify */
export type SelfStorage__Reservation__ModifyPayload = {
  __typename?: 'SelfStorage__Reservation__ModifyPayload';
  error?: Maybe<Scalars['String']>;
  reservation?: Maybe<SelfStorage__Reservation>;
  status: Status;
};

export enum SelfStorage__Reservation__State {
  Canceled = 'CANCELED',
  Lost = 'LOST',
  Pending = 'PENDING',
  Won = 'WON',
}

export type SelfStorage__Reservation__ThirdPartyModifyInput = {
  confirmationAttemptDate?: InputMaybe<Scalars['Date']>;
  externalConfirmationNumber?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  /** confirmation screenshot from partner site */
  signedID?: InputMaybe<Scalars['ID']>;
  state: SelfStorage__Reservation__State;
};

/** Autogenerated return type of SelfStorage__Reservation__ThirdPartyModify */
export type SelfStorage__Reservation__ThirdPartyModifyPayload = {
  __typename?: 'SelfStorage__Reservation__ThirdPartyModifyPayload';
  error?: Maybe<Scalars['String']>;
  reservation?: Maybe<SelfStorage__Reservation>;
  status: Status;
};

export type SelfStorage__SavePromotionBatchInput = {
  IDs: Array<SelfStorage__ClassificationFacilityInput>;
  /** The number of months until the promotions takes effect. */
  delay?: InputMaybe<Scalars['Int']>;
  kind: SelfStorage__Promotion__Kind;
  /** The number of months to discount a subscription for. */
  term: Scalars['Int'];
  /** The percent or amount applied (i.e. 20% or $30). */
  value: Scalars['Float'];
};

/** Autogenerated return type of SelfStorage__SavePromotionBatch */
export type SelfStorage__SavePromotionBatchPayload = {
  __typename?: 'SelfStorage__SavePromotionBatchPayload';
  status: Status;
};

export type SelfStorage__Shuttle = {
  __typename?: 'SelfStorage__Shuttle';
  id: Scalars['ID'];
  reservation: SelfStorage__Reservation;
};

export type SelfStorage__Signature = {
  __typename?: 'SelfStorage__Signature';
  contract?: Maybe<ActiveStorageAttachment>;
  document: SelfStorage__Document;
  id: Scalars['ID'];
};

export enum SelfStorage__Status {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Canceling = 'CANCELING',
  Pending = 'PENDING',
}

export type SelfStorage__Subscription = {
  __typename?: 'SelfStorage__Subscription';
  account: Account;
  activatedAt?: Maybe<Scalars['DateTime']>;
  billing: SelfStorage__Rental__BillingEnum;
  billsOn?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  currentFacilityFees: Array<SelfStorage__Fee>;
  defaultSource?: Maybe<Billing__Source>;
  id: Scalars['ID'];
  rentals: Array<SelfStorage__Rental>;
};

export type SelfStorage__SubscriptionCurrentFacilityFeesArgs = {
  facilityID: Scalars['ID'];
};

export type SelfStorage__SubscriptionItem = {
  __typename?: 'SelfStorage__SubscriptionItem';
  activatedAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  rate: Scalars['Float'];
  status: SelfStorage__Status;
  summary: Scalars['String'];
};

export type SelfStorage__Unit = {
  __typename?: 'SelfStorage__Unit';
  classification: SelfStorage__Classification;
  createdAt: Scalars['DateTime'];
  designations: Array<Scalars['String']>;
  facility: SelfStorage__Facility;
  floor?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<SelfStorage__Price>;
  promotion?: Maybe<SelfStorage__Promotion>;
  rentable: Scalars['Boolean'];
  rentals?: Maybe<Array<SelfStorage__Rental>>;
  status: SelfStorage__Unit__Status;
  updatedAt: Scalars['DateTime'];
};

export type SelfStorage__UnitGroup = {
  __typename?: 'SelfStorage__UnitGroup';
  classification: SelfStorage__Classification;
  facility: SelfStorage__Facility;
  id: Scalars['ID'];
  price: SelfStorage__Price;
  promotion?: Maybe<SelfStorage__Promotion>;
  remaining: Scalars['Int'];
};

export type SelfStorage__UnitInput = {
  classification: SelfStorage__ClassificationInput;
  floor: Scalars['Int'];
  name: Scalars['String'];
  price?: InputMaybe<SelfStorage__PriceInput>;
  rentable: Scalars['Boolean'];
};

export type SelfStorage__Unit__FiltersInput = {
  classification?: InputMaybe<SelfStorage__Unit__Filters__ClassificationInput>;
  classificationIDs?: InputMaybe<Array<Scalars['ID']>>;
  facilityIDs?: InputMaybe<Array<Scalars['ID']>>;
  floors?: InputMaybe<Array<Scalars['Int']>>;
  price?: InputMaybe<SelfStorage__Unit__Filters__RangeInput>;
  reservable?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<SelfStorage__Unit__Status>>;
};

export type SelfStorage__Unit__Filters__ClassificationInput = {
  climate?: InputMaybe<Scalars['Boolean']>;
  cuft?: InputMaybe<SelfStorage__Unit__Filters__RangeInput>;
  height?: InputMaybe<Scalars['Float']>;
  indoors?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<SelfStorage__Unit__Filters__KindInput>;
  length?: InputMaybe<Scalars['Float']>;
  obstructed?: InputMaybe<Scalars['Boolean']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type SelfStorage__Unit__Filters__KindInput = {
  name?: InputMaybe<Array<Scalars['String']>>;
};

export type SelfStorage__Unit__Filters__RangeInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of SelfStorage__Unit__Modify */
export type SelfStorage__Unit__ModifyPayload = {
  __typename?: 'SelfStorage__Unit__ModifyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  unit?: Maybe<SelfStorage__Unit>;
};

export type SelfStorage__Unit__OrderingAttribute = {
  attribute: SelfStorage__Unit__OrderingAttributeEnum;
  direction: OrderingDirectionEnum;
};

export enum SelfStorage__Unit__OrderingAttributeEnum {
  Cuft = 'CUFT',
  Floor = 'FLOOR',
}

export enum SelfStorage__Unit__Status {
  Available = 'AVAILABLE',
  Designated = 'DESIGNATED',
  Held = 'HELD',
  Rented = 'RENTED',
  Unavailable = 'UNAVAILABLE',
}

export type SelfStorage__UploadInput = {
  kind: SelfStorage__Upload__Kind;
  spreadsheetID: Scalars['ID'];
};

export enum SelfStorage__Upload__Kind {
  Pricing = 'PRICING',
}

/** Autogenerated return type of SelfStorage__Upload__Save */
export type SelfStorage__Upload__SavePayload = {
  __typename?: 'SelfStorage__Upload__SavePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type SendGrid__Suppression__Block = {
  __typename?: 'SendGrid__Suppression__Block';
  created: Scalars['DateTime'];
  reason: Scalars['String'];
};

export type SendGrid__Suppression__Bounce = {
  __typename?: 'SendGrid__Suppression__Bounce';
  created: Scalars['DateTime'];
  reason: Scalars['String'];
};

/** Autogenerated return type of Send */
export type SendPayload = {
  __typename?: 'SendPayload';
  status: Status;
};

export type SendgridEvent = {
  __typename?: 'SendgridEvent';
  event: Scalars['String'];
  id: Scalars['ID'];
  sender: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type SentIterableEmail = {
  __typename?: 'SentIterableEmail';
  html: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  dicentralAccountID?: Maybe<Scalars['String']>;
  emailReceipts: Scalars['Boolean'];
  internal: Scalars['Boolean'];
  isBusiness: Scalars['Boolean'];
  isThirdPartyLogistics: Scalars['Boolean'];
  skipIncomingIvr: Scalars['Boolean'];
  skuTeachInOptOut: Scalars['Boolean'];
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier: ShipmentCarrierEnum;
  contents?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  itemIDs: Array<Scalars['ID']>;
  items: Array<Item>;
  labelURL?: Maybe<Scalars['String']>;
  length: Scalars['Float'];
  purchased: Scalars['Boolean'];
  rate?: Maybe<Scalars['Float']>;
  service?: Maybe<Scalars['String']>;
  status: ShipmentStatusEnum;
  trackingCode?: Maybe<Scalars['String']>;
  trackingURL?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
  width: Scalars['Float'];
  zplURL?: Maybe<Scalars['String']>;
};

export enum ShipmentCarrierEnum {
  Fedex = 'FEDEX',
  Ontrac = 'ONTRAC',
  Ups = 'UPS',
  Usps = 'USPS',
}

export type ShipmentInput = {
  carrier: Scalars['String'];
  contents?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  easyPostRateId: Scalars['String'];
  easyPostShipmentId: Scalars['String'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  rate: Scalars['Float'];
  service: Scalars['String'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export enum ShipmentStatusEnum {
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  Failure = 'FAILURE',
  InTransit = 'IN_TRANSIT',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PreTransit = 'PRE_TRANSIT',
  ReturnToSender = 'RETURN_TO_SENDER',
  Unknown = 'UNKNOWN',
}

export type ShortenedUrl = {
  __typename?: 'ShortenedURL';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Signature = {
  __typename?: 'Signature';
  agreement?: Maybe<ActiveStorageAttachment>;
  contractUrl?: Maybe<Scalars['String']>;
  documentKind: Scalars['String'];
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  skipped: Scalars['Boolean'];
  state: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

/** Autogenerated return type of SignatureApprovalCreate */
export type SignatureApprovalCreatePayload = {
  __typename?: 'SignatureApprovalCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type SignatureRequest = {
  __typename?: 'SignatureRequest';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: SignatureRequestKindEnum;
  signedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum SignatureRequestKindEnum {
  InAdvance = 'IN_ADVANCE',
  PostJob = 'POST_JOB',
  PreJob = 'PRE_JOB',
}

export type SimulationError = {
  __typename?: 'SimulationError';
  message: Scalars['String'];
  severe: Scalars['Boolean'];
};

export type SizingMetric = {
  __typename?: 'SizingMetric';
  enumerations: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  range?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SkipOpsAudit */
export type SkipOpsAuditPayload = {
  __typename?: 'SkipOpsAuditPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type SourcePolicy = {
  __typename?: 'SourcePolicy';
  delete: Scalars['Boolean'];
};

export enum Status {
  Ok = 'OK',
  Unauthorized = 'UNAUTHORIZED',
  Unprocessable = 'UNPROCESSABLE',
}

export type StorageTerm = {
  __typename?: 'StorageTerm';
  id: Scalars['ID'];
  minimumMonths: Scalars['Int'];
  name: Scalars['String'];
  rateGroup: RateGroup;
};

export type StorageTreasuresMutationInterface = {
  storageTreasuresAuctionCancel: StorageTreasures__AuctionCancelPayload;
};

export type StorageTreasuresMutationInterfaceStorageTreasuresAuctionCancelArgs = {
  reason: Scalars['String'];
  tealAuctionID: Scalars['ID'];
};

export type StorageTreasuresQueryInterface = {
  storageTreasuresAuctionPolicy: StorageTreasures__AuctionPolicyType;
  storageTreasuresAuctionsPaginated: StorageTreasures__PaginatedAuctionType;
};

export type StorageTreasuresQueryInterfaceStorageTreasuresAuctionsPaginatedArgs = {
  page?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of StorageTreasures__AuctionCancel */
export type StorageTreasures__AuctionCancelPayload = {
  __typename?: 'StorageTreasures__AuctionCancelPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type StorageTreasures__AuctionInvoiceType = {
  __typename?: 'StorageTreasures__AuctionInvoiceType';
  clutterAmount: Scalars['Float'];
  id: Scalars['ID'];
};

export type StorageTreasures__AuctionPolicyType = {
  __typename?: 'StorageTreasures__AuctionPolicyType';
  cancel: Scalars['Boolean'];
  view: Scalars['Boolean'];
};

export enum StorageTreasures__AuctionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Sold = 'SOLD',
  Unsold = 'UNSOLD',
}

export type StorageTreasures__AuctionType = {
  __typename?: 'StorageTreasures__AuctionType';
  auctionedAccount: Account;
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
  status: StorageTreasures__AuctionStatus;
  storageTreasuresID: Scalars['ID'];
  storageTreasuresLink: Scalars['String'];
  winner?: Maybe<Account>;
  winnerInvoice?: Maybe<StorageTreasures__AuctionInvoiceType>;
  /** The order scheduled by the auction winner to pick up the items. */
  winnerOrder?: Maybe<Order>;
};

export type StorageTreasures__PaginatedAuctionType = {
  __typename?: 'StorageTreasures__PaginatedAuctionType';
  pagination: Pagination;
  results: Array<StorageTreasures__AuctionType>;
};

export type Stripe__TokenInput = {
  /** A unique identifier that can be parsed into a source. */
  id: Scalars['ID'];
  /** Something like "account", "card", "pii", etc. */
  type: Scalars['String'];
};

export type Subscribe = {
  __typename?: 'Subscribe';
  adjustments: Array<Adjustment>;
  id: Scalars['ID'];
  order: Order;
  user: User;
};

export type Subscription = {
  __typename?: 'Subscription';
  custom: Scalars['Boolean'];
  customDimension?: Maybe<CustomDimension>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pricing: Pricing;
  quantity: Scalars['Int'];
  state: Scalars['String'];
  stripeID?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
};

export type SubscriptionInput = {
  customDimension?: InputMaybe<CustomDimensionInput>;
  planID: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type TaskOrder__Task = {
  __typename?: 'TaskOrder__Task';
  address: Address;
  contact?: Maybe<Order__Contact>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  sequenceNumber: Scalars['Int'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Ticket = {
  __typename?: 'Ticket';
  assignee?: Maybe<User>;
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  dueDate: Scalars['DateTime'];
  group?: Maybe<Ticket__Group>;
  id: Scalars['ID'];
  latestNote?: Maybe<Note>;
  messages: Array<Ticket__Message>;
  notes: Array<Note>;
  owner: User;
  read: Scalars['Boolean'];
  resolutionType?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  ticketableID: Scalars['ID'];
  ticketableName: Scalars['String'];
  ticketableType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TicketAttachmentInput = {
  url: Scalars['String'];
};

export type TicketFiltersInput = {
  assigneeID?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['String']>;
  claimed?: InputMaybe<Scalars['String']>;
  groupID?: InputMaybe<Scalars['ID']>;
  mode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<Scalars['String']>;
};

export type TicketMutationInterface = {
  markMessagesRead: MarkMessagesReadPayload;
  messageCreate: MessageCreatePayload;
  messageUpdate: MessageUpdatePayload;
};

export type TicketMutationInterfaceMarkMessagesReadArgs = {
  ids: Array<Scalars['ID']>;
};

export type TicketMutationInterfaceMessageCreateArgs = {
  input: Ticket__MessageInput;
  sendPushNotification: Scalars['Boolean'];
};

export type TicketMutationInterfaceMessageUpdateArgs = {
  id: Scalars['ID'];
  input: Ticket__MessageInput;
};

export type Ticket__Group = {
  __typename?: 'Ticket__Group';
  customerTicketAssignable: Scalars['Boolean'];
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  internalTicketAssignable: Scalars['Boolean'];
  name: Scalars['String'];
  users: Array<User>;
};

export type Ticket__Message = {
  __typename?: 'Ticket__Message';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  files: Array<ActiveStorageAttachment>;
  id: Scalars['ID'];
  readAt?: Maybe<Scalars['DateTime']>;
  ticket: Ticket;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type Ticket__MessageInput = {
  body?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  files?: InputMaybe<Array<Scalars['ID']>>;
  readAt?: InputMaybe<Scalars['DateTime']>;
  ticketId?: InputMaybe<Scalars['ID']>;
};

export type Ticket__Template = {
  __typename?: 'Ticket__Template';
  category: Scalars['String'];
  expectedResponseTime: Scalars['Duration'];
  group: Ticket__Group;
  id: Scalars['ID'];
  maximumPhotoCount: Scalars['Int'];
  minimumPhotoCount: Scalars['Int'];
  subCategory?: Maybe<Scalars['String']>;
  ticketableType: Ticket__TicketableEnum;
};

export enum Ticket__TicketableEnum {
  Account = 'ACCOUNT',
  Claim = 'CLAIM',
  CustomerTicket = 'CUSTOMER_TICKET',
  Dispatch = 'DISPATCH',
  Lead = 'LEAD',
  Order = 'ORDER',
  SelfStorageUnit = 'SELF_STORAGE_UNIT',
  User = 'USER',
  Warehouse = 'WAREHOUSE',
}

export type Ticket__TicketableInput = {
  associatedLeadUserID?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  type: Ticket__TicketableEnum;
};

export type TicketsQueryInterface = {
  paginatedTicketList: PaginatedTicket;
};

export type TicketsQueryInterfacePaginatedTicketListArgs = {
  filters?: InputMaybe<TicketFiltersInput>;
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export enum TimeClockPlusEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type TodayApp = {
  __typename?: 'TodayApp';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type TouchTaxon = {
  __typename?: 'TouchTaxon';
  childrenCount: Scalars['Int'];
  id: Scalars['ID'];
  multiselect?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentID: Scalars['ID'];
  parentName?: Maybe<Scalars['String']>;
};

export type TrainingUpload = {
  __typename?: 'TrainingUpload';
  attestations: TrainingUploadAttestation;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  teams: Array<Team>;
  uploader: User;
  url: Scalars['String'];
};

export type TrainingUploadAttestation = {
  __typename?: 'TrainingUploadAttestation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  trainingUpload: TrainingUpload;
  user: User;
};

export type TrainingUploadAttestationPaginated = {
  __typename?: 'TrainingUploadAttestationPaginated';
  pagination: Pagination;
  results: Array<TrainingUploadAttestation>;
};

export type TrainingUploadPaginated = {
  __typename?: 'TrainingUploadPaginated';
  pagination: Pagination;
  results: Array<TrainingUpload>;
};

/** Autogenerated return type of TrainingUpload__AttestationCreate */
export type TrainingUpload__AttestationCreatePayload = {
  __typename?: 'TrainingUpload__AttestationCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type TrainingUpload__AttestationCreate__Input = {
  trainingUploadID: Scalars['ID'];
};

/** Autogenerated return type of TrainingUpload__Create */
export type TrainingUpload__CreatePayload = {
  __typename?: 'TrainingUpload__CreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type TrainingUpload__Create__Input = {
  fileSignedID: Scalars['ID'];
  name: Scalars['String'];
  teamIDs: Array<Scalars['ID']>;
};

/** Autogenerated return type of TrainingUpload__StatusUpdate */
export type TrainingUpload__StatusUpdatePayload = {
  __typename?: 'TrainingUpload__StatusUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type TrainingUpload__StatusUpdate__Input = {
  active: Scalars['Boolean'];
  trainingUploadID: Scalars['ID'];
};

export type Transfer = {
  __typename?: 'Transfer';
  destination: Warehouse;
  id: Scalars['ID'];
  items: Array<Item>;
  origin: Warehouse;
  /** @deprecated Vehicle will be used going forward */
  originCargoLocation?: Maybe<Location>;
  pallets: Array<Pallet>;
  scheduled: Scalars['DateTime'];
  sealCode?: Maybe<Scalars['String']>;
  state: TransferState;
  unsealCode?: Maybe<Scalars['String']>;
  user: User;
  vehicle?: Maybe<Vehicle>;
};

export enum TransferState {
  Approved = 'APPROVED',
  Arrived = 'ARRIVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Enroute = 'ENROUTE',
}

/** Autogenerated return type of UnpauseConfiguration */
export type UnpauseConfigurationPayload = {
  __typename?: 'UnpauseConfigurationPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Usage = {
  __typename?: 'Usage';
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  user: User;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  agentPriority: Scalars['Int'];
  allRolesEditableByManager: Scalars['Boolean'];
  email: Scalars['String'];
  externalGroup?: Maybe<User__ExternalGroup>;
  hasTimeClockPlus: Scalars['Boolean'];
  hrsJobTitle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  name: Scalars['String'];
  notificationPreference?: Maybe<UserNotificationPreferenceEnum>;
  ownedTeams: Array<Team>;
  personalEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  policy: User__Policy;
  promoCode: PromoCode;
  regions: Array<Region>;
  roles: Array<User__Role>;
  signInCount: Scalars['Int'];
  state: User__State;
  team?: Maybe<Team>;
  token: Scalars['String'];
  tz: Scalars['String'];
  workdayUserID?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UserActivate */
export type UserActivatePayload = {
  __typename?: 'UserActivatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserCreate */
export type UserCreatePayload = {
  __typename?: 'UserCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserDelete */
export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  user?: Maybe<User>;
};

export type UserFiltersInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  geo?: InputMaybe<ClutterGeoEnum>;
  jobTitle?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<User__Role>>;
  withSelfStorageReservation?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UserForceLeaveCallCenter */
export type UserForceLeaveCallCenterPayload = {
  __typename?: 'UserForceLeaveCallCenterPayload';
  user: User;
};

export type UserInput = {
  agentPriority?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  externalGroup?: InputMaybe<User__ExternalGroup>;
  hasTimeClockPlus?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationPreference?: InputMaybe<UserNotificationPreferenceEnum>;
  ownedTeamIDs?: InputMaybe<Array<Scalars['ID']>>;
  phone?: InputMaybe<Scalars['String']>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
  roles?: InputMaybe<Array<User__Role>>;
  teamID?: InputMaybe<Scalars['ID']>;
  tz: Scalars['String'];
  workdayUserID?: InputMaybe<Scalars['String']>;
};

export enum UserNotificationPreferenceEnum {
  PersonalEmail = 'PERSONAL_EMAIL',
  PersonalEmailAndPhone = 'PERSONAL_EMAIL_AND_PHONE',
  PersonalPhone = 'PERSONAL_PHONE',
  WorkEmailOnly = 'WORK_EMAIL_ONLY',
}

/** Autogenerated return type of UserUpdate */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  user?: Maybe<User>;
};

export enum User__ExternalGroup {
  BlueCrew = 'BLUE_CREW',
  FreshPrints = 'FRESH_PRINTS',
  IronMountain = 'IRON_MOUNTAIN',
  Teleperformance = 'TELEPERFORMANCE',
}

export type User__Policy = {
  __typename?: 'User__Policy';
  canEditReferralCode: Scalars['Boolean'];
  canForceLeaveCallCenter: Scalars['Boolean'];
};

export enum User__Role {
  Admin = 'ADMIN',
  Auditor = 'AUDITOR',
  AuditReviewer = 'AUDIT_REVIEWER',
  Billing = 'BILLING',
  BillingAdmin = 'BILLING_ADMIN',
  Care = 'CARE',
  Claims = 'CLAIMS',
  Collections = 'COLLECTIONS',
  DataCorrector = 'DATA_CORRECTOR',
  Dispatcher = 'DISPATCHER',
  Driver = 'DRIVER',
  EnterpriseManager = 'ENTERPRISE_MANAGER',
  L1Agent = 'L1_AGENT',
  L2Agent = 'L2_AGENT',
  Manager = 'MANAGER',
  Marketing = 'MARKETING',
  Mover = 'MOVER',
  NightDriver = 'NIGHT_DRIVER',
  OpsLeadership = 'OPS_LEADERSHIP',
  Retention = 'RETENTION',
  SelfStorageAssociate = 'SELF_STORAGE_ASSOCIATE',
  ShipmentPurchaser = 'SHIPMENT_PURCHASER',
  Teamlead = 'TEAMLEAD',
  Tester = 'TESTER',
  Timekeeper = 'TIMEKEEPER',
  Warehouse = 'WAREHOUSE',
}

export enum User__State {
  Alerting = 'ALERTING',
  Available = 'AVAILABLE',
  CoolDown = 'COOL_DOWN',
  Offline = 'OFFLINE',
  OnCall = 'ON_CALL',
}

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  failureReason?: Maybe<Scalars['String']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['ID'];
  itemsCount: Scalars['Int'];
  name: Scalars['String'];
  region: Region;
  serialCode: Scalars['String'];
  vehicleType: VehicleType;
  warehouse: Warehouse;
};

export type VehicleFiltersInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  fieldDispatchable?: InputMaybe<Scalars['Boolean']>;
  geo?: InputMaybe<ClutterGeoEnum>;
  regionIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  cuft: Scalars['Float'];
  fieldDispatchable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numSeats: Scalars['Int'];
  restrictedZipCodes: Array<Scalars['String']>;
  weightInPounds: Scalars['Int'];
};

/** Autogenerated return type of VehicleTypeCreate */
export type VehicleTypeCreatePayload = {
  __typename?: 'VehicleTypeCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type VehicleTypeInput = {
  cuft: Scalars['Int'];
  fieldDispatchable: Scalars['Boolean'];
  name: Scalars['String'];
  numSeats: Scalars['Int'];
  restrictedZipCodes: Array<Scalars['String']>;
  weightInPounds: Scalars['Int'];
};

/** Autogenerated return type of VehicleTypeUpdate */
export type VehicleTypeUpdatePayload = {
  __typename?: 'VehicleTypeUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Voicemail = {
  __typename?: 'Voicemail';
  id: Scalars['ID'];
  recording: Recording;
};

export type Wt__VisitorType = Abeet__CurrentMembership & {
  __typename?: 'WT__VisitorType';
  abeetCurrentMembership?: Maybe<Abeet__Membership>;
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};

export type Wt__VisitorTypeAbeetCurrentMembershipArgs = {
  familyName: Scalars['String'];
};

export type Walkthrough = {
  __typename?: 'Walkthrough';
  id: Scalars['ID'];
  images: Array<Image>;
  name?: Maybe<Scalars['String']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  abbreviation: Scalars['String'];
  accessHours: Array<Scalars['String']>;
  active: Scalars['Boolean'];
  address: Address;
  /** @deprecated This is always true. The field will be removed once Forklift is updated */
  allowsForkliftOutboundAisleSort: Scalars['Boolean'];
  /** @deprecated This is always true. The field will be removed once Forklift is updated */
  allowsForkliftOutboundCustomerSort: Scalars['Boolean'];
  capacity: Scalars['Int'];
  customerAccessHours?: Maybe<Array<CustomerAccessHours>>;
  customerAccessInstructions?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inboundDockItemsPerHour: Scalars['Int'];
  name: Scalars['String'];
  operatingScheduleTemplates: Array<OperatingScheduleTemplate>;
  outboundDockItemsPerHour: Scalars['Int'];
  regions: Array<Region>;
  storageTreasuresFacilityID?: Maybe<Scalars['ID']>;
  storageTreasuresLink?: Maybe<Scalars['String']>;
  thirdPartyLogistics: Scalars['Boolean'];
  tz: Scalars['String'];
};

export type WarehouseInput = {
  abbreviation: Scalars['String'];
  active: Scalars['Boolean'];
  address: NoDetailAddressInput;
  customerAccessHours: Array<CustomerAccessHoursInput>;
  customerAccessInstructions?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  inboundDockItemsPerHour: Scalars['Int'];
  name: Scalars['String'];
  outboundDockItemsPerHour: Scalars['Int'];
  regionIDs: Array<Scalars['ID']>;
  storageTreasuresFacilityID?: InputMaybe<Scalars['ID']>;
  thirdPartyLogistics: Scalars['Boolean'];
  tz?: InputMaybe<Scalars['String']>;
};

export type WorkforceMutationInterface = {
  ptoRequestGroupReview: Workforce__PtoRequestGroupReviewTypePayload;
  shiftCreate: Workforce__ShiftCreatePayload;
  shiftDelete: Workforce__ShiftDeletePayload;
  shiftUpdate: Workforce__ShiftUpdatePayload;
};

export type WorkforceMutationInterfacePtoRequestGroupReviewArgs = {
  input: Workforce__PtoRequestGroupReview__Input;
};

export type WorkforceMutationInterfaceShiftCreateArgs = {
  input: Workforce__ShiftInput;
};

export type WorkforceMutationInterfaceShiftDeleteArgs = {
  id: Scalars['ID'];
};

export type WorkforceMutationInterfaceShiftUpdateArgs = {
  input: Workforce__ShiftInput;
};

export type WorkforceQueryInterface = {
  paginatedPtoRequestGroups: Pto__PaginatedRequestGroupType;
  ptoRequestGroup: Pto__RequestGroupType;
  workforceFacilities: Array<Workforce__Facility>;
  workforceJobCodes: Array<Workforce__JobCode>;
  workforceReviewableUsers: Array<User>;
  workforceShift: Workforce__Shift;
  workforceShiftAssignment?: Maybe<Workforce__ShiftAssignment>;
};

export type WorkforceQueryInterfacePaginatedPtoRequestGroupsArgs = {
  filters?: InputMaybe<Pto__RequestFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type WorkforceQueryInterfacePtoRequestGroupArgs = {
  id: Scalars['ID'];
};

export type WorkforceQueryInterfaceWorkforceShiftArgs = {
  id: Scalars['ID'];
};

export type WorkforceQueryInterfaceWorkforceShiftAssignmentArgs = {
  date: Scalars['Date'];
  userID: Scalars['ID'];
};

export type Workforce__Clockin = {
  __typename?: 'Workforce__Clockin';
  distance: Scalars['Float'];
  facility: Workforce__Facility;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type Workforce__ClockinInput = {
  facilityID: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  timestamp: Scalars['DateTime'];
};

export type Workforce__Facility = {
  __typename?: 'Workforce__Facility';
  abbreviation: Scalars['String'];
  /** The street address of the facility */
  address?: Maybe<Address>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Workforce__JobCode = {
  __typename?: 'Workforce__JobCode';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of Workforce__PTORequestGroupReviewType */
export type Workforce__PtoRequestGroupReviewTypePayload = {
  __typename?: 'Workforce__PTORequestGroupReviewTypePayload';
  requestGroup?: Maybe<Pto__RequestGroupType>;
};

export type Workforce__PtoRequestGroupReview__Input = {
  approve: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  requestGroupID: Scalars['ID'];
};

export type Workforce__Shift = {
  __typename?: 'Workforce__Shift';
  activities: Array<Workforce__Shift__Activity>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approver?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deleter?: Maybe<User>;
  disputedAt?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Int'];
  ended?: Maybe<Workforce__Clockin>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  overriddenAt?: Maybe<Scalars['DateTime']>;
  shiftAssignment?: Maybe<Workforce__ShiftAssignment>;
  started: Workforce__Clockin;
  status: Workforce__Shift__ViolationStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  violations: Array<Workforce__Shift__Violation>;
  workBreaks: Array<Workforce__WorkBreak>;
};

export type Workforce__ShiftAssignment = {
  __typename?: 'Workforce__ShiftAssignment';
  facility?: Maybe<Workforce__Facility>;
  id: Scalars['ID'];
  jobCode: Workforce__JobCode;
};

/** Autogenerated return type of Workforce__ShiftCreate */
export type Workforce__ShiftCreatePayload = {
  __typename?: 'Workforce__ShiftCreatePayload';
  error?: Maybe<Scalars['String']>;
  shift?: Maybe<Workforce__Shift>;
  status: Status;
};

/** Autogenerated return type of Workforce__ShiftDelete */
export type Workforce__ShiftDeletePayload = {
  __typename?: 'Workforce__ShiftDeletePayload';
  error?: Maybe<Scalars['String']>;
  shift?: Maybe<Workforce__Shift>;
  status: Status;
};

export type Workforce__ShiftInput = {
  activities: Array<Workforce__Shift__ActivityInput>;
  ended?: InputMaybe<Workforce__ClockinInput>;
  id?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  started: Workforce__ClockinInput;
  userID?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['ID']>;
  workBreaks: Array<Workforce__WorkBreakInput>;
};

/** Autogenerated return type of Workforce__ShiftUpdate */
export type Workforce__ShiftUpdatePayload = {
  __typename?: 'Workforce__ShiftUpdatePayload';
  error?: Maybe<Scalars['String']>;
  shift?: Maybe<Workforce__Shift>;
  status: Status;
};

export type Workforce__Shift__Activity = {
  __typename?: 'Workforce__Shift__Activity';
  durationMinutes: Scalars['Int'];
  id: Scalars['ID'];
  jobCode: Workforce__JobCode;
  position: Scalars['Int'];
};

export type Workforce__Shift__ActivityInput = {
  durationMinutes: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  jobCodeID: Scalars['ID'];
};

export type Workforce__Shift__Violation = {
  __typename?: 'Workforce__Shift__Violation';
  message: Scalars['String'];
  requiresAdjustment?: Maybe<Scalars['Boolean']>;
};

export enum Workforce__Shift__ViolationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Violations = 'VIOLATIONS',
}

export type Workforce__WorkBreak = {
  __typename?: 'Workforce__WorkBreak';
  ended?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  images: Array<Image>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  started?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['String'];
};

export type Workforce__WorkBreakInput = {
  ended?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  started?: InputMaybe<Scalars['DateTime']>;
  uuid?: InputMaybe<Scalars['ID']>;
};

export type ZendeskTicket = {
  __typename?: 'ZendeskTicket';
  comments: Array<ZendeskTicketComment>;
  id: Scalars['ID'];
  subject: Scalars['String'];
};

export type ZendeskTicketAssociation = {
  __typename?: 'ZendeskTicketAssociation';
  createdAt: Scalars['DateTime'];
  creator?: Maybe<User>;
  id: Scalars['ID'];
  zdTicketID: Scalars['ID'];
};

export type ZendeskTicketComment = {
  __typename?: 'ZendeskTicketComment';
  author?: Maybe<ZendeskUser>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type ZendeskUser = {
  __typename?: 'ZendeskUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of ZendeskUserBulkMerge */
export type ZendeskUserBulkMergePayload = {
  __typename?: 'ZendeskUserBulkMergePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type AccountPageButtonsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountPageButtonsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    onboardable: boolean;
    signInCount: number;
    state: Account__State;
    permissions: {
      __typename?: 'AccountPolicy';
      bookWarehouseReturn: boolean;
      bookMaintenancePickup: boolean;
      bookOrders: boolean;
      overrideBookOrders: boolean;
    };
  };
};

export type ActiveAuctionOrderQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type ActiveAuctionOrderQuery = {
  __typename?: 'Query';
  activeAuctionOrder?: { __typename?: 'Order'; id: string; scheduled: string } | null;
  account: {
    __typename?: 'Account';
    id: string;
    storageTreasuresAuctionsHeld: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      startTime: string;
      status: StorageTreasures__AuctionStatus;
    }>;
  };
};

export type AccountAddressEditQueryVariables = Exact<{
  accountID: Scalars['ID'];
  addressID: Scalars['ID'];
}>;

export type AccountAddressEditQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      neighborhood?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      business_name?: string | null;
      details?: {
        __typename?: 'Detail';
        floor?: number | null;
        stories?: number | null;
        stairs: boolean;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        code: boolean;
        environment?: EnvironmentEnum | null;
        building_type?: string | null;
        building_subtype?: string | null;
        front_door_steps?: number | null;
        square_feet?: number | null;
        service_elevator: boolean;
        service_entrance: boolean;
        loading_dock: boolean;
        code_value?: string | null;
        parking_street: boolean;
        parking_driveway: boolean;
        parking_lot: boolean;
        parking_alley: boolean;
        parking_permit: boolean;
        parking_in_front: boolean;
        parking_on_specific_street?: string | null;
        parking_instructions?: string | null;
        max_truck_height?: number | null;
        max_truck_height_unknown: boolean;
        access_unknown: boolean;
        paperwork_unknown: boolean;
        parking_unknown: boolean;
        additional_notes?: string | null;
        building_opening_hour?: number | null;
        building_closing_hour?: number | null;
        building_restrictions_answer?: Address__BuildingRestrictionsAnswerEnum | null;
        parking_latitude?: number | null;
        parking_longitude?: number | null;
        secure_location_details?: string | null;
        long_carry: boolean;
        unit_size?: Address__UnitSizeEnum | null;
      } | null;
    };
  };
};

export type AccountAddressNewQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountAddressNewQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
  };
};

export type AccountAddressShowQueryVariables = Exact<{
  accountID: Scalars['ID'];
  addressID: Scalars['ID'];
}>;

export type AccountAddressShowQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      neighborhood?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      business_name?: string | null;
      details?: {
        __typename?: 'Detail';
        floor?: number | null;
        stories?: number | null;
        stairs: boolean;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        code: boolean;
        environment?: EnvironmentEnum | null;
        building_type?: string | null;
        building_subtype?: string | null;
        front_door_steps?: number | null;
        square_feet?: number | null;
        service_elevator: boolean;
        service_entrance: boolean;
        loading_dock: boolean;
        code_value?: string | null;
        parking_street: boolean;
        parking_driveway: boolean;
        parking_lot: boolean;
        parking_alley: boolean;
        parking_permit: boolean;
        parking_in_front: boolean;
        parking_on_specific_street?: string | null;
        parking_instructions?: string | null;
        max_truck_height?: number | null;
        max_truck_height_unknown: boolean;
        access_unknown: boolean;
        paperwork_unknown: boolean;
        parking_unknown: boolean;
        additional_notes?: string | null;
        building_opening_hour?: number | null;
        building_closing_hour?: number | null;
        building_restrictions_answer?: Address__BuildingRestrictionsAnswerEnum | null;
        parking_latitude?: number | null;
        parking_longitude?: number | null;
        secure_location_details?: string | null;
        long_carry: boolean;
        unit_size?: Address__UnitSizeEnum | null;
      } | null;
      orders?: Array<{
        __typename?: 'Order';
        id: string;
        number: string;
        scheduled: string;
        state: OrderStateEnum;
        type: OrderTypeEnum;
        subtype: OrderSubtypeEnum;
        maintenance: boolean;
        region: { __typename?: 'Region'; id: string; tz: string };
      }> | null;
    };
  };
};

export type AccountAddressUpsertMutationVariables = Exact<{
  accountID: Scalars['ID'];
  input: AddressInput;
}>;

export type AccountAddressUpsertMutation = {
  __typename?: 'Mutation';
  accountAddressUpsert: {
    __typename?: 'Account__AddressUpsertPayload';
    status: Status;
    error?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      safeAndSanitary?: boolean | null;
    } | null;
  };
};

export type AccountBanQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountBanQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; id: string; bannedFromBooking: boolean; banReason?: string | null };
};

export type AccountBanUpdateMutationVariables = Exact<{
  accountID: Scalars['ID'];
  input: Account__BanUpdateInput;
}>;

export type AccountBanUpdateMutation = {
  __typename?: 'Mutation';
  ban?: {
    __typename?: 'Account__BanUpdatePayload';
    status: Status;
    error?: string | null;
    account?: { __typename?: 'Account'; id: string; bannedFromBooking: boolean; banReason?: string | null } | null;
  } | null;
};

export type CancelAccountMutationVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type CancelAccountMutation = {
  __typename?: 'Mutation';
  cancel?: { __typename?: 'Account__CancelPayload'; status: Status; error?: string | null } | null;
};

export type LaborSummaryQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type LaborSummaryQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    laborSummary: {
      __typename?: 'Account__LaborSummary';
      availableSubsequentAccountLaborCreditsCount: number;
      subsequentAccountLaborCreditsCount: number;
      hasOnboardingLaborCredit: boolean;
      hasFinalLaborCredit: boolean;
      laborBundleCustomer: boolean;
      laborRate: { __typename?: 'LaborRate'; id: string; name: string };
    };
  };
};

export type AccountReferralCodeQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountReferralCodeQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    referralCode: { __typename?: 'PromoCode'; id: string; value: string };
  };
  currentUser: { __typename?: 'User'; policy: { __typename?: 'User__Policy'; canEditReferralCode: boolean } };
};

export type AccountReferralCodeUpdateMutationVariables = Exact<{
  accountID: Scalars['ID'];
  code: Scalars['String'];
}>;

export type AccountReferralCodeUpdateMutation = {
  __typename?: 'Mutation';
  accountReferralCodeUpdate: {
    __typename?: 'Account__ReferralCodeUpdatePayload';
    status: Status;
    error?: { __typename?: 'Error'; code: ErrorCodeEnum; message: string } | null;
    referralCode?: { __typename?: 'PromoCode'; id: string; value: string } | null;
  };
};

export type AccountStorageLadderQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountStorageLadderQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    subscriptions: Array<{
      __typename?: 'Subscription';
      id: string;
      stripeID?: string | null;
      name: string;
      state: string;
      quantity: number;
      customDimension?: {
        __typename?: 'CustomDimension';
        id: string;
        cuft: number;
        length: number;
        width: number;
        height: number;
      } | null;
      pricing: {
        __typename?: 'Pricing';
        id: string;
        amount: number;
        currency: Currency;
        plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
      };
    }>;
    storageLadderPricings?: Array<{
      __typename?: 'Pricing';
      id: string;
      amount: number;
      currency: Currency;
      plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
    }> | null;
  };
};

export type SuspendAccountMutationVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type SuspendAccountMutation = {
  __typename?: 'Mutation';
  suspend?: { __typename?: 'Account__SuspendPayload'; status: Status; error?: string | null } | null;
};

export type TermCommitmentsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type TermCommitmentsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    termCommitments: Array<{
      __typename?: 'Account__TermCommitment';
      term: number;
      contractEndDate?: string | null;
      type: Account__TermCommitment__Type;
      fulfilled: boolean;
    }>;
    terminationAgreement?: {
      __typename?: 'Account__TerminationAgreement';
      contractDuration: number;
      earlyTerminationFee: number;
      contractEndDate?: string | null;
    } | null;
  };
};

export type CancelRateAdjustmentSchedulingMutationVariables = Exact<{
  id: Scalars['ID'];
  cancelReason: RateAdjustment__Scheduling__CancelReason;
}>;

export type CancelRateAdjustmentSchedulingMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'RateAdjustment__CancelSchedulingPayload';
    error?: string | null;
    status: Status;
    rateAdjustment?: {
      __typename?: 'RateAdjustment__Scheduling';
      id: string;
      state: RateAdjustment__Scheduling__State;
    } | null;
  };
};

export type AccountRateAgreementSummaryQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountRateAgreementSummaryQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    rateAdjustments: Array<{
      __typename?: 'RateAdjustment__Scheduling';
      id: string;
      effectiveAt: string;
      notified: boolean;
      newPrice: number;
      percentageWithSign: string;
      state: RateAdjustment__Scheduling__State;
      cancelReason?: RateAdjustment__Scheduling__CancelReason | null;
    }>;
  };
};

export type AccountRateAdjustmentSchedulingFragment = {
  __typename?: 'RateAdjustment__Scheduling';
  id: string;
  effectiveAt: string;
  notified: boolean;
  newPrice: number;
  percentageWithSign: string;
  state: RateAdjustment__Scheduling__State;
  cancelReason?: RateAdjustment__Scheduling__CancelReason | null;
};

export type AccountImpersonateCustomerLinkCreateMutationVariables = Exact<{
  customerID: Scalars['ID'];
}>;

export type AccountImpersonateCustomerLinkCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ImpersonateCustomerLinkCreatePayload'; url: string };
};

export type AddressPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type AddressPolicyQuery = {
  __typename?: 'Query';
  permissions: { __typename?: 'AddressPolicy'; create: boolean };
};

export type AccountAddressesQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountAddressesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    addresses: Array<{
      __typename?: 'Address';
      id: string;
      aptsuite?: string | null;
      neighborhood?: string | null;
      street: string;
      city: string;
      state: string;
      zip: string;
      businessName?: string | null;
      kinds: Array<string>;
    }>;
  };
};

export type AccountAttachmentsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountAttachmentsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    ingestedDocuments: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
  };
};

export type AccountClaimFragment = {
  __typename?: 'Claim';
  id: string;
  uuid: string;
  number: string;
  status: Claim__Status;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
  offeredAt?: string | null;
  expiredReason?: string | null;
  owner?: { __typename?: 'User'; id: string; name: string } | null;
  itemSelections: Array<{ __typename?: 'Claim__ItemSelection'; id: string; declaration?: number | null }>;
  propertySelection?: { __typename?: 'Claim__PropertySelection'; id: string; declaration?: number | null } | null;
};

export type ClaimsPanelQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type ClaimsPanelQuery = {
  __typename?: 'Query';
  accountClaims: Array<{
    __typename?: 'Claim';
    id: string;
    uuid: string;
    number: string;
    status: Claim__Status;
    hasIssue: boolean;
    hasLostItem: boolean;
    hasDamagedItem: boolean;
    hasDamagedProperty: boolean;
    openedAt?: string | null;
    closedAt?: string | null;
    offeredAt?: string | null;
    expiredReason?: string | null;
    owner?: { __typename?: 'User'; id: string; name: string } | null;
    itemSelections: Array<{ __typename?: 'Claim__ItemSelection'; id: string; declaration?: number | null }>;
    propertySelection?: { __typename?: 'Claim__PropertySelection'; id: string; declaration?: number | null } | null;
  }>;
  account: {
    __typename?: 'Account';
    id: string;
    claimsSummary: {
      __typename?: 'ClaimsSummary';
      totalItemCompensation: number;
      totalPropertyCompensation: number;
      paidItemCompensation: number;
      pendingItemCompensation: number;
      paidPropertyCompensation: number;
      pendingPropertyCompensation: number;
      latestProtectionPlan: { __typename?: 'ProtectionPlan'; slug: string; name: string; maxCovered: number };
    };
  };
};

export type ContainersAccountCreateMutationVariables = Exact<{
  customerInput: Containers__CustomerInput;
  zip: Scalars['String'];
}>;

export type ContainersAccountCreateMutation = {
  __typename?: 'Mutation';
  containersAccountCreate: {
    __typename?: 'Containers__AccountCreatePayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type PaginatedAccountsQueryVariables = Exact<{
  state?: InputMaybe<Account__State>;
  query?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedAccountsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedAccount';
    results: Array<{
      __typename?: 'Account';
      id: string;
      state: Account__State;
      number: string;
      customer: { __typename?: 'Customer'; id: string; name: string };
      customers: Array<{ __typename?: 'Customer'; id: string; name: string }>;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type AccountPaymentPlanOffersQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountPaymentPlanOffersQuery = {
  __typename?: 'Query';
  offers: Array<{
    __typename?: 'PaymentPlan__Offer';
    id: string;
    type: PaymentPlan__Offer__Type;
    state: PaymentPlan__Offer__State;
    createdAt: string;
    expiry: string;
    expired: boolean;
    user: { __typename?: 'User'; id: string; name: string };
  }>;
};

export type AccountWithPricingOptionsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountWithPricingOptionsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    priceMatchKind?: PriceMatch__Kind | null;
    coupon?: { __typename?: 'Coupon'; id: string; name: string; code: string } | null;
    currentStorageSubscription?: {
      __typename?: 'Subscription';
      id: string;
      total: number;
      customDimension?: {
        __typename?: 'CustomDimension';
        id: string;
        cuft: number;
        length: number;
        width: number;
        height: number;
      } | null;
      pricing: { __typename?: 'Pricing'; id: string; plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum } };
    } | null;
    currentProtectionSubscription?: {
      __typename?: 'Subscription';
      id: string;
      pricing: { __typename?: 'Pricing'; id: string };
    } | null;
    customer: { __typename?: 'Customer'; id: string; name: string };
    pricingOptions: {
      __typename?: 'Account__PricingOptions';
      id: string;
      storagePricings: Array<{
        __typename?: 'Pricing';
        id: string;
        amount: number;
        plan: { __typename?: 'Plan'; id: string; name: string; cuft: number; kind: PlanKindEnum };
      }>;
      protectionPricings: Array<{
        __typename?: 'Pricing';
        id: string;
        amount: number;
        plan: { __typename?: 'Plan'; id: string; name: string };
      }>;
    };
  };
};

export type UpdateAccountPricingMutationVariables = Exact<{
  accountID: Scalars['ID'];
  input: Account__UpdatePricingInput;
}>;

export type UpdateAccountPricingMutation = {
  __typename?: 'Mutation';
  update: {
    __typename?: 'Account__UpdatePricingPayload';
    status: Status;
    error?: { __typename?: 'Error'; message: string } | null;
  };
};

export type ChangeAccountSettingsMutationVariables = Exact<{
  input: AccountSettingsInput;
}>;

export type ChangeAccountSettingsMutation = {
  __typename?: 'Mutation';
  changeAccountSettings: { __typename?: 'ChangeAccountSettingsPayload'; status: Status; error?: string | null };
};

export type SettingsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type SettingsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
    settings: {
      __typename?: 'Settings';
      internal: boolean;
      isBusiness: boolean;
      isThirdPartyLogistics: boolean;
      skuTeachInOptOut: boolean;
      emailReceipts: boolean;
      dicentralAccountID?: string | null;
      skipIncomingIvr: boolean;
    };
  };
};

export type AddressesQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AddressesQuery = {
  __typename?: 'Query';
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    latitude?: number | null;
    longitude?: number | null;
    serviceable: boolean;
    schedulable: boolean;
    business_name?: string | null;
    safe_and_sanitary?: boolean | null;
    details?: {
      __typename?: 'Detail';
      floor?: number | null;
      stories?: number | null;
      stairs: boolean;
      elevator: boolean;
      paperwork: boolean;
      gated: boolean;
      steep: boolean;
      code: boolean;
      environment?: EnvironmentEnum | null;
      building_type?: string | null;
      building_subtype?: string | null;
      front_door_steps?: number | null;
      square_feet?: number | null;
      service_elevator: boolean;
      service_entrance: boolean;
      loading_dock: boolean;
      code_value?: string | null;
      parking_street: boolean;
      parking_driveway: boolean;
      parking_lot: boolean;
      parking_alley: boolean;
      parking_permit: boolean;
      parking_in_front: boolean;
      parking_on_specific_street?: string | null;
      parking_instructions?: string | null;
      max_truck_height?: number | null;
      max_truck_height_unknown: boolean;
      access_unknown: boolean;
      paperwork_unknown: boolean;
      parking_unknown: boolean;
      additional_notes?: string | null;
      building_opening_hour?: number | null;
      building_closing_hour?: number | null;
      building_restrictions_answer?: Address__BuildingRestrictionsAnswerEnum | null;
      parking_latitude?: number | null;
      parking_longitude?: number | null;
      secure_location_details?: string | null;
      long_carry: boolean;
      unit_size?: Address__UnitSizeEnum | null;
    } | null;
  }>;
};

export type GeocodeQueryVariables = Exact<{
  input: Geocode__Input;
}>;

export type GeocodeQuery = {
  __typename?: 'Query';
  geocode?: {
    __typename?: 'Geocode';
    latitude: number;
    longitude: number;
    potentially_invalid: boolean;
    region: { __typename?: 'Region'; id: string; tz: string };
  } | null;
};

export type CreateAppointmentQuoteMutationVariables = Exact<{
  zip: Scalars['String'];
  cuft: Scalars['Float'];
  rateGroupId: Scalars['ID'];
  quotableId: Scalars['ID'];
  quotableType: Pricing__QuotableEnum;
  source: Pricing__SourceEnum;
}>;

export type CreateAppointmentQuoteMutation = {
  __typename?: 'Mutation';
  createAppointmentQuote?: {
    __typename?: 'CreateAppointmentQuotePayload';
    status: Status;
    error?: string | null;
    quote?: { __typename?: 'AppointmentQuote'; id: string } | null;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
    onboardingPackageSetEntries: Array<{
      __typename?: 'PackageSetEntry';
      id: string;
      amount?: number | null;
      orderSubtype: string;
      package: { __typename?: 'Package'; id: string; name: string; kind: PackageKindEnum; fullPack: boolean };
    }>;
    finalPackageSetEntries: Array<{
      __typename?: 'PackageSetEntry';
      id: string;
      amount?: number | null;
      orderSubtype: string;
      package: { __typename?: 'Package'; id: string; name: string; kind: PackageKindEnum; fullPack: boolean };
    }>;
  } | null;
};

export type PricingSetAppointmentDataQueryVariables = Exact<{
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
  cuft: Scalars['Float'];
}>;

export type PricingSetAppointmentDataQuery = {
  __typename?: 'Query';
  pricingSetAppointmentData: {
    __typename?: 'PricingSetAppointmentData';
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
    packageSetEntries: Array<{
      __typename?: 'PackageSetEntry';
      id: string;
      amount?: number | null;
      orderSubtype: string;
      package: { __typename?: 'Package'; id: string; name: string; kind: PackageKindEnum; fullPack: boolean };
    }>;
  };
};

export type AttendancePolicyViolationCreateMutationVariables = Exact<{
  input: AttendancePolicyViolation__Create__Input;
}>;

export type AttendancePolicyViolationCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'AttendancePolicyViolation__CreatePayload'; status: Status; error?: string | null };
};

export type AttendancePolicyViolationsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Date']>;
  teamID?: InputMaybe<Scalars['ID']>;
  teamReviewMode?: InputMaybe<Scalars['Boolean']>;
  till?: InputMaybe<Scalars['Date']>;
  timeClockPlusUsersOnly?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
}>;

export type AttendancePolicyViolationsQuery = {
  __typename?: 'Query';
  attendancePolicyViolations: Array<{
    __typename?: 'AttendancePolicyViolation__Type';
    id: string;
    actualTime?: string | null;
    confirmedNoCall: boolean;
    expectedTime: string;
    kind: AttendancePolicyViolation__Kind;
    note?: string | null;
    reviewedAt?: string | null;
    shiftDate: string;
    waived: boolean;
    reviewer?: { __typename?: 'User'; id: string; name: string } | null;
    user: { __typename?: 'User'; id: string; name: string };
  }>;
  users: Array<{ __typename?: 'User'; id: string; name: string }>;
};

export type AttendancePolicyViolationReviewMutationVariables = Exact<{
  input: AttendancePolicyViolation__Review__Input;
}>;

export type AttendancePolicyViolationReviewMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'AttendancePolicyViolation__ReviewPayload'; status: Status; error?: string | null };
};

export type AuctionUploadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AuctionUploadQuery = {
  __typename?: 'Query';
  upload: {
    __typename?: 'Auction__Upload';
    id: string;
    description: string;
    state: Auction__Upload__State;
    error?: string | null;
    processingAt?: string | null;
    processedAt?: string | null;
    user: { __typename?: 'User'; id: string; name: string };
    originalCSV: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
    failureCSV?: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string } | null;
  };
};

export type AuctionUploadCreateMutationVariables = Exact<{
  input: Auction__UploadInput;
}>;

export type AuctionUploadCreateMutation = {
  __typename?: 'Mutation';
  create: {
    __typename?: 'Auction__Upload__CreatePayload';
    status: Status;
    upload?: {
      __typename?: 'Auction__Upload';
      id: string;
      description: string;
      state: Auction__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      user: { __typename?: 'User'; id: string; name: string };
      originalCSV: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
      failureCSV?: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string } | null;
    } | null;
  };
};

export type AuctionUploadListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AuctionUploadListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Auction__PaginatedUpload';
    results: Array<{
      __typename?: 'Auction__Upload';
      id: string;
      description: string;
      state: Auction__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      user: { __typename?: 'User'; id: string; name: string };
      originalCSV: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
      failureCSV?: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  permission: { __typename?: 'Auction__Upload__Policy'; view: boolean; create: boolean };
};

export type AuctionUploadPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type AuctionUploadPolicyQuery = {
  __typename?: 'Query';
  permission: { __typename?: 'Auction__Upload__Policy'; view: boolean; create: boolean };
};

export type AvailabilityLaborAdjustmentsCreateMutationVariables = Exact<{
  input: Availability__LaborAdjustmentsCreateInput;
}>;

export type AvailabilityLaborAdjustmentsCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__LaborAdjustmentsCreatePayload'; status: Status; error?: string | null };
};

export type AvailabilityLaborAdjustmentsDeleteMutationVariables = Exact<{
  IDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AvailabilityLaborAdjustmentsDeleteMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__LaborAdjustmentsDeletePayload'; status: Status; error?: string | null };
};

export type AvailabilityLaborAdjustmentsQueryVariables = Exact<{
  filters: Availability__LaborAdjustmentFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AvailabilityLaborAdjustmentsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Availability__PaginatedLaborAdjustment';
    results: Array<{
      __typename?: 'Availability__LaborAdjustment';
      id: string;
      date: string;
      lowThresholdPercentage: number;
      highThresholdPercentage: number;
      perMoverHourAdjustmentAmount: number;
      orderClassification: Availability__OrderClassificationEnum;
      creator: { __typename?: 'User'; id: string; name: string };
      region: { __typename?: 'Region'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  policy: { __typename?: 'Availability__ManagementPolicy'; create: boolean; destroy: boolean };
};

export type AvailabilityGlobalUnavailabilitiesCreateMutationVariables = Exact<{
  input: Availability__GlobalUnavailabilitiesCreateInput;
}>;

export type AvailabilityGlobalUnavailabilitiesCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__GlobalUnavailabilitiesCreatePayload'; status: Status; error?: string | null };
};

export type AvailabilityGlobalUnavailabilitiesDestroyMutationVariables = Exact<{
  IDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AvailabilityGlobalUnavailabilitiesDestroyMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__GlobalUnavailabilitiesDestroyPayload'; status: Status; error?: string | null };
};

export type AvailabilityGlobalUnavailabilityQueryVariables = Exact<{
  filters: Availability__GlobalUnavailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AvailabilityGlobalUnavailabilityQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Availability__PaginatedGlobalUnavailability';
    results: Array<{
      __typename?: 'Availability__GlobalUnavailability';
      id: string;
      date: string;
      name: string;
      reason: Availability__GlobalUnavailabilityReasonEnum;
      capacityReductionPercentage: number;
      region: { __typename?: 'Region'; id: string; name: string };
      creator: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  policy: { __typename?: 'Availability__ManagementPolicy'; create: boolean; destroy: boolean };
};

export type AvailabilityJobAvailabilitiesCreateMutationVariables = Exact<{
  input: Availability__JobAvailabilitiesCreateInput;
}>;

export type AvailabilityJobAvailabilitiesCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__JobAvailabilitiesCreatePayload'; status: Status; error?: string | null };
};

export type AvailabilityJobAvailabilitiesDestroyMutationVariables = Exact<{
  input: Availability__JobAvailabilitiesDestroyInput;
}>;

export type AvailabilityJobAvailabilitiesDestroyMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__JobAvailabilitiesDestroyPayload'; status: Status; error?: string | null };
};

export type AvailabilityJobAvailabilityQueryVariables = Exact<{
  filters: Availability__JobAvailabilityFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AvailabilityJobAvailabilityQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Availability__PaginatedJobAvailability';
    results: Array<{
      __typename?: 'Availability__JobTypeAvailability';
      id: string;
      date: string;
      maximumMoverMinutes: number;
      bookedMoverMinutes: number;
      displayKind: Availability__JobAvailabilityKindEnum;
      reason?: Availability__BlockReasonEnum | null;
      region: { __typename?: 'Region'; id: string; name: string };
      creator: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  policy: { __typename?: 'Availability__ManagementPolicy'; create: boolean; destroy: boolean };
};

export type AvailabilityOperatingScheduleTemplateCreateMutationVariables = Exact<{
  input: Availability__OperatingScheduleTemplateCreate__Input;
}>;

export type AvailabilityOperatingScheduleTemplateCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__OperatingScheduleTemplateCreatePayload'; status: Status };
};

export type AvailabilityRescheduleOffersCreateMutationVariables = Exact<{
  input: Availability__RescheduleOffersCreateInput;
}>;

export type AvailabilityRescheduleOffersCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__RescheduleOffersCreatePayload'; status: Status; error?: string | null };
};

export type AvailabilityRescheduleOffersDeleteMutationVariables = Exact<{
  IDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AvailabilityRescheduleOffersDeleteMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__RescheduleOffersDeletePayload'; status: Status; error?: string | null };
};

export type AvailabilityRescheduleOffersQueryVariables = Exact<{
  filters: Availability__RescheduleOfferFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AvailabilityRescheduleOffersQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Availability__PaginatedRescheduleOffer';
    results: Array<{
      __typename?: 'Availability__RescheduleOffer';
      id: string;
      originDate: string;
      targetDates: Array<string>;
      orderMinimumMoverMinutes: number;
      originTargetAvailabilityThreshold: number;
      orderClassification: Availability__OrderClassificationEnum;
      startAt: string;
      expireAt: string;
      expired: boolean;
      rescheduleOfferItems: Array<{
        __typename?: 'Availability__RescheduleOfferItem';
        id: string;
        coupon: { __typename?: 'Coupon'; id: string; name: string; amount: number; percent: number };
      }>;
      creator: { __typename?: 'User'; id: string; name: string };
      region: { __typename?: 'Region'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  policy: { __typename?: 'Availability__ManagementPolicy'; create: boolean; destroy: boolean };
};

export type AvailabilityFacilityCuftLimitsCreateMutationVariables = Exact<{
  input: Availability__FacilityCuftLimitsCreateInput;
}>;

export type AvailabilityFacilityCuftLimitsCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__FacilityCuftLimitsCreatePayload'; status: Status; error?: string | null };
};

export type AvailabilityFacilityCuftLimitsDestroyMutationVariables = Exact<{
  IDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AvailabilityFacilityCuftLimitsDestroyMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Availability__FacilityCuftLimitsDestroyPayload'; status: Status; error?: string | null };
};

export type AvailabilityFacilityCuftLimitsQueryVariables = Exact<{
  filters: Availability__FacilityCuftLimitFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AvailabilityFacilityCuftLimitsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Availability__PaginatedFacilityCuftLimit';
    results: Array<{
      __typename?: 'Availability__FacilityCuftLimit';
      id: string;
      date: string;
      maximumInboundCuft?: number | null;
      maximumInboundOutboundCuft?: number | null;
      maximumInboundTransferCuft?: number | null;
      maximumOutboundCuft?: number | null;
      maximumOutboundFacilityCuft?: number | null;
      maximumOutboundDisposalCuft?: number | null;
      reason?: Availability__BlockReasonEnum | null;
      facility: { __typename?: 'Warehouse'; id: string; name: string };
      creator: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  policy: { __typename?: 'Availability__ManagementPolicy'; create: boolean; destroy: boolean };
};

export type BillingAuthorizationHoldCancelMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type BillingAuthorizationHoldCancelMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Billing__AuthorizationHold__CancelPayload';
    status: Status;
    unattemptedAuthorizationHold?: {
      __typename?: 'Billing__UnattemptedAuthorizationHold';
      id: string;
      status: Billing__UnattemptedAuthorizationHoldStatus;
    } | null;
  };
};

export type BillingAuthorizationHoldsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type BillingAuthorizationHoldsQuery = {
  __typename?: 'Query';
  billingUnattemptedAuthorizationHold?: {
    __typename?: 'Billing__UnattemptedAuthorizationHold';
    id: string;
    amount: number;
    date: string;
    orderID: string;
    status: Billing__UnattemptedAuthorizationHoldStatus;
    source?: { __typename?: 'Billing__Source'; id: string; number: string } | null;
  } | null;
  billingAuthorizationHolds: Array<{
    __typename?: 'Billing__AuthorizationHold';
    id: string;
    charge: {
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      outcomeReason?: string | null;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; number: string };
    };
  }>;
};

export type BillingAuthorizationHoldUncancelMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type BillingAuthorizationHoldUncancelMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Billing__AuthorizationHold__UncancelPayload';
    status: Status;
    unattemptedAuthorizationHold?: {
      __typename?: 'Billing__UnattemptedAuthorizationHold';
      id: string;
      status: Billing__UnattemptedAuthorizationHoldStatus;
    } | null;
  };
};

export type BillingBuildChargeMutationVariables = Exact<{
  input: Billing__ChargeInput;
}>;

export type BillingBuildChargeMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Billing__BuildChargePayload'; status: Status; error?: string | null };
};

export type BillingInvoiceItemKindsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type BillingInvoiceItemKindsQuery = {
  __typename?: 'Query';
  billing: { __typename?: 'Billing__Context'; options: Array<string> };
};

export type BillingInvoiceForgiveMutationVariables = Exact<{
  invoiceID: Scalars['ID'];
  input: Billing__Invoice__ForgiveInput;
}>;

export type BillingInvoiceForgiveMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Billing__Invoice__ForgivePayload'; attemptID: string };
};

export type BillingForgivenessReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type BillingForgivenessReasonsQuery = {
  __typename?: 'Query';
  billingForgivenessReasons: Array<{
    __typename?: 'Billing__ForgivenessReason';
    id: string;
    kind: string;
    parentID?: string | null;
  }>;
};

export type BillingInvoiceMarkAsPaidMutationVariables = Exact<{
  invoiceID: Scalars['ID'];
  input: Billing__Invoice__MarkAsPaidInput;
}>;

export type BillingInvoiceMarkAsPaidMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'Billing__Invoice__MarkAsPaidPayload'; attemptID: string };
};

export type BillingInvoiceReBillMutationVariables = Exact<{
  invoiceID: Scalars['ID'];
  input: Billing__Invoice__ReBillInput;
}>;

export type BillingInvoiceReBillMutation = {
  __typename?: 'Mutation';
  rebill: { __typename?: 'Billing__Invoice__ReBillPayload'; attemptID: string };
};

export type BillingReBillReasonsQueryVariables = Exact<{
  invoiceID: Scalars['ID'];
}>;

export type BillingReBillReasonsQuery = {
  __typename?: 'Query';
  reasons: Array<{ __typename?: 'Billing__ReBillReason'; id: string; name: string }>;
};

export type BillingReceiptSendMutationVariables = Exact<{
  input: Billing__Receipt__SendInput;
}>;

export type BillingReceiptSendMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SendPayload'; status: Status };
};

export type BillingChargeRefundMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Billing__Charge__RefundInput;
}>;

export type BillingChargeRefundMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'RefundPayload'; status: Status; error?: string | null };
};

export type BillingSourceDefaultMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BillingSourceDefaultMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Billing__Source__DefaultPayload';
    source?: { __typename?: 'Billing__Source'; id: string; default: boolean } | null;
  };
};

export type BillingSourceDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BillingSourceDeleteMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Billing__Source__DeletePayload';
    source?: { __typename?: 'Billing__Source'; id: string; active: boolean } | null;
  };
};

export type BillingSourceBuildMutationVariables = Exact<{
  accountID: Scalars['ID'];
  token: Scalars['ID'];
}>;

export type BillingSourceBuildMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Billing__Source__BuildPayload';
    error?: string | null;
    account?: {
      __typename?: 'Account';
      id: string;
      sources: Array<{
        __typename?: 'Billing__Source';
        id: string;
        stripeID: string;
        kind: Billing__Source__Kind;
        fundingType: Billing__Source__FundingType;
        number: string;
        brand?: string | null;
        name?: string | null;
        expiration?: string | null;
        expired: boolean;
        default: boolean;
        active: boolean;
        billable: boolean;
        cvcCheck?: Billing__Source__CvcCheck | null;
        zipCheck?: Billing__Source__ZipCheck | null;
      }>;
    } | null;
    source?: {
      __typename?: 'Billing__Source';
      id: string;
      stripeID: string;
      kind: Billing__Source__Kind;
      fundingType: Billing__Source__FundingType;
      number: string;
      brand?: string | null;
      name?: string | null;
      expiration?: string | null;
      expired: boolean;
      default: boolean;
      active: boolean;
      billable: boolean;
      cvcCheck?: Billing__Source__CvcCheck | null;
      zipCheck?: Billing__Source__ZipCheck | null;
    } | null;
  };
};

export type AccountSourcesQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountSourcesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    sources: Array<{
      __typename?: 'Billing__Source';
      id: string;
      stripeID: string;
      kind: Billing__Source__Kind;
      fundingType: Billing__Source__FundingType;
      number: string;
      brand?: string | null;
      name?: string | null;
      expiration?: string | null;
      expired: boolean;
      default: boolean;
      active: boolean;
      billable: boolean;
      cvcCheck?: Billing__Source__CvcCheck | null;
      zipCheck?: Billing__Source__ZipCheck | null;
    }>;
  };
  permissions: { __typename?: 'SourcePolicy'; delete: boolean };
};

export type ItemCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ItemCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Admin__ItemCategory';
    id: string;
    name: string;
    parentID?: string | null;
    moveAndPackVisible: boolean;
    sizingMetric?: {
      __typename?: 'SizingMetric';
      id: string;
      name: string;
      enumerations: Array<string>;
      range?: string | null;
      unit?: string | null;
    } | null;
  }>;
};

export type MaterialCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type MaterialCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'MaterialCategory'; id: string; name: string }>;
};

export type PartCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type PartCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'PartCategory'; id: string; name: string }>;
};

export type CurrentMembershipFragment = {
  __typename?: 'Abeet__Membership';
  createdAt: string;
  variant?: { __typename?: 'Abeet__Variant'; variables?: any | null } | null;
};

export type WebOnlyIncentiveEnrollmentQueryVariables = Exact<{
  leadID: Scalars['ID'];
  familyName: Scalars['String'];
}>;

export type WebOnlyIncentiveEnrollmentQuery = {
  __typename?: 'Query';
  lead: {
    __typename?: 'Lead';
    visitors: Array<{
      __typename?: 'WT__VisitorType';
      abeetCurrentMembership?: {
        __typename?: 'Abeet__Membership';
        createdAt: string;
        variant?: { __typename?: 'Abeet__Variant'; variables?: any | null } | null;
      } | null;
    }>;
  };
};

export type ModifyClaimAssessmentsMutationVariables = Exact<{
  input: Claims__ModifyAssessments__Input;
}>;

export type ModifyClaimAssessmentsMutation = {
  __typename?: 'Mutation';
  modifyClaimAssessments: {
    __typename?: 'Claims__ModifyAssessmentsPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      owner?: { __typename?: 'User'; id: string; name: string } | null;
      issueSelection?: {
        __typename?: 'Claim__IssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: Claim__IssueSelection__Kind | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'Claim__ItemSelection';
        id: string;
        kind: Claim__ItemSelectionKind;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        receipts: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        item?: {
          __typename?: 'Item';
          id: string;
          name?: string | null;
          barcode: { __typename?: 'Barcode'; value: string };
        } | null;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'Claim__PropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: Claim__PropertySelectionOwnership | null;
        owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        quotes: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      } | null;
      account: {
        __typename?: 'Account';
        id: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
        latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
      };
      offers: Array<{
        __typename?: 'Claim__Offer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
      settlementAgreement?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      } | null;
    } | null;
  };
};

export type ClaimQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClaimQuery = {
  __typename?: 'Query';
  claim: {
    __typename?: 'Claim';
    id: string;
    uuid: string;
    number: string;
    status: Claim__Status;
    hasIssue: boolean;
    hasLostItem: boolean;
    hasDamagedItem: boolean;
    hasDamagedProperty: boolean;
    openedAt?: string | null;
    closedAt?: string | null;
    offeredAt?: string | null;
    expiredReason?: string | null;
    owner?: { __typename?: 'User'; id: string; name: string } | null;
    issueSelection?: {
      __typename?: 'Claim__IssueSelection';
      id: string;
      compensationRequested?: boolean | null;
      declaration?: number | null;
      description?: string | null;
      kind?: Claim__IssueSelection__Kind | null;
      photos: Array<{
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      }>;
      resolution?: {
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      offeredResolutions: Array<{
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      }>;
    } | null;
    itemSelections: Array<{
      __typename?: 'Claim__ItemSelection';
      id: string;
      kind: Claim__ItemSelectionKind;
      name?: string | null;
      declaration?: number | null;
      productURL?: string | null;
      photos: Array<{
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      }>;
      receipts: Array<{
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      }>;
      item?: {
        __typename?: 'Item';
        id: string;
        name?: string | null;
        barcode: { __typename?: 'Barcode'; value: string };
      } | null;
      resolution?: {
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      offeredResolutions: Array<{
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      }>;
      assessment?: {
        __typename?: 'Claim__Assessment';
        id: string;
        verifiedValue: number;
        weight?: number | null;
        classification: Claim__Assessment__Classification;
        approvedAmount?: number | null;
        coverage?: Claim__Assessment__Coverage | null;
      } | null;
    }>;
    propertySelection?: {
      __typename?: 'Claim__PropertySelection';
      id: string;
      name?: string | null;
      declaration?: number | null;
      ownership?: Claim__PropertySelectionOwnership | null;
      owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
      photos: Array<{
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      }>;
      quotes: Array<{
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      }>;
      resolution?: {
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      offeredResolutions: Array<{
        __typename?: 'Claim__Resolution';
        id: string;
        kind: Claim__ResolutionKind;
        amount?: number | null;
        reason?: string | null;
        offeredAt?: string | null;
        user?: { __typename?: 'User'; id: string; name: string } | null;
      }>;
      assessment?: {
        __typename?: 'Claim__Assessment';
        id: string;
        verifiedValue: number;
        weight?: number | null;
        classification: Claim__Assessment__Classification;
        approvedAmount?: number | null;
        coverage?: Claim__Assessment__Coverage | null;
      } | null;
    } | null;
    account: {
      __typename?: 'Account';
      id: string;
      customer: { __typename?: 'Customer'; id: string; name: string };
      latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
    };
    offers: Array<{
      __typename?: 'Claim__Offer';
      id: string;
      details: string;
      createdAt: string;
      expiry: string;
      totalAmount: number;
      user: { __typename?: 'User'; id: string; name: string };
    }>;
    settlementAgreement?: {
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    } | null;
  };
};

export type CloseClaimMutationVariables = Exact<{
  claimID: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type CloseClaimMutation = {
  __typename?: 'Mutation';
  closeClaim: {
    __typename?: 'ClosePayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
    } | null;
  };
};

export type DetailedClaimFragment = {
  __typename?: 'Claim';
  id: string;
  uuid: string;
  number: string;
  status: Claim__Status;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
  offeredAt?: string | null;
  expiredReason?: string | null;
  owner?: { __typename?: 'User'; id: string; name: string } | null;
  issueSelection?: {
    __typename?: 'Claim__IssueSelection';
    id: string;
    compensationRequested?: boolean | null;
    declaration?: number | null;
    description?: string | null;
    kind?: Claim__IssueSelection__Kind | null;
    photos: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    resolution?: {
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    offeredResolutions: Array<{
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
  } | null;
  itemSelections: Array<{
    __typename?: 'Claim__ItemSelection';
    id: string;
    kind: Claim__ItemSelectionKind;
    name?: string | null;
    declaration?: number | null;
    productURL?: string | null;
    photos: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    receipts: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    item?: {
      __typename?: 'Item';
      id: string;
      name?: string | null;
      barcode: { __typename?: 'Barcode'; value: string };
    } | null;
    resolution?: {
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    offeredResolutions: Array<{
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
    assessment?: {
      __typename?: 'Claim__Assessment';
      id: string;
      verifiedValue: number;
      weight?: number | null;
      classification: Claim__Assessment__Classification;
      approvedAmount?: number | null;
      coverage?: Claim__Assessment__Coverage | null;
    } | null;
  }>;
  propertySelection?: {
    __typename?: 'Claim__PropertySelection';
    id: string;
    name?: string | null;
    declaration?: number | null;
    ownership?: Claim__PropertySelectionOwnership | null;
    owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
    photos: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    quotes: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    resolution?: {
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    offeredResolutions: Array<{
      __typename?: 'Claim__Resolution';
      id: string;
      kind: Claim__ResolutionKind;
      amount?: number | null;
      reason?: string | null;
      offeredAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
    assessment?: {
      __typename?: 'Claim__Assessment';
      id: string;
      verifiedValue: number;
      weight?: number | null;
      classification: Claim__Assessment__Classification;
      approvedAmount?: number | null;
      coverage?: Claim__Assessment__Coverage | null;
    } | null;
  } | null;
  account: {
    __typename?: 'Account';
    id: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
    latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
  };
  offers: Array<{
    __typename?: 'Claim__Offer';
    id: string;
    details: string;
    createdAt: string;
    expiry: string;
    totalAmount: number;
    user: { __typename?: 'User'; id: string; name: string };
  }>;
  settlementAgreement?: {
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  } | null;
};

export type ModifyClaimOwnerMutationVariables = Exact<{
  claimID: Scalars['ID'];
  ownerID?: InputMaybe<Scalars['ID']>;
}>;

export type ModifyClaimOwnerMutation = {
  __typename?: 'Mutation';
  modifyClaimOwner: {
    __typename?: 'Claims__ModifyOwnerPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      owner?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
  };
};

export type AccountClaimsSummaryQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountClaimsSummaryQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    claimsSummary: {
      __typename?: 'ClaimsSummary';
      totalItemCompensation: number;
      totalPropertyCompensation: number;
      paidItemCompensation: number;
      pendingItemCompensation: number;
      paidPropertyCompensation: number;
      pendingPropertyCompensation: number;
      latestProtectionPlan: { __typename?: 'ProtectionPlan'; slug: string; name: string; maxCovered: number };
    };
  };
};

export type ExtendOfferMutationVariables = Exact<{
  input: Claims__ExtendOffer__Input;
}>;

export type ExtendOfferMutation = {
  __typename?: 'Mutation';
  extendOffer: {
    __typename?: 'Claims__ExtendOfferPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      owner?: { __typename?: 'User'; id: string; name: string } | null;
      issueSelection?: {
        __typename?: 'Claim__IssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: Claim__IssueSelection__Kind | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'Claim__ItemSelection';
        id: string;
        kind: Claim__ItemSelectionKind;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        receipts: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        item?: {
          __typename?: 'Item';
          id: string;
          name?: string | null;
          barcode: { __typename?: 'Barcode'; value: string };
        } | null;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'Claim__PropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: Claim__PropertySelectionOwnership | null;
        owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        quotes: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      } | null;
      account: {
        __typename?: 'Account';
        id: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
        latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
      };
      offers: Array<{
        __typename?: 'Claim__Offer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
      settlementAgreement?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      } | null;
    } | null;
  };
};

export type SaveOfferMutationVariables = Exact<{
  input: Claims__SaveOffer__Input;
}>;

export type SaveOfferMutation = {
  __typename?: 'Mutation';
  saveOffer: {
    __typename?: 'Claims__SaveOfferPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      owner?: { __typename?: 'User'; id: string; name: string } | null;
      issueSelection?: {
        __typename?: 'Claim__IssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: Claim__IssueSelection__Kind | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'Claim__ItemSelection';
        id: string;
        kind: Claim__ItemSelectionKind;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        receipts: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        item?: {
          __typename?: 'Item';
          id: string;
          name?: string | null;
          barcode: { __typename?: 'Barcode'; value: string };
        } | null;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'Claim__PropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: Claim__PropertySelectionOwnership | null;
        owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        quotes: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      } | null;
      account: {
        __typename?: 'Account';
        id: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
        latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
      };
      offers: Array<{
        __typename?: 'Claim__Offer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
      settlementAgreement?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      } | null;
    } | null;
  };
};

export type ReopenClaimMutationVariables = Exact<{
  claimID: Scalars['ID'];
}>;

export type ReopenClaimMutation = {
  __typename?: 'Mutation';
  reopenClaim: {
    __typename?: 'Claims__ReopenPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      issueSelection?: {
        __typename?: 'Claim__IssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: Claim__IssueSelection__Kind | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'Claim__ItemSelection';
        id: string;
        kind: Claim__ItemSelectionKind;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        receipts: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        item?: {
          __typename?: 'Item';
          id: string;
          name?: string | null;
          barcode: { __typename?: 'Barcode'; value: string };
        } | null;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'Claim__PropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: Claim__PropertySelectionOwnership | null;
        owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        quotes: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      } | null;
    } | null;
  };
};

export type VerifyClaimMutationVariables = Exact<{
  input: Claim__Verify__Input;
}>;

export type VerifyClaimMutation = {
  __typename?: 'Mutation';
  verifyClaim: {
    __typename?: 'Claims__VerifyPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      owner?: { __typename?: 'User'; id: string; name: string } | null;
      issueSelection?: {
        __typename?: 'Claim__IssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: Claim__IssueSelection__Kind | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'Claim__ItemSelection';
        id: string;
        kind: Claim__ItemSelectionKind;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        receipts: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        item?: {
          __typename?: 'Item';
          id: string;
          name?: string | null;
          barcode: { __typename?: 'Barcode'; value: string };
        } | null;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'Claim__PropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: Claim__PropertySelectionOwnership | null;
        owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        quotes: Array<{
          __typename?: 'ActiveStorageAttachment';
          id: string;
          imgixURL: string;
          url: string;
          filename: string;
        }>;
        resolution?: {
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        offeredResolutions: Array<{
          __typename?: 'Claim__Resolution';
          id: string;
          kind: Claim__ResolutionKind;
          amount?: number | null;
          reason?: string | null;
          offeredAt?: string | null;
          user?: { __typename?: 'User'; id: string; name: string } | null;
        }>;
        assessment?: {
          __typename?: 'Claim__Assessment';
          id: string;
          verifiedValue: number;
          weight?: number | null;
          classification: Claim__Assessment__Classification;
          approvedAmount?: number | null;
          coverage?: Claim__Assessment__Coverage | null;
        } | null;
      } | null;
      account: {
        __typename?: 'Account';
        id: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
        latestProtectionSubscription?: { __typename?: 'Subscription'; name: string; quantity: number } | null;
      };
      offers: Array<{
        __typename?: 'Claim__Offer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
      settlementAgreement?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      } | null;
    } | null;
  };
};

export type Admin__PaginatedClaimFragment = {
  __typename?: 'Claim';
  kind: string;
  id: string;
  uuid: string;
  number: string;
  status: Claim__Status;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
  offeredAt?: string | null;
  expiredReason?: string | null;
  account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
  owner?: { __typename?: 'User'; id: string; name: string } | null;
};

export type PaginatedClaimsQueryVariables = Exact<{
  filters: Claim__FiltersInput;
  page?: InputMaybe<Scalars['Int']>;
  numPer?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedClaimsQuery = {
  __typename?: 'Query';
  paginatedClaims: {
    __typename?: 'PaginatedClaim';
    results: Array<{
      __typename?: 'Claim';
      kind: string;
      id: string;
      uuid: string;
      number: string;
      status: Claim__Status;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      offeredAt?: string | null;
      expiredReason?: string | null;
      account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
      owner?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ContractorPasswordResetRequestApproveMutationVariables = Exact<{
  input: ContractorPasswordResetRequest__Approve__Input;
}>;

export type ContractorPasswordResetRequestApproveMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ContractorPasswordResetRequest__ApprovePayload'; error?: string | null; status: Status };
};

export type ContractorPasswordResetRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ContractorPasswordResetRequestQuery = {
  __typename?: 'Query';
  result?: {
    __typename?: 'ContractorPasswordResetRequest';
    id: string;
    user: { __typename?: 'User'; id: string; email: string; name: string };
  } | null;
};

export type CouponActivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CouponActivateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Marketing__CouponActivatePayload';
    coupon?: { __typename?: 'Coupon'; id: string; active: boolean } | null;
  };
};

export type CouponCreateMutationVariables = Exact<{
  input: Coupon__Input;
}>;

export type CouponCreateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Marketing__CouponCreatePayload';
    error?: string | null;
    status: Status;
    coupon?: { __typename?: 'Coupon'; id: string } | null;
  };
};

export type CouponDeactivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CouponDeactivateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Marketing__CouponDeactivatePayload';
    coupon?: { __typename?: 'Coupon'; id: string; active: boolean } | null;
  };
};

export type CouponQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CouponQuery = {
  __typename?: 'Query';
  coupon: {
    __typename?: 'Coupon';
    id: string;
    stripeId: string;
    name: string;
    code: string;
    active: boolean;
    amount: number;
    percent: number;
    duration: Coupon__Duration;
    durationInMonths?: number | null;
    effective?: string | null;
    eligibleServices: Array<Coupon__Service>;
    scheduledCutoff?: string | null;
    subject: Coupon__Subject;
    currency: Currency;
  };
};

export type CouponUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Coupon__Input;
}>;

export type CouponUpdateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Marketing__CouponUpdatePayload';
    error?: string | null;
    status: Status;
    coupon?: { __typename?: 'Coupon'; id: string } | null;
  };
};

export type PaginatedCouponsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Coupon__FiltersInput>;
}>;

export type PaginatedCouponsQuery = {
  __typename?: 'Query';
  paginatedCoupons: {
    __typename?: 'Coupon__PaginatedType';
    results: Array<{
      __typename?: 'Coupon';
      id: string;
      stripeId: string;
      name: string;
      code: string;
      active: boolean;
      amount: number;
      percent: number;
      duration: Coupon__Duration;
      durationInMonths?: number | null;
      effective?: string | null;
      eligibleServices: Array<Coupon__Service>;
      scheduledCutoff?: string | null;
      subject: Coupon__Subject;
      currency: Currency;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type CouponsQueryVariables = Exact<{
  filters?: InputMaybe<Coupon__FiltersInput>;
}>;

export type CouponsQuery = {
  __typename?: 'Query';
  result: Array<{ __typename?: 'Coupon'; id: string; name: string; code: string }>;
};

export type BuildCustomerTicketMutationVariables = Exact<{
  input: CustomerTickets__BuildInput;
}>;

export type BuildCustomerTicketMutation = {
  __typename?: 'Mutation';
  buildCustomerTicket: {
    __typename?: 'BuildPayload';
    status: Status;
    error?: string | null;
    customerTicket?: { __typename?: 'CustomerTicket'; id: string } | null;
  };
};

export type CustomerTicketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomerTicketQuery = {
  __typename?: 'Query';
  customerTicket: {
    __typename?: 'CustomerTicket';
    id: string;
    category: string;
    subCategory?: string | null;
    description?: string | null;
    state: string;
    createdAt: string;
    actionRequiredAt?: string | null;
    customerUpdatedAt?: string | null;
    priority?: CustomerTicket__Priority | null;
    contactPreference?: CustomerTicket__ContactPreference | null;
    allowIncomingCalls: boolean;
    customerTicketableType: string;
    interactions: Array<{
      __typename?: 'CustomerInteraction';
      id: string;
      interactableType: string;
      interactable: {
        __typename?: 'PhoneCall';
        id: string;
        createdAt: string;
        direction: string;
        note?: { __typename?: 'Note'; body: string } | null;
        latestConnectedUser?: { __typename?: 'User'; id: string; name: string } | null;
      };
    }>;
    leadInteractions: Array<{
      __typename?: 'LeadInteraction';
      id: string;
      interactableType: string;
      interactable: {
        __typename?: 'PhoneCall';
        id: string;
        createdAt: string;
        direction: string;
        note?: { __typename?: 'Note'; body: string } | null;
        latestConnectedUser?: { __typename?: 'User'; id: string; name: string } | null;
      };
    }>;
    zendeskTicketAssociations: Array<{
      __typename?: 'ZendeskTicketAssociation';
      id: string;
      zdTicketID: string;
      createdAt: string;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
    files: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
    assignee?: { __typename?: 'User'; id: string; name: string } | null;
    creator?: { __typename?: 'User'; id: string; name: string } | null;
    customerTicketable:
      | { __typename?: 'Account'; id: string; shortDisplay: string }
      | { __typename?: 'Lead'; id: string; shortDisplay: string };
    group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
  };
};

export type CustomerTicketGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerTicketGroupsQuery = {
  __typename?: 'Query';
  customerTicketGroups: Array<{
    __typename?: 'Ticket__Group';
    id: string;
    name: string;
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
  }>;
};

export type ModifyCustomerTicketMutationVariables = Exact<{
  input: CustomerTickets__ModifyInput;
}>;

export type ModifyCustomerTicketMutation = {
  __typename?: 'Mutation';
  modifyCustomerTicket: {
    __typename?: 'ModifyPayload';
    status: Status;
    error?: string | null;
    customerTicket?: {
      __typename?: 'CustomerTicket';
      id: string;
      category: string;
      subCategory?: string | null;
      description?: string | null;
      state: string;
      createdAt: string;
      actionRequiredAt?: string | null;
      customerUpdatedAt?: string | null;
      priority?: CustomerTicket__Priority | null;
      contactPreference?: CustomerTicket__ContactPreference | null;
      allowIncomingCalls: boolean;
      customerTicketableType: string;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
      customerTicketable:
        | { __typename?: 'Account'; id: string; shortDisplay: string }
        | { __typename?: 'Lead'; id: string; shortDisplay: string };
      group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
    } | null;
  };
};

export type PaginatedCustomerTicketListQueryVariables = Exact<{
  filters?: InputMaybe<CustomerTicketFiltersInput>;
  sort?: InputMaybe<CustomerTicketSortInput>;
  page?: InputMaybe<Scalars['Int']>;
  numPer?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedCustomerTicketListQuery = {
  __typename?: 'Query';
  paginatedCustomerTicketList: {
    __typename?: 'PaginatedCustomerTicket';
    results: Array<{
      __typename?: 'CustomerTicket';
      id: string;
      category: string;
      subCategory?: string | null;
      description?: string | null;
      state: string;
      createdAt: string;
      actionRequiredAt?: string | null;
      customerUpdatedAt?: string | null;
      priority?: CustomerTicket__Priority | null;
      contactPreference?: CustomerTicket__ContactPreference | null;
      allowIncomingCalls: boolean;
      customerTicketableType: string;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
      customerTicketable:
        | { __typename?: 'Account'; id: string; shortDisplay: string }
        | { __typename?: 'Lead'; id: string; shortDisplay: string };
      group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ReopenCustomerTicketMutationVariables = Exact<{
  id: Scalars['ID'];
  actionRequiredAt: Scalars['DateTime'];
}>;

export type ReopenCustomerTicketMutation = {
  __typename?: 'Mutation';
  reopenCustomerTicket: {
    __typename?: 'ReopenPayload';
    status: Status;
    error?: string | null;
    customerTicket?: {
      __typename?: 'CustomerTicket';
      id: string;
      category: string;
      subCategory?: string | null;
      description?: string | null;
      state: string;
      createdAt: string;
      actionRequiredAt?: string | null;
      customerUpdatedAt?: string | null;
      priority?: CustomerTicket__Priority | null;
      contactPreference?: CustomerTicket__ContactPreference | null;
      allowIncomingCalls: boolean;
      customerTicketableType: string;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
      customerTicketable:
        | { __typename?: 'Account'; id: string; shortDisplay: string }
        | { __typename?: 'Lead'; id: string; shortDisplay: string };
      group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
    } | null;
  };
};

export type ResolveCustomerTicketMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResolveCustomerTicketMutation = {
  __typename?: 'Mutation';
  resolveCustomerTicket: {
    __typename?: 'ResolvePayload';
    status: Status;
    error?: string | null;
    customerTicket?: {
      __typename?: 'CustomerTicket';
      id: string;
      category: string;
      subCategory?: string | null;
      description?: string | null;
      state: string;
      createdAt: string;
      actionRequiredAt?: string | null;
      customerUpdatedAt?: string | null;
      priority?: CustomerTicket__Priority | null;
      contactPreference?: CustomerTicket__ContactPreference | null;
      allowIncomingCalls: boolean;
      customerTicketableType: string;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
      customerTicketable:
        | { __typename?: 'Account'; id: string; shortDisplay: string }
        | { __typename?: 'Lead'; id: string; shortDisplay: string };
      group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
    } | null;
  };
};

export type BuildZendeskTicketMutationVariables = Exact<{
  customerTicketID: Scalars['ID'];
}>;

export type BuildZendeskTicketMutation = {
  __typename?: 'Mutation';
  buildZendeskTicket: {
    __typename?: 'BuildZendeskTicketPayload';
    status: Status;
    error?: string | null;
    zendeskTicketAssociation?: {
      __typename?: 'ZendeskTicketAssociation';
      id: string;
      zdTicketID: string;
      createdAt: string;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
  };
};

export type ZendeskTicketQueryVariables = Exact<{
  zdTicketID: Scalars['ID'];
}>;

export type ZendeskTicketQuery = {
  __typename?: 'Query';
  zendeskTicket: {
    __typename?: 'ZendeskTicket';
    id: string;
    subject: string;
    comments: Array<{
      __typename?: 'ZendeskTicketComment';
      id: string;
      body: string;
      createdAt: string;
      author?: { __typename?: 'ZendeskUser'; id: string; name: string; email: string } | null;
    }>;
  };
};

export type DamagedItemsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type DamagedItemsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    damagedItems: Array<{
      __typename?: 'DamagedItem';
      id: string;
      kind: DamagedItem__Kind;
      reason?: string | null;
      timestamp: string;
      user: { __typename?: 'User'; name: string };
      images: Array<{ __typename?: 'Image'; id: string; source: string; size: string }>;
    }>;
    region: { __typename?: 'Region'; id: string; tz: string };
  };
};

export type BuildDepotMutationVariables = Exact<{
  input: DepotInput;
}>;

export type BuildDepotMutation = {
  __typename?: 'Mutation';
  buildDepot: { __typename?: 'BuildDepotPayload'; error?: string | null };
};

export type DepotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DepotQuery = {
  __typename?: 'Query';
  depot: {
    __typename?: 'Depot';
    id: string;
    name: string;
    abbreviation: string;
    active: boolean;
    address: {
      __typename?: 'Address';
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
    };
    regions: Array<{ __typename?: 'Region'; id: string }>;
  };
};

export type DepotsQueryVariables = Exact<{
  filters?: InputMaybe<FacilityFiltersInput>;
}>;

export type DepotsQuery = {
  __typename?: 'Query';
  depots: Array<{
    __typename?: 'Depot';
    id: string;
    name: string;
    abbreviation: string;
    active: boolean;
    address: {
      __typename?: 'Address';
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
    };
    regions: Array<{ __typename?: 'Region'; name: string }>;
  }>;
};

export type ModifyDepotMutationVariables = Exact<{
  input: DepotInput;
  id: Scalars['ID'];
}>;

export type ModifyDepotMutation = {
  __typename?: 'Mutation';
  modifyDepot: { __typename?: 'ModifyDepotPayload'; error?: string | null };
};

export type BuildMessageMutationVariables = Exact<{
  input: MessageInput;
}>;

export type BuildMessageMutation = {
  __typename?: 'Mutation';
  buildMessage: { __typename?: 'BuildMessagePayload'; error?: string | null };
};

export type DispatchCommunicationsQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type DispatchCommunicationsQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    region: { __typename?: 'Region'; id: string; tz: string };
    callables: Array<{
      __typename?: 'Callable';
      name: string;
      role: string;
      number: string;
      phoneId?: string | null;
      assignmentId?: string | null;
    }>;
    paginatedCommunications: {
      __typename?: 'PaginatedCommunication';
      results: Array<
        | {
            __typename?: 'Message';
            id: string;
            twilioStatus?: string | null;
            iterableStatus?: string | null;
            consentBlocked: boolean;
            body: string;
            kind: string;
            userName: string;
            recipientName: string;
            to: string;
            from: string;
            createdAt: string;
            nonUserNumber: string;
            images?: Array<{ __typename?: 'Image'; source: string; size: string }> | null;
          }
        | {
            __typename?: 'PhoneCall';
            id: string;
            startAt?: string | null;
            createdAt: string;
            status: string;
            direction: string;
            duration: number;
            latestConnectedUser?: { __typename?: 'User'; name: string } | null;
            touchTaxons: Array<{ __typename?: 'TouchTaxon'; name?: string | null; parentName?: string | null }>;
            notes: Array<{ __typename?: 'Note'; body: string }>;
            conversations: Array<{
              __typename?: 'Conversation';
              recording?: { __typename?: 'Recording'; playbackURL?: string | null } | null;
            }>;
          }
      >;
      pagination: {
        __typename?: 'Pagination';
        currentPage: number;
        nextPage?: number | null;
        prevPage?: number | null;
        totalPages: number;
        totalCount: number;
      };
    };
  };
};

export type DispatchPreTripAssessmentQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatchPreTripAssessmentQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    preTripAssessment?: {
      __typename?: 'PreTripAssessment';
      other?: string | null;
      unresolvedSelections?: Array<{
        __typename?: 'PreTripSelection';
        preTripItem: { __typename?: 'PreTripItem'; name: string };
        preTripOption: { __typename?: 'PreTripOption'; name: string };
      }> | null;
    } | null;
  };
};

export type DispatchAuditOrderEventsQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatchAuditOrderEventsQuery = {
  __typename?: 'Query';
  dispatchAuditOrderEvents?: Array<{
    __typename?: 'AuditOrderEvent';
    id: string;
    titleizedEventName: string;
    timestamp?: string | null;
    orderId: string;
    image?: { __typename?: 'Image'; id: string; source: string; size: string } | null;
  }> | null;
};

export type DispatchQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
  orderFilterInput?: InputMaybe<OrderFilterInput>;
}>;

export type DispatchQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    cutoff: string;
    clockout?: string | null;
    region: { __typename?: 'Region'; id: string; tz: string };
    orders: Array<{
      __typename?: 'Order';
      id: string;
      parentID?: string | null;
      scheduled: string;
      internalScheduled?: string | null;
      name: string;
      slaWindowEnd: string;
      status: OrderStatusEnum;
      expectedStartTime?: string | null;
      formattedArrivalWindow: string;
      orderEventsExcludingNonLeadAssignments: Array<{
        __typename?: 'OrderEvent';
        id: string;
        category: string;
        eventName: string;
        timestamp: string;
        isBreakEvent: boolean;
      }>;
      simulatedOrderEvents: Array<{
        __typename?: 'OrderEvent';
        id: string;
        category: string;
        eventName: string;
        timestamp: string;
        isBreakEvent: boolean;
      }>;
      services: Array<{
        __typename?: 'OrderService';
        id: string;
        type: OrderTypeEnum;
        cuft?: number | null;
        estimatedCuft: number;
        estimatedCuftPerHourPerMover?: number | null;
        cuftPerHourPerMover?: number | null;
        started?: string | null;
      }>;
    }>;
  };
};

export type DispatchVehicleSealImagesQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatchVehicleSealImagesQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    vehicleRecipe?: {
      __typename?: 'Dispatch__VehicleRecipe';
      id: string;
      minQuantity?: number | null;
      maxQuantity?: number | null;
      minimumCuft?: number | null;
      notes?: string | null;
      allowedVehicleTypes: Array<{ __typename?: 'VehicleType'; id: string; name: string }>;
    } | null;
    vehiclePreferences: Array<{
      __typename?: 'Dispatch__VehiclePreference';
      id: string;
      quantity: number;
      vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
    }>;
    dispatchesVehicles: Array<{
      __typename?: 'DispatchesVehicle';
      sealAt?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
      vehicle: { __typename?: 'Vehicle'; id: string; serialCode: string };
      sealImage?: { __typename?: 'Image'; id: string; source: string; size: string } | null;
    }>;
    tasks: Array<{
      __typename?: 'Dispatch__Task';
      kind: Dispatch__TaskKindEnum;
      user: { __typename?: 'User'; id: string; name: string };
      vehicle?: { __typename?: 'Vehicle'; id: string; serialCode: string } | null;
      image?: { __typename?: 'Image'; id: string; source: string; size: string; createdAt: string } | null;
    }>;
    region: { __typename?: 'Region'; id: string; tz: string };
  };
};

export type DispatcherDropMoverDispatchQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatcherDropMoverDispatchQuery = {
  __typename?: 'Query';
  dispatch: { __typename?: 'Dispatch'; canDropMover: boolean };
};

export type DispatcherDispatchQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatcherDispatchQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    simulation?: {
      __typename?: 'DispatchSimulation';
      errors: Array<{ __typename?: 'SimulationError'; message: string; severe: boolean }>;
    } | null;
  };
};

export type DispatchEditDispatchQueryVariables = Exact<{
  dispatchID: Scalars['ID'];
}>;

export type DispatchEditDispatchQuery = {
  __typename?: 'Query';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    region: { __typename?: 'Region'; id: string; name: string; tz: string };
    orders: Array<{
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      serviceType?: OrderServiceTypeEnum | null;
      address: { __typename?: 'Address'; id: string; addressableType?: string | null };
    }>;
    vehicles: Array<{ __typename?: 'Vehicle'; id: string }>;
    inboundWarehouse?: { __typename?: 'Warehouse'; id: string } | null;
  };
};

export type DispatchEditRegionQueryVariables = Exact<{
  vehicleFilters?: InputMaybe<VehicleFiltersInput>;
  warehouseFilters?: InputMaybe<FacilityFiltersInput>;
}>;

export type DispatchEditRegionQuery = {
  __typename?: 'Query';
  vehicles: Array<{ __typename?: 'Vehicle'; id: string; name: string }>;
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string }>;
};

export type DispatchUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: DispatchInput;
}>;

export type DispatchUpdateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'DispatchUpdatePayload'; status: Status; error?: string | null };
};

export type AdminDropOffAppointmentFragment = {
  __typename?: 'DropOff__Appointment';
  id: string;
  expectedAt: string;
  canceledAt?: string | null;
  completedAt?: string | null;
  state: AppointmentStateEnum;
  storageArrangement?:
    | { __typename?: 'DropOff__ThirdPartySelfStorageUnit'; id: string; name: string }
    | { __typename?: 'SelfStorage__Hold'; unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string } }
    | null;
  access?: { __typename?: 'DropOff__Access'; id: string; customerCode: string; moverCode: string } | null;
  facility: {
    __typename?: 'DropOff__Facility';
    id: string;
    name: string;
    hours: Array<string>;
    region: { __typename?: 'Region'; id: string };
    fulfiller?:
      | { __typename?: 'DropOff__ThirdPartySelfStorageFacility'; id: string; accessCode?: string | null }
      | { __typename?: 'SelfStorage__Facility'; id: string; accessCode?: string | null }
      | null;
  };
  files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; url: string; filename: string }>;
};

export type AdminDropOffAppointmentQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type AdminDropOffAppointmentQuery = {
  __typename?: 'Query';
  dropOffAppointment?: {
    __typename?: 'DropOff__Appointment';
    id: string;
    expectedAt: string;
    canceledAt?: string | null;
    completedAt?: string | null;
    state: AppointmentStateEnum;
    storageArrangement?:
      | { __typename?: 'DropOff__ThirdPartySelfStorageUnit'; id: string; name: string }
      | { __typename?: 'SelfStorage__Hold'; unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string } }
      | null;
    access?: { __typename?: 'DropOff__Access'; id: string; customerCode: string; moverCode: string } | null;
    facility: {
      __typename?: 'DropOff__Facility';
      id: string;
      name: string;
      hours: Array<string>;
      region: { __typename?: 'Region'; id: string };
      fulfiller?:
        | { __typename?: 'DropOff__ThirdPartySelfStorageFacility'; id: string; accessCode?: string | null }
        | { __typename?: 'SelfStorage__Facility'; id: string; accessCode?: string | null }
        | null;
    };
    files: Array<{
      __typename?: 'ActiveStorageAttachment';
      id: string;
      imgixURL: string;
      url: string;
      filename: string;
    }>;
  } | null;
};

export type EstimationOverrideEstimatedCuftMutationVariables = Exact<{
  orderID: Scalars['ID'];
  reason?: InputMaybe<Estimation__EstimatedCuftOverrideReason>;
  estimatedCuft?: InputMaybe<Scalars['Int']>;
}>;

export type EstimationOverrideEstimatedCuftMutation = {
  __typename?: 'Mutation';
  estimationOverrideEstimatedCuft?: {
    __typename?: 'Estimation__OverrideEstimatedCuftPayload';
    error?: string | null;
    status: Status;
    order?: { __typename?: 'Order'; id: string; estimatedCuft: number; estimatedDuration?: number | null } | null;
  } | null;
};

export type EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    estimatedCuft: number;
    estimatedCuftOverrideReason?: Estimation__EstimatedCuftOverrideReason | null;
  };
};

export type EstimationAlgorithmForOrderQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationAlgorithmForOrderQuery = {
  __typename?: 'Query';
  estimationAlgorithmForOrder?: {
    __typename?: 'Estimation__Algorithm';
    id: string;
    name: Estimation__AlgorithmName;
  } | null;
};

export type EstimationAlgorithmsQueryVariables = Exact<{ [key: string]: never }>;

export type EstimationAlgorithmsQuery = {
  __typename?: 'Query';
  estimationAlgorithms: Array<{ __typename?: 'Estimation__Algorithm'; id: string; name: Estimation__AlgorithmName }>;
};

export type EstimationItemsQueryVariables = Exact<{
  orderID: Scalars['ID'];
  algorithmID?: InputMaybe<Scalars['ID']>;
}>;

export type EstimationItemsQuery = {
  __typename?: 'Query';
  estimationItems: Array<{
    __typename?: 'Estimation__ItemType';
    id: string;
    quantity: number;
    disassemblyQuantity?: number | null;
    category: {
      __typename?: 'Estimation__CategoryType';
      id: string;
      name: string;
      defaultCuft?: number | null;
      parent?: { __typename?: 'Estimation__CategoryThemeType'; name: string } | null;
    };
  }>;
};

export type EstimationSelectedRoomsQueryVariables = Exact<{
  orderID: Scalars['ID'];
  algorithmID: Scalars['ID'];
}>;

export type EstimationSelectedRoomsQuery = {
  __typename?: 'Query';
  estimationSelectedRooms: Array<{
    __typename?: 'Estimation__SelectedRoomType';
    id: string;
    packingRequested: boolean;
    roomCategory: { __typename?: 'Estimation__RoomCategoryType'; id: string; name: string };
  }>;
};

export type EstimationEstimatedItemsCreateMutationVariables = Exact<{
  input: Estimation__EstimatedItemsCreateInput;
}>;

export type EstimationEstimatedItemsCreateMutation = {
  __typename?: 'Mutation';
  result?: { __typename?: 'Estimation__EstimatedItemsCreatePayload'; error?: string | null; status: Status } | null;
};

export type EstimationsForOrderQueryVariables = Exact<{
  orderID: Scalars['ID'];
  algorithmID: Scalars['ID'];
}>;

export type EstimationsForOrderQuery = {
  __typename?: 'Query';
  estimationsForOrder: {
    __typename?: 'Estimation__OrderEstimation';
    allItemsInListedRooms: boolean;
    itemsRequireMoreThanOneMover?: Order__Metadata__ItemsRequireMoreThanOneMover | null;
    agentBoxEstimation?: {
      __typename?: 'Estimation__OrderItemEstimation';
      categoryID: string;
      name: string;
      cuft?: number | null;
    } | null;
    customerBoxEstimation?: {
      __typename?: 'Estimation__OrderItemEstimation';
      categoryID: string;
      name: string;
      cuft?: number | null;
    } | null;
    itemEstimations: Array<{
      __typename?: 'Estimation__OrderItemEstimation';
      categoryID: string;
      name: string;
      cuft?: number | null;
      agentQuantity: number;
      customerQuantity: number;
    }>;
    agentOtherItemEstimation?: {
      __typename?: 'Estimation__OrderItemEstimation';
      categoryID: string;
      name: string;
      cuft?: number | null;
    } | null;
    customerOtherItemEstimation?: {
      __typename?: 'Estimation__OrderItemEstimation';
      categoryID: string;
      name: string;
      cuft?: number | null;
    } | null;
    roomEstimations: Array<{
      __typename?: 'Estimation__OrderRoomEstimation';
      categoryID: string;
      name: string;
      quantity: number;
    }>;
  };
  order: { __typename?: 'Order'; id: string; state: OrderStateEnum; type: OrderTypeEnum };
  boxCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    defaultCuft?: number | null;
    parent?: { __typename?: 'Estimation__CategoryThemeType'; id: string; name: string } | null;
  }>;
  otherItemCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    defaultCuft?: number | null;
    parent?: { __typename?: 'Estimation__CategoryThemeType'; id: string; name: string } | null;
  }>;
};

export type EstimationUploadsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationUploadsQuery = {
  __typename?: 'Query';
  estimationUploads: Array<{
    __typename?: 'Estimation__UploadType';
    id: string;
    media: {
      __typename?: 'Estimation__UploadMediaType';
      id: string;
      imgixURL: string;
      isImage: boolean;
      isVideo: boolean;
    };
  }>;
};

export type FacilityDetailsQueryVariables = Exact<{
  facility: FacilityInput;
}>;

export type FacilityDetailsQuery = {
  __typename?: 'Query';
  facility:
    | { __typename?: 'Depot' }
    | {
        __typename?: 'Warehouse';
        id: string;
        name: string;
        accessHours: Array<string>;
        address: { __typename?: 'Address'; id: string; formatted: string; map: string };
      };
};

export type FeatureFlagsQueryVariables = Exact<{
  resourceName?: InputMaybe<Scalars['String']>;
}>;

export type FeatureFlagsQuery = {
  __typename?: 'Query';
  featureFlags: Array<{
    __typename?: 'FeatureFlag';
    id: string;
    createdAt: string;
    description?: string | null;
    name: string;
    resourceName: string;
    updatedAt: string;
    whitelistedObjects: Array<
      | { __typename?: 'Account'; id: string; name: string }
      | { __typename?: 'Region'; id: string; name: string }
      | { __typename?: 'User'; id: string; name: string }
      | { __typename?: 'Warehouse'; id: string; name: string }
    >;
  }>;
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  users: Array<{ __typename?: 'User'; id: string; name: string }>;
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string }>;
};

export type FeatureFlagUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: FeatureFlagInput;
}>;

export type FeatureFlagUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'FeatureFlagUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'FeatureFlag';
      id: string;
      createdAt: string;
      name: string;
      resourceName: string;
      updatedAt: string;
      whitelistedObjects: Array<
        | { __typename?: 'Account'; id: string; name: string }
        | { __typename?: 'Region'; id: string; name: string }
        | { __typename?: 'User'; id: string; name: string }
        | { __typename?: 'Warehouse'; id: string; name: string }
      >;
    } | null;
  } | null;
};

export type MapRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type MapRegionsQuery = {
  __typename?: 'Query';
  regions: Array<{ __typename?: 'Region'; id: string; coordinates: any; hexColor: string }>;
};

export type PaginatedHistoryListQueryVariables = Exact<{
  subject: History__SubjectInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedHistoryListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedHistory';
    results: Array<{
      __typename?: 'History';
      tag?: string | null;
      timestamp: string;
      resource:
        | {
            __typename: 'Changed__Audit';
            id: string;
            event: Changed__Event;
            fields: Array<{ __typename?: 'Changed__Field'; name: string; now?: any | null; was?: any | null }>;
            audited:
              | { __typename?: 'Changed__Customer'; id: string; email: string }
              | { __typename?: 'Changed__Phone'; id: string; number: string }
              | { __typename?: 'Changed__Record'; id: string; type: string }
              | {
                  __typename?: 'LaborClock';
                  id: string;
                  role: Assignment__Role;
                  user: { __typename?: 'User'; id: string; name: string };
                };
            relationships: Array<{
              __typename?: 'Changed__Relationship';
              name: string;
              associations: Array<{
                __typename?: 'Changed__Association';
                id: string;
                name: string;
                kind: Changed__Association__Kind;
                associated: { __typename?: 'Changed__Associated'; id: string; name: string };
              }>;
            }>;
            changer: { __typename?: 'Changed__Changer'; id: string; name: string };
          }
        | { __typename: 'LaborClock' }
        | { __typename: 'MarketingEvent'; id: string; metadata: any }
        | { __typename: 'Note'; id: string; body: string; user: { __typename?: 'User'; id: string; name: string } }
        | {
            __typename: 'PhoneCall';
            id: string;
            status: string;
            direction: string;
            startAt?: string | null;
            endAt?: string | null;
            duration: number;
            reasons: Array<string>;
            notes: Array<{ __typename?: 'Note'; id: string; body: string }>;
            conversations: Array<{
              __typename?: 'Conversation';
              id: string;
              user: { __typename?: 'User'; id: string; name: string };
              recording?: { __typename?: 'Recording'; id: string; playbackURL?: string | null } | null;
            }>;
            voicemails: Array<{
              __typename?: 'Voicemail';
              id: string;
              recording: { __typename?: 'Recording'; id: string; playbackURL?: string | null };
            }>;
            phone: { __typename?: 'Phone'; id: string; number: string };
          }
        | {
            __typename: 'Subscribe';
            id: string;
            user: { __typename?: 'User'; id: string; name: string };
            order: { __typename?: 'Order'; id: string; accountID: string; number: string };
            adjustments: Array<{
              __typename?: 'Adjustment';
              id: string;
              kind: string;
              subscription: {
                __typename?: 'Subscription';
                id: string;
                name: string;
                quantity: number;
                pricing: { __typename?: 'Pricing'; id: string; amount: number };
              };
            }>;
          }
        | { __typename: 'Usage'; id: string; cuft: number; user: { __typename?: 'User'; id: string; name: string } };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type PhoneCallPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PhoneCallPolicyQuery = {
  __typename?: 'Query';
  phoneCallPolicy: { __typename?: 'PhoneCallPolicy'; download: boolean };
};

export type HrsLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type HrsLocationsQuery = {
  __typename?: 'Query';
  hrsLocations: Array<{
    __typename?: 'HRS__Location';
    id: string;
    code: string;
    region?: { __typename?: 'Region'; id: string; name: string } | null;
  }>;
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
};

export type HrsLocationUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__LocationInput;
}>;

export type HrsLocationUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'HRS__LocationUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'HRS__Location';
      id: string;
      code: string;
      region?: { __typename?: 'Region'; id: string; name: string } | null;
    } | null;
  } | null;
};

export type HrsProfileDefaultsQueryVariables = Exact<{ [key: string]: never }>;

export type HrsProfileDefaultsQuery = {
  __typename?: 'Query';
  hrsBusinessTitles: Array<string>;
  hrsLocations: Array<{ __typename?: 'HRS__Location'; id: string; code: string }>;
  hrsProfileDefaults: Array<{
    __typename?: 'HRS__ProfileDefault';
    id: string;
    businessTitle: string;
    orgLevel1?: string | null;
    orgLevel2?: string | null;
    orgLevel3?: string | null;
    orgLevel4?: string | null;
    location?: { __typename?: 'HRS__Location'; id: string; code: string } | null;
    team?: { __typename?: 'Team'; id: string; name: string } | null;
  }>;
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type HrsProfileDefaultUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__ProfileDefaultInput;
}>;

export type HrsProfileDefaultUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'HRS__ProfileDefaultUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'HRS__ProfileDefault';
      id: string;
      businessTitle: string;
      orgLevel1?: string | null;
      orgLevel2?: string | null;
      orgLevel3?: string | null;
      orgLevel4?: string | null;
      location?: { __typename?: 'HRS__Location'; id: string; code: string } | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
    } | null;
  } | null;
};

export type HrsRoleDefaultsQueryVariables = Exact<{ [key: string]: never }>;

export type HrsRoleDefaultsQuery = {
  __typename?: 'Query';
  hrsBusinessTitles: Array<string>;
  hrsRoleDefaults: Array<{
    __typename?: 'HRS__RoleDefault';
    id: string;
    businessTitle: string;
    roles: Array<User__Role>;
  }>;
};

export type HrsRoleDefaultUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Hrs__RoleDefaultInput;
}>;

export type HrsRoleDefaultUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'HRS__RoleDefaultUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: { __typename?: 'HRS__RoleDefault'; id: string; businessTitle: string; roles: Array<User__Role> } | null;
  } | null;
};

export type IngestionUploadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IngestionUploadQuery = {
  __typename?: 'Query';
  upload: {
    __typename?: 'Ingestion__Upload';
    id: string;
    description: string;
    state: Ingestion__Upload__State;
    error?: string | null;
    processingAt?: string | null;
    processedAt?: string | null;
    user: { __typename?: 'User'; id: string; name: string };
    spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
  };
};

export type IngestionUploadSaveMutationVariables = Exact<{
  input: Ingestion__UploadInput;
}>;

export type IngestionUploadSaveMutation = {
  __typename?: 'Mutation';
  save: {
    __typename?: 'Ingestion__Upload__SavePayload';
    upload?: {
      __typename?: 'Ingestion__Upload';
      id: string;
      description: string;
      state: Ingestion__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      user: { __typename?: 'User'; id: string; name: string };
      spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
    } | null;
  };
};

export type PaginatedIngestionUploadsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedIngestionUploadsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Ingestion__PaginatedUpload';
    results: Array<{
      __typename?: 'Ingestion__Upload';
      id: string;
      description: string;
      state: Ingestion__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      user: { __typename?: 'User'; id: string; name: string };
      spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ItemExceptionsQueryVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type ItemExceptionsQuery = {
  __typename?: 'Query';
  itemExceptions: Array<{
    __typename?: 'ItemException';
    id: string;
    reason: string;
    explanation?: string | null;
    createdAt: string;
    deletedAt?: string | null;
    creator: { __typename?: 'User'; name: string };
    image?: { __typename?: 'Image'; id: string; size: string; source: string } | null;
  }>;
};

export type ItemGroupPanelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ItemGroupPanelQuery = {
  __typename?: 'Query';
  itemGroup: {
    __typename?: 'ItemGroup';
    id: string;
    uuid: string;
    sizingSelection?: string | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    category: { __typename?: 'ItemCategory'; id: string; name: string };
    additionalImages: Array<{ __typename?: 'Image'; id: string; createdAt: string; source: string; size: string }>;
    heroImage: { __typename?: 'Image'; id: string; createdAt: string; source: string; size: string };
  };
};

export type NonExistentPartsItemGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NonExistentPartsItemGroupQuery = {
  __typename?: 'Query';
  itemGroup: {
    __typename?: 'ItemGroup';
    id: string;
    partialItems: Array<{
      __typename?: 'PartialItem';
      id: string;
      state: PartialItemStateEnum;
      accountID: string;
      category?: {
        __typename?: 'ItemCategory';
        id: string;
        name: string;
        sizingMetric?: { __typename?: 'SizingMetric'; id: string; unit?: string | null } | null;
      } | null;
      partCategory?: { __typename?: 'PartCategory'; id: string; name: string } | null;
      materialCategory?: { __typename?: 'MaterialCategory'; id: string; name: string } | null;
      includedParts: Array<{
        __typename?: 'IncludedPart';
        id: string;
        category:
          | { __typename: 'ItemCategory'; id: string; name: string }
          | { __typename: 'MaterialCategory'; id: string; name: string }
          | { __typename: 'PartCategory'; id: string; name: string };
      }>;
    }>;
  };
};

export type ItemActivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ItemActivateMutation = {
  __typename?: 'Mutation';
  itemActivate: { __typename?: 'ItemActivatePayload'; item: { __typename?: 'Item'; id: string; active: boolean } };
};

export type ItemByBarcodeQueryVariables = Exact<{
  accountID: Scalars['ID'];
  barcode: Scalars['String'];
}>;

export type ItemByBarcodeQuery = {
  __typename?: 'Query';
  item?: {
    __typename?: 'Item';
    id: string;
    uuid?: string | null;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    barcode: { __typename?: 'Barcode'; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  } | null;
};

export type ItemByIdQueryVariables = Exact<{
  accountID: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type ItemByIdQuery = {
  __typename?: 'Query';
  item?: {
    __typename?: 'Item';
    id: string;
    uuid?: string | null;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    barcode: { __typename?: 'Barcode'; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  } | null;
};

export type ItemDeactivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ItemDeactivateMutation = {
  __typename?: 'Mutation';
  itemDeactivate: { __typename?: 'ItemDeactivatePayload'; item: { __typename?: 'Item'; id: string; active: boolean } };
};

export type ItemDetailsQueryVariables = Exact<{
  accountID: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type ItemDetailsQuery = {
  __typename?: 'Query';
  item?: {
    __typename?: 'Item';
    id: string;
    active: boolean;
    state: ItemStateEnum;
    name?: string | null;
    contents?: string | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    account: {
      __typename?: 'Account';
      id: string;
      number: string;
      customer: { __typename?: 'Customer'; id: string; name: string };
    };
  } | null;
};

export type ItemManualReturnMutationVariables = Exact<{
  itemID: Scalars['ID'];
  orderID: Scalars['ID'];
}>;

export type ItemManualReturnMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ItemManualReturnPayload'; item: { __typename?: 'Item'; id: string; state: ItemStateEnum } };
};

export type FormattedAccountLaborAgreementQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;

export type FormattedAccountLaborAgreementQuery = {
  __typename?: 'Query';
  formattedAccountLaborAgreement: Array<string>;
};

export type FormattedLaborAgreementQueryVariables = Exact<{
  pricingSetId: Scalars['ID'];
  rateGroupId: Scalars['ID'];
  cuft: Scalars['Float'];
  orderZip: Scalars['String'];
  laborRateId?: InputMaybe<Scalars['ID']>;
  maxLaborAmount?: InputMaybe<Scalars['Float']>;
  packageSetEntryIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type FormattedLaborAgreementQuery = { __typename?: 'Query'; formattedLaborAgreement: Array<string> };

export type LandingLocationCityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LandingLocationCityQuery = {
  __typename?: 'Query';
  city: {
    __typename?: 'Landing__Location__City';
    id: string;
    globalID: string;
    name: string;
    slug: string;
    latitude: number;
    longitude: number;
    region: { __typename?: 'Region'; id: string; name: string };
    state: { __typename?: 'Landing__Location__State'; id: string; name: string };
    contents: Array<{
      __typename?: 'Landing__Location__Content';
      id: string;
      service: Landing__Location__Service;
      blurb: string;
      reviews: Array<{ __typename?: 'Landing__Location__Review'; id: string; author: string; message: string }>;
    }>;
  };
};

export type LandingLocationCityUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__CityInput;
}>;

export type LandingLocationCityUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Landing__Location__CityUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'Landing__Location__City';
      id: string;
      globalID: string;
      name: string;
      slug: string;
      latitude: number;
      longitude: number;
      region: { __typename?: 'Region'; id: string; name: string };
      state: { __typename?: 'Landing__Location__State'; id: string; name: string };
      contents: Array<{
        __typename?: 'Landing__Location__Content';
        id: string;
        service: Landing__Location__Service;
        blurb: string;
        reviews: Array<{ __typename?: 'Landing__Location__Review'; id: string; author: string; message: string }>;
      }>;
    } | null;
  } | null;
};

export type LandingLocationStatesQueryVariables = Exact<{ [key: string]: never }>;

export type LandingLocationStatesQuery = {
  __typename?: 'Query';
  states: Array<{ __typename?: 'Landing__Location__State'; id: string; name: string }>;
};

export type PaginatedLandingLocationCitiesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLandingLocationCitiesQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Landing__Location__PaginatedCity';
    results: Array<{ __typename?: 'Landing__Location__City'; id: string; name: string; slug: string }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type LandingLocationContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LandingLocationContentQuery = {
  __typename?: 'Query';
  content: {
    __typename?: 'Landing__Location__Content';
    id: string;
    service: Landing__Location__Service;
    title?: string | null;
    headline?: string | null;
    description?: string | null;
    blurb: string;
    heroAlt?: string | null;
    heroImage?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string } | null;
    reviews: Array<{
      __typename?: 'Landing__Location__Review';
      id: string;
      author: string;
      message: string;
      rating: number;
    }>;
  };
};

export type LandingLocationContentUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__ContentInput;
}>;

export type LandingLocationContentUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Landing__Location__ContentUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'Landing__Location__Content';
      id: string;
      service: Landing__Location__Service;
      title?: string | null;
      description?: string | null;
      blurb: string;
      heroImage?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string } | null;
      reviews: Array<{
        __typename?: 'Landing__Location__Review';
        id: string;
        author: string;
        message: string;
        rating: number;
      }>;
    } | null;
  } | null;
};

export type LandingLocationCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type LandingLocationCitiesQuery = {
  __typename?: 'Query';
  cities: Array<{ __typename?: 'Landing__Location__City'; id: string; name: string }>;
};

export type LandingLocationNeighborhoodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LandingLocationNeighborhoodQuery = {
  __typename?: 'Query';
  neighborhood: {
    __typename?: 'Landing__Location__Neighborhood';
    id: string;
    globalID: string;
    name: string;
    slug: string;
    latitude: number;
    longitude: number;
    city: { __typename?: 'Landing__Location__City'; id: string; name: string };
    contents: Array<{
      __typename?: 'Landing__Location__Content';
      id: string;
      service: Landing__Location__Service;
      blurb: string;
    }>;
  };
};

export type LandingLocationNeighborhoodUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__NeighborhoodInput;
}>;

export type LandingLocationNeighborhoodUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Landing__Location__NeighborhoodUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'Landing__Location__Neighborhood';
      id: string;
      globalID: string;
      name: string;
      slug: string;
      latitude: number;
      longitude: number;
      city: { __typename?: 'Landing__Location__City'; id: string; name: string };
      contents: Array<{
        __typename?: 'Landing__Location__Content';
        id: string;
        service: Landing__Location__Service;
        blurb: string;
        reviews: Array<{ __typename?: 'Landing__Location__Review'; id: string; author: string; message: string }>;
      }>;
    } | null;
  } | null;
};

export type PaginatedLandingLocationNeighborhoodsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLandingLocationNeighborhoodsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Landing__Location__PaginatedNeighborhood';
    results: Array<{ __typename?: 'Landing__Location__Neighborhood'; id: string; name: string; slug: string }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type LandingLocationRouteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LandingLocationRouteQuery = {
  __typename?: 'Query';
  route: {
    __typename?: 'Landing__Location__Route';
    id: string;
    globalID: string;
    slug: string;
    featured: boolean;
    contents: Array<{
      __typename?: 'Landing__Location__Content';
      id: string;
      service: Landing__Location__Service;
      blurb: string;
      reviews: Array<{ __typename?: 'Landing__Location__Review'; id: string; author: string; message: string }>;
    }>;
    origin:
      | { __typename?: 'Landing__Location__City'; id: string; name: string }
      | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
      | { __typename?: 'Landing__Location__State'; id: string; name: string };
    destination:
      | { __typename?: 'Landing__Location__City'; id: string; name: string }
      | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
      | { __typename?: 'Landing__Location__State'; id: string; name: string };
  };
};

export type LandingLocationRouteUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__RouteInput;
}>;

export type LandingLocationRouteUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Landing__Location__RouteUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'Landing__Location__Route';
      id: string;
      globalID: string;
      slug: string;
      origin:
        | { __typename?: 'Landing__Location__City'; id: string; name: string }
        | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
        | { __typename?: 'Landing__Location__State'; id: string; name: string };
      destination:
        | { __typename?: 'Landing__Location__City'; id: string; name: string }
        | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
        | { __typename?: 'Landing__Location__State'; id: string; name: string };
    } | null;
  } | null;
};

export type PaginatedLandingLocationRoutesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLandingLocationRoutesQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Landing__Location__PaginatedRoute';
    results: Array<{
      __typename?: 'Landing__Location__Route';
      id: string;
      slug: string;
      featured: boolean;
      origin:
        | { __typename?: 'Landing__Location__City'; id: string; name: string }
        | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
        | { __typename?: 'Landing__Location__State'; id: string; name: string };
      destination:
        | { __typename?: 'Landing__Location__City'; id: string; name: string }
        | { __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }
        | { __typename?: 'Landing__Location__State'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type RouteLocationOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type RouteLocationOptionsQuery = {
  __typename?: 'Query';
  states: Array<{
    __typename?: 'Landing__Location__State';
    id: string;
    name: string;
    cities: Array<{
      __typename?: 'Landing__Location__City';
      id: string;
      name: string;
      neighborhoods: Array<{ __typename?: 'Landing__Location__Neighborhood'; id: string; name: string }>;
    }>;
  }>;
};

export type LandingLocationStateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LandingLocationStateQuery = {
  __typename?: 'Query';
  state: {
    __typename?: 'Landing__Location__State';
    id: string;
    name: string;
    slug: string;
    code: string;
    country: Landing__Location__Country;
  };
};

export type LandingLocationStateUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Landing__Location__StateInput;
}>;

export type LandingLocationStateUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Landing__Location__StateUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'Landing__Location__State';
      id: string;
      name: string;
      slug: string;
      code: string;
      country: Landing__Location__Country;
    } | null;
  } | null;
};

export type PaginatedLandingLocationStatesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
}>;

export type PaginatedLandingLocationStatesQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Landing__Location__PaginatedState';
    results: Array<{
      __typename?: 'Landing__Location__State';
      id: string;
      name: string;
      slug: string;
      code: string;
      country: Landing__Location__Country;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type CallGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type CallGroupsQuery = {
  __typename?: 'Query';
  groups: Array<{ __typename?: 'CallGroup'; id: string; name: string }>;
};

export type LeadCloseCrmLinkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LeadCloseCrmLinkQuery = {
  __typename?: 'Query';
  lead: { __typename?: 'Lead'; id: string; closeCRMLink?: string | null };
};

export type LeadCreateMutationVariables = Exact<{
  input: Crm__LeadInput;
}>;

export type LeadCreateMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'LeadCreatePayload';
    status: Status;
    error?: string | null;
    lead?: { __typename?: 'Lead'; id: string } | null;
  } | null;
};

export type PaginatedLeadsQueryVariables = Exact<{
  page: Scalars['Int'];
  filters: LeadFiltersInput;
}>;

export type PaginatedLeadsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedLead';
    results: Array<{
      __typename?: 'Lead';
      id: string;
      name?: string | null;
      email?: string | null;
      closeLeadID?: string | null;
      status: Lead__Status;
      zip?: string | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type PalletTasksQueryVariables = Exact<{
  destinationLocationID: Scalars['ID'];
}>;

export type PalletTasksQuery = {
  __typename?: 'Query';
  palletTasks?: Array<{
    __typename?: 'PalletTask';
    id: string;
    completedAt?: string | null;
    canceledAt?: string | null;
    user?: { __typename?: 'User'; name: string } | null;
    image?: { __typename?: 'Image'; id: string; size: string; source: string } | null;
  }> | null;
};

export type BuildLogisticsAccountMutationVariables = Exact<{
  input: Logistics__AccountInput;
}>;

export type BuildLogisticsAccountMutation = {
  __typename?: 'Mutation';
  buildLogisticsAccount: {
    __typename?: 'Logistics__BuildAccountPayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type CancelLogisticsLoadMutationVariables = Exact<{
  input: Logistics__CancelLoadInput;
}>;

export type CancelLogisticsLoadMutation = {
  __typename?: 'Mutation';
  cancelLogisticsLoad: { __typename?: 'Logistics__CancelLoadPayload'; status: Status; error?: string | null };
};

export type LogisticsLoadInventoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsLoadInventoryQuery = {
  __typename?: 'Query';
  palletInventory: Array<{
    __typename?: 'Pallet';
    id: string;
    state: PalletStateEnum;
    itemsCount: number;
    skuValues: Array<string>;
    isDamaged: boolean;
    height?: { __typename?: 'PalletHeight'; value: number } | null;
    type: { __typename?: 'PalletType'; id: string; name: string };
    barcode: { __typename?: 'Barcode'; value: string };
    grade?: {
      __typename?: 'PalletGrade';
      id: string;
      image?: { __typename?: 'Image'; source: string; size: string } | null;
    } | null;
    location?: { __typename?: 'Location'; id: string; value: string } | null;
    warehouse: { __typename?: 'Warehouse'; id: string; name: string };
  }>;
};

export type ReopenLogisticsLoadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReopenLogisticsLoadMutation = {
  __typename?: 'Mutation';
  reopenLogisticsLoad: { __typename?: 'Logistics__ReopenLoadPayload'; status: Status; error?: string | null };
};

export type LogisticsSkusQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type LogisticsSkusQuery = {
  __typename?: 'Query';
  logisticsSkus: Array<{
    __typename?: 'Logistics__Sku';
    id: string;
    value: string;
    account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; name: string } };
  }>;
};

export type LogisticsLoadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsLoadQuery = {
  __typename?: 'Query';
  load: {
    __typename?: 'Logistics__Load';
    itemsCount: number;
    id: string;
    type: LoadTypeEnum;
    state: string;
    number: string;
    mustShipBy?: string | null;
    appointmentDate?: string | null;
    expectedOrders: number;
    expectedPallets: number;
    expectedItems: number;
    trailerNumber?: string | null;
    freightChargeTerms: string;
    mode: string;
    billOfLadingNumber?: string | null;
    handlingMethod?: string | null;
    specialInstructions?: string | null;
    itemLabelsRequired: boolean;
    palletLabelsRequired: boolean;
    digitalBolSignatureRequired: boolean;
    trailerSealRequired: boolean;
    requestedAppointmentDate: string;
    requestedAppointmentWindow: Logistics__RequestedAppointmentWindowEnum;
    drop: boolean;
    releaseNumber?: string | null;
    reference1?: string | null;
    reference2?: string | null;
    reference3?: string | null;
    reference4?: string | null;
    reference5?: string | null;
    targetPalletHeight?: number | null;
    source: Logistics__LoadSourceEnum;
    palletHeightPreference?: Logistics__PalletHeightPreferenceEnum | null;
    shipTo?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      businessName?: string | null;
    } | null;
    billTo?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      businessName?: string | null;
    } | null;
    openTrailerImages: Array<{
      __typename?: 'Image';
      id: string;
      size: string;
      source: string;
      state: ImageState;
      name?: string | null;
      createdAt: string;
    }>;
    fullTrailerImages: Array<{
      __typename?: 'Image';
      id: string;
      size: string;
      source: string;
      state: ImageState;
      name?: string | null;
      createdAt: string;
    }>;
    trailerNumberImage?: {
      __typename?: 'Image';
      id: string;
      size: string;
      source: string;
      state: ImageState;
      name?: string | null;
      createdAt: string;
    } | null;
    sealCodeImage?: {
      __typename?: 'Image';
      id: string;
      size: string;
      source: string;
      state: ImageState;
      name?: string | null;
      createdAt: string;
    } | null;
    sealedTrailerImage?: {
      __typename?: 'Image';
      id: string;
      size: string;
      source: string;
      state: ImageState;
      name?: string | null;
      createdAt: string;
    } | null;
    fulfillmentExpectations: Array<{
      __typename?: 'Logistics__FulfillmentExpectation';
      id: string;
      quantity: number;
      ingestedCount: number;
      loadedCount: number;
      preparedCount: number;
      damagedCount: number;
      sku: {
        __typename?: 'Logistics__Sku';
        id: string;
        value: string;
        itemsPerPallet?: number | null;
        requiresTeachIn: boolean;
        countingUnit: Logistics__Sku__CountingUnitEnum;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string };
        };
      };
      purchaseOrder?: {
        __typename?: 'Logistics__PurchaseOrder';
        id: string;
        number: string;
        mustShipBy?: string | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string };
        };
        quantities: Array<{
          __typename?: 'PurchaseOrderQuantities';
          loadId: string;
          itemCount: number;
          palletCount: number;
        }>;
      } | null;
      palletLabels?: Array<{ __typename?: 'Logistics__ShippingLabel'; id: string; itemsPerPallet: number }> | null;
    }>;
    carrierDetails?: {
      __typename?: 'Logistics__CarrierDetail';
      id: string;
      transportationArrangedBy?: Logistics__TransportationArrangedByEnum | null;
      businessName?: string | null;
      contactName?: string | null;
      contactPhone?: string | null;
      contactEmail?: string | null;
      scac?: string | null;
    } | null;
    warehouse: { __typename?: 'Warehouse'; id: string; name: string; tz: string };
    purchaseOrders: Array<{
      __typename?: 'Logistics__PurchaseOrder';
      id: string;
      number: string;
      company?: string | null;
      mustShipBy?: string | null;
      quantities: Array<{
        __typename?: 'PurchaseOrderQuantities';
        loadId: string;
        skuValue: string;
        palletCount: number;
        itemCount: number;
        skuID: string;
      }>;
    }>;
  };
};

export type DuplicateLoadNumbersQueryVariables = Exact<{
  loadNumber: Scalars['String'];
}>;

export type DuplicateLoadNumbersQuery = {
  __typename?: 'Query';
  duplicateLoadNumbers: Array<{ __typename?: 'Logistics__Load'; id: string; number: string }>;
};

export type ModifyLogisticsLoadMutationVariables = Exact<{
  input: Logistics__LoadInputType;
}>;

export type ModifyLogisticsLoadMutation = {
  __typename?: 'Mutation';
  modifyLogisticsLoad: {
    __typename?: 'Logistics__ModifyLoadPayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type InsufficientSkuInventoryQueryVariables = Exact<{
  input: Logistics__InsufficientSkuInventoryInput;
}>;

export type InsufficientSkuInventoryQuery = {
  __typename?: 'Query';
  insufficientSkuInventory: Array<{
    __typename?: 'Logistics__InsufficientSKUInventoryType';
    id: string;
    value: string;
    storedCount: number;
    upcomingLoadsNeedingSku: Array<{
      __typename?: 'Logistics__Load';
      id: string;
      number: string;
      fulfillmentExpectations: Array<{
        __typename?: 'Logistics__FulfillmentExpectation';
        id: string;
        quantity: number;
        sku: { __typename?: 'Logistics__Sku'; id: string };
      }>;
    }>;
  }>;
};

export type InsufficientSkuTruckCuftQueryVariables = Exact<{
  input: Logistics__InsufficientSkuTruckCuftInput;
}>;

export type InsufficientSkuTruckCuftQuery = {
  __typename?: 'Query';
  insufficientSkuTruckCuft: {
    __typename?: 'Logistics__InsufficientSKUTruckCuftType';
    validForKnownDimensions: boolean;
    skusWithoutDimensions: Array<{ __typename?: 'Logistics__Sku'; id: string; value: string }>;
    suggestedLoads: Array<{
      __typename?: 'Logistics__InsufficientSKULoadSuggestionType';
      suggestedExpectations: Array<{
        __typename?: 'Logistics__InsufficientSKUExpectationSuggestionType';
        quantity: number;
        sku: { __typename?: 'Logistics__Sku'; id: string; value: string };
      }>;
    }>;
  };
};

export type BuildLogisticsLoadMutationVariables = Exact<{
  input: Logistics__LoadInputType;
}>;

export type BuildLogisticsLoadMutation = {
  __typename?: 'Mutation';
  buildLogisticsLoad: {
    __typename?: 'Logistics__BuildLoadPayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type OptionsQueryVariables = Exact<{ [key: string]: never }>;

export type OptionsQuery = {
  __typename?: 'Query';
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string; active: boolean; tz: string }>;
  logisticsPurchaseOrders: Array<{
    __typename?: 'Logistics__PurchaseOrder';
    id: string;
    number: string;
    account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
  }>;
  logisticsAccounts: Array<{
    __typename?: 'Account';
    id: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
  }>;
};

export type LogisticsSkusForAccountQueryVariables = Exact<{
  accountID?: InputMaybe<Scalars['ID']>;
}>;

export type LogisticsSkusForAccountQuery = {
  __typename?: 'Query';
  logisticsSkus: Array<{
    __typename?: 'Logistics__Sku';
    id: string;
    value: string;
    countingUnit: Logistics__Sku__CountingUnitEnum;
  }>;
};

export type PaginatedLoadQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Logistics__LoadsFilterInputType>;
}>;

export type PaginatedLoadQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Logistics__PaginatedLoadListType';
    results: Array<{
      __typename?: 'Logistics__Load';
      id: string;
      type: LoadTypeEnum;
      state: string;
      number: string;
      appointmentDate?: string | null;
      requestedAppointmentDate: string;
      requestedAppointmentWindow: Logistics__RequestedAppointmentWindowEnum;
      requiresTeachIn: boolean;
      warehouse: { __typename?: 'Warehouse'; id: string; name: string; tz: string };
      fulfillmentExpectations: Array<{
        __typename?: 'Logistics__FulfillmentExpectation';
        id: string;
        quantity: number;
        sku: {
          __typename?: 'Logistics__Sku';
          id: string;
          value: string;
          account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; name: string } };
        };
      }>;
      carrierDetails?: { __typename?: 'Logistics__CarrierDetail'; id: string; businessName?: string | null } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type WarehousesQueryVariables = Exact<{ [key: string]: never }>;

export type WarehousesQuery = {
  __typename?: 'Query';
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string; active: boolean }>;
};

export type LogisticsPalletInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsPalletInfoQuery = {
  __typename?: 'Query';
  pallet: {
    __typename?: 'Pallet';
    id: string;
    palletInventories: Array<{
      __typename?: 'Logistics__PalletInventory';
      quantity: number;
      sku: {
        __typename?: 'Logistics__Sku';
        id: string;
        value: string;
        description?: string | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string };
        };
      };
    }>;
    loads?: Array<{
      __typename?: 'Logistics__Load';
      id: string;
      number: string;
      type: LoadTypeEnum;
      appointmentDate?: string | null;
      warehouse: { __typename?: 'Warehouse'; id: string; tz: string };
    }> | null;
  };
};

export type BuildParcelCarrierAccountMutationVariables = Exact<{
  input: Logistics__ParcelCarrierAccountInput;
}>;

export type BuildParcelCarrierAccountMutation = {
  __typename?: 'Mutation';
  buildParcelCarrierAccount: {
    __typename?: 'Logistics__BuildParcelCarrierAccountPayload';
    status: Status;
    error?: string | null;
    parcelCarrierAccount?: {
      __typename?: 'Logistics__ParcelCarrierAccount';
      id: string;
      carrier: Logistics__ParcelCarrierEnum;
      accountNumber: string;
      country: string;
      postalCode: string;
    } | null;
  };
};

export type ModifyParcelCarrierAccountMutationVariables = Exact<{
  input: Logistics__ParcelCarrierAccountInput;
}>;

export type ModifyParcelCarrierAccountMutation = {
  __typename?: 'Mutation';
  modifyParcelCarrierAccount: {
    __typename?: 'Logistics__ModifyParcelCarrierAccountPayload';
    status: Status;
    error?: string | null;
    parcelCarrierAccount?: {
      __typename?: 'Logistics__ParcelCarrierAccount';
      id: string;
      carrier: Logistics__ParcelCarrierEnum;
      accountNumber: string;
      country: string;
      postalCode: string;
    } | null;
  };
};

export type ParcelCarrierAccountsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type ParcelCarrierAccountsQuery = {
  __typename?: 'Query';
  parcelCarrierAccounts: Array<{
    __typename?: 'Logistics__ParcelCarrierAccount';
    id: string;
    carrier: Logistics__ParcelCarrierEnum;
    accountNumber: string;
    country: string;
    postalCode: string;
  }>;
};

export type AccountBreadCrumbsQueryQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountBreadCrumbsQueryQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
  };
};

export type ModifyLogisticsPurchaseOrderMutationVariables = Exact<{
  input: Logistics__PurchaseOrderInput;
}>;

export type ModifyLogisticsPurchaseOrderMutation = {
  __typename?: 'Mutation';
  modifyLogisticsPurchaseOrder: {
    __typename?: 'Logistics__ModifyPurchaseOrderPayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type BuildLogisticsPurchaseOrderMutationVariables = Exact<{
  input: Logistics__PurchaseOrderInput;
}>;

export type BuildLogisticsPurchaseOrderMutation = {
  __typename?: 'Mutation';
  buildLogisticsPurchaseOrder: {
    __typename?: 'Logistics__BuildPurchaseOrderPayload';
    status: Status;
    error?: string | null;
    id?: string | null;
  };
};

export type LogisticsPOsForPanelQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  accountID: Scalars['ID'];
}>;

export type LogisticsPOsForPanelQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Logistics__PaginatedPurchaseOrder';
    results: Array<{ __typename?: 'Logistics__PurchaseOrder'; id: string; number: string; createdAt: string }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type LogisticsPurchaseOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsPurchaseOrderQuery = {
  __typename?: 'Query';
  purchaseOrder: {
    __typename?: 'Logistics__PurchaseOrder';
    id: string;
    number: string;
    fulfillmentMethod: Logistics__FulfillmentMethodTypeEnum;
    phoneNumber?: string | null;
    shipTo?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      businessName?: string | null;
    } | null;
    fulfillmentExpectations: Array<{
      __typename?: 'Logistics__FulfillmentExpectation';
      id: string;
      quantity: number;
      sku: { __typename?: 'Logistics__Sku'; id: string; value: string };
    }>;
  };
};

export type LogisticsPurchaseOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type LogisticsPurchaseOrdersQuery = {
  __typename?: 'Query';
  logisticsPurchaseOrders: Array<{
    __typename?: 'Logistics__PurchaseOrder';
    id: string;
    number: string;
    orderDate?: string | null;
    mustShipBy?: string | null;
    additionalInfo?: string | null;
    account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
    fulfillmentExpectations: Array<{
      __typename?: 'Logistics__FulfillmentExpectation';
      quantity: number;
      sku: { __typename?: 'Logistics__Sku'; id: string; value: string };
    }>;
  }>;
};

export type SkuBulkUploadMutationVariables = Exact<{
  input: Logistics__Sku__BulkUploadInput;
}>;

export type SkuBulkUploadMutation = {
  __typename?: 'Mutation';
  skuBulkUpload: { __typename?: 'Logistics__Sku__BulkUploadPayload'; status: Status; error?: string | null };
};

export type LogisticsSkuBulkUploadPreviewQueryVariables = Exact<{
  input: Logistics__Sku__BulkUploadPreviewInput;
}>;

export type LogisticsSkuBulkUploadPreviewQuery = {
  __typename?: 'Query';
  preview:
    | { __typename?: 'Logistics__Sku__BulkUploadPreview__Error'; message: string }
    | {
        __typename?: 'Logistics__Sku__BulkUploadPreview__Preview';
        skus: Array<{
          __typename?: 'Logistics__Sku__BulkUploadPreview__Sku';
          id?: string | null;
          value: string;
          description?: string | null;
          nmfcNumber?: string | null;
          nmfcClass?: string | null;
          teachInStatus: Logistics__SkuTeachInStatusEnum;
          vbwOptOutReason?: Logistics__Sku__VbwOptOutReasonEnum | null;
          gtinBarcode?: string | null;
          weightPerPallet?: number | null;
          itemWeight?: number | null;
          itemLength?: number | null;
          itemWidth?: number | null;
          itemHeight?: number | null;
          itemsPerPallet?: number | null;
          deletedAt?: string | null;
          accountID: string;
          defaultPalletTypeID?: string | null;
          defaultPalletHeightID?: string | null;
          hasBarcode: boolean;
          verified: boolean;
          vbwEligible: boolean;
          countingUnit: Logistics__Sku__CountingUnitEnum;
        }>;
      };
};

export type LogisticsDetailedSkuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsDetailedSkuQuery = {
  __typename?: 'Query';
  logisticsSku: {
    __typename?: 'Logistics__Sku';
    id: string;
    accountID: string;
    value: string;
    description?: string | null;
    hasBarcode: boolean;
    defaultPalletTypeId?: string | null;
    itemsPerPallet?: number | null;
    weightPerPallet?: number | null;
    gtinBarcode?: string | null;
    nmfcClass?: string | null;
    nmfcNumber?: string | null;
    itemWeight?: number | null;
    itemLength?: number | null;
    itemWidth?: number | null;
    itemHeight?: number | null;
    teachInStatus: Logistics__SkuTeachInStatusEnum;
    vbwEligible: boolean;
    vbwOptOutReason?: Logistics__Sku__VbwOptOutReasonEnum | null;
    verified: boolean;
    deletedAt?: string | null;
    countingUnit: Logistics__Sku__CountingUnitEnum;
    account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
    defaultPalletHeight?: { __typename?: 'PalletHeight'; value: number; unit: PalletHeightUnitEnum } | null;
    measuredWeightImage?: {
      __typename?: 'DetailedImage';
      id: string;
      size: string;
      createdAt: string;
      source: string;
      name?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    verificationImages: Array<{
      __typename?: 'DetailedImage';
      id: string;
      size: string;
      createdAt: string;
      source: string;
      name?: string | null;
      user?: { __typename?: 'User'; id: string; name: string } | null;
    }>;
  };
};

export type ModifyLogisticsSkuMutationVariables = Exact<{
  input: Logistics__ModifySkuInput;
}>;

export type ModifyLogisticsSkuMutation = {
  __typename?: 'Mutation';
  modifyLogisticsSku: {
    __typename?: 'Logistics__ModifySkuPayload';
    status: Status;
    error?: string | null;
    sku?: { __typename?: 'Logistics__Sku'; id: string } | null;
  };
};

export type BuildLogisticsSkuMutationVariables = Exact<{
  input: Logistics__BuildSkuInput;
}>;

export type BuildLogisticsSkuMutation = {
  __typename?: 'Mutation';
  buildLogisticsSku: {
    __typename?: 'Logistics__BuildSkuPayload';
    status: Status;
    error?: string | null;
    sku?: { __typename?: 'Logistics__Sku'; id: string } | null;
  };
};

export type LogisticsSkuFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type LogisticsSkuFormOptionsQuery = {
  __typename?: 'Query';
  palletTypes: Array<{
    __typename?: 'PalletType';
    id: string;
    name: string;
    legacySize: PalletSizeEnum;
    active: boolean;
    position: number;
  }>;
};

export type ReteachLogisticsSkuMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReteachLogisticsSkuMutation = {
  __typename?: 'Mutation';
  reteachLogisticsSku: { __typename?: 'Logistics__ReteachSkuPayload'; status: Status; error?: string | null };
};

export type SkuDeleteMutationVariables = Exact<{
  skuID: Scalars['ID'];
}>;

export type SkuDeleteMutation = {
  __typename?: 'Mutation';
  skuDelete: {
    __typename?: 'Logistics__SkuDeletePayload';
    status: Status;
    error?: string | null;
    sku?: { __typename?: 'Logistics__Sku'; id: string; deletedAt?: string | null } | null;
  };
};

export type LogisticsSkuForEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LogisticsSkuForEditQuery = {
  __typename?: 'Query';
  logisticsSku: {
    __typename?: 'Logistics__Sku';
    id: string;
    accountID: string;
    value: string;
    description?: string | null;
    hasBarcode: boolean;
    defaultPalletTypeId?: string | null;
    itemsPerPallet?: number | null;
    weightPerPallet?: number | null;
    gtinBarcode?: string | null;
    nmfcClass?: string | null;
    nmfcNumber?: string | null;
    itemWeight?: number | null;
    itemLength?: number | null;
    itemWidth?: number | null;
    itemHeight?: number | null;
    teachInStatus: Logistics__SkuTeachInStatusEnum;
    vbwEligible: boolean;
    vbwOptOutReason?: Logistics__Sku__VbwOptOutReasonEnum | null;
    countingUnit: Logistics__Sku__CountingUnitEnum;
    defaultPalletHeight?: { __typename?: 'PalletHeight'; value: number; unit: PalletHeightUnitEnum } | null;
  };
};

export type SkuMergeMutationVariables = Exact<{
  skuID: Scalars['ID'];
  duplicateSkuID: Scalars['ID'];
}>;

export type SkuMergeMutation = {
  __typename?: 'Mutation';
  skuMerge: { __typename?: 'Logistics__SkuMergePayload'; status: Status; error?: string | null };
};

export type LogisticsSkusForPanelQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  accountID: Scalars['ID'];
}>;

export type LogisticsSkusForPanelQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Logistics__PaginatedSku';
    results: Array<{
      __typename?: 'Logistics__Sku';
      id: string;
      value: string;
      description?: string | null;
      hasBarcode: boolean;
      requiresTeachIn: boolean;
      teachInStatus: Logistics__SkuTeachInStatusEnum;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type MovingAppointmentDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingAppointmentDetailsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    estimatedDuration?: number | null;
    estimatedCuft: number;
    accountPackages?: Array<{
      __typename?: 'AccountPackage';
      name: string;
      kind: PackageKindEnum;
      packageSetEntryId: string;
    }> | null;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      moveSize?: Moving__MoveSizeEnum | null;
      packing?: Moving__PackingEnum | null;
      originAddress: {
        __typename?: 'Address';
        aptsuite?: string | null;
        city: string;
        state: string;
        street: string;
        zip: string;
        details?: {
          __typename?: 'Detail';
          buildingType?: string | null;
          floor?: number | null;
          longCarry: boolean;
          stories?: number | null;
        } | null;
      };
      destinationAddress: {
        __typename?: 'Address';
        aptsuite?: string | null;
        city: string;
        state: string;
        street: string;
        zip: string;
        details?: {
          __typename?: 'Detail';
          buildingType?: string | null;
          floor?: number | null;
          longCarry: boolean;
          stories?: number | null;
        } | null;
      };
      quote?: {
        __typename?: 'Moving__Quote';
        id: string;
        eligibility: boolean;
        reasons: Array<string>;
        truckCount?: number | null;
        moverSelection?: number | null;
        suggestedMoverCount?: number | null;
        deadtimeDriveDuration?: number | null;
        deadtimeDriveDistance?: number | null;
        drivetimeDuration?: number | null;
        driveDistance?: number | null;
        estimatedCuft: number;
        packDurations: Array<number>;
        unpackDurations: Array<number>;
        orderDurations?: Array<number> | null;
        dispatchDurations?: Array<number> | null;
        laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
        materialPackageSetEntry?: {
          __typename?: 'Moving__PackageSetEntry';
          id: string;
          fixedCost: number;
          package: { __typename?: 'Package'; name: string };
        } | null;
      } | null;
    } | null;
    movingSelections?: Array<{
      __typename?: 'MovingSelection';
      id: string;
      quantity: number;
      movingCategory: { __typename?: 'MovingCategory'; id: string; name: string };
    }> | null;
  };
};

export type CreateFollowUpMoveMutationVariables = Exact<{
  input: Moving__CreateFollowUpMoveInput;
}>;

export type CreateFollowUpMoveMutation = {
  __typename?: 'Mutation';
  createFollowUpMove?: {
    __typename?: 'Moving__CreateFollowUpMovePayload';
    orderID?: string | null;
    status: Status;
    error?: { __typename?: 'Error'; message: string; code: ErrorCodeEnum; expected?: boolean | null } | null;
  } | null;
};

export type ManageFollowUpsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type ManageFollowUpsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    sourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      cancellationFeeDescription?: string | null;
      estimatedDuration?: number | null;
      movers: number;
      scheduled: string;
      state: OrderStateEnum;
      subtype: OrderSubtypeEnum;
      account: { __typename?: 'Account'; id: string };
      movingOperation?: {
        __typename?: 'MovingOperation';
        id: string;
        destinationAddress: {
          __typename?: 'Address';
          id: string;
          street: string;
          city: string;
          state: string;
          zip: string;
          formatted: string;
        };
        originAddress: {
          __typename?: 'Address';
          id: string;
          street: string;
          city: string;
          state: string;
          zip: string;
          formatted: string;
        };
      } | null;
      region: { __typename?: 'Region'; id: string; name: string; tz: string };
    }>;
  };
};

export type OrderUpdateMutationVariables = Exact<{
  input: Order__Update__Input;
}>;

export type OrderUpdateMutation = {
  __typename?: 'Mutation';
  result?: { __typename?: 'Order__UpdatePayload'; status: Status; error?: string | null } | null;
};

export type OrderMovingClocksQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderMovingClocksQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    started?: string | null;
    scheduled: string;
    ended?: string | null;
    region: { __typename?: 'Region'; id: string; tz: string };
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      packedAt?: string | null;
      unpackedAt?: string | null;
      departedForDestinationAt?: string | null;
      arrivedAtDestinationAt?: string | null;
    } | null;
  };
};

export type ModifyMovingClocksMutationVariables = Exact<{
  orderID: Scalars['ID'];
  input: Moving__ClocksInput;
}>;

export type ModifyMovingClocksMutation = {
  __typename?: 'Mutation';
  modifyMovingClocks: { __typename?: 'ModifyMovingClocksPayload'; status: Status; error?: string | null };
};

export type GeoTimezoneQueryVariables = Exact<{
  geo: ClutterGeoEnum;
}>;

export type GeoTimezoneQuery = { __typename?: 'Query'; geoTimezone: string };

export type NightTransportPlannerFacilitiesQueryVariables = Exact<{
  filters: FacilityFiltersInput;
}>;

export type NightTransportPlannerFacilitiesQuery = {
  __typename?: 'Query';
  facilities: Array<
    { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string }
  >;
};

export type NightTransportPlanQueryVariables = Exact<{
  filters: NightTransport__Route__FiltersInput;
  input: NightTransport__DriveTask__Input;
}>;

export type NightTransportPlanQuery = {
  __typename?: 'Query';
  routes: Array<{
    __typename?: 'NightTransport__Route';
    id: string;
    arrival: string;
    tasks: Array<{
      __typename?: 'NightTransport__Task';
      id: string;
      position: number;
      route: {
        __typename?: 'NightTransport__Route';
        id: string;
        arrival: string;
        origin:
          | { __typename: 'Depot'; id: string; name: string }
          | { __typename: 'Warehouse'; id: string; name: string };
        driver?: { __typename?: 'User'; tz: string; id: string; name: string } | null;
      };
      action:
        | {
            __typename?: 'NightTransport__Carpool';
            id: string;
            drive: { __typename?: 'NightTransport__Drive'; id: string };
          }
        | {
            __typename?: 'NightTransport__Drive';
            id: string;
            origin:
              | { __typename: 'Depot'; id: string; name: string }
              | { __typename: 'Warehouse'; id: string; name: string };
            destination:
              | { __typename: 'Depot'; id: string; name: string }
              | { __typename: 'Warehouse'; id: string; name: string };
            driveDispatch?: {
              __typename?: 'Dispatch';
              id: string;
              arrival: string;
              region: { __typename?: 'Region'; id: string; name: string; tz: string };
              vehiclePreferences: Array<{
                __typename?: 'Dispatch__VehiclePreference';
                id: string;
                quantity: number;
                vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
              }>;
              vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
              estimatedInboundItemMetrics: {
                __typename?: 'Dispatch__ItemMetrics';
                cuft: number;
                count?: number | null;
              };
              outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
            } | null;
            vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
            predecessor?: { __typename?: 'NightTransport__Drive'; id: string } | null;
          }
        | {
            __typename?: 'NightTransport__Resupply';
            id: string;
            drive: { __typename?: 'NightTransport__Drive'; id: string };
            resupplyDispatch: {
              __typename?: 'Dispatch';
              id: string;
              arrival: string;
              region: { __typename?: 'Region'; id: string; name: string; tz: string };
              vehiclePreferences: Array<{
                __typename?: 'Dispatch__VehiclePreference';
                id: string;
                quantity: number;
                vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
              }>;
              vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
              estimatedInboundItemMetrics: {
                __typename?: 'Dispatch__ItemMetrics';
                cuft: number;
                count?: number | null;
              };
              outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
            };
            vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
          };
    }>;
    origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
    driver?: { __typename?: 'User'; tz: string; id: string; name: string } | null;
  }>;
  requiredDriveTasks: Array<{
    __typename?: 'NightTransport__DriveTask';
    dispatch: {
      __typename?: 'Dispatch';
      id: string;
      arrival: string;
      region: { __typename?: 'Region'; id: string; name: string; tz: string };
      vehiclePreferences: Array<{
        __typename?: 'Dispatch__VehiclePreference';
        id: string;
        quantity: number;
        vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
      }>;
      vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
      estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
      outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
    };
    destination:
      | { __typename: 'Depot'; id: string; name: string }
      | { __typename: 'Warehouse'; id: string; name: string };
    origin?:
      | { __typename: 'Depot'; id: string; name: string }
      | { __typename: 'Warehouse'; id: string; name: string }
      | null;
    vehicleSuggestion:
      | {
          __typename?: 'Dispatch__VehiclePreference';
          id: string;
          quantity: number;
          vehicleType: { __typename?: 'VehicleType'; id: string };
        }
      | { __typename?: 'Vehicle'; id: string; name: string };
  }>;
};

export type NightTransportPlannerDriversQueryVariables = Exact<{
  filters: UserFiltersInput;
}>;

export type NightTransportPlannerDriversQuery = {
  __typename?: 'Query';
  drivers: Array<{ __typename?: 'User'; id: string; name: string; tz: string }>;
};

export type NightTransportPlannerVehiclesQueryVariables = Exact<{
  filters: VehicleFiltersInput;
}>;

export type NightTransportPlannerVehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{ __typename?: 'Vehicle'; id: string; name: string }>;
};

export type NightTransportRouteUpsertMutationVariables = Exact<{
  input: NightTransport__RouteUpsertInput;
}>;

export type NightTransportRouteUpsertMutation = {
  __typename?: 'Mutation';
  result?: { __typename?: 'RouteUpsertPayload'; status: Status; error?: string | null } | null;
};

export type NightTransportRouteTimelinesQueryVariables = Exact<{
  input: NightTransport__RouteTimelineInput;
}>;

export type NightTransportRouteTimelinesQuery = {
  __typename?: 'Query';
  nightTransportRouteTimelines: Array<{
    __typename?: 'NightTransport__RouteTimeline';
    arrival: string;
    driver: { __typename?: 'User'; id: string; name: string };
    taskEvents: Array<{
      __typename?: 'NightTransport__RouteTimeline__TaskEvent';
      category: NightTransport__RouteTimeline__TaskEvent__CategoryEnum;
      startTimestamp: string;
      endTimestamp: string;
      dispatchID?: string | null;
      vehicleName?: string | null;
      origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
    }>;
  }>;
};

export type BuildNoteMutationVariables = Exact<{
  notable: NoteNotableInput;
  input: NoteInput;
}>;

export type BuildNoteMutation = {
  __typename?: 'Mutation';
  buildNote: { __typename?: 'BuildNotePayload'; status: Status };
};

export type NoteDestroyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NoteDestroyMutation = {
  __typename?: 'Mutation';
  noteDestroy?: { __typename?: 'Note__DestroyPayload'; status: Status } | null;
};

export type ModifyNoteMutationVariables = Exact<{
  body: Scalars['String'];
  id: Scalars['ID'];
}>;

export type ModifyNoteMutation = {
  __typename?: 'Mutation';
  modifyNote: {
    __typename?: 'ModifyNotePayload';
    status: Status;
    note?: {
      __typename?: 'Note';
      id: string;
      body: string;
      createdAt: string;
      updatedAt: string;
      user: { __typename?: 'User'; id: string; name: string };
      notable: { __typename?: 'NoteNotable'; id: string; type: NoteNotableEnum };
      files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; url: string; filename: string }>;
    } | null;
  };
};

export type NotesQueryVariables = Exact<{
  notable: NoteNotableInput;
}>;

export type NotesQuery = {
  __typename?: 'Query';
  notes: Array<{
    __typename?: 'Note';
    id: string;
    body: string;
    createdAt: string;
    updatedAt: string;
    user: { __typename?: 'User'; id: string; name: string };
    notable: { __typename?: 'NoteNotable'; id: string; type: NoteNotableEnum };
    files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; url: string; filename: string }>;
  }>;
};

export type AccountOrdersTypeAndSubtypeQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountOrdersTypeAndSubtypeQuery = {
  __typename?: 'Query';
  orders: Array<{ __typename?: 'Order'; subtype: OrderSubtypeEnum; type: OrderTypeEnum }>;
};

export type PaginatedNotificationListQueryVariables = Exact<{
  accountId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedNotificationListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedNotification';
    results: Array<{
      __typename?: 'Notification';
      id: string;
      objectName?: string | null;
      createdAt: string;
      updatedAt: string;
      kind: string;
      provider: Notification__ProviderEnum;
      deliveries: Array<{
        __typename?: 'NotificationDelivery';
        id: string;
        state: NotificationDelivery__State;
        sent?: string | null;
        scheduled?: string | null;
        createdAt: string;
        updatedAt: string;
        triggerer?: string | null;
        emailEvents: Array<
          | { __typename?: 'IterableEvent'; id: string; sender: string; timestamp: string; event: string }
          | { __typename?: 'SendgridEvent'; id: string; event: string; sender: string; timestamp: string }
        >;
      }>;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ResendIterableNotificationMutationVariables = Exact<{
  accountId: Scalars['ID'];
  kind: Scalars['String'];
}>;

export type ResendIterableNotificationMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ResendIterableNotificationPayload'; status: Status };
};

export type ResendNotificationMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;

export type ResendNotificationMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'ResendNotificationPayload';
    status: Status;
    notification?: {
      __typename?: 'Notification';
      id: string;
      objectName?: string | null;
      createdAt: string;
      updatedAt: string;
      kind: string;
      provider: Notification__ProviderEnum;
      deliveries: Array<{
        __typename?: 'NotificationDelivery';
        id: string;
        state: NotificationDelivery__State;
        sent?: string | null;
        scheduled?: string | null;
        createdAt: string;
        updatedAt: string;
        triggerer?: string | null;
        emailEvents: Array<
          | { __typename?: 'IterableEvent'; id: string; sender: string; timestamp: string; event: string }
          | { __typename?: 'SendgridEvent'; id: string; event: string; sender: string; timestamp: string }
        >;
      }>;
    } | null;
  };
};

export type SentIterableEmailQueryVariables = Exact<{
  notificationId: Scalars['ID'];
}>;

export type SentIterableEmailQuery = {
  __typename?: 'Query';
  result: { __typename?: 'SentIterableEmail'; html: string };
};

export type AuditQueryVariables = Exact<{
  auditID: Scalars['ID'];
}>;

export type AuditQuery = {
  __typename?: 'Query';
  audit: {
    __typename?: 'OpsAudit__AuditType';
    id: string;
    resourceType: string;
    resourceID: string;
    state: string;
    questionGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    auditor?: { __typename?: 'User'; name: string } | null;
    answers: Array<{
      __typename?: 'OpsAudit__AnswerType';
      id: string;
      response: string;
      failed: boolean;
      question: { __typename?: 'OpsAudit__QuestionType'; id: string };
    }>;
    children: Array<{
      __typename?: 'OpsAudit__AuditType';
      id: string;
      resourceType: string;
      resourceID: string;
      state: string;
      questionGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      answers: Array<{
        __typename?: 'OpsAudit__AnswerType';
        id: string;
        response: string;
        failed: boolean;
        question: { __typename?: 'OpsAudit__QuestionType'; id: string };
      }>;
      configuration: { __typename?: 'OpsAudit__ConfigurationType'; id: string; name: string };
      images: Array<{
        __typename?: 'Image';
        id: string;
        size: string;
        createdAt: string;
        source: string;
        name?: string | null;
      }>;
      questions: Array<{
        __typename?: 'OpsAudit__QuestionType';
        id: string;
        prompt: string;
        groups?: Array<string> | null;
        slug: string;
        answerChoices: Array<string>;
        defaultAnswer?: number | null;
        ordering?: number | null;
        defectAnswers: Array<string>;
        failedMessage?: string | null;
        failedAnswers: Array<string>;
        deletedAt?: string | null;
      }>;
    }>;
    configuration: { __typename?: 'OpsAudit__ConfigurationType'; id: string; name: string };
    images: Array<{
      __typename?: 'Image';
      id: string;
      size: string;
      createdAt: string;
      source: string;
      name?: string | null;
    }>;
    questions: Array<{
      __typename?: 'OpsAudit__QuestionType';
      id: string;
      prompt: string;
      groups?: Array<string> | null;
      slug: string;
      answerChoices: Array<string>;
      defaultAnswer?: number | null;
      ordering?: number | null;
      defectAnswers: Array<string>;
      failedMessage?: string | null;
      failedAnswers: Array<string>;
      deletedAt?: string | null;
    }>;
  };
};

export type CreateAuditExceptionMutationVariables = Exact<{
  input: OpsAudit__AuditExceptionInputType;
}>;

export type CreateAuditExceptionMutation = {
  __typename?: 'Mutation';
  createAuditException: { __typename?: 'CreateAuditExceptionPayload'; status: Status; error?: string | null };
};

export type EditAuditExceptionMutationVariables = Exact<{
  input: OpsAudit__AuditExceptionInputType;
}>;

export type EditAuditExceptionMutation = {
  __typename?: 'Mutation';
  editAuditException: { __typename?: 'EditAuditExceptionPayload'; status: Status; error?: string | null };
};

export type AuditResultsQueryVariables = Exact<{
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
}>;

export type AuditResultsQuery = {
  __typename?: 'Query';
  audits: Array<{
    __typename?: 'OpsAudit__AuditType';
    id: string;
    resourceType: string;
    resourceID: string;
    updatedAt: string;
    failed: boolean;
    questionGroup?: string | null;
    state: string;
    parentID?: string | null;
    auditor?: { __typename?: 'User'; name: string } | null;
    configuration: { __typename?: 'OpsAudit__ConfigurationType'; name: string; exceptionReasonCodes: Array<string> };
    answers: Array<{
      __typename?: 'OpsAudit__AnswerType';
      id: string;
      response: string;
      failed: boolean;
      question: { __typename?: 'OpsAudit__QuestionType'; id: string; prompt: string };
    }>;
    exception?: {
      __typename?: 'OpsAudit__AuditException';
      id: string;
      reason: string;
      status: OpsAudit__ExceptionStatus;
      creator: { __typename?: 'User'; id: string; name: string };
    } | null;
  }>;
};

export type ModifyItemCategoryMutationVariables = Exact<{
  input: OpsAudit__ModifyItemCategoryInput;
}>;

export type ModifyItemCategoryMutation = {
  __typename?: 'Mutation';
  modifyItemCategory: { __typename?: 'ModifyItemCategoryPayload'; status: Status; error?: string | null };
};

export type AuditClaimItemSelectionQueryVariables = Exact<{
  claimItemSelectionID: Scalars['ID'];
}>;

export type AuditClaimItemSelectionQuery = {
  __typename?: 'Query';
  itemSelection: {
    __typename?: 'Claim__ItemSelection';
    id: string;
    item?: {
      __typename?: 'Item';
      id: string;
      accountID: string;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      dimensionsOverwritten: boolean;
      sizingSelection?: string | null;
      customCategoryName?: string | null;
      packaging?: ItemPackagingEnum | null;
      category?: {
        __typename?: 'ItemCategory';
        id: string;
        name: string;
        sizingMetric?: { __typename?: 'SizingMetric'; id: string; unit?: string | null } | null;
      } | null;
      partCategory?: { __typename?: 'PartCategory'; id: string; name: string } | null;
      materialCategory?: { __typename?: 'MaterialCategory'; id: string; name: string } | null;
    } | null;
  };
};

export type AuditItemGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AuditItemGroupQuery = {
  __typename?: 'Query';
  itemGroup: {
    __typename?: 'ItemGroup';
    id: string;
    category: { __typename?: 'ItemCategory'; id: string; name: string };
    additionalImages: Array<{ __typename?: 'Image'; id: string; createdAt: string; source: string; size: string }>;
    heroImage: { __typename?: 'Image'; id: string; createdAt: string; source: string; size: string };
    items: Array<{
      __typename?: 'Item';
      id: string;
      accountID: string;
      barcode: { __typename?: 'Barcode'; value: string };
      category?: {
        __typename?: 'ItemCategory';
        id: string;
        name: string;
        sizingMetric?: { __typename?: 'SizingMetric'; id: string; unit?: string | null } | null;
      } | null;
      partCategory?: { __typename?: 'PartCategory'; id: string; name: string } | null;
      materialCategory?: { __typename?: 'MaterialCategory'; id: string; name: string } | null;
      includedParts: Array<{
        __typename?: 'IncludedPart';
        id: string;
        category:
          | { __typename: 'ItemCategory'; id: string; name: string }
          | { __typename: 'MaterialCategory'; id: string; name: string }
          | { __typename: 'PartCategory'; id: string; name: string };
      }>;
    }>;
    partialItems: Array<{
      __typename?: 'PartialItem';
      id: string;
      state: PartialItemStateEnum;
      accountID: string;
      category?: {
        __typename?: 'ItemCategory';
        id: string;
        name: string;
        sizingMetric?: { __typename?: 'SizingMetric'; id: string; unit?: string | null } | null;
      } | null;
      partCategory?: { __typename?: 'PartCategory'; id: string; name: string } | null;
      materialCategory?: { __typename?: 'MaterialCategory'; id: string; name: string } | null;
      includedParts: Array<{
        __typename?: 'IncludedPart';
        id: string;
        category:
          | { __typename: 'ItemCategory'; id: string; name: string }
          | { __typename: 'MaterialCategory'; id: string; name: string }
          | { __typename: 'PartCategory'; id: string; name: string };
      }>;
    }>;
  };
};

export type PalletGradeQueryVariables = Exact<{
  gradeID: Scalars['ID'];
}>;

export type PalletGradeQuery = {
  __typename?: 'Query';
  grade?: { __typename?: 'PalletGrade'; id: string; palletID: string } | null;
};

export type AuditItemQueryVariables = Exact<{
  itemID: Scalars['ID'];
}>;

export type AuditItemQuery = {
  __typename?: 'Query';
  item?: {
    __typename?: 'Item';
    id: string;
    accountID: string;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    dimensionsOverwritten: boolean;
    sizingSelection?: string | null;
    customCategoryName?: string | null;
    packaging?: ItemPackagingEnum | null;
    category?: {
      __typename?: 'ItemCategory';
      id: string;
      name: string;
      sizingMetric?: { __typename?: 'SizingMetric'; id: string; unit?: string | null } | null;
    } | null;
    partCategory?: { __typename?: 'PartCategory'; id: string; name: string } | null;
    materialCategory?: { __typename?: 'MaterialCategory'; id: string; name: string } | null;
  } | null;
};

export type BuildOpsAuditAnswersMutationVariables = Exact<{
  auditAnswers: Array<OpsAudit__AuditAnswersInputType> | OpsAudit__AuditAnswersInputType;
}>;

export type BuildOpsAuditAnswersMutation = {
  __typename?: 'Mutation';
  buildOpsAuditAnswers: { __typename?: 'BuildOpsAuditAnswersPayload'; status: Status; error?: string | null };
};

export type NotAuditableOpsAuditMutationVariables = Exact<{
  auditID: Scalars['ID'];
}>;

export type NotAuditableOpsAuditMutation = {
  __typename?: 'Mutation';
  notAuditableOpsAudit: { __typename?: 'NotAuditableOpsAuditPayload'; status: Status; error?: string | null };
};

export type PalletTaskQueryVariables = Exact<{
  palletTaskID: Scalars['ID'];
}>;

export type PalletTaskQuery = {
  __typename?: 'Query';
  palletTask?: { __typename?: 'PalletTask'; id: string; destinationLocationId?: string | null } | null;
};

export type ConfigurationAuditReviewQueryVariables = Exact<{
  configurationID: Scalars['ID'];
}>;

export type ConfigurationAuditReviewQuery = {
  __typename?: 'Query';
  audit?: {
    __typename?: 'OpsAudit__AuditType';
    id: string;
    resourceType: string;
    resourceID: string;
    state: string;
    questionGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    children: Array<{
      __typename?: 'OpsAudit__AuditType';
      id: string;
      resourceType: string;
      resourceID: string;
      state: string;
      questionGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      configuration: { __typename?: 'OpsAudit__ConfigurationType'; id: string; name: string };
      images: Array<{
        __typename?: 'Image';
        id: string;
        size: string;
        createdAt: string;
        source: string;
        name?: string | null;
      }>;
      questions: Array<{
        __typename?: 'OpsAudit__QuestionType';
        id: string;
        prompt: string;
        groups?: Array<string> | null;
        slug: string;
        answerChoices: Array<string>;
        defaultAnswer?: number | null;
        ordering?: number | null;
        defectAnswers: Array<string>;
        failedMessage?: string | null;
        failedAnswers: Array<string>;
        deletedAt?: string | null;
      }>;
    }>;
    configuration: { __typename?: 'OpsAudit__ConfigurationType'; id: string; name: string };
    images: Array<{
      __typename?: 'Image';
      id: string;
      size: string;
      createdAt: string;
      source: string;
      name?: string | null;
    }>;
    questions: Array<{
      __typename?: 'OpsAudit__QuestionType';
      id: string;
      prompt: string;
      groups?: Array<string> | null;
      slug: string;
      answerChoices: Array<string>;
      defaultAnswer?: number | null;
      ordering?: number | null;
      defectAnswers: Array<string>;
      failedMessage?: string | null;
      failedAnswers: Array<string>;
      deletedAt?: string | null;
    }>;
  } | null;
};

export type SkipOpsAuditMutationVariables = Exact<{
  auditID: Scalars['ID'];
}>;

export type SkipOpsAuditMutation = {
  __typename?: 'Mutation';
  skipOpsAudit: { __typename?: 'SkipOpsAuditPayload'; status: Status; error?: string | null };
};

export type OpsAuditConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OpsAuditConfigurationQuery = {
  __typename?: 'Query';
  opsAuditConfiguration?: {
    __typename?: 'OpsAudit__ConfigurationType';
    id: string;
    name: string;
    lastRanAt?: string | null;
    canceledAt?: string | null;
    createdAt: string;
    resourceType: string;
    refreshIntervalMinutes?: number | null;
    hoursUntilExpired?: number | null;
    pendingCount: number;
    pausedAt?: string | null;
    exceptionReasonCodes: Array<string>;
    dataQuery: string;
    questions: Array<{
      __typename?: 'OpsAudit__QuestionType';
      id: string;
      prompt: string;
      groups?: Array<string> | null;
      slug: string;
      answerChoices: Array<string>;
      defaultAnswer?: number | null;
      ordering?: number | null;
      defectAnswers: Array<string>;
      failedMessage?: string | null;
      failedAnswers: Array<string>;
      deletedAt?: string | null;
    }>;
    user: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type OpsAuditConfigurationModifyMutationVariables = Exact<{
  input: OpsAudit__ConfigurationModifyInput;
}>;

export type OpsAuditConfigurationModifyMutation = {
  __typename?: 'Mutation';
  opsAuditConfigurationModify: { __typename?: 'ConfigurationModifyPayload'; status: Status; error?: string | null };
};

export type PauseConfigurationMutationVariables = Exact<{
  configurationID: Scalars['ID'];
}>;

export type PauseConfigurationMutation = {
  __typename?: 'Mutation';
  pauseConfiguration: { __typename?: 'PauseConfigurationPayload'; status: Status; error?: string | null };
};

export type PaginatedConfigurationListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedConfigurationListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'OpsAudit__PaginatedConfigurationListType';
    results: Array<{
      __typename?: 'OpsAudit__ConfigurationType';
      id: string;
      name: string;
      lastRanAt?: string | null;
      canceledAt?: string | null;
      createdAt: string;
      resourceType: string;
      refreshIntervalMinutes?: number | null;
      hoursUntilExpired?: number | null;
      pendingCount: number;
      pausedAt?: string | null;
      exceptionReasonCodes: Array<string>;
      dataQuery: string;
      user: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type UnpauseConfigurationMutationVariables = Exact<{
  configurationID: Scalars['ID'];
}>;

export type UnpauseConfigurationMutation = {
  __typename?: 'Mutation';
  unpauseConfiguration: { __typename?: 'UnpauseConfigurationPayload'; status: Status; error?: string | null };
};

export type OrderEventsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderEventsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    orderEvents: Array<{
      __typename?: 'OrderEvent';
      id: string;
      category: string;
      eventName: string;
      timestamp: string;
      image?: { __typename?: 'Image'; id: string; source: string; size: string } | null;
    }>;
    region: { __typename?: 'Region'; id: string; tz: string };
  };
};

export type OrderLeadTimesQueryVariables = Exact<{
  warehouseID: Scalars['ID'];
}>;

export type OrderLeadTimesQuery = {
  __typename?: 'Query';
  orderLeadTimes: Array<{
    __typename?: 'OrderLeadTime';
    id: string;
    ignoreNonOperatingDays: boolean;
    orderSubtype: OrderSubtypeEnum;
    orderType: OrderTypeEnum;
    processingDayCutoffTime: string;
    processingDaysRequired: number;
    updatedAt: string;
  }>;
  warehouse: { __typename?: 'Warehouse'; id: string; name: string };
};

export type OrderLeadTimeUpsertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: OrderLeadTimeInput;
}>;

export type OrderLeadTimeUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'OrderLeadTimeUpsertPayload';
    status: Status;
    error?: string | null;
    resource?: {
      __typename?: 'OrderLeadTime';
      id: string;
      ignoreNonOperatingDays: boolean;
      orderSubtype: OrderSubtypeEnum;
      orderType: OrderTypeEnum;
      processingDayCutoffTime: string;
      processingDaysRequired: number;
      updatedAt: string;
    } | null;
  } | null;
};

export type OrderCurrentCancellationFeeQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderCurrentCancellationFeeQuery = { __typename?: 'Query'; fee?: string | null };

export type MoveCancelReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type MoveCancelReasonsQuery = {
  __typename?: 'Query';
  reasons: Array<{ __typename?: 'Order__CancelReason'; id: string; kind: string }>;
};

export type OnboardingCancelReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingCancelReasonsQuery = {
  __typename?: 'Query';
  reasons: Array<{ __typename?: 'Order__CancelReason'; id: string; kind: string }>;
};

export type OrderCancelMutationVariables = Exact<{
  input: Order__CancelInput;
}>;

export type OrderCancelMutation = {
  __typename?: 'Mutation';
  orderCancel?: { __typename?: 'Order__CancelPayload'; status: Status; error?: string | null } | null;
};

export type OrderCompleteMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderCompleteMutation = {
  __typename?: 'Mutation';
  orderComplete?: {
    __typename?: 'Order__CompletePayload';
    status: Status;
    error?: string | null;
    order?: {
      __typename?: 'Order';
      id: string;
      state: OrderStateEnum;
      items: Array<{
        __typename?: 'Item';
        id: string;
        uuid?: string | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        barcode: { __typename?: 'Barcode'; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
    } | null;
  } | null;
};

export type ModifyAuctionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ModifyAuctionMutation = {
  __typename?: 'Mutation';
  modifyAuction: { __typename?: 'ModifyAuctionPayload'; error?: string | null; status: Status };
};

export type OrderPolicyQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderPolicyQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    scheduled: string;
    itemsCount: number;
    simultaneous: boolean;
    state: OrderStateEnum;
    region: { __typename?: 'Region'; id: string; tz: string; parentID?: string | null };
    policy: {
      __typename?: 'Order__Policy';
      cancel: boolean;
      uncancel: boolean;
      completeManually: boolean;
      dispatch: boolean;
      modify: boolean;
      review: boolean;
      ship: boolean;
      viewSelfStorageAuction: boolean;
      createFollowUp: boolean;
    };
  };
};

export type OrderAttachmentsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderAttachmentsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    ingestedDocuments: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
  };
};

export type AccountBookingCancellationDisclaimerQueryVariables = Exact<{
  accountID: Scalars['ID'];
  orderType: OrderTypeEnum;
  orderSubtype: OrderSubtypeEnum;
  orderServiceType?: InputMaybe<OrderServiceTypeEnum>;
}>;

export type AccountBookingCancellationDisclaimerQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; id: string; disclaimer: string };
};

export type OrderTermContractsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type OrderTermContractsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    terminationAgreement?: {
      __typename?: 'Account__TerminationAgreement';
      contractDuration: number;
      earlyTerminationFee: number;
      contractEndDate?: string | null;
    } | null;
    latestTermCommitment: {
      __typename?: 'Account__TermCommitment';
      term: number;
      contractEndDate?: string | null;
      type: Account__TermCommitment__Type;
      fulfilled: boolean;
    };
  };
};

export type FormattedAppointmentLaborAgreementQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type FormattedAppointmentLaborAgreementQuery = {
  __typename?: 'Query';
  formattedAppointmentLaborAgreement: string;
};

export type OrderDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderDetailsQuery = {
  __typename?: 'Query';
  estimationCategories: Array<{
    __typename?: 'EstimationCategory';
    id: string;
    name: string;
    packable: boolean;
    cuft: number;
    numMovers?: number | null;
  }>;
  roomCategories: Array<{ __typename?: 'RoomCategory'; id: string; name: string; countable: boolean; cuft: number }>;
  order: {
    __typename?: 'Order';
    services: Array<{
      __typename?: 'OrderService';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      estimatedCuft: number;
      isFullMoveOut?: boolean | null;
      needsPackingHelp?: boolean | null;
      requestedMovers?: number | null;
      estimatedItems: Array<{
        __typename?: 'EstimatedItem';
        id: string;
        categoryId: string;
        quantity: number;
        categoryDisplayName?: string | null;
        notes?: string | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        cuft?: number | null;
        estimatedMovers?: number | null;
      }>;
      rooms: Array<{
        __typename?: 'RoomSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      estimations: Array<{
        __typename?: 'EstimationSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      items: Array<{
        __typename?: 'Item';
        id: string;
        uuid?: string | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        barcode: { __typename?: 'Barcode'; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
    }>;
  };
};

export type OrderLaborClocksQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderLaborClocksQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    region: { __typename?: 'Region'; id: string; tz: string };
    laborClocks: Array<{
      __typename?: 'LaborClock';
      id: string;
      orderID: string;
      started?: string | null;
      ended?: string | null;
      role: Assignment__Role;
      user: { __typename?: 'User'; id: string; name: string };
    }>;
    dispatch?: {
      __typename?: 'Dispatch';
      id: string;
      assignments: Array<{
        __typename?: 'Assignment';
        role: Assignment__Role;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
    } | null;
  };
};

export type CreateLaborClockMutationVariables = Exact<{
  input: CreateLaborClockInput;
}>;

export type CreateLaborClockMutation = {
  __typename?: 'Mutation';
  createLaborClock: { __typename?: 'CreateLaborClockPayload'; status: Status; error?: string | null };
};

export type DeleteLaborClockMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLaborClockMutation = {
  __typename?: 'Mutation';
  deleteLaborClock: { __typename?: 'DeleteLaborClockPayload'; status: Status; error?: string | null };
};

export type ModifyLaborClockMutationVariables = Exact<{
  id: Scalars['ID'];
  input: LaborClockInput;
}>;

export type ModifyLaborClockMutation = {
  __typename?: 'Mutation';
  modifyLaborClock: { __typename?: 'ModifyLaborClockPayload'; status: Status; error?: string | null };
};

export type PaginatedOrdersQueryVariables = Exact<{
  subject: OrderSubjectInput;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedOrdersQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedOrder';
    results: Array<{
      __typename?: 'Order';
      id: string;
      accountID: string;
      number: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      state: OrderStateEnum;
      moving: boolean;
      storage: boolean;
      maintenance: boolean;
      serviceType?: OrderServiceTypeEnum | null;
      scheduled: string;
      window: string;
      formattedArrivalWindow: string;
      bookedAt: string;
      region: { __typename?: 'Region'; id: string; tz: string };
      user?: { __typename?: 'User'; id: string; name: string } | null;
      dropOffAppointment?: {
        __typename?: 'DropOff__Appointment';
        id: string;
        expectedAt: string;
        state: AppointmentStateEnum;
      } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type OrderMapQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderMapQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    address: { __typename?: 'Address'; id: string; latitude?: number | null; longitude?: number | null };
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      originAddress: { __typename?: 'Address'; id: string; latitude?: number | null; longitude?: number | null };
      destinationAddress: { __typename?: 'Address'; id: string; latitude?: number | null; longitude?: number | null };
    } | null;
    tasks?: Array<{
      __typename?: 'TaskOrder__Task';
      id: string;
      sequenceNumber: number;
      address: { __typename?: 'Address'; id: string; latitude?: number | null; longitude?: number | null };
    }> | null;
  };
};

export type MovingLongDistanceTransportWeightUpsertMutationVariables = Exact<{
  input: Moving__LongDistance__TransportWeightUpsertInput;
}>;

export type MovingLongDistanceTransportWeightUpsertMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Moving__LongDistance__TransportWeightUpsertPayload';
    status: Status;
    error?: { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string } | null;
  } | null;
};

export type MovingLongDistanceTransportWeightUpsertDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingLongDistanceTransportWeightUpsertDetailsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    subtype: OrderSubtypeEnum;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      loadedTruckWeightInPounds?: number | null;
      assumedEmptyTruckWeightInPounds: number;
      emptyTruckWeightInPounds?: number | null;
      emptyTruckWeightInPoundsSuggestion: string;
      longDistanceTransportationRate?: { __typename?: 'Moving__LongDistance__TransportationRate'; id: string } | null;
    } | null;
  };
};

export type MovingConfigurationQueryVariables = Exact<{
  movingConfigurationID: Scalars['ID'];
  movers: Scalars['Int'];
}>;

export type MovingConfigurationQuery = {
  __typename?: 'Query';
  movingConfiguration: {
    __typename?: 'Moving__Configuration';
    laborRate: { __typename?: 'LaborRate'; amount: number };
  };
};

export type MovingWalkthroughDeleteMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingWalkthroughDeleteMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Moving__WalkthroughDeletePayload';
    order:
      | { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string }
      | {
          __typename?: 'Order';
          id: string;
          estimatedDuration?: number | null;
          accountPackages?: Array<{
            __typename?: 'AccountPackage';
            id: string;
            kind: PackageKindEnum;
            amount: number;
          }> | null;
        };
  };
};

export type MovingWalkthroughDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingWalkthroughDetailsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    estimatedDuration?: number | null;
    accountPackages?: Array<{
      __typename?: 'AccountPackage';
      id: string;
      kind: PackageKindEnum;
      amount: number;
    }> | null;
  };
  permissions: { __typename?: 'OrderMovingOperationPolicy'; submitVirtualWalkthrough: boolean };
};

export type MovingWalkthroughUpdateMutationVariables = Exact<{
  input: Moving__WalkthroughInput;
  orderID: Scalars['ID'];
}>;

export type MovingWalkthroughUpdateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Moving__WalkthroughUpdatePayload';
    order:
      | { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string }
      | {
          __typename?: 'Order';
          id: string;
          estimatedDuration?: number | null;
          accountPackages?: Array<{
            __typename?: 'AccountPackage';
            id: string;
            kind: PackageKindEnum;
            amount: number;
          }> | null;
        };
  };
};

export type MultiDayContextQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MultiDayContextQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    multiDayContext: {
      __typename?: 'MultiDayContext';
      id: string;
      currentDay: number;
      totalDays: number;
      multiDay: boolean;
    };
    predecessor?: { __typename?: 'Order'; id: string; number: string; accountID: string } | null;
    successor?: { __typename?: 'Order'; id: string; number: string; accountID: string } | null;
  };
};

export type OrderEstimatedCuftQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderEstimatedCuftQuery = {
  __typename?: 'Query';
  order: { __typename?: 'Order'; id: string; estimatedCuft: number };
};

export type OrderPickupCompleteMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderPickupCompleteMutation = {
  __typename?: 'Mutation';
  automationPickupComplete: {
    __typename?: 'PickupCompletePayload';
    order: { __typename?: 'Order'; id: string; state: OrderStateEnum };
  };
};

export type OrderRescheduleMutationVariables = Exact<{
  input: OrderRescheduleInput;
}>;

export type OrderRescheduleMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ReschedulePayload';
    error?: string | null;
    status: Status;
    order?: {
      __typename?: 'Order';
      id: string;
      scheduled: string;
      number: string;
      account: {
        __typename?: 'Account';
        id: string;
        number: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
      };
      region: { __typename?: 'Region'; id: string; tz: string };
    } | null;
  } | null;
};

export type OrderReturnRouteMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderReturnRouteMutation = {
  __typename?: 'Mutation';
  automationReturnRoute: {
    __typename?: 'ReturnRoutePayload';
    order: { __typename?: 'Order'; id: string; state: OrderStateEnum };
  };
};

export type OrderTagsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderTagsQuery = { __typename?: 'Query'; order: { __typename?: 'Order'; id: string; tags: Array<string> } };

export type OrderWithSchedulingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderWithSchedulingQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    number: string;
    account: {
      __typename?: 'Account';
      id: string;
      number: string;
      customer: { __typename?: 'Customer'; id: string; name: string };
    };
    region: { __typename?: 'Region'; id: string; tz: string };
  };
};

export type OrdersSelectorQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type OrdersSelectorQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    state: OrderStateEnum;
  }>;
};

export type ResendTodayAppUrlMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type ResendTodayAppUrlMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ResendTodayAppUrlPayload'; status: Status };
};

export type OrderShipmentsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderShipmentsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    shipments: Array<{
      __typename?: 'Shipment';
      id: string;
      carrier: ShipmentCarrierEnum;
      service?: string | null;
      status: ShipmentStatusEnum;
      length: number;
      width: number;
      height: number;
      weight: number;
      rate?: number | null;
      purchased: boolean;
      labelURL?: string | null;
      trackingURL?: string | null;
      zplURL?: string | null;
      items: Array<{
        __typename?: 'Item';
        id: string;
        state: ItemStateEnum;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
      }>;
    }>;
  };
};

export type OrderCouponUpdateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  couponID?: InputMaybe<Scalars['ID']>;
}>;

export type OrderCouponUpdateMutation = {
  __typename?: 'Mutation';
  orderCouponUpdate?: {
    __typename?: 'Order__CouponUpdatePayload';
    status: Status;
    result:
      | { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string }
      | {
          __typename?: 'Order';
          id: string;
          coupon?: { __typename?: 'Coupon'; id: string; code: string; discountDescription: string } | null;
        };
  } | null;
};

export type OrderShowQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderShowQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    eta?: string | null;
    flaggedAt?: string | null;
    formattedArrivalWindow: string;
    maintenance: boolean;
    movers: number;
    moving: boolean;
    number: string;
    otherNote?: string | null;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    shipped: boolean;
    slaWindowSize: number;
    state: OrderStateEnum;
    status: OrderStatusEnum;
    storage: boolean;
    subtype: OrderSubtypeEnum;
    type: OrderTypeEnum;
    account: {
      __typename?: 'Account';
      id: string;
      number: string;
      currentStorageSubscription?: {
        __typename?: 'Subscription';
        id: string;
        name: string;
        quantity: number;
        pricing: { __typename?: 'Pricing'; amount: number; currency: Currency };
      } | null;
      customer: { __typename?: 'Customer'; id: string; name: string; phone?: string | null };
      customers: Array<{ __typename?: 'Customer'; id: string; name: string; phone?: string | null }>;
    };
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      addressableId?: string | null;
      addressableType?: string | null;
      aptsuite?: string | null;
      city: string;
      country?: string | null;
      state: string;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      safeAndSanitary?: boolean | null;
      schedulable: boolean;
      details?: {
        __typename?: 'Detail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: EnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: Address__BuildingRestrictionsAnswerEnum | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: Address__UnitSizeEnum | null;
      } | null;
    };
    assessment?: {
      __typename?: 'Order__AssessmentType';
      id: string;
      otherReason?: string | null;
      rating: Rating;
      reasons: any;
    } | null;
    cancelReason?: { __typename?: 'Order__CancelReason'; id: string; kind: string } | null;
    children: Array<{ __typename?: 'Order'; id: string; number: string }>;
    contact?: {
      __typename?: 'Order__Contact';
      id: string;
      name?: string | null;
      phone: string;
      kind?: Order__ContactKind | null;
    } | null;
    coupon?: { __typename?: 'Coupon'; id: string; code: string; name: string; discountDescription: string } | null;
    dispatch?: {
      __typename?: 'Dispatch';
      id: string;
      assignments: Array<{
        __typename?: 'Assignment';
        role: Assignment__Role;
        user: { __typename?: 'User'; id: string; name: string };
      }>;
    } | null;
    dropOffAppointment?: { __typename?: 'DropOff__Appointment'; expectedAt: string } | null;
    leadUser?: { __typename?: 'User'; id: string } | null;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      destinationAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        addressableId?: string | null;
        addressableType?: string | null;
        aptsuite?: string | null;
        city: string;
        country?: string | null;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        safeAndSanitary?: boolean | null;
        schedulable: boolean;
        details?: {
          __typename?: 'Detail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: EnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: Address__BuildingRestrictionsAnswerEnum | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: Address__UnitSizeEnum | null;
        } | null;
      };
      originAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        addressableId?: string | null;
        addressableType?: string | null;
        aptsuite?: string | null;
        city: string;
        country?: string | null;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        safeAndSanitary?: boolean | null;
        schedulable: boolean;
        details?: {
          __typename?: 'Detail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: EnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: Address__BuildingRestrictionsAnswerEnum | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: Address__UnitSizeEnum | null;
        } | null;
      };
      quote?: {
        __typename?: 'Moving__Quote';
        id: string;
        movingConfiguration?: { __typename?: 'Moving__Configuration'; id: string; travelRateMultiplier: number } | null;
      } | null;
    } | null;
    parent?: { __typename?: 'Order'; id: string; number: string } | null;
    region: { __typename?: 'Region'; tz: string };
    review?: {
      __typename?: 'Review';
      id: string;
      comments?: string | null;
      createdAt: string;
      rating: string;
      updatedAt: string;
    } | null;
    shipmentValuationDeclaration?: { __typename?: 'Declaration'; id: boolean; description: string } | null;
    predecessor?: { __typename?: 'Order'; id: string } | null;
    todayApp?: { __typename?: 'TodayApp'; id: string; url: string } | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
    walkthroughs: Array<{
      __typename?: 'Walkthrough';
      id: string;
      name?: string | null;
      images: Array<{ __typename?: 'Image'; id: string; source: string; createdAt: string }>;
    }>;
  };
};

export type OrderUserUpdateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  userID: Scalars['ID'];
}>;

export type OrderUserUpdateMutation = {
  __typename?: 'Mutation';
  orderUserUpdate?: {
    __typename?: 'Order__UserUpdatePayload';
    status: Status;
    result:
      | { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string }
      | { __typename?: 'Order'; id: string; user?: { __typename?: 'User'; id: string; name: string } | null };
  } | null;
};

export type OrdersMissingSignatureQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type OrdersMissingSignatureQuery = {
  __typename?: 'Query';
  ordersMissingSignature: Array<{
    __typename?: 'Order';
    id: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    serviceType?: OrderServiceTypeEnum | null;
    scheduled: string;
  }>;
};

export type OrderSignaturesQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderSignaturesQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    started?: string | null;
    customerSignatureRequests: Array<{
      __typename?: 'SignatureRequest';
      id: string;
      kind: SignatureRequestKindEnum;
      signedAt?: string | null;
    }>;
    region: { __typename?: 'Region'; id: string; tz: string };
    signatures: Array<{
      __typename?: 'Signature';
      id: string;
      documentKind: string;
      contractUrl?: string | null;
      skipped: boolean;
      reason?: string | null;
      explanation?: string | null;
      timestamp: string;
      state: string;
      agreement?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        imgixURL: string;
        url: string;
        filename: string;
      } | null;
    }>;
  };
};

export type RegenerateDocumentsMutationVariables = Exact<{
  signatureRequestID: Scalars['ID'];
}>;

export type RegenerateDocumentsMutation = {
  __typename?: 'Mutation';
  regenerateDocuments: { __typename?: 'RegenerateDocumentsPayload'; error?: string | null; status: Status };
};

export type SignatureApprovalCreateMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type SignatureApprovalCreateMutation = {
  __typename?: 'Mutation';
  signatureApprovalCreate: { __typename?: 'SignatureApprovalCreatePayload'; error?: string | null; status: Status };
};

export type StoragePlansQueryVariables = Exact<{ [key: string]: never }>;

export type StoragePlansQuery = {
  __typename?: 'Query';
  storagePlans: Array<{
    __typename?: 'Plan';
    id: string;
    kind: PlanKindEnum;
    cuft: number;
    name: string;
    type: PlanTypeEnum;
  }>;
};

export type StorageSubscriptionsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type StorageSubscriptionsQuery = {
  __typename?: 'Query';
  storageSubscriptions: Array<{
    __typename?: 'Subscription';
    id: string;
    stripeID?: string | null;
    name: string;
    state: string;
    quantity: number;
    customDimension?: {
      __typename?: 'CustomDimension';
      id: string;
      cuft: number;
      length: number;
      width: number;
      height: number;
    } | null;
    pricing: {
      __typename?: 'Pricing';
      id: string;
      amount: number;
      currency: Currency;
      plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
    };
  }>;
  inventoryMetrics?: {
    __typename?: 'InventoryMetrics';
    cuftUsed: number;
    staleUsage: boolean;
    unsizedItemsCount: number;
  } | null;
};

export type PaginatedIndexOrdersQueryVariables = Exact<{
  page: Scalars['Int'];
  filters: OrderFiltersInput;
}>;

export type PaginatedIndexOrdersQuery = {
  __typename?: 'Query';
  paginatedOrders: {
    __typename?: 'PaginatedOrder';
    results: Array<{
      __typename?: 'Order';
      id: string;
      scheduled: string;
      maintenance: boolean;
      dispatched: boolean;
      movers: number;
      state: OrderStateEnum;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      serviceType?: OrderServiceTypeEnum | null;
      number: string;
      flaggedAt?: string | null;
      address: {
        __typename?: 'Address';
        id: string;
        aptsuite?: string | null;
        city: string;
        neighborhood?: string | null;
        street: string;
        state: string;
        zip: string;
        map: string;
      };
      account: {
        __typename?: 'Account';
        id: string;
        subscriptions: Array<{
          __typename?: 'Subscription';
          id: string;
          name: string;
          quantity: number;
          custom: boolean;
        }>;
        customer: { __typename?: 'Customer'; id: string; name: string };
        customers: Array<{ __typename?: 'Customer'; id: string; name: string }>;
      };
      region: { __typename?: 'Region'; id: string; parentID?: string | null; tz: string };
      policy: { __typename?: 'Order__Policy'; dispatch: boolean };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type OrderTasksUpdateMutationVariables = Exact<{
  input: Order__TasksUpdateInput;
}>;

export type OrderTasksUpdateMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Order__TasksUpdatePayload';
    error?: string | null;
    status: Status;
    order?: {
      __typename?: 'Order';
      tasks?: Array<{
        __typename?: 'TaskOrder__Task';
        id: string;
        instructions?: string | null;
        sequenceNumber: number;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
        };
        contact?: { __typename?: 'Order__Contact'; id: string; name?: string | null; phone: string } | null;
      }> | null;
    } | null;
  } | null;
};

export type TaskOrderContextQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type TaskOrderContextQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; id: string; name: string } };
};

export type TaskOrderCreateMutationVariables = Exact<{
  input: Order__TaskOrderInput;
}>;

export type TaskOrderCreateMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Order__TaskOrderCreatePayload';
    status: Status;
    error?: string | null;
    order?: { __typename?: 'Order'; id: string } | null;
  } | null;
};

export type TaskOrderEditQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type TaskOrderEditQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    estimatedDuration?: number | null;
    movers: number;
    number: string;
    scheduled: string;
    region: { __typename?: 'Region'; id: string; tz: string };
    tasks?: Array<{
      __typename?: 'TaskOrder__Task';
      id: string;
      instructions?: string | null;
      sequenceNumber: number;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
      };
      contact?: { __typename?: 'Order__Contact'; id: string; name?: string | null; phone: string } | null;
    }> | null;
  };
};

export type TaskOrderUpdateMutationVariables = Exact<{
  input: Order__Update__Input;
}>;

export type TaskOrderUpdateMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Order__UpdatePayload';
    status: Status;
    error?: string | null;
    order?: {
      __typename?: 'Order';
      id: string;
      scheduled: string;
      movers: number;
      estimatedDuration?: number | null;
      region: { __typename?: 'Region'; id: string; name: string };
    } | null;
  } | null;
};

export type BuildTestOrderRequestMutationVariables = Exact<{
  input: Automation__TestOrderRequestInput;
}>;

export type BuildTestOrderRequestMutation = {
  __typename?: 'Mutation';
  buildTestOrderRequest: {
    __typename?: 'BuildTestOrderRequestPayload';
    status: Status;
    error?: string | null;
    testOrderRequest?: { __typename?: 'Automation__TestOrderRequest'; id: string } | null;
  };
};

export type TestOrderRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type TestOrderRequestsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Automation__PaginatedTestOrderRequest';
    results: Array<{
      __typename?: 'Automation__TestOrderRequest';
      id: string;
      state: Automation__TestOrderRequest__State;
      type: string;
      error?: string | null;
      subtype: string;
      completed: boolean;
      order?: { __typename?: 'Order'; id: string; state: OrderStateEnum; scheduled: string; accountID: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type WaitlistRequestsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type WaitlistRequestsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
  };
};

export type PalletGradesQueryVariables = Exact<{
  palletID: Scalars['ID'];
}>;

export type PalletGradesQuery = {
  __typename?: 'Query';
  palletGrades?: Array<{
    __typename?: 'PalletGrade';
    id: string;
    createdAt: string;
    user: { __typename?: 'User'; name: string };
    image?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
    frontImage?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
    backImage?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
  }> | null;
};

export type ActivePalletRequestsQueryVariables = Exact<{
  warehouseId: Scalars['ID'];
  kind: PalletRequestKindEnum;
}>;

export type ActivePalletRequestsQuery = {
  __typename?: 'Query';
  activePalletRequests: Array<{
    __typename?: 'PalletRequest';
    id: string;
    createdAt: string;
    deletedAt?: string | null;
    pallet: {
      __typename?: 'Pallet';
      id: string;
      itemsCount: number;
      barcode: { __typename?: 'Barcode'; value: string };
      items: Array<{
        __typename?: 'Item';
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string };
        };
      }>;
      palletInventories: Array<{
        __typename?: 'Logistics__PalletInventory';
        id: string;
        sku: {
          __typename?: 'Logistics__Sku';
          id: string;
          account: { __typename?: 'Account'; id: string; customer: { __typename?: 'Customer'; name: string } };
        };
      }>;
      densityMetrics: { __typename?: 'PalletDensityMetrics'; percentage: number };
      location?: { __typename?: 'Location'; id: string; value: string } | null;
      warehouse: { __typename?: 'Warehouse'; id: string; tz: string };
    };
    requestor: { __typename?: 'User'; id: string; name: string };
  }>;
};

export type BuildPalletRequestMutationVariables = Exact<{
  input: PalletRequestInput;
}>;

export type BuildPalletRequestMutation = {
  __typename?: 'Mutation';
  buildPalletRequest: { __typename?: 'BuildPalletRequestPayload'; error?: string | null; status: Status };
};

export type DeletePalletRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePalletRequestMutation = {
  __typename?: 'Mutation';
  deletePalletRequest: { __typename?: 'DeletePalletRequestPayload'; error?: string | null; status: Status };
};

export type InProgressManualPalletRequestQueryVariables = Exact<{
  palletID: Scalars['ID'];
}>;

export type InProgressManualPalletRequestQuery = {
  __typename?: 'Query';
  inProgressManualPalletRequest?: { __typename?: 'PalletRequest'; id: string } | null;
};

export type BuildPalletTypeMutationVariables = Exact<{
  input: PalletTypeInput;
}>;

export type BuildPalletTypeMutation = {
  __typename?: 'Mutation';
  buildPalletType: { __typename?: 'BuildPalletTypePayload'; error?: string | null };
};

export type ModifyPalletTypeMutationVariables = Exact<{
  input: PalletTypeInput;
  id: Scalars['ID'];
}>;

export type ModifyPalletTypeMutation = {
  __typename?: 'Mutation';
  modifyPalletType: { __typename?: 'ModifyPalletTypePayload'; error?: string | null };
};

export type PalletTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PalletTypeQuery = {
  __typename?: 'Query';
  palletType: {
    __typename?: 'PalletType';
    id: string;
    name: string;
    capacity?: number | null;
    legacySize: PalletSizeEnum;
    active: boolean;
    description?: string | null;
    position: number;
    itemCategories?: Array<{ __typename?: 'ItemCategory'; id: string; name: string }> | null;
    partCategories?: Array<{ __typename?: 'PartCategory'; id: string; name: string }> | null;
    materialCategories?: Array<{ __typename?: 'MaterialCategory'; id: string; name: string }> | null;
  };
};

export type PalletTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PalletTypesQuery = {
  __typename?: 'Query';
  palletTypes: Array<{
    __typename?: 'PalletType';
    id: string;
    name: string;
    capacity?: number | null;
    legacySize: PalletSizeEnum;
    active: boolean;
    description?: string | null;
    position: number;
    itemCategories?: Array<{ __typename?: 'ItemCategory'; id: string; name: string }> | null;
    partCategories?: Array<{ __typename?: 'PartCategory'; id: string; name: string }> | null;
    materialCategories?: Array<{ __typename?: 'MaterialCategory'; id: string; name: string }> | null;
  }>;
};

export type PaymentPlanAccountQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type PaymentPlanAccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
  };
};

export type AcceptPaymentPlanOfferMutationVariables = Exact<{
  input: PaymentPlan__Offer__SaveInput;
  sourceID?: InputMaybe<Scalars['ID']>;
  offlineSource?: InputMaybe<Billing__Invoice__OfflinePaymentChargeInput>;
}>;

export type AcceptPaymentPlanOfferMutation = {
  __typename?: 'Mutation';
  acceptOffer: {
    __typename?: 'PaymentPlan__Offer__AcceptPayload';
    error?: string | null;
    status: Status;
    offer?: {
      __typename?: 'PaymentPlan__Offer';
      id: string;
      state: PaymentPlan__Offer__State;
      type: PaymentPlan__Offer__Type;
      oneTimeFee?: number | null;
      expiry: string;
      expired: boolean;
      createdAt: string;
      acceptedAt?: string | null;
      storageTerm?: number | null;
      storageTermEndDate?: string | null;
      monthlyFee?: number | null;
      monthlyFeeTerm?: number | null;
      monthlyFeeTermEndDate?: string | null;
      account: {
        __typename?: 'Account';
        id: string;
        number: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
      };
      user: { __typename?: 'User'; id: string; name: string };
      settledInvoices: Array<{
        __typename?: 'Billing__Invoice';
        id: string;
        createdAt: string;
        summary?: string | null;
        status: Billing__Invoice__Status;
        amountDue: number;
      }>;
    } | null;
  };
};

export type CancelPaymentPlanOfferMutationVariables = Exact<{
  offerID: Scalars['ID'];
}>;

export type CancelPaymentPlanOfferMutation = {
  __typename?: 'Mutation';
  cancelOffer: {
    __typename?: 'PaymentPlan__Offer__CancelPayload';
    error?: string | null;
    status: Status;
    offer?: { __typename?: 'PaymentPlan__Offer'; id: string; state: PaymentPlan__Offer__State } | null;
  };
};

export type ExtendPaymentPlanOfferMutationVariables = Exact<{
  input: PaymentPlan__Offer__SaveInput;
}>;

export type ExtendPaymentPlanOfferMutation = {
  __typename?: 'Mutation';
  extendOffer: {
    __typename?: 'PaymentPlan__Offer__ExtendPayload';
    error?: string | null;
    status: Status;
    offer?: {
      __typename?: 'PaymentPlan__Offer';
      id: string;
      state: PaymentPlan__Offer__State;
      type: PaymentPlan__Offer__Type;
      oneTimeFee?: number | null;
      expiry: string;
      expired: boolean;
      createdAt: string;
      acceptedAt?: string | null;
      storageTerm?: number | null;
      storageTermEndDate?: string | null;
      monthlyFee?: number | null;
      monthlyFeeTerm?: number | null;
      monthlyFeeTermEndDate?: string | null;
      account: {
        __typename?: 'Account';
        id: string;
        number: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
      };
      user: { __typename?: 'User'; id: string; name: string };
      settledInvoices: Array<{
        __typename?: 'Billing__Invoice';
        id: string;
        createdAt: string;
        summary?: string | null;
        status: Billing__Invoice__Status;
        amountDue: number;
      }>;
    } | null;
  };
};

export type SavePaymentPlanOfferMutationVariables = Exact<{
  input: PaymentPlan__Offer__SaveInput;
}>;

export type SavePaymentPlanOfferMutation = {
  __typename?: 'Mutation';
  saveOffer: {
    __typename?: 'PaymentPlan__Offer__SavePayload';
    error?: string | null;
    status: Status;
    offer?: {
      __typename?: 'PaymentPlan__Offer';
      id: string;
      state: PaymentPlan__Offer__State;
      type: PaymentPlan__Offer__Type;
      oneTimeFee?: number | null;
      expiry: string;
      expired: boolean;
      createdAt: string;
      acceptedAt?: string | null;
      storageTerm?: number | null;
      storageTermEndDate?: string | null;
      monthlyFee?: number | null;
      monthlyFeeTerm?: number | null;
      monthlyFeeTermEndDate?: string | null;
      account: {
        __typename?: 'Account';
        id: string;
        number: string;
        customer: { __typename?: 'Customer'; id: string; name: string };
      };
      user: { __typename?: 'User'; id: string; name: string };
      settledInvoices: Array<{
        __typename?: 'Billing__Invoice';
        id: string;
        createdAt: string;
        summary?: string | null;
        status: Billing__Invoice__Status;
        amountDue: number;
      }>;
    } | null;
  };
};

export type UnforgivenInvoicesQueryVariables = Exact<{
  accountID: Scalars['ID'];
  statuses?: InputMaybe<Array<Billing__Invoice__Status> | Billing__Invoice__Status>;
}>;

export type UnforgivenInvoicesQuery = {
  __typename?: 'Query';
  invoices: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    createdAt: string;
    summary?: string | null;
    status: Billing__Invoice__Status;
    amountDue: number;
  }>;
};

export type PaymentPlanOfferQueryVariables = Exact<{
  accountID: Scalars['ID'];
  offerID: Scalars['ID'];
}>;

export type PaymentPlanOfferQuery = {
  __typename?: 'Query';
  offer: {
    __typename?: 'PaymentPlan__Offer';
    id: string;
    state: PaymentPlan__Offer__State;
    type: PaymentPlan__Offer__Type;
    oneTimeFee?: number | null;
    expiry: string;
    expired: boolean;
    createdAt: string;
    acceptedAt?: string | null;
    storageTerm?: number | null;
    storageTermEndDate?: string | null;
    monthlyFee?: number | null;
    monthlyFeeTerm?: number | null;
    monthlyFeeTermEndDate?: string | null;
    account: {
      __typename?: 'Account';
      id: string;
      number: string;
      customer: { __typename?: 'Customer'; id: string; name: string };
    };
    user: { __typename?: 'User'; id: string; name: string };
    settledInvoices: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      createdAt: string;
      summary?: string | null;
      status: Billing__Invoice__Status;
      amountDue: number;
    }>;
  };
};

export type BuildCustomerTicketFromCallMutationVariables = Exact<{
  input: CustomerTickets__BuildFromCallInput;
}>;

export type BuildCustomerTicketFromCallMutation = {
  __typename?: 'Mutation';
  buildCustomerTicketFromCall: { __typename?: 'BuildFromCallPayload'; status: Status; error?: string | null };
};

export type BuildInteractionFromCallMutationVariables = Exact<{
  input: CustomerTickets__BuildInteractionFromCallInput;
}>;

export type BuildInteractionFromCallMutation = {
  __typename?: 'Mutation';
  buildInteractionFromCall: { __typename?: 'BuildInteractionFromCallPayload'; status: Status; error?: string | null };
};

export type CustomerTicketByCallQueryVariables = Exact<{
  sid: Scalars['String'];
}>;

export type CustomerTicketByCallQuery = {
  __typename?: 'Query';
  customerTicketByCall: {
    __typename?: 'CustomerTicket';
    id: string;
    category: string;
    subCategory?: string | null;
    priority?: CustomerTicket__Priority | null;
    assignee?: { __typename?: 'User'; id: string; name: string } | null;
    group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
  };
};

export type ProcessCustomerTicketFromCallMutationVariables = Exact<{
  input: CustomerTickets__ProcessFromCallInput;
}>;

export type ProcessCustomerTicketFromCallMutation = {
  __typename?: 'Mutation';
  processCustomerTicketFromCall: {
    __typename?: 'ProcessFromCallPayload';
    status: Status;
    error?: string | null;
    customerTicket?: {
      __typename?: 'CustomerTicket';
      id: string;
      category: string;
      subCategory?: string | null;
      description?: string | null;
      state: string;
      createdAt: string;
      actionRequiredAt?: string | null;
      customerUpdatedAt?: string | null;
      priority?: CustomerTicket__Priority | null;
      contactPreference?: CustomerTicket__ContactPreference | null;
      allowIncomingCalls: boolean;
      customerTicketableType: string;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      creator?: { __typename?: 'User'; id: string; name: string } | null;
      customerTicketable:
        | { __typename?: 'Account'; id: string; shortDisplay: string }
        | { __typename?: 'Lead'; id: string; shortDisplay: string };
      group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
    } | null;
  };
};

export type ConnectPhoneCallMutationVariables = Exact<{
  input: ConnectPhoneCallInput;
}>;

export type ConnectPhoneCallMutation = {
  __typename?: 'Mutation';
  connectPhoneCall: { __typename?: 'ConnectPhoneCallPayload'; error?: string | null; status: Status };
};

export type AccountsQueryVariables = Exact<{
  query: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedAccount';
    results: Array<{
      __typename?: 'Account';
      id: string;
      number: string;
      customer: { __typename?: 'Customer'; id: string; name: string; phone?: string | null };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type PredecessorOrderSelectorQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type PredecessorOrderSelectorQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    id: string;
    scheduled: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    number: string;
  }>;
};

export type PaginatedPricingUploadsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedPricingUploadsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'Pricing__PaginatedUpload';
    results: Array<{
      __typename?: 'Pricing__Upload';
      id: string;
      description: string;
      state: Pricing__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      service: Pricing__Upload__Service;
      user: { __typename?: 'User'; id: string; name: string };
      spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type PricingUploadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PricingUploadQuery = {
  __typename?: 'Query';
  upload: {
    __typename?: 'Pricing__Upload';
    id: string;
    description: string;
    state: Pricing__Upload__State;
    error?: string | null;
    processingAt?: string | null;
    processedAt?: string | null;
    service: Pricing__Upload__Service;
    user: { __typename?: 'User'; id: string; name: string };
    spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
  };
};

export type SavePricingUploadMutationVariables = Exact<{
  input: Pricing__UploadInput;
}>;

export type SavePricingUploadMutation = {
  __typename?: 'Mutation';
  save: {
    __typename?: 'Pricing__Upload__SavePayload';
    upload: {
      __typename?: 'Pricing__Upload';
      id: string;
      description: string;
      state: Pricing__Upload__State;
      error?: string | null;
      processingAt?: string | null;
      processedAt?: string | null;
      service: Pricing__Upload__Service;
      user: { __typename?: 'User'; id: string; name: string };
      spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
    };
  };
};

export type PaginatedPtoRequestGroupsQueryVariables = Exact<{
  filters?: InputMaybe<Pto__RequestFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedPtoRequestGroupsQuery = {
  __typename?: 'Query';
  paginatedPtoRequestGroups: {
    __typename?: 'PTO__PaginatedRequestGroupType';
    results: Array<{
      __typename?: 'PTO__RequestGroupType';
      id: string;
      createdAt: string;
      endDate: string;
      startDate: string;
      state: Pto__RequestState;
      totalHours: number;
      reviewerNote?: string | null;
      requests: Array<{ __typename?: 'PTO__RequestType'; id: string; hours: number; requestDate: string }>;
      kind: { __typename?: 'PTO__KindType'; id: string; code: string; name: string };
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        team?: { __typename?: 'Team'; id: string; name: string } | null;
      };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
  users: Array<{ __typename?: 'User'; id: string; name: string }>;
};

export type PtoRequestGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PtoRequestGroupQuery = {
  __typename?: 'Query';
  ptoRequestGroup: {
    __typename?: 'PTO__RequestGroupType';
    id: string;
    totalHours: number;
    requests: Array<{
      __typename?: 'PTO__RequestType';
      id: string;
      hours: number;
      requestDate: string;
      conflictingShiftAssignmentExists: boolean;
    }>;
    user: { __typename?: 'User'; id: string; name: string };
    kind: { __typename?: 'PTO__KindType'; id: string; name: string };
  };
};

export type PtoRequestGroupReviewMutationVariables = Exact<{
  input: Workforce__PtoRequestGroupReview__Input;
}>;

export type PtoRequestGroupReviewMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Workforce__PTORequestGroupReviewTypePayload';
    requestGroup?: {
      __typename?: 'PTO__RequestGroupType';
      id: string;
      reviewerNote?: string | null;
      state: Pto__RequestState;
    } | null;
  };
};

export type GenerateTestPasswordMutationVariables = Exact<{
  resourceID: Scalars['ID'];
  resourceType: Scalars['String'];
}>;

export type GenerateTestPasswordMutation = {
  __typename?: 'Mutation';
  generate: {
    __typename?: 'GenerateTestPasswordPayload';
    status: Status;
    error?: string | null;
    password?: string | null;
  };
};

export type PaginatedRateAdjustmentSchedulingsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<RateAdjustment__Scheduling__FiltersInput>;
}>;

export type PaginatedRateAdjustmentSchedulingsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'RateAdjustment__PaginatedScheduling';
    results: Array<{
      __typename?: 'RateAdjustment__Scheduling';
      id: string;
      state: RateAdjustment__Scheduling__State;
      createdAt: string;
      effectiveAt: string;
      oldPrice: number;
      newPrice: number;
      percentageWithSign: string;
      label?: string | null;
      account: {
        __typename?: 'Account';
        id: string;
        customer: { __typename?: 'Customer'; id: string; name: string; phone?: string | null };
      };
      selfStorageRental?: {
        __typename?: 'SelfStorage__Rental';
        id: string;
        unit: {
          __typename?: 'SelfStorage__Unit';
          id: string;
          name: string;
          classification: {
            __typename?: 'SelfStorage__Classification';
            id: string;
            length: number;
            width: number;
            height: number;
            indoors: boolean;
            climate: boolean;
            electricity: boolean;
            firstFloor: boolean;
            obstructed: boolean;
            category: SelfStorage__Classification__Category;
            kind: {
              __typename?: 'SelfStorage__Kind';
              id: string;
              name: string;
              subtype?: SelfStorage__Kind__Subtype | null;
            };
          };
          facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
        };
      } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ConfirmRateAdjustmentUploadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ConfirmRateAdjustmentUploadMutation = {
  __typename?: 'Mutation';
  confirmRateAdjustmentUpload: { __typename?: 'RateAdjustment__ConfirmUploadPayload'; status: Status };
};

export type PreviewRateAdjustmentUploadMutationVariables = Exact<{
  input: RateAdjustment__UploadInput;
}>;

export type PreviewRateAdjustmentUploadMutation = {
  __typename?: 'Mutation';
  previewRateAdjustmentUpload: {
    __typename?: 'RateAdjustment__PreviewUploadPayload';
    status: Status;
    error?: string | null;
    upload?: { __typename?: 'RateAdjustment__Upload'; id: string } | null;
    drafts?: Array<{
      __typename?: 'RateAdjustment__Draft';
      accountID: string;
      rentalID?: string | null;
      newPrice: number;
      effectiveMonthYear: string;
      excludeFromEmails: boolean;
      bestOffer?: number | null;
      label?: string | null;
    }> | null;
  };
};

export type PricingSetRateGroupsQueryVariables = Exact<{
  pricingSetId: Scalars['ID'];
}>;

export type PricingSetRateGroupsQuery = {
  __typename?: 'Query';
  rateGroups: Array<{ __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean }>;
};

export type StorageTermsQueryVariables = Exact<{ [key: string]: never }>;

export type StorageTermsQuery = {
  __typename?: 'Query';
  storageTerms: Array<{
    __typename?: 'StorageTerm';
    id: string;
    name: string;
    minimumMonths: number;
    rateGroup: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };
  }>;
};

export type PaginatedReferableAccountsQueryVariables = Exact<{
  query: Scalars['String'];
  referable: Scalars['Boolean'];
}>;

export type PaginatedReferableAccountsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedAccount';
    results: Array<{
      __typename?: 'Account';
      id: string;
      number: string;
      referable: boolean;
      customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type RegionCreateMutationVariables = Exact<{
  input: RegionCreate__Input;
}>;

export type RegionCreateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'RegionCreatePayload';
    status: Status;
    error?: string | null;
    region?: { __typename?: 'Region'; id: string } | null;
  };
};

export type RegionsQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsQuery = {
  __typename?: 'Query';
  regions: Array<{
    __typename?: 'Region';
    id: string;
    active: boolean;
    name: string;
    tz: string;
    parent?: { __typename?: 'Region'; name: string } | null;
  }>;
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string }>;
};

export type RegionQueryVariables = Exact<{
  regionID: Scalars['ID'];
}>;

export type RegionQuery = {
  __typename?: 'Query';
  region: {
    __typename?: 'Region';
    id: string;
    active: boolean;
    name: string;
    tz: string;
    hexColor: string;
    createdAt: string;
    updatedAt: string;
    zipCodes: Array<string>;
    storageExtendedServiceAreaPostalCodes: Array<string>;
    movingExtendedServiceAreaPostalCodes: Array<string>;
    irmCluster: IrmClusterEnum;
    irmTerritory: IrmTerritoryEnum;
    geo: ClutterGeoEnum;
    netsuiteRegionID?: number | null;
    fulfiller: RegionFulfillerEnum;
    parent?: { __typename?: 'Region'; id: string; name: string } | null;
    center: { __typename?: 'LocatablePoint'; latitude: number; longitude: number };
    operatingScheduleTemplates: Array<{
      __typename?: 'OperatingScheduleTemplate';
      id: string;
      effectiveDate: string;
      operatesSunday: boolean;
      operatesMonday: boolean;
      operatesTuesday: boolean;
      operatesWednesday: boolean;
      operatesThursday: boolean;
      operatesFriday: boolean;
      operatesSaturday: boolean;
      orderType?: OrderTypeEnum | null;
      orderSubtype?: OrderSubtypeEnum | null;
    }>;
    defaultDepot?: { __typename?: 'Depot'; id: string } | null;
    defaultWarehouse?: { __typename?: 'Warehouse'; id: string; name: string } | null;
  };
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string }>;
};

export type ModifyRegionMutationVariables = Exact<{
  input: ModifyRegionInput;
  id: Scalars['ID'];
}>;

export type ModifyRegionMutation = {
  __typename?: 'Mutation';
  modifyRegion: {
    __typename?: 'ModifyRegionPayload';
    error?: string | null;
    region?: {
      __typename?: 'Region';
      id: string;
      active: boolean;
      name: string;
      tz: string;
      hexColor: string;
      createdAt: string;
      updatedAt: string;
      zipCodes: Array<string>;
      storageExtendedServiceAreaPostalCodes: Array<string>;
      movingExtendedServiceAreaPostalCodes: Array<string>;
      irmCluster: IrmClusterEnum;
      irmTerritory: IrmTerritoryEnum;
      geo: ClutterGeoEnum;
      netsuiteRegionID?: number | null;
      fulfiller: RegionFulfillerEnum;
      parent?: { __typename?: 'Region'; id: string; name: string } | null;
      center: { __typename?: 'LocatablePoint'; latitude: number; longitude: number };
      operatingScheduleTemplates: Array<{
        __typename?: 'OperatingScheduleTemplate';
        id: string;
        effectiveDate: string;
        operatesSunday: boolean;
        operatesMonday: boolean;
        operatesTuesday: boolean;
        operatesWednesday: boolean;
        operatesThursday: boolean;
        operatesFriday: boolean;
        operatesSaturday: boolean;
        orderType?: OrderTypeEnum | null;
        orderSubtype?: OrderSubtypeEnum | null;
      }>;
      defaultDepot?: { __typename?: 'Depot'; id: string } | null;
      defaultWarehouse?: { __typename?: 'Warehouse'; id: string; name: string } | null;
    } | null;
  };
};

export type RegionEditQueryVariables = Exact<{
  regionID: Scalars['ID'];
}>;

export type RegionEditQuery = {
  __typename?: 'Query';
  region: {
    __typename?: 'Region';
    id: string;
    name: string;
    zipCodes: Array<string>;
    storageExtendedServiceAreaPostalCodes: Array<string>;
    movingExtendedServiceAreaPostalCodes: Array<string>;
  };
};

export type RegionsSelectorQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsSelectorQuery = {
  __typename?: 'Query';
  regions: Array<{ __typename?: 'Region'; id: string; name: string; tz: string }>;
};

export type ModifySelfStorageDefaultSourceMutationVariables = Exact<{
  subscriptionID: Scalars['ID'];
  sourceID?: InputMaybe<Scalars['ID']>;
  billing: SelfStorage__Rental__BillingEnum;
}>;

export type ModifySelfStorageDefaultSourceMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'SelfStorage__Billing__DefaultSource__ModifyPayload';
    status: Status;
    error?: string | null;
    subscription?: {
      __typename?: 'SelfStorage__Subscription';
      id: string;
      billing: SelfStorage__Rental__BillingEnum;
      defaultSource?: {
        __typename?: 'Billing__Source';
        id: string;
        stripeID: string;
        kind: Billing__Source__Kind;
        fundingType: Billing__Source__FundingType;
        number: string;
        brand?: string | null;
        name?: string | null;
        expiration?: string | null;
        expired: boolean;
        default: boolean;
        active: boolean;
        billable: boolean;
        cvcCheck?: Billing__Source__CvcCheck | null;
        zipCheck?: Billing__Source__ZipCheck | null;
      } | null;
    } | null;
  };
};

export type SelfStorageSubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageSubscriptionQuery = {
  __typename?: 'Query';
  subscription: {
    __typename?: 'SelfStorage__Subscription';
    id: string;
    billing: SelfStorage__Rental__BillingEnum;
    activatedAt?: string | null;
    billsOn?: string | null;
    canceledAt?: string | null;
    account: { __typename?: 'Account'; id: string };
    rentals: Array<{ __typename?: 'SelfStorage__Rental'; id: string; name: string }>;
    defaultSource?: {
      __typename?: 'Billing__Source';
      id: string;
      stripeID: string;
      kind: Billing__Source__Kind;
      fundingType: Billing__Source__FundingType;
      number: string;
      brand?: string | null;
      name?: string | null;
      expiration?: string | null;
      expired: boolean;
      default: boolean;
      active: boolean;
      billable: boolean;
      cvcCheck?: Billing__Source__CvcCheck | null;
      zipCheck?: Billing__Source__ZipCheck | null;
    } | null;
  };
};

export type SelfStorageRentalPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type SelfStorageRentalPolicyQuery = {
  __typename?: 'Query';
  permissions: { __typename?: 'SelfStorage__RentalPolicy'; modify: boolean; new: boolean };
};

export type SelfStorageRentalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageRentalQuery = {
  __typename?: 'Query';
  rental: {
    __typename?: 'SelfStorage__Rental';
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
    subscriptionItems: Array<{
      __typename?: 'SelfStorage__SubscriptionItem';
      id: string;
      rate: number;
      status: SelfStorage__Status;
      summary: string;
      activatedAt?: string | null;
      canceledAt?: string | null;
    }>;
    signatures: Array<{
      __typename?: 'SelfStorage__Signature';
      id: string;
      document: { __typename?: 'SelfStorage__Document'; id: string; name: string };
      contract?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
    }>;
    subscription: {
      __typename?: 'SelfStorage__Subscription';
      id: string;
      account: { __typename?: 'Account'; id: string };
    };
    rateAdjustmentSchedulings: Array<{
      __typename?: 'RateAdjustment__Scheduling';
      id: string;
      effectiveAt: string;
      newPrice: number;
      selfStorageRental?: {
        __typename?: 'SelfStorage__Rental';
        id: string;
        unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string };
      } | null;
    }>;
  };
};

export type SelfStorageRentalsWithUnitsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type SelfStorageRentalsWithUnitsQuery = {
  __typename?: 'Query';
  rentals: Array<{ __typename?: 'SelfStorage__Rental'; id: string; name: string }>;
};

export type SelfStorageAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type SelfStorageAddressesQuery = {
  __typename?: 'Query';
  facilities: Array<{
    __typename?: 'SelfStorage__Facility';
    id: string;
    name: string;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      schedulable: boolean;
    };
  }>;
};

export type ModifySelfStorageBillingMutationVariables = Exact<{
  accountID: Scalars['ID'];
  inputs: Array<SelfStorage__BillingLineInput> | SelfStorage__BillingLineInput;
}>;

export type ModifySelfStorageBillingMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__Billing__ModifyPayload'; status: Status; error?: string | null };
};

export type AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountWithSelfStorageRentalsAndBillingDetailsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
    rentals: Array<{
      __typename?: 'SelfStorage__Rental';
      unitRate: number;
      id: string;
      status: SelfStorage__Status;
      overlocked: boolean;
      rate: number;
      activatedAt?: string | null;
      canceledAt?: string | null;
      fees: Array<{
        __typename?: 'SelfStorage__Fee';
        id: string;
        facilityID: string;
        kind: SelfStorage__Fee__Kind;
        price: number;
        recurring: boolean;
        waivable: boolean;
      }>;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
      protection?: { __typename?: 'SelfStorage__Protection'; id: string; name: string; price: number } | null;
      subscription: {
        __typename?: 'SelfStorage__Subscription';
        id: string;
        billing: SelfStorage__Rental__BillingEnum;
        activatedAt?: string | null;
        canceledAt?: string | null;
        billsOn?: string | null;
        defaultSource?: {
          __typename?: 'Billing__Source';
          id: string;
          brand?: string | null;
          default: boolean;
          fundingType: Billing__Source__FundingType;
          number: string;
        } | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
        };
      };
      unit: {
        __typename?: 'SelfStorage__Unit';
        id: string;
        floor?: number | null;
        name: string;
        facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
        classification: {
          __typename?: 'SelfStorage__Classification';
          id: string;
          length: number;
          width: number;
          height: number;
          indoors: boolean;
          climate: boolean;
          electricity: boolean;
          firstFloor: boolean;
          obstructed: boolean;
          category: SelfStorage__Classification__Category;
          kind: {
            __typename?: 'SelfStorage__Kind';
            id: string;
            name: string;
            subtype?: SelfStorage__Kind__Subtype | null;
          };
        };
      };
    }>;
  };
};

export type PaginatedSelfStorageFacilitiesQueryVariables = Exact<{
  filters?: InputMaybe<SelfStorage__Facility__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedSelfStorageFacilitiesQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'SelfStorage__PaginatedFacility';
    results: Array<{
      __typename?: 'SelfStorage__Facility';
      id: string;
      active: boolean;
      name: string;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
      };
      region?: { __typename?: 'Region'; id: string; name: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type SelfStorageFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type SelfStorageFacilitiesQuery = {
  __typename?: 'Query';
  facilities: Array<{ __typename?: 'SelfStorage__Facility'; id: string; name: string }>;
};

export type SelfStorageCurrentFacilityFeesQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type SelfStorageCurrentFacilityFeesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    fees: Array<{
      __typename?: 'SelfStorage__Fee';
      id: string;
      facilityID: string;
      kind: SelfStorage__Fee__Kind;
      price: number;
      recurring: boolean;
      waivable: boolean;
    }>;
  };
};

export type SelfStorageFeesQueryVariables = Exact<{
  facilityID: Scalars['ID'];
}>;

export type SelfStorageFeesQuery = {
  __typename?: 'Query';
  fees: Array<{
    __typename?: 'SelfStorage__Fee';
    id: string;
    facilityID: string;
    kind: SelfStorage__Fee__Kind;
    price: number;
    recurring: boolean;
    waivable: boolean;
  }>;
};

export type ReservableSelfStorageUnitGroupsQueryVariables = Exact<{
  facilityID: Scalars['ID'];
}>;

export type ReservableSelfStorageUnitGroupsQuery = {
  __typename?: 'Query';
  groups: Array<{
    __typename?: 'SelfStorage__UnitGroup';
    remaining: number;
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    };
    price: { __typename?: 'SelfStorage__Price'; id: string; rate: number; pushRate: number; quotedRate: number };
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
  }>;
};

export type SelfStoragePartnersQueryVariables = Exact<{ [key: string]: never }>;

export type SelfStoragePartnersQuery = {
  __typename?: 'Query';
  partners: Array<{
    __typename?: 'SelfStorage__Partner';
    id: string;
    name: string;
    company?: SelfStorage__Partner__Company | null;
  }>;
};

export type SelfStorageProrationQueryVariables = Exact<{
  accountID: Scalars['ID'];
  facilityID: Scalars['ID'];
}>;

export type SelfStorageProrationQuery = {
  __typename?: 'Query';
  proration?: { __typename?: 'SelfStorage__Proration'; ratio: number; periodEnd: string } | null;
};

export type SelfStorageProtectionsQueryVariables = Exact<{
  facilityID: Scalars['ID'];
}>;

export type SelfStorageProtectionsQuery = {
  __typename?: 'Query';
  protections: Array<{ __typename?: 'SelfStorage__Protection'; id: string; name: string; price: number }>;
};

export type CancelSelfStorageRentalMutationVariables = Exact<{
  id: Scalars['ID'];
  prorate: Scalars['Boolean'];
  skipDelinquentVerification?: InputMaybe<Scalars['Boolean']>;
}>;

export type CancelSelfStorageRentalMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__Rental__CancelPayload'; status: Status; error?: string | null };
};

export type AccountWithSelfStorageRentalsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountWithSelfStorageRentalsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    delinquent: boolean;
    balance: number;
    customer: { __typename?: 'Customer'; id: string; name: string };
    rentals: Array<{
      __typename?: 'SelfStorage__Rental';
      id: string;
      status: SelfStorage__Status;
      overlocked: boolean;
      rate: number;
      activatedAt?: string | null;
      canceledAt?: string | null;
      subscription: {
        __typename?: 'SelfStorage__Subscription';
        id: string;
        billing: SelfStorage__Rental__BillingEnum;
        activatedAt?: string | null;
        canceledAt?: string | null;
        billsOn?: string | null;
        defaultSource?: {
          __typename?: 'Billing__Source';
          id: string;
          brand?: string | null;
          default: boolean;
          fundingType: Billing__Source__FundingType;
          number: string;
        } | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
        };
      };
      unit: {
        __typename?: 'SelfStorage__Unit';
        id: string;
        floor?: number | null;
        name: string;
        facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
        classification: {
          __typename?: 'SelfStorage__Classification';
          id: string;
          length: number;
          width: number;
          height: number;
          indoors: boolean;
          climate: boolean;
          electricity: boolean;
          firstFloor: boolean;
          obstructed: boolean;
          category: SelfStorage__Classification__Category;
          kind: {
            __typename?: 'SelfStorage__Kind';
            id: string;
            name: string;
            subtype?: SelfStorage__Kind__Subtype | null;
          };
        };
      };
    }>;
  };
};

export type SelfStorageAccountForRentingQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type SelfStorageAccountForRentingQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: {
      __typename?: 'Customer';
      id: string;
      name: string;
      cellPhone?: string | null;
      homePhone?: string | null;
      workPhone?: string | null;
    };
    addresses: Array<{
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      kinds: Array<string>;
    }>;
    sources: Array<{
      __typename?: 'Billing__Source';
      id: string;
      brand?: string | null;
      kind: Billing__Source__Kind;
      number: string;
      expiration?: string | null;
      default: boolean;
      billable: boolean;
    }>;
    policy?: {
      __typename?: 'SelfStorage__Policy';
      id: string;
      kind: SelfStorage__Policy__Kind;
      number: string;
      company: string;
      amount: number;
      from: string;
      till: string;
    } | null;
    identification?: {
      __typename?: 'SelfStorage__Identification';
      id: string;
      number: string;
      state: string;
      birthday: string;
    } | null;
    subscriptions: Array<{
      __typename?: 'SelfStorage__Subscription';
      id: string;
      billing: SelfStorage__Rental__BillingEnum;
      billsOn?: string | null;
      source?: { __typename?: 'Billing__Source'; id: string } | null;
      rentals: Array<{ __typename?: 'SelfStorage__Rental'; id: string; status: SelfStorage__Status }>;
    }>;
  };
};

export type GenerateSelfStorageGateCodeMutationVariables = Exact<{
  accountID?: InputMaybe<Scalars['ID']>;
  facilityID: Scalars['ID'];
}>;

export type GenerateSelfStorageGateCodeMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__GenerateGateCodePayload'; value: string };
};

export type RentSelfStorageMutationVariables = Exact<{
  input: SelfStorage__RentInput;
}>;

export type RentSelfStorageMutation = {
  __typename?: 'Mutation';
  rent: {
    __typename?: 'SelfStorage__RentPayload';
    status: Status;
    error?: string | null;
    account?: { __typename?: 'Account'; id: string } | null;
  };
};

export type OverlockSelfStorageRentalMutationVariables = Exact<{
  id: Scalars['ID'];
  overlocked: Scalars['Boolean'];
}>;

export type OverlockSelfStorageRentalMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'SelfStorage__Rental__OverlockPayload';
    rental: { __typename?: 'SelfStorage__Rental'; id: string; overlocked: boolean };
  };
};

export type PaginatedSelfStorageRentalsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<SelfStorage__RentalFiltersInput>;
}>;

export type PaginatedSelfStorageRentalsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'SelfStorage__PaginatedRental';
    results: Array<{
      __typename?: 'SelfStorage__Rental';
      id: string;
      status: SelfStorage__Status;
      overlocked: boolean;
      rate: number;
      activatedAt?: string | null;
      canceledAt?: string | null;
      subscription: {
        __typename?: 'SelfStorage__Subscription';
        id: string;
        billing: SelfStorage__Rental__BillingEnum;
        activatedAt?: string | null;
        canceledAt?: string | null;
        billsOn?: string | null;
        defaultSource?: {
          __typename?: 'Billing__Source';
          id: string;
          brand?: string | null;
          default: boolean;
          fundingType: Billing__Source__FundingType;
          number: string;
        } | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
        };
      };
      unit: {
        __typename?: 'SelfStorage__Unit';
        id: string;
        floor?: number | null;
        name: string;
        facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
        classification: {
          __typename?: 'SelfStorage__Classification';
          id: string;
          length: number;
          width: number;
          height: number;
          indoors: boolean;
          climate: boolean;
          electricity: boolean;
          firstFloor: boolean;
          obstructed: boolean;
          category: SelfStorage__Classification__Category;
          kind: {
            __typename?: 'SelfStorage__Kind';
            id: string;
            name: string;
            subtype?: SelfStorage__Kind__Subtype | null;
          };
        };
      };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type GenerateSelfStorageSigningMutationVariables = Exact<{
  channel: Scalars['ID'];
  input: SelfStorage__RentInput;
}>;

export type GenerateSelfStorageSigningMutation = {
  __typename?: 'Mutation';
  signing: {
    __typename?: 'SelfStorage__GenerateSigningPayload';
    url: string;
    code: string;
    signatures: Array<{
      __typename?: 'SelfStorage__Signature';
      id: string;
      document: { __typename?: 'SelfStorage__Document'; id: string; name: string };
    }>;
  };
};

export type BookSelfStorageReservationMutationVariables = Exact<{
  input: SelfStorage__Reservation__BuildInput;
}>;

export type BookSelfStorageReservationMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'SelfStorage__Reservation__BuildPayload';
    error?: string | null;
    status: Status;
    reservation?: {
      __typename?: 'SelfStorage__Reservation';
      id: string;
      date?: string | null;
      name: string;
      email: string;
      phone: string;
      state: SelfStorage__Reservation__State;
      length?: number | null;
      width?: number | null;
      unitType?: string | null;
      quotedPrice: number;
      tags: Array<string>;
      confirmationAttemptDate?: string | null;
      externalConfirmationNumber?: string | null;
      smsSubscribed: boolean;
      reservedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      classification?: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      } | null;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        bookingLink: string;
        phoneNumber?: string | null;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          safeAndSanitary?: boolean | null;
        };
        partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
      };
      lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
      user: { __typename?: 'User'; id: string; name: string };
      reserver?: { __typename?: 'User'; id: string; name: string } | null;
      price?: {
        __typename?: 'SelfStorage__Price';
        id: string;
        rate: number;
        pushRate: number;
        quotedRate: number;
      } | null;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type CancelSelfStorageReservationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelSelfStorageReservationMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'SelfStorage__Reservation__CancelPayload';
    reservation?: {
      __typename?: 'SelfStorage__Reservation';
      id: string;
      date?: string | null;
      name: string;
      email: string;
      phone: string;
      state: SelfStorage__Reservation__State;
      length?: number | null;
      width?: number | null;
      unitType?: string | null;
      quotedPrice: number;
      tags: Array<string>;
      confirmationAttemptDate?: string | null;
      externalConfirmationNumber?: string | null;
      smsSubscribed: boolean;
      reservedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      classification?: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      } | null;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        bookingLink: string;
        phoneNumber?: string | null;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          safeAndSanitary?: boolean | null;
        };
        partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
      };
      lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
      user: { __typename?: 'User'; id: string; name: string };
      reserver?: { __typename?: 'User'; id: string; name: string } | null;
      price?: {
        __typename?: 'SelfStorage__Price';
        id: string;
        rate: number;
        pushRate: number;
        quotedRate: number;
      } | null;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
    } | null;
  };
};

export type ModifySelfStorageReservationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ModifyInput;
}>;

export type ModifySelfStorageReservationMutation = {
  __typename?: 'Mutation';
  modify: { __typename?: 'SelfStorage__Reservation__ModifyPayload'; status: Status; error?: string | null };
};

export type PaginatedSelfStorageReservationsQueryVariables = Exact<{
  filters?: InputMaybe<SelfStorage__Reservation__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedSelfStorageReservationsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'SelfStorage__PaginatedReservation';
    results: Array<{
      __typename?: 'SelfStorage__Reservation';
      id: string;
      date?: string | null;
      name: string;
      email: string;
      phone: string;
      state: SelfStorage__Reservation__State;
      length?: number | null;
      width?: number | null;
      unitType?: string | null;
      quotedPrice: number;
      tags: Array<string>;
      confirmationAttemptDate?: string | null;
      externalConfirmationNumber?: string | null;
      smsSubscribed: boolean;
      reservedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      classification?: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      } | null;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        bookingLink: string;
        phoneNumber?: string | null;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          safeAndSanitary?: boolean | null;
        };
        partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
      };
      lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
      user: { __typename?: 'User'; id: string; name: string };
      reserver?: { __typename?: 'User'; id: string; name: string } | null;
      price?: {
        __typename?: 'SelfStorage__Price';
        id: string;
        rate: number;
        pushRate: number;
        quotedRate: number;
      } | null;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type SelfStorageReservationPolicyQueryVariables = Exact<{
  reservationID?: InputMaybe<Scalars['ID']>;
}>;

export type SelfStorageReservationPolicyQuery = {
  __typename?: 'Query';
  permissions: { __typename?: 'SelfStorage__ReservationPolicy'; modify: boolean; new: boolean };
};

export type SelfStorageReservationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageReservationQuery = {
  __typename?: 'Query';
  reservation: {
    __typename?: 'SelfStorage__Reservation';
    id: string;
    date?: string | null;
    name: string;
    email: string;
    phone: string;
    state: SelfStorage__Reservation__State;
    length?: number | null;
    width?: number | null;
    unitType?: string | null;
    quotedPrice: number;
    tags: Array<string>;
    confirmationAttemptDate?: string | null;
    externalConfirmationNumber?: string | null;
    smsSubscribed: boolean;
    reservedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    account?: { __typename?: 'Account'; id: string } | null;
    shuttleBookingUrl?: { __typename?: 'ShortenedURL'; id: string; url: string } | null;
    classification?: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    } | null;
    facility: {
      __typename?: 'SelfStorage__Facility';
      id: string;
      name: string;
      bookingLink: string;
      phoneNumber?: string | null;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        safeAndSanitary?: boolean | null;
      };
      partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
    };
    lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
    user: { __typename?: 'User'; id: string; name: string };
    reserver?: { __typename?: 'User'; id: string; name: string } | null;
    price?: {
      __typename?: 'SelfStorage__Price';
      id: string;
      rate: number;
      pushRate: number;
      quotedRate: number;
    } | null;
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
  };
};

export type SelfStorageCurrentUserTokenQueryVariables = Exact<{ [key: string]: never }>;

export type SelfStorageCurrentUserTokenQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; token: string };
};

export type ModifySelfStorageThirdPartyReservationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SelfStorage__Reservation__ThirdPartyModifyInput;
}>;

export type ModifySelfStorageThirdPartyReservationMutation = {
  __typename?: 'Mutation';
  modify: {
    __typename?: 'SelfStorage__Reservation__ThirdPartyModifyPayload';
    status: Status;
    error?: string | null;
    reservation?: {
      __typename?: 'SelfStorage__Reservation';
      id: string;
      date?: string | null;
      name: string;
      email: string;
      phone: string;
      state: SelfStorage__Reservation__State;
      length?: number | null;
      width?: number | null;
      unitType?: string | null;
      quotedPrice: number;
      tags: Array<string>;
      confirmationAttemptDate?: string | null;
      externalConfirmationNumber?: string | null;
      smsSubscribed: boolean;
      reservedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      classification?: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      } | null;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        bookingLink: string;
        phoneNumber?: string | null;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          safeAndSanitary?: boolean | null;
        };
        partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
      };
      lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
      user: { __typename?: 'User'; id: string; name: string };
      reserver?: { __typename?: 'User'; id: string; name: string } | null;
      price?: {
        __typename?: 'SelfStorage__Price';
        id: string;
        rate: number;
        pushRate: number;
        quotedRate: number;
      } | null;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
    } | null;
  };
};

export type SelfStorageShuttleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageShuttleQuery = {
  __typename?: 'Query';
  shuttle?: {
    __typename?: 'SelfStorage__Shuttle';
    id: string;
    reservation: {
      __typename?: 'SelfStorage__Reservation';
      id: string;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        address: {
          __typename?: 'Address';
          map: string;
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          safeAndSanitary?: boolean | null;
        };
      };
    };
  } | null;
};

export type SelfStorageUpcomingReservationsQueryVariables = Exact<{
  facilityID: Scalars['ID'];
}>;

export type SelfStorageUpcomingReservationsQuery = {
  __typename?: 'Query';
  reservations: Array<{
    __typename?: 'SelfStorage__Reservation';
    id: string;
    date?: string | null;
    name: string;
    email: string;
    phone: string;
    state: SelfStorage__Reservation__State;
    length?: number | null;
    width?: number | null;
    unitType?: string | null;
    quotedPrice: number;
    tags: Array<string>;
    confirmationAttemptDate?: string | null;
    externalConfirmationNumber?: string | null;
    smsSubscribed: boolean;
    reservedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    order?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    classification?: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    } | null;
    facility: {
      __typename?: 'SelfStorage__Facility';
      id: string;
      name: string;
      bookingLink: string;
      phoneNumber?: string | null;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        safeAndSanitary?: boolean | null;
      };
      partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
    };
    lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
    user: { __typename?: 'User'; id: string; name: string };
    reserver?: { __typename?: 'User'; id: string; name: string } | null;
    price?: {
      __typename?: 'SelfStorage__Price';
      id: string;
      rate: number;
      pushRate: number;
      quotedRate: number;
    } | null;
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
  }>;
};

export type DestroySelfStoragePromotionBatchMutationVariables = Exact<{
  input: SelfStorage__DestroyPromotionBatchInput;
}>;

export type DestroySelfStoragePromotionBatchMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__DestroyPromotionBatchPayload'; status: Status };
};

export type SelfStorageUnitQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageUnitQuery = {
  __typename?: 'Query';
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    rentable: boolean;
    status: SelfStorage__Unit__Status;
    name: string;
    floor?: number | null;
    createdAt: string;
    updatedAt: string;
    designations: Array<string>;
    facility: {
      __typename?: 'SelfStorage__Facility';
      id: string;
      name: string;
      partner?: { __typename?: 'SelfStorage__Partner'; isThirdParty: boolean } | null;
    };
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    };
    price?: {
      __typename?: 'SelfStorage__Price';
      id: string;
      rate: number;
      pushRate: number;
      quotedRate: number;
    } | null;
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
  };
};

export type ModifySelfStorageUnitMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SelfStorage__UnitInput;
}>;

export type ModifySelfStorageUnitMutation = {
  __typename?: 'Mutation';
  modify: { __typename?: 'SelfStorage__Unit__ModifyPayload'; status: Status; error?: string | null };
};

export type PaginatedSelfStorageUnitsQueryVariables = Exact<{
  filters?: InputMaybe<SelfStorage__Unit__FiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedSelfStorageUnitsQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'SelfStorage__PaginatedUnit';
    results: Array<{
      __typename?: 'SelfStorage__Unit';
      id: string;
      rentable: boolean;
      status: SelfStorage__Unit__Status;
      name: string;
      floor?: number | null;
      createdAt: string;
      updatedAt: string;
      designations: Array<string>;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        partner?: { __typename?: 'SelfStorage__Partner'; isThirdParty: boolean } | null;
      };
      classification: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      };
      price?: {
        __typename?: 'SelfStorage__Price';
        id: string;
        rate: number;
        pushRate: number;
        quotedRate: number;
      } | null;
      promotion?: {
        __typename?: 'SelfStorage__Promotion';
        id: string;
        kind: SelfStorage__Promotion__Kind;
        value: number;
        term: number;
        delay: number;
        name: string;
      } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type ReservableSelfStorageUnitsCountQueryVariables = Exact<{
  ids: Array<SelfStorage__ClassificationFacilityInput> | SelfStorage__ClassificationFacilityInput;
}>;

export type ReservableSelfStorageUnitsCountQuery = { __typename?: 'Query'; count: number };

export type SaveSelfStoragePromotionBatchMutationVariables = Exact<{
  input: SelfStorage__SavePromotionBatchInput;
}>;

export type SaveSelfStoragePromotionBatchMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__SavePromotionBatchPayload'; status: Status };
};

export type SaveSelfStorageUploadMutationVariables = Exact<{
  input: SelfStorage__UploadInput;
}>;

export type SaveSelfStorageUploadMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'SelfStorage__Upload__SavePayload'; status: Status; error?: string | null };
};

export type SelfStorageUnitWithRentalsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SelfStorageUnitWithRentalsQuery = {
  __typename?: 'Query';
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    rentable: boolean;
    status: SelfStorage__Unit__Status;
    name: string;
    floor?: number | null;
    createdAt: string;
    updatedAt: string;
    designations: Array<string>;
    rentals?: Array<{
      __typename?: 'SelfStorage__Rental';
      id: string;
      status: SelfStorage__Status;
      overlocked: boolean;
      rate: number;
      activatedAt?: string | null;
      canceledAt?: string | null;
      subscription: {
        __typename?: 'SelfStorage__Subscription';
        id: string;
        billing: SelfStorage__Rental__BillingEnum;
        activatedAt?: string | null;
        canceledAt?: string | null;
        billsOn?: string | null;
        defaultSource?: {
          __typename?: 'Billing__Source';
          id: string;
          brand?: string | null;
          default: boolean;
          fundingType: Billing__Source__FundingType;
          number: string;
        } | null;
        account: {
          __typename?: 'Account';
          id: string;
          customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
        };
      };
      unit: {
        __typename?: 'SelfStorage__Unit';
        id: string;
        floor?: number | null;
        name: string;
        facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
        classification: {
          __typename?: 'SelfStorage__Classification';
          id: string;
          length: number;
          width: number;
          height: number;
          indoors: boolean;
          climate: boolean;
          electricity: boolean;
          firstFloor: boolean;
          obstructed: boolean;
          category: SelfStorage__Classification__Category;
          kind: {
            __typename?: 'SelfStorage__Kind';
            id: string;
            name: string;
            subtype?: SelfStorage__Kind__Subtype | null;
          };
        };
      };
    }> | null;
    facility: {
      __typename?: 'SelfStorage__Facility';
      id: string;
      name: string;
      partner?: { __typename?: 'SelfStorage__Partner'; isThirdParty: boolean } | null;
    };
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    };
    price?: {
      __typename?: 'SelfStorage__Price';
      id: string;
      rate: number;
      pushRate: number;
      quotedRate: number;
    } | null;
    promotion?: {
      __typename?: 'SelfStorage__Promotion';
      id: string;
      kind: SelfStorage__Promotion__Kind;
      value: number;
      term: number;
      delay: number;
      name: string;
    } | null;
  };
};

export type SendGridStatusQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendGridStatusQuery = {
  __typename?: 'Query';
  bounces: Array<{ __typename?: 'SendGrid__Suppression__Bounce'; created: string; reason: string }>;
  blocks: Array<{ __typename?: 'SendGrid__Suppression__Block'; created: string; reason: string }>;
};

export type SendGridSuppressionResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendGridSuppressionResetMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ResetPayload'; error?: string | null };
};

export type AttestationEventResolveMutationVariables = Exact<{
  eventId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  shiftInput?: InputMaybe<Workforce__ShiftInput>;
}>;

export type AttestationEventResolveMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'AttestationEventResolvePayload';
    error?: string | null;
    status: Status;
    attestationEvent?: { __typename?: 'AttestationEvent'; id: string } | null;
  };
};

export type AttestationEventsPaginatedQueryVariables = Exact<{
  filters?: InputMaybe<AttestationEventFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type AttestationEventsPaginatedQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'AttestationEventPaginated';
    results: Array<{
      __typename?: 'AttestationEvent';
      id: string;
      kind: Attestation__EventKind;
      flagged: boolean;
      comment?: string | null;
      correctedShiftStart?: string | null;
      correctedWorkBreaks?: any | null;
      reviewDueDate: string;
      reviewedAt?: string | null;
      shift: {
        __typename?: 'Workforce__Shift';
        id: string;
        date: string;
        started: {
          __typename?: 'Workforce__Clockin';
          id: string;
          timestamp: string;
          facility: { __typename?: 'Workforce__Facility'; id: string };
        };
        ended?: {
          __typename?: 'Workforce__Clockin';
          id: string;
          timestamp: string;
          facility: { __typename?: 'Workforce__Facility'; id: string };
        } | null;
        workBreaks: Array<{
          __typename?: 'Workforce__WorkBreak';
          id: string;
          uuid: string;
          started?: string | null;
          ended?: string | null;
        }>;
        user: { __typename?: 'User'; tz: string; id: string; name: string };
        activities: Array<{
          __typename?: 'Workforce__Shift__Activity';
          id: string;
          durationMinutes: number;
          position: number;
          jobCode: { __typename?: 'Workforce__JobCode'; id: string };
        }>;
      };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type JobCodesQueryVariables = Exact<{ [key: string]: never }>;

export type JobCodesQuery = {
  __typename?: 'Query';
  jobCodes: Array<{ __typename?: 'Workforce__JobCode'; id: string; name: string }>;
};

export type ShiftCreateMutationVariables = Exact<{
  input: Workforce__ShiftInput;
}>;

export type ShiftCreateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Workforce__ShiftCreatePayload';
    error?: string | null;
    status: Status;
    shift?: {
      __typename?: 'Workforce__Shift';
      id: string;
      date: string;
      duration: number;
      notes?: string | null;
      status: Workforce__Shift__ViolationStatus;
      approvedAt?: string | null;
      overriddenAt?: string | null;
      disputedAt?: string | null;
      deletedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      shiftAssignment?: {
        __typename?: 'Workforce__ShiftAssignment';
        id: string;
        facility?: { __typename?: 'Workforce__Facility'; id: string; name: string } | null;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      } | null;
      workBreaks: Array<{
        __typename?: 'Workforce__WorkBreak';
        id: string;
        uuid: string;
        started?: string | null;
        ended?: string | null;
      }>;
      started: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      };
      ended?: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      } | null;
      approver?: { __typename?: 'User'; id: string; name: string } | null;
      deleter?: { __typename?: 'User'; id: string; name: string } | null;
      violations: Array<{
        __typename?: 'Workforce__Shift__Violation';
        message: string;
        requiresAdjustment?: boolean | null;
      }>;
      activities: Array<{
        __typename?: 'Workforce__Shift__Activity';
        id: string;
        durationMinutes: number;
        position: number;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      }>;
      user: { __typename?: 'User'; tz: string; id: string; name: string };
    } | null;
  };
};

export type ShiftDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShiftDeleteMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Workforce__ShiftDeletePayload';
    error?: string | null;
    status: Status;
    shift?: {
      __typename?: 'Workforce__Shift';
      id: string;
      date: string;
      duration: number;
      notes?: string | null;
      status: Workforce__Shift__ViolationStatus;
      approvedAt?: string | null;
      overriddenAt?: string | null;
      disputedAt?: string | null;
      deletedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      shiftAssignment?: {
        __typename?: 'Workforce__ShiftAssignment';
        id: string;
        facility?: { __typename?: 'Workforce__Facility'; id: string; name: string } | null;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      } | null;
      workBreaks: Array<{
        __typename?: 'Workforce__WorkBreak';
        id: string;
        uuid: string;
        started?: string | null;
        ended?: string | null;
      }>;
      started: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      };
      ended?: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      } | null;
      approver?: { __typename?: 'User'; id: string; name: string } | null;
      deleter?: { __typename?: 'User'; id: string; name: string } | null;
      violations: Array<{
        __typename?: 'Workforce__Shift__Violation';
        message: string;
        requiresAdjustment?: boolean | null;
      }>;
      activities: Array<{
        __typename?: 'Workforce__Shift__Activity';
        id: string;
        durationMinutes: number;
        position: number;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      }>;
      user: { __typename?: 'User'; tz: string; id: string; name: string };
    } | null;
  };
};

export type ShiftUpdateMutationVariables = Exact<{
  input: Workforce__ShiftInput;
}>;

export type ShiftUpdateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'Workforce__ShiftUpdatePayload';
    error?: string | null;
    status: Status;
    shift?: {
      __typename?: 'Workforce__Shift';
      id: string;
      date: string;
      duration: number;
      notes?: string | null;
      status: Workforce__Shift__ViolationStatus;
      approvedAt?: string | null;
      overriddenAt?: string | null;
      disputedAt?: string | null;
      deletedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      shiftAssignment?: {
        __typename?: 'Workforce__ShiftAssignment';
        id: string;
        facility?: { __typename?: 'Workforce__Facility'; id: string; name: string } | null;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      } | null;
      workBreaks: Array<{
        __typename?: 'Workforce__WorkBreak';
        id: string;
        uuid: string;
        started?: string | null;
        ended?: string | null;
      }>;
      started: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      };
      ended?: {
        __typename?: 'Workforce__Clockin';
        id: string;
        timestamp: string;
        distance: number;
        latitude: number;
        longitude: number;
        facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
      } | null;
      approver?: { __typename?: 'User'; id: string; name: string } | null;
      deleter?: { __typename?: 'User'; id: string; name: string } | null;
      violations: Array<{
        __typename?: 'Workforce__Shift__Violation';
        message: string;
        requiresAdjustment?: boolean | null;
      }>;
      activities: Array<{
        __typename?: 'Workforce__Shift__Activity';
        id: string;
        durationMinutes: number;
        position: number;
        jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
      }>;
      user: { __typename?: 'User'; tz: string; id: string; name: string };
    } | null;
  };
};

export type NewShiftOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type NewShiftOptionsQuery = {
  __typename?: 'Query';
  users: Array<{ __typename?: 'User'; tz: string; id: string; name: string }>;
  facilities: Array<{ __typename?: 'Workforce__Facility'; id: string; name: string }>;
};

export type ShiftAssignmentQueryVariables = Exact<{
  userID: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type ShiftAssignmentQuery = {
  __typename?: 'Query';
  shiftAssignment?: {
    __typename?: 'Workforce__ShiftAssignment';
    id: string;
    facility?: { __typename?: 'Workforce__Facility'; id: string; name: string } | null;
    jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
  } | null;
};

export type ShiftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShiftQuery = {
  __typename?: 'Query';
  shift: {
    __typename?: 'Workforce__Shift';
    id: string;
    date: string;
    duration: number;
    notes?: string | null;
    status: Workforce__Shift__ViolationStatus;
    approvedAt?: string | null;
    overriddenAt?: string | null;
    disputedAt?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    shiftAssignment?: {
      __typename?: 'Workforce__ShiftAssignment';
      id: string;
      facility?: { __typename?: 'Workforce__Facility'; id: string; name: string } | null;
      jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
    } | null;
    workBreaks: Array<{
      __typename?: 'Workforce__WorkBreak';
      id: string;
      uuid: string;
      started?: string | null;
      ended?: string | null;
    }>;
    started: {
      __typename?: 'Workforce__Clockin';
      id: string;
      timestamp: string;
      distance: number;
      latitude: number;
      longitude: number;
      facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
    };
    ended?: {
      __typename?: 'Workforce__Clockin';
      id: string;
      timestamp: string;
      distance: number;
      latitude: number;
      longitude: number;
      facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
    } | null;
    approver?: { __typename?: 'User'; id: string; name: string } | null;
    deleter?: { __typename?: 'User'; id: string; name: string } | null;
    violations: Array<{
      __typename?: 'Workforce__Shift__Violation';
      message: string;
      requiresAdjustment?: boolean | null;
    }>;
    activities: Array<{
      __typename?: 'Workforce__Shift__Activity';
      id: string;
      durationMinutes: number;
      position: number;
      jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
    }>;
    user: { __typename?: 'User'; tz: string; id: string; name: string };
  };
};

export type StorageTreasuresAuctionCancelMutationVariables = Exact<{
  tealAuctionID: Scalars['ID'];
  reason: Scalars['String'];
}>;

export type StorageTreasuresAuctionCancelMutation = {
  __typename?: 'Mutation';
  storageTreasuresAuctionCancel: {
    __typename?: 'StorageTreasures__AuctionCancelPayload';
    status: Status;
    error?: string | null;
  };
};

export type StorageTreasuresAuctionListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type StorageTreasuresAuctionListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'StorageTreasures__PaginatedAuctionType';
    results: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      startTime: string;
      status: StorageTreasures__AuctionStatus;
      storageTreasuresID: string;
      storageTreasuresLink: string;
      auctionedAccount: { __typename?: 'Account'; id: string; name: string };
      winner?: { __typename?: 'Account'; id: string; name: string } | null;
      winnerInvoice?: { __typename?: 'StorageTreasures__AuctionInvoiceType'; id: string; clutterAmount: number } | null;
      winnerOrder?: { __typename?: 'Order'; id: string; number: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  permission: { __typename?: 'StorageTreasures__AuctionPolicyType'; cancel: boolean; view: boolean };
};

export type PricingSetFetcherQueryVariables = Exact<{
  zip: Scalars['String'];
  accountID?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
}>;

export type PricingSetFetcherQuery = {
  __typename?: 'Query';
  pricingSet?: {
    __typename?: 'PricingSet';
    id: string;
    quoteId?: string | null;
    marketPricingVariantLabel?: string | null;
    laborPricingGroupEntries: Array<{
      __typename?: 'PricingGroupLaborEntry';
      id: string;
      rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
      laborPolicy: {
        __typename?: 'LaborPolicy';
        id: string;
        laborBillingFormat: LaborBillingFormatEnum;
        laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
      };
      storageTerm: {
        __typename?: 'StorageTerm';
        id: string;
        name: string;
        minimumMonths: number;
        rateGroup: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };
      };
    }>;
    storagePricingGroupEntries: Array<{
      __typename?: 'PricingGroupPricingEntry';
      id: string;
      rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
      pricing: {
        __typename?: 'Pricing';
        id: string;
        amount: number;
        currency: Currency;
        plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
      };
    }>;
    protectionPricingGroupEntries: Array<{
      __typename?: 'PricingGroupPricingEntry';
      id: string;
      rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
      pricing: {
        __typename?: 'Pricing';
        id: string;
        amount: number;
        currency: Currency;
        plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
      };
    }>;
  } | null;
};

export type PricingPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PricingPolicyQuery = {
  __typename?: 'Query';
  permissions: { __typename?: 'PricingPolicy'; modify: boolean };
};

export type AccountSubscriptionsQueryVariables = Exact<{
  accountID: Scalars['ID'];
}>;

export type AccountSubscriptionsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    subscriptions: Array<{
      __typename?: 'Subscription';
      id: string;
      stripeID?: string | null;
      name: string;
      state: string;
      quantity: number;
      customDimension?: {
        __typename?: 'CustomDimension';
        id: string;
        cuft: number;
        length: number;
        width: number;
        height: number;
      } | null;
      pricing: {
        __typename?: 'Pricing';
        id: string;
        amount: number;
        currency: Currency;
        plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
      };
    }>;
    currentPaymentPlanSubscriptions: Array<{
      __typename?: 'PaymentPlan__Subscription';
      id: string;
      status?: string | null;
      amount: number;
      term: number;
      activatedAt?: string | null;
      canceledAt?: string | null;
      currentPeriodStart?: string | null;
      currentPeriodEnd?: string | null;
      offer: { __typename?: 'PaymentPlan__Offer'; id: string };
    }>;
  };
};

export type MarkMessagesReadMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type MarkMessagesReadMutation = {
  __typename?: 'Mutation';
  markMessagesRead: { __typename?: 'MarkMessagesReadPayload'; status: Status };
};

export type TicketMessagesQueryVariables = Exact<{
  ticketID: Scalars['ID'];
}>;

export type TicketMessagesQuery = {
  __typename?: 'Query';
  ticket: {
    __typename?: 'Ticket';
    messages: Array<{
      __typename?: 'Ticket__Message';
      id: string;
      body: string;
      createdAt: string;
      readAt?: string | null;
      user: { __typename?: 'User'; id: string; name: string };
      ticket: {
        __typename?: 'Ticket';
        assignee?: { __typename?: 'User'; id: string; name: string } | null;
        owner: { __typename?: 'User'; id: string; name: string };
      };
    }>;
  };
};

export type BuildTicketMutationVariables = Exact<{
  input: BuildTicketInput;
}>;

export type BuildTicketMutation = {
  __typename?: 'Mutation';
  buildTicket: { __typename?: 'BuildTicketPayload'; error?: string | null; status: Status };
};

export type PaginatedTicketListQueryVariables = Exact<{
  filters?: InputMaybe<TicketFiltersInput>;
  page?: InputMaybe<Scalars['Int']>;
  numPer?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedTicketListQuery = {
  __typename?: 'Query';
  paginatedTicketList: {
    __typename?: 'PaginatedTicket';
    results: Array<{
      __typename?: 'Ticket';
      id: string;
      category: string;
      subCategory?: string | null;
      description: string;
      state: string;
      dueDate: string;
      updatedAt: string;
      resolutionType?: string | null;
      read: boolean;
      ticketableID: string;
      ticketableType: string;
      ticketableName: string;
      group?: { __typename?: 'Ticket__Group'; name: string } | null;
      assignee?: { __typename?: 'User'; id: string; name: string } | null;
      owner: { __typename?: 'User'; id: string; name: string };
      latestNote?: { __typename?: 'Note'; body: string } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type InternalTicketGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type InternalTicketGroupsQuery = {
  __typename?: 'Query';
  internalTicketGroups: Array<{
    __typename?: 'Ticket__Group';
    id: string;
    name: string;
    users: Array<{ __typename?: 'User'; id: string; name: string }>;
  }>;
};

export type MessageCreateMutationVariables = Exact<{
  input: Ticket__MessageInput;
  sendPushNotification: Scalars['Boolean'];
}>;

export type MessageCreateMutation = {
  __typename?: 'Mutation';
  messageCreate: {
    __typename?: 'MessageCreatePayload';
    error?: string | null;
    status: Status;
    ticketMessage?: {
      __typename?: 'Ticket__Message';
      id: string;
      body: string;
      createdAt: string;
      deletedAt?: string | null;
      readAt?: string | null;
      updatedAt: string;
      files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
      ticket: {
        __typename?: 'Ticket';
        id: string;
        category: string;
        subCategory?: string | null;
        description: string;
        state: string;
        dueDate: string;
        updatedAt: string;
        resolutionType?: string | null;
        read: boolean;
        ticketableID: string;
        ticketableType: string;
        ticketableName: string;
        group?: { __typename?: 'Ticket__Group'; name: string } | null;
        assignee?: { __typename?: 'User'; id: string; name: string } | null;
        owner: { __typename?: 'User'; id: string; name: string };
        latestNote?: { __typename?: 'Note'; body: string } | null;
      };
      user: { __typename?: 'User'; id: string; name: string };
    } | null;
  };
};

export type MessageUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Ticket__MessageInput;
}>;

export type MessageUpdateMutation = {
  __typename?: 'Mutation';
  messageUpdate: {
    __typename?: 'MessageUpdatePayload';
    error?: string | null;
    status: Status;
    ticketMessage?: {
      __typename?: 'Ticket__Message';
      id: string;
      body: string;
      createdAt: string;
      deletedAt?: string | null;
      readAt?: string | null;
      updatedAt: string;
      files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
      ticket: {
        __typename?: 'Ticket';
        id: string;
        category: string;
        subCategory?: string | null;
        description: string;
        state: string;
        dueDate: string;
        updatedAt: string;
        resolutionType?: string | null;
        read: boolean;
        ticketableID: string;
        ticketableType: string;
        ticketableName: string;
        group?: { __typename?: 'Ticket__Group'; name: string } | null;
        assignee?: { __typename?: 'User'; id: string; name: string } | null;
        owner: { __typename?: 'User'; id: string; name: string };
        latestNote?: { __typename?: 'Note'; body: string } | null;
      };
      user: { __typename?: 'User'; id: string; name: string };
    } | null;
  };
};

export type TicketCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type TicketCategoriesQuery = { __typename?: 'Query'; ticketCategories: any };

export type TicketsQueryVariables = Exact<{
  ticketable: Ticket__TicketableInput;
}>;

export type TicketsQuery = {
  __typename?: 'Query';
  tickets: Array<{
    __typename?: 'Ticket';
    id: string;
    category: string;
    subCategory?: string | null;
    description: string;
    state: string;
    dueDate: string;
    updatedAt: string;
    resolutionType?: string | null;
    read: boolean;
    ticketableID: string;
    ticketableType: string;
    ticketableName: string;
    group?: { __typename?: 'Ticket__Group'; name: string } | null;
    assignee?: { __typename?: 'User'; id: string; name: string } | null;
    owner: { __typename?: 'User'; id: string; name: string };
    latestNote?: { __typename?: 'Note'; body: string } | null;
  }>;
};

export type TrainingUploadAttestationsPaginatedQueryVariables = Exact<{
  trainingUploadID: Scalars['ID'];
  userQuery?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type TrainingUploadAttestationsPaginatedQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'TrainingUploadAttestationPaginated';
    results: Array<{
      __typename?: 'TrainingUploadAttestation';
      id: string;
      createdAt: string;
      user: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type TrainingUploadCreateMutationVariables = Exact<{
  input: TrainingUpload__Create__Input;
}>;

export type TrainingUploadCreateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'TrainingUpload__CreatePayload'; status: Status; error?: string | null };
};

export type TrainingUploadsPaginatedQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type TrainingUploadsPaginatedQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'TrainingUploadPaginated';
    results: Array<{
      __typename?: 'TrainingUpload';
      id: string;
      createdAt: string;
      deletedAt?: string | null;
      name: string;
      url: string;
      teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
      uploader: { __typename?: 'User'; id: string; name: string };
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type TrainingUploadStatusUpdateMutationVariables = Exact<{
  input: TrainingUpload__StatusUpdate__Input;
}>;

export type TrainingUploadStatusUpdateMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'TrainingUpload__StatusUpdatePayload'; status: Status; error?: string | null };
};

export type UserActivateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserActivateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'UserActivatePayload';
    status: Status;
    error?: string | null;
    user?: { __typename?: 'User'; id: string; active: boolean } | null;
  };
};

export type AvatarDeleteMutationVariables = Exact<{
  userID: Scalars['ID'];
}>;

export type AvatarDeleteMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'AvatarDeletePayload';
    user?: {
      __typename?: 'User';
      id: string;
      workdayUserID?: string | null;
      name: string;
      email: string;
      phone?: string | null;
      tz: string;
      roles: Array<User__Role>;
      agentPriority: number;
      externalGroup?: User__ExternalGroup | null;
      notificationPreference?: UserNotificationPreferenceEnum | null;
      hasTimeClockPlus: boolean;
      image?: { __typename?: 'Image'; id: string; source: string } | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    } | null;
  };
};

export type AvatarUploadMutationVariables = Exact<{
  userID: Scalars['ID'];
  url: Scalars['String'];
}>;

export type AvatarUploadMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'AvatarUploadPayload';
    user?: {
      __typename?: 'User';
      id: string;
      workdayUserID?: string | null;
      name: string;
      email: string;
      phone?: string | null;
      tz: string;
      roles: Array<User__Role>;
      agentPriority: number;
      externalGroup?: User__ExternalGroup | null;
      notificationPreference?: UserNotificationPreferenceEnum | null;
      hasTimeClockPlus: boolean;
      image?: { __typename?: 'Image'; id: string; source: string } | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    } | null;
  };
};

export type UserCreateMutationVariables = Exact<{
  input: UserInput;
}>;

export type UserCreateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'UserCreatePayload';
    status: Status;
    error?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      workdayUserID?: string | null;
      name: string;
      email: string;
      phone?: string | null;
      tz: string;
      roles: Array<User__Role>;
      agentPriority: number;
      externalGroup?: User__ExternalGroup | null;
      notificationPreference?: UserNotificationPreferenceEnum | null;
      hasTimeClockPlus: boolean;
      image?: { __typename?: 'Image'; id: string; source: string } | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    } | null;
  };
};

export type UserDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserDeleteMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'UserDeletePayload';
    status: Status;
    error?: string | null;
    user?: { __typename?: 'User'; id: string; active: boolean } | null;
  };
};

export type UsersDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UsersDetailsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    workdayUserID?: string | null;
    active: boolean;
    name: string;
    email: string;
    personalEmail?: string | null;
    notificationPreference?: UserNotificationPreferenceEnum | null;
    externalGroup?: User__ExternalGroup | null;
    phone?: string | null;
    roles: Array<User__Role>;
    state: User__State;
    signInCount: number;
    allRolesEditableByManager: boolean;
    image?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
    promoCode: { __typename?: 'PromoCode'; id: string; value: string };
    team?: { __typename?: 'Team'; id: string; name: string } | null;
    ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    policy: { __typename?: 'User__Policy'; canForceLeaveCallCenter: boolean };
  };
};

export type UserForceLeaveCallCenterMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserForceLeaveCallCenterMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'UserForceLeaveCallCenterPayload';
    user: { __typename?: 'User'; id: string; state: User__State };
  };
};

export type UserFormFragment = {
  __typename?: 'Query';
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type UserDefaultsFragment = {
  __typename?: 'User';
  id: string;
  workdayUserID?: string | null;
  name: string;
  email: string;
  phone?: string | null;
  tz: string;
  roles: Array<User__Role>;
  agentPriority: number;
  externalGroup?: User__ExternalGroup | null;
  notificationPreference?: UserNotificationPreferenceEnum | null;
  hasTimeClockPlus: boolean;
  image?: { __typename?: 'Image'; id: string; source: string } | null;
  team?: { __typename?: 'Team'; id: string; name: string } | null;
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type UserCreateFormQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreateFormQuery = {
  __typename?: 'Query';
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type UserUpdateFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserUpdateFormQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    workdayUserID?: string | null;
    name: string;
    email: string;
    phone?: string | null;
    tz: string;
    roles: Array<User__Role>;
    agentPriority: number;
    externalGroup?: User__ExternalGroup | null;
    notificationPreference?: UserNotificationPreferenceEnum | null;
    hasTimeClockPlus: boolean;
    image?: { __typename?: 'Image'; id: string; source: string } | null;
    team?: { __typename?: 'Team'; id: string; name: string } | null;
    regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
    ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
  };
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
  teams: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type UsersListQueryVariables = Exact<{
  filters: UserFiltersInput;
  page: Scalars['Int'];
}>;

export type UsersListQuery = {
  __typename?: 'Query';
  paginated: {
    __typename?: 'PaginatedUser';
    results: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      roles: Array<User__Role>;
      state: User__State;
      active: boolean;
      signInCount: number;
      allRolesEditableByManager: boolean;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type UsersSelectorQueryVariables = Exact<{
  filters?: InputMaybe<UserFiltersInput>;
}>;

export type UsersSelectorQuery = {
  __typename?: 'Query';
  users: Array<{ __typename?: 'User'; id: string; name: string }>;
};

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserInput;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  result: {
    __typename?: 'UserUpdatePayload';
    status: Status;
    error?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      workdayUserID?: string | null;
      name: string;
      email: string;
      phone?: string | null;
      tz: string;
      roles: Array<User__Role>;
      agentPriority: number;
      externalGroup?: User__ExternalGroup | null;
      notificationPreference?: UserNotificationPreferenceEnum | null;
      hasTimeClockPlus: boolean;
      image?: { __typename?: 'Image'; id: string; source: string } | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
      regions: Array<{ __typename?: 'Region'; id: string; name: string }>;
      ownedTeams: Array<{ __typename?: 'Team'; id: string; name: string }>;
    } | null;
  };
};

export type VehicleTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VehicleTypeQuery = {
  __typename?: 'Query';
  vehicleType: {
    __typename?: 'VehicleType';
    id: string;
    name: string;
    cuft: number;
    numSeats: number;
    weightInPounds: number;
    fieldDispatchable: boolean;
    restrictedZipCodes: Array<string>;
  };
};

export type VehicleTypeCreateMutationVariables = Exact<{
  input: VehicleTypeInput;
}>;

export type VehicleTypeCreateMutation = {
  __typename?: 'Mutation';
  vehicleTypeCreate: { __typename?: 'VehicleTypeCreatePayload'; status: Status; error?: string | null };
};

export type VehicleTypeUpdateMutationVariables = Exact<{
  input: VehicleTypeInput;
  id: Scalars['ID'];
}>;

export type VehicleTypeUpdateMutation = {
  __typename?: 'Mutation';
  vehicleTypeUpdate: { __typename?: 'VehicleTypeUpdatePayload'; status: Status; error?: string | null };
};

export type VehicleTypesQueryVariables = Exact<{ [key: string]: never }>;

export type VehicleTypesQuery = {
  __typename?: 'Query';
  vehicleTypes: Array<{
    __typename?: 'VehicleType';
    id: string;
    name: string;
    cuft: number;
    numSeats: number;
    weightInPounds: number;
    fieldDispatchable: boolean;
    restrictedZipCodes: Array<string>;
  }>;
};

export type WarehouseAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type WarehouseAddressesQuery = {
  __typename?: 'Query';
  warehouses: Array<{
    __typename?: 'Warehouse';
    id: string;
    name: string;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      schedulable: boolean;
      region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
    };
  }>;
};

export type BuildWarehouseMutationVariables = Exact<{
  input: WarehouseInput;
}>;

export type BuildWarehouseMutation = {
  __typename?: 'Mutation';
  buildWarehouse: { __typename?: 'BuildWarehousePayload'; error?: string | null };
};

export type ModifyWarehouseMutationVariables = Exact<{
  input: WarehouseInput;
  id: Scalars['ID'];
}>;

export type ModifyWarehouseMutation = {
  __typename?: 'Mutation';
  modifyWarehouse: {
    __typename?: 'ModifyWarehousePayload';
    error?: string | null;
    warehouse?: {
      __typename?: 'Warehouse';
      id: string;
      name: string;
      abbreviation: string;
      tz: string;
      active: boolean;
      height?: number | null;
      thirdPartyLogistics: boolean;
      inboundDockItemsPerHour: number;
      outboundDockItemsPerHour: number;
      customerAccessInstructions?: string | null;
      storageTreasuresFacilityID?: string | null;
      storageTreasuresLink?: string | null;
      address: {
        __typename?: 'Address';
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        country?: string | null;
        zip: string;
        latitude?: number | null;
        longitude?: number | null;
      };
      regions: Array<{ __typename?: 'Region'; id: string }>;
      customerAccessHours?: Array<{
        __typename?: 'CustomerAccessHours';
        day: string;
        open?: string | null;
        close?: string | null;
      }> | null;
      operatingScheduleTemplates: Array<{
        __typename?: 'OperatingScheduleTemplate';
        id: string;
        effectiveDate: string;
        operatesSunday: boolean;
        operatesMonday: boolean;
        operatesTuesday: boolean;
        operatesWednesday: boolean;
        operatesThursday: boolean;
        operatesFriday: boolean;
        operatesSaturday: boolean;
      }>;
    } | null;
  };
};

export type WarehousesSelectorQueryVariables = Exact<{
  filters?: InputMaybe<FacilityFiltersInput>;
}>;

export type WarehousesSelectorQuery = {
  __typename?: 'Query';
  warehouses: Array<{ __typename?: 'Warehouse'; id: string; name: string }>;
};

export type WarehouseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WarehouseQuery = {
  __typename?: 'Query';
  warehouse: {
    __typename?: 'Warehouse';
    id: string;
    name: string;
    abbreviation: string;
    tz: string;
    active: boolean;
    height?: number | null;
    thirdPartyLogistics: boolean;
    inboundDockItemsPerHour: number;
    outboundDockItemsPerHour: number;
    customerAccessInstructions?: string | null;
    storageTreasuresFacilityID?: string | null;
    storageTreasuresLink?: string | null;
    address: {
      __typename?: 'Address';
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      country?: string | null;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
    };
    regions: Array<{ __typename?: 'Region'; id: string }>;
    customerAccessHours?: Array<{
      __typename?: 'CustomerAccessHours';
      day: string;
      open?: string | null;
      close?: string | null;
    }> | null;
    operatingScheduleTemplates: Array<{
      __typename?: 'OperatingScheduleTemplate';
      id: string;
      effectiveDate: string;
      operatesSunday: boolean;
      operatesMonday: boolean;
      operatesTuesday: boolean;
      operatesWednesday: boolean;
      operatesThursday: boolean;
      operatesFriday: boolean;
      operatesSaturday: boolean;
    }>;
  };
};

export type ZendeskUserBulkMergeMutationVariables = Exact<{
  signedID: Scalars['ID'];
}>;

export type ZendeskUserBulkMergeMutation = {
  __typename?: 'Mutation';
  result: { __typename?: 'ZendeskUserBulkMergePayload'; status: Status; error?: string | null };
};

export type AccountFragment = {
  __typename?: 'Account';
  id: string;
  number: string;
  customer: { __typename?: 'Customer'; id: string; name: string; phone?: string | null };
  subscriptions: Array<{
    __typename?: 'Subscription';
    id: string;
    stripeID?: string | null;
    name: string;
    state: string;
    quantity: number;
    customDimension?: {
      __typename?: 'CustomDimension';
      id: string;
      cuft: number;
      length: number;
      width: number;
      height: number;
    } | null;
    pricing: {
      __typename?: 'Pricing';
      id: string;
      amount: number;
      currency: Currency;
      plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
    };
  }>;
};

export type Account__ClaimsSummaryFragment = {
  __typename?: 'ClaimsSummary';
  totalItemCompensation: number;
  totalPropertyCompensation: number;
  paidItemCompensation: number;
  pendingItemCompensation: number;
  paidPropertyCompensation: number;
  pendingPropertyCompensation: number;
  latestProtectionPlan: { __typename?: 'ProtectionPlan'; slug: string; name: string; maxCovered: number };
};

export type AddressFragment = {
  __typename?: 'Address';
  id: string;
  street: string;
  aptsuite?: string | null;
  city: string;
  state: string;
  zip: string;
  latitude?: number | null;
  longitude?: number | null;
  serviceable: boolean;
  businessName?: string | null;
  safeAndSanitary?: boolean | null;
};

export type AttachmentFragment = {
  __typename?: 'ActiveStorageAttachment';
  id: string;
  imgixURL: string;
  url: string;
  filename: string;
};

export type AttestationEventFragment = {
  __typename?: 'AttestationEvent';
  id: string;
  kind: Attestation__EventKind;
  flagged: boolean;
  comment?: string | null;
  correctedShiftStart?: string | null;
  correctedWorkBreaks?: any | null;
  reviewDueDate: string;
  reviewedAt?: string | null;
  shift: {
    __typename?: 'Workforce__Shift';
    id: string;
    date: string;
    started: {
      __typename?: 'Workforce__Clockin';
      id: string;
      timestamp: string;
      facility: { __typename?: 'Workforce__Facility'; id: string };
    };
    ended?: {
      __typename?: 'Workforce__Clockin';
      id: string;
      timestamp: string;
      facility: { __typename?: 'Workforce__Facility'; id: string };
    } | null;
    workBreaks: Array<{
      __typename?: 'Workforce__WorkBreak';
      id: string;
      uuid: string;
      started?: string | null;
      ended?: string | null;
    }>;
    user: { __typename?: 'User'; tz: string; id: string; name: string };
    activities: Array<{
      __typename?: 'Workforce__Shift__Activity';
      id: string;
      durationMinutes: number;
      position: number;
      jobCode: { __typename?: 'Workforce__JobCode'; id: string };
    }>;
  };
};

export type Auction__UploadFragment = {
  __typename?: 'Auction__Upload';
  id: string;
  description: string;
  state: Auction__Upload__State;
  error?: string | null;
  processingAt?: string | null;
  processedAt?: string | null;
  user: { __typename?: 'User'; id: string; name: string };
  originalCSV: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
  failureCSV?: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string } | null;
};

export type AuditOrderEventFragment = {
  __typename?: 'AuditOrderEvent';
  id: string;
  titleizedEventName: string;
  timestamp?: string | null;
  orderId: string;
  image?: { __typename?: 'Image'; id: string; source: string; size: string } | null;
};

export type AvailabilityFragment = {
  __typename?: 'Availability';
  datetime: string;
  duration?: string | null;
  available: boolean;
  perMoverHourAdjustmentAmount?: number | null;
  laborBillingFormat?: LaborBillingFormatEnum | null;
  laborRate?: { __typename?: 'Pricing__LaborRate'; id: string; name: string; amount: number } | null;
  feeAmount?: { __typename?: 'MonetaryAmount'; formatted: string; value: number } | null;
};

export type FacilityCuftLimitFragment = {
  __typename?: 'Availability__FacilityCuftLimit';
  id: string;
  date: string;
  maximumInboundCuft?: number | null;
  maximumInboundOutboundCuft?: number | null;
  maximumInboundTransferCuft?: number | null;
  maximumOutboundCuft?: number | null;
  maximumOutboundFacilityCuft?: number | null;
  maximumOutboundDisposalCuft?: number | null;
  reason?: Availability__BlockReasonEnum | null;
  facility: { __typename?: 'Warehouse'; id: string; name: string };
  creator: { __typename?: 'User'; id: string; name: string };
};

export type Availability__GlobalUnavailabilityFragment = {
  __typename?: 'Availability__GlobalUnavailability';
  id: string;
  date: string;
  name: string;
  reason: Availability__GlobalUnavailabilityReasonEnum;
  capacityReductionPercentage: number;
  region: { __typename?: 'Region'; id: string; name: string };
  creator: { __typename?: 'User'; id: string; name: string };
};

export type Availability__JobTypeAvailabilityFragment = {
  __typename?: 'Availability__JobTypeAvailability';
  id: string;
  date: string;
  maximumMoverMinutes: number;
  bookedMoverMinutes: number;
  displayKind: Availability__JobAvailabilityKindEnum;
  reason?: Availability__BlockReasonEnum | null;
  region: { __typename?: 'Region'; id: string; name: string };
  creator: { __typename?: 'User'; id: string; name: string };
};

export type Availability__LaborAdjustmentFragment = {
  __typename?: 'Availability__LaborAdjustment';
  id: string;
  date: string;
  lowThresholdPercentage: number;
  highThresholdPercentage: number;
  perMoverHourAdjustmentAmount: number;
  orderClassification: Availability__OrderClassificationEnum;
  creator: { __typename?: 'User'; id: string; name: string };
  region: { __typename?: 'Region'; id: string; name: string };
};

export type Availability__RescheduleOfferFragment = {
  __typename?: 'Availability__RescheduleOffer';
  id: string;
  originDate: string;
  targetDates: Array<string>;
  orderMinimumMoverMinutes: number;
  originTargetAvailabilityThreshold: number;
  orderClassification: Availability__OrderClassificationEnum;
  startAt: string;
  expireAt: string;
  expired: boolean;
  rescheduleOfferItems: Array<{
    __typename?: 'Availability__RescheduleOfferItem';
    id: string;
    coupon: { __typename?: 'Coupon'; id: string; name: string; amount: number; percent: number };
  }>;
  creator: { __typename?: 'User'; id: string; name: string };
  region: { __typename?: 'Region'; id: string; name: string };
};

export type WaitlistRequestFragment = { __typename?: 'Availability__WaitlistRequest'; id: string; date: string };

export type BarcodeFragment = { __typename?: 'Barcode'; value: string };

export type Billing__ForgivenessReasonFragment = {
  __typename?: 'Billing__ForgivenessReason';
  id: string;
  kind: string;
  parentID?: string | null;
};

export type Billing__ReBillReasonFragment = { __typename?: 'Billing__ReBillReason'; id: string; name: string };

export type Billing__SourceFragment = {
  __typename?: 'Billing__Source';
  id: string;
  stripeID: string;
  kind: Billing__Source__Kind;
  fundingType: Billing__Source__FundingType;
  number: string;
  brand?: string | null;
  name?: string | null;
  expiration?: string | null;
  expired: boolean;
  default: boolean;
  active: boolean;
  billable: boolean;
  cvcCheck?: Billing__Source__CvcCheck | null;
  zipCheck?: Billing__Source__ZipCheck | null;
};

export type Admin__ClaimFragment = {
  __typename?: 'Claim';
  id: string;
  uuid: string;
  number: string;
  status: Claim__Status;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
  offeredAt?: string | null;
  expiredReason?: string | null;
};

export type Claim__AssessmentFragment = {
  __typename?: 'Claim__Assessment';
  id: string;
  verifiedValue: number;
  weight?: number | null;
  classification: Claim__Assessment__Classification;
  approvedAmount?: number | null;
  coverage?: Claim__Assessment__Coverage | null;
};

export type Claim__IssueSelectionFragment = {
  __typename?: 'Claim__IssueSelection';
  id: string;
  compensationRequested?: boolean | null;
  declaration?: number | null;
  description?: string | null;
  kind?: Claim__IssueSelection__Kind | null;
  photos: Array<{
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  }>;
  resolution?: {
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  offeredResolutions: Array<{
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  }>;
};

export type Claim__ItemSelectionFragment = {
  __typename?: 'Claim__ItemSelection';
  id: string;
  kind: Claim__ItemSelectionKind;
  name?: string | null;
  declaration?: number | null;
  productURL?: string | null;
  photos: Array<{
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  }>;
  receipts: Array<{
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  }>;
  item?: {
    __typename?: 'Item';
    id: string;
    name?: string | null;
    barcode: { __typename?: 'Barcode'; value: string };
  } | null;
  resolution?: {
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  offeredResolutions: Array<{
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  }>;
  assessment?: {
    __typename?: 'Claim__Assessment';
    id: string;
    verifiedValue: number;
    weight?: number | null;
    classification: Claim__Assessment__Classification;
    approvedAmount?: number | null;
    coverage?: Claim__Assessment__Coverage | null;
  } | null;
};

export type Claim__OfferFragment = {
  __typename?: 'Claim__Offer';
  id: string;
  details: string;
  createdAt: string;
  expiry: string;
  totalAmount: number;
  user: { __typename?: 'User'; id: string; name: string };
};

export type Claim__PropertySelectionFragment = {
  __typename?: 'Claim__PropertySelection';
  id: string;
  name?: string | null;
  declaration?: number | null;
  ownership?: Claim__PropertySelectionOwnership | null;
  owner?: { __typename?: 'Claim__PropertyOwner'; name: string; email: string; phone: string } | null;
  photos: Array<{
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  }>;
  quotes: Array<{
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  }>;
  resolution?: {
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  offeredResolutions: Array<{
    __typename?: 'Claim__Resolution';
    id: string;
    kind: Claim__ResolutionKind;
    amount?: number | null;
    reason?: string | null;
    offeredAt?: string | null;
    user?: { __typename?: 'User'; id: string; name: string } | null;
  }>;
  assessment?: {
    __typename?: 'Claim__Assessment';
    id: string;
    verifiedValue: number;
    weight?: number | null;
    classification: Claim__Assessment__Classification;
    approvedAmount?: number | null;
    coverage?: Claim__Assessment__Coverage | null;
  } | null;
};

export type Claim__ResolutionFragment = {
  __typename?: 'Claim__Resolution';
  id: string;
  kind: Claim__ResolutionKind;
  amount?: number | null;
  reason?: string | null;
  offeredAt?: string | null;
  user?: { __typename?: 'User'; id: string; name: string } | null;
};

export type Admin__CouponFragment = {
  __typename?: 'Coupon';
  id: string;
  stripeId: string;
  name: string;
  code: string;
  active: boolean;
  amount: number;
  percent: number;
  duration: Coupon__Duration;
  durationInMonths?: number | null;
  effective?: string | null;
  eligibleServices: Array<Coupon__Service>;
  scheduledCutoff?: string | null;
  subject: Coupon__Subject;
  currency: Currency;
};

export type CustomDimensionFragment = {
  __typename?: 'CustomDimension';
  id: string;
  cuft: number;
  length: number;
  width: number;
  height: number;
};

export type CustomerFragment = { __typename?: 'Customer'; id: string; name: string; phone?: string | null };

export type CustomerInteractionFragment = {
  __typename?: 'CustomerInteraction';
  id: string;
  interactableType: string;
  interactable: {
    __typename?: 'PhoneCall';
    id: string;
    createdAt: string;
    direction: string;
    note?: { __typename?: 'Note'; body: string } | null;
    latestConnectedUser?: { __typename?: 'User'; id: string; name: string } | null;
  };
};

export type CustomerTicketFragment = {
  __typename?: 'CustomerTicket';
  id: string;
  category: string;
  subCategory?: string | null;
  description?: string | null;
  state: string;
  createdAt: string;
  actionRequiredAt?: string | null;
  customerUpdatedAt?: string | null;
  priority?: CustomerTicket__Priority | null;
  contactPreference?: CustomerTicket__ContactPreference | null;
  allowIncomingCalls: boolean;
  customerTicketableType: string;
  assignee?: { __typename?: 'User'; id: string; name: string } | null;
  creator?: { __typename?: 'User'; id: string; name: string } | null;
  customerTicketable:
    | { __typename?: 'Account'; id: string; shortDisplay: string }
    | { __typename?: 'Lead'; id: string; shortDisplay: string };
  group?: { __typename?: 'Ticket__Group'; id: string; name: string } | null;
};

export type DetailedAddressFragment = {
  __typename?: 'Address';
  id: string;
  street: string;
  addressableId?: string | null;
  addressableType?: string | null;
  aptsuite?: string | null;
  city: string;
  country?: string | null;
  state: string;
  zip: string;
  latitude?: number | null;
  longitude?: number | null;
  serviceable: boolean;
  businessName?: string | null;
  safeAndSanitary?: boolean | null;
  schedulable: boolean;
  details?: {
    __typename?: 'Detail';
    buildingType?: string | null;
    buildingSubtype?: string | null;
    floor?: number | null;
    stories?: number | null;
    frontDoorSteps?: number | null;
    stairs: boolean;
    squareFeet?: number | null;
    elevator: boolean;
    paperwork: boolean;
    gated: boolean;
    steep: boolean;
    serviceElevator: boolean;
    serviceEntrance: boolean;
    loadingDock: boolean;
    code: boolean;
    codeValue?: string | null;
    parkingStreet: boolean;
    parkingDriveway: boolean;
    parkingLot: boolean;
    parkingAlley: boolean;
    parkingPermit: boolean;
    parkingInFront: boolean;
    parkingOnSpecificStreet?: string | null;
    parkingInstructions?: string | null;
    maxTruckHeight?: number | null;
    maxTruckHeightUnknown: boolean;
    accessUnknown: boolean;
    paperworkUnknown: boolean;
    parkingUnknown: boolean;
    environment?: EnvironmentEnum | null;
    additionalNotes?: string | null;
    buildingOpeningHour?: number | null;
    buildingClosingHour?: number | null;
    buildingRestrictionsAnswer?: Address__BuildingRestrictionsAnswerEnum | null;
    parkingLatitude?: number | null;
    parkingLongitude?: number | null;
    secureLocationDetails?: string | null;
    longCarry: boolean;
    unitSize?: Address__UnitSizeEnum | null;
  } | null;
};

export type DetailedImageFragment = {
  __typename?: 'DetailedImage';
  id: string;
  size: string;
  createdAt: string;
  source: string;
  name?: string | null;
  user?: { __typename?: 'User'; id: string; name: string } | null;
};

export type DetailedPhoneCallFragment = {
  __typename?: 'PhoneCall';
  id: string;
  startAt?: string | null;
  createdAt: string;
  status: string;
  direction: string;
  duration: number;
  latestConnectedUser?: { __typename?: 'User'; name: string } | null;
  touchTaxons: Array<{ __typename?: 'TouchTaxon'; name?: string | null; parentName?: string | null }>;
  notes: Array<{ __typename?: 'Note'; body: string }>;
  conversations: Array<{
    __typename?: 'Conversation';
    recording?: { __typename?: 'Recording'; playbackURL?: string | null } | null;
  }>;
};

export type Dispatch__ItemMetricsFragment = {
  __typename?: 'Dispatch__ItemMetrics';
  cuft: number;
  count?: number | null;
};

export type AccessFragment = { __typename?: 'DropOff__Access'; id: string; customerCode: string; moverCode: string };

export type DropOffFacilityFragment = { __typename?: 'DropOff__Facility'; id: string; name: string };

type StorageArrangement_DropOff__ThirdPartySelfStorageUnit_Fragment = {
  __typename?: 'DropOff__ThirdPartySelfStorageUnit';
  id: string;
  name: string;
};

type StorageArrangement_SelfStorage__Hold_Fragment = {
  __typename?: 'SelfStorage__Hold';
  unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string };
};

export type StorageArrangementFragment =
  | StorageArrangement_DropOff__ThirdPartySelfStorageUnit_Fragment
  | StorageArrangement_SelfStorage__Hold_Fragment;

export type DropOffThirdPartySelfStorageUnitFragment = {
  __typename?: 'DropOff__ThirdPartySelfStorageUnit';
  id: string;
  name: string;
  directions?: string | null;
  length: number;
  width: number;
  height: number;
  accessCode?: string | null;
};

export type ErrorFragment = { __typename: 'Error'; errorCode: ErrorCodeEnum; errorMessage: string };

export type EstimatedItemFragment = {
  __typename?: 'EstimatedItem';
  id: string;
  categoryId: string;
  quantity: number;
  categoryDisplayName?: string | null;
  notes?: string | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  cuft?: number | null;
  estimatedMovers?: number | null;
};

export type EstimationUploadFragment = {
  __typename?: 'Estimation__UploadType';
  id: string;
  media: {
    __typename?: 'Estimation__UploadMediaType';
    id: string;
    imgixURL: string;
    isImage: boolean;
    isVideo: boolean;
  };
};

export type EstimationCategoryFragment = {
  __typename?: 'EstimationCategory';
  id: string;
  name: string;
  packable: boolean;
  cuft: number;
  numMovers?: number | null;
};

type Facility_Depot_Fragment = { __typename: 'Depot'; id: string; name: string };

type Facility_Warehouse_Fragment = { __typename: 'Warehouse'; id: string; name: string };

export type FacilityFragment = Facility_Depot_Fragment | Facility_Warehouse_Fragment;

export type HistoryFragment = {
  __typename?: 'History';
  tag?: string | null;
  timestamp: string;
  resource:
    | {
        __typename: 'Changed__Audit';
        id: string;
        event: Changed__Event;
        fields: Array<{ __typename?: 'Changed__Field'; name: string; now?: any | null; was?: any | null }>;
        audited:
          | { __typename?: 'Changed__Customer'; id: string; email: string }
          | { __typename?: 'Changed__Phone'; id: string; number: string }
          | { __typename?: 'Changed__Record'; id: string; type: string }
          | {
              __typename?: 'LaborClock';
              id: string;
              role: Assignment__Role;
              user: { __typename?: 'User'; id: string; name: string };
            };
        relationships: Array<{
          __typename?: 'Changed__Relationship';
          name: string;
          associations: Array<{
            __typename?: 'Changed__Association';
            id: string;
            name: string;
            kind: Changed__Association__Kind;
            associated: { __typename?: 'Changed__Associated'; id: string; name: string };
          }>;
        }>;
        changer: { __typename?: 'Changed__Changer'; id: string; name: string };
      }
    | { __typename: 'LaborClock' }
    | { __typename: 'MarketingEvent'; id: string; metadata: any }
    | { __typename: 'Note'; id: string; body: string; user: { __typename?: 'User'; id: string; name: string } }
    | {
        __typename: 'PhoneCall';
        id: string;
        status: string;
        direction: string;
        startAt?: string | null;
        endAt?: string | null;
        duration: number;
        reasons: Array<string>;
        notes: Array<{ __typename?: 'Note'; id: string; body: string }>;
        conversations: Array<{
          __typename?: 'Conversation';
          id: string;
          user: { __typename?: 'User'; id: string; name: string };
          recording?: { __typename?: 'Recording'; id: string; playbackURL?: string | null } | null;
        }>;
        voicemails: Array<{
          __typename?: 'Voicemail';
          id: string;
          recording: { __typename?: 'Recording'; id: string; playbackURL?: string | null };
        }>;
        phone: { __typename?: 'Phone'; id: string; number: string };
      }
    | {
        __typename: 'Subscribe';
        id: string;
        user: { __typename?: 'User'; id: string; name: string };
        order: { __typename?: 'Order'; id: string; accountID: string; number: string };
        adjustments: Array<{
          __typename?: 'Adjustment';
          id: string;
          kind: string;
          subscription: {
            __typename?: 'Subscription';
            id: string;
            name: string;
            quantity: number;
            pricing: { __typename?: 'Pricing'; id: string; amount: number };
          };
        }>;
      }
    | { __typename: 'Usage'; id: string; cuft: number; user: { __typename?: 'User'; id: string; name: string } };
};

export type HistoryResourceChangedAuditFragment = {
  __typename?: 'Changed__Audit';
  id: string;
  event: Changed__Event;
  fields: Array<{ __typename?: 'Changed__Field'; name: string; now?: any | null; was?: any | null }>;
  audited:
    | { __typename?: 'Changed__Customer'; id: string; email: string }
    | { __typename?: 'Changed__Phone'; id: string; number: string }
    | { __typename?: 'Changed__Record'; id: string; type: string }
    | {
        __typename?: 'LaborClock';
        id: string;
        role: Assignment__Role;
        user: { __typename?: 'User'; id: string; name: string };
      };
  relationships: Array<{
    __typename?: 'Changed__Relationship';
    name: string;
    associations: Array<{
      __typename?: 'Changed__Association';
      id: string;
      name: string;
      kind: Changed__Association__Kind;
      associated: { __typename?: 'Changed__Associated'; id: string; name: string };
    }>;
  }>;
  changer: { __typename?: 'Changed__Changer'; id: string; name: string };
};

export type HistoryResourceChangedFieldFragment = {
  __typename?: 'Changed__Field';
  name: string;
  now?: any | null;
  was?: any | null;
};

type HistoryResourceChangedAudited_Changed__Customer_Fragment = {
  __typename?: 'Changed__Customer';
  id: string;
  email: string;
};

type HistoryResourceChangedAudited_Changed__Phone_Fragment = {
  __typename?: 'Changed__Phone';
  id: string;
  number: string;
};

type HistoryResourceChangedAudited_Changed__Record_Fragment = {
  __typename?: 'Changed__Record';
  id: string;
  type: string;
};

type HistoryResourceChangedAudited_LaborClock_Fragment = {
  __typename?: 'LaborClock';
  id: string;
  role: Assignment__Role;
  user: { __typename?: 'User'; id: string; name: string };
};

export type HistoryResourceChangedAuditedFragment =
  | HistoryResourceChangedAudited_Changed__Customer_Fragment
  | HistoryResourceChangedAudited_Changed__Phone_Fragment
  | HistoryResourceChangedAudited_Changed__Record_Fragment
  | HistoryResourceChangedAudited_LaborClock_Fragment;

export type HistoryResourceChangedRelationshipFragment = {
  __typename?: 'Changed__Relationship';
  name: string;
  associations: Array<{
    __typename?: 'Changed__Association';
    id: string;
    name: string;
    kind: Changed__Association__Kind;
    associated: { __typename?: 'Changed__Associated'; id: string; name: string };
  }>;
};

export type HistoryResourceMarketingEventFragment = { __typename?: 'MarketingEvent'; id: string; metadata: any };

export type HistoryResourceNoteFragment = {
  __typename?: 'Note';
  id: string;
  body: string;
  user: { __typename?: 'User'; id: string; name: string };
};

export type HistoryResourcePhoneCallFragment = {
  __typename?: 'PhoneCall';
  id: string;
  status: string;
  direction: string;
  startAt?: string | null;
  endAt?: string | null;
  duration: number;
  reasons: Array<string>;
  notes: Array<{ __typename?: 'Note'; id: string; body: string }>;
  conversations: Array<{
    __typename?: 'Conversation';
    id: string;
    user: { __typename?: 'User'; id: string; name: string };
    recording?: { __typename?: 'Recording'; id: string; playbackURL?: string | null } | null;
  }>;
  voicemails: Array<{
    __typename?: 'Voicemail';
    id: string;
    recording: { __typename?: 'Recording'; id: string; playbackURL?: string | null };
  }>;
  phone: { __typename?: 'Phone'; id: string; number: string };
};

export type HistoryResourceSubscribeFragment = {
  __typename?: 'Subscribe';
  id: string;
  user: { __typename?: 'User'; id: string; name: string };
  order: { __typename?: 'Order'; id: string; accountID: string; number: string };
  adjustments: Array<{
    __typename?: 'Adjustment';
    id: string;
    kind: string;
    subscription: {
      __typename?: 'Subscription';
      id: string;
      name: string;
      quantity: number;
      pricing: { __typename?: 'Pricing'; id: string; amount: number };
    };
  }>;
};

export type HistoryResourceUsageFragment = {
  __typename?: 'Usage';
  id: string;
  cuft: number;
  user: { __typename?: 'User'; id: string; name: string };
};

export type HoursFragment = {
  __typename?: 'Hours';
  monday: { __typename?: 'OpenClose'; open: string; close: string };
  tuesday: { __typename?: 'OpenClose'; open: string; close: string };
  wednesday: { __typename?: 'OpenClose'; open: string; close: string };
  thursday: { __typename?: 'OpenClose'; open: string; close: string };
  friday: { __typename?: 'OpenClose'; open: string; close: string };
  saturday: { __typename?: 'OpenClose'; open: string; close: string };
  sunday: { __typename?: 'OpenClose'; open: string; close: string };
};

export type ImageFragment = { __typename?: 'Image'; id: string; source: string; state: ImageState };

export type Ingestion__UploadFragment = {
  __typename?: 'Ingestion__Upload';
  id: string;
  description: string;
  state: Ingestion__Upload__State;
  error?: string | null;
  processingAt?: string | null;
  processedAt?: string | null;
  user: { __typename?: 'User'; id: string; name: string };
  spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
};

export type InventoryMetricsFragment = {
  __typename?: 'InventoryMetrics';
  cuftUsed: number;
  staleUsage: boolean;
  unsizedItemsCount: number;
};

export type Admin__Billing__InvoiceFragment = {
  __typename?: 'Billing__Invoice';
  id: string;
  createdAt: string;
  summary?: string | null;
  status: Billing__Invoice__Status;
  amountDue: number;
};

export type ItemFragment = {
  __typename?: 'Item';
  id: string;
  uuid?: string | null;
  hero: number;
  name?: string | null;
  contents?: string | null;
  selectableForReturn: boolean;
  barcode: { __typename?: 'Barcode'; value: string };
  images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
};

export type ItemExceptionFragment = {
  __typename?: 'ItemException';
  id: string;
  reason: string;
  explanation?: string | null;
  createdAt: string;
  deletedAt?: string | null;
  creator: { __typename?: 'User'; name: string };
  image?: { __typename?: 'Image'; id: string; size: string; source: string } | null;
};

export type LaborPolicyFragment = {
  __typename?: 'LaborPolicy';
  id: string;
  laborBillingFormat: LaborBillingFormatEnum;
  laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
};

export type LaborRateFragment = { __typename?: 'LaborRate'; id: string; amount: number; name: string };

export type LeadFragment = { __typename?: 'Lead'; id: string; name?: string | null };

export type LeadInteractionFragment = {
  __typename?: 'LeadInteraction';
  id: string;
  interactableType: string;
  interactable: {
    __typename?: 'PhoneCall';
    id: string;
    createdAt: string;
    direction: string;
    note?: { __typename?: 'Note'; body: string } | null;
    latestConnectedUser?: { __typename?: 'User'; id: string; name: string } | null;
  };
};

export type Logistics__AddressFragment = {
  __typename?: 'Address';
  id: string;
  street: string;
  aptsuite?: string | null;
  city: string;
  state: string;
  country?: string | null;
  zip: string;
  latitude?: number | null;
  longitude?: number | null;
  businessName?: string | null;
};

export type LoadFragment = {
  __typename?: 'Logistics__Load';
  id: string;
  type: LoadTypeEnum;
  state: string;
  number: string;
  mustShipBy?: string | null;
  appointmentDate?: string | null;
  expectedOrders: number;
  expectedPallets: number;
  expectedItems: number;
  trailerNumber?: string | null;
  freightChargeTerms: string;
  mode: string;
  billOfLadingNumber?: string | null;
  handlingMethod?: string | null;
  specialInstructions?: string | null;
  itemLabelsRequired: boolean;
  palletLabelsRequired: boolean;
  digitalBolSignatureRequired: boolean;
  trailerSealRequired: boolean;
  requestedAppointmentDate: string;
  requestedAppointmentWindow: Logistics__RequestedAppointmentWindowEnum;
  drop: boolean;
  releaseNumber?: string | null;
  reference1?: string | null;
  reference2?: string | null;
  reference3?: string | null;
  reference4?: string | null;
  reference5?: string | null;
  targetPalletHeight?: number | null;
  source: Logistics__LoadSourceEnum;
  palletHeightPreference?: Logistics__PalletHeightPreferenceEnum | null;
  warehouse: { __typename?: 'Warehouse'; id: string; name: string; tz: string };
  purchaseOrders: Array<{
    __typename?: 'Logistics__PurchaseOrder';
    id: string;
    number: string;
    company?: string | null;
    mustShipBy?: string | null;
    quantities: Array<{
      __typename?: 'PurchaseOrderQuantities';
      loadId: string;
      skuValue: string;
      palletCount: number;
      itemCount: number;
      skuID: string;
    }>;
  }>;
  carrierDetails?: {
    __typename?: 'Logistics__CarrierDetail';
    id: string;
    businessName?: string | null;
    scac?: string | null;
  } | null;
};

export type MessageFragment = {
  __typename?: 'Message';
  id: string;
  twilioStatus?: string | null;
  iterableStatus?: string | null;
  consentBlocked: boolean;
  body: string;
  kind: string;
  userName: string;
  recipientName: string;
  to: string;
  from: string;
  createdAt: string;
  nonUserNumber: string;
  images?: Array<{ __typename?: 'Image'; source: string; size: string }> | null;
};

export type MovingQuoteFragment = {
  __typename?: 'Moving__Quote';
  id: string;
  eligibility: boolean;
  reasons: Array<string>;
  truckCount?: number | null;
  moverSelection?: number | null;
  suggestedMoverCount?: number | null;
  deadtimeDriveDuration?: number | null;
  deadtimeDriveDistance?: number | null;
  drivetimeDuration?: number | null;
  driveDistance?: number | null;
  estimatedCuft: number;
  packDurations: Array<number>;
  unpackDurations: Array<number>;
  orderDurations?: Array<number> | null;
  dispatchDurations?: Array<number> | null;
  laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
  materialPackageSetEntry?: {
    __typename?: 'Moving__PackageSetEntry';
    id: string;
    fixedCost: number;
    package: { __typename?: 'Package'; name: string };
  } | null;
};

export type NightTransport__CarpoolFragment = {
  __typename?: 'NightTransport__Carpool';
  id: string;
  drive: { __typename?: 'NightTransport__Drive'; id: string };
};

export type NightTransport__DispatchFragment = {
  __typename?: 'Dispatch';
  id: string;
  arrival: string;
  region: { __typename?: 'Region'; id: string; name: string; tz: string };
  vehiclePreferences: Array<{
    __typename?: 'Dispatch__VehiclePreference';
    id: string;
    quantity: number;
    vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
  }>;
  vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
  estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
  outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
};

export type NightTransport__DriveFragment = {
  __typename?: 'NightTransport__Drive';
  id: string;
  origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
  destination:
    | { __typename: 'Depot'; id: string; name: string }
    | { __typename: 'Warehouse'; id: string; name: string };
  driveDispatch?: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    region: { __typename?: 'Region'; id: string; name: string; tz: string };
    vehiclePreferences: Array<{
      __typename?: 'Dispatch__VehiclePreference';
      id: string;
      quantity: number;
      vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
    }>;
    vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
    estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
    outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
  } | null;
  vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
  predecessor?: { __typename?: 'NightTransport__Drive'; id: string } | null;
};

export type NightTransport__DriveTaskFragment = {
  __typename?: 'NightTransport__DriveTask';
  dispatch: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    region: { __typename?: 'Region'; id: string; name: string; tz: string };
    vehiclePreferences: Array<{
      __typename?: 'Dispatch__VehiclePreference';
      id: string;
      quantity: number;
      vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
    }>;
    vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
    estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
    outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
  };
  destination:
    | { __typename: 'Depot'; id: string; name: string }
    | { __typename: 'Warehouse'; id: string; name: string };
  origin?:
    | { __typename: 'Depot'; id: string; name: string }
    | { __typename: 'Warehouse'; id: string; name: string }
    | null;
  vehicleSuggestion:
    | {
        __typename?: 'Dispatch__VehiclePreference';
        id: string;
        quantity: number;
        vehicleType: { __typename?: 'VehicleType'; id: string };
      }
    | { __typename?: 'Vehicle'; id: string; name: string };
};

export type NightTransport__ResupplyFragment = {
  __typename?: 'NightTransport__Resupply';
  id: string;
  drive: { __typename?: 'NightTransport__Drive'; id: string };
  resupplyDispatch: {
    __typename?: 'Dispatch';
    id: string;
    arrival: string;
    region: { __typename?: 'Region'; id: string; name: string; tz: string };
    vehiclePreferences: Array<{
      __typename?: 'Dispatch__VehiclePreference';
      id: string;
      quantity: number;
      vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
    }>;
    vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
    estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
    outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
  };
  vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
};

export type NightTransport__RouteFragment = {
  __typename?: 'NightTransport__Route';
  id: string;
  arrival: string;
  origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
  driver?: { __typename?: 'User'; tz: string; id: string; name: string } | null;
};

export type NightTransport__RouteTimelineFragment = {
  __typename?: 'NightTransport__RouteTimeline';
  arrival: string;
  driver: { __typename?: 'User'; id: string; name: string };
  taskEvents: Array<{
    __typename?: 'NightTransport__RouteTimeline__TaskEvent';
    category: NightTransport__RouteTimeline__TaskEvent__CategoryEnum;
    startTimestamp: string;
    endTimestamp: string;
    dispatchID?: string | null;
    vehicleName?: string | null;
    origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
  }>;
};

export type NightTransport__TaskFragment = {
  __typename?: 'NightTransport__Task';
  id: string;
  position: number;
  route: {
    __typename?: 'NightTransport__Route';
    id: string;
    arrival: string;
    origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
    driver?: { __typename?: 'User'; tz: string; id: string; name: string } | null;
  };
  action:
    | {
        __typename?: 'NightTransport__Carpool';
        id: string;
        drive: { __typename?: 'NightTransport__Drive'; id: string };
      }
    | {
        __typename?: 'NightTransport__Drive';
        id: string;
        origin:
          | { __typename: 'Depot'; id: string; name: string }
          | { __typename: 'Warehouse'; id: string; name: string };
        destination:
          | { __typename: 'Depot'; id: string; name: string }
          | { __typename: 'Warehouse'; id: string; name: string };
        driveDispatch?: {
          __typename?: 'Dispatch';
          id: string;
          arrival: string;
          region: { __typename?: 'Region'; id: string; name: string; tz: string };
          vehiclePreferences: Array<{
            __typename?: 'Dispatch__VehiclePreference';
            id: string;
            quantity: number;
            vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
          }>;
          vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
          estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
          outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
        } | null;
        vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
        predecessor?: { __typename?: 'NightTransport__Drive'; id: string } | null;
      }
    | {
        __typename?: 'NightTransport__Resupply';
        id: string;
        drive: { __typename?: 'NightTransport__Drive'; id: string };
        resupplyDispatch: {
          __typename?: 'Dispatch';
          id: string;
          arrival: string;
          region: { __typename?: 'Region'; id: string; name: string; tz: string };
          vehiclePreferences: Array<{
            __typename?: 'Dispatch__VehiclePreference';
            id: string;
            quantity: number;
            vehicleType: { __typename?: 'VehicleType'; id: string; name: string };
          }>;
          vehicles: Array<{ __typename?: 'Vehicle'; id: string; serialCode: string }>;
          estimatedInboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
          outboundItemMetrics: { __typename?: 'Dispatch__ItemMetrics'; cuft: number; count?: number | null };
        };
        vehicle?: { __typename?: 'Vehicle'; id: string; name: string } | null;
      };
};

export type NightTransport__RouteTimeline_TaskEventFragment = {
  __typename?: 'NightTransport__RouteTimeline__TaskEvent';
  category: NightTransport__RouteTimeline__TaskEvent__CategoryEnum;
  startTimestamp: string;
  endTimestamp: string;
  dispatchID?: string | null;
  vehicleName?: string | null;
  origin: { __typename: 'Depot'; id: string; name: string } | { __typename: 'Warehouse'; id: string; name: string };
};

export type NoteFragment = {
  __typename?: 'Note';
  id: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  user: { __typename?: 'User'; id: string; name: string };
  notable: { __typename?: 'NoteNotable'; id: string; type: NoteNotableEnum };
  files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; url: string; filename: string }>;
};

export type NotificationFragment = {
  __typename?: 'Notification';
  id: string;
  objectName?: string | null;
  createdAt: string;
  updatedAt: string;
  kind: string;
  provider: Notification__ProviderEnum;
  deliveries: Array<{
    __typename?: 'NotificationDelivery';
    id: string;
    state: NotificationDelivery__State;
    sent?: string | null;
    scheduled?: string | null;
    createdAt: string;
    updatedAt: string;
    triggerer?: string | null;
    emailEvents: Array<
      | { __typename?: 'IterableEvent'; id: string; sender: string; timestamp: string; event: string }
      | { __typename?: 'SendgridEvent'; id: string; event: string; sender: string; timestamp: string }
    >;
  }>;
};

export type OpsAudit__AuditFragment = {
  __typename?: 'OpsAudit__AuditType';
  id: string;
  resourceType: string;
  resourceID: string;
  state: string;
  questionGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  configuration: { __typename?: 'OpsAudit__ConfigurationType'; id: string; name: string };
  images: Array<{
    __typename?: 'Image';
    id: string;
    size: string;
    createdAt: string;
    source: string;
    name?: string | null;
  }>;
  questions: Array<{
    __typename?: 'OpsAudit__QuestionType';
    id: string;
    prompt: string;
    groups?: Array<string> | null;
    slug: string;
    answerChoices: Array<string>;
    defaultAnswer?: number | null;
    ordering?: number | null;
    defectAnswers: Array<string>;
    failedMessage?: string | null;
    failedAnswers: Array<string>;
    deletedAt?: string | null;
  }>;
};

export type OpsAudit__ImageFragment = {
  __typename?: 'Image';
  id: string;
  size: string;
  createdAt: string;
  source: string;
  name?: string | null;
};

export type OpsAudit__ConfigurationFragment = {
  __typename?: 'OpsAudit__ConfigurationType';
  id: string;
  name: string;
  lastRanAt?: string | null;
  canceledAt?: string | null;
  createdAt: string;
  resourceType: string;
  refreshIntervalMinutes?: number | null;
  hoursUntilExpired?: number | null;
  pendingCount: number;
  pausedAt?: string | null;
  exceptionReasonCodes: Array<string>;
  dataQuery: string;
  user: { __typename?: 'User'; id: string; name: string };
};

export type AuditExceptionFragment = {
  __typename?: 'OpsAudit__AuditException';
  id: string;
  reason: string;
  status: OpsAudit__ExceptionStatus;
  creator: { __typename?: 'User'; id: string; name: string };
};

export type OpsAudit__QuestionFragment = {
  __typename?: 'OpsAudit__QuestionType';
  id: string;
  prompt: string;
  groups?: Array<string> | null;
  slug: string;
  answerChoices: Array<string>;
  defaultAnswer?: number | null;
  ordering?: number | null;
  defectAnswers: Array<string>;
  failedMessage?: string | null;
  failedAnswers: Array<string>;
  deletedAt?: string | null;
};

export type PackageFragment = {
  __typename?: 'Package';
  id: string;
  name: string;
  kind: PackageKindEnum;
  fullPack: boolean;
};

export type PackageSetFragment = { __typename?: 'PackageSet'; id: string; name: string };

export type PackageSetEntryFragment = {
  __typename?: 'PackageSetEntry';
  id: string;
  amount?: number | null;
  orderSubtype: string;
  package: { __typename?: 'Package'; id: string; name: string; kind: PackageKindEnum; fullPack: boolean };
};

export type PaginationFragment = {
  __typename?: 'Pagination';
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  totalPages: number;
  totalCount: number;
};

export type PalletGradeFragment = {
  __typename?: 'PalletGrade';
  id: string;
  createdAt: string;
  user: { __typename?: 'User'; name: string };
  image?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
  frontImage?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
  backImage?: { __typename?: 'Image'; id: string; size: string; source: string; createdAt: string } | null;
};

export type Admin__PaymentPlan__AccountFragment = {
  __typename?: 'Account';
  id: string;
  number: string;
  customer: { __typename?: 'Customer'; id: string; name: string };
};

export type Admin__PaymentPlan__OfferFragment = {
  __typename?: 'PaymentPlan__Offer';
  id: string;
  state: PaymentPlan__Offer__State;
  type: PaymentPlan__Offer__Type;
  oneTimeFee?: number | null;
  expiry: string;
  expired: boolean;
  createdAt: string;
  acceptedAt?: string | null;
  storageTerm?: number | null;
  storageTermEndDate?: string | null;
  monthlyFee?: number | null;
  monthlyFeeTerm?: number | null;
  monthlyFeeTermEndDate?: string | null;
  account: {
    __typename?: 'Account';
    id: string;
    number: string;
    customer: { __typename?: 'Customer'; id: string; name: string };
  };
  user: { __typename?: 'User'; id: string; name: string };
  settledInvoices: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    createdAt: string;
    summary?: string | null;
    status: Billing__Invoice__Status;
    amountDue: number;
  }>;
};

export type Admin__PaymentPlan__SubscriptionFragment = {
  __typename?: 'PaymentPlan__Subscription';
  id: string;
  status?: string | null;
  amount: number;
  term: number;
  activatedAt?: string | null;
  canceledAt?: string | null;
  currentPeriodStart?: string | null;
  currentPeriodEnd?: string | null;
  offer: { __typename?: 'PaymentPlan__Offer'; id: string };
};

export type PhoneCallFragment = {
  __typename?: 'PhoneCall';
  id: string;
  createdAt: string;
  direction: string;
  note?: { __typename?: 'Note'; body: string } | null;
  latestConnectedUser?: { __typename?: 'User'; id: string; name: string } | null;
};

export type PhoneCallPolicyFragment = { __typename?: 'PhoneCallPolicy'; download: boolean };

export type PickupInventoryFragment = {
  __typename?: 'OrderService';
  isFullMoveOut?: boolean | null;
  needsPackingHelp?: boolean | null;
  requestedMovers?: number | null;
  estimatedItems: Array<{
    __typename?: 'EstimatedItem';
    id: string;
    categoryId: string;
    quantity: number;
    categoryDisplayName?: string | null;
    notes?: string | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    cuft?: number | null;
    estimatedMovers?: number | null;
  }>;
  rooms: Array<{ __typename?: 'RoomSelection'; id: string; selected: boolean; quantity: number; categoryID: string }>;
  estimations: Array<{
    __typename?: 'EstimationSelection';
    id: string;
    selected: boolean;
    quantity: number;
    categoryID: string;
  }>;
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  kind: PlanKindEnum;
  cuft: number;
  name: string;
  type: PlanTypeEnum;
};

export type PricingFragment = {
  __typename?: 'Pricing';
  id: string;
  amount: number;
  currency: Currency;
  plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
};

export type PerItemFeeFragment = {
  __typename?: 'Pricing__PerItemFee';
  id: string;
  amount: number;
  largeAmount: number;
  orderType: OrderTypeEnum;
  orderSubtype: OrderSubtypeEnum;
};

export type Pricing__UploadFragment = {
  __typename?: 'Pricing__Upload';
  id: string;
  description: string;
  state: Pricing__Upload__State;
  error?: string | null;
  processingAt?: string | null;
  processedAt?: string | null;
  service: Pricing__Upload__Service;
  user: { __typename?: 'User'; id: string; name: string };
  spreadsheet: { __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string };
};

export type PricingGroupLaborEntryFragment = {
  __typename?: 'PricingGroupLaborEntry';
  id: string;
  rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
  laborPolicy: {
    __typename?: 'LaborPolicy';
    id: string;
    laborBillingFormat: LaborBillingFormatEnum;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
  };
  storageTerm: {
    __typename?: 'StorageTerm';
    id: string;
    name: string;
    minimumMonths: number;
    rateGroup: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };
  };
};

export type PricingGroupPricingEntryFragment = {
  __typename?: 'PricingGroupPricingEntry';
  id: string;
  rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
  pricing: {
    __typename?: 'Pricing';
    id: string;
    amount: number;
    currency: Currency;
    plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
  };
};

export type PricingSetFragment = {
  __typename?: 'PricingSet';
  id: string;
  quoteId?: string | null;
  marketPricingVariantLabel?: string | null;
  laborPricingGroupEntries: Array<{
    __typename?: 'PricingGroupLaborEntry';
    id: string;
    rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
    laborPolicy: {
      __typename?: 'LaborPolicy';
      id: string;
      laborBillingFormat: LaborBillingFormatEnum;
      laborRate: { __typename?: 'LaborRate'; id: string; amount: number; name: string };
    };
    storageTerm: {
      __typename?: 'StorageTerm';
      id: string;
      name: string;
      minimumMonths: number;
      rateGroup: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };
    };
  }>;
  storagePricingGroupEntries: Array<{
    __typename?: 'PricingGroupPricingEntry';
    id: string;
    rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
    pricing: {
      __typename?: 'Pricing';
      id: string;
      amount: number;
      currency: Currency;
      plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
    };
  }>;
  protectionPricingGroupEntries: Array<{
    __typename?: 'PricingGroupPricingEntry';
    id: string;
    rateGroup?: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean } | null;
    pricing: {
      __typename?: 'Pricing';
      id: string;
      amount: number;
      currency: Currency;
      plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
    };
  }>;
};

export type RateAdjustment__DraftFragment = {
  __typename?: 'RateAdjustment__Draft';
  accountID: string;
  rentalID?: string | null;
  newPrice: number;
  effectiveMonthYear: string;
  excludeFromEmails: boolean;
  bestOffer?: number | null;
  label?: string | null;
};

export type RateAdjustment__SchedulingFragment = {
  __typename?: 'RateAdjustment__Scheduling';
  id: string;
  state: RateAdjustment__Scheduling__State;
  createdAt: string;
  effectiveAt: string;
  oldPrice: number;
  newPrice: number;
  percentageWithSign: string;
  label?: string | null;
  account: {
    __typename?: 'Account';
    id: string;
    customer: { __typename?: 'Customer'; id: string; name: string; phone?: string | null };
  };
  selfStorageRental?: {
    __typename?: 'SelfStorage__Rental';
    id: string;
    unit: {
      __typename?: 'SelfStorage__Unit';
      id: string;
      name: string;
      classification: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        length: number;
        width: number;
        height: number;
        indoors: boolean;
        climate: boolean;
        electricity: boolean;
        firstFloor: boolean;
        obstructed: boolean;
        category: SelfStorage__Classification__Category;
        kind: {
          __typename?: 'SelfStorage__Kind';
          id: string;
          name: string;
          subtype?: SelfStorage__Kind__Subtype | null;
        };
      };
      facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
    };
  } | null;
};

export type RateGroupFragment = { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };

export type RegionFragment = {
  __typename?: 'Region';
  id: string;
  active: boolean;
  name: string;
  tz: string;
  hexColor: string;
  createdAt: string;
  updatedAt: string;
  zipCodes: Array<string>;
  storageExtendedServiceAreaPostalCodes: Array<string>;
  movingExtendedServiceAreaPostalCodes: Array<string>;
  irmCluster: IrmClusterEnum;
  irmTerritory: IrmTerritoryEnum;
  geo: ClutterGeoEnum;
  netsuiteRegionID?: number | null;
  fulfiller: RegionFulfillerEnum;
  parent?: { __typename?: 'Region'; id: string; name: string } | null;
  center: { __typename?: 'LocatablePoint'; latitude: number; longitude: number };
  operatingScheduleTemplates: Array<{
    __typename?: 'OperatingScheduleTemplate';
    id: string;
    effectiveDate: string;
    operatesSunday: boolean;
    operatesMonday: boolean;
    operatesTuesday: boolean;
    operatesWednesday: boolean;
    operatesThursday: boolean;
    operatesFriday: boolean;
    operatesSaturday: boolean;
    orderType?: OrderTypeEnum | null;
    orderSubtype?: OrderSubtypeEnum | null;
  }>;
  defaultDepot?: { __typename?: 'Depot'; id: string } | null;
  defaultWarehouse?: { __typename?: 'Warehouse'; id: string; name: string } | null;
};

export type ReturnInventoryFragment = {
  __typename?: 'OrderService';
  items: Array<{
    __typename?: 'Item';
    id: string;
    uuid?: string | null;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    barcode: { __typename?: 'Barcode'; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type RoomCategoryFragment = {
  __typename?: 'RoomCategory';
  id: string;
  name: string;
  countable: boolean;
  cuft: number;
};

export type SelfStorage__AccountForRentingFragment = {
  __typename?: 'Account';
  id: string;
  number: string;
  customer: {
    __typename?: 'Customer';
    id: string;
    name: string;
    cellPhone?: string | null;
    homePhone?: string | null;
    workPhone?: string | null;
  };
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    kinds: Array<string>;
  }>;
  sources: Array<{
    __typename?: 'Billing__Source';
    id: string;
    brand?: string | null;
    kind: Billing__Source__Kind;
    number: string;
    expiration?: string | null;
    default: boolean;
    billable: boolean;
  }>;
  policy?: {
    __typename?: 'SelfStorage__Policy';
    id: string;
    kind: SelfStorage__Policy__Kind;
    number: string;
    company: string;
    amount: number;
    from: string;
    till: string;
  } | null;
  identification?: {
    __typename?: 'SelfStorage__Identification';
    id: string;
    number: string;
    state: string;
    birthday: string;
  } | null;
  subscriptions: Array<{
    __typename?: 'SelfStorage__Subscription';
    id: string;
    billing: SelfStorage__Rental__BillingEnum;
    billsOn?: string | null;
    source?: { __typename?: 'Billing__Source'; id: string } | null;
    rentals: Array<{ __typename?: 'SelfStorage__Rental'; id: string; status: SelfStorage__Status }>;
  }>;
};

export type SelfStorage__ClassificationFragment = {
  __typename?: 'SelfStorage__Classification';
  id: string;
  length: number;
  width: number;
  height: number;
  indoors: boolean;
  climate: boolean;
  electricity: boolean;
  firstFloor: boolean;
  obstructed: boolean;
  category: SelfStorage__Classification__Category;
  kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
};

export type SelfStorage__ClassificationDraftFragment = {
  __typename?: 'SelfStorage__Classification';
  length: number;
  width: number;
  height: number;
  indoors: boolean;
  climate: boolean;
  electricity: boolean;
  firstFloor: boolean;
  obstructed: boolean;
  kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
};

export type SelfStorage__DocumentFragment = { __typename?: 'SelfStorage__Document'; id: string; name: string };

export type SelfStorage__FacilityFragment = {
  __typename?: 'SelfStorage__Facility';
  id: string;
  active: boolean;
  name: string;
  address: {
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    latitude?: number | null;
    longitude?: number | null;
  };
  region?: { __typename?: 'Region'; id: string; name: string } | null;
};

export type SelfStorage__FeeFragment = {
  __typename?: 'SelfStorage__Fee';
  id: string;
  facilityID: string;
  kind: SelfStorage__Fee__Kind;
  price: number;
  recurring: boolean;
  waivable: boolean;
};

export type SelfStorage__KindFragment = {
  __typename?: 'SelfStorage__Kind';
  id: string;
  name: string;
  subtype?: SelfStorage__Kind__Subtype | null;
};

export type SelfStorage__PriceFragment = {
  __typename?: 'SelfStorage__Price';
  id: string;
  rate: number;
  pushRate: number;
  quotedRate: number;
};

export type SelfStorage__PromotionFragment = {
  __typename?: 'SelfStorage__Promotion';
  id: string;
  kind: SelfStorage__Promotion__Kind;
  value: number;
  term: number;
  delay: number;
  name: string;
};

export type SelfStorage__ProtectionFragment = {
  __typename?: 'SelfStorage__Protection';
  id: string;
  name: string;
  price: number;
};

export type SelfStorage__RentalFragment = {
  __typename?: 'SelfStorage__Rental';
  id: string;
  status: SelfStorage__Status;
  overlocked: boolean;
  rate: number;
  activatedAt?: string | null;
  canceledAt?: string | null;
  subscription: {
    __typename?: 'SelfStorage__Subscription';
    id: string;
    billing: SelfStorage__Rental__BillingEnum;
    activatedAt?: string | null;
    canceledAt?: string | null;
    billsOn?: string | null;
    defaultSource?: {
      __typename?: 'Billing__Source';
      id: string;
      brand?: string | null;
      default: boolean;
      fundingType: Billing__Source__FundingType;
      number: string;
    } | null;
    account: {
      __typename?: 'Account';
      id: string;
      customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
    };
  };
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    floor?: number | null;
    name: string;
    facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    };
  };
};

export type SelfStorage__RentalWithBillingFragment = {
  __typename?: 'SelfStorage__Rental';
  unitRate: number;
  id: string;
  status: SelfStorage__Status;
  overlocked: boolean;
  rate: number;
  activatedAt?: string | null;
  canceledAt?: string | null;
  fees: Array<{
    __typename?: 'SelfStorage__Fee';
    id: string;
    facilityID: string;
    kind: SelfStorage__Fee__Kind;
    price: number;
    recurring: boolean;
    waivable: boolean;
  }>;
  promotion?: {
    __typename?: 'SelfStorage__Promotion';
    id: string;
    kind: SelfStorage__Promotion__Kind;
    value: number;
    term: number;
    delay: number;
    name: string;
  } | null;
  protection?: { __typename?: 'SelfStorage__Protection'; id: string; name: string; price: number } | null;
  subscription: {
    __typename?: 'SelfStorage__Subscription';
    id: string;
    billing: SelfStorage__Rental__BillingEnum;
    activatedAt?: string | null;
    canceledAt?: string | null;
    billsOn?: string | null;
    defaultSource?: {
      __typename?: 'Billing__Source';
      id: string;
      brand?: string | null;
      default: boolean;
      fundingType: Billing__Source__FundingType;
      number: string;
    } | null;
    account: {
      __typename?: 'Account';
      id: string;
      customer: { __typename?: 'Customer'; id: string; name: string; email: string; phone?: string | null };
    };
  };
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    floor?: number | null;
    name: string;
    facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      length: number;
      width: number;
      height: number;
      indoors: boolean;
      climate: boolean;
      electricity: boolean;
      firstFloor: boolean;
      obstructed: boolean;
      category: SelfStorage__Classification__Category;
      kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
    };
  };
};

export type SelfStorage__ReservationFragment = {
  __typename?: 'SelfStorage__Reservation';
  id: string;
  date?: string | null;
  name: string;
  email: string;
  phone: string;
  state: SelfStorage__Reservation__State;
  length?: number | null;
  width?: number | null;
  unitType?: string | null;
  quotedPrice: number;
  tags: Array<string>;
  confirmationAttemptDate?: string | null;
  externalConfirmationNumber?: string | null;
  smsSubscribed: boolean;
  reservedAt?: string | null;
  createdAt: string;
  updatedAt: string;
  classification?: {
    __typename?: 'SelfStorage__Classification';
    id: string;
    length: number;
    width: number;
    height: number;
    indoors: boolean;
    climate: boolean;
    electricity: boolean;
    firstFloor: boolean;
    obstructed: boolean;
    category: SelfStorage__Classification__Category;
    kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
  } | null;
  facility: {
    __typename?: 'SelfStorage__Facility';
    id: string;
    name: string;
    bookingLink: string;
    phoneNumber?: string | null;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      safeAndSanitary?: boolean | null;
    };
    partner?: { __typename?: 'SelfStorage__Partner'; id: string; name: string; isThirdParty: boolean } | null;
  };
  lead: { __typename?: 'Lead'; id: string; name?: string | null; accountID?: string | null };
  user: { __typename?: 'User'; id: string; name: string };
  reserver?: { __typename?: 'User'; id: string; name: string } | null;
  price?: { __typename?: 'SelfStorage__Price'; id: string; rate: number; pushRate: number; quotedRate: number } | null;
  promotion?: {
    __typename?: 'SelfStorage__Promotion';
    id: string;
    kind: SelfStorage__Promotion__Kind;
    value: number;
    term: number;
    delay: number;
    name: string;
  } | null;
};

export type SelfStorage__SignatureFragment = {
  __typename?: 'SelfStorage__Signature';
  id: string;
  document: { __typename?: 'SelfStorage__Document'; id: string; name: string };
  contract?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
};

export type SelfStorage__SubscriptionFragment = {
  __typename?: 'SelfStorage__Subscription';
  id: string;
  billing: SelfStorage__Rental__BillingEnum;
  activatedAt?: string | null;
  billsOn?: string | null;
  canceledAt?: string | null;
  account: { __typename?: 'Account'; id: string };
  rentals: Array<{ __typename?: 'SelfStorage__Rental'; id: string; name: string }>;
  defaultSource?: {
    __typename?: 'Billing__Source';
    id: string;
    stripeID: string;
    kind: Billing__Source__Kind;
    fundingType: Billing__Source__FundingType;
    number: string;
    brand?: string | null;
    name?: string | null;
    expiration?: string | null;
    expired: boolean;
    default: boolean;
    active: boolean;
    billable: boolean;
    cvcCheck?: Billing__Source__CvcCheck | null;
    zipCheck?: Billing__Source__ZipCheck | null;
  } | null;
};

export type SelfStorage__SubscriptionItemFragment = {
  __typename?: 'SelfStorage__SubscriptionItem';
  id: string;
  rate: number;
  status: SelfStorage__Status;
  summary: string;
  activatedAt?: string | null;
  canceledAt?: string | null;
};

export type SelfStorage__UnitFragment = {
  __typename?: 'SelfStorage__Unit';
  id: string;
  rentable: boolean;
  status: SelfStorage__Unit__Status;
  name: string;
  floor?: number | null;
  createdAt: string;
  updatedAt: string;
  designations: Array<string>;
  facility: {
    __typename?: 'SelfStorage__Facility';
    id: string;
    name: string;
    partner?: { __typename?: 'SelfStorage__Partner'; isThirdParty: boolean } | null;
  };
  classification: {
    __typename?: 'SelfStorage__Classification';
    id: string;
    length: number;
    width: number;
    height: number;
    indoors: boolean;
    climate: boolean;
    electricity: boolean;
    firstFloor: boolean;
    obstructed: boolean;
    category: SelfStorage__Classification__Category;
    kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
  };
  price?: { __typename?: 'SelfStorage__Price'; id: string; rate: number; pushRate: number; quotedRate: number } | null;
  promotion?: {
    __typename?: 'SelfStorage__Promotion';
    id: string;
    kind: SelfStorage__Promotion__Kind;
    value: number;
    term: number;
    delay: number;
    name: string;
  } | null;
};

export type SelfStorage__UnitGroupFragment = {
  __typename?: 'SelfStorage__UnitGroup';
  remaining: number;
  classification: {
    __typename?: 'SelfStorage__Classification';
    id: string;
    length: number;
    width: number;
    height: number;
    indoors: boolean;
    climate: boolean;
    electricity: boolean;
    firstFloor: boolean;
    obstructed: boolean;
    category: SelfStorage__Classification__Category;
    kind: { __typename?: 'SelfStorage__Kind'; id: string; name: string; subtype?: SelfStorage__Kind__Subtype | null };
  };
  price: { __typename?: 'SelfStorage__Price'; id: string; rate: number; pushRate: number; quotedRate: number };
  promotion?: {
    __typename?: 'SelfStorage__Promotion';
    id: string;
    kind: SelfStorage__Promotion__Kind;
    value: number;
    term: number;
    delay: number;
    name: string;
  } | null;
};

export type SettingsFragment = {
  __typename?: 'Settings';
  internal: boolean;
  isBusiness: boolean;
  isThirdPartyLogistics: boolean;
  skuTeachInOptOut: boolean;
  emailReceipts: boolean;
  dicentralAccountID?: string | null;
  skipIncomingIvr: boolean;
};

export type SignatureFragment = {
  __typename?: 'Signature';
  id: string;
  documentKind: string;
  contractUrl?: string | null;
  skipped: boolean;
  reason?: string | null;
  explanation?: string | null;
  timestamp: string;
  state: string;
  agreement?: {
    __typename?: 'ActiveStorageAttachment';
    id: string;
    imgixURL: string;
    url: string;
    filename: string;
  } | null;
};

export type StorageTermFragment = {
  __typename?: 'StorageTerm';
  id: string;
  name: string;
  minimumMonths: number;
  rateGroup: { __typename?: 'RateGroup'; id: string; name: RateGroupKindEnum; curbside: boolean };
};

export type SubscriptionFragment = {
  __typename?: 'Subscription';
  id: string;
  stripeID?: string | null;
  name: string;
  state: string;
  quantity: number;
  customDimension?: {
    __typename?: 'CustomDimension';
    id: string;
    cuft: number;
    length: number;
    width: number;
    height: number;
  } | null;
  pricing: {
    __typename?: 'Pricing';
    id: string;
    amount: number;
    currency: Currency;
    plan: { __typename?: 'Plan'; id: string; kind: PlanKindEnum; cuft: number; name: string; type: PlanTypeEnum };
  };
};

export type TaskOrder__TaskFragment = {
  __typename?: 'TaskOrder__Task';
  id: string;
  instructions?: string | null;
  sequenceNumber: number;
  address: {
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    latitude?: number | null;
    longitude?: number | null;
  };
  contact?: { __typename?: 'Order__Contact'; id: string; name?: string | null; phone: string } | null;
};

export type TermCommitmentFragment = {
  __typename?: 'Account__TermCommitment';
  term: number;
  contractEndDate?: string | null;
  type: Account__TermCommitment__Type;
  fulfilled: boolean;
};

export type TicketFragment = {
  __typename?: 'Ticket';
  id: string;
  category: string;
  subCategory?: string | null;
  description: string;
  state: string;
  dueDate: string;
  updatedAt: string;
  resolutionType?: string | null;
  read: boolean;
  ticketableID: string;
  ticketableType: string;
  ticketableName: string;
  group?: { __typename?: 'Ticket__Group'; name: string } | null;
  assignee?: { __typename?: 'User'; id: string; name: string } | null;
  owner: { __typename?: 'User'; id: string; name: string };
  latestNote?: { __typename?: 'Note'; body: string } | null;
};

export type TicketGroupFragment = {
  __typename?: 'Ticket__Group';
  id: string;
  name: string;
  users: Array<{ __typename?: 'User'; id: string; name: string }>;
};

export type TicketMessageFragment = {
  __typename?: 'Ticket__Message';
  id: string;
  body: string;
  createdAt: string;
  deletedAt?: string | null;
  readAt?: string | null;
  updatedAt: string;
  files: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
  ticket: {
    __typename?: 'Ticket';
    id: string;
    category: string;
    subCategory?: string | null;
    description: string;
    state: string;
    dueDate: string;
    updatedAt: string;
    resolutionType?: string | null;
    read: boolean;
    ticketableID: string;
    ticketableType: string;
    ticketableName: string;
    group?: { __typename?: 'Ticket__Group'; name: string } | null;
    assignee?: { __typename?: 'User'; id: string; name: string } | null;
    owner: { __typename?: 'User'; id: string; name: string };
    latestNote?: { __typename?: 'Note'; body: string } | null;
  };
  user: { __typename?: 'User'; id: string; name: string };
};

export type UserFragment = { __typename?: 'User'; id: string; name: string };

export type VehicleFragment = { __typename?: 'Vehicle'; id: string; name: string };

export type WarehouseFragment = {
  __typename?: 'Warehouse';
  id: string;
  name: string;
  abbreviation: string;
  tz: string;
  active: boolean;
  height?: number | null;
  thirdPartyLogistics: boolean;
  inboundDockItemsPerHour: number;
  outboundDockItemsPerHour: number;
  customerAccessInstructions?: string | null;
  storageTreasuresFacilityID?: string | null;
  storageTreasuresLink?: string | null;
  address: {
    __typename?: 'Address';
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    country?: string | null;
    zip: string;
    latitude?: number | null;
    longitude?: number | null;
  };
  regions: Array<{ __typename?: 'Region'; id: string }>;
  customerAccessHours?: Array<{
    __typename?: 'CustomerAccessHours';
    day: string;
    open?: string | null;
    close?: string | null;
  }> | null;
  operatingScheduleTemplates: Array<{
    __typename?: 'OperatingScheduleTemplate';
    id: string;
    effectiveDate: string;
    operatesSunday: boolean;
    operatesMonday: boolean;
    operatesTuesday: boolean;
    operatesWednesday: boolean;
    operatesThursday: boolean;
    operatesFriday: boolean;
    operatesSaturday: boolean;
  }>;
};

export type Workforce__ClockinFragment = {
  __typename?: 'Workforce__Clockin';
  id: string;
  timestamp: string;
  distance: number;
  latitude: number;
  longitude: number;
  facility: { __typename?: 'Workforce__Facility'; id: string; name: string };
};

export type Workforce__FacilityFragment = { __typename?: 'Workforce__Facility'; id: string; name: string };

export type Workforce__JobCodeFragment = { __typename?: 'Workforce__JobCode'; id: string; name: string };

export type Workforce__ShiftFragment = {
  __typename?: 'Workforce__Shift';
  id: string;
  date: string;
  duration: number;
  notes?: string | null;
  status: Workforce__Shift__ViolationStatus;
  approvedAt?: string | null;
  overriddenAt?: string | null;
  disputedAt?: string | null;
  deletedAt?: string | null;
  createdAt: string;
  updatedAt: string;
  user: { __typename?: 'User'; tz: string; id: string; name: string };
};

export type Workforce__Shift__ActivityFragment = {
  __typename?: 'Workforce__Shift__Activity';
  id: string;
  durationMinutes: number;
  position: number;
  jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
};

export type Workforce__ShiftAssignmentFragment = {
  __typename?: 'Workforce__ShiftAssignment';
  id: string;
  jobCode: { __typename?: 'Workforce__JobCode'; id: string; name: string };
};

export type Workforce__Work_BreakFragment = {
  __typename?: 'Workforce__WorkBreak';
  id: string;
  uuid: string;
  started?: string | null;
  ended?: string | null;
};

export type ZendeskTicketAssociationFragment = {
  __typename?: 'ZendeskTicketAssociation';
  id: string;
  zdTicketID: string;
  createdAt: string;
  creator?: { __typename?: 'User'; id: string; name: string } | null;
};

export type ValidEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ValidEmailQuery = { __typename?: 'Query'; valid: boolean };

export type ValidPhoneQueryVariables = Exact<{
  phone: Scalars['String'];
}>;

export type ValidPhoneQuery = { __typename?: 'Query'; valid: boolean };

export type AvailabilitiesQueryVariables = Exact<{
  accountID?: InputMaybe<Scalars['ID']>;
  input: AvailabilitiesInput;
}>;

export type AvailabilitiesQuery = {
  __typename?: 'Query';
  availabilities: Array<{
    __typename?: 'Availability';
    appointmentFee?: string | null;
    datetime: string;
    duration?: string | null;
    available: boolean;
    perMoverHourAdjustmentAmount?: number | null;
    laborBillingFormat?: LaborBillingFormatEnum | null;
    laborRate?: { __typename?: 'Pricing__LaborRate'; id: string; name: string; amount: number } | null;
    feeAmount?: { __typename?: 'MonetaryAmount'; formatted: string; value: number } | null;
  }>;
};

export type PickupMoverCountQueryVariables = Exact<{
  orderID: Scalars['ID'];
  inventory: PickupInventoryInput;
}>;

export type PickupMoverCountQuery = { __typename?: 'Query'; pickupMoverCount: number };

export type PollForInvoiceEventQueryVariables = Exact<{
  accountID: Scalars['ID'];
  attemptID: Scalars['ID'];
}>;

export type PollForInvoiceEventQuery = {
  __typename?: 'Query';
  billing: {
    __typename?: 'Billing__Context';
    invoiceEvent: { __typename?: 'Billing__InvoiceEvent'; completed: boolean; id: string };
  };
};

export type PricingPerItemFeeQueryVariables = Exact<{
  input: Pricing__PerItemFeeInput;
}>;

export type PricingPerItemFeeQuery = {
  __typename?: 'Query';
  pricingPerItemFee?: {
    __typename?: 'Pricing__PerItemFee';
    id: string;
    amount: number;
    largeAmount: number;
    orderType: OrderTypeEnum;
    orderSubtype: OrderSubtypeEnum;
  } | null;
};

export const AccountRateAdjustmentSchedulingFragmentDoc = gql`
  fragment accountRateAdjustmentScheduling on RateAdjustment__Scheduling {
    id
    effectiveAt
    notified
    newPrice
    percentageWithSign
    state
    cancelReason
  }
`;
export const Admin__ClaimFragmentDoc = gql`
  fragment Admin__Claim on Claim {
    id
    uuid
    number
    status
    hasIssue
    hasLostItem
    hasDamagedItem
    hasDamagedProperty
    openedAt
    closedAt
    offeredAt
    expiredReason
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    name
  }
`;
export const AccountClaimFragmentDoc = gql`
  fragment accountClaim on Claim {
    ...Admin__Claim
    owner {
      ...user
    }
    itemSelections {
      id
      declaration
    }
    propertySelection {
      id
      declaration
    }
  }
  ${Admin__ClaimFragmentDoc}
  ${UserFragmentDoc}
`;
export const CurrentMembershipFragmentDoc = gql`
  fragment CurrentMembership on Abeet__Membership {
    createdAt
    variant {
      variables
    }
  }
`;
export const AttachmentFragmentDoc = gql`
  fragment attachment on ActiveStorageAttachment {
    id
    imgixURL
    url
    filename
  }
`;
export const Claim__ResolutionFragmentDoc = gql`
  fragment Claim__Resolution on Claim__Resolution {
    id
    kind
    amount
    reason
    offeredAt
    user {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const Claim__IssueSelectionFragmentDoc = gql`
  fragment Claim__IssueSelection on Claim__IssueSelection {
    id
    compensationRequested
    declaration
    description
    kind
    photos {
      ...attachment
    }
    photos {
      ...attachment
    }
    resolution {
      ...Claim__Resolution
    }
    offeredResolutions {
      ...Claim__Resolution
    }
  }
  ${AttachmentFragmentDoc}
  ${Claim__ResolutionFragmentDoc}
`;
export const Claim__AssessmentFragmentDoc = gql`
  fragment Claim__Assessment on Claim__Assessment {
    id
    verifiedValue
    weight
    classification
    approvedAmount
    coverage
  }
`;
export const Claim__ItemSelectionFragmentDoc = gql`
  fragment Claim__ItemSelection on Claim__ItemSelection {
    id
    kind
    name
    declaration
    productURL
    photos {
      ...attachment
    }
    receipts {
      ...attachment
    }
    item {
      id
      name
      barcode {
        value
      }
    }
    resolution {
      ...Claim__Resolution
    }
    offeredResolutions {
      ...Claim__Resolution
    }
    assessment {
      ...Claim__Assessment
    }
  }
  ${AttachmentFragmentDoc}
  ${Claim__ResolutionFragmentDoc}
  ${Claim__AssessmentFragmentDoc}
`;
export const Claim__PropertySelectionFragmentDoc = gql`
  fragment Claim__PropertySelection on Claim__PropertySelection {
    id
    name
    declaration
    ownership
    owner {
      name
      email
      phone
    }
    photos {
      ...attachment
    }
    quotes {
      ...attachment
    }
    resolution {
      ...Claim__Resolution
    }
    offeredResolutions {
      ...Claim__Resolution
    }
    assessment {
      ...Claim__Assessment
    }
  }
  ${AttachmentFragmentDoc}
  ${Claim__ResolutionFragmentDoc}
  ${Claim__AssessmentFragmentDoc}
`;
export const Claim__OfferFragmentDoc = gql`
  fragment Claim__Offer on Claim__Offer {
    id
    details
    createdAt
    expiry
    totalAmount
    user {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const DetailedClaimFragmentDoc = gql`
  fragment detailedClaim on Claim {
    ...Admin__Claim
    owner {
      ...user
    }
    issueSelection {
      ...Claim__IssueSelection
    }
    itemSelections {
      ...Claim__ItemSelection
    }
    propertySelection {
      ...Claim__PropertySelection
    }
    account {
      id
      customer {
        id
        name
      }
      latestProtectionSubscription {
        name
        quantity
      }
    }
    offers {
      ...Claim__Offer
    }
    settlementAgreement {
      ...attachment
    }
  }
  ${Admin__ClaimFragmentDoc}
  ${UserFragmentDoc}
  ${Claim__IssueSelectionFragmentDoc}
  ${Claim__ItemSelectionFragmentDoc}
  ${Claim__PropertySelectionFragmentDoc}
  ${Claim__OfferFragmentDoc}
  ${AttachmentFragmentDoc}
`;
export const Admin__PaginatedClaimFragmentDoc = gql`
  fragment Admin__PaginatedClaim on Claim {
    ...Admin__Claim
    kind
    account {
      id
      customer {
        id
        name
      }
    }
    owner {
      ...user
    }
  }
  ${Admin__ClaimFragmentDoc}
  ${UserFragmentDoc}
`;
export const StorageArrangementFragmentDoc = gql`
  fragment storageArrangement on DropOff__StorageArrangement {
    ... on SelfStorage__Hold {
      unit {
        id
        name
      }
    }
    ... on DropOff__ThirdPartySelfStorageUnit {
      id
      name
    }
  }
`;
export const AccessFragmentDoc = gql`
  fragment access on DropOff__Access {
    id
    customerCode
    moverCode
  }
`;
export const AdminDropOffAppointmentFragmentDoc = gql`
  fragment AdminDropOffAppointment on DropOff__Appointment {
    id
    expectedAt
    canceledAt
    completedAt
    state
    storageArrangement {
      ...storageArrangement
    }
    access {
      ...access
    }
    facility {
      id
      name
      hours
      region {
        id
      }
      fulfiller {
        ... on SelfStorage__Facility {
          id
          accessCode
        }
        ... on DropOff__ThirdPartySelfStorageFacility {
          id
          accessCode
        }
      }
    }
    files {
      ...attachment
    }
  }
  ${StorageArrangementFragmentDoc}
  ${AccessFragmentDoc}
  ${AttachmentFragmentDoc}
`;
export const UserFormFragmentDoc = gql`
  fragment UserForm on Query {
    regions {
      id
      name
    }
    teams {
      id
      name
    }
  }
`;
export const UserDefaultsFragmentDoc = gql`
  fragment UserDefaults on User {
    id
    workdayUserID
    name
    email
    phone
    tz
    roles
    agentPriority
    externalGroup
    notificationPreference
    image {
      id
      source
    }
    team {
      id
      name
    }
    regions {
      id
      name
    }
    ownedTeams {
      id
      name
    }
    hasTimeClockPlus
  }
`;
export const CustomerFragmentDoc = gql`
  fragment customer on Customer {
    id
    name
    phone
  }
`;
export const CustomDimensionFragmentDoc = gql`
  fragment customDimension on CustomDimension {
    id
    cuft
    length
    width
    height
  }
`;
export const PlanFragmentDoc = gql`
  fragment plan on Plan {
    id
    kind
    cuft
    name
    type
  }
`;
export const PricingFragmentDoc = gql`
  fragment pricing on Pricing {
    id
    amount
    currency
    plan {
      ...plan
    }
  }
  ${PlanFragmentDoc}
`;
export const SubscriptionFragmentDoc = gql`
  fragment subscription on Subscription {
    id
    stripeID
    name
    state
    quantity
    customDimension {
      ...customDimension
    }
    pricing {
      ...pricing
    }
  }
  ${CustomDimensionFragmentDoc}
  ${PricingFragmentDoc}
`;
export const AccountFragmentDoc = gql`
  fragment account on Account {
    id
    number
    customer {
      ...customer
    }
    subscriptions {
      ...subscription
    }
  }
  ${CustomerFragmentDoc}
  ${SubscriptionFragmentDoc}
`;
export const Account__ClaimsSummaryFragmentDoc = gql`
  fragment Account__ClaimsSummary on ClaimsSummary {
    totalItemCompensation
    totalPropertyCompensation
    paidItemCompensation
    pendingItemCompensation
    paidPropertyCompensation
    pendingPropertyCompensation
    latestProtectionPlan {
      slug
      name
      maxCovered
    }
  }
`;
export const AttestationEventFragmentDoc = gql`
  fragment attestationEvent on AttestationEvent {
    id
    kind
    flagged
    comment
    correctedShiftStart
    correctedWorkBreaks
    reviewDueDate
    reviewedAt
    shift {
      id
      date
      started {
        id
        timestamp
        facility {
          id
        }
      }
      ended {
        id
        timestamp
        facility {
          id
        }
      }
      workBreaks {
        id
        uuid
        started
        ended
      }
      user {
        ...user
        tz
      }
      activities {
        id
        durationMinutes
        position
        jobCode {
          id
        }
      }
    }
  }
  ${UserFragmentDoc}
`;
export const Auction__UploadFragmentDoc = gql`
  fragment Auction__upload on Auction__Upload {
    id
    description
    state
    error
    user {
      id
      name
    }
    originalCSV {
      id
      filename
      url
    }
    failureCSV {
      id
      filename
      url
    }
    processingAt
    processedAt
  }
`;
export const AuditOrderEventFragmentDoc = gql`
  fragment auditOrderEvent on AuditOrderEvent {
    id
    titleizedEventName
    timestamp
    orderId
    image {
      id
      source
      size
    }
  }
`;
export const AvailabilityFragmentDoc = gql`
  fragment availability on Availability {
    datetime
    duration
    available
    laborRate {
      id
      name
      amount
    }
    perMoverHourAdjustmentAmount
    laborBillingFormat
    feeAmount {
      formatted
      value
    }
  }
`;
export const FacilityCuftLimitFragmentDoc = gql`
  fragment facilityCuftLimit on Availability__FacilityCuftLimit {
    id
    facility {
      id
      name
    }
    date
    creator {
      ...user
    }
    maximumInboundCuft
    maximumInboundOutboundCuft
    maximumInboundTransferCuft
    maximumOutboundCuft
    maximumOutboundFacilityCuft
    maximumOutboundDisposalCuft
    reason
  }
  ${UserFragmentDoc}
`;
export const Availability__GlobalUnavailabilityFragmentDoc = gql`
  fragment Availability__globalUnavailability on Availability__GlobalUnavailability {
    id
    date
    name
    reason
    capacityReductionPercentage
    region {
      id
      name
    }
    creator {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const Availability__JobTypeAvailabilityFragmentDoc = gql`
  fragment Availability__jobTypeAvailability on Availability__JobTypeAvailability {
    id
    date
    maximumMoverMinutes
    bookedMoverMinutes
    displayKind
    region {
      id
      name
    }
    creator {
      ...user
    }
    reason
  }
  ${UserFragmentDoc}
`;
export const Availability__LaborAdjustmentFragmentDoc = gql`
  fragment Availability__laborAdjustment on Availability__LaborAdjustment {
    id
    date
    creator {
      ...user
    }
    region {
      id
      name
    }
    lowThresholdPercentage
    highThresholdPercentage
    perMoverHourAdjustmentAmount
    orderClassification
  }
  ${UserFragmentDoc}
`;
export const Availability__RescheduleOfferFragmentDoc = gql`
  fragment Availability__rescheduleOffer on Availability__RescheduleOffer {
    id
    originDate
    targetDates
    orderMinimumMoverMinutes
    originTargetAvailabilityThreshold
    orderClassification
    startAt
    expireAt
    expired
    rescheduleOfferItems {
      id
      coupon {
        id
        name
        amount
        percent
      }
    }
    creator {
      ...user
    }
    region {
      id
      name
    }
  }
  ${UserFragmentDoc}
`;
export const WaitlistRequestFragmentDoc = gql`
  fragment waitlistRequest on Availability__WaitlistRequest {
    id
    date
  }
`;
export const Billing__ForgivenessReasonFragmentDoc = gql`
  fragment billing__forgivenessReason on Billing__ForgivenessReason {
    id
    kind
    parentID
  }
`;
export const Billing__ReBillReasonFragmentDoc = gql`
  fragment billing__reBillReason on Billing__ReBillReason {
    id
    name
  }
`;
export const Admin__CouponFragmentDoc = gql`
  fragment Admin__Coupon on Coupon {
    id
    stripeId
    name
    code
    active
    amount
    percent
    duration
    durationInMonths
    effective
    eligibleServices
    scheduledCutoff
    subject
    currency
  }
`;
export const PhoneCallFragmentDoc = gql`
  fragment phoneCall on PhoneCall {
    id
    createdAt
    direction
    note {
      body
    }
    latestConnectedUser {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const CustomerInteractionFragmentDoc = gql`
  fragment customerInteraction on CustomerInteraction {
    id
    interactableType
    interactable {
      ... on PhoneCall {
        ...phoneCall
      }
    }
  }
  ${PhoneCallFragmentDoc}
`;
export const CustomerTicketFragmentDoc = gql`
  fragment customerTicket on CustomerTicket {
    id
    category
    subCategory
    description
    state
    createdAt
    actionRequiredAt
    customerUpdatedAt
    priority
    contactPreference
    allowIncomingCalls
    assignee {
      ...user
    }
    creator {
      ...user
    }
    customerTicketableType
    customerTicketable {
      ... on Account {
        id
        shortDisplay
      }
      ... on Lead {
        id
        shortDisplay
      }
    }
    group {
      id
      name
    }
  }
  ${UserFragmentDoc}
`;
export const DetailedAddressFragmentDoc = gql`
  fragment detailedAddress on Address {
    id
    street
    addressableId
    addressableType
    aptsuite
    city
    country
    state
    zip
    latitude
    longitude
    serviceable
    businessName
    safeAndSanitary
    schedulable
    details {
      buildingType
      buildingSubtype
      floor
      stories
      frontDoorSteps
      stairs
      squareFeet
      elevator
      paperwork
      gated
      steep
      serviceElevator
      serviceEntrance
      loadingDock
      code
      codeValue
      parkingStreet
      parkingDriveway
      parkingLot
      parkingAlley
      parkingPermit
      parkingInFront
      parkingOnSpecificStreet
      parkingInstructions
      maxTruckHeight
      maxTruckHeightUnknown
      accessUnknown
      paperworkUnknown
      parkingUnknown
      environment
      additionalNotes
      buildingOpeningHour
      buildingClosingHour
      buildingRestrictionsAnswer
      parkingLatitude
      parkingLongitude
      secureLocationDetails
      longCarry
      unitSize
    }
  }
`;
export const DetailedImageFragmentDoc = gql`
  fragment DetailedImage on DetailedImage {
    id
    size
    createdAt
    source
    name
    user {
      id
      name
    }
  }
`;
export const DetailedPhoneCallFragmentDoc = gql`
  fragment detailedPhoneCall on PhoneCall {
    id
    startAt
    createdAt
    latestConnectedUser {
      name
    }
    status
    direction
    duration
    touchTaxons {
      name
      parentName
    }
    notes {
      body
    }
    conversations {
      recording {
        playbackURL
      }
    }
  }
`;
export const DropOffFacilityFragmentDoc = gql`
  fragment dropOffFacility on DropOff__Facility {
    id
    name
  }
`;
export const DropOffThirdPartySelfStorageUnitFragmentDoc = gql`
  fragment dropOffThirdPartySelfStorageUnit on DropOff__ThirdPartySelfStorageUnit {
    id
    name
    directions
    length
    width
    height
    accessCode
  }
`;
export const ErrorFragmentDoc = gql`
  fragment error on Error {
    __typename
    errorCode: code
    errorMessage: message
  }
`;
export const EstimationUploadFragmentDoc = gql`
  fragment estimationUpload on Estimation__UploadType {
    id
    media {
      id
      imgixURL
      isImage
      isVideo
    }
  }
`;
export const EstimationCategoryFragmentDoc = gql`
  fragment estimationCategory on EstimationCategory {
    id
    name
    packable
    cuft
    numMovers
  }
`;
export const HistoryResourceChangedFieldFragmentDoc = gql`
  fragment historyResourceChangedField on Changed__Field {
    name
    now
    was
  }
`;
export const HistoryResourceChangedAuditedFragmentDoc = gql`
  fragment historyResourceChangedAudited on Changed__Audited {
    ... on Changed__Customer {
      id
      email
    }
    ... on Changed__Phone {
      id
      number
    }
    ... on LaborClock {
      id
      user {
        id
        name
      }
      role
    }
    ... on Changed__Record {
      id
      type
    }
  }
`;
export const HistoryResourceChangedRelationshipFragmentDoc = gql`
  fragment historyResourceChangedRelationship on Changed__Relationship {
    name
    associations {
      id
      name
      kind
      associated {
        id
        name
      }
    }
  }
`;
export const HistoryResourceChangedAuditFragmentDoc = gql`
  fragment historyResourceChangedAudit on Changed__Audit {
    id
    event
    fields {
      ...historyResourceChangedField
    }
    audited {
      ...historyResourceChangedAudited
    }
    relationships {
      ...historyResourceChangedRelationship
    }
    changer {
      id
      name
    }
  }
  ${HistoryResourceChangedFieldFragmentDoc}
  ${HistoryResourceChangedAuditedFragmentDoc}
  ${HistoryResourceChangedRelationshipFragmentDoc}
`;
export const HistoryResourceNoteFragmentDoc = gql`
  fragment historyResourceNote on Note {
    id
    body
    user {
      id
      name
    }
  }
`;
export const HistoryResourceUsageFragmentDoc = gql`
  fragment historyResourceUsage on Usage {
    id
    cuft
    user {
      id
      name
    }
  }
`;
export const HistoryResourceSubscribeFragmentDoc = gql`
  fragment historyResourceSubscribe on Subscribe {
    id
    user {
      id
      name
    }
    order {
      id
      accountID
      number
    }
    adjustments {
      id
      kind
      subscription {
        id
        name
        quantity
        pricing {
          id
          amount
        }
      }
    }
  }
`;
export const HistoryResourcePhoneCallFragmentDoc = gql`
  fragment historyResourcePhoneCall on PhoneCall {
    id
    status
    direction
    startAt
    endAt
    duration
    reasons
    notes {
      id
      body
    }
    conversations {
      id
      user {
        id
        name
      }
      recording {
        id
        playbackURL
      }
    }
    voicemails {
      id
      recording {
        id
        playbackURL
      }
    }
    phone {
      id
      number
    }
  }
`;
export const HistoryResourceMarketingEventFragmentDoc = gql`
  fragment historyResourceMarketingEvent on MarketingEvent {
    id
    metadata
  }
`;
export const HistoryFragmentDoc = gql`
  fragment history on History {
    tag
    timestamp
    resource {
      __typename
      ... on Changed__Audit {
        ...historyResourceChangedAudit
      }
      ... on Note {
        ...historyResourceNote
      }
      ... on Usage {
        ...historyResourceUsage
      }
      ... on Subscribe {
        ...historyResourceSubscribe
      }
      ... on PhoneCall {
        ...historyResourcePhoneCall
      }
      ... on MarketingEvent {
        ...historyResourceMarketingEvent
      }
    }
  }
  ${HistoryResourceChangedAuditFragmentDoc}
  ${HistoryResourceNoteFragmentDoc}
  ${HistoryResourceUsageFragmentDoc}
  ${HistoryResourceSubscribeFragmentDoc}
  ${HistoryResourcePhoneCallFragmentDoc}
  ${HistoryResourceMarketingEventFragmentDoc}
`;
export const HoursFragmentDoc = gql`
  fragment hours on Hours {
    monday {
      open
      close
    }
    tuesday {
      open
      close
    }
    wednesday {
      open
      close
    }
    thursday {
      open
      close
    }
    friday {
      open
      close
    }
    saturday {
      open
      close
    }
    sunday {
      open
      close
    }
  }
`;
export const Ingestion__UploadFragmentDoc = gql`
  fragment Ingestion__upload on Ingestion__Upload {
    id
    description
    state
    error
    user {
      id
      name
    }
    spreadsheet {
      id
      filename
      url
    }
    processingAt
    processedAt
  }
`;
export const InventoryMetricsFragmentDoc = gql`
  fragment inventoryMetrics on InventoryMetrics {
    cuftUsed
    staleUsage
    unsizedItemsCount
  }
`;
export const ItemExceptionFragmentDoc = gql`
  fragment itemException on ItemException {
    id
    creator {
      name
    }
    reason
    explanation
    createdAt
    deletedAt
    image {
      id
      size
      source
    }
  }
`;
export const LeadFragmentDoc = gql`
  fragment lead on Lead {
    id
    name
  }
`;
export const LeadInteractionFragmentDoc = gql`
  fragment leadInteraction on LeadInteraction {
    id
    interactableType
    interactable {
      ... on PhoneCall {
        ...phoneCall
      }
    }
  }
  ${PhoneCallFragmentDoc}
`;
export const Logistics__AddressFragmentDoc = gql`
  fragment Logistics__Address on Address {
    id
    street
    aptsuite
    city
    state
    country
    zip
    latitude
    longitude
    businessName
  }
`;
export const LoadFragmentDoc = gql`
  fragment load on Logistics__Load {
    id
    type
    state
    number
    mustShipBy
    appointmentDate
    expectedOrders
    expectedPallets
    expectedItems
    trailerNumber
    freightChargeTerms
    mode
    billOfLadingNumber
    handlingMethod
    specialInstructions
    itemLabelsRequired
    palletLabelsRequired
    digitalBolSignatureRequired
    trailerSealRequired
    requestedAppointmentDate
    requestedAppointmentWindow
    drop
    releaseNumber
    reference1
    reference2
    reference3
    reference4
    reference5
    targetPalletHeight
    source
    palletHeightPreference
    warehouse {
      id
      name
      tz
    }
    purchaseOrders {
      id
      number
      company
      mustShipBy
      quantities {
        loadId
        skuValue
        palletCount
        itemCount
        skuID
      }
    }
    carrierDetails {
      id
      businessName
      scac
    }
  }
`;
export const MessageFragmentDoc = gql`
  fragment message on Message {
    id
    images {
      source
      size
    }
    twilioStatus
    iterableStatus
    consentBlocked
    body
    kind
    userName
    recipientName
    to
    from
    createdAt
    nonUserNumber
  }
`;
export const MovingQuoteFragmentDoc = gql`
  fragment movingQuote on Moving__Quote {
    id
    eligibility
    reasons
    laborRate {
      id
      amount
      name
    }
    materialPackageSetEntry {
      id
      fixedCost
      package {
        name
      }
    }
    truckCount
    moverSelection
    suggestedMoverCount
    deadtimeDriveDuration
    deadtimeDriveDistance
    drivetimeDuration
    driveDistance
    estimatedCuft
    packDurations
    unpackDurations
    orderDurations
    dispatchDurations
  }
`;
export const Dispatch__ItemMetricsFragmentDoc = gql`
  fragment Dispatch__itemMetrics on Dispatch__ItemMetrics {
    cuft
    count
  }
`;
export const NightTransport__DispatchFragmentDoc = gql`
  fragment NightTransport__dispatch on Dispatch {
    id
    arrival
    region {
      id
      name
      tz
    }
    vehiclePreferences {
      id
      quantity
      vehicleType {
        id
        name
      }
    }
    vehicles {
      id
      serialCode
    }
    estimatedInboundItemMetrics {
      ...Dispatch__itemMetrics
    }
    outboundItemMetrics {
      ...Dispatch__itemMetrics
    }
  }
  ${Dispatch__ItemMetricsFragmentDoc}
`;
export const FacilityFragmentDoc = gql`
  fragment facility on Facility {
    ... on Warehouse {
      id
      name
    }
    ... on Depot {
      id
      name
    }
    __typename
  }
`;
export const NightTransport__DriveTaskFragmentDoc = gql`
  fragment NightTransport__driveTask on NightTransport__DriveTask {
    dispatch {
      ...NightTransport__dispatch
    }
    destination {
      ...facility
    }
    origin {
      ...facility
    }
    vehicleSuggestion {
      ... on Vehicle {
        id
        name
      }
      ... on Dispatch__VehiclePreference {
        id
        quantity
        vehicleType {
          id
        }
      }
    }
  }
  ${NightTransport__DispatchFragmentDoc}
  ${FacilityFragmentDoc}
`;
export const NightTransport__RouteTimeline_TaskEventFragmentDoc = gql`
  fragment NightTransport__RouteTimeline_taskEvent on NightTransport__RouteTimeline__TaskEvent {
    category
    startTimestamp
    endTimestamp
    origin {
      ...facility
    }
    dispatchID
    vehicleName
  }
  ${FacilityFragmentDoc}
`;
export const NightTransport__RouteTimelineFragmentDoc = gql`
  fragment NightTransport__routeTimeline on NightTransport__RouteTimeline {
    arrival
    driver {
      ...user
    }
    taskEvents {
      ...NightTransport__RouteTimeline_taskEvent
    }
  }
  ${UserFragmentDoc}
  ${NightTransport__RouteTimeline_TaskEventFragmentDoc}
`;
export const NightTransport__RouteFragmentDoc = gql`
  fragment NightTransport__route on NightTransport__Route {
    id
    arrival
    origin {
      ...facility
    }
    driver {
      ...user
      tz
    }
  }
  ${FacilityFragmentDoc}
  ${UserFragmentDoc}
`;
export const NightTransport__DriveFragmentDoc = gql`
  fragment NightTransport__drive on NightTransport__Drive {
    id
    origin {
      ...facility
    }
    destination {
      ...facility
    }
    driveDispatch: dispatch {
      ...NightTransport__dispatch
    }
    vehicle {
      id
      name
    }
    predecessor {
      id
    }
  }
  ${FacilityFragmentDoc}
  ${NightTransport__DispatchFragmentDoc}
`;
export const NightTransport__CarpoolFragmentDoc = gql`
  fragment NightTransport__carpool on NightTransport__Carpool {
    id
    drive {
      id
    }
  }
`;
export const NightTransport__ResupplyFragmentDoc = gql`
  fragment NightTransport__resupply on NightTransport__Resupply {
    id
    drive {
      id
    }
    resupplyDispatch: dispatch {
      ...NightTransport__dispatch
    }
    vehicle {
      id
      name
    }
  }
  ${NightTransport__DispatchFragmentDoc}
`;
export const NightTransport__TaskFragmentDoc = gql`
  fragment NightTransport__task on NightTransport__Task {
    id
    route {
      ...NightTransport__route
    }
    position
    action {
      ... on NightTransport__Drive {
        ...NightTransport__drive
      }
      ... on NightTransport__Carpool {
        ...NightTransport__carpool
      }
      ... on NightTransport__Resupply {
        ...NightTransport__resupply
      }
    }
  }
  ${NightTransport__RouteFragmentDoc}
  ${NightTransport__DriveFragmentDoc}
  ${NightTransport__CarpoolFragmentDoc}
  ${NightTransport__ResupplyFragmentDoc}
`;
export const NoteFragmentDoc = gql`
  fragment note on Note {
    id
    body
    createdAt
    updatedAt
    user {
      id
      name
    }
    notable {
      id
      type
    }
    files {
      id
      url
      filename
    }
  }
`;
export const NotificationFragmentDoc = gql`
  fragment notification on Notification {
    id
    objectName
    createdAt
    updatedAt
    kind
    provider
    createdAt
    updatedAt
    deliveries {
      id
      state
      sent
      scheduled
      createdAt
      updatedAt
      triggerer
      emailEvents {
        ... on SendgridEvent {
          id
          event
          sender
          timestamp
        }
        ... on IterableEvent {
          id
          sender
          timestamp
          event
        }
      }
    }
  }
`;
export const OpsAudit__ImageFragmentDoc = gql`
  fragment OpsAudit__Image on Image {
    id
    size
    createdAt
    source
    name
  }
`;
export const OpsAudit__QuestionFragmentDoc = gql`
  fragment OpsAudit__Question on OpsAudit__QuestionType {
    id
    prompt
    groups
    slug
    answerChoices
    defaultAnswer
    ordering
    defectAnswers
    failedMessage
    failedAnswers
    ordering
    deletedAt
  }
`;
export const OpsAudit__AuditFragmentDoc = gql`
  fragment OpsAudit__Audit on OpsAudit__AuditType {
    id
    resourceType
    resourceID
    state
    questionGroup
    createdAt
    updatedAt
    configuration {
      id
      name
    }
    images {
      ...OpsAudit__Image
    }
    questions {
      ...OpsAudit__Question
    }
  }
  ${OpsAudit__ImageFragmentDoc}
  ${OpsAudit__QuestionFragmentDoc}
`;
export const OpsAudit__ConfigurationFragmentDoc = gql`
  fragment OpsAudit__Configuration on OpsAudit__ConfigurationType {
    id
    name
    user {
      id
      name
    }
    lastRanAt
    canceledAt
    createdAt
    resourceType
    refreshIntervalMinutes
    hoursUntilExpired
    pendingCount
    pausedAt
    exceptionReasonCodes
    dataQuery
  }
`;
export const AuditExceptionFragmentDoc = gql`
  fragment auditException on OpsAudit__AuditException {
    id
    reason
    status
    creator {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const PackageSetFragmentDoc = gql`
  fragment packageSet on PackageSet {
    id
    name
  }
`;
export const PackageFragmentDoc = gql`
  fragment package on Package {
    id
    name
    kind
    fullPack
  }
`;
export const PackageSetEntryFragmentDoc = gql`
  fragment packageSetEntry on PackageSetEntry {
    id
    amount(cuft: $cuft)
    orderSubtype
    package {
      ...package
    }
  }
  ${PackageFragmentDoc}
`;
export const PaginationFragmentDoc = gql`
  fragment pagination on Pagination {
    currentPage
    nextPage
    prevPage
    totalPages
    totalCount
  }
`;
export const PalletGradeFragmentDoc = gql`
  fragment palletGrade on PalletGrade {
    id
    createdAt
    user {
      name
    }
    image {
      id
      size
      source
      createdAt
    }
    frontImage {
      id
      size
      source
      createdAt
    }
    backImage {
      id
      size
      source
      createdAt
    }
  }
`;
export const Admin__PaymentPlan__AccountFragmentDoc = gql`
  fragment Admin__PaymentPlan__Account on Account {
    id
    number
    customer {
      id
      name
    }
  }
`;
export const Admin__Billing__InvoiceFragmentDoc = gql`
  fragment Admin__Billing__Invoice on Billing__Invoice {
    id
    createdAt
    summary
    status
    amountDue
  }
`;
export const Admin__PaymentPlan__OfferFragmentDoc = gql`
  fragment Admin__PaymentPlan__Offer on PaymentPlan__Offer {
    id
    state
    type
    oneTimeFee
    expiry
    expired
    createdAt
    account {
      ...Admin__PaymentPlan__Account
    }
    user {
      id
      name
    }
    settledInvoices {
      ...Admin__Billing__Invoice
    }
    acceptedAt
    storageTerm
    storageTermEndDate
    monthlyFee
    monthlyFeeTerm
    monthlyFeeTermEndDate
  }
  ${Admin__PaymentPlan__AccountFragmentDoc}
  ${Admin__Billing__InvoiceFragmentDoc}
`;
export const Admin__PaymentPlan__SubscriptionFragmentDoc = gql`
  fragment Admin__PaymentPlan__Subscription on PaymentPlan__Subscription {
    id
    offer {
      id
    }
    status
    amount
    term
    activatedAt
    canceledAt
    currentPeriodStart
    currentPeriodEnd
  }
`;
export const PhoneCallPolicyFragmentDoc = gql`
  fragment phoneCallPolicy on PhoneCallPolicy {
    download
  }
`;
export const EstimatedItemFragmentDoc = gql`
  fragment estimatedItem on EstimatedItem {
    id
    categoryId
    quantity
    categoryDisplayName
    notes
    length
    width
    height
    cuft
    estimatedMovers
  }
`;
export const PickupInventoryFragmentDoc = gql`
  fragment pickupInventory on OrderService {
    isFullMoveOut
    needsPackingHelp
    requestedMovers
    estimatedItems {
      ...estimatedItem
    }
    rooms {
      id
      selected
      quantity
      categoryID
    }
    estimations {
      id
      selected
      quantity
      categoryID
    }
  }
  ${EstimatedItemFragmentDoc}
`;
export const PerItemFeeFragmentDoc = gql`
  fragment perItemFee on Pricing__PerItemFee {
    id
    amount
    largeAmount
    orderType
    orderSubtype
  }
`;
export const Pricing__UploadFragmentDoc = gql`
  fragment Pricing__upload on Pricing__Upload {
    id
    description
    state
    error
    user {
      id
      name
    }
    spreadsheet {
      id
      filename
      url
    }
    processingAt
    processedAt
    service
  }
`;
export const RateGroupFragmentDoc = gql`
  fragment rateGroup on RateGroup {
    id
    name
    curbside
  }
`;
export const LaborRateFragmentDoc = gql`
  fragment laborRate on LaborRate {
    id
    amount
    name
  }
`;
export const LaborPolicyFragmentDoc = gql`
  fragment laborPolicy on LaborPolicy {
    id
    laborBillingFormat
    laborRate {
      ...laborRate
    }
  }
  ${LaborRateFragmentDoc}
`;
export const StorageTermFragmentDoc = gql`
  fragment storageTerm on StorageTerm {
    id
    name
    minimumMonths
    rateGroup {
      ...rateGroup
    }
  }
  ${RateGroupFragmentDoc}
`;
export const PricingGroupLaborEntryFragmentDoc = gql`
  fragment pricingGroupLaborEntry on PricingGroupLaborEntry {
    id
    rateGroup {
      ...rateGroup
    }
    laborPolicy {
      ...laborPolicy
    }
    storageTerm {
      ...storageTerm
    }
  }
  ${RateGroupFragmentDoc}
  ${LaborPolicyFragmentDoc}
  ${StorageTermFragmentDoc}
`;
export const PricingGroupPricingEntryFragmentDoc = gql`
  fragment pricingGroupPricingEntry on PricingGroupPricingEntry {
    id
    rateGroup {
      ...rateGroup
    }
    pricing {
      ...pricing
    }
  }
  ${RateGroupFragmentDoc}
  ${PricingFragmentDoc}
`;
export const PricingSetFragmentDoc = gql`
  fragment pricingSet on PricingSet {
    id
    quoteId
    marketPricingVariantLabel
    laborPricingGroupEntries {
      ...pricingGroupLaborEntry
    }
    storagePricingGroupEntries {
      ...pricingGroupPricingEntry
    }
    protectionPricingGroupEntries {
      ...pricingGroupPricingEntry
    }
  }
  ${PricingGroupLaborEntryFragmentDoc}
  ${PricingGroupPricingEntryFragmentDoc}
`;
export const RateAdjustment__DraftFragmentDoc = gql`
  fragment RateAdjustment__draft on RateAdjustment__Draft {
    accountID
    rentalID
    newPrice
    effectiveMonthYear
    excludeFromEmails
    bestOffer
    label
  }
`;
export const SelfStorage__KindFragmentDoc = gql`
  fragment SelfStorage__kind on SelfStorage__Kind {
    id
    name
    subtype
  }
`;
export const SelfStorage__ClassificationFragmentDoc = gql`
  fragment SelfStorage__classification on SelfStorage__Classification {
    id
    kind {
      ...SelfStorage__kind
    }
    length
    width
    height
    indoors
    climate
    electricity
    firstFloor
    obstructed
    category
  }
  ${SelfStorage__KindFragmentDoc}
`;
export const RateAdjustment__SchedulingFragmentDoc = gql`
  fragment RateAdjustment__scheduling on RateAdjustment__Scheduling {
    id
    state
    createdAt
    effectiveAt
    oldPrice
    newPrice
    percentageWithSign
    label
    account {
      id
      customer {
        ...customer
      }
    }
    selfStorageRental {
      id
      unit {
        id
        name
        classification {
          ...SelfStorage__classification
        }
        facility {
          id
          name
        }
      }
    }
  }
  ${CustomerFragmentDoc}
  ${SelfStorage__ClassificationFragmentDoc}
`;
export const RegionFragmentDoc = gql`
  fragment region on Region {
    id
    active
    name
    tz
    hexColor
    createdAt
    updatedAt
    parent {
      id
      name
    }
    zipCodes
    storageExtendedServiceAreaPostalCodes
    movingExtendedServiceAreaPostalCodes
    center {
      latitude
      longitude
    }
    operatingScheduleTemplates {
      id
      effectiveDate
      operatesSunday
      operatesMonday
      operatesTuesday
      operatesWednesday
      operatesThursday
      operatesFriday
      operatesSaturday
      orderType
      orderSubtype
    }
    defaultDepot {
      id
    }
    defaultWarehouse {
      id
      name
    }
    irmCluster
    irmTerritory
    geo
    netsuiteRegionID
    fulfiller
  }
`;
export const BarcodeFragmentDoc = gql`
  fragment barcode on Barcode {
    value
  }
`;
export const ImageFragmentDoc = gql`
  fragment image on Image {
    id
    source
    state
  }
`;
export const ItemFragmentDoc = gql`
  fragment item on Item {
    id
    uuid
    hero
    name
    contents
    selectableForReturn
    barcode {
      ...barcode
    }
    images {
      ...image
    }
  }
  ${BarcodeFragmentDoc}
  ${ImageFragmentDoc}
`;
export const ReturnInventoryFragmentDoc = gql`
  fragment returnInventory on OrderService {
    items {
      ...item
    }
  }
  ${ItemFragmentDoc}
`;
export const RoomCategoryFragmentDoc = gql`
  fragment roomCategory on RoomCategory {
    id
    name
    countable
    cuft
  }
`;
export const SelfStorage__AccountForRentingFragmentDoc = gql`
  fragment SelfStorage__AccountForRenting on Account {
    id
    number
    customer {
      id
      name
      cellPhone
      homePhone
      workPhone
    }
    addresses {
      id
      street
      aptsuite
      city
      state
      zip
      kinds
    }
    sources {
      id
      brand
      kind
      number
      expiration
      default
      billable
    }
    policy: latestSelfStoragePolicy {
      id
      kind
      number
      company
      amount
      from
      till
    }
    identification: latestSelfStorageIdentification {
      id
      number
      state
      birthday
    }
    subscriptions: currentSelfStorageSubscriptions {
      id
      billing
      billsOn
      source: defaultSource {
        id
      }
      rentals {
        id
        status
      }
    }
  }
`;
export const SelfStorage__ClassificationDraftFragmentDoc = gql`
  fragment SelfStorage__classificationDraft on SelfStorage__Classification {
    kind {
      ...SelfStorage__kind
    }
    length
    width
    height
    indoors
    climate
    electricity
    firstFloor
    obstructed
  }
  ${SelfStorage__KindFragmentDoc}
`;
export const SelfStorage__FacilityFragmentDoc = gql`
  fragment SelfStorage__facility on SelfStorage__Facility {
    id
    active
    name
    address {
      id
      street
      aptsuite
      city
      state
      zip
      latitude
      longitude
    }
    region {
      id
      name
    }
  }
`;
export const SelfStorage__RentalFragmentDoc = gql`
  fragment SelfStorage__rental on SelfStorage__Rental {
    id
    status
    subscription {
      id
      billing
      activatedAt
      canceledAt
      billsOn
      defaultSource {
        id
        brand
        default
        fundingType
        number
      }
      account {
        id
        customer {
          id
          name
          email
          phone
        }
      }
    }
    unit {
      id
      floor
      name
      facility {
        id
        name
      }
      classification {
        ...SelfStorage__classification
      }
    }
    overlocked
    rate
    activatedAt
    canceledAt
  }
  ${SelfStorage__ClassificationFragmentDoc}
`;
export const SelfStorage__FeeFragmentDoc = gql`
  fragment SelfStorage__fee on SelfStorage__Fee {
    id
    facilityID
    kind
    price
    recurring
    waivable
  }
`;
export const SelfStorage__PromotionFragmentDoc = gql`
  fragment SelfStorage__promotion on SelfStorage__Promotion {
    id
    kind
    value
    term
    delay
    name
  }
`;
export const SelfStorage__ProtectionFragmentDoc = gql`
  fragment SelfStorage__protection on SelfStorage__Protection {
    id
    name
    price
  }
`;
export const SelfStorage__RentalWithBillingFragmentDoc = gql`
  fragment SelfStorage__rentalWithBilling on SelfStorage__Rental {
    ...SelfStorage__rental
    unitRate
    fees {
      ...SelfStorage__fee
    }
    promotion {
      ...SelfStorage__promotion
    }
    protection {
      ...SelfStorage__protection
    }
  }
  ${SelfStorage__RentalFragmentDoc}
  ${SelfStorage__FeeFragmentDoc}
  ${SelfStorage__PromotionFragmentDoc}
  ${SelfStorage__ProtectionFragmentDoc}
`;
export const AddressFragmentDoc = gql`
  fragment address on Address {
    id
    street
    aptsuite
    city
    state
    zip
    latitude
    longitude
    serviceable
    businessName
    safeAndSanitary
  }
`;
export const SelfStorage__PriceFragmentDoc = gql`
  fragment SelfStorage__price on SelfStorage__Price {
    id
    rate
    pushRate
    quotedRate
  }
`;
export const SelfStorage__ReservationFragmentDoc = gql`
  fragment SelfStorage__reservation on SelfStorage__Reservation {
    id
    date
    name
    email
    phone
    state
    length
    width
    unitType
    quotedPrice
    tags
    confirmationAttemptDate
    externalConfirmationNumber
    smsSubscribed
    classification {
      ...SelfStorage__classification
    }
    facility {
      id
      name
      bookingLink
      phoneNumber
      address {
        ...address
      }
      partner {
        id
        name
        isThirdParty
      }
    }
    lead {
      id
      name
      accountID
    }
    user {
      id
      name
    }
    reserver {
      id
      name
    }
    price {
      ...SelfStorage__price
    }
    promotion {
      ...SelfStorage__promotion
    }
    reservedAt
    createdAt
    updatedAt
  }
  ${SelfStorage__ClassificationFragmentDoc}
  ${AddressFragmentDoc}
  ${SelfStorage__PriceFragmentDoc}
  ${SelfStorage__PromotionFragmentDoc}
`;
export const SelfStorage__DocumentFragmentDoc = gql`
  fragment SelfStorage__document on SelfStorage__Document {
    id
    name
  }
`;
export const SelfStorage__SignatureFragmentDoc = gql`
  fragment SelfStorage__signature on SelfStorage__Signature {
    id
    document {
      ...SelfStorage__document
    }
    contract {
      id
      url
    }
  }
  ${SelfStorage__DocumentFragmentDoc}
`;
export const Billing__SourceFragmentDoc = gql`
  fragment billing__source on Billing__Source {
    id
    stripeID
    kind
    fundingType
    number
    brand
    name
    expiration
    expired
    default
    active
    billable
    cvcCheck
    zipCheck
  }
`;
export const SelfStorage__SubscriptionFragmentDoc = gql`
  fragment SelfStorage__subscription on SelfStorage__Subscription {
    id
    billing
    activatedAt
    billsOn
    canceledAt
    account {
      id
    }
    rentals {
      id
      name
    }
    defaultSource {
      ...billing__source
    }
  }
  ${Billing__SourceFragmentDoc}
`;
export const SelfStorage__SubscriptionItemFragmentDoc = gql`
  fragment SelfStorage__subscriptionItem on SelfStorage__SubscriptionItem {
    id
    rate
    status
    summary
    activatedAt
    canceledAt
  }
`;
export const SelfStorage__UnitFragmentDoc = gql`
  fragment SelfStorage__unit on SelfStorage__Unit {
    id
    facility {
      id
      name
      partner {
        isThirdParty
      }
    }
    classification {
      ...SelfStorage__classification
    }
    price {
      ...SelfStorage__price
    }
    promotion {
      ...SelfStorage__promotion
    }
    rentable
    status
    name
    floor
    createdAt
    updatedAt
    designations
  }
  ${SelfStorage__ClassificationFragmentDoc}
  ${SelfStorage__PriceFragmentDoc}
  ${SelfStorage__PromotionFragmentDoc}
`;
export const SelfStorage__UnitGroupFragmentDoc = gql`
  fragment SelfStorage__unitGroup on SelfStorage__UnitGroup {
    classification {
      ...SelfStorage__classification
    }
    price {
      ...SelfStorage__price
    }
    promotion {
      ...SelfStorage__promotion
    }
    remaining
  }
  ${SelfStorage__ClassificationFragmentDoc}
  ${SelfStorage__PriceFragmentDoc}
  ${SelfStorage__PromotionFragmentDoc}
`;
export const SettingsFragmentDoc = gql`
  fragment settings on Settings {
    internal
    isBusiness
    isThirdPartyLogistics
    skuTeachInOptOut
    emailReceipts
    dicentralAccountID
    skipIncomingIvr
  }
`;
export const SignatureFragmentDoc = gql`
  fragment signature on Signature {
    id
    documentKind
    contractUrl
    skipped
    reason
    explanation
    timestamp
    state
    agreement {
      ...attachment
    }
  }
  ${AttachmentFragmentDoc}
`;
export const TaskOrder__TaskFragmentDoc = gql`
  fragment taskOrder__task on TaskOrder__Task {
    id
    instructions
    sequenceNumber
    address {
      id
      street
      aptsuite
      city
      state
      zip
      latitude
      longitude
    }
    contact {
      id
      name
      phone
    }
  }
`;
export const TermCommitmentFragmentDoc = gql`
  fragment termCommitment on Account__TermCommitment {
    term
    contractEndDate
    type
    fulfilled
  }
`;
export const TicketGroupFragmentDoc = gql`
  fragment ticketGroup on Ticket__Group {
    id
    name
    users {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const TicketFragmentDoc = gql`
  fragment ticket on Ticket {
    id
    group {
      name
    }
    assignee {
      ...user
    }
    category
    subCategory
    description
    state
    dueDate
    updatedAt
    resolutionType
    read
    owner {
      ...user
    }
    latestNote {
      body
    }
    ticketableID
    ticketableType
    ticketableName
  }
  ${UserFragmentDoc}
`;
export const TicketMessageFragmentDoc = gql`
  fragment ticketMessage on Ticket__Message {
    id
    body
    createdAt
    deletedAt
    readAt
    updatedAt
    files {
      id
      filename
      url
    }
    ticket {
      ...ticket
    }
    user {
      ...user
    }
  }
  ${TicketFragmentDoc}
  ${UserFragmentDoc}
`;
export const VehicleFragmentDoc = gql`
  fragment vehicle on Vehicle {
    id
    name
  }
`;
export const WarehouseFragmentDoc = gql`
  fragment warehouse on Warehouse {
    id
    name
    abbreviation
    tz
    active
    height
    thirdPartyLogistics
    inboundDockItemsPerHour
    outboundDockItemsPerHour
    address {
      street
      aptsuite
      city
      state
      country
      zip
      latitude
      longitude
    }
    regions {
      id
    }
    customerAccessInstructions
    customerAccessHours {
      day
      open
      close
    }
    operatingScheduleTemplates {
      id
      effectiveDate
      operatesSunday
      operatesMonday
      operatesTuesday
      operatesWednesday
      operatesThursday
      operatesFriday
      operatesSaturday
    }
    storageTreasuresFacilityID
    storageTreasuresLink
  }
`;
export const Workforce__ClockinFragmentDoc = gql`
  fragment Workforce__clockin on Workforce__Clockin {
    id
    timestamp
    distance
    latitude
    longitude
    facility {
      id
      name
    }
  }
`;
export const Workforce__FacilityFragmentDoc = gql`
  fragment Workforce__facility on Workforce__Facility {
    id
    name
  }
`;
export const Workforce__ShiftFragmentDoc = gql`
  fragment Workforce__shift on Workforce__Shift {
    id
    date
    duration
    notes
    status
    approvedAt
    overriddenAt
    disputedAt
    deletedAt
    createdAt
    updatedAt
    user {
      ...user
      tz
    }
  }
  ${UserFragmentDoc}
`;
export const Workforce__JobCodeFragmentDoc = gql`
  fragment Workforce__jobCode on Workforce__JobCode {
    id
    name
  }
`;
export const Workforce__Shift__ActivityFragmentDoc = gql`
  fragment Workforce__Shift__activity on Workforce__Shift__Activity {
    id
    durationMinutes
    position
    jobCode {
      ...Workforce__jobCode
    }
  }
  ${Workforce__JobCodeFragmentDoc}
`;
export const Workforce__ShiftAssignmentFragmentDoc = gql`
  fragment Workforce__shiftAssignment on Workforce__ShiftAssignment {
    id
    jobCode {
      ...Workforce__jobCode
    }
  }
  ${Workforce__JobCodeFragmentDoc}
`;
export const Workforce__Work_BreakFragmentDoc = gql`
  fragment Workforce__work_break on Workforce__WorkBreak {
    id
    uuid
    started
    ended
  }
`;
export const ZendeskTicketAssociationFragmentDoc = gql`
  fragment zendeskTicketAssociation on ZendeskTicketAssociation {
    id
    zdTicketID
    creator {
      ...user
    }
    createdAt
  }
  ${UserFragmentDoc}
`;
export const AccountPageButtonsDocument = gql`
  query AccountPageButtons($accountID: ID!) {
    account(accountID: $accountID) {
      id
      onboardable
      signInCount
      state
      permissions: policy {
        bookWarehouseReturn
        bookMaintenancePickup
        bookOrders
        overrideBookOrders
      }
    }
  }
`;

/**
 * __useAccountPageButtonsQuery__
 *
 * To run a query within a React component, call `useAccountPageButtonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPageButtonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPageButtonsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountPageButtonsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountPageButtonsQuery, AccountPageButtonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountPageButtonsQuery, AccountPageButtonsQueryVariables>(
    AccountPageButtonsDocument,
    options,
  );
}
export function useAccountPageButtonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountPageButtonsQuery, AccountPageButtonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountPageButtonsQuery, AccountPageButtonsQueryVariables>(
    AccountPageButtonsDocument,
    options,
  );
}
export type AccountPageButtonsQueryHookResult = ReturnType<typeof useAccountPageButtonsQuery>;
export type AccountPageButtonsLazyQueryHookResult = ReturnType<typeof useAccountPageButtonsLazyQuery>;
export type AccountPageButtonsQueryResult = Apollo.QueryResult<
  AccountPageButtonsQuery,
  AccountPageButtonsQueryVariables
>;
export const ActiveAuctionOrderDocument = gql`
  query ActiveAuctionOrder($accountID: ID!) {
    activeAuctionOrder(accountID: $accountID) {
      id
      scheduled
    }
    account(accountID: $accountID) {
      id
      storageTreasuresAuctionsHeld {
        id
        startTime
        status
      }
    }
  }
`;

/**
 * __useActiveAuctionOrderQuery__
 *
 * To run a query within a React component, call `useActiveAuctionOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAuctionOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAuctionOrderQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useActiveAuctionOrderQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveAuctionOrderQuery, ActiveAuctionOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveAuctionOrderQuery, ActiveAuctionOrderQueryVariables>(
    ActiveAuctionOrderDocument,
    options,
  );
}
export function useActiveAuctionOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveAuctionOrderQuery, ActiveAuctionOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveAuctionOrderQuery, ActiveAuctionOrderQueryVariables>(
    ActiveAuctionOrderDocument,
    options,
  );
}
export type ActiveAuctionOrderQueryHookResult = ReturnType<typeof useActiveAuctionOrderQuery>;
export type ActiveAuctionOrderLazyQueryHookResult = ReturnType<typeof useActiveAuctionOrderLazyQuery>;
export type ActiveAuctionOrderQueryResult = Apollo.QueryResult<
  ActiveAuctionOrderQuery,
  ActiveAuctionOrderQueryVariables
>;
export const AccountAddressEditDocument = gql`
  query AccountAddressEdit($accountID: ID!, $addressID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
      address(id: $addressID) {
        id
        street
        aptsuite
        neighborhood
        city
        state
        country
        zip
        latitude
        longitude
        business_name: businessName
        details {
          building_type: buildingType
          building_subtype: buildingSubtype
          floor
          stories
          front_door_steps: frontDoorSteps
          stairs
          square_feet: squareFeet
          elevator
          paperwork
          gated
          steep
          service_elevator: serviceElevator
          service_entrance: serviceEntrance
          loading_dock: loadingDock
          code
          code_value: codeValue
          parking_street: parkingStreet
          parking_driveway: parkingDriveway
          parking_lot: parkingLot
          parking_alley: parkingAlley
          parking_permit: parkingPermit
          parking_in_front: parkingInFront
          parking_on_specific_street: parkingOnSpecificStreet
          parking_instructions: parkingInstructions
          max_truck_height: maxTruckHeight
          max_truck_height_unknown: maxTruckHeightUnknown
          access_unknown: accessUnknown
          paperwork_unknown: paperworkUnknown
          parking_unknown: parkingUnknown
          environment
          additional_notes: additionalNotes
          building_opening_hour: buildingOpeningHour
          building_closing_hour: buildingClosingHour
          building_restrictions_answer: buildingRestrictionsAnswer
          parking_latitude: parkingLatitude
          parking_longitude: parkingLongitude
          secure_location_details: secureLocationDetails
          long_carry: longCarry
          unit_size: unitSize
        }
      }
    }
  }
`;

/**
 * __useAccountAddressEditQuery__
 *
 * To run a query within a React component, call `useAccountAddressEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressEditQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      addressID: // value for 'addressID'
 *   },
 * });
 */
export function useAccountAddressEditQuery(
  baseOptions: Apollo.QueryHookOptions<AccountAddressEditQuery, AccountAddressEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAddressEditQuery, AccountAddressEditQueryVariables>(
    AccountAddressEditDocument,
    options,
  );
}
export function useAccountAddressEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressEditQuery, AccountAddressEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAddressEditQuery, AccountAddressEditQueryVariables>(
    AccountAddressEditDocument,
    options,
  );
}
export type AccountAddressEditQueryHookResult = ReturnType<typeof useAccountAddressEditQuery>;
export type AccountAddressEditLazyQueryHookResult = ReturnType<typeof useAccountAddressEditLazyQuery>;
export type AccountAddressEditQueryResult = Apollo.QueryResult<
  AccountAddressEditQuery,
  AccountAddressEditQueryVariables
>;
export const AccountAddressNewDocument = gql`
  query AccountAddressNew($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useAccountAddressNewQuery__
 *
 * To run a query within a React component, call `useAccountAddressNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressNewQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountAddressNewQuery(
  baseOptions: Apollo.QueryHookOptions<AccountAddressNewQuery, AccountAddressNewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAddressNewQuery, AccountAddressNewQueryVariables>(AccountAddressNewDocument, options);
}
export function useAccountAddressNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressNewQuery, AccountAddressNewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAddressNewQuery, AccountAddressNewQueryVariables>(
    AccountAddressNewDocument,
    options,
  );
}
export type AccountAddressNewQueryHookResult = ReturnType<typeof useAccountAddressNewQuery>;
export type AccountAddressNewLazyQueryHookResult = ReturnType<typeof useAccountAddressNewLazyQuery>;
export type AccountAddressNewQueryResult = Apollo.QueryResult<AccountAddressNewQuery, AccountAddressNewQueryVariables>;
export const AccountAddressShowDocument = gql`
  query AccountAddressShow($accountID: ID!, $addressID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
      address(id: $addressID) {
        id
        street
        aptsuite
        neighborhood
        city
        state
        country
        zip
        latitude
        longitude
        business_name: businessName
        details {
          building_type: buildingType
          building_subtype: buildingSubtype
          floor
          stories
          front_door_steps: frontDoorSteps
          stairs
          square_feet: squareFeet
          elevator
          paperwork
          gated
          steep
          service_elevator: serviceElevator
          service_entrance: serviceEntrance
          loading_dock: loadingDock
          code
          code_value: codeValue
          parking_street: parkingStreet
          parking_driveway: parkingDriveway
          parking_lot: parkingLot
          parking_alley: parkingAlley
          parking_permit: parkingPermit
          parking_in_front: parkingInFront
          parking_on_specific_street: parkingOnSpecificStreet
          parking_instructions: parkingInstructions
          max_truck_height: maxTruckHeight
          max_truck_height_unknown: maxTruckHeightUnknown
          access_unknown: accessUnknown
          paperwork_unknown: paperworkUnknown
          parking_unknown: parkingUnknown
          environment
          additional_notes: additionalNotes
          building_opening_hour: buildingOpeningHour
          building_closing_hour: buildingClosingHour
          building_restrictions_answer: buildingRestrictionsAnswer
          parking_latitude: parkingLatitude
          parking_longitude: parkingLongitude
          secure_location_details: secureLocationDetails
          long_carry: longCarry
          unit_size: unitSize
        }
        orders {
          id
          number
          scheduled
          state
          type
          subtype
          maintenance
          region {
            id
            tz
          }
        }
      }
    }
  }
`;

/**
 * __useAccountAddressShowQuery__
 *
 * To run a query within a React component, call `useAccountAddressShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressShowQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      addressID: // value for 'addressID'
 *   },
 * });
 */
export function useAccountAddressShowQuery(
  baseOptions: Apollo.QueryHookOptions<AccountAddressShowQuery, AccountAddressShowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAddressShowQuery, AccountAddressShowQueryVariables>(
    AccountAddressShowDocument,
    options,
  );
}
export function useAccountAddressShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressShowQuery, AccountAddressShowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAddressShowQuery, AccountAddressShowQueryVariables>(
    AccountAddressShowDocument,
    options,
  );
}
export type AccountAddressShowQueryHookResult = ReturnType<typeof useAccountAddressShowQuery>;
export type AccountAddressShowLazyQueryHookResult = ReturnType<typeof useAccountAddressShowLazyQuery>;
export type AccountAddressShowQueryResult = Apollo.QueryResult<
  AccountAddressShowQuery,
  AccountAddressShowQueryVariables
>;
export const AccountAddressUpsertDocument = gql`
  mutation AccountAddressUpsert($accountID: ID!, $input: AddressInput!) {
    accountAddressUpsert(accountID: $accountID, input: $input) {
      status
      error
      address {
        ...address
      }
    }
  }
  ${AddressFragmentDoc}
`;
export type AccountAddressUpsertMutationFn = Apollo.MutationFunction<
  AccountAddressUpsertMutation,
  AccountAddressUpsertMutationVariables
>;

/**
 * __useAccountAddressUpsertMutation__
 *
 * To run a mutation, you first call `useAccountAddressUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAddressUpsertMutation, { data, loading, error }] = useAccountAddressUpsertMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountAddressUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<AccountAddressUpsertMutation, AccountAddressUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountAddressUpsertMutation, AccountAddressUpsertMutationVariables>(
    AccountAddressUpsertDocument,
    options,
  );
}
export type AccountAddressUpsertMutationHookResult = ReturnType<typeof useAccountAddressUpsertMutation>;
export type AccountAddressUpsertMutationResult = Apollo.MutationResult<AccountAddressUpsertMutation>;
export type AccountAddressUpsertMutationOptions = Apollo.BaseMutationOptions<
  AccountAddressUpsertMutation,
  AccountAddressUpsertMutationVariables
>;
export const AccountBanDocument = gql`
  query AccountBan($accountID: ID!) {
    account(accountID: $accountID) {
      id
      bannedFromBooking
      banReason
    }
  }
`;

/**
 * __useAccountBanQuery__
 *
 * To run a query within a React component, call `useAccountBanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBanQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountBanQuery(baseOptions: Apollo.QueryHookOptions<AccountBanQuery, AccountBanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountBanQuery, AccountBanQueryVariables>(AccountBanDocument, options);
}
export function useAccountBanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountBanQuery, AccountBanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountBanQuery, AccountBanQueryVariables>(AccountBanDocument, options);
}
export type AccountBanQueryHookResult = ReturnType<typeof useAccountBanQuery>;
export type AccountBanLazyQueryHookResult = ReturnType<typeof useAccountBanLazyQuery>;
export type AccountBanQueryResult = Apollo.QueryResult<AccountBanQuery, AccountBanQueryVariables>;
export const AccountBanUpdateDocument = gql`
  mutation AccountBanUpdate($accountID: ID!, $input: Account__BanUpdateInput!) {
    ban: accountBanUpdate(accountID: $accountID, input: $input) {
      status
      error
      account {
        id
        bannedFromBooking
        banReason
      }
    }
  }
`;
export type AccountBanUpdateMutationFn = Apollo.MutationFunction<
  AccountBanUpdateMutation,
  AccountBanUpdateMutationVariables
>;

/**
 * __useAccountBanUpdateMutation__
 *
 * To run a mutation, you first call `useAccountBanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountBanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountBanUpdateMutation, { data, loading, error }] = useAccountBanUpdateMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountBanUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<AccountBanUpdateMutation, AccountBanUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountBanUpdateMutation, AccountBanUpdateMutationVariables>(
    AccountBanUpdateDocument,
    options,
  );
}
export type AccountBanUpdateMutationHookResult = ReturnType<typeof useAccountBanUpdateMutation>;
export type AccountBanUpdateMutationResult = Apollo.MutationResult<AccountBanUpdateMutation>;
export type AccountBanUpdateMutationOptions = Apollo.BaseMutationOptions<
  AccountBanUpdateMutation,
  AccountBanUpdateMutationVariables
>;
export const CancelAccountDocument = gql`
  mutation CancelAccount($accountID: ID!) {
    cancel: cancelAccount(accountID: $accountID) {
      status
      error
    }
  }
`;
export type CancelAccountMutationFn = Apollo.MutationFunction<CancelAccountMutation, CancelAccountMutationVariables>;

/**
 * __useCancelAccountMutation__
 *
 * To run a mutation, you first call `useCancelAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAccountMutation, { data, loading, error }] = useCancelAccountMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useCancelAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelAccountMutation, CancelAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAccountMutation, CancelAccountMutationVariables>(CancelAccountDocument, options);
}
export type CancelAccountMutationHookResult = ReturnType<typeof useCancelAccountMutation>;
export type CancelAccountMutationResult = Apollo.MutationResult<CancelAccountMutation>;
export type CancelAccountMutationOptions = Apollo.BaseMutationOptions<
  CancelAccountMutation,
  CancelAccountMutationVariables
>;
export const LaborSummaryDocument = gql`
  query LaborSummary($accountID: ID!) {
    account(accountID: $accountID) {
      id
      laborSummary {
        availableSubsequentAccountLaborCreditsCount
        subsequentAccountLaborCreditsCount
        hasOnboardingLaborCredit
        hasFinalLaborCredit
        laborBundleCustomer
        laborRate {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useLaborSummaryQuery__
 *
 * To run a query within a React component, call `useLaborSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborSummaryQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useLaborSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<LaborSummaryQuery, LaborSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LaborSummaryQuery, LaborSummaryQueryVariables>(LaborSummaryDocument, options);
}
export function useLaborSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LaborSummaryQuery, LaborSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LaborSummaryQuery, LaborSummaryQueryVariables>(LaborSummaryDocument, options);
}
export type LaborSummaryQueryHookResult = ReturnType<typeof useLaborSummaryQuery>;
export type LaborSummaryLazyQueryHookResult = ReturnType<typeof useLaborSummaryLazyQuery>;
export type LaborSummaryQueryResult = Apollo.QueryResult<LaborSummaryQuery, LaborSummaryQueryVariables>;
export const AccountReferralCodeDocument = gql`
  query AccountReferralCode($accountID: ID!) {
    account(accountID: $accountID) {
      id
      referralCode: promoCode {
        id
        value
      }
    }
    currentUser {
      policy {
        canEditReferralCode
      }
    }
  }
`;

/**
 * __useAccountReferralCodeQuery__
 *
 * To run a query within a React component, call `useAccountReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountReferralCodeQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountReferralCodeQuery(
  baseOptions: Apollo.QueryHookOptions<AccountReferralCodeQuery, AccountReferralCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountReferralCodeQuery, AccountReferralCodeQueryVariables>(
    AccountReferralCodeDocument,
    options,
  );
}
export function useAccountReferralCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountReferralCodeQuery, AccountReferralCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountReferralCodeQuery, AccountReferralCodeQueryVariables>(
    AccountReferralCodeDocument,
    options,
  );
}
export type AccountReferralCodeQueryHookResult = ReturnType<typeof useAccountReferralCodeQuery>;
export type AccountReferralCodeLazyQueryHookResult = ReturnType<typeof useAccountReferralCodeLazyQuery>;
export type AccountReferralCodeQueryResult = Apollo.QueryResult<
  AccountReferralCodeQuery,
  AccountReferralCodeQueryVariables
>;
export const AccountReferralCodeUpdateDocument = gql`
  mutation AccountReferralCodeUpdate($accountID: ID!, $code: String!) {
    accountReferralCodeUpdate(accountID: $accountID, code: $code) {
      status
      error {
        code
        message
      }
      referralCode: promoCode {
        id
        value
      }
    }
  }
`;
export type AccountReferralCodeUpdateMutationFn = Apollo.MutationFunction<
  AccountReferralCodeUpdateMutation,
  AccountReferralCodeUpdateMutationVariables
>;

/**
 * __useAccountReferralCodeUpdateMutation__
 *
 * To run a mutation, you first call `useAccountReferralCodeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountReferralCodeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountReferralCodeUpdateMutation, { data, loading, error }] = useAccountReferralCodeUpdateMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAccountReferralCodeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountReferralCodeUpdateMutation,
    AccountReferralCodeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountReferralCodeUpdateMutation, AccountReferralCodeUpdateMutationVariables>(
    AccountReferralCodeUpdateDocument,
    options,
  );
}
export type AccountReferralCodeUpdateMutationHookResult = ReturnType<typeof useAccountReferralCodeUpdateMutation>;
export type AccountReferralCodeUpdateMutationResult = Apollo.MutationResult<AccountReferralCodeUpdateMutation>;
export type AccountReferralCodeUpdateMutationOptions = Apollo.BaseMutationOptions<
  AccountReferralCodeUpdateMutation,
  AccountReferralCodeUpdateMutationVariables
>;
export const AccountStorageLadderDocument = gql`
  query AccountStorageLadder($accountID: ID!) {
    account(accountID: $accountID) {
      id
      subscriptions {
        ...subscription
        customDimension {
          ...customDimension
        }
      }
      storageLadderPricings {
        ...pricing
      }
    }
  }
  ${SubscriptionFragmentDoc}
  ${CustomDimensionFragmentDoc}
  ${PricingFragmentDoc}
`;

/**
 * __useAccountStorageLadderQuery__
 *
 * To run a query within a React component, call `useAccountStorageLadderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStorageLadderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStorageLadderQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountStorageLadderQuery(
  baseOptions: Apollo.QueryHookOptions<AccountStorageLadderQuery, AccountStorageLadderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountStorageLadderQuery, AccountStorageLadderQueryVariables>(
    AccountStorageLadderDocument,
    options,
  );
}
export function useAccountStorageLadderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountStorageLadderQuery, AccountStorageLadderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountStorageLadderQuery, AccountStorageLadderQueryVariables>(
    AccountStorageLadderDocument,
    options,
  );
}
export type AccountStorageLadderQueryHookResult = ReturnType<typeof useAccountStorageLadderQuery>;
export type AccountStorageLadderLazyQueryHookResult = ReturnType<typeof useAccountStorageLadderLazyQuery>;
export type AccountStorageLadderQueryResult = Apollo.QueryResult<
  AccountStorageLadderQuery,
  AccountStorageLadderQueryVariables
>;
export const SuspendAccountDocument = gql`
  mutation SuspendAccount($accountID: ID!) {
    suspend: suspendAccount(accountID: $accountID) {
      status
      error
    }
  }
`;
export type SuspendAccountMutationFn = Apollo.MutationFunction<SuspendAccountMutation, SuspendAccountMutationVariables>;

/**
 * __useSuspendAccountMutation__
 *
 * To run a mutation, you first call `useSuspendAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendAccountMutation, { data, loading, error }] = useSuspendAccountMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useSuspendAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<SuspendAccountMutation, SuspendAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuspendAccountMutation, SuspendAccountMutationVariables>(SuspendAccountDocument, options);
}
export type SuspendAccountMutationHookResult = ReturnType<typeof useSuspendAccountMutation>;
export type SuspendAccountMutationResult = Apollo.MutationResult<SuspendAccountMutation>;
export type SuspendAccountMutationOptions = Apollo.BaseMutationOptions<
  SuspendAccountMutation,
  SuspendAccountMutationVariables
>;
export const TermCommitmentsDocument = gql`
  query TermCommitments($accountID: ID!) {
    account(accountID: $accountID) {
      id
      termCommitments {
        ...termCommitment
      }
      terminationAgreement {
        contractDuration
        earlyTerminationFee
        contractEndDate
      }
    }
  }
  ${TermCommitmentFragmentDoc}
`;

/**
 * __useTermCommitmentsQuery__
 *
 * To run a query within a React component, call `useTermCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermCommitmentsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useTermCommitmentsQuery(
  baseOptions: Apollo.QueryHookOptions<TermCommitmentsQuery, TermCommitmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TermCommitmentsQuery, TermCommitmentsQueryVariables>(TermCommitmentsDocument, options);
}
export function useTermCommitmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TermCommitmentsQuery, TermCommitmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TermCommitmentsQuery, TermCommitmentsQueryVariables>(TermCommitmentsDocument, options);
}
export type TermCommitmentsQueryHookResult = ReturnType<typeof useTermCommitmentsQuery>;
export type TermCommitmentsLazyQueryHookResult = ReturnType<typeof useTermCommitmentsLazyQuery>;
export type TermCommitmentsQueryResult = Apollo.QueryResult<TermCommitmentsQuery, TermCommitmentsQueryVariables>;
export const CancelRateAdjustmentSchedulingDocument = gql`
  mutation CancelRateAdjustmentScheduling($id: ID!, $cancelReason: RateAdjustment__Scheduling__CancelReason!) {
    result: cancelRateAdjustmentScheduling(id: $id, cancelReason: $cancelReason) {
      error
      status
      rateAdjustment {
        id
        state
      }
    }
  }
`;
export type CancelRateAdjustmentSchedulingMutationFn = Apollo.MutationFunction<
  CancelRateAdjustmentSchedulingMutation,
  CancelRateAdjustmentSchedulingMutationVariables
>;

/**
 * __useCancelRateAdjustmentSchedulingMutation__
 *
 * To run a mutation, you first call `useCancelRateAdjustmentSchedulingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRateAdjustmentSchedulingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRateAdjustmentSchedulingMutation, { data, loading, error }] = useCancelRateAdjustmentSchedulingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancelReason: // value for 'cancelReason'
 *   },
 * });
 */
export function useCancelRateAdjustmentSchedulingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRateAdjustmentSchedulingMutation,
    CancelRateAdjustmentSchedulingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelRateAdjustmentSchedulingMutation, CancelRateAdjustmentSchedulingMutationVariables>(
    CancelRateAdjustmentSchedulingDocument,
    options,
  );
}
export type CancelRateAdjustmentSchedulingMutationHookResult = ReturnType<
  typeof useCancelRateAdjustmentSchedulingMutation
>;
export type CancelRateAdjustmentSchedulingMutationResult =
  Apollo.MutationResult<CancelRateAdjustmentSchedulingMutation>;
export type CancelRateAdjustmentSchedulingMutationOptions = Apollo.BaseMutationOptions<
  CancelRateAdjustmentSchedulingMutation,
  CancelRateAdjustmentSchedulingMutationVariables
>;
export const AccountRateAgreementSummaryDocument = gql`
  query AccountRateAgreementSummary($accountID: ID!) {
    account(accountID: $accountID) {
      id
      rateAdjustments {
        ...accountRateAdjustmentScheduling
      }
    }
  }
  ${AccountRateAdjustmentSchedulingFragmentDoc}
`;

/**
 * __useAccountRateAgreementSummaryQuery__
 *
 * To run a query within a React component, call `useAccountRateAgreementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountRateAgreementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountRateAgreementSummaryQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountRateAgreementSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<AccountRateAgreementSummaryQuery, AccountRateAgreementSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountRateAgreementSummaryQuery, AccountRateAgreementSummaryQueryVariables>(
    AccountRateAgreementSummaryDocument,
    options,
  );
}
export function useAccountRateAgreementSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountRateAgreementSummaryQuery,
    AccountRateAgreementSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountRateAgreementSummaryQuery, AccountRateAgreementSummaryQueryVariables>(
    AccountRateAgreementSummaryDocument,
    options,
  );
}
export type AccountRateAgreementSummaryQueryHookResult = ReturnType<typeof useAccountRateAgreementSummaryQuery>;
export type AccountRateAgreementSummaryLazyQueryHookResult = ReturnType<typeof useAccountRateAgreementSummaryLazyQuery>;
export type AccountRateAgreementSummaryQueryResult = Apollo.QueryResult<
  AccountRateAgreementSummaryQuery,
  AccountRateAgreementSummaryQueryVariables
>;
export const AccountImpersonateCustomerLinkCreateDocument = gql`
  mutation AccountImpersonateCustomerLinkCreate($customerID: ID!) {
    result: accountImpersonateCustomerLinkCreate(customerID: $customerID) {
      url
    }
  }
`;
export type AccountImpersonateCustomerLinkCreateMutationFn = Apollo.MutationFunction<
  AccountImpersonateCustomerLinkCreateMutation,
  AccountImpersonateCustomerLinkCreateMutationVariables
>;

/**
 * __useAccountImpersonateCustomerLinkCreateMutation__
 *
 * To run a mutation, you first call `useAccountImpersonateCustomerLinkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountImpersonateCustomerLinkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountImpersonateCustomerLinkCreateMutation, { data, loading, error }] = useAccountImpersonateCustomerLinkCreateMutation({
 *   variables: {
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useAccountImpersonateCustomerLinkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountImpersonateCustomerLinkCreateMutation,
    AccountImpersonateCustomerLinkCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AccountImpersonateCustomerLinkCreateMutation,
    AccountImpersonateCustomerLinkCreateMutationVariables
  >(AccountImpersonateCustomerLinkCreateDocument, options);
}
export type AccountImpersonateCustomerLinkCreateMutationHookResult = ReturnType<
  typeof useAccountImpersonateCustomerLinkCreateMutation
>;
export type AccountImpersonateCustomerLinkCreateMutationResult =
  Apollo.MutationResult<AccountImpersonateCustomerLinkCreateMutation>;
export type AccountImpersonateCustomerLinkCreateMutationOptions = Apollo.BaseMutationOptions<
  AccountImpersonateCustomerLinkCreateMutation,
  AccountImpersonateCustomerLinkCreateMutationVariables
>;
export const AddressPolicyDocument = gql`
  query AddressPolicy {
    permissions: addressPolicy {
      create
    }
  }
`;

/**
 * __useAddressPolicyQuery__
 *
 * To run a query within a React component, call `useAddressPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<AddressPolicyQuery, AddressPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressPolicyQuery, AddressPolicyQueryVariables>(AddressPolicyDocument, options);
}
export function useAddressPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressPolicyQuery, AddressPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressPolicyQuery, AddressPolicyQueryVariables>(AddressPolicyDocument, options);
}
export type AddressPolicyQueryHookResult = ReturnType<typeof useAddressPolicyQuery>;
export type AddressPolicyLazyQueryHookResult = ReturnType<typeof useAddressPolicyLazyQuery>;
export type AddressPolicyQueryResult = Apollo.QueryResult<AddressPolicyQuery, AddressPolicyQueryVariables>;
export const AccountAddressesDocument = gql`
  query AccountAddresses($accountID: ID!) {
    account(accountID: $accountID) {
      id
      addresses {
        id
        aptsuite
        neighborhood
        street
        city
        state
        zip
        businessName
        kinds
      }
    }
  }
`;

/**
 * __useAccountAddressesQuery__
 *
 * To run a query within a React component, call `useAccountAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<AccountAddressesQuery, AccountAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAddressesQuery, AccountAddressesQueryVariables>(AccountAddressesDocument, options);
}
export function useAccountAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressesQuery, AccountAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAddressesQuery, AccountAddressesQueryVariables>(AccountAddressesDocument, options);
}
export type AccountAddressesQueryHookResult = ReturnType<typeof useAccountAddressesQuery>;
export type AccountAddressesLazyQueryHookResult = ReturnType<typeof useAccountAddressesLazyQuery>;
export type AccountAddressesQueryResult = Apollo.QueryResult<AccountAddressesQuery, AccountAddressesQueryVariables>;
export const AccountAttachmentsDocument = gql`
  query AccountAttachments($accountID: ID!) {
    account(accountID: $accountID) {
      id
      ingestedDocuments {
        id
        filename
        url
      }
    }
  }
`;

/**
 * __useAccountAttachmentsQuery__
 *
 * To run a query within a React component, call `useAccountAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAttachmentsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountAttachmentsQuery, AccountAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountAttachmentsQuery, AccountAttachmentsQueryVariables>(
    AccountAttachmentsDocument,
    options,
  );
}
export function useAccountAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountAttachmentsQuery, AccountAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountAttachmentsQuery, AccountAttachmentsQueryVariables>(
    AccountAttachmentsDocument,
    options,
  );
}
export type AccountAttachmentsQueryHookResult = ReturnType<typeof useAccountAttachmentsQuery>;
export type AccountAttachmentsLazyQueryHookResult = ReturnType<typeof useAccountAttachmentsLazyQuery>;
export type AccountAttachmentsQueryResult = Apollo.QueryResult<
  AccountAttachmentsQuery,
  AccountAttachmentsQueryVariables
>;
export const ClaimsPanelDocument = gql`
  query ClaimsPanel($accountID: ID!) {
    accountClaims(accountID: $accountID) {
      ...accountClaim
    }
    account(accountID: $accountID) {
      id
      claimsSummary {
        ...Account__ClaimsSummary
      }
    }
  }
  ${AccountClaimFragmentDoc}
  ${Account__ClaimsSummaryFragmentDoc}
`;

/**
 * __useClaimsPanelQuery__
 *
 * To run a query within a React component, call `useClaimsPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsPanelQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useClaimsPanelQuery(baseOptions: Apollo.QueryHookOptions<ClaimsPanelQuery, ClaimsPanelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimsPanelQuery, ClaimsPanelQueryVariables>(ClaimsPanelDocument, options);
}
export function useClaimsPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClaimsPanelQuery, ClaimsPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimsPanelQuery, ClaimsPanelQueryVariables>(ClaimsPanelDocument, options);
}
export type ClaimsPanelQueryHookResult = ReturnType<typeof useClaimsPanelQuery>;
export type ClaimsPanelLazyQueryHookResult = ReturnType<typeof useClaimsPanelLazyQuery>;
export type ClaimsPanelQueryResult = Apollo.QueryResult<ClaimsPanelQuery, ClaimsPanelQueryVariables>;
export const ContainersAccountCreateDocument = gql`
  mutation ContainersAccountCreate($customerInput: Containers__CustomerInput!, $zip: String!) {
    containersAccountCreate(customerInput: $customerInput, zip: $zip) {
      status
      error
      id
    }
  }
`;
export type ContainersAccountCreateMutationFn = Apollo.MutationFunction<
  ContainersAccountCreateMutation,
  ContainersAccountCreateMutationVariables
>;

/**
 * __useContainersAccountCreateMutation__
 *
 * To run a mutation, you first call `useContainersAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContainersAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [containersAccountCreateMutation, { data, loading, error }] = useContainersAccountCreateMutation({
 *   variables: {
 *      customerInput: // value for 'customerInput'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useContainersAccountCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<ContainersAccountCreateMutation, ContainersAccountCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContainersAccountCreateMutation, ContainersAccountCreateMutationVariables>(
    ContainersAccountCreateDocument,
    options,
  );
}
export type ContainersAccountCreateMutationHookResult = ReturnType<typeof useContainersAccountCreateMutation>;
export type ContainersAccountCreateMutationResult = Apollo.MutationResult<ContainersAccountCreateMutation>;
export type ContainersAccountCreateMutationOptions = Apollo.BaseMutationOptions<
  ContainersAccountCreateMutation,
  ContainersAccountCreateMutationVariables
>;
export const PaginatedAccountsDocument = gql`
  query PaginatedAccounts($state: Account__State, $query: String, $page: Int) {
    paginated: paginatedAccounts(state: $state, query: $query, page: $page) {
      results {
        id
        state
        number
        customer {
          id
          name
        }
        customers {
          id
          name
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedAccountsQuery__
 *
 * To run a query within a React component, call `usePaginatedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAccountsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedAccountsQuery, PaginatedAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedAccountsQuery, PaginatedAccountsQueryVariables>(PaginatedAccountsDocument, options);
}
export function usePaginatedAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedAccountsQuery, PaginatedAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedAccountsQuery, PaginatedAccountsQueryVariables>(
    PaginatedAccountsDocument,
    options,
  );
}
export type PaginatedAccountsQueryHookResult = ReturnType<typeof usePaginatedAccountsQuery>;
export type PaginatedAccountsLazyQueryHookResult = ReturnType<typeof usePaginatedAccountsLazyQuery>;
export type PaginatedAccountsQueryResult = Apollo.QueryResult<PaginatedAccountsQuery, PaginatedAccountsQueryVariables>;
export const AccountPaymentPlanOffersDocument = gql`
  query AccountPaymentPlanOffers($accountID: ID!) {
    offers: accountPaymentPlanOffers(accountID: $accountID) {
      id
      type
      state
      createdAt
      expiry
      expired
      user {
        id
        name
      }
    }
  }
`;

/**
 * __useAccountPaymentPlanOffersQuery__
 *
 * To run a query within a React component, call `useAccountPaymentPlanOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPaymentPlanOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPaymentPlanOffersQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountPaymentPlanOffersQuery(
  baseOptions: Apollo.QueryHookOptions<AccountPaymentPlanOffersQuery, AccountPaymentPlanOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountPaymentPlanOffersQuery, AccountPaymentPlanOffersQueryVariables>(
    AccountPaymentPlanOffersDocument,
    options,
  );
}
export function useAccountPaymentPlanOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountPaymentPlanOffersQuery, AccountPaymentPlanOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountPaymentPlanOffersQuery, AccountPaymentPlanOffersQueryVariables>(
    AccountPaymentPlanOffersDocument,
    options,
  );
}
export type AccountPaymentPlanOffersQueryHookResult = ReturnType<typeof useAccountPaymentPlanOffersQuery>;
export type AccountPaymentPlanOffersLazyQueryHookResult = ReturnType<typeof useAccountPaymentPlanOffersLazyQuery>;
export type AccountPaymentPlanOffersQueryResult = Apollo.QueryResult<
  AccountPaymentPlanOffersQuery,
  AccountPaymentPlanOffersQueryVariables
>;
export const AccountWithPricingOptionsDocument = gql`
  query AccountWithPricingOptions($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      priceMatchKind
      coupon {
        id
        name
        code
      }
      currentStorageSubscription {
        id
        customDimension {
          ...customDimension
        }
        pricing {
          id
          plan {
            id
            kind
          }
        }
        total
      }
      currentProtectionSubscription {
        id
        pricing {
          id
        }
      }
      customer {
        id
        name
      }
      pricingOptions {
        id
        storagePricings {
          id
          amount
          plan {
            id
            name
            cuft
            kind
          }
        }
        protectionPricings {
          id
          amount
          plan {
            id
            name
          }
        }
      }
    }
  }
  ${CustomDimensionFragmentDoc}
`;

/**
 * __useAccountWithPricingOptionsQuery__
 *
 * To run a query within a React component, call `useAccountWithPricingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountWithPricingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountWithPricingOptionsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountWithPricingOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountWithPricingOptionsQuery, AccountWithPricingOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountWithPricingOptionsQuery, AccountWithPricingOptionsQueryVariables>(
    AccountWithPricingOptionsDocument,
    options,
  );
}
export function useAccountWithPricingOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountWithPricingOptionsQuery, AccountWithPricingOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountWithPricingOptionsQuery, AccountWithPricingOptionsQueryVariables>(
    AccountWithPricingOptionsDocument,
    options,
  );
}
export type AccountWithPricingOptionsQueryHookResult = ReturnType<typeof useAccountWithPricingOptionsQuery>;
export type AccountWithPricingOptionsLazyQueryHookResult = ReturnType<typeof useAccountWithPricingOptionsLazyQuery>;
export type AccountWithPricingOptionsQueryResult = Apollo.QueryResult<
  AccountWithPricingOptionsQuery,
  AccountWithPricingOptionsQueryVariables
>;
export const UpdateAccountPricingDocument = gql`
  mutation UpdateAccountPricing($accountID: ID!, $input: Account__UpdatePricingInput!) {
    update: updateAccountPricing(accountID: $accountID, input: $input) {
      status
      error {
        message
      }
    }
  }
`;
export type UpdateAccountPricingMutationFn = Apollo.MutationFunction<
  UpdateAccountPricingMutation,
  UpdateAccountPricingMutationVariables
>;

/**
 * __useUpdateAccountPricingMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPricingMutation, { data, loading, error }] = useUpdateAccountPricingMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountPricingMutation, UpdateAccountPricingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountPricingMutation, UpdateAccountPricingMutationVariables>(
    UpdateAccountPricingDocument,
    options,
  );
}
export type UpdateAccountPricingMutationHookResult = ReturnType<typeof useUpdateAccountPricingMutation>;
export type UpdateAccountPricingMutationResult = Apollo.MutationResult<UpdateAccountPricingMutation>;
export type UpdateAccountPricingMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountPricingMutation,
  UpdateAccountPricingMutationVariables
>;
export const ChangeAccountSettingsDocument = gql`
  mutation ChangeAccountSettings($input: AccountSettingsInput!) {
    changeAccountSettings(input: $input) {
      status
      error
    }
  }
`;
export type ChangeAccountSettingsMutationFn = Apollo.MutationFunction<
  ChangeAccountSettingsMutation,
  ChangeAccountSettingsMutationVariables
>;

/**
 * __useChangeAccountSettingsMutation__
 *
 * To run a mutation, you first call `useChangeAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountSettingsMutation, { data, loading, error }] = useChangeAccountSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAccountSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeAccountSettingsMutation, ChangeAccountSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeAccountSettingsMutation, ChangeAccountSettingsMutationVariables>(
    ChangeAccountSettingsDocument,
    options,
  );
}
export type ChangeAccountSettingsMutationHookResult = ReturnType<typeof useChangeAccountSettingsMutation>;
export type ChangeAccountSettingsMutationResult = Apollo.MutationResult<ChangeAccountSettingsMutation>;
export type ChangeAccountSettingsMutationOptions = Apollo.BaseMutationOptions<
  ChangeAccountSettingsMutation,
  ChangeAccountSettingsMutationVariables
>;
export const SettingsDocument = gql`
  query Settings($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
      settings {
        ...settings
      }
    }
  }
  ${SettingsFragmentDoc}
`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useSettingsQuery(baseOptions: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const AddressesDocument = gql`
  query Addresses($accountID: ID!) {
    addresses(accountID: $accountID) {
      id
      street
      aptsuite
      city
      state
      zip
      latitude
      longitude
      serviceable
      business_name: businessName
      safe_and_sanitary: safeAndSanitary
      schedulable
      details {
        building_type: buildingType
        building_subtype: buildingSubtype
        floor
        stories
        front_door_steps: frontDoorSteps
        stairs
        square_feet: squareFeet
        elevator
        paperwork
        gated
        steep
        service_elevator: serviceElevator
        service_entrance: serviceEntrance
        loading_dock: loadingDock
        code
        code_value: codeValue
        parking_street: parkingStreet
        parking_driveway: parkingDriveway
        parking_lot: parkingLot
        parking_alley: parkingAlley
        parking_permit: parkingPermit
        parking_in_front: parkingInFront
        parking_on_specific_street: parkingOnSpecificStreet
        parking_instructions: parkingInstructions
        max_truck_height: maxTruckHeight
        max_truck_height_unknown: maxTruckHeightUnknown
        access_unknown: accessUnknown
        paperwork_unknown: paperworkUnknown
        parking_unknown: parkingUnknown
        environment
        additional_notes: additionalNotes
        building_opening_hour: buildingOpeningHour
        building_closing_hour: buildingClosingHour
        building_restrictions_answer: buildingRestrictionsAnswer
        parking_latitude: parkingLatitude
        parking_longitude: parkingLongitude
        secure_location_details: secureLocationDetails
        long_carry: longCarry
        unit_size: unitSize
      }
    }
  }
`;

/**
 * __useAddressesQuery__
 *
 * To run a query within a React component, call `useAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAddressesQuery(baseOptions: Apollo.QueryHookOptions<AddressesQuery, AddressesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressesQuery, AddressesQueryVariables>(AddressesDocument, options);
}
export function useAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressesQuery, AddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressesQuery, AddressesQueryVariables>(AddressesDocument, options);
}
export type AddressesQueryHookResult = ReturnType<typeof useAddressesQuery>;
export type AddressesLazyQueryHookResult = ReturnType<typeof useAddressesLazyQuery>;
export type AddressesQueryResult = Apollo.QueryResult<AddressesQuery, AddressesQueryVariables>;
export const GeocodeDocument = gql`
  query Geocode($input: Geocode__Input!) {
    geocode(input: $input) {
      latitude
      longitude
      potentially_invalid: potentiallyInvalid
      region {
        id
        tz
      }
    }
  }
`;

/**
 * __useGeocodeQuery__
 *
 * To run a query within a React component, call `useGeocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeocodeQuery(baseOptions: Apollo.QueryHookOptions<GeocodeQuery, GeocodeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeocodeQuery, GeocodeQueryVariables>(GeocodeDocument, options);
}
export function useGeocodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeocodeQuery, GeocodeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeocodeQuery, GeocodeQueryVariables>(GeocodeDocument, options);
}
export type GeocodeQueryHookResult = ReturnType<typeof useGeocodeQuery>;
export type GeocodeLazyQueryHookResult = ReturnType<typeof useGeocodeLazyQuery>;
export type GeocodeQueryResult = Apollo.QueryResult<GeocodeQuery, GeocodeQueryVariables>;
export const CreateAppointmentQuoteDocument = gql`
  mutation CreateAppointmentQuote(
    $zip: String!
    $cuft: Float!
    $rateGroupId: ID!
    $quotableId: ID!
    $quotableType: Pricing__QuotableEnum!
    $source: Pricing__SourceEnum!
  ) {
    createAppointmentQuote(
      zip: $zip
      cuft: $cuft
      rateGroupId: $rateGroupId
      quotableId: $quotableId
      quotableType: $quotableType
      source: $source
    ) {
      status
      error
      quote {
        id
      }
      laborRate {
        ...laborRate
      }
      onboardingPackageSetEntries {
        ...packageSetEntry
      }
      finalPackageSetEntries {
        ...packageSetEntry
      }
    }
  }
  ${LaborRateFragmentDoc}
  ${PackageSetEntryFragmentDoc}
`;
export type CreateAppointmentQuoteMutationFn = Apollo.MutationFunction<
  CreateAppointmentQuoteMutation,
  CreateAppointmentQuoteMutationVariables
>;

/**
 * __useCreateAppointmentQuoteMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentQuoteMutation, { data, loading, error }] = useCreateAppointmentQuoteMutation({
 *   variables: {
 *      zip: // value for 'zip'
 *      cuft: // value for 'cuft'
 *      rateGroupId: // value for 'rateGroupId'
 *      quotableId: // value for 'quotableId'
 *      quotableType: // value for 'quotableType'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCreateAppointmentQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAppointmentQuoteMutation, CreateAppointmentQuoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppointmentQuoteMutation, CreateAppointmentQuoteMutationVariables>(
    CreateAppointmentQuoteDocument,
    options,
  );
}
export type CreateAppointmentQuoteMutationHookResult = ReturnType<typeof useCreateAppointmentQuoteMutation>;
export type CreateAppointmentQuoteMutationResult = Apollo.MutationResult<CreateAppointmentQuoteMutation>;
export type CreateAppointmentQuoteMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentQuoteMutation,
  CreateAppointmentQuoteMutationVariables
>;
export const PricingSetAppointmentDataDocument = gql`
  query PricingSetAppointmentData($pricingSetId: ID!, $rateGroupId: ID!, $cuft: Float!) {
    pricingSetAppointmentData(pricingSetId: $pricingSetId, rateGroupId: $rateGroupId, cuft: $cuft) {
      laborRate {
        ...laborRate
      }
      packageSetEntries {
        ...packageSetEntry
      }
    }
  }
  ${LaborRateFragmentDoc}
  ${PackageSetEntryFragmentDoc}
`;

/**
 * __usePricingSetAppointmentDataQuery__
 *
 * To run a query within a React component, call `usePricingSetAppointmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingSetAppointmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingSetAppointmentDataQuery({
 *   variables: {
 *      pricingSetId: // value for 'pricingSetId'
 *      rateGroupId: // value for 'rateGroupId'
 *      cuft: // value for 'cuft'
 *   },
 * });
 */
export function usePricingSetAppointmentDataQuery(
  baseOptions: Apollo.QueryHookOptions<PricingSetAppointmentDataQuery, PricingSetAppointmentDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingSetAppointmentDataQuery, PricingSetAppointmentDataQueryVariables>(
    PricingSetAppointmentDataDocument,
    options,
  );
}
export function usePricingSetAppointmentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingSetAppointmentDataQuery, PricingSetAppointmentDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingSetAppointmentDataQuery, PricingSetAppointmentDataQueryVariables>(
    PricingSetAppointmentDataDocument,
    options,
  );
}
export type PricingSetAppointmentDataQueryHookResult = ReturnType<typeof usePricingSetAppointmentDataQuery>;
export type PricingSetAppointmentDataLazyQueryHookResult = ReturnType<typeof usePricingSetAppointmentDataLazyQuery>;
export type PricingSetAppointmentDataQueryResult = Apollo.QueryResult<
  PricingSetAppointmentDataQuery,
  PricingSetAppointmentDataQueryVariables
>;
export const AttendancePolicyViolationCreateDocument = gql`
  mutation AttendancePolicyViolationCreate($input: AttendancePolicyViolation__Create__Input!) {
    result: attendancePolicyViolationCreate(input: $input) {
      status
      error
    }
  }
`;
export type AttendancePolicyViolationCreateMutationFn = Apollo.MutationFunction<
  AttendancePolicyViolationCreateMutation,
  AttendancePolicyViolationCreateMutationVariables
>;

/**
 * __useAttendancePolicyViolationCreateMutation__
 *
 * To run a mutation, you first call `useAttendancePolicyViolationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendancePolicyViolationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendancePolicyViolationCreateMutation, { data, loading, error }] = useAttendancePolicyViolationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendancePolicyViolationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttendancePolicyViolationCreateMutation,
    AttendancePolicyViolationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttendancePolicyViolationCreateMutation, AttendancePolicyViolationCreateMutationVariables>(
    AttendancePolicyViolationCreateDocument,
    options,
  );
}
export type AttendancePolicyViolationCreateMutationHookResult = ReturnType<
  typeof useAttendancePolicyViolationCreateMutation
>;
export type AttendancePolicyViolationCreateMutationResult =
  Apollo.MutationResult<AttendancePolicyViolationCreateMutation>;
export type AttendancePolicyViolationCreateMutationOptions = Apollo.BaseMutationOptions<
  AttendancePolicyViolationCreateMutation,
  AttendancePolicyViolationCreateMutationVariables
>;
export const AttendancePolicyViolationsDocument = gql`
  query AttendancePolicyViolations(
    $from: Date
    $teamID: ID
    $teamReviewMode: Boolean
    $till: Date
    $timeClockPlusUsersOnly: Boolean
    $userName: String
  ) {
    attendancePolicyViolations(
      from: $from
      teamID: $teamID
      teamReviewMode: $teamReviewMode
      till: $till
      timeClockPlusUsersOnly: $timeClockPlusUsersOnly
      userName: $userName
    ) {
      id
      actualTime
      confirmedNoCall
      expectedTime
      kind
      note
      reviewedAt
      reviewer {
        id
        name
      }
      shiftDate
      user {
        id
        name
      }
      waived
    }
    users {
      id
      name
    }
  }
`;

/**
 * __useAttendancePolicyViolationsQuery__
 *
 * To run a query within a React component, call `useAttendancePolicyViolationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendancePolicyViolationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendancePolicyViolationsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      teamID: // value for 'teamID'
 *      teamReviewMode: // value for 'teamReviewMode'
 *      till: // value for 'till'
 *      timeClockPlusUsersOnly: // value for 'timeClockPlusUsersOnly'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useAttendancePolicyViolationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AttendancePolicyViolationsQuery, AttendancePolicyViolationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendancePolicyViolationsQuery, AttendancePolicyViolationsQueryVariables>(
    AttendancePolicyViolationsDocument,
    options,
  );
}
export function useAttendancePolicyViolationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendancePolicyViolationsQuery, AttendancePolicyViolationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendancePolicyViolationsQuery, AttendancePolicyViolationsQueryVariables>(
    AttendancePolicyViolationsDocument,
    options,
  );
}
export type AttendancePolicyViolationsQueryHookResult = ReturnType<typeof useAttendancePolicyViolationsQuery>;
export type AttendancePolicyViolationsLazyQueryHookResult = ReturnType<typeof useAttendancePolicyViolationsLazyQuery>;
export type AttendancePolicyViolationsQueryResult = Apollo.QueryResult<
  AttendancePolicyViolationsQuery,
  AttendancePolicyViolationsQueryVariables
>;
export const AttendancePolicyViolationReviewDocument = gql`
  mutation AttendancePolicyViolationReview($input: AttendancePolicyViolation__Review__Input!) {
    result: attendancePolicyViolationReview(input: $input) {
      status
      error
    }
  }
`;
export type AttendancePolicyViolationReviewMutationFn = Apollo.MutationFunction<
  AttendancePolicyViolationReviewMutation,
  AttendancePolicyViolationReviewMutationVariables
>;

/**
 * __useAttendancePolicyViolationReviewMutation__
 *
 * To run a mutation, you first call `useAttendancePolicyViolationReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendancePolicyViolationReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendancePolicyViolationReviewMutation, { data, loading, error }] = useAttendancePolicyViolationReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendancePolicyViolationReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttendancePolicyViolationReviewMutation,
    AttendancePolicyViolationReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttendancePolicyViolationReviewMutation, AttendancePolicyViolationReviewMutationVariables>(
    AttendancePolicyViolationReviewDocument,
    options,
  );
}
export type AttendancePolicyViolationReviewMutationHookResult = ReturnType<
  typeof useAttendancePolicyViolationReviewMutation
>;
export type AttendancePolicyViolationReviewMutationResult =
  Apollo.MutationResult<AttendancePolicyViolationReviewMutation>;
export type AttendancePolicyViolationReviewMutationOptions = Apollo.BaseMutationOptions<
  AttendancePolicyViolationReviewMutation,
  AttendancePolicyViolationReviewMutationVariables
>;
export const AuctionUploadDocument = gql`
  query AuctionUpload($id: ID!) {
    upload: auctionUpload(id: $id) {
      ...Auction__upload
    }
  }
  ${Auction__UploadFragmentDoc}
`;

/**
 * __useAuctionUploadQuery__
 *
 * To run a query within a React component, call `useAuctionUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuctionUploadQuery(
  baseOptions: Apollo.QueryHookOptions<AuctionUploadQuery, AuctionUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionUploadQuery, AuctionUploadQueryVariables>(AuctionUploadDocument, options);
}
export function useAuctionUploadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionUploadQuery, AuctionUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionUploadQuery, AuctionUploadQueryVariables>(AuctionUploadDocument, options);
}
export type AuctionUploadQueryHookResult = ReturnType<typeof useAuctionUploadQuery>;
export type AuctionUploadLazyQueryHookResult = ReturnType<typeof useAuctionUploadLazyQuery>;
export type AuctionUploadQueryResult = Apollo.QueryResult<AuctionUploadQuery, AuctionUploadQueryVariables>;
export const AuctionUploadCreateDocument = gql`
  mutation AuctionUploadCreate($input: Auction__UploadInput!) {
    create: auctionUploadCreate(input: $input) {
      status
      upload {
        ...Auction__upload
      }
    }
  }
  ${Auction__UploadFragmentDoc}
`;
export type AuctionUploadCreateMutationFn = Apollo.MutationFunction<
  AuctionUploadCreateMutation,
  AuctionUploadCreateMutationVariables
>;

/**
 * __useAuctionUploadCreateMutation__
 *
 * To run a mutation, you first call `useAuctionUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuctionUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auctionUploadCreateMutation, { data, loading, error }] = useAuctionUploadCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuctionUploadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<AuctionUploadCreateMutation, AuctionUploadCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuctionUploadCreateMutation, AuctionUploadCreateMutationVariables>(
    AuctionUploadCreateDocument,
    options,
  );
}
export type AuctionUploadCreateMutationHookResult = ReturnType<typeof useAuctionUploadCreateMutation>;
export type AuctionUploadCreateMutationResult = Apollo.MutationResult<AuctionUploadCreateMutation>;
export type AuctionUploadCreateMutationOptions = Apollo.BaseMutationOptions<
  AuctionUploadCreateMutation,
  AuctionUploadCreateMutationVariables
>;
export const AuctionUploadListDocument = gql`
  query AuctionUploadList($page: Int) {
    paginated: auctionPaginatedUploads(page: $page) {
      results {
        ...Auction__upload
      }
      pagination {
        ...pagination
      }
    }
    permission: auctionUploadPolicy {
      view
      create
    }
  }
  ${Auction__UploadFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAuctionUploadListQuery__
 *
 * To run a query within a React component, call `useAuctionUploadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionUploadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionUploadListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAuctionUploadListQuery(
  baseOptions?: Apollo.QueryHookOptions<AuctionUploadListQuery, AuctionUploadListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionUploadListQuery, AuctionUploadListQueryVariables>(AuctionUploadListDocument, options);
}
export function useAuctionUploadListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionUploadListQuery, AuctionUploadListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionUploadListQuery, AuctionUploadListQueryVariables>(
    AuctionUploadListDocument,
    options,
  );
}
export type AuctionUploadListQueryHookResult = ReturnType<typeof useAuctionUploadListQuery>;
export type AuctionUploadListLazyQueryHookResult = ReturnType<typeof useAuctionUploadListLazyQuery>;
export type AuctionUploadListQueryResult = Apollo.QueryResult<AuctionUploadListQuery, AuctionUploadListQueryVariables>;
export const AuctionUploadPolicyDocument = gql`
  query AuctionUploadPolicy {
    permission: auctionUploadPolicy {
      view
      create
    }
  }
`;

/**
 * __useAuctionUploadPolicyQuery__
 *
 * To run a query within a React component, call `useAuctionUploadPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionUploadPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionUploadPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuctionUploadPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<AuctionUploadPolicyQuery, AuctionUploadPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionUploadPolicyQuery, AuctionUploadPolicyQueryVariables>(
    AuctionUploadPolicyDocument,
    options,
  );
}
export function useAuctionUploadPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionUploadPolicyQuery, AuctionUploadPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionUploadPolicyQuery, AuctionUploadPolicyQueryVariables>(
    AuctionUploadPolicyDocument,
    options,
  );
}
export type AuctionUploadPolicyQueryHookResult = ReturnType<typeof useAuctionUploadPolicyQuery>;
export type AuctionUploadPolicyLazyQueryHookResult = ReturnType<typeof useAuctionUploadPolicyLazyQuery>;
export type AuctionUploadPolicyQueryResult = Apollo.QueryResult<
  AuctionUploadPolicyQuery,
  AuctionUploadPolicyQueryVariables
>;
export const AvailabilityLaborAdjustmentsCreateDocument = gql`
  mutation AvailabilityLaborAdjustmentsCreate($input: Availability__LaborAdjustmentsCreateInput!) {
    result: availabilityLaborAdjustmentsCreate(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityLaborAdjustmentsCreateMutationFn = Apollo.MutationFunction<
  AvailabilityLaborAdjustmentsCreateMutation,
  AvailabilityLaborAdjustmentsCreateMutationVariables
>;

/**
 * __useAvailabilityLaborAdjustmentsCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityLaborAdjustmentsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityLaborAdjustmentsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityLaborAdjustmentsCreateMutation, { data, loading, error }] = useAvailabilityLaborAdjustmentsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityLaborAdjustmentsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityLaborAdjustmentsCreateMutation,
    AvailabilityLaborAdjustmentsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityLaborAdjustmentsCreateMutation,
    AvailabilityLaborAdjustmentsCreateMutationVariables
  >(AvailabilityLaborAdjustmentsCreateDocument, options);
}
export type AvailabilityLaborAdjustmentsCreateMutationHookResult = ReturnType<
  typeof useAvailabilityLaborAdjustmentsCreateMutation
>;
export type AvailabilityLaborAdjustmentsCreateMutationResult =
  Apollo.MutationResult<AvailabilityLaborAdjustmentsCreateMutation>;
export type AvailabilityLaborAdjustmentsCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityLaborAdjustmentsCreateMutation,
  AvailabilityLaborAdjustmentsCreateMutationVariables
>;
export const AvailabilityLaborAdjustmentsDeleteDocument = gql`
  mutation AvailabilityLaborAdjustmentsDelete($IDs: [ID!]!) {
    result: availabilityLaborAdjustmentsDelete(IDs: $IDs) {
      status
      error
    }
  }
`;
export type AvailabilityLaborAdjustmentsDeleteMutationFn = Apollo.MutationFunction<
  AvailabilityLaborAdjustmentsDeleteMutation,
  AvailabilityLaborAdjustmentsDeleteMutationVariables
>;

/**
 * __useAvailabilityLaborAdjustmentsDeleteMutation__
 *
 * To run a mutation, you first call `useAvailabilityLaborAdjustmentsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityLaborAdjustmentsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityLaborAdjustmentsDeleteMutation, { data, loading, error }] = useAvailabilityLaborAdjustmentsDeleteMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useAvailabilityLaborAdjustmentsDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityLaborAdjustmentsDeleteMutation,
    AvailabilityLaborAdjustmentsDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityLaborAdjustmentsDeleteMutation,
    AvailabilityLaborAdjustmentsDeleteMutationVariables
  >(AvailabilityLaborAdjustmentsDeleteDocument, options);
}
export type AvailabilityLaborAdjustmentsDeleteMutationHookResult = ReturnType<
  typeof useAvailabilityLaborAdjustmentsDeleteMutation
>;
export type AvailabilityLaborAdjustmentsDeleteMutationResult =
  Apollo.MutationResult<AvailabilityLaborAdjustmentsDeleteMutation>;
export type AvailabilityLaborAdjustmentsDeleteMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityLaborAdjustmentsDeleteMutation,
  AvailabilityLaborAdjustmentsDeleteMutationVariables
>;
export const AvailabilityLaborAdjustmentsDocument = gql`
  query AvailabilityLaborAdjustments($filters: Availability__LaborAdjustmentFiltersInput!, $page: Int) {
    paginated: availabilityLaborAdjustments(filters: $filters, page: $page) {
      results {
        ...Availability__laborAdjustment
      }
      pagination {
        ...pagination
      }
    }
    policy: availabilityLaborAdjustmentsPolicy {
      create
      destroy
    }
  }
  ${Availability__LaborAdjustmentFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAvailabilityLaborAdjustmentsQuery__
 *
 * To run a query within a React component, call `useAvailabilityLaborAdjustmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityLaborAdjustmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityLaborAdjustmentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAvailabilityLaborAdjustmentsQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilityLaborAdjustmentsQuery, AvailabilityLaborAdjustmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityLaborAdjustmentsQuery, AvailabilityLaborAdjustmentsQueryVariables>(
    AvailabilityLaborAdjustmentsDocument,
    options,
  );
}
export function useAvailabilityLaborAdjustmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityLaborAdjustmentsQuery,
    AvailabilityLaborAdjustmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityLaborAdjustmentsQuery, AvailabilityLaborAdjustmentsQueryVariables>(
    AvailabilityLaborAdjustmentsDocument,
    options,
  );
}
export type AvailabilityLaborAdjustmentsQueryHookResult = ReturnType<typeof useAvailabilityLaborAdjustmentsQuery>;
export type AvailabilityLaborAdjustmentsLazyQueryHookResult = ReturnType<
  typeof useAvailabilityLaborAdjustmentsLazyQuery
>;
export type AvailabilityLaborAdjustmentsQueryResult = Apollo.QueryResult<
  AvailabilityLaborAdjustmentsQuery,
  AvailabilityLaborAdjustmentsQueryVariables
>;
export const AvailabilityGlobalUnavailabilitiesCreateDocument = gql`
  mutation AvailabilityGlobalUnavailabilitiesCreate($input: Availability__GlobalUnavailabilitiesCreateInput!) {
    result: availabilityGlobalUnavailabilitiesCreate(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityGlobalUnavailabilitiesCreateMutationFn = Apollo.MutationFunction<
  AvailabilityGlobalUnavailabilitiesCreateMutation,
  AvailabilityGlobalUnavailabilitiesCreateMutationVariables
>;

/**
 * __useAvailabilityGlobalUnavailabilitiesCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityGlobalUnavailabilitiesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityGlobalUnavailabilitiesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityGlobalUnavailabilitiesCreateMutation, { data, loading, error }] = useAvailabilityGlobalUnavailabilitiesCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityGlobalUnavailabilitiesCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityGlobalUnavailabilitiesCreateMutation,
    AvailabilityGlobalUnavailabilitiesCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityGlobalUnavailabilitiesCreateMutation,
    AvailabilityGlobalUnavailabilitiesCreateMutationVariables
  >(AvailabilityGlobalUnavailabilitiesCreateDocument, options);
}
export type AvailabilityGlobalUnavailabilitiesCreateMutationHookResult = ReturnType<
  typeof useAvailabilityGlobalUnavailabilitiesCreateMutation
>;
export type AvailabilityGlobalUnavailabilitiesCreateMutationResult =
  Apollo.MutationResult<AvailabilityGlobalUnavailabilitiesCreateMutation>;
export type AvailabilityGlobalUnavailabilitiesCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityGlobalUnavailabilitiesCreateMutation,
  AvailabilityGlobalUnavailabilitiesCreateMutationVariables
>;
export const AvailabilityGlobalUnavailabilitiesDestroyDocument = gql`
  mutation AvailabilityGlobalUnavailabilitiesDestroy($IDs: [ID!]!) {
    result: availabilityGlobalUnavailabilitiesDestroy(IDs: $IDs) {
      status
      error
    }
  }
`;
export type AvailabilityGlobalUnavailabilitiesDestroyMutationFn = Apollo.MutationFunction<
  AvailabilityGlobalUnavailabilitiesDestroyMutation,
  AvailabilityGlobalUnavailabilitiesDestroyMutationVariables
>;

/**
 * __useAvailabilityGlobalUnavailabilitiesDestroyMutation__
 *
 * To run a mutation, you first call `useAvailabilityGlobalUnavailabilitiesDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityGlobalUnavailabilitiesDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityGlobalUnavailabilitiesDestroyMutation, { data, loading, error }] = useAvailabilityGlobalUnavailabilitiesDestroyMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useAvailabilityGlobalUnavailabilitiesDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityGlobalUnavailabilitiesDestroyMutation,
    AvailabilityGlobalUnavailabilitiesDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityGlobalUnavailabilitiesDestroyMutation,
    AvailabilityGlobalUnavailabilitiesDestroyMutationVariables
  >(AvailabilityGlobalUnavailabilitiesDestroyDocument, options);
}
export type AvailabilityGlobalUnavailabilitiesDestroyMutationHookResult = ReturnType<
  typeof useAvailabilityGlobalUnavailabilitiesDestroyMutation
>;
export type AvailabilityGlobalUnavailabilitiesDestroyMutationResult =
  Apollo.MutationResult<AvailabilityGlobalUnavailabilitiesDestroyMutation>;
export type AvailabilityGlobalUnavailabilitiesDestroyMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityGlobalUnavailabilitiesDestroyMutation,
  AvailabilityGlobalUnavailabilitiesDestroyMutationVariables
>;
export const AvailabilityGlobalUnavailabilityDocument = gql`
  query AvailabilityGlobalUnavailability($filters: Availability__GlobalUnavailabilityFiltersInput!, $page: Int) {
    paginated: availabilityGlobalUnavailability(filters: $filters, page: $page) {
      results {
        ...Availability__globalUnavailability
      }
      pagination {
        ...pagination
      }
    }
    policy: availabilityGlobalUnavailabilityPolicy {
      create
      destroy
    }
  }
  ${Availability__GlobalUnavailabilityFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAvailabilityGlobalUnavailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityGlobalUnavailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityGlobalUnavailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityGlobalUnavailabilityQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAvailabilityGlobalUnavailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilityGlobalUnavailabilityQuery,
    AvailabilityGlobalUnavailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityGlobalUnavailabilityQuery, AvailabilityGlobalUnavailabilityQueryVariables>(
    AvailabilityGlobalUnavailabilityDocument,
    options,
  );
}
export function useAvailabilityGlobalUnavailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityGlobalUnavailabilityQuery,
    AvailabilityGlobalUnavailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityGlobalUnavailabilityQuery, AvailabilityGlobalUnavailabilityQueryVariables>(
    AvailabilityGlobalUnavailabilityDocument,
    options,
  );
}
export type AvailabilityGlobalUnavailabilityQueryHookResult = ReturnType<
  typeof useAvailabilityGlobalUnavailabilityQuery
>;
export type AvailabilityGlobalUnavailabilityLazyQueryHookResult = ReturnType<
  typeof useAvailabilityGlobalUnavailabilityLazyQuery
>;
export type AvailabilityGlobalUnavailabilityQueryResult = Apollo.QueryResult<
  AvailabilityGlobalUnavailabilityQuery,
  AvailabilityGlobalUnavailabilityQueryVariables
>;
export const AvailabilityJobAvailabilitiesCreateDocument = gql`
  mutation AvailabilityJobAvailabilitiesCreate($input: Availability__JobAvailabilitiesCreateInput!) {
    result: availabilityJobAvailabilitiesCreate(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityJobAvailabilitiesCreateMutationFn = Apollo.MutationFunction<
  AvailabilityJobAvailabilitiesCreateMutation,
  AvailabilityJobAvailabilitiesCreateMutationVariables
>;

/**
 * __useAvailabilityJobAvailabilitiesCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityJobAvailabilitiesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityJobAvailabilitiesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityJobAvailabilitiesCreateMutation, { data, loading, error }] = useAvailabilityJobAvailabilitiesCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityJobAvailabilitiesCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityJobAvailabilitiesCreateMutation,
    AvailabilityJobAvailabilitiesCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityJobAvailabilitiesCreateMutation,
    AvailabilityJobAvailabilitiesCreateMutationVariables
  >(AvailabilityJobAvailabilitiesCreateDocument, options);
}
export type AvailabilityJobAvailabilitiesCreateMutationHookResult = ReturnType<
  typeof useAvailabilityJobAvailabilitiesCreateMutation
>;
export type AvailabilityJobAvailabilitiesCreateMutationResult =
  Apollo.MutationResult<AvailabilityJobAvailabilitiesCreateMutation>;
export type AvailabilityJobAvailabilitiesCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityJobAvailabilitiesCreateMutation,
  AvailabilityJobAvailabilitiesCreateMutationVariables
>;
export const AvailabilityJobAvailabilitiesDestroyDocument = gql`
  mutation AvailabilityJobAvailabilitiesDestroy($input: Availability__JobAvailabilitiesDestroyInput!) {
    result: availabilityJobAvailabilitiesDestroy(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityJobAvailabilitiesDestroyMutationFn = Apollo.MutationFunction<
  AvailabilityJobAvailabilitiesDestroyMutation,
  AvailabilityJobAvailabilitiesDestroyMutationVariables
>;

/**
 * __useAvailabilityJobAvailabilitiesDestroyMutation__
 *
 * To run a mutation, you first call `useAvailabilityJobAvailabilitiesDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityJobAvailabilitiesDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityJobAvailabilitiesDestroyMutation, { data, loading, error }] = useAvailabilityJobAvailabilitiesDestroyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityJobAvailabilitiesDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityJobAvailabilitiesDestroyMutation,
    AvailabilityJobAvailabilitiesDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityJobAvailabilitiesDestroyMutation,
    AvailabilityJobAvailabilitiesDestroyMutationVariables
  >(AvailabilityJobAvailabilitiesDestroyDocument, options);
}
export type AvailabilityJobAvailabilitiesDestroyMutationHookResult = ReturnType<
  typeof useAvailabilityJobAvailabilitiesDestroyMutation
>;
export type AvailabilityJobAvailabilitiesDestroyMutationResult =
  Apollo.MutationResult<AvailabilityJobAvailabilitiesDestroyMutation>;
export type AvailabilityJobAvailabilitiesDestroyMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityJobAvailabilitiesDestroyMutation,
  AvailabilityJobAvailabilitiesDestroyMutationVariables
>;
export const AvailabilityJobAvailabilityDocument = gql`
  query AvailabilityJobAvailability($filters: Availability__JobAvailabilityFiltersInput!, $page: Int) {
    paginated: availabilityJobAvailability(filters: $filters, page: $page) {
      results {
        ... on Availability__JobTypeAvailability {
          ...Availability__jobTypeAvailability
        }
      }
      pagination {
        ...pagination
      }
    }
    policy: availabilityJobAvailabilityPolicy {
      create
      destroy
    }
  }
  ${Availability__JobTypeAvailabilityFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAvailabilityJobAvailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityJobAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityJobAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityJobAvailabilityQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAvailabilityJobAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilityJobAvailabilityQuery, AvailabilityJobAvailabilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityJobAvailabilityQuery, AvailabilityJobAvailabilityQueryVariables>(
    AvailabilityJobAvailabilityDocument,
    options,
  );
}
export function useAvailabilityJobAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityJobAvailabilityQuery,
    AvailabilityJobAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityJobAvailabilityQuery, AvailabilityJobAvailabilityQueryVariables>(
    AvailabilityJobAvailabilityDocument,
    options,
  );
}
export type AvailabilityJobAvailabilityQueryHookResult = ReturnType<typeof useAvailabilityJobAvailabilityQuery>;
export type AvailabilityJobAvailabilityLazyQueryHookResult = ReturnType<typeof useAvailabilityJobAvailabilityLazyQuery>;
export type AvailabilityJobAvailabilityQueryResult = Apollo.QueryResult<
  AvailabilityJobAvailabilityQuery,
  AvailabilityJobAvailabilityQueryVariables
>;
export const AvailabilityOperatingScheduleTemplateCreateDocument = gql`
  mutation AvailabilityOperatingScheduleTemplateCreate($input: Availability__OperatingScheduleTemplateCreate__Input!) {
    result: availabilityOperatingScheduleTemplateCreate(input: $input) {
      status
    }
  }
`;
export type AvailabilityOperatingScheduleTemplateCreateMutationFn = Apollo.MutationFunction<
  AvailabilityOperatingScheduleTemplateCreateMutation,
  AvailabilityOperatingScheduleTemplateCreateMutationVariables
>;

/**
 * __useAvailabilityOperatingScheduleTemplateCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityOperatingScheduleTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityOperatingScheduleTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityOperatingScheduleTemplateCreateMutation, { data, loading, error }] = useAvailabilityOperatingScheduleTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityOperatingScheduleTemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityOperatingScheduleTemplateCreateMutation,
    AvailabilityOperatingScheduleTemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityOperatingScheduleTemplateCreateMutation,
    AvailabilityOperatingScheduleTemplateCreateMutationVariables
  >(AvailabilityOperatingScheduleTemplateCreateDocument, options);
}
export type AvailabilityOperatingScheduleTemplateCreateMutationHookResult = ReturnType<
  typeof useAvailabilityOperatingScheduleTemplateCreateMutation
>;
export type AvailabilityOperatingScheduleTemplateCreateMutationResult =
  Apollo.MutationResult<AvailabilityOperatingScheduleTemplateCreateMutation>;
export type AvailabilityOperatingScheduleTemplateCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityOperatingScheduleTemplateCreateMutation,
  AvailabilityOperatingScheduleTemplateCreateMutationVariables
>;
export const AvailabilityRescheduleOffersCreateDocument = gql`
  mutation AvailabilityRescheduleOffersCreate($input: Availability__RescheduleOffersCreateInput!) {
    result: availabilityRescheduleOffersCreate(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityRescheduleOffersCreateMutationFn = Apollo.MutationFunction<
  AvailabilityRescheduleOffersCreateMutation,
  AvailabilityRescheduleOffersCreateMutationVariables
>;

/**
 * __useAvailabilityRescheduleOffersCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityRescheduleOffersCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityRescheduleOffersCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityRescheduleOffersCreateMutation, { data, loading, error }] = useAvailabilityRescheduleOffersCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityRescheduleOffersCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityRescheduleOffersCreateMutation,
    AvailabilityRescheduleOffersCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityRescheduleOffersCreateMutation,
    AvailabilityRescheduleOffersCreateMutationVariables
  >(AvailabilityRescheduleOffersCreateDocument, options);
}
export type AvailabilityRescheduleOffersCreateMutationHookResult = ReturnType<
  typeof useAvailabilityRescheduleOffersCreateMutation
>;
export type AvailabilityRescheduleOffersCreateMutationResult =
  Apollo.MutationResult<AvailabilityRescheduleOffersCreateMutation>;
export type AvailabilityRescheduleOffersCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityRescheduleOffersCreateMutation,
  AvailabilityRescheduleOffersCreateMutationVariables
>;
export const AvailabilityRescheduleOffersDeleteDocument = gql`
  mutation AvailabilityRescheduleOffersDelete($IDs: [ID!]!) {
    result: availabilityRescheduleOffersDelete(IDs: $IDs) {
      status
      error
    }
  }
`;
export type AvailabilityRescheduleOffersDeleteMutationFn = Apollo.MutationFunction<
  AvailabilityRescheduleOffersDeleteMutation,
  AvailabilityRescheduleOffersDeleteMutationVariables
>;

/**
 * __useAvailabilityRescheduleOffersDeleteMutation__
 *
 * To run a mutation, you first call `useAvailabilityRescheduleOffersDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityRescheduleOffersDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityRescheduleOffersDeleteMutation, { data, loading, error }] = useAvailabilityRescheduleOffersDeleteMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useAvailabilityRescheduleOffersDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityRescheduleOffersDeleteMutation,
    AvailabilityRescheduleOffersDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityRescheduleOffersDeleteMutation,
    AvailabilityRescheduleOffersDeleteMutationVariables
  >(AvailabilityRescheduleOffersDeleteDocument, options);
}
export type AvailabilityRescheduleOffersDeleteMutationHookResult = ReturnType<
  typeof useAvailabilityRescheduleOffersDeleteMutation
>;
export type AvailabilityRescheduleOffersDeleteMutationResult =
  Apollo.MutationResult<AvailabilityRescheduleOffersDeleteMutation>;
export type AvailabilityRescheduleOffersDeleteMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityRescheduleOffersDeleteMutation,
  AvailabilityRescheduleOffersDeleteMutationVariables
>;
export const AvailabilityRescheduleOffersDocument = gql`
  query AvailabilityRescheduleOffers($filters: Availability__RescheduleOfferFiltersInput!, $page: Int) {
    paginated: availabilityRescheduleOffers(filters: $filters, page: $page) {
      results {
        ...Availability__rescheduleOffer
      }
      pagination {
        ...pagination
      }
    }
    policy: availabilityLaborAdjustmentsPolicy {
      create
      destroy
    }
  }
  ${Availability__RescheduleOfferFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAvailabilityRescheduleOffersQuery__
 *
 * To run a query within a React component, call `useAvailabilityRescheduleOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityRescheduleOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityRescheduleOffersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAvailabilityRescheduleOffersQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilityRescheduleOffersQuery, AvailabilityRescheduleOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityRescheduleOffersQuery, AvailabilityRescheduleOffersQueryVariables>(
    AvailabilityRescheduleOffersDocument,
    options,
  );
}
export function useAvailabilityRescheduleOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityRescheduleOffersQuery,
    AvailabilityRescheduleOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityRescheduleOffersQuery, AvailabilityRescheduleOffersQueryVariables>(
    AvailabilityRescheduleOffersDocument,
    options,
  );
}
export type AvailabilityRescheduleOffersQueryHookResult = ReturnType<typeof useAvailabilityRescheduleOffersQuery>;
export type AvailabilityRescheduleOffersLazyQueryHookResult = ReturnType<
  typeof useAvailabilityRescheduleOffersLazyQuery
>;
export type AvailabilityRescheduleOffersQueryResult = Apollo.QueryResult<
  AvailabilityRescheduleOffersQuery,
  AvailabilityRescheduleOffersQueryVariables
>;
export const AvailabilityFacilityCuftLimitsCreateDocument = gql`
  mutation AvailabilityFacilityCuftLimitsCreate($input: Availability__FacilityCuftLimitsCreateInput!) {
    result: availabilityFacilityCuftLimitsCreate(input: $input) {
      status
      error
    }
  }
`;
export type AvailabilityFacilityCuftLimitsCreateMutationFn = Apollo.MutationFunction<
  AvailabilityFacilityCuftLimitsCreateMutation,
  AvailabilityFacilityCuftLimitsCreateMutationVariables
>;

/**
 * __useAvailabilityFacilityCuftLimitsCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityFacilityCuftLimitsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityFacilityCuftLimitsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityFacilityCuftLimitsCreateMutation, { data, loading, error }] = useAvailabilityFacilityCuftLimitsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityFacilityCuftLimitsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityFacilityCuftLimitsCreateMutation,
    AvailabilityFacilityCuftLimitsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityFacilityCuftLimitsCreateMutation,
    AvailabilityFacilityCuftLimitsCreateMutationVariables
  >(AvailabilityFacilityCuftLimitsCreateDocument, options);
}
export type AvailabilityFacilityCuftLimitsCreateMutationHookResult = ReturnType<
  typeof useAvailabilityFacilityCuftLimitsCreateMutation
>;
export type AvailabilityFacilityCuftLimitsCreateMutationResult =
  Apollo.MutationResult<AvailabilityFacilityCuftLimitsCreateMutation>;
export type AvailabilityFacilityCuftLimitsCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityFacilityCuftLimitsCreateMutation,
  AvailabilityFacilityCuftLimitsCreateMutationVariables
>;
export const AvailabilityFacilityCuftLimitsDestroyDocument = gql`
  mutation AvailabilityFacilityCuftLimitsDestroy($IDs: [ID!]!) {
    result: availabilityFacilityCuftLimitsDestroy(IDs: $IDs) {
      status
      error
    }
  }
`;
export type AvailabilityFacilityCuftLimitsDestroyMutationFn = Apollo.MutationFunction<
  AvailabilityFacilityCuftLimitsDestroyMutation,
  AvailabilityFacilityCuftLimitsDestroyMutationVariables
>;

/**
 * __useAvailabilityFacilityCuftLimitsDestroyMutation__
 *
 * To run a mutation, you first call `useAvailabilityFacilityCuftLimitsDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityFacilityCuftLimitsDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityFacilityCuftLimitsDestroyMutation, { data, loading, error }] = useAvailabilityFacilityCuftLimitsDestroyMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useAvailabilityFacilityCuftLimitsDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityFacilityCuftLimitsDestroyMutation,
    AvailabilityFacilityCuftLimitsDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityFacilityCuftLimitsDestroyMutation,
    AvailabilityFacilityCuftLimitsDestroyMutationVariables
  >(AvailabilityFacilityCuftLimitsDestroyDocument, options);
}
export type AvailabilityFacilityCuftLimitsDestroyMutationHookResult = ReturnType<
  typeof useAvailabilityFacilityCuftLimitsDestroyMutation
>;
export type AvailabilityFacilityCuftLimitsDestroyMutationResult =
  Apollo.MutationResult<AvailabilityFacilityCuftLimitsDestroyMutation>;
export type AvailabilityFacilityCuftLimitsDestroyMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityFacilityCuftLimitsDestroyMutation,
  AvailabilityFacilityCuftLimitsDestroyMutationVariables
>;
export const AvailabilityFacilityCuftLimitsDocument = gql`
  query AvailabilityFacilityCuftLimits($filters: Availability__FacilityCuftLimitFiltersInput!, $page: Int) {
    paginated: availabilityFacilityCuftLimits(filters: $filters, page: $page) {
      results {
        ...facilityCuftLimit
      }
      pagination {
        ...pagination
      }
    }
    policy: availabilityFacilityCuftLimitPolicy {
      create
      destroy
    }
  }
  ${FacilityCuftLimitFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAvailabilityFacilityCuftLimitsQuery__
 *
 * To run a query within a React component, call `useAvailabilityFacilityCuftLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityFacilityCuftLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityFacilityCuftLimitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAvailabilityFacilityCuftLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilityFacilityCuftLimitsQuery,
    AvailabilityFacilityCuftLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityFacilityCuftLimitsQuery, AvailabilityFacilityCuftLimitsQueryVariables>(
    AvailabilityFacilityCuftLimitsDocument,
    options,
  );
}
export function useAvailabilityFacilityCuftLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityFacilityCuftLimitsQuery,
    AvailabilityFacilityCuftLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityFacilityCuftLimitsQuery, AvailabilityFacilityCuftLimitsQueryVariables>(
    AvailabilityFacilityCuftLimitsDocument,
    options,
  );
}
export type AvailabilityFacilityCuftLimitsQueryHookResult = ReturnType<typeof useAvailabilityFacilityCuftLimitsQuery>;
export type AvailabilityFacilityCuftLimitsLazyQueryHookResult = ReturnType<
  typeof useAvailabilityFacilityCuftLimitsLazyQuery
>;
export type AvailabilityFacilityCuftLimitsQueryResult = Apollo.QueryResult<
  AvailabilityFacilityCuftLimitsQuery,
  AvailabilityFacilityCuftLimitsQueryVariables
>;
export const BillingAuthorizationHoldCancelDocument = gql`
  mutation BillingAuthorizationHoldCancel($orderID: ID!) {
    result: billingAuthorizationHoldCancel(orderID: $orderID) {
      status
      unattemptedAuthorizationHold {
        id
        status
      }
    }
  }
`;
export type BillingAuthorizationHoldCancelMutationFn = Apollo.MutationFunction<
  BillingAuthorizationHoldCancelMutation,
  BillingAuthorizationHoldCancelMutationVariables
>;

/**
 * __useBillingAuthorizationHoldCancelMutation__
 *
 * To run a mutation, you first call `useBillingAuthorizationHoldCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingAuthorizationHoldCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingAuthorizationHoldCancelMutation, { data, loading, error }] = useBillingAuthorizationHoldCancelMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBillingAuthorizationHoldCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingAuthorizationHoldCancelMutation,
    BillingAuthorizationHoldCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingAuthorizationHoldCancelMutation, BillingAuthorizationHoldCancelMutationVariables>(
    BillingAuthorizationHoldCancelDocument,
    options,
  );
}
export type BillingAuthorizationHoldCancelMutationHookResult = ReturnType<
  typeof useBillingAuthorizationHoldCancelMutation
>;
export type BillingAuthorizationHoldCancelMutationResult =
  Apollo.MutationResult<BillingAuthorizationHoldCancelMutation>;
export type BillingAuthorizationHoldCancelMutationOptions = Apollo.BaseMutationOptions<
  BillingAuthorizationHoldCancelMutation,
  BillingAuthorizationHoldCancelMutationVariables
>;
export const BillingAuthorizationHoldsDocument = gql`
  query BillingAuthorizationHolds($orderID: ID!) {
    billingUnattemptedAuthorizationHold(orderID: $orderID) {
      id
      amount
      date
      source {
        id
        number
      }
      orderID
      status
    }
    billingAuthorizationHolds(orderID: $orderID) {
      id
      charge {
        id
        amount
        date
        outcomeReason
        source {
          id
          number
        }
        status
      }
    }
  }
`;

/**
 * __useBillingAuthorizationHoldsQuery__
 *
 * To run a query within a React component, call `useBillingAuthorizationHoldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingAuthorizationHoldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingAuthorizationHoldsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBillingAuthorizationHoldsQuery(
  baseOptions: Apollo.QueryHookOptions<BillingAuthorizationHoldsQuery, BillingAuthorizationHoldsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingAuthorizationHoldsQuery, BillingAuthorizationHoldsQueryVariables>(
    BillingAuthorizationHoldsDocument,
    options,
  );
}
export function useBillingAuthorizationHoldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingAuthorizationHoldsQuery, BillingAuthorizationHoldsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingAuthorizationHoldsQuery, BillingAuthorizationHoldsQueryVariables>(
    BillingAuthorizationHoldsDocument,
    options,
  );
}
export type BillingAuthorizationHoldsQueryHookResult = ReturnType<typeof useBillingAuthorizationHoldsQuery>;
export type BillingAuthorizationHoldsLazyQueryHookResult = ReturnType<typeof useBillingAuthorizationHoldsLazyQuery>;
export type BillingAuthorizationHoldsQueryResult = Apollo.QueryResult<
  BillingAuthorizationHoldsQuery,
  BillingAuthorizationHoldsQueryVariables
>;
export const BillingAuthorizationHoldUncancelDocument = gql`
  mutation BillingAuthorizationHoldUncancel($orderID: ID!) {
    result: billingAuthorizationHoldUncancel(orderID: $orderID) {
      status
      unattemptedAuthorizationHold {
        id
        status
      }
    }
  }
`;
export type BillingAuthorizationHoldUncancelMutationFn = Apollo.MutationFunction<
  BillingAuthorizationHoldUncancelMutation,
  BillingAuthorizationHoldUncancelMutationVariables
>;

/**
 * __useBillingAuthorizationHoldUncancelMutation__
 *
 * To run a mutation, you first call `useBillingAuthorizationHoldUncancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingAuthorizationHoldUncancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingAuthorizationHoldUncancelMutation, { data, loading, error }] = useBillingAuthorizationHoldUncancelMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBillingAuthorizationHoldUncancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingAuthorizationHoldUncancelMutation,
    BillingAuthorizationHoldUncancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BillingAuthorizationHoldUncancelMutation,
    BillingAuthorizationHoldUncancelMutationVariables
  >(BillingAuthorizationHoldUncancelDocument, options);
}
export type BillingAuthorizationHoldUncancelMutationHookResult = ReturnType<
  typeof useBillingAuthorizationHoldUncancelMutation
>;
export type BillingAuthorizationHoldUncancelMutationResult =
  Apollo.MutationResult<BillingAuthorizationHoldUncancelMutation>;
export type BillingAuthorizationHoldUncancelMutationOptions = Apollo.BaseMutationOptions<
  BillingAuthorizationHoldUncancelMutation,
  BillingAuthorizationHoldUncancelMutationVariables
>;
export const BillingBuildChargeDocument = gql`
  mutation BillingBuildCharge($input: Billing__ChargeInput!) {
    result: billingBuildCharge(input: $input) {
      status
      error
    }
  }
`;
export type BillingBuildChargeMutationFn = Apollo.MutationFunction<
  BillingBuildChargeMutation,
  BillingBuildChargeMutationVariables
>;

/**
 * __useBillingBuildChargeMutation__
 *
 * To run a mutation, you first call `useBillingBuildChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingBuildChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingBuildChargeMutation, { data, loading, error }] = useBillingBuildChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingBuildChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingBuildChargeMutation, BillingBuildChargeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingBuildChargeMutation, BillingBuildChargeMutationVariables>(
    BillingBuildChargeDocument,
    options,
  );
}
export type BillingBuildChargeMutationHookResult = ReturnType<typeof useBillingBuildChargeMutation>;
export type BillingBuildChargeMutationResult = Apollo.MutationResult<BillingBuildChargeMutation>;
export type BillingBuildChargeMutationOptions = Apollo.BaseMutationOptions<
  BillingBuildChargeMutation,
  BillingBuildChargeMutationVariables
>;
export const BillingInvoiceItemKindsDocument = gql`
  query BillingInvoiceItemKinds($accountID: ID!) {
    billing(accountID: $accountID) {
      options: invoiceItemKinds
    }
  }
`;

/**
 * __useBillingInvoiceItemKindsQuery__
 *
 * To run a query within a React component, call `useBillingInvoiceItemKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoiceItemKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingInvoiceItemKindsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useBillingInvoiceItemKindsQuery(
  baseOptions: Apollo.QueryHookOptions<BillingInvoiceItemKindsQuery, BillingInvoiceItemKindsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingInvoiceItemKindsQuery, BillingInvoiceItemKindsQueryVariables>(
    BillingInvoiceItemKindsDocument,
    options,
  );
}
export function useBillingInvoiceItemKindsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingInvoiceItemKindsQuery, BillingInvoiceItemKindsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingInvoiceItemKindsQuery, BillingInvoiceItemKindsQueryVariables>(
    BillingInvoiceItemKindsDocument,
    options,
  );
}
export type BillingInvoiceItemKindsQueryHookResult = ReturnType<typeof useBillingInvoiceItemKindsQuery>;
export type BillingInvoiceItemKindsLazyQueryHookResult = ReturnType<typeof useBillingInvoiceItemKindsLazyQuery>;
export type BillingInvoiceItemKindsQueryResult = Apollo.QueryResult<
  BillingInvoiceItemKindsQuery,
  BillingInvoiceItemKindsQueryVariables
>;
export const BillingInvoiceForgiveDocument = gql`
  mutation BillingInvoiceForgive($invoiceID: ID!, $input: Billing__Invoice__ForgiveInput!) {
    result: billingInvoiceForgive(invoiceID: $invoiceID, input: $input) {
      attemptID
    }
  }
`;
export type BillingInvoiceForgiveMutationFn = Apollo.MutationFunction<
  BillingInvoiceForgiveMutation,
  BillingInvoiceForgiveMutationVariables
>;

/**
 * __useBillingInvoiceForgiveMutation__
 *
 * To run a mutation, you first call `useBillingInvoiceForgiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoiceForgiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingInvoiceForgiveMutation, { data, loading, error }] = useBillingInvoiceForgiveMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingInvoiceForgiveMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingInvoiceForgiveMutation, BillingInvoiceForgiveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingInvoiceForgiveMutation, BillingInvoiceForgiveMutationVariables>(
    BillingInvoiceForgiveDocument,
    options,
  );
}
export type BillingInvoiceForgiveMutationHookResult = ReturnType<typeof useBillingInvoiceForgiveMutation>;
export type BillingInvoiceForgiveMutationResult = Apollo.MutationResult<BillingInvoiceForgiveMutation>;
export type BillingInvoiceForgiveMutationOptions = Apollo.BaseMutationOptions<
  BillingInvoiceForgiveMutation,
  BillingInvoiceForgiveMutationVariables
>;
export const BillingForgivenessReasonsDocument = gql`
  query BillingForgivenessReasons {
    billingForgivenessReasons {
      ...billing__forgivenessReason
    }
  }
  ${Billing__ForgivenessReasonFragmentDoc}
`;

/**
 * __useBillingForgivenessReasonsQuery__
 *
 * To run a query within a React component, call `useBillingForgivenessReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingForgivenessReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingForgivenessReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingForgivenessReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingForgivenessReasonsQuery, BillingForgivenessReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingForgivenessReasonsQuery, BillingForgivenessReasonsQueryVariables>(
    BillingForgivenessReasonsDocument,
    options,
  );
}
export function useBillingForgivenessReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingForgivenessReasonsQuery, BillingForgivenessReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingForgivenessReasonsQuery, BillingForgivenessReasonsQueryVariables>(
    BillingForgivenessReasonsDocument,
    options,
  );
}
export type BillingForgivenessReasonsQueryHookResult = ReturnType<typeof useBillingForgivenessReasonsQuery>;
export type BillingForgivenessReasonsLazyQueryHookResult = ReturnType<typeof useBillingForgivenessReasonsLazyQuery>;
export type BillingForgivenessReasonsQueryResult = Apollo.QueryResult<
  BillingForgivenessReasonsQuery,
  BillingForgivenessReasonsQueryVariables
>;
export const BillingInvoiceMarkAsPaidDocument = gql`
  mutation BillingInvoiceMarkAsPaid($invoiceID: ID!, $input: Billing__Invoice__MarkAsPaidInput!) {
    result: billingInvoiceMarkAsPaid(invoiceID: $invoiceID, input: $input) {
      attemptID
    }
  }
`;
export type BillingInvoiceMarkAsPaidMutationFn = Apollo.MutationFunction<
  BillingInvoiceMarkAsPaidMutation,
  BillingInvoiceMarkAsPaidMutationVariables
>;

/**
 * __useBillingInvoiceMarkAsPaidMutation__
 *
 * To run a mutation, you first call `useBillingInvoiceMarkAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoiceMarkAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingInvoiceMarkAsPaidMutation, { data, loading, error }] = useBillingInvoiceMarkAsPaidMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingInvoiceMarkAsPaidMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingInvoiceMarkAsPaidMutation, BillingInvoiceMarkAsPaidMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingInvoiceMarkAsPaidMutation, BillingInvoiceMarkAsPaidMutationVariables>(
    BillingInvoiceMarkAsPaidDocument,
    options,
  );
}
export type BillingInvoiceMarkAsPaidMutationHookResult = ReturnType<typeof useBillingInvoiceMarkAsPaidMutation>;
export type BillingInvoiceMarkAsPaidMutationResult = Apollo.MutationResult<BillingInvoiceMarkAsPaidMutation>;
export type BillingInvoiceMarkAsPaidMutationOptions = Apollo.BaseMutationOptions<
  BillingInvoiceMarkAsPaidMutation,
  BillingInvoiceMarkAsPaidMutationVariables
>;
export const BillingInvoiceReBillDocument = gql`
  mutation BillingInvoiceReBill($invoiceID: ID!, $input: Billing__Invoice__ReBillInput!) {
    rebill: billingInvoiceReBill(invoiceID: $invoiceID, input: $input) {
      attemptID
    }
  }
`;
export type BillingInvoiceReBillMutationFn = Apollo.MutationFunction<
  BillingInvoiceReBillMutation,
  BillingInvoiceReBillMutationVariables
>;

/**
 * __useBillingInvoiceReBillMutation__
 *
 * To run a mutation, you first call `useBillingInvoiceReBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoiceReBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingInvoiceReBillMutation, { data, loading, error }] = useBillingInvoiceReBillMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingInvoiceReBillMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingInvoiceReBillMutation, BillingInvoiceReBillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingInvoiceReBillMutation, BillingInvoiceReBillMutationVariables>(
    BillingInvoiceReBillDocument,
    options,
  );
}
export type BillingInvoiceReBillMutationHookResult = ReturnType<typeof useBillingInvoiceReBillMutation>;
export type BillingInvoiceReBillMutationResult = Apollo.MutationResult<BillingInvoiceReBillMutation>;
export type BillingInvoiceReBillMutationOptions = Apollo.BaseMutationOptions<
  BillingInvoiceReBillMutation,
  BillingInvoiceReBillMutationVariables
>;
export const BillingReBillReasonsDocument = gql`
  query BillingReBillReasons($invoiceID: ID!) {
    reasons: billingReBillReasons(invoiceID: $invoiceID) {
      ...billing__reBillReason
    }
  }
  ${Billing__ReBillReasonFragmentDoc}
`;

/**
 * __useBillingReBillReasonsQuery__
 *
 * To run a query within a React component, call `useBillingReBillReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingReBillReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingReBillReasonsQuery({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *   },
 * });
 */
export function useBillingReBillReasonsQuery(
  baseOptions: Apollo.QueryHookOptions<BillingReBillReasonsQuery, BillingReBillReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingReBillReasonsQuery, BillingReBillReasonsQueryVariables>(
    BillingReBillReasonsDocument,
    options,
  );
}
export function useBillingReBillReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingReBillReasonsQuery, BillingReBillReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingReBillReasonsQuery, BillingReBillReasonsQueryVariables>(
    BillingReBillReasonsDocument,
    options,
  );
}
export type BillingReBillReasonsQueryHookResult = ReturnType<typeof useBillingReBillReasonsQuery>;
export type BillingReBillReasonsLazyQueryHookResult = ReturnType<typeof useBillingReBillReasonsLazyQuery>;
export type BillingReBillReasonsQueryResult = Apollo.QueryResult<
  BillingReBillReasonsQuery,
  BillingReBillReasonsQueryVariables
>;
export const BillingReceiptSendDocument = gql`
  mutation BillingReceiptSend($input: Billing__Receipt__SendInput!) {
    result: billingReceiptSend(input: $input) {
      status
    }
  }
`;
export type BillingReceiptSendMutationFn = Apollo.MutationFunction<
  BillingReceiptSendMutation,
  BillingReceiptSendMutationVariables
>;

/**
 * __useBillingReceiptSendMutation__
 *
 * To run a mutation, you first call `useBillingReceiptSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingReceiptSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingReceiptSendMutation, { data, loading, error }] = useBillingReceiptSendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingReceiptSendMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingReceiptSendMutation, BillingReceiptSendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingReceiptSendMutation, BillingReceiptSendMutationVariables>(
    BillingReceiptSendDocument,
    options,
  );
}
export type BillingReceiptSendMutationHookResult = ReturnType<typeof useBillingReceiptSendMutation>;
export type BillingReceiptSendMutationResult = Apollo.MutationResult<BillingReceiptSendMutation>;
export type BillingReceiptSendMutationOptions = Apollo.BaseMutationOptions<
  BillingReceiptSendMutation,
  BillingReceiptSendMutationVariables
>;
export const BillingChargeRefundDocument = gql`
  mutation BillingChargeRefund($id: ID!, $input: Billing__Charge__RefundInput!) {
    result: billingChargeRefund(id: $id, input: $input) {
      status
      error
    }
  }
`;
export type BillingChargeRefundMutationFn = Apollo.MutationFunction<
  BillingChargeRefundMutation,
  BillingChargeRefundMutationVariables
>;

/**
 * __useBillingChargeRefundMutation__
 *
 * To run a mutation, you first call `useBillingChargeRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingChargeRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingChargeRefundMutation, { data, loading, error }] = useBillingChargeRefundMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingChargeRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingChargeRefundMutation, BillingChargeRefundMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingChargeRefundMutation, BillingChargeRefundMutationVariables>(
    BillingChargeRefundDocument,
    options,
  );
}
export type BillingChargeRefundMutationHookResult = ReturnType<typeof useBillingChargeRefundMutation>;
export type BillingChargeRefundMutationResult = Apollo.MutationResult<BillingChargeRefundMutation>;
export type BillingChargeRefundMutationOptions = Apollo.BaseMutationOptions<
  BillingChargeRefundMutation,
  BillingChargeRefundMutationVariables
>;
export const BillingSourceDefaultDocument = gql`
  mutation BillingSourceDefault($id: ID!) {
    result: billingSourceDefault(id: $id) {
      source {
        id
        default
      }
    }
  }
`;
export type BillingSourceDefaultMutationFn = Apollo.MutationFunction<
  BillingSourceDefaultMutation,
  BillingSourceDefaultMutationVariables
>;

/**
 * __useBillingSourceDefaultMutation__
 *
 * To run a mutation, you first call `useBillingSourceDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSourceDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSourceDefaultMutation, { data, loading, error }] = useBillingSourceDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingSourceDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingSourceDefaultMutation, BillingSourceDefaultMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingSourceDefaultMutation, BillingSourceDefaultMutationVariables>(
    BillingSourceDefaultDocument,
    options,
  );
}
export type BillingSourceDefaultMutationHookResult = ReturnType<typeof useBillingSourceDefaultMutation>;
export type BillingSourceDefaultMutationResult = Apollo.MutationResult<BillingSourceDefaultMutation>;
export type BillingSourceDefaultMutationOptions = Apollo.BaseMutationOptions<
  BillingSourceDefaultMutation,
  BillingSourceDefaultMutationVariables
>;
export const BillingSourceDeleteDocument = gql`
  mutation BillingSourceDelete($id: ID!) {
    result: billingSourceDelete(id: $id) {
      source {
        id
        active
      }
    }
  }
`;
export type BillingSourceDeleteMutationFn = Apollo.MutationFunction<
  BillingSourceDeleteMutation,
  BillingSourceDeleteMutationVariables
>;

/**
 * __useBillingSourceDeleteMutation__
 *
 * To run a mutation, you first call `useBillingSourceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSourceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSourceDeleteMutation, { data, loading, error }] = useBillingSourceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingSourceDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingSourceDeleteMutation, BillingSourceDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingSourceDeleteMutation, BillingSourceDeleteMutationVariables>(
    BillingSourceDeleteDocument,
    options,
  );
}
export type BillingSourceDeleteMutationHookResult = ReturnType<typeof useBillingSourceDeleteMutation>;
export type BillingSourceDeleteMutationResult = Apollo.MutationResult<BillingSourceDeleteMutation>;
export type BillingSourceDeleteMutationOptions = Apollo.BaseMutationOptions<
  BillingSourceDeleteMutation,
  BillingSourceDeleteMutationVariables
>;
export const BillingSourceBuildDocument = gql`
  mutation BillingSourceBuild($accountID: ID!, $token: ID!) {
    result: billingSourceBuild(accountID: $accountID, token: $token) {
      error
      account {
        id
        sources {
          ...billing__source
        }
      }
      source {
        ...billing__source
      }
    }
  }
  ${Billing__SourceFragmentDoc}
`;
export type BillingSourceBuildMutationFn = Apollo.MutationFunction<
  BillingSourceBuildMutation,
  BillingSourceBuildMutationVariables
>;

/**
 * __useBillingSourceBuildMutation__
 *
 * To run a mutation, you first call `useBillingSourceBuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSourceBuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSourceBuildMutation, { data, loading, error }] = useBillingSourceBuildMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useBillingSourceBuildMutation(
  baseOptions?: Apollo.MutationHookOptions<BillingSourceBuildMutation, BillingSourceBuildMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingSourceBuildMutation, BillingSourceBuildMutationVariables>(
    BillingSourceBuildDocument,
    options,
  );
}
export type BillingSourceBuildMutationHookResult = ReturnType<typeof useBillingSourceBuildMutation>;
export type BillingSourceBuildMutationResult = Apollo.MutationResult<BillingSourceBuildMutation>;
export type BillingSourceBuildMutationOptions = Apollo.BaseMutationOptions<
  BillingSourceBuildMutation,
  BillingSourceBuildMutationVariables
>;
export const AccountSourcesDocument = gql`
  query AccountSources($accountID: ID!) {
    account(accountID: $accountID) {
      id
      sources {
        ...billing__source
      }
    }
    permissions: sourcePolicy {
      delete
    }
  }
  ${Billing__SourceFragmentDoc}
`;

/**
 * __useAccountSourcesQuery__
 *
 * To run a query within a React component, call `useAccountSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSourcesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountSourcesQuery(
  baseOptions: Apollo.QueryHookOptions<AccountSourcesQuery, AccountSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSourcesQuery, AccountSourcesQueryVariables>(AccountSourcesDocument, options);
}
export function useAccountSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountSourcesQuery, AccountSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountSourcesQuery, AccountSourcesQueryVariables>(AccountSourcesDocument, options);
}
export type AccountSourcesQueryHookResult = ReturnType<typeof useAccountSourcesQuery>;
export type AccountSourcesLazyQueryHookResult = ReturnType<typeof useAccountSourcesLazyQuery>;
export type AccountSourcesQueryResult = Apollo.QueryResult<AccountSourcesQuery, AccountSourcesQueryVariables>;
export const ItemCategoriesDocument = gql`
  query ItemCategories {
    categories: itemCategories {
      id
      name
      parentID
      moveAndPackVisible
      sizingMetric {
        id
        name
        enumerations
        range
        unit
      }
    }
  }
`;

/**
 * __useItemCategoriesQuery__
 *
 * To run a query within a React component, call `useItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, options);
}
export function useItemCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, options);
}
export type ItemCategoriesQueryHookResult = ReturnType<typeof useItemCategoriesQuery>;
export type ItemCategoriesLazyQueryHookResult = ReturnType<typeof useItemCategoriesLazyQuery>;
export type ItemCategoriesQueryResult = Apollo.QueryResult<ItemCategoriesQuery, ItemCategoriesQueryVariables>;
export const MaterialCategoriesDocument = gql`
  query MaterialCategories {
    categories: materialCategories {
      id
      name
    }
  }
`;

/**
 * __useMaterialCategoriesQuery__
 *
 * To run a query within a React component, call `useMaterialCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaterialCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<MaterialCategoriesQuery, MaterialCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MaterialCategoriesQuery, MaterialCategoriesQueryVariables>(
    MaterialCategoriesDocument,
    options,
  );
}
export function useMaterialCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MaterialCategoriesQuery, MaterialCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MaterialCategoriesQuery, MaterialCategoriesQueryVariables>(
    MaterialCategoriesDocument,
    options,
  );
}
export type MaterialCategoriesQueryHookResult = ReturnType<typeof useMaterialCategoriesQuery>;
export type MaterialCategoriesLazyQueryHookResult = ReturnType<typeof useMaterialCategoriesLazyQuery>;
export type MaterialCategoriesQueryResult = Apollo.QueryResult<
  MaterialCategoriesQuery,
  MaterialCategoriesQueryVariables
>;
export const PartCategoriesDocument = gql`
  query PartCategories {
    categories: partCategories {
      id
      name
    }
  }
`;

/**
 * __usePartCategoriesQuery__
 *
 * To run a query within a React component, call `usePartCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<PartCategoriesQuery, PartCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartCategoriesQuery, PartCategoriesQueryVariables>(PartCategoriesDocument, options);
}
export function usePartCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartCategoriesQuery, PartCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartCategoriesQuery, PartCategoriesQueryVariables>(PartCategoriesDocument, options);
}
export type PartCategoriesQueryHookResult = ReturnType<typeof usePartCategoriesQuery>;
export type PartCategoriesLazyQueryHookResult = ReturnType<typeof usePartCategoriesLazyQuery>;
export type PartCategoriesQueryResult = Apollo.QueryResult<PartCategoriesQuery, PartCategoriesQueryVariables>;
export const WebOnlyIncentiveEnrollmentDocument = gql`
  query WebOnlyIncentiveEnrollment($leadID: ID!, $familyName: String!) {
    lead(id: $leadID) {
      visitors {
        abeetCurrentMembership(familyName: $familyName) {
          ...CurrentMembership
        }
      }
    }
  }
  ${CurrentMembershipFragmentDoc}
`;

/**
 * __useWebOnlyIncentiveEnrollmentQuery__
 *
 * To run a query within a React component, call `useWebOnlyIncentiveEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebOnlyIncentiveEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebOnlyIncentiveEnrollmentQuery({
 *   variables: {
 *      leadID: // value for 'leadID'
 *      familyName: // value for 'familyName'
 *   },
 * });
 */
export function useWebOnlyIncentiveEnrollmentQuery(
  baseOptions: Apollo.QueryHookOptions<WebOnlyIncentiveEnrollmentQuery, WebOnlyIncentiveEnrollmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebOnlyIncentiveEnrollmentQuery, WebOnlyIncentiveEnrollmentQueryVariables>(
    WebOnlyIncentiveEnrollmentDocument,
    options,
  );
}
export function useWebOnlyIncentiveEnrollmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WebOnlyIncentiveEnrollmentQuery, WebOnlyIncentiveEnrollmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebOnlyIncentiveEnrollmentQuery, WebOnlyIncentiveEnrollmentQueryVariables>(
    WebOnlyIncentiveEnrollmentDocument,
    options,
  );
}
export type WebOnlyIncentiveEnrollmentQueryHookResult = ReturnType<typeof useWebOnlyIncentiveEnrollmentQuery>;
export type WebOnlyIncentiveEnrollmentLazyQueryHookResult = ReturnType<typeof useWebOnlyIncentiveEnrollmentLazyQuery>;
export type WebOnlyIncentiveEnrollmentQueryResult = Apollo.QueryResult<
  WebOnlyIncentiveEnrollmentQuery,
  WebOnlyIncentiveEnrollmentQueryVariables
>;
export const ModifyClaimAssessmentsDocument = gql`
  mutation ModifyClaimAssessments($input: Claims__ModifyAssessments__Input!) {
    modifyClaimAssessments(input: $input) {
      error
      status
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type ModifyClaimAssessmentsMutationFn = Apollo.MutationFunction<
  ModifyClaimAssessmentsMutation,
  ModifyClaimAssessmentsMutationVariables
>;

/**
 * __useModifyClaimAssessmentsMutation__
 *
 * To run a mutation, you first call `useModifyClaimAssessmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyClaimAssessmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyClaimAssessmentsMutation, { data, loading, error }] = useModifyClaimAssessmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyClaimAssessmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyClaimAssessmentsMutation, ModifyClaimAssessmentsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyClaimAssessmentsMutation, ModifyClaimAssessmentsMutationVariables>(
    ModifyClaimAssessmentsDocument,
    options,
  );
}
export type ModifyClaimAssessmentsMutationHookResult = ReturnType<typeof useModifyClaimAssessmentsMutation>;
export type ModifyClaimAssessmentsMutationResult = Apollo.MutationResult<ModifyClaimAssessmentsMutation>;
export type ModifyClaimAssessmentsMutationOptions = Apollo.BaseMutationOptions<
  ModifyClaimAssessmentsMutation,
  ModifyClaimAssessmentsMutationVariables
>;
export const ClaimDocument = gql`
  query Claim($id: ID!) {
    claim(id: $id) {
      ...detailedClaim
    }
  }
  ${DetailedClaimFragmentDoc}
`;

/**
 * __useClaimQuery__
 *
 * To run a query within a React component, call `useClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClaimQuery(baseOptions: Apollo.QueryHookOptions<ClaimQuery, ClaimQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimQuery, ClaimQueryVariables>(ClaimDocument, options);
}
export function useClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimQuery, ClaimQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimQuery, ClaimQueryVariables>(ClaimDocument, options);
}
export type ClaimQueryHookResult = ReturnType<typeof useClaimQuery>;
export type ClaimLazyQueryHookResult = ReturnType<typeof useClaimLazyQuery>;
export type ClaimQueryResult = Apollo.QueryResult<ClaimQuery, ClaimQueryVariables>;
export const CloseClaimDocument = gql`
  mutation closeClaim($claimID: ID!, $reason: String!) {
    closeClaim(claimID: $claimID, reason: $reason) {
      error
      status
      claim {
        ...Admin__Claim
      }
    }
  }
  ${Admin__ClaimFragmentDoc}
`;
export type CloseClaimMutationFn = Apollo.MutationFunction<CloseClaimMutation, CloseClaimMutationVariables>;

/**
 * __useCloseClaimMutation__
 *
 * To run a mutation, you first call `useCloseClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeClaimMutation, { data, loading, error }] = useCloseClaimMutation({
 *   variables: {
 *      claimID: // value for 'claimID'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCloseClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseClaimMutation, CloseClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseClaimMutation, CloseClaimMutationVariables>(CloseClaimDocument, options);
}
export type CloseClaimMutationHookResult = ReturnType<typeof useCloseClaimMutation>;
export type CloseClaimMutationResult = Apollo.MutationResult<CloseClaimMutation>;
export type CloseClaimMutationOptions = Apollo.BaseMutationOptions<CloseClaimMutation, CloseClaimMutationVariables>;
export const ModifyClaimOwnerDocument = gql`
  mutation ModifyClaimOwner($claimID: ID!, $ownerID: ID) {
    modifyClaimOwner(claimID: $claimID, ownerID: $ownerID) {
      error
      status
      claim {
        ...Admin__Claim
        owner {
          ...user
        }
      }
    }
  }
  ${Admin__ClaimFragmentDoc}
  ${UserFragmentDoc}
`;
export type ModifyClaimOwnerMutationFn = Apollo.MutationFunction<
  ModifyClaimOwnerMutation,
  ModifyClaimOwnerMutationVariables
>;

/**
 * __useModifyClaimOwnerMutation__
 *
 * To run a mutation, you first call `useModifyClaimOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyClaimOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyClaimOwnerMutation, { data, loading, error }] = useModifyClaimOwnerMutation({
 *   variables: {
 *      claimID: // value for 'claimID'
 *      ownerID: // value for 'ownerID'
 *   },
 * });
 */
export function useModifyClaimOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyClaimOwnerMutation, ModifyClaimOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyClaimOwnerMutation, ModifyClaimOwnerMutationVariables>(
    ModifyClaimOwnerDocument,
    options,
  );
}
export type ModifyClaimOwnerMutationHookResult = ReturnType<typeof useModifyClaimOwnerMutation>;
export type ModifyClaimOwnerMutationResult = Apollo.MutationResult<ModifyClaimOwnerMutation>;
export type ModifyClaimOwnerMutationOptions = Apollo.BaseMutationOptions<
  ModifyClaimOwnerMutation,
  ModifyClaimOwnerMutationVariables
>;
export const AccountClaimsSummaryDocument = gql`
  query AccountClaimsSummary($accountID: ID!) {
    account(accountID: $accountID) {
      id
      claimsSummary {
        ...Account__ClaimsSummary
      }
    }
  }
  ${Account__ClaimsSummaryFragmentDoc}
`;

/**
 * __useAccountClaimsSummaryQuery__
 *
 * To run a query within a React component, call `useAccountClaimsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountClaimsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountClaimsSummaryQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountClaimsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<AccountClaimsSummaryQuery, AccountClaimsSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountClaimsSummaryQuery, AccountClaimsSummaryQueryVariables>(
    AccountClaimsSummaryDocument,
    options,
  );
}
export function useAccountClaimsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountClaimsSummaryQuery, AccountClaimsSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountClaimsSummaryQuery, AccountClaimsSummaryQueryVariables>(
    AccountClaimsSummaryDocument,
    options,
  );
}
export type AccountClaimsSummaryQueryHookResult = ReturnType<typeof useAccountClaimsSummaryQuery>;
export type AccountClaimsSummaryLazyQueryHookResult = ReturnType<typeof useAccountClaimsSummaryLazyQuery>;
export type AccountClaimsSummaryQueryResult = Apollo.QueryResult<
  AccountClaimsSummaryQuery,
  AccountClaimsSummaryQueryVariables
>;
export const ExtendOfferDocument = gql`
  mutation ExtendOffer($input: Claims__ExtendOffer__Input!) {
    extendOffer(input: $input) {
      error
      status
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type ExtendOfferMutationFn = Apollo.MutationFunction<ExtendOfferMutation, ExtendOfferMutationVariables>;

/**
 * __useExtendOfferMutation__
 *
 * To run a mutation, you first call `useExtendOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendOfferMutation, { data, loading, error }] = useExtendOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtendOfferMutation, ExtendOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendOfferMutation, ExtendOfferMutationVariables>(ExtendOfferDocument, options);
}
export type ExtendOfferMutationHookResult = ReturnType<typeof useExtendOfferMutation>;
export type ExtendOfferMutationResult = Apollo.MutationResult<ExtendOfferMutation>;
export type ExtendOfferMutationOptions = Apollo.BaseMutationOptions<ExtendOfferMutation, ExtendOfferMutationVariables>;
export const SaveOfferDocument = gql`
  mutation SaveOffer($input: Claims__SaveOffer__Input!) {
    saveOffer(input: $input) {
      error
      status
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type SaveOfferMutationFn = Apollo.MutationFunction<SaveOfferMutation, SaveOfferMutationVariables>;

/**
 * __useSaveOfferMutation__
 *
 * To run a mutation, you first call `useSaveOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOfferMutation, { data, loading, error }] = useSaveOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveOfferMutation, SaveOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveOfferMutation, SaveOfferMutationVariables>(SaveOfferDocument, options);
}
export type SaveOfferMutationHookResult = ReturnType<typeof useSaveOfferMutation>;
export type SaveOfferMutationResult = Apollo.MutationResult<SaveOfferMutation>;
export type SaveOfferMutationOptions = Apollo.BaseMutationOptions<SaveOfferMutation, SaveOfferMutationVariables>;
export const ReopenClaimDocument = gql`
  mutation ReopenClaim($claimID: ID!) {
    reopenClaim(claimID: $claimID) {
      error
      status
      claim {
        ...Admin__Claim
        issueSelection {
          ...Claim__IssueSelection
        }
        itemSelections {
          ...Claim__ItemSelection
        }
        propertySelection {
          ...Claim__PropertySelection
        }
      }
    }
  }
  ${Admin__ClaimFragmentDoc}
  ${Claim__IssueSelectionFragmentDoc}
  ${Claim__ItemSelectionFragmentDoc}
  ${Claim__PropertySelectionFragmentDoc}
`;
export type ReopenClaimMutationFn = Apollo.MutationFunction<ReopenClaimMutation, ReopenClaimMutationVariables>;

/**
 * __useReopenClaimMutation__
 *
 * To run a mutation, you first call `useReopenClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenClaimMutation, { data, loading, error }] = useReopenClaimMutation({
 *   variables: {
 *      claimID: // value for 'claimID'
 *   },
 * });
 */
export function useReopenClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<ReopenClaimMutation, ReopenClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReopenClaimMutation, ReopenClaimMutationVariables>(ReopenClaimDocument, options);
}
export type ReopenClaimMutationHookResult = ReturnType<typeof useReopenClaimMutation>;
export type ReopenClaimMutationResult = Apollo.MutationResult<ReopenClaimMutation>;
export type ReopenClaimMutationOptions = Apollo.BaseMutationOptions<ReopenClaimMutation, ReopenClaimMutationVariables>;
export const VerifyClaimDocument = gql`
  mutation VerifyClaim($input: Claim__Verify__Input!) {
    verifyClaim(input: $input) {
      error
      status
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type VerifyClaimMutationFn = Apollo.MutationFunction<VerifyClaimMutation, VerifyClaimMutationVariables>;

/**
 * __useVerifyClaimMutation__
 *
 * To run a mutation, you first call `useVerifyClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyClaimMutation, { data, loading, error }] = useVerifyClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyClaimMutation, VerifyClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyClaimMutation, VerifyClaimMutationVariables>(VerifyClaimDocument, options);
}
export type VerifyClaimMutationHookResult = ReturnType<typeof useVerifyClaimMutation>;
export type VerifyClaimMutationResult = Apollo.MutationResult<VerifyClaimMutation>;
export type VerifyClaimMutationOptions = Apollo.BaseMutationOptions<VerifyClaimMutation, VerifyClaimMutationVariables>;
export const PaginatedClaimsDocument = gql`
  query PaginatedClaims($filters: Claim__FiltersInput!, $page: Int, $numPer: Int) {
    paginatedClaims(filters: $filters, page: $page, numPer: $numPer) {
      results {
        ...Admin__PaginatedClaim
      }
      pagination {
        ...pagination
      }
    }
  }
  ${Admin__PaginatedClaimFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedClaimsQuery__
 *
 * To run a query within a React component, call `usePaginatedClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedClaimsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      numPer: // value for 'numPer'
 *   },
 * });
 */
export function usePaginatedClaimsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedClaimsQuery, PaginatedClaimsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedClaimsQuery, PaginatedClaimsQueryVariables>(PaginatedClaimsDocument, options);
}
export function usePaginatedClaimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedClaimsQuery, PaginatedClaimsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedClaimsQuery, PaginatedClaimsQueryVariables>(PaginatedClaimsDocument, options);
}
export type PaginatedClaimsQueryHookResult = ReturnType<typeof usePaginatedClaimsQuery>;
export type PaginatedClaimsLazyQueryHookResult = ReturnType<typeof usePaginatedClaimsLazyQuery>;
export type PaginatedClaimsQueryResult = Apollo.QueryResult<PaginatedClaimsQuery, PaginatedClaimsQueryVariables>;
export const ContractorPasswordResetRequestApproveDocument = gql`
  mutation ContractorPasswordResetRequestApprove($input: ContractorPasswordResetRequest__Approve__Input!) {
    result: contractorPasswordResetRequestApprove(input: $input) {
      error
      status
    }
  }
`;
export type ContractorPasswordResetRequestApproveMutationFn = Apollo.MutationFunction<
  ContractorPasswordResetRequestApproveMutation,
  ContractorPasswordResetRequestApproveMutationVariables
>;

/**
 * __useContractorPasswordResetRequestApproveMutation__
 *
 * To run a mutation, you first call `useContractorPasswordResetRequestApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractorPasswordResetRequestApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractorPasswordResetRequestApproveMutation, { data, loading, error }] = useContractorPasswordResetRequestApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorPasswordResetRequestApproveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractorPasswordResetRequestApproveMutation,
    ContractorPasswordResetRequestApproveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContractorPasswordResetRequestApproveMutation,
    ContractorPasswordResetRequestApproveMutationVariables
  >(ContractorPasswordResetRequestApproveDocument, options);
}
export type ContractorPasswordResetRequestApproveMutationHookResult = ReturnType<
  typeof useContractorPasswordResetRequestApproveMutation
>;
export type ContractorPasswordResetRequestApproveMutationResult =
  Apollo.MutationResult<ContractorPasswordResetRequestApproveMutation>;
export type ContractorPasswordResetRequestApproveMutationOptions = Apollo.BaseMutationOptions<
  ContractorPasswordResetRequestApproveMutation,
  ContractorPasswordResetRequestApproveMutationVariables
>;
export const ContractorPasswordResetRequestDocument = gql`
  query contractorPasswordResetRequest($id: ID!) {
    result: contractorPasswordResetRequest(id: $id) {
      id
      user {
        id
        email
        name
      }
    }
  }
`;

/**
 * __useContractorPasswordResetRequestQuery__
 *
 * To run a query within a React component, call `useContractorPasswordResetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorPasswordResetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorPasswordResetRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractorPasswordResetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractorPasswordResetRequestQuery,
    ContractorPasswordResetRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractorPasswordResetRequestQuery, ContractorPasswordResetRequestQueryVariables>(
    ContractorPasswordResetRequestDocument,
    options,
  );
}
export function useContractorPasswordResetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractorPasswordResetRequestQuery,
    ContractorPasswordResetRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractorPasswordResetRequestQuery, ContractorPasswordResetRequestQueryVariables>(
    ContractorPasswordResetRequestDocument,
    options,
  );
}
export type ContractorPasswordResetRequestQueryHookResult = ReturnType<typeof useContractorPasswordResetRequestQuery>;
export type ContractorPasswordResetRequestLazyQueryHookResult = ReturnType<
  typeof useContractorPasswordResetRequestLazyQuery
>;
export type ContractorPasswordResetRequestQueryResult = Apollo.QueryResult<
  ContractorPasswordResetRequestQuery,
  ContractorPasswordResetRequestQueryVariables
>;
export const CouponActivateDocument = gql`
  mutation CouponActivate($id: ID!) {
    result: couponActivate(id: $id) {
      coupon {
        id
        active
      }
    }
  }
`;
export type CouponActivateMutationFn = Apollo.MutationFunction<CouponActivateMutation, CouponActivateMutationVariables>;

/**
 * __useCouponActivateMutation__
 *
 * To run a mutation, you first call `useCouponActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponActivateMutation, { data, loading, error }] = useCouponActivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<CouponActivateMutation, CouponActivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CouponActivateMutation, CouponActivateMutationVariables>(CouponActivateDocument, options);
}
export type CouponActivateMutationHookResult = ReturnType<typeof useCouponActivateMutation>;
export type CouponActivateMutationResult = Apollo.MutationResult<CouponActivateMutation>;
export type CouponActivateMutationOptions = Apollo.BaseMutationOptions<
  CouponActivateMutation,
  CouponActivateMutationVariables
>;
export const CouponCreateDocument = gql`
  mutation CouponCreate($input: Coupon__Input!) {
    result: couponCreate(input: $input) {
      coupon {
        id
      }
      error
      status
    }
  }
`;
export type CouponCreateMutationFn = Apollo.MutationFunction<CouponCreateMutation, CouponCreateMutationVariables>;

/**
 * __useCouponCreateMutation__
 *
 * To run a mutation, you first call `useCouponCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponCreateMutation, { data, loading, error }] = useCouponCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCouponCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<CouponCreateMutation, CouponCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CouponCreateMutation, CouponCreateMutationVariables>(CouponCreateDocument, options);
}
export type CouponCreateMutationHookResult = ReturnType<typeof useCouponCreateMutation>;
export type CouponCreateMutationResult = Apollo.MutationResult<CouponCreateMutation>;
export type CouponCreateMutationOptions = Apollo.BaseMutationOptions<
  CouponCreateMutation,
  CouponCreateMutationVariables
>;
export const CouponDeactivateDocument = gql`
  mutation CouponDeactivate($id: ID!) {
    result: couponDeactivate(id: $id) {
      coupon {
        id
        active
      }
    }
  }
`;
export type CouponDeactivateMutationFn = Apollo.MutationFunction<
  CouponDeactivateMutation,
  CouponDeactivateMutationVariables
>;

/**
 * __useCouponDeactivateMutation__
 *
 * To run a mutation, you first call `useCouponDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponDeactivateMutation, { data, loading, error }] = useCouponDeactivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<CouponDeactivateMutation, CouponDeactivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CouponDeactivateMutation, CouponDeactivateMutationVariables>(
    CouponDeactivateDocument,
    options,
  );
}
export type CouponDeactivateMutationHookResult = ReturnType<typeof useCouponDeactivateMutation>;
export type CouponDeactivateMutationResult = Apollo.MutationResult<CouponDeactivateMutation>;
export type CouponDeactivateMutationOptions = Apollo.BaseMutationOptions<
  CouponDeactivateMutation,
  CouponDeactivateMutationVariables
>;
export const CouponDocument = gql`
  query Coupon($id: ID!) {
    coupon(id: $id) {
      ...Admin__Coupon
    }
  }
  ${Admin__CouponFragmentDoc}
`;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
}
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
}
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const CouponUpdateDocument = gql`
  mutation CouponUpdate($id: ID!, $input: Coupon__Input!) {
    result: couponUpdate(id: $id, input: $input) {
      coupon {
        id
      }
      error
      status
    }
  }
`;
export type CouponUpdateMutationFn = Apollo.MutationFunction<CouponUpdateMutation, CouponUpdateMutationVariables>;

/**
 * __useCouponUpdateMutation__
 *
 * To run a mutation, you first call `useCouponUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponUpdateMutation, { data, loading, error }] = useCouponUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCouponUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<CouponUpdateMutation, CouponUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CouponUpdateMutation, CouponUpdateMutationVariables>(CouponUpdateDocument, options);
}
export type CouponUpdateMutationHookResult = ReturnType<typeof useCouponUpdateMutation>;
export type CouponUpdateMutationResult = Apollo.MutationResult<CouponUpdateMutation>;
export type CouponUpdateMutationOptions = Apollo.BaseMutationOptions<
  CouponUpdateMutation,
  CouponUpdateMutationVariables
>;
export const PaginatedCouponsDocument = gql`
  query PaginatedCoupons($page: Int, $filters: Coupon__FiltersInput) {
    paginatedCoupons(page: $page, filters: $filters) {
      results {
        ...Admin__Coupon
      }
      pagination {
        ...pagination
      }
    }
  }
  ${Admin__CouponFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedCouponsQuery__
 *
 * To run a query within a React component, call `usePaginatedCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCouponsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>(PaginatedCouponsDocument, options);
}
export function usePaginatedCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>(PaginatedCouponsDocument, options);
}
export type PaginatedCouponsQueryHookResult = ReturnType<typeof usePaginatedCouponsQuery>;
export type PaginatedCouponsLazyQueryHookResult = ReturnType<typeof usePaginatedCouponsLazyQuery>;
export type PaginatedCouponsQueryResult = Apollo.QueryResult<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>;
export const CouponsDocument = gql`
  query coupons($filters: Coupon__FiltersInput) {
    result: coupons(filters: $filters) {
      id
      name
      code
    }
  }
`;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
}
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
}
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const BuildCustomerTicketDocument = gql`
  mutation BuildCustomerTicket($input: CustomerTickets__BuildInput!) {
    buildCustomerTicket(input: $input) {
      customerTicket {
        id
      }
      status
      error
    }
  }
`;
export type BuildCustomerTicketMutationFn = Apollo.MutationFunction<
  BuildCustomerTicketMutation,
  BuildCustomerTicketMutationVariables
>;

/**
 * __useBuildCustomerTicketMutation__
 *
 * To run a mutation, you first call `useBuildCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildCustomerTicketMutation, { data, loading, error }] = useBuildCustomerTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildCustomerTicketMutation, BuildCustomerTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildCustomerTicketMutation, BuildCustomerTicketMutationVariables>(
    BuildCustomerTicketDocument,
    options,
  );
}
export type BuildCustomerTicketMutationHookResult = ReturnType<typeof useBuildCustomerTicketMutation>;
export type BuildCustomerTicketMutationResult = Apollo.MutationResult<BuildCustomerTicketMutation>;
export type BuildCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  BuildCustomerTicketMutation,
  BuildCustomerTicketMutationVariables
>;
export const CustomerTicketDocument = gql`
  query CustomerTicket($id: ID!) {
    customerTicket(id: $id) {
      ...customerTicket
      interactions {
        ...customerInteraction
      }
      leadInteractions {
        ...leadInteraction
      }
      zendeskTicketAssociations {
        ...zendeskTicketAssociation
      }
      files {
        ...attachment
      }
    }
  }
  ${CustomerTicketFragmentDoc}
  ${CustomerInteractionFragmentDoc}
  ${LeadInteractionFragmentDoc}
  ${ZendeskTicketAssociationFragmentDoc}
  ${AttachmentFragmentDoc}
`;

/**
 * __useCustomerTicketQuery__
 *
 * To run a query within a React component, call `useCustomerTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerTicketQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerTicketQuery, CustomerTicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerTicketQuery, CustomerTicketQueryVariables>(CustomerTicketDocument, options);
}
export function useCustomerTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerTicketQuery, CustomerTicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerTicketQuery, CustomerTicketQueryVariables>(CustomerTicketDocument, options);
}
export type CustomerTicketQueryHookResult = ReturnType<typeof useCustomerTicketQuery>;
export type CustomerTicketLazyQueryHookResult = ReturnType<typeof useCustomerTicketLazyQuery>;
export type CustomerTicketQueryResult = Apollo.QueryResult<CustomerTicketQuery, CustomerTicketQueryVariables>;
export const CustomerTicketGroupsDocument = gql`
  query CustomerTicketGroups {
    customerTicketGroups {
      ...ticketGroup
    }
  }
  ${TicketGroupFragmentDoc}
`;

/**
 * __useCustomerTicketGroupsQuery__
 *
 * To run a query within a React component, call `useCustomerTicketGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTicketGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTicketGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerTicketGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerTicketGroupsQuery, CustomerTicketGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerTicketGroupsQuery, CustomerTicketGroupsQueryVariables>(
    CustomerTicketGroupsDocument,
    options,
  );
}
export function useCustomerTicketGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerTicketGroupsQuery, CustomerTicketGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerTicketGroupsQuery, CustomerTicketGroupsQueryVariables>(
    CustomerTicketGroupsDocument,
    options,
  );
}
export type CustomerTicketGroupsQueryHookResult = ReturnType<typeof useCustomerTicketGroupsQuery>;
export type CustomerTicketGroupsLazyQueryHookResult = ReturnType<typeof useCustomerTicketGroupsLazyQuery>;
export type CustomerTicketGroupsQueryResult = Apollo.QueryResult<
  CustomerTicketGroupsQuery,
  CustomerTicketGroupsQueryVariables
>;
export const ModifyCustomerTicketDocument = gql`
  mutation ModifyCustomerTicket($input: CustomerTickets__ModifyInput!) {
    modifyCustomerTicket(input: $input) {
      status
      error
      customerTicket {
        ...customerTicket
      }
    }
  }
  ${CustomerTicketFragmentDoc}
`;
export type ModifyCustomerTicketMutationFn = Apollo.MutationFunction<
  ModifyCustomerTicketMutation,
  ModifyCustomerTicketMutationVariables
>;

/**
 * __useModifyCustomerTicketMutation__
 *
 * To run a mutation, you first call `useModifyCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCustomerTicketMutation, { data, loading, error }] = useModifyCustomerTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyCustomerTicketMutation, ModifyCustomerTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyCustomerTicketMutation, ModifyCustomerTicketMutationVariables>(
    ModifyCustomerTicketDocument,
    options,
  );
}
export type ModifyCustomerTicketMutationHookResult = ReturnType<typeof useModifyCustomerTicketMutation>;
export type ModifyCustomerTicketMutationResult = Apollo.MutationResult<ModifyCustomerTicketMutation>;
export type ModifyCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  ModifyCustomerTicketMutation,
  ModifyCustomerTicketMutationVariables
>;
export const PaginatedCustomerTicketListDocument = gql`
  query PaginatedCustomerTicketList(
    $filters: CustomerTicketFiltersInput
    $sort: CustomerTicketSortInput
    $page: Int
    $numPer: Int
  ) {
    paginatedCustomerTicketList(filters: $filters, sort: $sort, page: $page, numPer: $numPer) {
      results {
        ...customerTicket
      }
      pagination {
        ...pagination
      }
    }
  }
  ${CustomerTicketFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedCustomerTicketListQuery__
 *
 * To run a query within a React component, call `usePaginatedCustomerTicketListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCustomerTicketListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCustomerTicketListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *      numPer: // value for 'numPer'
 *   },
 * });
 */
export function usePaginatedCustomerTicketListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedCustomerTicketListQuery, PaginatedCustomerTicketListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedCustomerTicketListQuery, PaginatedCustomerTicketListQueryVariables>(
    PaginatedCustomerTicketListDocument,
    options,
  );
}
export function usePaginatedCustomerTicketListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedCustomerTicketListQuery,
    PaginatedCustomerTicketListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedCustomerTicketListQuery, PaginatedCustomerTicketListQueryVariables>(
    PaginatedCustomerTicketListDocument,
    options,
  );
}
export type PaginatedCustomerTicketListQueryHookResult = ReturnType<typeof usePaginatedCustomerTicketListQuery>;
export type PaginatedCustomerTicketListLazyQueryHookResult = ReturnType<typeof usePaginatedCustomerTicketListLazyQuery>;
export type PaginatedCustomerTicketListQueryResult = Apollo.QueryResult<
  PaginatedCustomerTicketListQuery,
  PaginatedCustomerTicketListQueryVariables
>;
export const ReopenCustomerTicketDocument = gql`
  mutation ReopenCustomerTicket($id: ID!, $actionRequiredAt: DateTime!) {
    reopenCustomerTicket(id: $id, actionRequiredAt: $actionRequiredAt) {
      status
      error
      customerTicket {
        ...customerTicket
      }
    }
  }
  ${CustomerTicketFragmentDoc}
`;
export type ReopenCustomerTicketMutationFn = Apollo.MutationFunction<
  ReopenCustomerTicketMutation,
  ReopenCustomerTicketMutationVariables
>;

/**
 * __useReopenCustomerTicketMutation__
 *
 * To run a mutation, you first call `useReopenCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenCustomerTicketMutation, { data, loading, error }] = useReopenCustomerTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      actionRequiredAt: // value for 'actionRequiredAt'
 *   },
 * });
 */
export function useReopenCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<ReopenCustomerTicketMutation, ReopenCustomerTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReopenCustomerTicketMutation, ReopenCustomerTicketMutationVariables>(
    ReopenCustomerTicketDocument,
    options,
  );
}
export type ReopenCustomerTicketMutationHookResult = ReturnType<typeof useReopenCustomerTicketMutation>;
export type ReopenCustomerTicketMutationResult = Apollo.MutationResult<ReopenCustomerTicketMutation>;
export type ReopenCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  ReopenCustomerTicketMutation,
  ReopenCustomerTicketMutationVariables
>;
export const ResolveCustomerTicketDocument = gql`
  mutation ResolveCustomerTicket($id: ID!) {
    resolveCustomerTicket(id: $id) {
      status
      error
      customerTicket {
        ...customerTicket
      }
    }
  }
  ${CustomerTicketFragmentDoc}
`;
export type ResolveCustomerTicketMutationFn = Apollo.MutationFunction<
  ResolveCustomerTicketMutation,
  ResolveCustomerTicketMutationVariables
>;

/**
 * __useResolveCustomerTicketMutation__
 *
 * To run a mutation, you first call `useResolveCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveCustomerTicketMutation, { data, loading, error }] = useResolveCustomerTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResolveCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<ResolveCustomerTicketMutation, ResolveCustomerTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveCustomerTicketMutation, ResolveCustomerTicketMutationVariables>(
    ResolveCustomerTicketDocument,
    options,
  );
}
export type ResolveCustomerTicketMutationHookResult = ReturnType<typeof useResolveCustomerTicketMutation>;
export type ResolveCustomerTicketMutationResult = Apollo.MutationResult<ResolveCustomerTicketMutation>;
export type ResolveCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  ResolveCustomerTicketMutation,
  ResolveCustomerTicketMutationVariables
>;
export const BuildZendeskTicketDocument = gql`
  mutation BuildZendeskTicket($customerTicketID: ID!) {
    buildZendeskTicket(customerTicketID: $customerTicketID) {
      zendeskTicketAssociation {
        ...zendeskTicketAssociation
      }
      status
      error
    }
  }
  ${ZendeskTicketAssociationFragmentDoc}
`;
export type BuildZendeskTicketMutationFn = Apollo.MutationFunction<
  BuildZendeskTicketMutation,
  BuildZendeskTicketMutationVariables
>;

/**
 * __useBuildZendeskTicketMutation__
 *
 * To run a mutation, you first call `useBuildZendeskTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildZendeskTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildZendeskTicketMutation, { data, loading, error }] = useBuildZendeskTicketMutation({
 *   variables: {
 *      customerTicketID: // value for 'customerTicketID'
 *   },
 * });
 */
export function useBuildZendeskTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildZendeskTicketMutation, BuildZendeskTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildZendeskTicketMutation, BuildZendeskTicketMutationVariables>(
    BuildZendeskTicketDocument,
    options,
  );
}
export type BuildZendeskTicketMutationHookResult = ReturnType<typeof useBuildZendeskTicketMutation>;
export type BuildZendeskTicketMutationResult = Apollo.MutationResult<BuildZendeskTicketMutation>;
export type BuildZendeskTicketMutationOptions = Apollo.BaseMutationOptions<
  BuildZendeskTicketMutation,
  BuildZendeskTicketMutationVariables
>;
export const ZendeskTicketDocument = gql`
  query ZendeskTicket($zdTicketID: ID!) {
    zendeskTicket(zdTicketID: $zdTicketID) {
      id
      subject
      comments {
        id
        body
        createdAt
        author {
          id
          name
          email
        }
      }
    }
  }
`;

/**
 * __useZendeskTicketQuery__
 *
 * To run a query within a React component, call `useZendeskTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useZendeskTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZendeskTicketQuery({
 *   variables: {
 *      zdTicketID: // value for 'zdTicketID'
 *   },
 * });
 */
export function useZendeskTicketQuery(
  baseOptions: Apollo.QueryHookOptions<ZendeskTicketQuery, ZendeskTicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZendeskTicketQuery, ZendeskTicketQueryVariables>(ZendeskTicketDocument, options);
}
export function useZendeskTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZendeskTicketQuery, ZendeskTicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZendeskTicketQuery, ZendeskTicketQueryVariables>(ZendeskTicketDocument, options);
}
export type ZendeskTicketQueryHookResult = ReturnType<typeof useZendeskTicketQuery>;
export type ZendeskTicketLazyQueryHookResult = ReturnType<typeof useZendeskTicketLazyQuery>;
export type ZendeskTicketQueryResult = Apollo.QueryResult<ZendeskTicketQuery, ZendeskTicketQueryVariables>;
export const DamagedItemsDocument = gql`
  query DamagedItems($orderID: ID!) {
    order(orderID: $orderID) {
      id
      damagedItems {
        id
        kind
        reason
        timestamp
        user {
          name
        }
        images {
          id
          source
          size
        }
      }
      region {
        id
        tz
      }
    }
  }
`;

/**
 * __useDamagedItemsQuery__
 *
 * To run a query within a React component, call `useDamagedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDamagedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDamagedItemsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useDamagedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<DamagedItemsQuery, DamagedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DamagedItemsQuery, DamagedItemsQueryVariables>(DamagedItemsDocument, options);
}
export function useDamagedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DamagedItemsQuery, DamagedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DamagedItemsQuery, DamagedItemsQueryVariables>(DamagedItemsDocument, options);
}
export type DamagedItemsQueryHookResult = ReturnType<typeof useDamagedItemsQuery>;
export type DamagedItemsLazyQueryHookResult = ReturnType<typeof useDamagedItemsLazyQuery>;
export type DamagedItemsQueryResult = Apollo.QueryResult<DamagedItemsQuery, DamagedItemsQueryVariables>;
export const BuildDepotDocument = gql`
  mutation BuildDepot($input: DepotInput!) {
    buildDepot(input: $input) {
      error
    }
  }
`;
export type BuildDepotMutationFn = Apollo.MutationFunction<BuildDepotMutation, BuildDepotMutationVariables>;

/**
 * __useBuildDepotMutation__
 *
 * To run a mutation, you first call `useBuildDepotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildDepotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildDepotMutation, { data, loading, error }] = useBuildDepotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildDepotMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildDepotMutation, BuildDepotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildDepotMutation, BuildDepotMutationVariables>(BuildDepotDocument, options);
}
export type BuildDepotMutationHookResult = ReturnType<typeof useBuildDepotMutation>;
export type BuildDepotMutationResult = Apollo.MutationResult<BuildDepotMutation>;
export type BuildDepotMutationOptions = Apollo.BaseMutationOptions<BuildDepotMutation, BuildDepotMutationVariables>;
export const DepotDocument = gql`
  query Depot($id: ID!) {
    depot(id: $id) {
      id
      name
      abbreviation
      active
      address {
        street
        aptsuite
        city
        state
        country
        zip
        latitude
        longitude
      }
      regions {
        id
      }
    }
  }
`;

/**
 * __useDepotQuery__
 *
 * To run a query within a React component, call `useDepotQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepotQuery(baseOptions: Apollo.QueryHookOptions<DepotQuery, DepotQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DepotQuery, DepotQueryVariables>(DepotDocument, options);
}
export function useDepotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepotQuery, DepotQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DepotQuery, DepotQueryVariables>(DepotDocument, options);
}
export type DepotQueryHookResult = ReturnType<typeof useDepotQuery>;
export type DepotLazyQueryHookResult = ReturnType<typeof useDepotLazyQuery>;
export type DepotQueryResult = Apollo.QueryResult<DepotQuery, DepotQueryVariables>;
export const DepotsDocument = gql`
  query Depots($filters: FacilityFiltersInput) {
    depots(filters: $filters) {
      id
      name
      abbreviation
      active
      address {
        street
        aptsuite
        city
        state
        zip
      }
      regions {
        name
      }
    }
  }
`;

/**
 * __useDepotsQuery__
 *
 * To run a query within a React component, call `useDepotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepotsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDepotsQuery(baseOptions?: Apollo.QueryHookOptions<DepotsQuery, DepotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DepotsQuery, DepotsQueryVariables>(DepotsDocument, options);
}
export function useDepotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepotsQuery, DepotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DepotsQuery, DepotsQueryVariables>(DepotsDocument, options);
}
export type DepotsQueryHookResult = ReturnType<typeof useDepotsQuery>;
export type DepotsLazyQueryHookResult = ReturnType<typeof useDepotsLazyQuery>;
export type DepotsQueryResult = Apollo.QueryResult<DepotsQuery, DepotsQueryVariables>;
export const ModifyDepotDocument = gql`
  mutation ModifyDepot($input: DepotInput!, $id: ID!) {
    modifyDepot(input: $input, id: $id) {
      error
    }
  }
`;
export type ModifyDepotMutationFn = Apollo.MutationFunction<ModifyDepotMutation, ModifyDepotMutationVariables>;

/**
 * __useModifyDepotMutation__
 *
 * To run a mutation, you first call `useModifyDepotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyDepotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyDepotMutation, { data, loading, error }] = useModifyDepotMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyDepotMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyDepotMutation, ModifyDepotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyDepotMutation, ModifyDepotMutationVariables>(ModifyDepotDocument, options);
}
export type ModifyDepotMutationHookResult = ReturnType<typeof useModifyDepotMutation>;
export type ModifyDepotMutationResult = Apollo.MutationResult<ModifyDepotMutation>;
export type ModifyDepotMutationOptions = Apollo.BaseMutationOptions<ModifyDepotMutation, ModifyDepotMutationVariables>;
export const BuildMessageDocument = gql`
  mutation BuildMessage($input: MessageInput!) {
    buildMessage(input: $input) {
      error
    }
  }
`;
export type BuildMessageMutationFn = Apollo.MutationFunction<BuildMessageMutation, BuildMessageMutationVariables>;

/**
 * __useBuildMessageMutation__
 *
 * To run a mutation, you first call `useBuildMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildMessageMutation, { data, loading, error }] = useBuildMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildMessageMutation, BuildMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildMessageMutation, BuildMessageMutationVariables>(BuildMessageDocument, options);
}
export type BuildMessageMutationHookResult = ReturnType<typeof useBuildMessageMutation>;
export type BuildMessageMutationResult = Apollo.MutationResult<BuildMessageMutation>;
export type BuildMessageMutationOptions = Apollo.BaseMutationOptions<
  BuildMessageMutation,
  BuildMessageMutationVariables
>;
export const DispatchCommunicationsDocument = gql`
  query DispatchCommunications($dispatchID: ID!, $page: Int) {
    dispatch(dispatchID: $dispatchID) {
      id
      arrival
      region {
        id
        tz
      }
      callables {
        name
        role
        number
        phoneId
        assignmentId
      }
      paginatedCommunications(page: $page) {
        results {
          ... on Message {
            ...message
          }
          ... on PhoneCall {
            ...detailedPhoneCall
          }
        }
        pagination {
          ...pagination
        }
      }
    }
  }
  ${MessageFragmentDoc}
  ${DetailedPhoneCallFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useDispatchCommunicationsQuery__
 *
 * To run a query within a React component, call `useDispatchCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchCommunicationsQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDispatchCommunicationsQuery(
  baseOptions: Apollo.QueryHookOptions<DispatchCommunicationsQuery, DispatchCommunicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchCommunicationsQuery, DispatchCommunicationsQueryVariables>(
    DispatchCommunicationsDocument,
    options,
  );
}
export function useDispatchCommunicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchCommunicationsQuery, DispatchCommunicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchCommunicationsQuery, DispatchCommunicationsQueryVariables>(
    DispatchCommunicationsDocument,
    options,
  );
}
export type DispatchCommunicationsQueryHookResult = ReturnType<typeof useDispatchCommunicationsQuery>;
export type DispatchCommunicationsLazyQueryHookResult = ReturnType<typeof useDispatchCommunicationsLazyQuery>;
export type DispatchCommunicationsQueryResult = Apollo.QueryResult<
  DispatchCommunicationsQuery,
  DispatchCommunicationsQueryVariables
>;
export const DispatchPreTripAssessmentDocument = gql`
  query DispatchPreTripAssessment($dispatchID: ID!) {
    dispatch(dispatchID: $dispatchID) {
      preTripAssessment {
        other
        unresolvedSelections {
          preTripItem {
            name
          }
          preTripOption {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useDispatchPreTripAssessmentQuery__
 *
 * To run a query within a React component, call `useDispatchPreTripAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchPreTripAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchPreTripAssessmentQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatchPreTripAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<DispatchPreTripAssessmentQuery, DispatchPreTripAssessmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchPreTripAssessmentQuery, DispatchPreTripAssessmentQueryVariables>(
    DispatchPreTripAssessmentDocument,
    options,
  );
}
export function useDispatchPreTripAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchPreTripAssessmentQuery, DispatchPreTripAssessmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchPreTripAssessmentQuery, DispatchPreTripAssessmentQueryVariables>(
    DispatchPreTripAssessmentDocument,
    options,
  );
}
export type DispatchPreTripAssessmentQueryHookResult = ReturnType<typeof useDispatchPreTripAssessmentQuery>;
export type DispatchPreTripAssessmentLazyQueryHookResult = ReturnType<typeof useDispatchPreTripAssessmentLazyQuery>;
export type DispatchPreTripAssessmentQueryResult = Apollo.QueryResult<
  DispatchPreTripAssessmentQuery,
  DispatchPreTripAssessmentQueryVariables
>;
export const DispatchAuditOrderEventsDocument = gql`
  query DispatchAuditOrderEvents($dispatchID: ID!) {
    dispatchAuditOrderEvents(dispatchID: $dispatchID) {
      ...auditOrderEvent
    }
  }
  ${AuditOrderEventFragmentDoc}
`;

/**
 * __useDispatchAuditOrderEventsQuery__
 *
 * To run a query within a React component, call `useDispatchAuditOrderEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchAuditOrderEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchAuditOrderEventsQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatchAuditOrderEventsQuery(
  baseOptions: Apollo.QueryHookOptions<DispatchAuditOrderEventsQuery, DispatchAuditOrderEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchAuditOrderEventsQuery, DispatchAuditOrderEventsQueryVariables>(
    DispatchAuditOrderEventsDocument,
    options,
  );
}
export function useDispatchAuditOrderEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchAuditOrderEventsQuery, DispatchAuditOrderEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchAuditOrderEventsQuery, DispatchAuditOrderEventsQueryVariables>(
    DispatchAuditOrderEventsDocument,
    options,
  );
}
export type DispatchAuditOrderEventsQueryHookResult = ReturnType<typeof useDispatchAuditOrderEventsQuery>;
export type DispatchAuditOrderEventsLazyQueryHookResult = ReturnType<typeof useDispatchAuditOrderEventsLazyQuery>;
export type DispatchAuditOrderEventsQueryResult = Apollo.QueryResult<
  DispatchAuditOrderEventsQuery,
  DispatchAuditOrderEventsQueryVariables
>;
export const DispatchDocument = gql`
  query Dispatch($dispatchID: ID!, $orderFilterInput: OrderFilterInput) {
    dispatch(dispatchID: $dispatchID) {
      id
      arrival
      cutoff
      clockout
      region {
        id
        tz
      }
      orders(filter: $orderFilterInput) {
        id
        parentID
        scheduled
        internalScheduled
        name
        slaWindowEnd
        status
        expectedStartTime
        formattedArrivalWindow
        orderEventsExcludingNonLeadAssignments {
          id
          category
          eventName
          timestamp
          isBreakEvent
        }
        simulatedOrderEvents {
          id
          category
          eventName
          timestamp
          isBreakEvent
        }
        services {
          id
          type
          cuft
          estimatedCuft
          estimatedCuftPerHourPerMover
          cuftPerHourPerMover
          started
        }
      }
    }
  }
`;

/**
 * __useDispatchQuery__
 *
 * To run a query within a React component, call `useDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *      orderFilterInput: // value for 'orderFilterInput'
 *   },
 * });
 */
export function useDispatchQuery(baseOptions: Apollo.QueryHookOptions<DispatchQuery, DispatchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchQuery, DispatchQueryVariables>(DispatchDocument, options);
}
export function useDispatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DispatchQuery, DispatchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchQuery, DispatchQueryVariables>(DispatchDocument, options);
}
export type DispatchQueryHookResult = ReturnType<typeof useDispatchQuery>;
export type DispatchLazyQueryHookResult = ReturnType<typeof useDispatchLazyQuery>;
export type DispatchQueryResult = Apollo.QueryResult<DispatchQuery, DispatchQueryVariables>;
export const DispatchVehicleSealImagesDocument = gql`
  query DispatchVehicleSealImages($dispatchID: ID!) {
    dispatch(dispatchID: $dispatchID) {
      id
      vehicleRecipe {
        id
        minQuantity
        maxQuantity
        minimumCuft
        notes
        allowedVehicleTypes {
          id
          name
        }
      }
      vehiclePreferences {
        id
        vehicleType {
          id
          name
        }
        quantity
      }
      dispatchesVehicles {
        sealAt
        user {
          id
          name
        }
        vehicle {
          id
          serialCode
        }
        sealImage {
          id
          source
          size
        }
      }
      tasks {
        user {
          id
          name
        }
        vehicle {
          id
          serialCode
        }
        image {
          id
          source
          size
          createdAt
        }
        kind
      }
      region {
        id
        tz
      }
    }
  }
`;

/**
 * __useDispatchVehicleSealImagesQuery__
 *
 * To run a query within a React component, call `useDispatchVehicleSealImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchVehicleSealImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchVehicleSealImagesQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatchVehicleSealImagesQuery(
  baseOptions: Apollo.QueryHookOptions<DispatchVehicleSealImagesQuery, DispatchVehicleSealImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchVehicleSealImagesQuery, DispatchVehicleSealImagesQueryVariables>(
    DispatchVehicleSealImagesDocument,
    options,
  );
}
export function useDispatchVehicleSealImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchVehicleSealImagesQuery, DispatchVehicleSealImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchVehicleSealImagesQuery, DispatchVehicleSealImagesQueryVariables>(
    DispatchVehicleSealImagesDocument,
    options,
  );
}
export type DispatchVehicleSealImagesQueryHookResult = ReturnType<typeof useDispatchVehicleSealImagesQuery>;
export type DispatchVehicleSealImagesLazyQueryHookResult = ReturnType<typeof useDispatchVehicleSealImagesLazyQuery>;
export type DispatchVehicleSealImagesQueryResult = Apollo.QueryResult<
  DispatchVehicleSealImagesQuery,
  DispatchVehicleSealImagesQueryVariables
>;
export const DispatcherDropMoverDispatchDocument = gql`
  query DispatcherDropMoverDispatch($dispatchID: ID!) {
    dispatch(dispatchID: $dispatchID) {
      canDropMover
    }
  }
`;

/**
 * __useDispatcherDropMoverDispatchQuery__
 *
 * To run a query within a React component, call `useDispatcherDropMoverDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatcherDropMoverDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatcherDropMoverDispatchQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatcherDropMoverDispatchQuery(
  baseOptions: Apollo.QueryHookOptions<DispatcherDropMoverDispatchQuery, DispatcherDropMoverDispatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatcherDropMoverDispatchQuery, DispatcherDropMoverDispatchQueryVariables>(
    DispatcherDropMoverDispatchDocument,
    options,
  );
}
export function useDispatcherDropMoverDispatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DispatcherDropMoverDispatchQuery,
    DispatcherDropMoverDispatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatcherDropMoverDispatchQuery, DispatcherDropMoverDispatchQueryVariables>(
    DispatcherDropMoverDispatchDocument,
    options,
  );
}
export type DispatcherDropMoverDispatchQueryHookResult = ReturnType<typeof useDispatcherDropMoverDispatchQuery>;
export type DispatcherDropMoverDispatchLazyQueryHookResult = ReturnType<typeof useDispatcherDropMoverDispatchLazyQuery>;
export type DispatcherDropMoverDispatchQueryResult = Apollo.QueryResult<
  DispatcherDropMoverDispatchQuery,
  DispatcherDropMoverDispatchQueryVariables
>;
export const DispatcherDispatchDocument = gql`
  query DispatcherDispatch($dispatchID: ID!) {
    dispatch(dispatchID: $dispatchID) {
      id
      simulation {
        errors {
          message
          severe
        }
      }
    }
  }
`;

/**
 * __useDispatcherDispatchQuery__
 *
 * To run a query within a React component, call `useDispatcherDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatcherDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatcherDispatchQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatcherDispatchQuery(
  baseOptions: Apollo.QueryHookOptions<DispatcherDispatchQuery, DispatcherDispatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatcherDispatchQuery, DispatcherDispatchQueryVariables>(
    DispatcherDispatchDocument,
    options,
  );
}
export function useDispatcherDispatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatcherDispatchQuery, DispatcherDispatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatcherDispatchQuery, DispatcherDispatchQueryVariables>(
    DispatcherDispatchDocument,
    options,
  );
}
export type DispatcherDispatchQueryHookResult = ReturnType<typeof useDispatcherDispatchQuery>;
export type DispatcherDispatchLazyQueryHookResult = ReturnType<typeof useDispatcherDispatchLazyQuery>;
export type DispatcherDispatchQueryResult = Apollo.QueryResult<
  DispatcherDispatchQuery,
  DispatcherDispatchQueryVariables
>;
export const DispatchEditDispatchDocument = gql`
  query DispatchEditDispatch($dispatchID: ID!) {
    dispatch(dispatchID: $dispatchID) {
      id
      arrival
      region {
        id
        name
        tz
      }
      orders {
        id
        type
        serviceType
        address {
          id
          addressableType
        }
      }
      vehicles {
        id
      }
      inboundWarehouse {
        id
      }
    }
  }
`;

/**
 * __useDispatchEditDispatchQuery__
 *
 * To run a query within a React component, call `useDispatchEditDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchEditDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchEditDispatchQuery({
 *   variables: {
 *      dispatchID: // value for 'dispatchID'
 *   },
 * });
 */
export function useDispatchEditDispatchQuery(
  baseOptions: Apollo.QueryHookOptions<DispatchEditDispatchQuery, DispatchEditDispatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchEditDispatchQuery, DispatchEditDispatchQueryVariables>(
    DispatchEditDispatchDocument,
    options,
  );
}
export function useDispatchEditDispatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchEditDispatchQuery, DispatchEditDispatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchEditDispatchQuery, DispatchEditDispatchQueryVariables>(
    DispatchEditDispatchDocument,
    options,
  );
}
export type DispatchEditDispatchQueryHookResult = ReturnType<typeof useDispatchEditDispatchQuery>;
export type DispatchEditDispatchLazyQueryHookResult = ReturnType<typeof useDispatchEditDispatchLazyQuery>;
export type DispatchEditDispatchQueryResult = Apollo.QueryResult<
  DispatchEditDispatchQuery,
  DispatchEditDispatchQueryVariables
>;
export const DispatchEditRegionDocument = gql`
  query DispatchEditRegion($vehicleFilters: VehicleFiltersInput, $warehouseFilters: FacilityFiltersInput) {
    vehicles(filters: $vehicleFilters) {
      id
      name
    }
    warehouses(filters: $warehouseFilters) {
      id
      name
    }
  }
`;

/**
 * __useDispatchEditRegionQuery__
 *
 * To run a query within a React component, call `useDispatchEditRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatchEditRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatchEditRegionQuery({
 *   variables: {
 *      vehicleFilters: // value for 'vehicleFilters'
 *      warehouseFilters: // value for 'warehouseFilters'
 *   },
 * });
 */
export function useDispatchEditRegionQuery(
  baseOptions?: Apollo.QueryHookOptions<DispatchEditRegionQuery, DispatchEditRegionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DispatchEditRegionQuery, DispatchEditRegionQueryVariables>(
    DispatchEditRegionDocument,
    options,
  );
}
export function useDispatchEditRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DispatchEditRegionQuery, DispatchEditRegionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DispatchEditRegionQuery, DispatchEditRegionQueryVariables>(
    DispatchEditRegionDocument,
    options,
  );
}
export type DispatchEditRegionQueryHookResult = ReturnType<typeof useDispatchEditRegionQuery>;
export type DispatchEditRegionLazyQueryHookResult = ReturnType<typeof useDispatchEditRegionLazyQuery>;
export type DispatchEditRegionQueryResult = Apollo.QueryResult<
  DispatchEditRegionQuery,
  DispatchEditRegionQueryVariables
>;
export const DispatchUpdateDocument = gql`
  mutation DispatchUpdate($id: ID!, $input: DispatchInput!) {
    result: dispatchUpdate(id: $id, input: $input) {
      status
      error
    }
  }
`;
export type DispatchUpdateMutationFn = Apollo.MutationFunction<DispatchUpdateMutation, DispatchUpdateMutationVariables>;

/**
 * __useDispatchUpdateMutation__
 *
 * To run a mutation, you first call `useDispatchUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchUpdateMutation, { data, loading, error }] = useDispatchUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDispatchUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<DispatchUpdateMutation, DispatchUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DispatchUpdateMutation, DispatchUpdateMutationVariables>(DispatchUpdateDocument, options);
}
export type DispatchUpdateMutationHookResult = ReturnType<typeof useDispatchUpdateMutation>;
export type DispatchUpdateMutationResult = Apollo.MutationResult<DispatchUpdateMutation>;
export type DispatchUpdateMutationOptions = Apollo.BaseMutationOptions<
  DispatchUpdateMutation,
  DispatchUpdateMutationVariables
>;
export const AdminDropOffAppointmentDocument = gql`
  query AdminDropOffAppointment($orderID: ID!) {
    dropOffAppointment(orderID: $orderID) {
      ...AdminDropOffAppointment
    }
  }
  ${AdminDropOffAppointmentFragmentDoc}
`;

/**
 * __useAdminDropOffAppointmentQuery__
 *
 * To run a query within a React component, call `useAdminDropOffAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDropOffAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDropOffAppointmentQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useAdminDropOffAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<AdminDropOffAppointmentQuery, AdminDropOffAppointmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminDropOffAppointmentQuery, AdminDropOffAppointmentQueryVariables>(
    AdminDropOffAppointmentDocument,
    options,
  );
}
export function useAdminDropOffAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminDropOffAppointmentQuery, AdminDropOffAppointmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminDropOffAppointmentQuery, AdminDropOffAppointmentQueryVariables>(
    AdminDropOffAppointmentDocument,
    options,
  );
}
export type AdminDropOffAppointmentQueryHookResult = ReturnType<typeof useAdminDropOffAppointmentQuery>;
export type AdminDropOffAppointmentLazyQueryHookResult = ReturnType<typeof useAdminDropOffAppointmentLazyQuery>;
export type AdminDropOffAppointmentQueryResult = Apollo.QueryResult<
  AdminDropOffAppointmentQuery,
  AdminDropOffAppointmentQueryVariables
>;
export const EstimationOverrideEstimatedCuftDocument = gql`
  mutation EstimationOverrideEstimatedCuft(
    $orderID: ID!
    $reason: Estimation__EstimatedCuftOverrideReason
    $estimatedCuft: Int
  ) {
    estimationOverrideEstimatedCuft(orderID: $orderID, reason: $reason, estimatedCuft: $estimatedCuft) {
      error
      status
      order {
        id
        estimatedCuft
        estimatedDuration
      }
    }
  }
`;
export type EstimationOverrideEstimatedCuftMutationFn = Apollo.MutationFunction<
  EstimationOverrideEstimatedCuftMutation,
  EstimationOverrideEstimatedCuftMutationVariables
>;

/**
 * __useEstimationOverrideEstimatedCuftMutation__
 *
 * To run a mutation, you first call `useEstimationOverrideEstimatedCuftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationOverrideEstimatedCuftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationOverrideEstimatedCuftMutation, { data, loading, error }] = useEstimationOverrideEstimatedCuftMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      reason: // value for 'reason'
 *      estimatedCuft: // value for 'estimatedCuft'
 *   },
 * });
 */
export function useEstimationOverrideEstimatedCuftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimationOverrideEstimatedCuftMutation,
    EstimationOverrideEstimatedCuftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationOverrideEstimatedCuftMutation, EstimationOverrideEstimatedCuftMutationVariables>(
    EstimationOverrideEstimatedCuftDocument,
    options,
  );
}
export type EstimationOverrideEstimatedCuftMutationHookResult = ReturnType<
  typeof useEstimationOverrideEstimatedCuftMutation
>;
export type EstimationOverrideEstimatedCuftMutationResult =
  Apollo.MutationResult<EstimationOverrideEstimatedCuftMutation>;
export type EstimationOverrideEstimatedCuftMutationOptions = Apollo.BaseMutationOptions<
  EstimationOverrideEstimatedCuftMutation,
  EstimationOverrideEstimatedCuftMutationVariables
>;
export const EstimationOrderEstimatedCuftAndCuftOverrideReasonDocument = gql`
  query estimationOrderEstimatedCuftAndCuftOverrideReason($orderID: ID!) {
    order: order(orderID: $orderID) {
      estimatedCuft
      estimatedCuftOverrideReason
    }
  }
`;

/**
 * __useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery__
 *
 * To run a query within a React component, call `useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables
  >(EstimationOrderEstimatedCuftAndCuftOverrideReasonDocument, options);
}
export function useEstimationOrderEstimatedCuftAndCuftOverrideReasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
    EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables
  >(EstimationOrderEstimatedCuftAndCuftOverrideReasonDocument, options);
}
export type EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryHookResult = ReturnType<
  typeof useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery
>;
export type EstimationOrderEstimatedCuftAndCuftOverrideReasonLazyQueryHookResult = ReturnType<
  typeof useEstimationOrderEstimatedCuftAndCuftOverrideReasonLazyQuery
>;
export type EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryResult = Apollo.QueryResult<
  EstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
  EstimationOrderEstimatedCuftAndCuftOverrideReasonQueryVariables
>;
export const EstimationAlgorithmForOrderDocument = gql`
  query EstimationAlgorithmForOrder($orderID: ID!) {
    estimationAlgorithmForOrder(orderID: $orderID) {
      id
      name
    }
  }
`;

/**
 * __useEstimationAlgorithmForOrderQuery__
 *
 * To run a query within a React component, call `useEstimationAlgorithmForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationAlgorithmForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationAlgorithmForOrderQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationAlgorithmForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationAlgorithmForOrderQuery, EstimationAlgorithmForOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationAlgorithmForOrderQuery, EstimationAlgorithmForOrderQueryVariables>(
    EstimationAlgorithmForOrderDocument,
    options,
  );
}
export function useEstimationAlgorithmForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimationAlgorithmForOrderQuery,
    EstimationAlgorithmForOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationAlgorithmForOrderQuery, EstimationAlgorithmForOrderQueryVariables>(
    EstimationAlgorithmForOrderDocument,
    options,
  );
}
export type EstimationAlgorithmForOrderQueryHookResult = ReturnType<typeof useEstimationAlgorithmForOrderQuery>;
export type EstimationAlgorithmForOrderLazyQueryHookResult = ReturnType<typeof useEstimationAlgorithmForOrderLazyQuery>;
export type EstimationAlgorithmForOrderQueryResult = Apollo.QueryResult<
  EstimationAlgorithmForOrderQuery,
  EstimationAlgorithmForOrderQueryVariables
>;
export const EstimationAlgorithmsDocument = gql`
  query EstimationAlgorithms {
    estimationAlgorithms {
      id
      name
    }
  }
`;

/**
 * __useEstimationAlgorithmsQuery__
 *
 * To run a query within a React component, call `useEstimationAlgorithmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationAlgorithmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationAlgorithmsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstimationAlgorithmsQuery(
  baseOptions?: Apollo.QueryHookOptions<EstimationAlgorithmsQuery, EstimationAlgorithmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationAlgorithmsQuery, EstimationAlgorithmsQueryVariables>(
    EstimationAlgorithmsDocument,
    options,
  );
}
export function useEstimationAlgorithmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationAlgorithmsQuery, EstimationAlgorithmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationAlgorithmsQuery, EstimationAlgorithmsQueryVariables>(
    EstimationAlgorithmsDocument,
    options,
  );
}
export type EstimationAlgorithmsQueryHookResult = ReturnType<typeof useEstimationAlgorithmsQuery>;
export type EstimationAlgorithmsLazyQueryHookResult = ReturnType<typeof useEstimationAlgorithmsLazyQuery>;
export type EstimationAlgorithmsQueryResult = Apollo.QueryResult<
  EstimationAlgorithmsQuery,
  EstimationAlgorithmsQueryVariables
>;
export const EstimationItemsDocument = gql`
  query EstimationItems($orderID: ID!, $algorithmID: ID) {
    estimationItems(orderID: $orderID, algorithmID: $algorithmID) {
      id
      quantity
      disassemblyQuantity
      category {
        id
        name
        defaultCuft
        parent {
          name
        }
      }
    }
  }
`;

/**
 * __useEstimationItemsQuery__
 *
 * To run a query within a React component, call `useEstimationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationItemsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      algorithmID: // value for 'algorithmID'
 *   },
 * });
 */
export function useEstimationItemsQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationItemsQuery, EstimationItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationItemsQuery, EstimationItemsQueryVariables>(EstimationItemsDocument, options);
}
export function useEstimationItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationItemsQuery, EstimationItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationItemsQuery, EstimationItemsQueryVariables>(EstimationItemsDocument, options);
}
export type EstimationItemsQueryHookResult = ReturnType<typeof useEstimationItemsQuery>;
export type EstimationItemsLazyQueryHookResult = ReturnType<typeof useEstimationItemsLazyQuery>;
export type EstimationItemsQueryResult = Apollo.QueryResult<EstimationItemsQuery, EstimationItemsQueryVariables>;
export const EstimationSelectedRoomsDocument = gql`
  query EstimationSelectedRooms($orderID: ID!, $algorithmID: ID!) {
    estimationSelectedRooms(orderID: $orderID, algorithmID: $algorithmID) {
      id
      packingRequested
      roomCategory {
        id
        name
      }
    }
  }
`;

/**
 * __useEstimationSelectedRoomsQuery__
 *
 * To run a query within a React component, call `useEstimationSelectedRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationSelectedRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationSelectedRoomsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      algorithmID: // value for 'algorithmID'
 *   },
 * });
 */
export function useEstimationSelectedRoomsQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationSelectedRoomsQuery, EstimationSelectedRoomsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationSelectedRoomsQuery, EstimationSelectedRoomsQueryVariables>(
    EstimationSelectedRoomsDocument,
    options,
  );
}
export function useEstimationSelectedRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationSelectedRoomsQuery, EstimationSelectedRoomsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationSelectedRoomsQuery, EstimationSelectedRoomsQueryVariables>(
    EstimationSelectedRoomsDocument,
    options,
  );
}
export type EstimationSelectedRoomsQueryHookResult = ReturnType<typeof useEstimationSelectedRoomsQuery>;
export type EstimationSelectedRoomsLazyQueryHookResult = ReturnType<typeof useEstimationSelectedRoomsLazyQuery>;
export type EstimationSelectedRoomsQueryResult = Apollo.QueryResult<
  EstimationSelectedRoomsQuery,
  EstimationSelectedRoomsQueryVariables
>;
export const EstimationEstimatedItemsCreateDocument = gql`
  mutation EstimationEstimatedItemsCreate($input: Estimation__EstimatedItemsCreateInput!) {
    result: estimationEstimatedItemsCreate(input: $input) {
      error
      status
    }
  }
`;
export type EstimationEstimatedItemsCreateMutationFn = Apollo.MutationFunction<
  EstimationEstimatedItemsCreateMutation,
  EstimationEstimatedItemsCreateMutationVariables
>;

/**
 * __useEstimationEstimatedItemsCreateMutation__
 *
 * To run a mutation, you first call `useEstimationEstimatedItemsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationEstimatedItemsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationEstimatedItemsCreateMutation, { data, loading, error }] = useEstimationEstimatedItemsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimationEstimatedItemsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimationEstimatedItemsCreateMutation,
    EstimationEstimatedItemsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationEstimatedItemsCreateMutation, EstimationEstimatedItemsCreateMutationVariables>(
    EstimationEstimatedItemsCreateDocument,
    options,
  );
}
export type EstimationEstimatedItemsCreateMutationHookResult = ReturnType<
  typeof useEstimationEstimatedItemsCreateMutation
>;
export type EstimationEstimatedItemsCreateMutationResult =
  Apollo.MutationResult<EstimationEstimatedItemsCreateMutation>;
export type EstimationEstimatedItemsCreateMutationOptions = Apollo.BaseMutationOptions<
  EstimationEstimatedItemsCreateMutation,
  EstimationEstimatedItemsCreateMutationVariables
>;
export const EstimationsForOrderDocument = gql`
  query EstimationsForOrder($orderID: ID!, $algorithmID: ID!) {
    estimationsForOrder(orderID: $orderID, algorithmID: $algorithmID) {
      agentBoxEstimation {
        categoryID
        name
        cuft
      }
      customerBoxEstimation {
        categoryID
        name
        cuft
      }
      itemEstimations {
        categoryID
        name
        cuft
        agentQuantity
        customerQuantity
      }
      agentOtherItemEstimation {
        categoryID
        name
        cuft
      }
      customerOtherItemEstimation {
        categoryID
        name
        cuft
      }
      roomEstimations {
        categoryID
        name
        quantity
      }
      allItemsInListedRooms
      itemsRequireMoreThanOneMover
    }
    order(orderID: $orderID) {
      id
      state
      type
    }
    boxCategories: estimationBoxItemCategories(algorithmID: $algorithmID) {
      id
      name
      defaultCuft
      parent {
        id
        name
      }
    }
    otherItemCategories: estimationOtherItemCategories(algorithmID: $algorithmID) {
      id
      name
      defaultCuft
      parent {
        id
        name
      }
    }
  }
`;

/**
 * __useEstimationsForOrderQuery__
 *
 * To run a query within a React component, call `useEstimationsForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationsForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationsForOrderQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      algorithmID: // value for 'algorithmID'
 *   },
 * });
 */
export function useEstimationsForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationsForOrderQuery, EstimationsForOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationsForOrderQuery, EstimationsForOrderQueryVariables>(
    EstimationsForOrderDocument,
    options,
  );
}
export function useEstimationsForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationsForOrderQuery, EstimationsForOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationsForOrderQuery, EstimationsForOrderQueryVariables>(
    EstimationsForOrderDocument,
    options,
  );
}
export type EstimationsForOrderQueryHookResult = ReturnType<typeof useEstimationsForOrderQuery>;
export type EstimationsForOrderLazyQueryHookResult = ReturnType<typeof useEstimationsForOrderLazyQuery>;
export type EstimationsForOrderQueryResult = Apollo.QueryResult<
  EstimationsForOrderQuery,
  EstimationsForOrderQueryVariables
>;
export const EstimationUploadsDocument = gql`
  query EstimationUploads($orderID: ID!) {
    estimationUploads(orderID: $orderID) {
      ...estimationUpload
    }
  }
  ${EstimationUploadFragmentDoc}
`;

/**
 * __useEstimationUploadsQuery__
 *
 * To run a query within a React component, call `useEstimationUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationUploadsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationUploadsQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationUploadsQuery, EstimationUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationUploadsQuery, EstimationUploadsQueryVariables>(EstimationUploadsDocument, options);
}
export function useEstimationUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationUploadsQuery, EstimationUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationUploadsQuery, EstimationUploadsQueryVariables>(
    EstimationUploadsDocument,
    options,
  );
}
export type EstimationUploadsQueryHookResult = ReturnType<typeof useEstimationUploadsQuery>;
export type EstimationUploadsLazyQueryHookResult = ReturnType<typeof useEstimationUploadsLazyQuery>;
export type EstimationUploadsQueryResult = Apollo.QueryResult<EstimationUploadsQuery, EstimationUploadsQueryVariables>;
export const FacilityDetailsDocument = gql`
  query FacilityDetails($facility: FacilityInput!) {
    facility(facility: $facility) {
      ... on Warehouse {
        id
        name
        accessHours
        address {
          id
          formatted
          map
        }
      }
    }
  }
`;

/**
 * __useFacilityDetailsQuery__
 *
 * To run a query within a React component, call `useFacilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityDetailsQuery({
 *   variables: {
 *      facility: // value for 'facility'
 *   },
 * });
 */
export function useFacilityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FacilityDetailsQuery, FacilityDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FacilityDetailsQuery, FacilityDetailsQueryVariables>(FacilityDetailsDocument, options);
}
export function useFacilityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FacilityDetailsQuery, FacilityDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FacilityDetailsQuery, FacilityDetailsQueryVariables>(FacilityDetailsDocument, options);
}
export type FacilityDetailsQueryHookResult = ReturnType<typeof useFacilityDetailsQuery>;
export type FacilityDetailsLazyQueryHookResult = ReturnType<typeof useFacilityDetailsLazyQuery>;
export type FacilityDetailsQueryResult = Apollo.QueryResult<FacilityDetailsQuery, FacilityDetailsQueryVariables>;
export const FeatureFlagsDocument = gql`
  query FeatureFlags($resourceName: String) {
    featureFlags(resourceName: $resourceName) {
      id
      createdAt
      description
      name
      resourceName
      updatedAt
      whitelistedObjects {
        ... on Account {
          id
          name
        }
        ... on Region {
          id
          name
        }
        ... on User {
          id
          name
        }
        ... on Warehouse {
          id
          name
        }
      }
    }
    regions {
      id
      name
    }
    users {
      id
      name
    }
    warehouses {
      id
      name
    }
  }
`;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *      resourceName: // value for 'resourceName'
 *   },
 * });
 */
export function useFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
}
export function useFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
}
export type FeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsQuery>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsLazyQuery>;
export type FeatureFlagsQueryResult = Apollo.QueryResult<FeatureFlagsQuery, FeatureFlagsQueryVariables>;
export const FeatureFlagUpsertDocument = gql`
  mutation FeatureFlagUpsert($id: ID, $input: FeatureFlagInput!) {
    result: featureFlagUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        createdAt
        name
        resourceName
        updatedAt
        whitelistedObjects {
          ... on Account {
            id
            name
          }
          ... on Region {
            id
            name
          }
          ... on User {
            id
            name
          }
          ... on Warehouse {
            id
            name
          }
        }
      }
    }
  }
`;
export type FeatureFlagUpsertMutationFn = Apollo.MutationFunction<
  FeatureFlagUpsertMutation,
  FeatureFlagUpsertMutationVariables
>;

/**
 * __useFeatureFlagUpsertMutation__
 *
 * To run a mutation, you first call `useFeatureFlagUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagUpsertMutation, { data, loading, error }] = useFeatureFlagUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeatureFlagUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<FeatureFlagUpsertMutation, FeatureFlagUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FeatureFlagUpsertMutation, FeatureFlagUpsertMutationVariables>(
    FeatureFlagUpsertDocument,
    options,
  );
}
export type FeatureFlagUpsertMutationHookResult = ReturnType<typeof useFeatureFlagUpsertMutation>;
export type FeatureFlagUpsertMutationResult = Apollo.MutationResult<FeatureFlagUpsertMutation>;
export type FeatureFlagUpsertMutationOptions = Apollo.BaseMutationOptions<
  FeatureFlagUpsertMutation,
  FeatureFlagUpsertMutationVariables
>;
export const MapRegionsDocument = gql`
  query MapRegions {
    regions {
      id
      coordinates
      hexColor
    }
  }
`;

/**
 * __useMapRegionsQuery__
 *
 * To run a query within a React component, call `useMapRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMapRegionsQuery(baseOptions?: Apollo.QueryHookOptions<MapRegionsQuery, MapRegionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MapRegionsQuery, MapRegionsQueryVariables>(MapRegionsDocument, options);
}
export function useMapRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MapRegionsQuery, MapRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MapRegionsQuery, MapRegionsQueryVariables>(MapRegionsDocument, options);
}
export type MapRegionsQueryHookResult = ReturnType<typeof useMapRegionsQuery>;
export type MapRegionsLazyQueryHookResult = ReturnType<typeof useMapRegionsLazyQuery>;
export type MapRegionsQueryResult = Apollo.QueryResult<MapRegionsQuery, MapRegionsQueryVariables>;
export const PaginatedHistoryListDocument = gql`
  query PaginatedHistoryList($subject: History__SubjectInput!, $page: Int) {
    paginated: paginatedHistoryList(subject: $subject, page: $page) {
      results {
        ...history
      }
      pagination {
        ...pagination
      }
    }
  }
  ${HistoryFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedHistoryListQuery__
 *
 * To run a query within a React component, call `usePaginatedHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedHistoryListQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedHistoryListQuery, PaginatedHistoryListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedHistoryListQuery, PaginatedHistoryListQueryVariables>(
    PaginatedHistoryListDocument,
    options,
  );
}
export function usePaginatedHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedHistoryListQuery, PaginatedHistoryListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedHistoryListQuery, PaginatedHistoryListQueryVariables>(
    PaginatedHistoryListDocument,
    options,
  );
}
export type PaginatedHistoryListQueryHookResult = ReturnType<typeof usePaginatedHistoryListQuery>;
export type PaginatedHistoryListLazyQueryHookResult = ReturnType<typeof usePaginatedHistoryListLazyQuery>;
export type PaginatedHistoryListQueryResult = Apollo.QueryResult<
  PaginatedHistoryListQuery,
  PaginatedHistoryListQueryVariables
>;
export const PhoneCallPolicyDocument = gql`
  query PhoneCallPolicy {
    phoneCallPolicy {
      download
    }
  }
`;

/**
 * __usePhoneCallPolicyQuery__
 *
 * To run a query within a React component, call `usePhoneCallPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneCallPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneCallPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneCallPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<PhoneCallPolicyQuery, PhoneCallPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhoneCallPolicyQuery, PhoneCallPolicyQueryVariables>(PhoneCallPolicyDocument, options);
}
export function usePhoneCallPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhoneCallPolicyQuery, PhoneCallPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhoneCallPolicyQuery, PhoneCallPolicyQueryVariables>(PhoneCallPolicyDocument, options);
}
export type PhoneCallPolicyQueryHookResult = ReturnType<typeof usePhoneCallPolicyQuery>;
export type PhoneCallPolicyLazyQueryHookResult = ReturnType<typeof usePhoneCallPolicyLazyQuery>;
export type PhoneCallPolicyQueryResult = Apollo.QueryResult<PhoneCallPolicyQuery, PhoneCallPolicyQueryVariables>;
export const HrsLocationsDocument = gql`
  query HRSLocations {
    hrsLocations {
      id
      code
      region {
        id
        name
      }
    }
    regions {
      id
      name
    }
  }
`;

/**
 * __useHrsLocationsQuery__
 *
 * To run a query within a React component, call `useHrsLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrsLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrsLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHrsLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<HrsLocationsQuery, HrsLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HrsLocationsQuery, HrsLocationsQueryVariables>(HrsLocationsDocument, options);
}
export function useHrsLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HrsLocationsQuery, HrsLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HrsLocationsQuery, HrsLocationsQueryVariables>(HrsLocationsDocument, options);
}
export type HrsLocationsQueryHookResult = ReturnType<typeof useHrsLocationsQuery>;
export type HrsLocationsLazyQueryHookResult = ReturnType<typeof useHrsLocationsLazyQuery>;
export type HrsLocationsQueryResult = Apollo.QueryResult<HrsLocationsQuery, HrsLocationsQueryVariables>;
export const HrsLocationUpsertDocument = gql`
  mutation HRSLocationUpsert($id: ID, $input: HRS__LocationInput!) {
    result: hrsLocationUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        code
        region {
          id
          name
        }
      }
    }
  }
`;
export type HrsLocationUpsertMutationFn = Apollo.MutationFunction<
  HrsLocationUpsertMutation,
  HrsLocationUpsertMutationVariables
>;

/**
 * __useHrsLocationUpsertMutation__
 *
 * To run a mutation, you first call `useHrsLocationUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHrsLocationUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hrsLocationUpsertMutation, { data, loading, error }] = useHrsLocationUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHrsLocationUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<HrsLocationUpsertMutation, HrsLocationUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HrsLocationUpsertMutation, HrsLocationUpsertMutationVariables>(
    HrsLocationUpsertDocument,
    options,
  );
}
export type HrsLocationUpsertMutationHookResult = ReturnType<typeof useHrsLocationUpsertMutation>;
export type HrsLocationUpsertMutationResult = Apollo.MutationResult<HrsLocationUpsertMutation>;
export type HrsLocationUpsertMutationOptions = Apollo.BaseMutationOptions<
  HrsLocationUpsertMutation,
  HrsLocationUpsertMutationVariables
>;
export const HrsProfileDefaultsDocument = gql`
  query HRSProfileDefaults {
    hrsBusinessTitles
    hrsLocations {
      id
      code
    }
    hrsProfileDefaults {
      id
      businessTitle
      location {
        id
        code
      }
      orgLevel1
      orgLevel2
      orgLevel3
      orgLevel4
      team {
        id
        name
      }
    }
    teams {
      id
      name
    }
  }
`;

/**
 * __useHrsProfileDefaultsQuery__
 *
 * To run a query within a React component, call `useHrsProfileDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrsProfileDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrsProfileDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHrsProfileDefaultsQuery(
  baseOptions?: Apollo.QueryHookOptions<HrsProfileDefaultsQuery, HrsProfileDefaultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HrsProfileDefaultsQuery, HrsProfileDefaultsQueryVariables>(
    HrsProfileDefaultsDocument,
    options,
  );
}
export function useHrsProfileDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HrsProfileDefaultsQuery, HrsProfileDefaultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HrsProfileDefaultsQuery, HrsProfileDefaultsQueryVariables>(
    HrsProfileDefaultsDocument,
    options,
  );
}
export type HrsProfileDefaultsQueryHookResult = ReturnType<typeof useHrsProfileDefaultsQuery>;
export type HrsProfileDefaultsLazyQueryHookResult = ReturnType<typeof useHrsProfileDefaultsLazyQuery>;
export type HrsProfileDefaultsQueryResult = Apollo.QueryResult<
  HrsProfileDefaultsQuery,
  HrsProfileDefaultsQueryVariables
>;
export const HrsProfileDefaultUpsertDocument = gql`
  mutation HRSProfileDefaultUpsert($id: ID, $input: HRS__ProfileDefaultInput!) {
    result: hrsProfileDefaultUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        businessTitle
        location {
          id
          code
        }
        orgLevel1
        orgLevel2
        orgLevel3
        orgLevel4
        team {
          id
          name
        }
      }
    }
  }
`;
export type HrsProfileDefaultUpsertMutationFn = Apollo.MutationFunction<
  HrsProfileDefaultUpsertMutation,
  HrsProfileDefaultUpsertMutationVariables
>;

/**
 * __useHrsProfileDefaultUpsertMutation__
 *
 * To run a mutation, you first call `useHrsProfileDefaultUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHrsProfileDefaultUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hrsProfileDefaultUpsertMutation, { data, loading, error }] = useHrsProfileDefaultUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHrsProfileDefaultUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<HrsProfileDefaultUpsertMutation, HrsProfileDefaultUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HrsProfileDefaultUpsertMutation, HrsProfileDefaultUpsertMutationVariables>(
    HrsProfileDefaultUpsertDocument,
    options,
  );
}
export type HrsProfileDefaultUpsertMutationHookResult = ReturnType<typeof useHrsProfileDefaultUpsertMutation>;
export type HrsProfileDefaultUpsertMutationResult = Apollo.MutationResult<HrsProfileDefaultUpsertMutation>;
export type HrsProfileDefaultUpsertMutationOptions = Apollo.BaseMutationOptions<
  HrsProfileDefaultUpsertMutation,
  HrsProfileDefaultUpsertMutationVariables
>;
export const HrsRoleDefaultsDocument = gql`
  query HRSRoleDefaults {
    hrsBusinessTitles
    hrsRoleDefaults {
      id
      businessTitle
      roles
    }
  }
`;

/**
 * __useHrsRoleDefaultsQuery__
 *
 * To run a query within a React component, call `useHrsRoleDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrsRoleDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrsRoleDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHrsRoleDefaultsQuery(
  baseOptions?: Apollo.QueryHookOptions<HrsRoleDefaultsQuery, HrsRoleDefaultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HrsRoleDefaultsQuery, HrsRoleDefaultsQueryVariables>(HrsRoleDefaultsDocument, options);
}
export function useHrsRoleDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HrsRoleDefaultsQuery, HrsRoleDefaultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HrsRoleDefaultsQuery, HrsRoleDefaultsQueryVariables>(HrsRoleDefaultsDocument, options);
}
export type HrsRoleDefaultsQueryHookResult = ReturnType<typeof useHrsRoleDefaultsQuery>;
export type HrsRoleDefaultsLazyQueryHookResult = ReturnType<typeof useHrsRoleDefaultsLazyQuery>;
export type HrsRoleDefaultsQueryResult = Apollo.QueryResult<HrsRoleDefaultsQuery, HrsRoleDefaultsQueryVariables>;
export const HrsRoleDefaultUpsertDocument = gql`
  mutation HRSRoleDefaultUpsert($id: ID, $input: HRS__RoleDefaultInput!) {
    result: hrsRoleDefaultUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        businessTitle
        roles
      }
    }
  }
`;
export type HrsRoleDefaultUpsertMutationFn = Apollo.MutationFunction<
  HrsRoleDefaultUpsertMutation,
  HrsRoleDefaultUpsertMutationVariables
>;

/**
 * __useHrsRoleDefaultUpsertMutation__
 *
 * To run a mutation, you first call `useHrsRoleDefaultUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHrsRoleDefaultUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hrsRoleDefaultUpsertMutation, { data, loading, error }] = useHrsRoleDefaultUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHrsRoleDefaultUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<HrsRoleDefaultUpsertMutation, HrsRoleDefaultUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HrsRoleDefaultUpsertMutation, HrsRoleDefaultUpsertMutationVariables>(
    HrsRoleDefaultUpsertDocument,
    options,
  );
}
export type HrsRoleDefaultUpsertMutationHookResult = ReturnType<typeof useHrsRoleDefaultUpsertMutation>;
export type HrsRoleDefaultUpsertMutationResult = Apollo.MutationResult<HrsRoleDefaultUpsertMutation>;
export type HrsRoleDefaultUpsertMutationOptions = Apollo.BaseMutationOptions<
  HrsRoleDefaultUpsertMutation,
  HrsRoleDefaultUpsertMutationVariables
>;
export const IngestionUploadDocument = gql`
  query IngestionUpload($id: ID!) {
    upload: ingestionUpload(id: $id) {
      ...Ingestion__upload
    }
  }
  ${Ingestion__UploadFragmentDoc}
`;

/**
 * __useIngestionUploadQuery__
 *
 * To run a query within a React component, call `useIngestionUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngestionUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngestionUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIngestionUploadQuery(
  baseOptions: Apollo.QueryHookOptions<IngestionUploadQuery, IngestionUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IngestionUploadQuery, IngestionUploadQueryVariables>(IngestionUploadDocument, options);
}
export function useIngestionUploadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IngestionUploadQuery, IngestionUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IngestionUploadQuery, IngestionUploadQueryVariables>(IngestionUploadDocument, options);
}
export type IngestionUploadQueryHookResult = ReturnType<typeof useIngestionUploadQuery>;
export type IngestionUploadLazyQueryHookResult = ReturnType<typeof useIngestionUploadLazyQuery>;
export type IngestionUploadQueryResult = Apollo.QueryResult<IngestionUploadQuery, IngestionUploadQueryVariables>;
export const IngestionUploadSaveDocument = gql`
  mutation IngestionUploadSave($input: Ingestion__UploadInput!) {
    save: ingestionUploadSave(input: $input) {
      upload {
        ...Ingestion__upload
      }
    }
  }
  ${Ingestion__UploadFragmentDoc}
`;
export type IngestionUploadSaveMutationFn = Apollo.MutationFunction<
  IngestionUploadSaveMutation,
  IngestionUploadSaveMutationVariables
>;

/**
 * __useIngestionUploadSaveMutation__
 *
 * To run a mutation, you first call `useIngestionUploadSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIngestionUploadSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ingestionUploadSaveMutation, { data, loading, error }] = useIngestionUploadSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngestionUploadSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<IngestionUploadSaveMutation, IngestionUploadSaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IngestionUploadSaveMutation, IngestionUploadSaveMutationVariables>(
    IngestionUploadSaveDocument,
    options,
  );
}
export type IngestionUploadSaveMutationHookResult = ReturnType<typeof useIngestionUploadSaveMutation>;
export type IngestionUploadSaveMutationResult = Apollo.MutationResult<IngestionUploadSaveMutation>;
export type IngestionUploadSaveMutationOptions = Apollo.BaseMutationOptions<
  IngestionUploadSaveMutation,
  IngestionUploadSaveMutationVariables
>;
export const PaginatedIngestionUploadsDocument = gql`
  query PaginatedIngestionUploads($page: Int) {
    paginated: paginatedIngestionUploads(page: $page) {
      results {
        ...Ingestion__upload
      }
      pagination {
        ...pagination
      }
    }
  }
  ${Ingestion__UploadFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedIngestionUploadsQuery__
 *
 * To run a query within a React component, call `usePaginatedIngestionUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedIngestionUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedIngestionUploadsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedIngestionUploadsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedIngestionUploadsQuery, PaginatedIngestionUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedIngestionUploadsQuery, PaginatedIngestionUploadsQueryVariables>(
    PaginatedIngestionUploadsDocument,
    options,
  );
}
export function usePaginatedIngestionUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedIngestionUploadsQuery, PaginatedIngestionUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedIngestionUploadsQuery, PaginatedIngestionUploadsQueryVariables>(
    PaginatedIngestionUploadsDocument,
    options,
  );
}
export type PaginatedIngestionUploadsQueryHookResult = ReturnType<typeof usePaginatedIngestionUploadsQuery>;
export type PaginatedIngestionUploadsLazyQueryHookResult = ReturnType<typeof usePaginatedIngestionUploadsLazyQuery>;
export type PaginatedIngestionUploadsQueryResult = Apollo.QueryResult<
  PaginatedIngestionUploadsQuery,
  PaginatedIngestionUploadsQueryVariables
>;
export const ItemExceptionsDocument = gql`
  query ItemExceptions($itemId: ID!) {
    itemExceptions(itemId: $itemId) {
      ...itemException
    }
  }
  ${ItemExceptionFragmentDoc}
`;

/**
 * __useItemExceptionsQuery__
 *
 * To run a query within a React component, call `useItemExceptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemExceptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemExceptionsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useItemExceptionsQuery(
  baseOptions: Apollo.QueryHookOptions<ItemExceptionsQuery, ItemExceptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemExceptionsQuery, ItemExceptionsQueryVariables>(ItemExceptionsDocument, options);
}
export function useItemExceptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemExceptionsQuery, ItemExceptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemExceptionsQuery, ItemExceptionsQueryVariables>(ItemExceptionsDocument, options);
}
export type ItemExceptionsQueryHookResult = ReturnType<typeof useItemExceptionsQuery>;
export type ItemExceptionsLazyQueryHookResult = ReturnType<typeof useItemExceptionsLazyQuery>;
export type ItemExceptionsQueryResult = Apollo.QueryResult<ItemExceptionsQuery, ItemExceptionsQueryVariables>;
export const ItemGroupPanelDocument = gql`
  query ItemGroupPanel($id: ID!) {
    itemGroup(id: $id) {
      id
      uuid
      category {
        id
        name
      }
      additionalImages {
        id
        createdAt
        source
        size
      }
      heroImage {
        id
        createdAt
        source
        size
      }
      sizingSelection
      length
      width
      height
    }
  }
`;

/**
 * __useItemGroupPanelQuery__
 *
 * To run a query within a React component, call `useItemGroupPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemGroupPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemGroupPanelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemGroupPanelQuery(
  baseOptions: Apollo.QueryHookOptions<ItemGroupPanelQuery, ItemGroupPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemGroupPanelQuery, ItemGroupPanelQueryVariables>(ItemGroupPanelDocument, options);
}
export function useItemGroupPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemGroupPanelQuery, ItemGroupPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemGroupPanelQuery, ItemGroupPanelQueryVariables>(ItemGroupPanelDocument, options);
}
export type ItemGroupPanelQueryHookResult = ReturnType<typeof useItemGroupPanelQuery>;
export type ItemGroupPanelLazyQueryHookResult = ReturnType<typeof useItemGroupPanelLazyQuery>;
export type ItemGroupPanelQueryResult = Apollo.QueryResult<ItemGroupPanelQuery, ItemGroupPanelQueryVariables>;
export const NonExistentPartsItemGroupDocument = gql`
  query NonExistentPartsItemGroup($id: ID!) {
    itemGroup(id: $id) {
      id
      partialItems {
        id
        state
        accountID
        category {
          id
          name
          sizingMetric {
            id
            unit
          }
        }
        partCategory {
          id
          name
        }
        materialCategory {
          id
          name
        }
        includedParts {
          id
          category {
            __typename
            ... on ItemCategory {
              id
              name
            }
            ... on MaterialCategory {
              id
              name
            }
            ... on PartCategory {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useNonExistentPartsItemGroupQuery__
 *
 * To run a query within a React component, call `useNonExistentPartsItemGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonExistentPartsItemGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonExistentPartsItemGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNonExistentPartsItemGroupQuery(
  baseOptions: Apollo.QueryHookOptions<NonExistentPartsItemGroupQuery, NonExistentPartsItemGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NonExistentPartsItemGroupQuery, NonExistentPartsItemGroupQueryVariables>(
    NonExistentPartsItemGroupDocument,
    options,
  );
}
export function useNonExistentPartsItemGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NonExistentPartsItemGroupQuery, NonExistentPartsItemGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NonExistentPartsItemGroupQuery, NonExistentPartsItemGroupQueryVariables>(
    NonExistentPartsItemGroupDocument,
    options,
  );
}
export type NonExistentPartsItemGroupQueryHookResult = ReturnType<typeof useNonExistentPartsItemGroupQuery>;
export type NonExistentPartsItemGroupLazyQueryHookResult = ReturnType<typeof useNonExistentPartsItemGroupLazyQuery>;
export type NonExistentPartsItemGroupQueryResult = Apollo.QueryResult<
  NonExistentPartsItemGroupQuery,
  NonExistentPartsItemGroupQueryVariables
>;
export const ItemActivateDocument = gql`
  mutation ItemActivate($id: ID!) {
    itemActivate(id: $id) {
      item {
        id
        active
      }
    }
  }
`;
export type ItemActivateMutationFn = Apollo.MutationFunction<ItemActivateMutation, ItemActivateMutationVariables>;

/**
 * __useItemActivateMutation__
 *
 * To run a mutation, you first call `useItemActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemActivateMutation, { data, loading, error }] = useItemActivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<ItemActivateMutation, ItemActivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ItemActivateMutation, ItemActivateMutationVariables>(ItemActivateDocument, options);
}
export type ItemActivateMutationHookResult = ReturnType<typeof useItemActivateMutation>;
export type ItemActivateMutationResult = Apollo.MutationResult<ItemActivateMutation>;
export type ItemActivateMutationOptions = Apollo.BaseMutationOptions<
  ItemActivateMutation,
  ItemActivateMutationVariables
>;
export const ItemByBarcodeDocument = gql`
  query ItemByBarcode($accountID: ID!, $barcode: String!) {
    item(accountID: $accountID, barcode: $barcode) {
      ...item
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useItemByBarcodeQuery__
 *
 * To run a query within a React component, call `useItemByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemByBarcodeQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useItemByBarcodeQuery(
  baseOptions: Apollo.QueryHookOptions<ItemByBarcodeQuery, ItemByBarcodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemByBarcodeQuery, ItemByBarcodeQueryVariables>(ItemByBarcodeDocument, options);
}
export function useItemByBarcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemByBarcodeQuery, ItemByBarcodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemByBarcodeQuery, ItemByBarcodeQueryVariables>(ItemByBarcodeDocument, options);
}
export type ItemByBarcodeQueryHookResult = ReturnType<typeof useItemByBarcodeQuery>;
export type ItemByBarcodeLazyQueryHookResult = ReturnType<typeof useItemByBarcodeLazyQuery>;
export type ItemByBarcodeQueryResult = Apollo.QueryResult<ItemByBarcodeQuery, ItemByBarcodeQueryVariables>;
export const ItemByIdDocument = gql`
  query ItemById($accountID: ID!, $id: ID!) {
    item(accountID: $accountID, id: $id) {
      ...item
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useItemByIdQuery__
 *
 * To run a query within a React component, call `useItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemByIdQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemByIdQuery(baseOptions: Apollo.QueryHookOptions<ItemByIdQuery, ItemByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemByIdQuery, ItemByIdQueryVariables>(ItemByIdDocument, options);
}
export function useItemByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemByIdQuery, ItemByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemByIdQuery, ItemByIdQueryVariables>(ItemByIdDocument, options);
}
export type ItemByIdQueryHookResult = ReturnType<typeof useItemByIdQuery>;
export type ItemByIdLazyQueryHookResult = ReturnType<typeof useItemByIdLazyQuery>;
export type ItemByIdQueryResult = Apollo.QueryResult<ItemByIdQuery, ItemByIdQueryVariables>;
export const ItemDeactivateDocument = gql`
  mutation ItemDeactivate($id: ID!) {
    itemDeactivate(id: $id) {
      item {
        id
        active
      }
    }
  }
`;
export type ItemDeactivateMutationFn = Apollo.MutationFunction<ItemDeactivateMutation, ItemDeactivateMutationVariables>;

/**
 * __useItemDeactivateMutation__
 *
 * To run a mutation, you first call `useItemDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemDeactivateMutation, { data, loading, error }] = useItemDeactivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<ItemDeactivateMutation, ItemDeactivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ItemDeactivateMutation, ItemDeactivateMutationVariables>(ItemDeactivateDocument, options);
}
export type ItemDeactivateMutationHookResult = ReturnType<typeof useItemDeactivateMutation>;
export type ItemDeactivateMutationResult = Apollo.MutationResult<ItemDeactivateMutation>;
export type ItemDeactivateMutationOptions = Apollo.BaseMutationOptions<
  ItemDeactivateMutation,
  ItemDeactivateMutationVariables
>;
export const ItemDetailsDocument = gql`
  query ItemDetails($accountID: ID!, $id: ID!) {
    item(accountID: $accountID, id: $id) {
      id
      active
      state
      name
      contents
      barcode {
        id
        value
      }
      account {
        id
        number
        customer {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useItemDetailsQuery__
 *
 * To run a query within a React component, call `useItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemDetailsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<ItemDetailsQuery, ItemDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemDetailsQuery, ItemDetailsQueryVariables>(ItemDetailsDocument, options);
}
export function useItemDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemDetailsQuery, ItemDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemDetailsQuery, ItemDetailsQueryVariables>(ItemDetailsDocument, options);
}
export type ItemDetailsQueryHookResult = ReturnType<typeof useItemDetailsQuery>;
export type ItemDetailsLazyQueryHookResult = ReturnType<typeof useItemDetailsLazyQuery>;
export type ItemDetailsQueryResult = Apollo.QueryResult<ItemDetailsQuery, ItemDetailsQueryVariables>;
export const ItemManualReturnDocument = gql`
  mutation ItemManualReturn($itemID: ID!, $orderID: ID!) {
    result: itemManualReturn(itemID: $itemID, orderID: $orderID) {
      item {
        id
        state
      }
    }
  }
`;
export type ItemManualReturnMutationFn = Apollo.MutationFunction<
  ItemManualReturnMutation,
  ItemManualReturnMutationVariables
>;

/**
 * __useItemManualReturnMutation__
 *
 * To run a mutation, you first call `useItemManualReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemManualReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemManualReturnMutation, { data, loading, error }] = useItemManualReturnMutation({
 *   variables: {
 *      itemID: // value for 'itemID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useItemManualReturnMutation(
  baseOptions?: Apollo.MutationHookOptions<ItemManualReturnMutation, ItemManualReturnMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ItemManualReturnMutation, ItemManualReturnMutationVariables>(
    ItemManualReturnDocument,
    options,
  );
}
export type ItemManualReturnMutationHookResult = ReturnType<typeof useItemManualReturnMutation>;
export type ItemManualReturnMutationResult = Apollo.MutationResult<ItemManualReturnMutation>;
export type ItemManualReturnMutationOptions = Apollo.BaseMutationOptions<
  ItemManualReturnMutation,
  ItemManualReturnMutationVariables
>;
export const FormattedAccountLaborAgreementDocument = gql`
  query FormattedAccountLaborAgreement($accountId: ID!) {
    formattedAccountLaborAgreement(accountId: $accountId)
  }
`;

/**
 * __useFormattedAccountLaborAgreementQuery__
 *
 * To run a query within a React component, call `useFormattedAccountLaborAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormattedAccountLaborAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormattedAccountLaborAgreementQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useFormattedAccountLaborAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormattedAccountLaborAgreementQuery,
    FormattedAccountLaborAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormattedAccountLaborAgreementQuery, FormattedAccountLaborAgreementQueryVariables>(
    FormattedAccountLaborAgreementDocument,
    options,
  );
}
export function useFormattedAccountLaborAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormattedAccountLaborAgreementQuery,
    FormattedAccountLaborAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormattedAccountLaborAgreementQuery, FormattedAccountLaborAgreementQueryVariables>(
    FormattedAccountLaborAgreementDocument,
    options,
  );
}
export type FormattedAccountLaborAgreementQueryHookResult = ReturnType<typeof useFormattedAccountLaborAgreementQuery>;
export type FormattedAccountLaborAgreementLazyQueryHookResult = ReturnType<
  typeof useFormattedAccountLaborAgreementLazyQuery
>;
export type FormattedAccountLaborAgreementQueryResult = Apollo.QueryResult<
  FormattedAccountLaborAgreementQuery,
  FormattedAccountLaborAgreementQueryVariables
>;
export const FormattedLaborAgreementDocument = gql`
  query FormattedLaborAgreement(
    $pricingSetId: ID!
    $rateGroupId: ID!
    $cuft: Float!
    $orderZip: String!
    $laborRateId: ID
    $maxLaborAmount: Float
    $packageSetEntryIds: [ID!]
  ) {
    formattedLaborAgreement(
      pricingSetId: $pricingSetId
      rateGroupId: $rateGroupId
      cuft: $cuft
      orderZip: $orderZip
      laborRateId: $laborRateId
      maxLaborAmount: $maxLaborAmount
      packageSetEntryIds: $packageSetEntryIds
    )
  }
`;

/**
 * __useFormattedLaborAgreementQuery__
 *
 * To run a query within a React component, call `useFormattedLaborAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormattedLaborAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormattedLaborAgreementQuery({
 *   variables: {
 *      pricingSetId: // value for 'pricingSetId'
 *      rateGroupId: // value for 'rateGroupId'
 *      cuft: // value for 'cuft'
 *      orderZip: // value for 'orderZip'
 *      laborRateId: // value for 'laborRateId'
 *      maxLaborAmount: // value for 'maxLaborAmount'
 *      packageSetEntryIds: // value for 'packageSetEntryIds'
 *   },
 * });
 */
export function useFormattedLaborAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<FormattedLaborAgreementQuery, FormattedLaborAgreementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormattedLaborAgreementQuery, FormattedLaborAgreementQueryVariables>(
    FormattedLaborAgreementDocument,
    options,
  );
}
export function useFormattedLaborAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormattedLaborAgreementQuery, FormattedLaborAgreementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormattedLaborAgreementQuery, FormattedLaborAgreementQueryVariables>(
    FormattedLaborAgreementDocument,
    options,
  );
}
export type FormattedLaborAgreementQueryHookResult = ReturnType<typeof useFormattedLaborAgreementQuery>;
export type FormattedLaborAgreementLazyQueryHookResult = ReturnType<typeof useFormattedLaborAgreementLazyQuery>;
export type FormattedLaborAgreementQueryResult = Apollo.QueryResult<
  FormattedLaborAgreementQuery,
  FormattedLaborAgreementQueryVariables
>;
export const LandingLocationCityDocument = gql`
  query LandingLocationCity($id: ID!) {
    city: landingLocationCity(id: $id) {
      id
      globalID
      name
      slug
      latitude
      longitude
      region {
        id
        name
      }
      state {
        id
        name
      }
      contents {
        id
        service
        blurb
        reviews {
          id
          author
          message
        }
      }
    }
  }
`;

/**
 * __useLandingLocationCityQuery__
 *
 * To run a query within a React component, call `useLandingLocationCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationCityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandingLocationCityQuery(
  baseOptions: Apollo.QueryHookOptions<LandingLocationCityQuery, LandingLocationCityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationCityQuery, LandingLocationCityQueryVariables>(
    LandingLocationCityDocument,
    options,
  );
}
export function useLandingLocationCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationCityQuery, LandingLocationCityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationCityQuery, LandingLocationCityQueryVariables>(
    LandingLocationCityDocument,
    options,
  );
}
export type LandingLocationCityQueryHookResult = ReturnType<typeof useLandingLocationCityQuery>;
export type LandingLocationCityLazyQueryHookResult = ReturnType<typeof useLandingLocationCityLazyQuery>;
export type LandingLocationCityQueryResult = Apollo.QueryResult<
  LandingLocationCityQuery,
  LandingLocationCityQueryVariables
>;
export const LandingLocationCityUpsertDocument = gql`
  mutation LandingLocationCityUpsert($id: ID, $input: Landing__Location__CityInput!) {
    result: landingLocationCityUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        globalID
        name
        slug
        latitude
        longitude
        region {
          id
          name
        }
        state {
          id
          name
        }
        contents {
          id
          service
          blurb
          reviews {
            id
            author
            message
          }
        }
      }
    }
  }
`;
export type LandingLocationCityUpsertMutationFn = Apollo.MutationFunction<
  LandingLocationCityUpsertMutation,
  LandingLocationCityUpsertMutationVariables
>;

/**
 * __useLandingLocationCityUpsertMutation__
 *
 * To run a mutation, you first call `useLandingLocationCityUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationCityUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingLocationCityUpsertMutation, { data, loading, error }] = useLandingLocationCityUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingLocationCityUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingLocationCityUpsertMutation,
    LandingLocationCityUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LandingLocationCityUpsertMutation, LandingLocationCityUpsertMutationVariables>(
    LandingLocationCityUpsertDocument,
    options,
  );
}
export type LandingLocationCityUpsertMutationHookResult = ReturnType<typeof useLandingLocationCityUpsertMutation>;
export type LandingLocationCityUpsertMutationResult = Apollo.MutationResult<LandingLocationCityUpsertMutation>;
export type LandingLocationCityUpsertMutationOptions = Apollo.BaseMutationOptions<
  LandingLocationCityUpsertMutation,
  LandingLocationCityUpsertMutationVariables
>;
export const LandingLocationStatesDocument = gql`
  query LandingLocationStates {
    states: landingLocationStates {
      id
      name
    }
  }
`;

/**
 * __useLandingLocationStatesQuery__
 *
 * To run a query within a React component, call `useLandingLocationStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLandingLocationStatesQuery(
  baseOptions?: Apollo.QueryHookOptions<LandingLocationStatesQuery, LandingLocationStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationStatesQuery, LandingLocationStatesQueryVariables>(
    LandingLocationStatesDocument,
    options,
  );
}
export function useLandingLocationStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationStatesQuery, LandingLocationStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationStatesQuery, LandingLocationStatesQueryVariables>(
    LandingLocationStatesDocument,
    options,
  );
}
export type LandingLocationStatesQueryHookResult = ReturnType<typeof useLandingLocationStatesQuery>;
export type LandingLocationStatesLazyQueryHookResult = ReturnType<typeof useLandingLocationStatesLazyQuery>;
export type LandingLocationStatesQueryResult = Apollo.QueryResult<
  LandingLocationStatesQuery,
  LandingLocationStatesQueryVariables
>;
export const PaginatedLandingLocationCitiesDocument = gql`
  query PaginatedLandingLocationCities($query: String, $page: Int) {
    paginated: paginatedLandingLocationCities(query: $query, page: $page) {
      results {
        id
        name
        slug
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLandingLocationCitiesQuery__
 *
 * To run a query within a React component, call `usePaginatedLandingLocationCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLandingLocationCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLandingLocationCitiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedLandingLocationCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedLandingLocationCitiesQuery,
    PaginatedLandingLocationCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedLandingLocationCitiesQuery, PaginatedLandingLocationCitiesQueryVariables>(
    PaginatedLandingLocationCitiesDocument,
    options,
  );
}
export function usePaginatedLandingLocationCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedLandingLocationCitiesQuery,
    PaginatedLandingLocationCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedLandingLocationCitiesQuery, PaginatedLandingLocationCitiesQueryVariables>(
    PaginatedLandingLocationCitiesDocument,
    options,
  );
}
export type PaginatedLandingLocationCitiesQueryHookResult = ReturnType<typeof usePaginatedLandingLocationCitiesQuery>;
export type PaginatedLandingLocationCitiesLazyQueryHookResult = ReturnType<
  typeof usePaginatedLandingLocationCitiesLazyQuery
>;
export type PaginatedLandingLocationCitiesQueryResult = Apollo.QueryResult<
  PaginatedLandingLocationCitiesQuery,
  PaginatedLandingLocationCitiesQueryVariables
>;
export const LandingLocationContentDocument = gql`
  query LandingLocationContent($id: ID!) {
    content: landingLocationContent(id: $id) {
      id
      service
      title
      headline
      description
      blurb
      heroAlt
      heroImage {
        id
        imgixURL
      }
      reviews {
        id
        author
        message
        rating
      }
    }
  }
`;

/**
 * __useLandingLocationContentQuery__
 *
 * To run a query within a React component, call `useLandingLocationContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandingLocationContentQuery(
  baseOptions: Apollo.QueryHookOptions<LandingLocationContentQuery, LandingLocationContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationContentQuery, LandingLocationContentQueryVariables>(
    LandingLocationContentDocument,
    options,
  );
}
export function useLandingLocationContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationContentQuery, LandingLocationContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationContentQuery, LandingLocationContentQueryVariables>(
    LandingLocationContentDocument,
    options,
  );
}
export type LandingLocationContentQueryHookResult = ReturnType<typeof useLandingLocationContentQuery>;
export type LandingLocationContentLazyQueryHookResult = ReturnType<typeof useLandingLocationContentLazyQuery>;
export type LandingLocationContentQueryResult = Apollo.QueryResult<
  LandingLocationContentQuery,
  LandingLocationContentQueryVariables
>;
export const LandingLocationContentUpsertDocument = gql`
  mutation LandingLocationContentUpsert($id: ID, $input: Landing__Location__ContentInput!) {
    result: landingLocationContentUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        service
        title
        description
        blurb
        heroImage {
          id
          imgixURL
        }
        reviews {
          id
          author
          message
          rating
        }
      }
    }
  }
`;
export type LandingLocationContentUpsertMutationFn = Apollo.MutationFunction<
  LandingLocationContentUpsertMutation,
  LandingLocationContentUpsertMutationVariables
>;

/**
 * __useLandingLocationContentUpsertMutation__
 *
 * To run a mutation, you first call `useLandingLocationContentUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationContentUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingLocationContentUpsertMutation, { data, loading, error }] = useLandingLocationContentUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingLocationContentUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingLocationContentUpsertMutation,
    LandingLocationContentUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LandingLocationContentUpsertMutation, LandingLocationContentUpsertMutationVariables>(
    LandingLocationContentUpsertDocument,
    options,
  );
}
export type LandingLocationContentUpsertMutationHookResult = ReturnType<typeof useLandingLocationContentUpsertMutation>;
export type LandingLocationContentUpsertMutationResult = Apollo.MutationResult<LandingLocationContentUpsertMutation>;
export type LandingLocationContentUpsertMutationOptions = Apollo.BaseMutationOptions<
  LandingLocationContentUpsertMutation,
  LandingLocationContentUpsertMutationVariables
>;
export const LandingLocationCitiesDocument = gql`
  query LandingLocationCities {
    cities: landingLocationCities {
      id
      name
    }
  }
`;

/**
 * __useLandingLocationCitiesQuery__
 *
 * To run a query within a React component, call `useLandingLocationCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLandingLocationCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<LandingLocationCitiesQuery, LandingLocationCitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationCitiesQuery, LandingLocationCitiesQueryVariables>(
    LandingLocationCitiesDocument,
    options,
  );
}
export function useLandingLocationCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationCitiesQuery, LandingLocationCitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationCitiesQuery, LandingLocationCitiesQueryVariables>(
    LandingLocationCitiesDocument,
    options,
  );
}
export type LandingLocationCitiesQueryHookResult = ReturnType<typeof useLandingLocationCitiesQuery>;
export type LandingLocationCitiesLazyQueryHookResult = ReturnType<typeof useLandingLocationCitiesLazyQuery>;
export type LandingLocationCitiesQueryResult = Apollo.QueryResult<
  LandingLocationCitiesQuery,
  LandingLocationCitiesQueryVariables
>;
export const LandingLocationNeighborhoodDocument = gql`
  query LandingLocationNeighborhood($id: ID!) {
    neighborhood: landingLocationNeighborhood(id: $id) {
      id
      globalID
      name
      slug
      latitude
      longitude
      city {
        id
        name
      }
      contents {
        id
        service
        blurb
      }
    }
  }
`;

/**
 * __useLandingLocationNeighborhoodQuery__
 *
 * To run a query within a React component, call `useLandingLocationNeighborhoodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationNeighborhoodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationNeighborhoodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandingLocationNeighborhoodQuery(
  baseOptions: Apollo.QueryHookOptions<LandingLocationNeighborhoodQuery, LandingLocationNeighborhoodQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationNeighborhoodQuery, LandingLocationNeighborhoodQueryVariables>(
    LandingLocationNeighborhoodDocument,
    options,
  );
}
export function useLandingLocationNeighborhoodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandingLocationNeighborhoodQuery,
    LandingLocationNeighborhoodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationNeighborhoodQuery, LandingLocationNeighborhoodQueryVariables>(
    LandingLocationNeighborhoodDocument,
    options,
  );
}
export type LandingLocationNeighborhoodQueryHookResult = ReturnType<typeof useLandingLocationNeighborhoodQuery>;
export type LandingLocationNeighborhoodLazyQueryHookResult = ReturnType<typeof useLandingLocationNeighborhoodLazyQuery>;
export type LandingLocationNeighborhoodQueryResult = Apollo.QueryResult<
  LandingLocationNeighborhoodQuery,
  LandingLocationNeighborhoodQueryVariables
>;
export const LandingLocationNeighborhoodUpsertDocument = gql`
  mutation LandingLocationNeighborhoodUpsert($id: ID, $input: Landing__Location__NeighborhoodInput!) {
    result: landingLocationNeighborhoodUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        globalID
        name
        slug
        latitude
        longitude
        city {
          id
          name
        }
        contents {
          id
          service
          blurb
          reviews {
            id
            author
            message
          }
        }
      }
    }
  }
`;
export type LandingLocationNeighborhoodUpsertMutationFn = Apollo.MutationFunction<
  LandingLocationNeighborhoodUpsertMutation,
  LandingLocationNeighborhoodUpsertMutationVariables
>;

/**
 * __useLandingLocationNeighborhoodUpsertMutation__
 *
 * To run a mutation, you first call `useLandingLocationNeighborhoodUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationNeighborhoodUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingLocationNeighborhoodUpsertMutation, { data, loading, error }] = useLandingLocationNeighborhoodUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingLocationNeighborhoodUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingLocationNeighborhoodUpsertMutation,
    LandingLocationNeighborhoodUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LandingLocationNeighborhoodUpsertMutation,
    LandingLocationNeighborhoodUpsertMutationVariables
  >(LandingLocationNeighborhoodUpsertDocument, options);
}
export type LandingLocationNeighborhoodUpsertMutationHookResult = ReturnType<
  typeof useLandingLocationNeighborhoodUpsertMutation
>;
export type LandingLocationNeighborhoodUpsertMutationResult =
  Apollo.MutationResult<LandingLocationNeighborhoodUpsertMutation>;
export type LandingLocationNeighborhoodUpsertMutationOptions = Apollo.BaseMutationOptions<
  LandingLocationNeighborhoodUpsertMutation,
  LandingLocationNeighborhoodUpsertMutationVariables
>;
export const PaginatedLandingLocationNeighborhoodsDocument = gql`
  query PaginatedLandingLocationNeighborhoods($query: String, $page: Int) {
    paginated: paginatedLandingLocationNeighborhoods(query: $query, page: $page) {
      results {
        id
        name
        slug
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLandingLocationNeighborhoodsQuery__
 *
 * To run a query within a React component, call `usePaginatedLandingLocationNeighborhoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLandingLocationNeighborhoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLandingLocationNeighborhoodsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedLandingLocationNeighborhoodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedLandingLocationNeighborhoodsQuery,
    PaginatedLandingLocationNeighborhoodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedLandingLocationNeighborhoodsQuery,
    PaginatedLandingLocationNeighborhoodsQueryVariables
  >(PaginatedLandingLocationNeighborhoodsDocument, options);
}
export function usePaginatedLandingLocationNeighborhoodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedLandingLocationNeighborhoodsQuery,
    PaginatedLandingLocationNeighborhoodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedLandingLocationNeighborhoodsQuery,
    PaginatedLandingLocationNeighborhoodsQueryVariables
  >(PaginatedLandingLocationNeighborhoodsDocument, options);
}
export type PaginatedLandingLocationNeighborhoodsQueryHookResult = ReturnType<
  typeof usePaginatedLandingLocationNeighborhoodsQuery
>;
export type PaginatedLandingLocationNeighborhoodsLazyQueryHookResult = ReturnType<
  typeof usePaginatedLandingLocationNeighborhoodsLazyQuery
>;
export type PaginatedLandingLocationNeighborhoodsQueryResult = Apollo.QueryResult<
  PaginatedLandingLocationNeighborhoodsQuery,
  PaginatedLandingLocationNeighborhoodsQueryVariables
>;
export const LandingLocationRouteDocument = gql`
  query LandingLocationRoute($id: ID!) {
    route: landingLocationRoute(id: $id) {
      id
      globalID
      slug
      featured
      contents {
        id
        service
        blurb
        reviews {
          id
          author
          message
        }
      }
      origin {
        ... on Landing__Location__State {
          id
          name
        }
        ... on Landing__Location__City {
          id
          name
        }
        ... on Landing__Location__Neighborhood {
          id
          name
        }
      }
      destination {
        ... on Landing__Location__State {
          id
          name
        }
        ... on Landing__Location__City {
          id
          name
        }
        ... on Landing__Location__Neighborhood {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useLandingLocationRouteQuery__
 *
 * To run a query within a React component, call `useLandingLocationRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandingLocationRouteQuery(
  baseOptions: Apollo.QueryHookOptions<LandingLocationRouteQuery, LandingLocationRouteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationRouteQuery, LandingLocationRouteQueryVariables>(
    LandingLocationRouteDocument,
    options,
  );
}
export function useLandingLocationRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationRouteQuery, LandingLocationRouteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationRouteQuery, LandingLocationRouteQueryVariables>(
    LandingLocationRouteDocument,
    options,
  );
}
export type LandingLocationRouteQueryHookResult = ReturnType<typeof useLandingLocationRouteQuery>;
export type LandingLocationRouteLazyQueryHookResult = ReturnType<typeof useLandingLocationRouteLazyQuery>;
export type LandingLocationRouteQueryResult = Apollo.QueryResult<
  LandingLocationRouteQuery,
  LandingLocationRouteQueryVariables
>;
export const LandingLocationRouteUpsertDocument = gql`
  mutation LandingLocationRouteUpsert($id: ID, $input: Landing__Location__RouteInput!) {
    result: landingLocationRouteUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        globalID
        slug
        origin {
          ... on Landing__Location__State {
            id
            name
          }
          ... on Landing__Location__City {
            id
            name
          }
          ... on Landing__Location__Neighborhood {
            id
            name
          }
        }
        destination {
          ... on Landing__Location__State {
            id
            name
          }
          ... on Landing__Location__City {
            id
            name
          }
          ... on Landing__Location__Neighborhood {
            id
            name
          }
        }
      }
    }
  }
`;
export type LandingLocationRouteUpsertMutationFn = Apollo.MutationFunction<
  LandingLocationRouteUpsertMutation,
  LandingLocationRouteUpsertMutationVariables
>;

/**
 * __useLandingLocationRouteUpsertMutation__
 *
 * To run a mutation, you first call `useLandingLocationRouteUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationRouteUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingLocationRouteUpsertMutation, { data, loading, error }] = useLandingLocationRouteUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingLocationRouteUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingLocationRouteUpsertMutation,
    LandingLocationRouteUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LandingLocationRouteUpsertMutation, LandingLocationRouteUpsertMutationVariables>(
    LandingLocationRouteUpsertDocument,
    options,
  );
}
export type LandingLocationRouteUpsertMutationHookResult = ReturnType<typeof useLandingLocationRouteUpsertMutation>;
export type LandingLocationRouteUpsertMutationResult = Apollo.MutationResult<LandingLocationRouteUpsertMutation>;
export type LandingLocationRouteUpsertMutationOptions = Apollo.BaseMutationOptions<
  LandingLocationRouteUpsertMutation,
  LandingLocationRouteUpsertMutationVariables
>;
export const PaginatedLandingLocationRoutesDocument = gql`
  query PaginatedLandingLocationRoutes($query: String, $page: Int) {
    paginated: paginatedLandingLocationRoutes(query: $query, page: $page) {
      results {
        id
        slug
        featured
        origin {
          ... on Landing__Location__State {
            id
            name
          }
          ... on Landing__Location__City {
            id
            name
          }
          ... on Landing__Location__Neighborhood {
            id
            name
          }
        }
        destination {
          ... on Landing__Location__State {
            id
            name
          }
          ... on Landing__Location__City {
            id
            name
          }
          ... on Landing__Location__Neighborhood {
            id
            name
          }
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLandingLocationRoutesQuery__
 *
 * To run a query within a React component, call `usePaginatedLandingLocationRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLandingLocationRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLandingLocationRoutesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedLandingLocationRoutesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedLandingLocationRoutesQuery,
    PaginatedLandingLocationRoutesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedLandingLocationRoutesQuery, PaginatedLandingLocationRoutesQueryVariables>(
    PaginatedLandingLocationRoutesDocument,
    options,
  );
}
export function usePaginatedLandingLocationRoutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedLandingLocationRoutesQuery,
    PaginatedLandingLocationRoutesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedLandingLocationRoutesQuery, PaginatedLandingLocationRoutesQueryVariables>(
    PaginatedLandingLocationRoutesDocument,
    options,
  );
}
export type PaginatedLandingLocationRoutesQueryHookResult = ReturnType<typeof usePaginatedLandingLocationRoutesQuery>;
export type PaginatedLandingLocationRoutesLazyQueryHookResult = ReturnType<
  typeof usePaginatedLandingLocationRoutesLazyQuery
>;
export type PaginatedLandingLocationRoutesQueryResult = Apollo.QueryResult<
  PaginatedLandingLocationRoutesQuery,
  PaginatedLandingLocationRoutesQueryVariables
>;
export const RouteLocationOptionsDocument = gql`
  query RouteLocationOptions {
    states: landingLocationStates {
      id
      name
      cities {
        id
        name
        neighborhoods {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useRouteLocationOptionsQuery__
 *
 * To run a query within a React component, call `useRouteLocationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteLocationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteLocationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRouteLocationOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<RouteLocationOptionsQuery, RouteLocationOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RouteLocationOptionsQuery, RouteLocationOptionsQueryVariables>(
    RouteLocationOptionsDocument,
    options,
  );
}
export function useRouteLocationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RouteLocationOptionsQuery, RouteLocationOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RouteLocationOptionsQuery, RouteLocationOptionsQueryVariables>(
    RouteLocationOptionsDocument,
    options,
  );
}
export type RouteLocationOptionsQueryHookResult = ReturnType<typeof useRouteLocationOptionsQuery>;
export type RouteLocationOptionsLazyQueryHookResult = ReturnType<typeof useRouteLocationOptionsLazyQuery>;
export type RouteLocationOptionsQueryResult = Apollo.QueryResult<
  RouteLocationOptionsQuery,
  RouteLocationOptionsQueryVariables
>;
export const LandingLocationStateDocument = gql`
  query LandingLocationState($id: ID!) {
    state: landingLocationState(id: $id) {
      id
      name
      slug
      code
      country
    }
  }
`;

/**
 * __useLandingLocationStateQuery__
 *
 * To run a query within a React component, call `useLandingLocationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingLocationStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLandingLocationStateQuery(
  baseOptions: Apollo.QueryHookOptions<LandingLocationStateQuery, LandingLocationStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingLocationStateQuery, LandingLocationStateQueryVariables>(
    LandingLocationStateDocument,
    options,
  );
}
export function useLandingLocationStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingLocationStateQuery, LandingLocationStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingLocationStateQuery, LandingLocationStateQueryVariables>(
    LandingLocationStateDocument,
    options,
  );
}
export type LandingLocationStateQueryHookResult = ReturnType<typeof useLandingLocationStateQuery>;
export type LandingLocationStateLazyQueryHookResult = ReturnType<typeof useLandingLocationStateLazyQuery>;
export type LandingLocationStateQueryResult = Apollo.QueryResult<
  LandingLocationStateQuery,
  LandingLocationStateQueryVariables
>;
export const LandingLocationStateUpsertDocument = gql`
  mutation LandingLocationStateUpsert($id: ID, $input: Landing__Location__StateInput!) {
    result: landingLocationStateUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        name
        slug
        code
        country
      }
    }
  }
`;
export type LandingLocationStateUpsertMutationFn = Apollo.MutationFunction<
  LandingLocationStateUpsertMutation,
  LandingLocationStateUpsertMutationVariables
>;

/**
 * __useLandingLocationStateUpsertMutation__
 *
 * To run a mutation, you first call `useLandingLocationStateUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingLocationStateUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingLocationStateUpsertMutation, { data, loading, error }] = useLandingLocationStateUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLandingLocationStateUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingLocationStateUpsertMutation,
    LandingLocationStateUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LandingLocationStateUpsertMutation, LandingLocationStateUpsertMutationVariables>(
    LandingLocationStateUpsertDocument,
    options,
  );
}
export type LandingLocationStateUpsertMutationHookResult = ReturnType<typeof useLandingLocationStateUpsertMutation>;
export type LandingLocationStateUpsertMutationResult = Apollo.MutationResult<LandingLocationStateUpsertMutation>;
export type LandingLocationStateUpsertMutationOptions = Apollo.BaseMutationOptions<
  LandingLocationStateUpsertMutation,
  LandingLocationStateUpsertMutationVariables
>;
export const PaginatedLandingLocationStatesDocument = gql`
  query PaginatedLandingLocationStates($query: String, $page: Int!) {
    paginated: paginatedLandingLocationStates(query: $query, page: $page) {
      results {
        id
        name
        slug
        code
        country
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLandingLocationStatesQuery__
 *
 * To run a query within a React component, call `usePaginatedLandingLocationStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLandingLocationStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLandingLocationStatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedLandingLocationStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedLandingLocationStatesQuery,
    PaginatedLandingLocationStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedLandingLocationStatesQuery, PaginatedLandingLocationStatesQueryVariables>(
    PaginatedLandingLocationStatesDocument,
    options,
  );
}
export function usePaginatedLandingLocationStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedLandingLocationStatesQuery,
    PaginatedLandingLocationStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedLandingLocationStatesQuery, PaginatedLandingLocationStatesQueryVariables>(
    PaginatedLandingLocationStatesDocument,
    options,
  );
}
export type PaginatedLandingLocationStatesQueryHookResult = ReturnType<typeof usePaginatedLandingLocationStatesQuery>;
export type PaginatedLandingLocationStatesLazyQueryHookResult = ReturnType<
  typeof usePaginatedLandingLocationStatesLazyQuery
>;
export type PaginatedLandingLocationStatesQueryResult = Apollo.QueryResult<
  PaginatedLandingLocationStatesQuery,
  PaginatedLandingLocationStatesQueryVariables
>;
export const CallGroupsDocument = gql`
  query CallGroups {
    groups: callGroups {
      id
      name
    }
  }
`;

/**
 * __useCallGroupsQuery__
 *
 * To run a query within a React component, call `useCallGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CallGroupsQuery, CallGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CallGroupsQuery, CallGroupsQueryVariables>(CallGroupsDocument, options);
}
export function useCallGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CallGroupsQuery, CallGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CallGroupsQuery, CallGroupsQueryVariables>(CallGroupsDocument, options);
}
export type CallGroupsQueryHookResult = ReturnType<typeof useCallGroupsQuery>;
export type CallGroupsLazyQueryHookResult = ReturnType<typeof useCallGroupsLazyQuery>;
export type CallGroupsQueryResult = Apollo.QueryResult<CallGroupsQuery, CallGroupsQueryVariables>;
export const LeadCloseCrmLinkDocument = gql`
  query LeadCloseCRMLink($id: ID!) {
    lead(id: $id) {
      id
      closeCRMLink: closeCrmLink
    }
  }
`;

/**
 * __useLeadCloseCrmLinkQuery__
 *
 * To run a query within a React component, call `useLeadCloseCrmLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadCloseCrmLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadCloseCrmLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeadCloseCrmLinkQuery(
  baseOptions: Apollo.QueryHookOptions<LeadCloseCrmLinkQuery, LeadCloseCrmLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeadCloseCrmLinkQuery, LeadCloseCrmLinkQueryVariables>(LeadCloseCrmLinkDocument, options);
}
export function useLeadCloseCrmLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeadCloseCrmLinkQuery, LeadCloseCrmLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeadCloseCrmLinkQuery, LeadCloseCrmLinkQueryVariables>(LeadCloseCrmLinkDocument, options);
}
export type LeadCloseCrmLinkQueryHookResult = ReturnType<typeof useLeadCloseCrmLinkQuery>;
export type LeadCloseCrmLinkLazyQueryHookResult = ReturnType<typeof useLeadCloseCrmLinkLazyQuery>;
export type LeadCloseCrmLinkQueryResult = Apollo.QueryResult<LeadCloseCrmLinkQuery, LeadCloseCrmLinkQueryVariables>;
export const LeadCreateDocument = gql`
  mutation LeadCreate($input: CRM__LeadInput!) {
    result: leadCreate(input: $input) {
      lead {
        id
      }
      status
      error
    }
  }
`;
export type LeadCreateMutationFn = Apollo.MutationFunction<LeadCreateMutation, LeadCreateMutationVariables>;

/**
 * __useLeadCreateMutation__
 *
 * To run a mutation, you first call `useLeadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leadCreateMutation, { data, loading, error }] = useLeadCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<LeadCreateMutation, LeadCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeadCreateMutation, LeadCreateMutationVariables>(LeadCreateDocument, options);
}
export type LeadCreateMutationHookResult = ReturnType<typeof useLeadCreateMutation>;
export type LeadCreateMutationResult = Apollo.MutationResult<LeadCreateMutation>;
export type LeadCreateMutationOptions = Apollo.BaseMutationOptions<LeadCreateMutation, LeadCreateMutationVariables>;
export const PaginatedLeadsDocument = gql`
  query PaginatedLeads($page: Int!, $filters: LeadFiltersInput!) {
    paginated: paginatedLeads(page: $page, filters: $filters) {
      results {
        id
        name
        email
        closeLeadID
        status
        zip
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLeadsQuery__
 *
 * To run a query within a React component, call `usePaginatedLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLeadsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedLeadsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedLeadsQuery, PaginatedLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedLeadsQuery, PaginatedLeadsQueryVariables>(PaginatedLeadsDocument, options);
}
export function usePaginatedLeadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedLeadsQuery, PaginatedLeadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedLeadsQuery, PaginatedLeadsQueryVariables>(PaginatedLeadsDocument, options);
}
export type PaginatedLeadsQueryHookResult = ReturnType<typeof usePaginatedLeadsQuery>;
export type PaginatedLeadsLazyQueryHookResult = ReturnType<typeof usePaginatedLeadsLazyQuery>;
export type PaginatedLeadsQueryResult = Apollo.QueryResult<PaginatedLeadsQuery, PaginatedLeadsQueryVariables>;
export const PalletTasksDocument = gql`
  query PalletTasks($destinationLocationID: ID!) {
    palletTasks(destinationLocationID: $destinationLocationID) {
      id
      completedAt
      canceledAt
      user {
        name
      }
      image {
        id
        size
        source
      }
    }
  }
`;

/**
 * __usePalletTasksQuery__
 *
 * To run a query within a React component, call `usePalletTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletTasksQuery({
 *   variables: {
 *      destinationLocationID: // value for 'destinationLocationID'
 *   },
 * });
 */
export function usePalletTasksQuery(baseOptions: Apollo.QueryHookOptions<PalletTasksQuery, PalletTasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletTasksQuery, PalletTasksQueryVariables>(PalletTasksDocument, options);
}
export function usePalletTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletTasksQuery, PalletTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletTasksQuery, PalletTasksQueryVariables>(PalletTasksDocument, options);
}
export type PalletTasksQueryHookResult = ReturnType<typeof usePalletTasksQuery>;
export type PalletTasksLazyQueryHookResult = ReturnType<typeof usePalletTasksLazyQuery>;
export type PalletTasksQueryResult = Apollo.QueryResult<PalletTasksQuery, PalletTasksQueryVariables>;
export const BuildLogisticsAccountDocument = gql`
  mutation BuildLogisticsAccount($input: Logistics__AccountInput!) {
    buildLogisticsAccount(input: $input) {
      status
      error
      id
    }
  }
`;
export type BuildLogisticsAccountMutationFn = Apollo.MutationFunction<
  BuildLogisticsAccountMutation,
  BuildLogisticsAccountMutationVariables
>;

/**
 * __useBuildLogisticsAccountMutation__
 *
 * To run a mutation, you first call `useBuildLogisticsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildLogisticsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildLogisticsAccountMutation, { data, loading, error }] = useBuildLogisticsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildLogisticsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildLogisticsAccountMutation, BuildLogisticsAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildLogisticsAccountMutation, BuildLogisticsAccountMutationVariables>(
    BuildLogisticsAccountDocument,
    options,
  );
}
export type BuildLogisticsAccountMutationHookResult = ReturnType<typeof useBuildLogisticsAccountMutation>;
export type BuildLogisticsAccountMutationResult = Apollo.MutationResult<BuildLogisticsAccountMutation>;
export type BuildLogisticsAccountMutationOptions = Apollo.BaseMutationOptions<
  BuildLogisticsAccountMutation,
  BuildLogisticsAccountMutationVariables
>;
export const CancelLogisticsLoadDocument = gql`
  mutation CancelLogisticsLoad($input: Logistics__CancelLoadInput!) {
    cancelLogisticsLoad(input: $input) {
      status
      error
    }
  }
`;
export type CancelLogisticsLoadMutationFn = Apollo.MutationFunction<
  CancelLogisticsLoadMutation,
  CancelLogisticsLoadMutationVariables
>;

/**
 * __useCancelLogisticsLoadMutation__
 *
 * To run a mutation, you first call `useCancelLogisticsLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelLogisticsLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelLogisticsLoadMutation, { data, loading, error }] = useCancelLogisticsLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelLogisticsLoadMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelLogisticsLoadMutation, CancelLogisticsLoadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelLogisticsLoadMutation, CancelLogisticsLoadMutationVariables>(
    CancelLogisticsLoadDocument,
    options,
  );
}
export type CancelLogisticsLoadMutationHookResult = ReturnType<typeof useCancelLogisticsLoadMutation>;
export type CancelLogisticsLoadMutationResult = Apollo.MutationResult<CancelLogisticsLoadMutation>;
export type CancelLogisticsLoadMutationOptions = Apollo.BaseMutationOptions<
  CancelLogisticsLoadMutation,
  CancelLogisticsLoadMutationVariables
>;
export const LogisticsLoadInventoryDocument = gql`
  query LogisticsLoadInventory($id: ID!) {
    palletInventory: logisticsLoadInventory(id: $id) {
      id
      state
      itemsCount
      height {
        value
      }
      type {
        id
        name
      }
      barcode {
        value
      }
      grade {
        id
        image {
          source
          size
        }
      }
      location {
        id
        value
      }
      warehouse {
        id
        name
      }
      skuValues
      isDamaged
    }
  }
`;

/**
 * __useLogisticsLoadInventoryQuery__
 *
 * To run a query within a React component, call `useLogisticsLoadInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsLoadInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsLoadInventoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsLoadInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsLoadInventoryQuery, LogisticsLoadInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsLoadInventoryQuery, LogisticsLoadInventoryQueryVariables>(
    LogisticsLoadInventoryDocument,
    options,
  );
}
export function useLogisticsLoadInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsLoadInventoryQuery, LogisticsLoadInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsLoadInventoryQuery, LogisticsLoadInventoryQueryVariables>(
    LogisticsLoadInventoryDocument,
    options,
  );
}
export type LogisticsLoadInventoryQueryHookResult = ReturnType<typeof useLogisticsLoadInventoryQuery>;
export type LogisticsLoadInventoryLazyQueryHookResult = ReturnType<typeof useLogisticsLoadInventoryLazyQuery>;
export type LogisticsLoadInventoryQueryResult = Apollo.QueryResult<
  LogisticsLoadInventoryQuery,
  LogisticsLoadInventoryQueryVariables
>;
export const ReopenLogisticsLoadDocument = gql`
  mutation ReopenLogisticsLoad($id: ID!) {
    reopenLogisticsLoad(id: $id) {
      status
      error
    }
  }
`;
export type ReopenLogisticsLoadMutationFn = Apollo.MutationFunction<
  ReopenLogisticsLoadMutation,
  ReopenLogisticsLoadMutationVariables
>;

/**
 * __useReopenLogisticsLoadMutation__
 *
 * To run a mutation, you first call `useReopenLogisticsLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenLogisticsLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenLogisticsLoadMutation, { data, loading, error }] = useReopenLogisticsLoadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReopenLogisticsLoadMutation(
  baseOptions?: Apollo.MutationHookOptions<ReopenLogisticsLoadMutation, ReopenLogisticsLoadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReopenLogisticsLoadMutation, ReopenLogisticsLoadMutationVariables>(
    ReopenLogisticsLoadDocument,
    options,
  );
}
export type ReopenLogisticsLoadMutationHookResult = ReturnType<typeof useReopenLogisticsLoadMutation>;
export type ReopenLogisticsLoadMutationResult = Apollo.MutationResult<ReopenLogisticsLoadMutation>;
export type ReopenLogisticsLoadMutationOptions = Apollo.BaseMutationOptions<
  ReopenLogisticsLoadMutation,
  ReopenLogisticsLoadMutationVariables
>;
export const LogisticsSkusDocument = gql`
  query LogisticsSkus($ids: [ID!]!) {
    logisticsSkus(ids: $ids) {
      id
      value
      account {
        id
        customer {
          name
        }
      }
    }
  }
`;

/**
 * __useLogisticsSkusQuery__
 *
 * To run a query within a React component, call `useLogisticsSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkusQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLogisticsSkusQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsSkusQuery, LogisticsSkusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkusQuery, LogisticsSkusQueryVariables>(LogisticsSkusDocument, options);
}
export function useLogisticsSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsSkusQuery, LogisticsSkusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkusQuery, LogisticsSkusQueryVariables>(LogisticsSkusDocument, options);
}
export type LogisticsSkusQueryHookResult = ReturnType<typeof useLogisticsSkusQuery>;
export type LogisticsSkusLazyQueryHookResult = ReturnType<typeof useLogisticsSkusLazyQuery>;
export type LogisticsSkusQueryResult = Apollo.QueryResult<LogisticsSkusQuery, LogisticsSkusQueryVariables>;
export const LogisticsLoadDocument = gql`
  query LogisticsLoad($id: ID!) {
    load: logisticsLoad(id: $id) {
      ...load
      shipTo {
        ...Logistics__Address
      }
      billTo {
        ...Logistics__Address
      }
      openTrailerImages {
        id
        size
        source
        state
        name
        createdAt
      }
      fullTrailerImages {
        id
        size
        source
        state
        name
        createdAt
      }
      trailerNumberImage {
        id
        size
        source
        state
        name
        createdAt
      }
      sealCodeImage {
        id
        size
        source
        state
        name
        createdAt
      }
      sealedTrailerImage {
        id
        size
        source
        state
        name
        createdAt
      }
      fulfillmentExpectations {
        id
        sku {
          id
          value
          itemsPerPallet
          requiresTeachIn
          countingUnit
          account {
            id
            customer {
              id
              name
            }
          }
        }
        quantity
        purchaseOrder {
          id
          number
          account {
            id
            customer {
              id
              name
            }
          }
          quantities {
            loadId
            itemCount
            palletCount
          }
          mustShipBy
        }
        palletLabels {
          id
          itemsPerPallet
        }
        ingestedCount
        loadedCount
        preparedCount
        damagedCount
      }
      itemsCount
      carrierDetails {
        id
        transportationArrangedBy
        businessName
        contactName
        contactPhone
        contactEmail
        scac
      }
    }
  }
  ${LoadFragmentDoc}
  ${Logistics__AddressFragmentDoc}
`;

/**
 * __useLogisticsLoadQuery__
 *
 * To run a query within a React component, call `useLogisticsLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsLoadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsLoadQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsLoadQuery, LogisticsLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsLoadQuery, LogisticsLoadQueryVariables>(LogisticsLoadDocument, options);
}
export function useLogisticsLoadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsLoadQuery, LogisticsLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsLoadQuery, LogisticsLoadQueryVariables>(LogisticsLoadDocument, options);
}
export type LogisticsLoadQueryHookResult = ReturnType<typeof useLogisticsLoadQuery>;
export type LogisticsLoadLazyQueryHookResult = ReturnType<typeof useLogisticsLoadLazyQuery>;
export type LogisticsLoadQueryResult = Apollo.QueryResult<LogisticsLoadQuery, LogisticsLoadQueryVariables>;
export const DuplicateLoadNumbersDocument = gql`
  query DuplicateLoadNumbers($loadNumber: String!) {
    duplicateLoadNumbers(loadNumber: $loadNumber) {
      id
      number
    }
  }
`;

/**
 * __useDuplicateLoadNumbersQuery__
 *
 * To run a query within a React component, call `useDuplicateLoadNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuplicateLoadNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuplicateLoadNumbersQuery({
 *   variables: {
 *      loadNumber: // value for 'loadNumber'
 *   },
 * });
 */
export function useDuplicateLoadNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<DuplicateLoadNumbersQuery, DuplicateLoadNumbersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DuplicateLoadNumbersQuery, DuplicateLoadNumbersQueryVariables>(
    DuplicateLoadNumbersDocument,
    options,
  );
}
export function useDuplicateLoadNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DuplicateLoadNumbersQuery, DuplicateLoadNumbersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DuplicateLoadNumbersQuery, DuplicateLoadNumbersQueryVariables>(
    DuplicateLoadNumbersDocument,
    options,
  );
}
export type DuplicateLoadNumbersQueryHookResult = ReturnType<typeof useDuplicateLoadNumbersQuery>;
export type DuplicateLoadNumbersLazyQueryHookResult = ReturnType<typeof useDuplicateLoadNumbersLazyQuery>;
export type DuplicateLoadNumbersQueryResult = Apollo.QueryResult<
  DuplicateLoadNumbersQuery,
  DuplicateLoadNumbersQueryVariables
>;
export const ModifyLogisticsLoadDocument = gql`
  mutation ModifyLogisticsLoad($input: Logistics__LoadInputType!) {
    modifyLogisticsLoad(input: $input) {
      status
      error
      id
    }
  }
`;
export type ModifyLogisticsLoadMutationFn = Apollo.MutationFunction<
  ModifyLogisticsLoadMutation,
  ModifyLogisticsLoadMutationVariables
>;

/**
 * __useModifyLogisticsLoadMutation__
 *
 * To run a mutation, you first call `useModifyLogisticsLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLogisticsLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLogisticsLoadMutation, { data, loading, error }] = useModifyLogisticsLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyLogisticsLoadMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyLogisticsLoadMutation, ModifyLogisticsLoadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyLogisticsLoadMutation, ModifyLogisticsLoadMutationVariables>(
    ModifyLogisticsLoadDocument,
    options,
  );
}
export type ModifyLogisticsLoadMutationHookResult = ReturnType<typeof useModifyLogisticsLoadMutation>;
export type ModifyLogisticsLoadMutationResult = Apollo.MutationResult<ModifyLogisticsLoadMutation>;
export type ModifyLogisticsLoadMutationOptions = Apollo.BaseMutationOptions<
  ModifyLogisticsLoadMutation,
  ModifyLogisticsLoadMutationVariables
>;
export const InsufficientSkuInventoryDocument = gql`
  query InsufficientSKUInventory($input: Logistics__InsufficientSKUInventoryInput!) {
    insufficientSkuInventory(input: $input) {
      id
      value
      storedCount
      upcomingLoadsNeedingSku {
        id
        number
        fulfillmentExpectations {
          id
          quantity
          sku {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useInsufficientSkuInventoryQuery__
 *
 * To run a query within a React component, call `useInsufficientSkuInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsufficientSkuInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsufficientSkuInventoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsufficientSkuInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<InsufficientSkuInventoryQuery, InsufficientSkuInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsufficientSkuInventoryQuery, InsufficientSkuInventoryQueryVariables>(
    InsufficientSkuInventoryDocument,
    options,
  );
}
export function useInsufficientSkuInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsufficientSkuInventoryQuery, InsufficientSkuInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsufficientSkuInventoryQuery, InsufficientSkuInventoryQueryVariables>(
    InsufficientSkuInventoryDocument,
    options,
  );
}
export type InsufficientSkuInventoryQueryHookResult = ReturnType<typeof useInsufficientSkuInventoryQuery>;
export type InsufficientSkuInventoryLazyQueryHookResult = ReturnType<typeof useInsufficientSkuInventoryLazyQuery>;
export type InsufficientSkuInventoryQueryResult = Apollo.QueryResult<
  InsufficientSkuInventoryQuery,
  InsufficientSkuInventoryQueryVariables
>;
export const InsufficientSkuTruckCuftDocument = gql`
  query InsufficientSKUTruckCuft($input: Logistics__InsufficientSKUTruckCuftInput!) {
    insufficientSkuTruckCuft(input: $input) {
      validForKnownDimensions
      skusWithoutDimensions {
        id
        value
      }
      suggestedLoads {
        suggestedExpectations {
          sku {
            id
            value
          }
          quantity
        }
      }
    }
  }
`;

/**
 * __useInsufficientSkuTruckCuftQuery__
 *
 * To run a query within a React component, call `useInsufficientSkuTruckCuftQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsufficientSkuTruckCuftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsufficientSkuTruckCuftQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsufficientSkuTruckCuftQuery(
  baseOptions: Apollo.QueryHookOptions<InsufficientSkuTruckCuftQuery, InsufficientSkuTruckCuftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsufficientSkuTruckCuftQuery, InsufficientSkuTruckCuftQueryVariables>(
    InsufficientSkuTruckCuftDocument,
    options,
  );
}
export function useInsufficientSkuTruckCuftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsufficientSkuTruckCuftQuery, InsufficientSkuTruckCuftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsufficientSkuTruckCuftQuery, InsufficientSkuTruckCuftQueryVariables>(
    InsufficientSkuTruckCuftDocument,
    options,
  );
}
export type InsufficientSkuTruckCuftQueryHookResult = ReturnType<typeof useInsufficientSkuTruckCuftQuery>;
export type InsufficientSkuTruckCuftLazyQueryHookResult = ReturnType<typeof useInsufficientSkuTruckCuftLazyQuery>;
export type InsufficientSkuTruckCuftQueryResult = Apollo.QueryResult<
  InsufficientSkuTruckCuftQuery,
  InsufficientSkuTruckCuftQueryVariables
>;
export const BuildLogisticsLoadDocument = gql`
  mutation BuildLogisticsLoad($input: Logistics__LoadInputType!) {
    buildLogisticsLoad(input: $input) {
      status
      error
      id
    }
  }
`;
export type BuildLogisticsLoadMutationFn = Apollo.MutationFunction<
  BuildLogisticsLoadMutation,
  BuildLogisticsLoadMutationVariables
>;

/**
 * __useBuildLogisticsLoadMutation__
 *
 * To run a mutation, you first call `useBuildLogisticsLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildLogisticsLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildLogisticsLoadMutation, { data, loading, error }] = useBuildLogisticsLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildLogisticsLoadMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildLogisticsLoadMutation, BuildLogisticsLoadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildLogisticsLoadMutation, BuildLogisticsLoadMutationVariables>(
    BuildLogisticsLoadDocument,
    options,
  );
}
export type BuildLogisticsLoadMutationHookResult = ReturnType<typeof useBuildLogisticsLoadMutation>;
export type BuildLogisticsLoadMutationResult = Apollo.MutationResult<BuildLogisticsLoadMutation>;
export type BuildLogisticsLoadMutationOptions = Apollo.BaseMutationOptions<
  BuildLogisticsLoadMutation,
  BuildLogisticsLoadMutationVariables
>;
export const OptionsDocument = gql`
  query Options {
    warehouses {
      id
      name
      active
      tz
    }
    logisticsPurchaseOrders {
      id
      account {
        id
        customer {
          id
          name
        }
      }
      number
    }
    logisticsAccounts {
      id
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useOptionsQuery__
 *
 * To run a query within a React component, call `useOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptionsQuery(baseOptions?: Apollo.QueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, options);
}
export function useOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, options);
}
export type OptionsQueryHookResult = ReturnType<typeof useOptionsQuery>;
export type OptionsLazyQueryHookResult = ReturnType<typeof useOptionsLazyQuery>;
export type OptionsQueryResult = Apollo.QueryResult<OptionsQuery, OptionsQueryVariables>;
export const LogisticsSkusForAccountDocument = gql`
  query LogisticsSkusForAccount($accountID: ID) {
    logisticsSkus(accountID: $accountID) {
      id
      value
      countingUnit
    }
  }
`;

/**
 * __useLogisticsSkusForAccountQuery__
 *
 * To run a query within a React component, call `useLogisticsSkusForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkusForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkusForAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useLogisticsSkusForAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<LogisticsSkusForAccountQuery, LogisticsSkusForAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkusForAccountQuery, LogisticsSkusForAccountQueryVariables>(
    LogisticsSkusForAccountDocument,
    options,
  );
}
export function useLogisticsSkusForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsSkusForAccountQuery, LogisticsSkusForAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkusForAccountQuery, LogisticsSkusForAccountQueryVariables>(
    LogisticsSkusForAccountDocument,
    options,
  );
}
export type LogisticsSkusForAccountQueryHookResult = ReturnType<typeof useLogisticsSkusForAccountQuery>;
export type LogisticsSkusForAccountLazyQueryHookResult = ReturnType<typeof useLogisticsSkusForAccountLazyQuery>;
export type LogisticsSkusForAccountQueryResult = Apollo.QueryResult<
  LogisticsSkusForAccountQuery,
  LogisticsSkusForAccountQueryVariables
>;
export const PaginatedLoadDocument = gql`
  query PaginatedLoad($page: Int, $filters: Logistics__LoadsFilterInputType) {
    paginated: paginatedLoads(page: $page, filters: $filters) {
      results {
        id
        type
        state
        number
        appointmentDate
        requestedAppointmentDate
        requestedAppointmentWindow
        requiresTeachIn
        warehouse {
          id
          name
          tz
        }
        fulfillmentExpectations {
          id
          quantity
          sku {
            id
            value
            account {
              id
              customer {
                name
              }
            }
          }
        }
        carrierDetails {
          id
          businessName
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedLoadQuery__
 *
 * To run a query within a React component, call `usePaginatedLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedLoadQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedLoadQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedLoadQuery, PaginatedLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedLoadQuery, PaginatedLoadQueryVariables>(PaginatedLoadDocument, options);
}
export function usePaginatedLoadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedLoadQuery, PaginatedLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedLoadQuery, PaginatedLoadQueryVariables>(PaginatedLoadDocument, options);
}
export type PaginatedLoadQueryHookResult = ReturnType<typeof usePaginatedLoadQuery>;
export type PaginatedLoadLazyQueryHookResult = ReturnType<typeof usePaginatedLoadLazyQuery>;
export type PaginatedLoadQueryResult = Apollo.QueryResult<PaginatedLoadQuery, PaginatedLoadQueryVariables>;
export const WarehousesDocument = gql`
  query Warehouses {
    warehouses {
      id
      name
      active
    }
  }
`;

/**
 * __useWarehousesQuery__
 *
 * To run a query within a React component, call `useWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<WarehousesQuery, WarehousesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WarehousesQuery, WarehousesQueryVariables>(WarehousesDocument, options);
}
export function useWarehousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WarehousesQuery, WarehousesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WarehousesQuery, WarehousesQueryVariables>(WarehousesDocument, options);
}
export type WarehousesQueryHookResult = ReturnType<typeof useWarehousesQuery>;
export type WarehousesLazyQueryHookResult = ReturnType<typeof useWarehousesLazyQuery>;
export type WarehousesQueryResult = Apollo.QueryResult<WarehousesQuery, WarehousesQueryVariables>;
export const LogisticsPalletInfoDocument = gql`
  query LogisticsPalletInfo($id: ID!) {
    pallet(id: $id) {
      id
      palletInventories {
        sku {
          id
          value
          description
          account {
            id
            customer {
              id
              name
            }
          }
        }
        quantity
      }
      loads {
        id
        number
        type
        appointmentDate
        warehouse {
          id
          tz
        }
      }
    }
  }
`;

/**
 * __useLogisticsPalletInfoQuery__
 *
 * To run a query within a React component, call `useLogisticsPalletInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsPalletInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsPalletInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsPalletInfoQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsPalletInfoQuery, LogisticsPalletInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsPalletInfoQuery, LogisticsPalletInfoQueryVariables>(
    LogisticsPalletInfoDocument,
    options,
  );
}
export function useLogisticsPalletInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsPalletInfoQuery, LogisticsPalletInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsPalletInfoQuery, LogisticsPalletInfoQueryVariables>(
    LogisticsPalletInfoDocument,
    options,
  );
}
export type LogisticsPalletInfoQueryHookResult = ReturnType<typeof useLogisticsPalletInfoQuery>;
export type LogisticsPalletInfoLazyQueryHookResult = ReturnType<typeof useLogisticsPalletInfoLazyQuery>;
export type LogisticsPalletInfoQueryResult = Apollo.QueryResult<
  LogisticsPalletInfoQuery,
  LogisticsPalletInfoQueryVariables
>;
export const BuildParcelCarrierAccountDocument = gql`
  mutation BuildParcelCarrierAccount($input: Logistics__ParcelCarrierAccountInput!) {
    buildParcelCarrierAccount(input: $input) {
      status
      error
      parcelCarrierAccount {
        id
        carrier
        accountNumber
        country
        postalCode
      }
    }
  }
`;
export type BuildParcelCarrierAccountMutationFn = Apollo.MutationFunction<
  BuildParcelCarrierAccountMutation,
  BuildParcelCarrierAccountMutationVariables
>;

/**
 * __useBuildParcelCarrierAccountMutation__
 *
 * To run a mutation, you first call `useBuildParcelCarrierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildParcelCarrierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildParcelCarrierAccountMutation, { data, loading, error }] = useBuildParcelCarrierAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildParcelCarrierAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildParcelCarrierAccountMutation,
    BuildParcelCarrierAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildParcelCarrierAccountMutation, BuildParcelCarrierAccountMutationVariables>(
    BuildParcelCarrierAccountDocument,
    options,
  );
}
export type BuildParcelCarrierAccountMutationHookResult = ReturnType<typeof useBuildParcelCarrierAccountMutation>;
export type BuildParcelCarrierAccountMutationResult = Apollo.MutationResult<BuildParcelCarrierAccountMutation>;
export type BuildParcelCarrierAccountMutationOptions = Apollo.BaseMutationOptions<
  BuildParcelCarrierAccountMutation,
  BuildParcelCarrierAccountMutationVariables
>;
export const ModifyParcelCarrierAccountDocument = gql`
  mutation ModifyParcelCarrierAccount($input: Logistics__ParcelCarrierAccountInput!) {
    modifyParcelCarrierAccount(input: $input) {
      status
      error
      parcelCarrierAccount {
        id
        carrier
        accountNumber
        country
        postalCode
      }
    }
  }
`;
export type ModifyParcelCarrierAccountMutationFn = Apollo.MutationFunction<
  ModifyParcelCarrierAccountMutation,
  ModifyParcelCarrierAccountMutationVariables
>;

/**
 * __useModifyParcelCarrierAccountMutation__
 *
 * To run a mutation, you first call `useModifyParcelCarrierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyParcelCarrierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyParcelCarrierAccountMutation, { data, loading, error }] = useModifyParcelCarrierAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyParcelCarrierAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyParcelCarrierAccountMutation,
    ModifyParcelCarrierAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyParcelCarrierAccountMutation, ModifyParcelCarrierAccountMutationVariables>(
    ModifyParcelCarrierAccountDocument,
    options,
  );
}
export type ModifyParcelCarrierAccountMutationHookResult = ReturnType<typeof useModifyParcelCarrierAccountMutation>;
export type ModifyParcelCarrierAccountMutationResult = Apollo.MutationResult<ModifyParcelCarrierAccountMutation>;
export type ModifyParcelCarrierAccountMutationOptions = Apollo.BaseMutationOptions<
  ModifyParcelCarrierAccountMutation,
  ModifyParcelCarrierAccountMutationVariables
>;
export const ParcelCarrierAccountsDocument = gql`
  query ParcelCarrierAccounts($accountID: ID!) {
    parcelCarrierAccounts(id: $accountID) {
      id
      carrier
      accountNumber
      country
      postalCode
    }
  }
`;

/**
 * __useParcelCarrierAccountsQuery__
 *
 * To run a query within a React component, call `useParcelCarrierAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParcelCarrierAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParcelCarrierAccountsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useParcelCarrierAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<ParcelCarrierAccountsQuery, ParcelCarrierAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParcelCarrierAccountsQuery, ParcelCarrierAccountsQueryVariables>(
    ParcelCarrierAccountsDocument,
    options,
  );
}
export function useParcelCarrierAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ParcelCarrierAccountsQuery, ParcelCarrierAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParcelCarrierAccountsQuery, ParcelCarrierAccountsQueryVariables>(
    ParcelCarrierAccountsDocument,
    options,
  );
}
export type ParcelCarrierAccountsQueryHookResult = ReturnType<typeof useParcelCarrierAccountsQuery>;
export type ParcelCarrierAccountsLazyQueryHookResult = ReturnType<typeof useParcelCarrierAccountsLazyQuery>;
export type ParcelCarrierAccountsQueryResult = Apollo.QueryResult<
  ParcelCarrierAccountsQuery,
  ParcelCarrierAccountsQueryVariables
>;
export const AccountBreadCrumbsQueryDocument = gql`
  query AccountBreadCrumbsQuery($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useAccountBreadCrumbsQueryQuery__
 *
 * To run a query within a React component, call `useAccountBreadCrumbsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBreadCrumbsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBreadCrumbsQueryQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountBreadCrumbsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<AccountBreadCrumbsQueryQuery, AccountBreadCrumbsQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountBreadCrumbsQueryQuery, AccountBreadCrumbsQueryQueryVariables>(
    AccountBreadCrumbsQueryDocument,
    options,
  );
}
export function useAccountBreadCrumbsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountBreadCrumbsQueryQuery, AccountBreadCrumbsQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountBreadCrumbsQueryQuery, AccountBreadCrumbsQueryQueryVariables>(
    AccountBreadCrumbsQueryDocument,
    options,
  );
}
export type AccountBreadCrumbsQueryQueryHookResult = ReturnType<typeof useAccountBreadCrumbsQueryQuery>;
export type AccountBreadCrumbsQueryLazyQueryHookResult = ReturnType<typeof useAccountBreadCrumbsQueryLazyQuery>;
export type AccountBreadCrumbsQueryQueryResult = Apollo.QueryResult<
  AccountBreadCrumbsQueryQuery,
  AccountBreadCrumbsQueryQueryVariables
>;
export const ModifyLogisticsPurchaseOrderDocument = gql`
  mutation ModifyLogisticsPurchaseOrder($input: Logistics__PurchaseOrderInput!) {
    modifyLogisticsPurchaseOrder(input: $input) {
      status
      error
      id
    }
  }
`;
export type ModifyLogisticsPurchaseOrderMutationFn = Apollo.MutationFunction<
  ModifyLogisticsPurchaseOrderMutation,
  ModifyLogisticsPurchaseOrderMutationVariables
>;

/**
 * __useModifyLogisticsPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useModifyLogisticsPurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLogisticsPurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLogisticsPurchaseOrderMutation, { data, loading, error }] = useModifyLogisticsPurchaseOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyLogisticsPurchaseOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyLogisticsPurchaseOrderMutation,
    ModifyLogisticsPurchaseOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyLogisticsPurchaseOrderMutation, ModifyLogisticsPurchaseOrderMutationVariables>(
    ModifyLogisticsPurchaseOrderDocument,
    options,
  );
}
export type ModifyLogisticsPurchaseOrderMutationHookResult = ReturnType<typeof useModifyLogisticsPurchaseOrderMutation>;
export type ModifyLogisticsPurchaseOrderMutationResult = Apollo.MutationResult<ModifyLogisticsPurchaseOrderMutation>;
export type ModifyLogisticsPurchaseOrderMutationOptions = Apollo.BaseMutationOptions<
  ModifyLogisticsPurchaseOrderMutation,
  ModifyLogisticsPurchaseOrderMutationVariables
>;
export const BuildLogisticsPurchaseOrderDocument = gql`
  mutation BuildLogisticsPurchaseOrder($input: Logistics__PurchaseOrderInput!) {
    buildLogisticsPurchaseOrder(input: $input) {
      status
      error
      id
    }
  }
`;
export type BuildLogisticsPurchaseOrderMutationFn = Apollo.MutationFunction<
  BuildLogisticsPurchaseOrderMutation,
  BuildLogisticsPurchaseOrderMutationVariables
>;

/**
 * __useBuildLogisticsPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useBuildLogisticsPurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildLogisticsPurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildLogisticsPurchaseOrderMutation, { data, loading, error }] = useBuildLogisticsPurchaseOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildLogisticsPurchaseOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildLogisticsPurchaseOrderMutation,
    BuildLogisticsPurchaseOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildLogisticsPurchaseOrderMutation, BuildLogisticsPurchaseOrderMutationVariables>(
    BuildLogisticsPurchaseOrderDocument,
    options,
  );
}
export type BuildLogisticsPurchaseOrderMutationHookResult = ReturnType<typeof useBuildLogisticsPurchaseOrderMutation>;
export type BuildLogisticsPurchaseOrderMutationResult = Apollo.MutationResult<BuildLogisticsPurchaseOrderMutation>;
export type BuildLogisticsPurchaseOrderMutationOptions = Apollo.BaseMutationOptions<
  BuildLogisticsPurchaseOrderMutation,
  BuildLogisticsPurchaseOrderMutationVariables
>;
export const LogisticsPOsForPanelDocument = gql`
  query LogisticsPOsForPanel($page: Int, $accountID: ID!) {
    paginated: paginatedLogisticsPurchaseOrders(page: $page, accountID: $accountID) {
      results {
        id
        number
        createdAt
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useLogisticsPOsForPanelQuery__
 *
 * To run a query within a React component, call `useLogisticsPOsForPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsPOsForPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsPOsForPanelQuery({
 *   variables: {
 *      page: // value for 'page'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useLogisticsPOsForPanelQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsPOsForPanelQuery, LogisticsPOsForPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsPOsForPanelQuery, LogisticsPOsForPanelQueryVariables>(
    LogisticsPOsForPanelDocument,
    options,
  );
}
export function useLogisticsPOsForPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsPOsForPanelQuery, LogisticsPOsForPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsPOsForPanelQuery, LogisticsPOsForPanelQueryVariables>(
    LogisticsPOsForPanelDocument,
    options,
  );
}
export type LogisticsPOsForPanelQueryHookResult = ReturnType<typeof useLogisticsPOsForPanelQuery>;
export type LogisticsPOsForPanelLazyQueryHookResult = ReturnType<typeof useLogisticsPOsForPanelLazyQuery>;
export type LogisticsPOsForPanelQueryResult = Apollo.QueryResult<
  LogisticsPOsForPanelQuery,
  LogisticsPOsForPanelQueryVariables
>;
export const LogisticsPurchaseOrderDocument = gql`
  query LogisticsPurchaseOrder($id: ID!) {
    purchaseOrder: logisticsPurchaseOrder(id: $id) {
      id
      number
      fulfillmentMethod
      shipTo {
        id
        street
        aptsuite
        city
        state
        country
        zip
        latitude
        longitude
        businessName
      }
      fulfillmentExpectations {
        id
        sku {
          id
          value
        }
        quantity
      }
      phoneNumber
    }
  }
`;

/**
 * __useLogisticsPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useLogisticsPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsPurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsPurchaseOrderQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsPurchaseOrderQuery, LogisticsPurchaseOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsPurchaseOrderQuery, LogisticsPurchaseOrderQueryVariables>(
    LogisticsPurchaseOrderDocument,
    options,
  );
}
export function useLogisticsPurchaseOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsPurchaseOrderQuery, LogisticsPurchaseOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsPurchaseOrderQuery, LogisticsPurchaseOrderQueryVariables>(
    LogisticsPurchaseOrderDocument,
    options,
  );
}
export type LogisticsPurchaseOrderQueryHookResult = ReturnType<typeof useLogisticsPurchaseOrderQuery>;
export type LogisticsPurchaseOrderLazyQueryHookResult = ReturnType<typeof useLogisticsPurchaseOrderLazyQuery>;
export type LogisticsPurchaseOrderQueryResult = Apollo.QueryResult<
  LogisticsPurchaseOrderQuery,
  LogisticsPurchaseOrderQueryVariables
>;
export const LogisticsPurchaseOrdersDocument = gql`
  query LogisticsPurchaseOrders {
    logisticsPurchaseOrders {
      id
      account {
        id
        customer {
          id
          name
        }
      }
      number
      orderDate
      mustShipBy
      additionalInfo
      fulfillmentExpectations {
        quantity
        sku {
          id
          value
        }
      }
    }
  }
`;

/**
 * __useLogisticsPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useLogisticsPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsPurchaseOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogisticsPurchaseOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<LogisticsPurchaseOrdersQuery, LogisticsPurchaseOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsPurchaseOrdersQuery, LogisticsPurchaseOrdersQueryVariables>(
    LogisticsPurchaseOrdersDocument,
    options,
  );
}
export function useLogisticsPurchaseOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsPurchaseOrdersQuery, LogisticsPurchaseOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsPurchaseOrdersQuery, LogisticsPurchaseOrdersQueryVariables>(
    LogisticsPurchaseOrdersDocument,
    options,
  );
}
export type LogisticsPurchaseOrdersQueryHookResult = ReturnType<typeof useLogisticsPurchaseOrdersQuery>;
export type LogisticsPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useLogisticsPurchaseOrdersLazyQuery>;
export type LogisticsPurchaseOrdersQueryResult = Apollo.QueryResult<
  LogisticsPurchaseOrdersQuery,
  LogisticsPurchaseOrdersQueryVariables
>;
export const SkuBulkUploadDocument = gql`
  mutation SkuBulkUpload($input: Logistics__Sku__BulkUploadInput!) {
    skuBulkUpload(input: $input) {
      status
      error
    }
  }
`;
export type SkuBulkUploadMutationFn = Apollo.MutationFunction<SkuBulkUploadMutation, SkuBulkUploadMutationVariables>;

/**
 * __useSkuBulkUploadMutation__
 *
 * To run a mutation, you first call `useSkuBulkUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkuBulkUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skuBulkUploadMutation, { data, loading, error }] = useSkuBulkUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSkuBulkUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<SkuBulkUploadMutation, SkuBulkUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SkuBulkUploadMutation, SkuBulkUploadMutationVariables>(SkuBulkUploadDocument, options);
}
export type SkuBulkUploadMutationHookResult = ReturnType<typeof useSkuBulkUploadMutation>;
export type SkuBulkUploadMutationResult = Apollo.MutationResult<SkuBulkUploadMutation>;
export type SkuBulkUploadMutationOptions = Apollo.BaseMutationOptions<
  SkuBulkUploadMutation,
  SkuBulkUploadMutationVariables
>;
export const LogisticsSkuBulkUploadPreviewDocument = gql`
  query LogisticsSkuBulkUploadPreview($input: Logistics__Sku__BulkUploadPreviewInput!) {
    preview: logisticsSkuBulkUploadPreview(input: $input) {
      ... on Logistics__Sku__BulkUploadPreview__Preview {
        skus {
          id
          value
          description
          nmfcNumber
          nmfcClass
          teachInStatus
          vbwOptOutReason
          gtinBarcode
          weightPerPallet
          itemWeight
          itemLength
          itemWidth
          itemHeight
          itemsPerPallet
          deletedAt
          accountID
          defaultPalletTypeID
          defaultPalletHeightID
          hasBarcode
          verified
          vbwEligible
          countingUnit
        }
      }
      ... on Logistics__Sku__BulkUploadPreview__Error {
        message
      }
    }
  }
`;

/**
 * __useLogisticsSkuBulkUploadPreviewQuery__
 *
 * To run a query within a React component, call `useLogisticsSkuBulkUploadPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkuBulkUploadPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkuBulkUploadPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogisticsSkuBulkUploadPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsSkuBulkUploadPreviewQuery, LogisticsSkuBulkUploadPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkuBulkUploadPreviewQuery, LogisticsSkuBulkUploadPreviewQueryVariables>(
    LogisticsSkuBulkUploadPreviewDocument,
    options,
  );
}
export function useLogisticsSkuBulkUploadPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LogisticsSkuBulkUploadPreviewQuery,
    LogisticsSkuBulkUploadPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkuBulkUploadPreviewQuery, LogisticsSkuBulkUploadPreviewQueryVariables>(
    LogisticsSkuBulkUploadPreviewDocument,
    options,
  );
}
export type LogisticsSkuBulkUploadPreviewQueryHookResult = ReturnType<typeof useLogisticsSkuBulkUploadPreviewQuery>;
export type LogisticsSkuBulkUploadPreviewLazyQueryHookResult = ReturnType<
  typeof useLogisticsSkuBulkUploadPreviewLazyQuery
>;
export type LogisticsSkuBulkUploadPreviewQueryResult = Apollo.QueryResult<
  LogisticsSkuBulkUploadPreviewQuery,
  LogisticsSkuBulkUploadPreviewQueryVariables
>;
export const LogisticsDetailedSkuDocument = gql`
  query LogisticsDetailedSku($id: ID!) {
    logisticsSku(id: $id) {
      id
      accountID
      account {
        id
        customer {
          id
          name
        }
      }
      value
      description
      hasBarcode
      defaultPalletTypeId
      defaultPalletHeight {
        value
        unit
      }
      itemsPerPallet
      weightPerPallet
      gtinBarcode
      nmfcClass
      nmfcNumber
      itemWeight
      itemLength
      itemWidth
      itemHeight
      teachInStatus
      vbwEligible
      vbwOptOutReason
      verified
      deletedAt
      measuredWeightImage {
        ...DetailedImage
      }
      verificationImages {
        ...DetailedImage
      }
      countingUnit
    }
  }
  ${DetailedImageFragmentDoc}
`;

/**
 * __useLogisticsDetailedSkuQuery__
 *
 * To run a query within a React component, call `useLogisticsDetailedSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsDetailedSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsDetailedSkuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsDetailedSkuQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsDetailedSkuQuery, LogisticsDetailedSkuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsDetailedSkuQuery, LogisticsDetailedSkuQueryVariables>(
    LogisticsDetailedSkuDocument,
    options,
  );
}
export function useLogisticsDetailedSkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsDetailedSkuQuery, LogisticsDetailedSkuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsDetailedSkuQuery, LogisticsDetailedSkuQueryVariables>(
    LogisticsDetailedSkuDocument,
    options,
  );
}
export type LogisticsDetailedSkuQueryHookResult = ReturnType<typeof useLogisticsDetailedSkuQuery>;
export type LogisticsDetailedSkuLazyQueryHookResult = ReturnType<typeof useLogisticsDetailedSkuLazyQuery>;
export type LogisticsDetailedSkuQueryResult = Apollo.QueryResult<
  LogisticsDetailedSkuQuery,
  LogisticsDetailedSkuQueryVariables
>;
export const ModifyLogisticsSkuDocument = gql`
  mutation ModifyLogisticsSku($input: Logistics__ModifySkuInput!) {
    modifyLogisticsSku(input: $input) {
      status
      error
      sku {
        id
      }
    }
  }
`;
export type ModifyLogisticsSkuMutationFn = Apollo.MutationFunction<
  ModifyLogisticsSkuMutation,
  ModifyLogisticsSkuMutationVariables
>;

/**
 * __useModifyLogisticsSkuMutation__
 *
 * To run a mutation, you first call `useModifyLogisticsSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLogisticsSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLogisticsSkuMutation, { data, loading, error }] = useModifyLogisticsSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyLogisticsSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyLogisticsSkuMutation, ModifyLogisticsSkuMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyLogisticsSkuMutation, ModifyLogisticsSkuMutationVariables>(
    ModifyLogisticsSkuDocument,
    options,
  );
}
export type ModifyLogisticsSkuMutationHookResult = ReturnType<typeof useModifyLogisticsSkuMutation>;
export type ModifyLogisticsSkuMutationResult = Apollo.MutationResult<ModifyLogisticsSkuMutation>;
export type ModifyLogisticsSkuMutationOptions = Apollo.BaseMutationOptions<
  ModifyLogisticsSkuMutation,
  ModifyLogisticsSkuMutationVariables
>;
export const BuildLogisticsSkuDocument = gql`
  mutation BuildLogisticsSku($input: Logistics__BuildSkuInput!) {
    buildLogisticsSku(input: $input) {
      status
      error
      sku {
        id
      }
    }
  }
`;
export type BuildLogisticsSkuMutationFn = Apollo.MutationFunction<
  BuildLogisticsSkuMutation,
  BuildLogisticsSkuMutationVariables
>;

/**
 * __useBuildLogisticsSkuMutation__
 *
 * To run a mutation, you first call `useBuildLogisticsSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildLogisticsSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildLogisticsSkuMutation, { data, loading, error }] = useBuildLogisticsSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildLogisticsSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildLogisticsSkuMutation, BuildLogisticsSkuMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildLogisticsSkuMutation, BuildLogisticsSkuMutationVariables>(
    BuildLogisticsSkuDocument,
    options,
  );
}
export type BuildLogisticsSkuMutationHookResult = ReturnType<typeof useBuildLogisticsSkuMutation>;
export type BuildLogisticsSkuMutationResult = Apollo.MutationResult<BuildLogisticsSkuMutation>;
export type BuildLogisticsSkuMutationOptions = Apollo.BaseMutationOptions<
  BuildLogisticsSkuMutation,
  BuildLogisticsSkuMutationVariables
>;
export const LogisticsSkuFormOptionsDocument = gql`
  query LogisticsSkuFormOptions {
    palletTypes {
      id
      name
      legacySize
      active
      position
    }
  }
`;

/**
 * __useLogisticsSkuFormOptionsQuery__
 *
 * To run a query within a React component, call `useLogisticsSkuFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkuFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkuFormOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogisticsSkuFormOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<LogisticsSkuFormOptionsQuery, LogisticsSkuFormOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkuFormOptionsQuery, LogisticsSkuFormOptionsQueryVariables>(
    LogisticsSkuFormOptionsDocument,
    options,
  );
}
export function useLogisticsSkuFormOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsSkuFormOptionsQuery, LogisticsSkuFormOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkuFormOptionsQuery, LogisticsSkuFormOptionsQueryVariables>(
    LogisticsSkuFormOptionsDocument,
    options,
  );
}
export type LogisticsSkuFormOptionsQueryHookResult = ReturnType<typeof useLogisticsSkuFormOptionsQuery>;
export type LogisticsSkuFormOptionsLazyQueryHookResult = ReturnType<typeof useLogisticsSkuFormOptionsLazyQuery>;
export type LogisticsSkuFormOptionsQueryResult = Apollo.QueryResult<
  LogisticsSkuFormOptionsQuery,
  LogisticsSkuFormOptionsQueryVariables
>;
export const ReteachLogisticsSkuDocument = gql`
  mutation ReteachLogisticsSku($id: ID!) {
    reteachLogisticsSku(id: $id) {
      status
      error
    }
  }
`;
export type ReteachLogisticsSkuMutationFn = Apollo.MutationFunction<
  ReteachLogisticsSkuMutation,
  ReteachLogisticsSkuMutationVariables
>;

/**
 * __useReteachLogisticsSkuMutation__
 *
 * To run a mutation, you first call `useReteachLogisticsSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReteachLogisticsSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reteachLogisticsSkuMutation, { data, loading, error }] = useReteachLogisticsSkuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReteachLogisticsSkuMutation(
  baseOptions?: Apollo.MutationHookOptions<ReteachLogisticsSkuMutation, ReteachLogisticsSkuMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReteachLogisticsSkuMutation, ReteachLogisticsSkuMutationVariables>(
    ReteachLogisticsSkuDocument,
    options,
  );
}
export type ReteachLogisticsSkuMutationHookResult = ReturnType<typeof useReteachLogisticsSkuMutation>;
export type ReteachLogisticsSkuMutationResult = Apollo.MutationResult<ReteachLogisticsSkuMutation>;
export type ReteachLogisticsSkuMutationOptions = Apollo.BaseMutationOptions<
  ReteachLogisticsSkuMutation,
  ReteachLogisticsSkuMutationVariables
>;
export const SkuDeleteDocument = gql`
  mutation SkuDelete($skuID: ID!) {
    skuDelete(skuID: $skuID) {
      status
      error
      sku {
        id
        deletedAt
      }
    }
  }
`;
export type SkuDeleteMutationFn = Apollo.MutationFunction<SkuDeleteMutation, SkuDeleteMutationVariables>;

/**
 * __useSkuDeleteMutation__
 *
 * To run a mutation, you first call `useSkuDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkuDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skuDeleteMutation, { data, loading, error }] = useSkuDeleteMutation({
 *   variables: {
 *      skuID: // value for 'skuID'
 *   },
 * });
 */
export function useSkuDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<SkuDeleteMutation, SkuDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SkuDeleteMutation, SkuDeleteMutationVariables>(SkuDeleteDocument, options);
}
export type SkuDeleteMutationHookResult = ReturnType<typeof useSkuDeleteMutation>;
export type SkuDeleteMutationResult = Apollo.MutationResult<SkuDeleteMutation>;
export type SkuDeleteMutationOptions = Apollo.BaseMutationOptions<SkuDeleteMutation, SkuDeleteMutationVariables>;
export const LogisticsSkuForEditDocument = gql`
  query LogisticsSkuForEdit($id: ID!) {
    logisticsSku(id: $id) {
      id
      accountID
      value
      description
      hasBarcode
      defaultPalletTypeId
      defaultPalletHeight {
        value
        unit
      }
      itemsPerPallet
      weightPerPallet
      gtinBarcode
      nmfcClass
      nmfcNumber
      itemWeight
      itemLength
      itemWidth
      itemHeight
      teachInStatus
      vbwEligible
      vbwOptOutReason
      countingUnit
    }
  }
`;

/**
 * __useLogisticsSkuForEditQuery__
 *
 * To run a query within a React component, call `useLogisticsSkuForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkuForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkuForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogisticsSkuForEditQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsSkuForEditQuery, LogisticsSkuForEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkuForEditQuery, LogisticsSkuForEditQueryVariables>(
    LogisticsSkuForEditDocument,
    options,
  );
}
export function useLogisticsSkuForEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsSkuForEditQuery, LogisticsSkuForEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkuForEditQuery, LogisticsSkuForEditQueryVariables>(
    LogisticsSkuForEditDocument,
    options,
  );
}
export type LogisticsSkuForEditQueryHookResult = ReturnType<typeof useLogisticsSkuForEditQuery>;
export type LogisticsSkuForEditLazyQueryHookResult = ReturnType<typeof useLogisticsSkuForEditLazyQuery>;
export type LogisticsSkuForEditQueryResult = Apollo.QueryResult<
  LogisticsSkuForEditQuery,
  LogisticsSkuForEditQueryVariables
>;
export const SkuMergeDocument = gql`
  mutation SkuMerge($skuID: ID!, $duplicateSkuID: ID!) {
    skuMerge(skuID: $skuID, duplicateSkuID: $duplicateSkuID) {
      status
      error
    }
  }
`;
export type SkuMergeMutationFn = Apollo.MutationFunction<SkuMergeMutation, SkuMergeMutationVariables>;

/**
 * __useSkuMergeMutation__
 *
 * To run a mutation, you first call `useSkuMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkuMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skuMergeMutation, { data, loading, error }] = useSkuMergeMutation({
 *   variables: {
 *      skuID: // value for 'skuID'
 *      duplicateSkuID: // value for 'duplicateSkuID'
 *   },
 * });
 */
export function useSkuMergeMutation(
  baseOptions?: Apollo.MutationHookOptions<SkuMergeMutation, SkuMergeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SkuMergeMutation, SkuMergeMutationVariables>(SkuMergeDocument, options);
}
export type SkuMergeMutationHookResult = ReturnType<typeof useSkuMergeMutation>;
export type SkuMergeMutationResult = Apollo.MutationResult<SkuMergeMutation>;
export type SkuMergeMutationOptions = Apollo.BaseMutationOptions<SkuMergeMutation, SkuMergeMutationVariables>;
export const LogisticsSkusForPanelDocument = gql`
  query LogisticsSkusForPanel($page: Int, $accountID: ID!) {
    paginated: paginatedLogisticsSkus(page: $page, accountID: $accountID) {
      results {
        id
        value
        description
        hasBarcode
        requiresTeachIn
        teachInStatus
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useLogisticsSkusForPanelQuery__
 *
 * To run a query within a React component, call `useLogisticsSkusForPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogisticsSkusForPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogisticsSkusForPanelQuery({
 *   variables: {
 *      page: // value for 'page'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useLogisticsSkusForPanelQuery(
  baseOptions: Apollo.QueryHookOptions<LogisticsSkusForPanelQuery, LogisticsSkusForPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogisticsSkusForPanelQuery, LogisticsSkusForPanelQueryVariables>(
    LogisticsSkusForPanelDocument,
    options,
  );
}
export function useLogisticsSkusForPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogisticsSkusForPanelQuery, LogisticsSkusForPanelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogisticsSkusForPanelQuery, LogisticsSkusForPanelQueryVariables>(
    LogisticsSkusForPanelDocument,
    options,
  );
}
export type LogisticsSkusForPanelQueryHookResult = ReturnType<typeof useLogisticsSkusForPanelQuery>;
export type LogisticsSkusForPanelLazyQueryHookResult = ReturnType<typeof useLogisticsSkusForPanelLazyQuery>;
export type LogisticsSkusForPanelQueryResult = Apollo.QueryResult<
  LogisticsSkusForPanelQuery,
  LogisticsSkusForPanelQueryVariables
>;
export const MovingAppointmentDetailsDocument = gql`
  query MovingAppointmentDetails($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      estimatedDuration
      estimatedCuft
      accountPackages {
        name
        kind
        packageSetEntryId
      }
      movingOperation {
        id
        moveSize
        packing
        originAddress {
          aptsuite
          city
          state
          street
          zip
          details {
            buildingType
            floor
            longCarry
            stories
          }
        }
        destinationAddress {
          aptsuite
          city
          state
          street
          zip
          details {
            buildingType
            floor
            longCarry
            stories
          }
        }
        quote {
          ...movingQuote
        }
      }
      movingSelections {
        id
        quantity
        movingCategory {
          id
          name
        }
      }
    }
  }
  ${MovingQuoteFragmentDoc}
`;

/**
 * __useMovingAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useMovingAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingAppointmentDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingAppointmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<MovingAppointmentDetailsQuery, MovingAppointmentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingAppointmentDetailsQuery, MovingAppointmentDetailsQueryVariables>(
    MovingAppointmentDetailsDocument,
    options,
  );
}
export function useMovingAppointmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovingAppointmentDetailsQuery, MovingAppointmentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingAppointmentDetailsQuery, MovingAppointmentDetailsQueryVariables>(
    MovingAppointmentDetailsDocument,
    options,
  );
}
export type MovingAppointmentDetailsQueryHookResult = ReturnType<typeof useMovingAppointmentDetailsQuery>;
export type MovingAppointmentDetailsLazyQueryHookResult = ReturnType<typeof useMovingAppointmentDetailsLazyQuery>;
export type MovingAppointmentDetailsQueryResult = Apollo.QueryResult<
  MovingAppointmentDetailsQuery,
  MovingAppointmentDetailsQueryVariables
>;
export const CreateFollowUpMoveDocument = gql`
  mutation CreateFollowUpMove($input: Moving__CreateFollowUpMoveInput!) {
    createFollowUpMove(input: $input) {
      orderID
      status
      error {
        message
        code
        expected
      }
    }
  }
`;
export type CreateFollowUpMoveMutationFn = Apollo.MutationFunction<
  CreateFollowUpMoveMutation,
  CreateFollowUpMoveMutationVariables
>;

/**
 * __useCreateFollowUpMoveMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpMoveMutation, { data, loading, error }] = useCreateFollowUpMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFollowUpMoveMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFollowUpMoveMutation, CreateFollowUpMoveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFollowUpMoveMutation, CreateFollowUpMoveMutationVariables>(
    CreateFollowUpMoveDocument,
    options,
  );
}
export type CreateFollowUpMoveMutationHookResult = ReturnType<typeof useCreateFollowUpMoveMutation>;
export type CreateFollowUpMoveMutationResult = Apollo.MutationResult<CreateFollowUpMoveMutation>;
export type CreateFollowUpMoveMutationOptions = Apollo.BaseMutationOptions<
  CreateFollowUpMoveMutation,
  CreateFollowUpMoveMutationVariables
>;
export const ManageFollowUpsDocument = gql`
  query ManageFollowUps($orderID: ID!) {
    order(orderID: $orderID) {
      id
      sourceAndSuccessors {
        id
        account {
          id
        }
        cancellationFeeDescription
        estimatedDuration
        movers
        movingOperation {
          id
          destinationAddress {
            id
            street
            city
            state
            zip
            formatted
          }
          originAddress {
            id
            street
            city
            state
            zip
            formatted
          }
        }
        region {
          id
          name
          tz
        }
        scheduled
        state
        subtype
      }
    }
  }
`;

/**
 * __useManageFollowUpsQuery__
 *
 * To run a query within a React component, call `useManageFollowUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageFollowUpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageFollowUpsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useManageFollowUpsQuery(
  baseOptions: Apollo.QueryHookOptions<ManageFollowUpsQuery, ManageFollowUpsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageFollowUpsQuery, ManageFollowUpsQueryVariables>(ManageFollowUpsDocument, options);
}
export function useManageFollowUpsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageFollowUpsQuery, ManageFollowUpsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageFollowUpsQuery, ManageFollowUpsQueryVariables>(ManageFollowUpsDocument, options);
}
export type ManageFollowUpsQueryHookResult = ReturnType<typeof useManageFollowUpsQuery>;
export type ManageFollowUpsLazyQueryHookResult = ReturnType<typeof useManageFollowUpsLazyQuery>;
export type ManageFollowUpsQueryResult = Apollo.QueryResult<ManageFollowUpsQuery, ManageFollowUpsQueryVariables>;
export const OrderUpdateDocument = gql`
  mutation OrderUpdate($input: Order__Update__Input!) {
    result: orderUpdate(input: $input) {
      status
      error
    }
  }
`;
export type OrderUpdateMutationFn = Apollo.MutationFunction<OrderUpdateMutation, OrderUpdateMutationVariables>;

/**
 * __useOrderUpdateMutation__
 *
 * To run a mutation, you first call `useOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderUpdateMutation, { data, loading, error }] = useOrderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderUpdateMutation, OrderUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderUpdateMutation, OrderUpdateMutationVariables>(OrderUpdateDocument, options);
}
export type OrderUpdateMutationHookResult = ReturnType<typeof useOrderUpdateMutation>;
export type OrderUpdateMutationResult = Apollo.MutationResult<OrderUpdateMutation>;
export type OrderUpdateMutationOptions = Apollo.BaseMutationOptions<OrderUpdateMutation, OrderUpdateMutationVariables>;
export const OrderMovingClocksDocument = gql`
  query OrderMovingClocks($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      started
      scheduled
      ended
      region {
        id
        tz
      }
      movingOperation {
        id
        packedAt
        unpackedAt
        departedForDestinationAt
        arrivedAtDestinationAt
      }
    }
  }
`;

/**
 * __useOrderMovingClocksQuery__
 *
 * To run a query within a React component, call `useOrderMovingClocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMovingClocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMovingClocksQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderMovingClocksQuery(
  baseOptions: Apollo.QueryHookOptions<OrderMovingClocksQuery, OrderMovingClocksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderMovingClocksQuery, OrderMovingClocksQueryVariables>(OrderMovingClocksDocument, options);
}
export function useOrderMovingClocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderMovingClocksQuery, OrderMovingClocksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderMovingClocksQuery, OrderMovingClocksQueryVariables>(
    OrderMovingClocksDocument,
    options,
  );
}
export type OrderMovingClocksQueryHookResult = ReturnType<typeof useOrderMovingClocksQuery>;
export type OrderMovingClocksLazyQueryHookResult = ReturnType<typeof useOrderMovingClocksLazyQuery>;
export type OrderMovingClocksQueryResult = Apollo.QueryResult<OrderMovingClocksQuery, OrderMovingClocksQueryVariables>;
export const ModifyMovingClocksDocument = gql`
  mutation ModifyMovingClocks($orderID: ID!, $input: Moving__ClocksInput!) {
    modifyMovingClocks(orderID: $orderID, input: $input) {
      status
      error
    }
  }
`;
export type ModifyMovingClocksMutationFn = Apollo.MutationFunction<
  ModifyMovingClocksMutation,
  ModifyMovingClocksMutationVariables
>;

/**
 * __useModifyMovingClocksMutation__
 *
 * To run a mutation, you first call `useModifyMovingClocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyMovingClocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyMovingClocksMutation, { data, loading, error }] = useModifyMovingClocksMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyMovingClocksMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyMovingClocksMutation, ModifyMovingClocksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyMovingClocksMutation, ModifyMovingClocksMutationVariables>(
    ModifyMovingClocksDocument,
    options,
  );
}
export type ModifyMovingClocksMutationHookResult = ReturnType<typeof useModifyMovingClocksMutation>;
export type ModifyMovingClocksMutationResult = Apollo.MutationResult<ModifyMovingClocksMutation>;
export type ModifyMovingClocksMutationOptions = Apollo.BaseMutationOptions<
  ModifyMovingClocksMutation,
  ModifyMovingClocksMutationVariables
>;
export const GeoTimezoneDocument = gql`
  query GeoTimezone($geo: ClutterGeoEnum!) {
    geoTimezone(geo: $geo)
  }
`;

/**
 * __useGeoTimezoneQuery__
 *
 * To run a query within a React component, call `useGeoTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoTimezoneQuery({
 *   variables: {
 *      geo: // value for 'geo'
 *   },
 * });
 */
export function useGeoTimezoneQuery(baseOptions: Apollo.QueryHookOptions<GeoTimezoneQuery, GeoTimezoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeoTimezoneQuery, GeoTimezoneQueryVariables>(GeoTimezoneDocument, options);
}
export function useGeoTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeoTimezoneQuery, GeoTimezoneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeoTimezoneQuery, GeoTimezoneQueryVariables>(GeoTimezoneDocument, options);
}
export type GeoTimezoneQueryHookResult = ReturnType<typeof useGeoTimezoneQuery>;
export type GeoTimezoneLazyQueryHookResult = ReturnType<typeof useGeoTimezoneLazyQuery>;
export type GeoTimezoneQueryResult = Apollo.QueryResult<GeoTimezoneQuery, GeoTimezoneQueryVariables>;
export const NightTransportPlannerFacilitiesDocument = gql`
  query NightTransportPlannerFacilities($filters: FacilityFiltersInput!) {
    facilities(filters: $filters) {
      ...facility
    }
  }
  ${FacilityFragmentDoc}
`;

/**
 * __useNightTransportPlannerFacilitiesQuery__
 *
 * To run a query within a React component, call `useNightTransportPlannerFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNightTransportPlannerFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNightTransportPlannerFacilitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNightTransportPlannerFacilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NightTransportPlannerFacilitiesQuery,
    NightTransportPlannerFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NightTransportPlannerFacilitiesQuery, NightTransportPlannerFacilitiesQueryVariables>(
    NightTransportPlannerFacilitiesDocument,
    options,
  );
}
export function useNightTransportPlannerFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NightTransportPlannerFacilitiesQuery,
    NightTransportPlannerFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NightTransportPlannerFacilitiesQuery, NightTransportPlannerFacilitiesQueryVariables>(
    NightTransportPlannerFacilitiesDocument,
    options,
  );
}
export type NightTransportPlannerFacilitiesQueryHookResult = ReturnType<typeof useNightTransportPlannerFacilitiesQuery>;
export type NightTransportPlannerFacilitiesLazyQueryHookResult = ReturnType<
  typeof useNightTransportPlannerFacilitiesLazyQuery
>;
export type NightTransportPlannerFacilitiesQueryResult = Apollo.QueryResult<
  NightTransportPlannerFacilitiesQuery,
  NightTransportPlannerFacilitiesQueryVariables
>;
export const NightTransportPlanDocument = gql`
  query NightTransportPlan($filters: NightTransport__Route__FiltersInput!, $input: NightTransport__DriveTask__Input!) {
    routes: nightTransportRoutes(filters: $filters) {
      ...NightTransport__route
      tasks {
        ...NightTransport__task
      }
    }
    requiredDriveTasks: nightTransportRequiredDriveTasks(input: $input) {
      ...NightTransport__driveTask
    }
  }
  ${NightTransport__RouteFragmentDoc}
  ${NightTransport__TaskFragmentDoc}
  ${NightTransport__DriveTaskFragmentDoc}
`;

/**
 * __useNightTransportPlanQuery__
 *
 * To run a query within a React component, call `useNightTransportPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useNightTransportPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNightTransportPlanQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNightTransportPlanQuery(
  baseOptions: Apollo.QueryHookOptions<NightTransportPlanQuery, NightTransportPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NightTransportPlanQuery, NightTransportPlanQueryVariables>(
    NightTransportPlanDocument,
    options,
  );
}
export function useNightTransportPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NightTransportPlanQuery, NightTransportPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NightTransportPlanQuery, NightTransportPlanQueryVariables>(
    NightTransportPlanDocument,
    options,
  );
}
export type NightTransportPlanQueryHookResult = ReturnType<typeof useNightTransportPlanQuery>;
export type NightTransportPlanLazyQueryHookResult = ReturnType<typeof useNightTransportPlanLazyQuery>;
export type NightTransportPlanQueryResult = Apollo.QueryResult<
  NightTransportPlanQuery,
  NightTransportPlanQueryVariables
>;
export const NightTransportPlannerDriversDocument = gql`
  query NightTransportPlannerDrivers($filters: UserFiltersInput!) {
    drivers: users(filters: $filters) {
      id
      name
      tz
    }
  }
`;

/**
 * __useNightTransportPlannerDriversQuery__
 *
 * To run a query within a React component, call `useNightTransportPlannerDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useNightTransportPlannerDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNightTransportPlannerDriversQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNightTransportPlannerDriversQuery(
  baseOptions: Apollo.QueryHookOptions<NightTransportPlannerDriversQuery, NightTransportPlannerDriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NightTransportPlannerDriversQuery, NightTransportPlannerDriversQueryVariables>(
    NightTransportPlannerDriversDocument,
    options,
  );
}
export function useNightTransportPlannerDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NightTransportPlannerDriversQuery,
    NightTransportPlannerDriversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NightTransportPlannerDriversQuery, NightTransportPlannerDriversQueryVariables>(
    NightTransportPlannerDriversDocument,
    options,
  );
}
export type NightTransportPlannerDriversQueryHookResult = ReturnType<typeof useNightTransportPlannerDriversQuery>;
export type NightTransportPlannerDriversLazyQueryHookResult = ReturnType<
  typeof useNightTransportPlannerDriversLazyQuery
>;
export type NightTransportPlannerDriversQueryResult = Apollo.QueryResult<
  NightTransportPlannerDriversQuery,
  NightTransportPlannerDriversQueryVariables
>;
export const NightTransportPlannerVehiclesDocument = gql`
  query NightTransportPlannerVehicles($filters: VehicleFiltersInput!) {
    vehicles(filters: $filters) {
      ...vehicle
    }
  }
  ${VehicleFragmentDoc}
`;

/**
 * __useNightTransportPlannerVehiclesQuery__
 *
 * To run a query within a React component, call `useNightTransportPlannerVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNightTransportPlannerVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNightTransportPlannerVehiclesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNightTransportPlannerVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<NightTransportPlannerVehiclesQuery, NightTransportPlannerVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NightTransportPlannerVehiclesQuery, NightTransportPlannerVehiclesQueryVariables>(
    NightTransportPlannerVehiclesDocument,
    options,
  );
}
export function useNightTransportPlannerVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NightTransportPlannerVehiclesQuery,
    NightTransportPlannerVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NightTransportPlannerVehiclesQuery, NightTransportPlannerVehiclesQueryVariables>(
    NightTransportPlannerVehiclesDocument,
    options,
  );
}
export type NightTransportPlannerVehiclesQueryHookResult = ReturnType<typeof useNightTransportPlannerVehiclesQuery>;
export type NightTransportPlannerVehiclesLazyQueryHookResult = ReturnType<
  typeof useNightTransportPlannerVehiclesLazyQuery
>;
export type NightTransportPlannerVehiclesQueryResult = Apollo.QueryResult<
  NightTransportPlannerVehiclesQuery,
  NightTransportPlannerVehiclesQueryVariables
>;
export const NightTransportRouteUpsertDocument = gql`
  mutation NightTransportRouteUpsert($input: NightTransport__RouteUpsertInput!) {
    result: nightTransportRouteUpsert(input: $input) {
      status
      error
    }
  }
`;
export type NightTransportRouteUpsertMutationFn = Apollo.MutationFunction<
  NightTransportRouteUpsertMutation,
  NightTransportRouteUpsertMutationVariables
>;

/**
 * __useNightTransportRouteUpsertMutation__
 *
 * To run a mutation, you first call `useNightTransportRouteUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNightTransportRouteUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nightTransportRouteUpsertMutation, { data, loading, error }] = useNightTransportRouteUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNightTransportRouteUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NightTransportRouteUpsertMutation,
    NightTransportRouteUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NightTransportRouteUpsertMutation, NightTransportRouteUpsertMutationVariables>(
    NightTransportRouteUpsertDocument,
    options,
  );
}
export type NightTransportRouteUpsertMutationHookResult = ReturnType<typeof useNightTransportRouteUpsertMutation>;
export type NightTransportRouteUpsertMutationResult = Apollo.MutationResult<NightTransportRouteUpsertMutation>;
export type NightTransportRouteUpsertMutationOptions = Apollo.BaseMutationOptions<
  NightTransportRouteUpsertMutation,
  NightTransportRouteUpsertMutationVariables
>;
export const NightTransportRouteTimelinesDocument = gql`
  query NightTransportRouteTimelines($input: NightTransport__RouteTimelineInput!) {
    nightTransportRouteTimelines(input: $input) {
      ...NightTransport__routeTimeline
    }
  }
  ${NightTransport__RouteTimelineFragmentDoc}
`;

/**
 * __useNightTransportRouteTimelinesQuery__
 *
 * To run a query within a React component, call `useNightTransportRouteTimelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNightTransportRouteTimelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNightTransportRouteTimelinesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNightTransportRouteTimelinesQuery(
  baseOptions: Apollo.QueryHookOptions<NightTransportRouteTimelinesQuery, NightTransportRouteTimelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NightTransportRouteTimelinesQuery, NightTransportRouteTimelinesQueryVariables>(
    NightTransportRouteTimelinesDocument,
    options,
  );
}
export function useNightTransportRouteTimelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NightTransportRouteTimelinesQuery,
    NightTransportRouteTimelinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NightTransportRouteTimelinesQuery, NightTransportRouteTimelinesQueryVariables>(
    NightTransportRouteTimelinesDocument,
    options,
  );
}
export type NightTransportRouteTimelinesQueryHookResult = ReturnType<typeof useNightTransportRouteTimelinesQuery>;
export type NightTransportRouteTimelinesLazyQueryHookResult = ReturnType<
  typeof useNightTransportRouteTimelinesLazyQuery
>;
export type NightTransportRouteTimelinesQueryResult = Apollo.QueryResult<
  NightTransportRouteTimelinesQuery,
  NightTransportRouteTimelinesQueryVariables
>;
export const BuildNoteDocument = gql`
  mutation BuildNote($notable: NoteNotableInput!, $input: NoteInput!) {
    buildNote(notable: $notable, input: $input) {
      status
    }
  }
`;
export type BuildNoteMutationFn = Apollo.MutationFunction<BuildNoteMutation, BuildNoteMutationVariables>;

/**
 * __useBuildNoteMutation__
 *
 * To run a mutation, you first call `useBuildNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildNoteMutation, { data, loading, error }] = useBuildNoteMutation({
 *   variables: {
 *      notable: // value for 'notable'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildNoteMutation, BuildNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildNoteMutation, BuildNoteMutationVariables>(BuildNoteDocument, options);
}
export type BuildNoteMutationHookResult = ReturnType<typeof useBuildNoteMutation>;
export type BuildNoteMutationResult = Apollo.MutationResult<BuildNoteMutation>;
export type BuildNoteMutationOptions = Apollo.BaseMutationOptions<BuildNoteMutation, BuildNoteMutationVariables>;
export const NoteDestroyDocument = gql`
  mutation NoteDestroy($id: ID!) {
    noteDestroy(id: $id) {
      status
    }
  }
`;
export type NoteDestroyMutationFn = Apollo.MutationFunction<NoteDestroyMutation, NoteDestroyMutationVariables>;

/**
 * __useNoteDestroyMutation__
 *
 * To run a mutation, you first call `useNoteDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteDestroyMutation, { data, loading, error }] = useNoteDestroyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<NoteDestroyMutation, NoteDestroyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NoteDestroyMutation, NoteDestroyMutationVariables>(NoteDestroyDocument, options);
}
export type NoteDestroyMutationHookResult = ReturnType<typeof useNoteDestroyMutation>;
export type NoteDestroyMutationResult = Apollo.MutationResult<NoteDestroyMutation>;
export type NoteDestroyMutationOptions = Apollo.BaseMutationOptions<NoteDestroyMutation, NoteDestroyMutationVariables>;
export const ModifyNoteDocument = gql`
  mutation ModifyNote($body: String!, $id: ID!) {
    modifyNote(body: $body, id: $id) {
      status
      note {
        ...note
      }
    }
  }
  ${NoteFragmentDoc}
`;
export type ModifyNoteMutationFn = Apollo.MutationFunction<ModifyNoteMutation, ModifyNoteMutationVariables>;

/**
 * __useModifyNoteMutation__
 *
 * To run a mutation, you first call `useModifyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyNoteMutation, { data, loading, error }] = useModifyNoteMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyNoteMutation, ModifyNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyNoteMutation, ModifyNoteMutationVariables>(ModifyNoteDocument, options);
}
export type ModifyNoteMutationHookResult = ReturnType<typeof useModifyNoteMutation>;
export type ModifyNoteMutationResult = Apollo.MutationResult<ModifyNoteMutation>;
export type ModifyNoteMutationOptions = Apollo.BaseMutationOptions<ModifyNoteMutation, ModifyNoteMutationVariables>;
export const NotesDocument = gql`
  query Notes($notable: NoteNotableInput!) {
    notes(notable: $notable) {
      ...note
    }
  }
  ${NoteFragmentDoc}
`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      notable: // value for 'notable'
 *   },
 * });
 */
export function useNotesQuery(baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const AccountOrdersTypeAndSubtypeDocument = gql`
  query accountOrdersTypeAndSubtype($accountID: ID!) {
    orders(accountID: $accountID) {
      subtype
      type
    }
  }
`;

/**
 * __useAccountOrdersTypeAndSubtypeQuery__
 *
 * To run a query within a React component, call `useAccountOrdersTypeAndSubtypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountOrdersTypeAndSubtypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOrdersTypeAndSubtypeQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountOrdersTypeAndSubtypeQuery(
  baseOptions: Apollo.QueryHookOptions<AccountOrdersTypeAndSubtypeQuery, AccountOrdersTypeAndSubtypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountOrdersTypeAndSubtypeQuery, AccountOrdersTypeAndSubtypeQueryVariables>(
    AccountOrdersTypeAndSubtypeDocument,
    options,
  );
}
export function useAccountOrdersTypeAndSubtypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountOrdersTypeAndSubtypeQuery,
    AccountOrdersTypeAndSubtypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountOrdersTypeAndSubtypeQuery, AccountOrdersTypeAndSubtypeQueryVariables>(
    AccountOrdersTypeAndSubtypeDocument,
    options,
  );
}
export type AccountOrdersTypeAndSubtypeQueryHookResult = ReturnType<typeof useAccountOrdersTypeAndSubtypeQuery>;
export type AccountOrdersTypeAndSubtypeLazyQueryHookResult = ReturnType<typeof useAccountOrdersTypeAndSubtypeLazyQuery>;
export type AccountOrdersTypeAndSubtypeQueryResult = Apollo.QueryResult<
  AccountOrdersTypeAndSubtypeQuery,
  AccountOrdersTypeAndSubtypeQueryVariables
>;
export const PaginatedNotificationListDocument = gql`
  query PaginatedNotificationList($accountId: ID!, $page: Int) {
    paginated: paginatedNotificationList(accountId: $accountId, page: $page) {
      results {
        ...notification
      }
      pagination {
        ...pagination
      }
    }
  }
  ${NotificationFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedNotificationListQuery__
 *
 * To run a query within a React component, call `usePaginatedNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedNotificationListQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedNotificationListQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedNotificationListQuery, PaginatedNotificationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedNotificationListQuery, PaginatedNotificationListQueryVariables>(
    PaginatedNotificationListDocument,
    options,
  );
}
export function usePaginatedNotificationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedNotificationListQuery, PaginatedNotificationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedNotificationListQuery, PaginatedNotificationListQueryVariables>(
    PaginatedNotificationListDocument,
    options,
  );
}
export type PaginatedNotificationListQueryHookResult = ReturnType<typeof usePaginatedNotificationListQuery>;
export type PaginatedNotificationListLazyQueryHookResult = ReturnType<typeof usePaginatedNotificationListLazyQuery>;
export type PaginatedNotificationListQueryResult = Apollo.QueryResult<
  PaginatedNotificationListQuery,
  PaginatedNotificationListQueryVariables
>;
export const ResendIterableNotificationDocument = gql`
  mutation ResendIterableNotification($accountId: ID!, $kind: String!) {
    result: resendIterableNotification(accountId: $accountId, kind: $kind) {
      status
    }
  }
`;
export type ResendIterableNotificationMutationFn = Apollo.MutationFunction<
  ResendIterableNotificationMutation,
  ResendIterableNotificationMutationVariables
>;

/**
 * __useResendIterableNotificationMutation__
 *
 * To run a mutation, you first call `useResendIterableNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendIterableNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendIterableNotificationMutation, { data, loading, error }] = useResendIterableNotificationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useResendIterableNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendIterableNotificationMutation,
    ResendIterableNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendIterableNotificationMutation, ResendIterableNotificationMutationVariables>(
    ResendIterableNotificationDocument,
    options,
  );
}
export type ResendIterableNotificationMutationHookResult = ReturnType<typeof useResendIterableNotificationMutation>;
export type ResendIterableNotificationMutationResult = Apollo.MutationResult<ResendIterableNotificationMutation>;
export type ResendIterableNotificationMutationOptions = Apollo.BaseMutationOptions<
  ResendIterableNotificationMutation,
  ResendIterableNotificationMutationVariables
>;
export const ResendNotificationDocument = gql`
  mutation ResendNotification($notificationId: ID!) {
    result: resendNotification(notificationId: $notificationId) {
      status
      notification {
        ...notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;
export type ResendNotificationMutationFn = Apollo.MutationFunction<
  ResendNotificationMutation,
  ResendNotificationMutationVariables
>;

/**
 * __useResendNotificationMutation__
 *
 * To run a mutation, you first call `useResendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendNotificationMutation, { data, loading, error }] = useResendNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useResendNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendNotificationMutation, ResendNotificationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendNotificationMutation, ResendNotificationMutationVariables>(
    ResendNotificationDocument,
    options,
  );
}
export type ResendNotificationMutationHookResult = ReturnType<typeof useResendNotificationMutation>;
export type ResendNotificationMutationResult = Apollo.MutationResult<ResendNotificationMutation>;
export type ResendNotificationMutationOptions = Apollo.BaseMutationOptions<
  ResendNotificationMutation,
  ResendNotificationMutationVariables
>;
export const SentIterableEmailDocument = gql`
  query SentIterableEmail($notificationId: ID!) {
    result: sentIterableEmail(notificationId: $notificationId) {
      html
    }
  }
`;

/**
 * __useSentIterableEmailQuery__
 *
 * To run a query within a React component, call `useSentIterableEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentIterableEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentIterableEmailQuery({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useSentIterableEmailQuery(
  baseOptions: Apollo.QueryHookOptions<SentIterableEmailQuery, SentIterableEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SentIterableEmailQuery, SentIterableEmailQueryVariables>(SentIterableEmailDocument, options);
}
export function useSentIterableEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SentIterableEmailQuery, SentIterableEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SentIterableEmailQuery, SentIterableEmailQueryVariables>(
    SentIterableEmailDocument,
    options,
  );
}
export type SentIterableEmailQueryHookResult = ReturnType<typeof useSentIterableEmailQuery>;
export type SentIterableEmailLazyQueryHookResult = ReturnType<typeof useSentIterableEmailLazyQuery>;
export type SentIterableEmailQueryResult = Apollo.QueryResult<SentIterableEmailQuery, SentIterableEmailQueryVariables>;
export const AuditDocument = gql`
  query Audit($auditID: ID!) {
    audit(auditID: $auditID) {
      ...OpsAudit__Audit
      auditor {
        name
      }
      answers {
        id
        response
        question {
          id
        }
        failed
      }
      children {
        ...OpsAudit__Audit
        answers {
          id
          response
          question {
            id
          }
          failed
        }
      }
    }
  }
  ${OpsAudit__AuditFragmentDoc}
`;

/**
 * __useAuditQuery__
 *
 * To run a query within a React component, call `useAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditQuery({
 *   variables: {
 *      auditID: // value for 'auditID'
 *   },
 * });
 */
export function useAuditQuery(baseOptions: Apollo.QueryHookOptions<AuditQuery, AuditQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditQuery, AuditQueryVariables>(AuditDocument, options);
}
export function useAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuditQuery, AuditQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditQuery, AuditQueryVariables>(AuditDocument, options);
}
export type AuditQueryHookResult = ReturnType<typeof useAuditQuery>;
export type AuditLazyQueryHookResult = ReturnType<typeof useAuditLazyQuery>;
export type AuditQueryResult = Apollo.QueryResult<AuditQuery, AuditQueryVariables>;
export const CreateAuditExceptionDocument = gql`
  mutation CreateAuditException($input: OpsAudit__AuditExceptionInputType!) {
    createAuditException(input: $input) {
      status
      error
    }
  }
`;
export type CreateAuditExceptionMutationFn = Apollo.MutationFunction<
  CreateAuditExceptionMutation,
  CreateAuditExceptionMutationVariables
>;

/**
 * __useCreateAuditExceptionMutation__
 *
 * To run a mutation, you first call `useCreateAuditExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditExceptionMutation, { data, loading, error }] = useCreateAuditExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuditExceptionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuditExceptionMutation, CreateAuditExceptionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuditExceptionMutation, CreateAuditExceptionMutationVariables>(
    CreateAuditExceptionDocument,
    options,
  );
}
export type CreateAuditExceptionMutationHookResult = ReturnType<typeof useCreateAuditExceptionMutation>;
export type CreateAuditExceptionMutationResult = Apollo.MutationResult<CreateAuditExceptionMutation>;
export type CreateAuditExceptionMutationOptions = Apollo.BaseMutationOptions<
  CreateAuditExceptionMutation,
  CreateAuditExceptionMutationVariables
>;
export const EditAuditExceptionDocument = gql`
  mutation EditAuditException($input: OpsAudit__AuditExceptionInputType!) {
    editAuditException(input: $input) {
      status
      error
    }
  }
`;
export type EditAuditExceptionMutationFn = Apollo.MutationFunction<
  EditAuditExceptionMutation,
  EditAuditExceptionMutationVariables
>;

/**
 * __useEditAuditExceptionMutation__
 *
 * To run a mutation, you first call `useEditAuditExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAuditExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAuditExceptionMutation, { data, loading, error }] = useEditAuditExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAuditExceptionMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAuditExceptionMutation, EditAuditExceptionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAuditExceptionMutation, EditAuditExceptionMutationVariables>(
    EditAuditExceptionDocument,
    options,
  );
}
export type EditAuditExceptionMutationHookResult = ReturnType<typeof useEditAuditExceptionMutation>;
export type EditAuditExceptionMutationResult = Apollo.MutationResult<EditAuditExceptionMutation>;
export type EditAuditExceptionMutationOptions = Apollo.BaseMutationOptions<
  EditAuditExceptionMutation,
  EditAuditExceptionMutationVariables
>;
export const AuditResultsDocument = gql`
  query AuditResults($resourceID: ID!, $resourceType: String!) {
    audits: auditResults(resourceID: $resourceID, resourceType: $resourceType) {
      id
      resourceType
      resourceID
      updatedAt
      failed
      questionGroup
      state
      parentID
      auditor {
        name
      }
      configuration {
        name
        exceptionReasonCodes
      }
      answers {
        id
        question {
          id
          prompt
        }
        response
        failed
      }
      exception {
        ...auditException
      }
    }
  }
  ${AuditExceptionFragmentDoc}
`;

/**
 * __useAuditResultsQuery__
 *
 * To run a query within a React component, call `useAuditResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditResultsQuery({
 *   variables: {
 *      resourceID: // value for 'resourceID'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useAuditResultsQuery(
  baseOptions: Apollo.QueryHookOptions<AuditResultsQuery, AuditResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditResultsQuery, AuditResultsQueryVariables>(AuditResultsDocument, options);
}
export function useAuditResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditResultsQuery, AuditResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditResultsQuery, AuditResultsQueryVariables>(AuditResultsDocument, options);
}
export type AuditResultsQueryHookResult = ReturnType<typeof useAuditResultsQuery>;
export type AuditResultsLazyQueryHookResult = ReturnType<typeof useAuditResultsLazyQuery>;
export type AuditResultsQueryResult = Apollo.QueryResult<AuditResultsQuery, AuditResultsQueryVariables>;
export const ModifyItemCategoryDocument = gql`
  mutation ModifyItemCategory($input: OpsAudit__ModifyItemCategoryInput!) {
    modifyItemCategory(input: $input) {
      status
      error
    }
  }
`;
export type ModifyItemCategoryMutationFn = Apollo.MutationFunction<
  ModifyItemCategoryMutation,
  ModifyItemCategoryMutationVariables
>;

/**
 * __useModifyItemCategoryMutation__
 *
 * To run a mutation, you first call `useModifyItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyItemCategoryMutation, { data, loading, error }] = useModifyItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyItemCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyItemCategoryMutation, ModifyItemCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyItemCategoryMutation, ModifyItemCategoryMutationVariables>(
    ModifyItemCategoryDocument,
    options,
  );
}
export type ModifyItemCategoryMutationHookResult = ReturnType<typeof useModifyItemCategoryMutation>;
export type ModifyItemCategoryMutationResult = Apollo.MutationResult<ModifyItemCategoryMutation>;
export type ModifyItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  ModifyItemCategoryMutation,
  ModifyItemCategoryMutationVariables
>;
export const AuditClaimItemSelectionDocument = gql`
  query AuditClaimItemSelection($claimItemSelectionID: ID!) {
    itemSelection(claimItemSelectionID: $claimItemSelectionID) {
      id
      item {
        id
        accountID
        length
        width
        height
        dimensionsOverwritten
        sizingSelection
        customCategoryName
        packaging
        category {
          id
          name
          sizingMetric {
            id
            unit
          }
        }
        partCategory {
          id
          name
        }
        materialCategory {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useAuditClaimItemSelectionQuery__
 *
 * To run a query within a React component, call `useAuditClaimItemSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditClaimItemSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditClaimItemSelectionQuery({
 *   variables: {
 *      claimItemSelectionID: // value for 'claimItemSelectionID'
 *   },
 * });
 */
export function useAuditClaimItemSelectionQuery(
  baseOptions: Apollo.QueryHookOptions<AuditClaimItemSelectionQuery, AuditClaimItemSelectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditClaimItemSelectionQuery, AuditClaimItemSelectionQueryVariables>(
    AuditClaimItemSelectionDocument,
    options,
  );
}
export function useAuditClaimItemSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditClaimItemSelectionQuery, AuditClaimItemSelectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditClaimItemSelectionQuery, AuditClaimItemSelectionQueryVariables>(
    AuditClaimItemSelectionDocument,
    options,
  );
}
export type AuditClaimItemSelectionQueryHookResult = ReturnType<typeof useAuditClaimItemSelectionQuery>;
export type AuditClaimItemSelectionLazyQueryHookResult = ReturnType<typeof useAuditClaimItemSelectionLazyQuery>;
export type AuditClaimItemSelectionQueryResult = Apollo.QueryResult<
  AuditClaimItemSelectionQuery,
  AuditClaimItemSelectionQueryVariables
>;
export const AuditItemGroupDocument = gql`
  query AuditItemGroup($id: ID!) {
    itemGroup(id: $id) {
      id
      category {
        id
        name
      }
      additionalImages {
        id
        createdAt
        source
        size
      }
      heroImage {
        id
        createdAt
        source
        size
      }
      items {
        id
        barcode {
          value
        }
        accountID
        category {
          id
          name
          sizingMetric {
            id
            unit
          }
        }
        partCategory {
          id
          name
        }
        materialCategory {
          id
          name
        }
        includedParts {
          id
          category {
            __typename
            ... on ItemCategory {
              id
              name
            }
            ... on MaterialCategory {
              id
              name
            }
            ... on PartCategory {
              id
              name
            }
          }
        }
      }
      partialItems {
        id
        state
        accountID
        category {
          id
          name
          sizingMetric {
            id
            unit
          }
        }
        partCategory {
          id
          name
        }
        materialCategory {
          id
          name
        }
        includedParts {
          id
          category {
            __typename
            ... on ItemCategory {
              id
              name
            }
            ... on MaterialCategory {
              id
              name
            }
            ... on PartCategory {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAuditItemGroupQuery__
 *
 * To run a query within a React component, call `useAuditItemGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditItemGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditItemGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuditItemGroupQuery(
  baseOptions: Apollo.QueryHookOptions<AuditItemGroupQuery, AuditItemGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditItemGroupQuery, AuditItemGroupQueryVariables>(AuditItemGroupDocument, options);
}
export function useAuditItemGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditItemGroupQuery, AuditItemGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditItemGroupQuery, AuditItemGroupQueryVariables>(AuditItemGroupDocument, options);
}
export type AuditItemGroupQueryHookResult = ReturnType<typeof useAuditItemGroupQuery>;
export type AuditItemGroupLazyQueryHookResult = ReturnType<typeof useAuditItemGroupLazyQuery>;
export type AuditItemGroupQueryResult = Apollo.QueryResult<AuditItemGroupQuery, AuditItemGroupQueryVariables>;
export const PalletGradeDocument = gql`
  query PalletGrade($gradeID: ID!) {
    grade: palletGrade(gradeID: $gradeID) {
      id
      palletID
    }
  }
`;

/**
 * __usePalletGradeQuery__
 *
 * To run a query within a React component, call `usePalletGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletGradeQuery({
 *   variables: {
 *      gradeID: // value for 'gradeID'
 *   },
 * });
 */
export function usePalletGradeQuery(baseOptions: Apollo.QueryHookOptions<PalletGradeQuery, PalletGradeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletGradeQuery, PalletGradeQueryVariables>(PalletGradeDocument, options);
}
export function usePalletGradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletGradeQuery, PalletGradeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletGradeQuery, PalletGradeQueryVariables>(PalletGradeDocument, options);
}
export type PalletGradeQueryHookResult = ReturnType<typeof usePalletGradeQuery>;
export type PalletGradeLazyQueryHookResult = ReturnType<typeof usePalletGradeLazyQuery>;
export type PalletGradeQueryResult = Apollo.QueryResult<PalletGradeQuery, PalletGradeQueryVariables>;
export const AuditItemDocument = gql`
  query AuditItem($itemID: ID!) {
    item: auditItem(itemID: $itemID) {
      id
      accountID
      length
      width
      height
      dimensionsOverwritten
      sizingSelection
      customCategoryName
      packaging
      category {
        id
        name
        sizingMetric {
          id
          unit
        }
      }
      partCategory {
        id
        name
      }
      materialCategory {
        id
        name
      }
    }
  }
`;

/**
 * __useAuditItemQuery__
 *
 * To run a query within a React component, call `useAuditItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditItemQuery({
 *   variables: {
 *      itemID: // value for 'itemID'
 *   },
 * });
 */
export function useAuditItemQuery(baseOptions: Apollo.QueryHookOptions<AuditItemQuery, AuditItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditItemQuery, AuditItemQueryVariables>(AuditItemDocument, options);
}
export function useAuditItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditItemQuery, AuditItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditItemQuery, AuditItemQueryVariables>(AuditItemDocument, options);
}
export type AuditItemQueryHookResult = ReturnType<typeof useAuditItemQuery>;
export type AuditItemLazyQueryHookResult = ReturnType<typeof useAuditItemLazyQuery>;
export type AuditItemQueryResult = Apollo.QueryResult<AuditItemQuery, AuditItemQueryVariables>;
export const BuildOpsAuditAnswersDocument = gql`
  mutation BuildOpsAuditAnswers($auditAnswers: [OpsAudit__AuditAnswersInputType!]!) {
    buildOpsAuditAnswers(auditAnswers: $auditAnswers) {
      status
      error
    }
  }
`;
export type BuildOpsAuditAnswersMutationFn = Apollo.MutationFunction<
  BuildOpsAuditAnswersMutation,
  BuildOpsAuditAnswersMutationVariables
>;

/**
 * __useBuildOpsAuditAnswersMutation__
 *
 * To run a mutation, you first call `useBuildOpsAuditAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildOpsAuditAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildOpsAuditAnswersMutation, { data, loading, error }] = useBuildOpsAuditAnswersMutation({
 *   variables: {
 *      auditAnswers: // value for 'auditAnswers'
 *   },
 * });
 */
export function useBuildOpsAuditAnswersMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildOpsAuditAnswersMutation, BuildOpsAuditAnswersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildOpsAuditAnswersMutation, BuildOpsAuditAnswersMutationVariables>(
    BuildOpsAuditAnswersDocument,
    options,
  );
}
export type BuildOpsAuditAnswersMutationHookResult = ReturnType<typeof useBuildOpsAuditAnswersMutation>;
export type BuildOpsAuditAnswersMutationResult = Apollo.MutationResult<BuildOpsAuditAnswersMutation>;
export type BuildOpsAuditAnswersMutationOptions = Apollo.BaseMutationOptions<
  BuildOpsAuditAnswersMutation,
  BuildOpsAuditAnswersMutationVariables
>;
export const NotAuditableOpsAuditDocument = gql`
  mutation NotAuditableOpsAudit($auditID: ID!) {
    notAuditableOpsAudit(auditID: $auditID) {
      status
      error
    }
  }
`;
export type NotAuditableOpsAuditMutationFn = Apollo.MutationFunction<
  NotAuditableOpsAuditMutation,
  NotAuditableOpsAuditMutationVariables
>;

/**
 * __useNotAuditableOpsAuditMutation__
 *
 * To run a mutation, you first call `useNotAuditableOpsAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotAuditableOpsAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notAuditableOpsAuditMutation, { data, loading, error }] = useNotAuditableOpsAuditMutation({
 *   variables: {
 *      auditID: // value for 'auditID'
 *   },
 * });
 */
export function useNotAuditableOpsAuditMutation(
  baseOptions?: Apollo.MutationHookOptions<NotAuditableOpsAuditMutation, NotAuditableOpsAuditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotAuditableOpsAuditMutation, NotAuditableOpsAuditMutationVariables>(
    NotAuditableOpsAuditDocument,
    options,
  );
}
export type NotAuditableOpsAuditMutationHookResult = ReturnType<typeof useNotAuditableOpsAuditMutation>;
export type NotAuditableOpsAuditMutationResult = Apollo.MutationResult<NotAuditableOpsAuditMutation>;
export type NotAuditableOpsAuditMutationOptions = Apollo.BaseMutationOptions<
  NotAuditableOpsAuditMutation,
  NotAuditableOpsAuditMutationVariables
>;
export const PalletTaskDocument = gql`
  query PalletTask($palletTaskID: ID!) {
    palletTask: palletTask(palletTaskID: $palletTaskID) {
      id
      destinationLocationId
    }
  }
`;

/**
 * __usePalletTaskQuery__
 *
 * To run a query within a React component, call `usePalletTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletTaskQuery({
 *   variables: {
 *      palletTaskID: // value for 'palletTaskID'
 *   },
 * });
 */
export function usePalletTaskQuery(baseOptions: Apollo.QueryHookOptions<PalletTaskQuery, PalletTaskQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletTaskQuery, PalletTaskQueryVariables>(PalletTaskDocument, options);
}
export function usePalletTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletTaskQuery, PalletTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletTaskQuery, PalletTaskQueryVariables>(PalletTaskDocument, options);
}
export type PalletTaskQueryHookResult = ReturnType<typeof usePalletTaskQuery>;
export type PalletTaskLazyQueryHookResult = ReturnType<typeof usePalletTaskLazyQuery>;
export type PalletTaskQueryResult = Apollo.QueryResult<PalletTaskQuery, PalletTaskQueryVariables>;
export const ConfigurationAuditReviewDocument = gql`
  query ConfigurationAuditReview($configurationID: ID!) {
    audit: configurationAuditReview(configurationID: $configurationID) {
      ...OpsAudit__Audit
      children {
        ...OpsAudit__Audit
      }
    }
  }
  ${OpsAudit__AuditFragmentDoc}
`;

/**
 * __useConfigurationAuditReviewQuery__
 *
 * To run a query within a React component, call `useConfigurationAuditReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationAuditReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationAuditReviewQuery({
 *   variables: {
 *      configurationID: // value for 'configurationID'
 *   },
 * });
 */
export function useConfigurationAuditReviewQuery(
  baseOptions: Apollo.QueryHookOptions<ConfigurationAuditReviewQuery, ConfigurationAuditReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigurationAuditReviewQuery, ConfigurationAuditReviewQueryVariables>(
    ConfigurationAuditReviewDocument,
    options,
  );
}
export function useConfigurationAuditReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationAuditReviewQuery, ConfigurationAuditReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigurationAuditReviewQuery, ConfigurationAuditReviewQueryVariables>(
    ConfigurationAuditReviewDocument,
    options,
  );
}
export type ConfigurationAuditReviewQueryHookResult = ReturnType<typeof useConfigurationAuditReviewQuery>;
export type ConfigurationAuditReviewLazyQueryHookResult = ReturnType<typeof useConfigurationAuditReviewLazyQuery>;
export type ConfigurationAuditReviewQueryResult = Apollo.QueryResult<
  ConfigurationAuditReviewQuery,
  ConfigurationAuditReviewQueryVariables
>;
export const SkipOpsAuditDocument = gql`
  mutation SkipOpsAudit($auditID: ID!) {
    skipOpsAudit(auditID: $auditID) {
      status
      error
    }
  }
`;
export type SkipOpsAuditMutationFn = Apollo.MutationFunction<SkipOpsAuditMutation, SkipOpsAuditMutationVariables>;

/**
 * __useSkipOpsAuditMutation__
 *
 * To run a mutation, you first call `useSkipOpsAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipOpsAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipOpsAuditMutation, { data, loading, error }] = useSkipOpsAuditMutation({
 *   variables: {
 *      auditID: // value for 'auditID'
 *   },
 * });
 */
export function useSkipOpsAuditMutation(
  baseOptions?: Apollo.MutationHookOptions<SkipOpsAuditMutation, SkipOpsAuditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SkipOpsAuditMutation, SkipOpsAuditMutationVariables>(SkipOpsAuditDocument, options);
}
export type SkipOpsAuditMutationHookResult = ReturnType<typeof useSkipOpsAuditMutation>;
export type SkipOpsAuditMutationResult = Apollo.MutationResult<SkipOpsAuditMutation>;
export type SkipOpsAuditMutationOptions = Apollo.BaseMutationOptions<
  SkipOpsAuditMutation,
  SkipOpsAuditMutationVariables
>;
export const OpsAuditConfigurationDocument = gql`
  query OpsAuditConfiguration($id: ID!) {
    opsAuditConfiguration(id: $id) {
      ...OpsAudit__Configuration
      questions {
        ...OpsAudit__Question
      }
    }
  }
  ${OpsAudit__ConfigurationFragmentDoc}
  ${OpsAudit__QuestionFragmentDoc}
`;

/**
 * __useOpsAuditConfigurationQuery__
 *
 * To run a query within a React component, call `useOpsAuditConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpsAuditConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpsAuditConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpsAuditConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<OpsAuditConfigurationQuery, OpsAuditConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpsAuditConfigurationQuery, OpsAuditConfigurationQueryVariables>(
    OpsAuditConfigurationDocument,
    options,
  );
}
export function useOpsAuditConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpsAuditConfigurationQuery, OpsAuditConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpsAuditConfigurationQuery, OpsAuditConfigurationQueryVariables>(
    OpsAuditConfigurationDocument,
    options,
  );
}
export type OpsAuditConfigurationQueryHookResult = ReturnType<typeof useOpsAuditConfigurationQuery>;
export type OpsAuditConfigurationLazyQueryHookResult = ReturnType<typeof useOpsAuditConfigurationLazyQuery>;
export type OpsAuditConfigurationQueryResult = Apollo.QueryResult<
  OpsAuditConfigurationQuery,
  OpsAuditConfigurationQueryVariables
>;
export const OpsAuditConfigurationModifyDocument = gql`
  mutation OpsAuditConfigurationModify($input: OpsAudit__ConfigurationModifyInput!) {
    opsAuditConfigurationModify(input: $input) {
      status
      error
    }
  }
`;
export type OpsAuditConfigurationModifyMutationFn = Apollo.MutationFunction<
  OpsAuditConfigurationModifyMutation,
  OpsAuditConfigurationModifyMutationVariables
>;

/**
 * __useOpsAuditConfigurationModifyMutation__
 *
 * To run a mutation, you first call `useOpsAuditConfigurationModifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpsAuditConfigurationModifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [opsAuditConfigurationModifyMutation, { data, loading, error }] = useOpsAuditConfigurationModifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpsAuditConfigurationModifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpsAuditConfigurationModifyMutation,
    OpsAuditConfigurationModifyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpsAuditConfigurationModifyMutation, OpsAuditConfigurationModifyMutationVariables>(
    OpsAuditConfigurationModifyDocument,
    options,
  );
}
export type OpsAuditConfigurationModifyMutationHookResult = ReturnType<typeof useOpsAuditConfigurationModifyMutation>;
export type OpsAuditConfigurationModifyMutationResult = Apollo.MutationResult<OpsAuditConfigurationModifyMutation>;
export type OpsAuditConfigurationModifyMutationOptions = Apollo.BaseMutationOptions<
  OpsAuditConfigurationModifyMutation,
  OpsAuditConfigurationModifyMutationVariables
>;
export const PauseConfigurationDocument = gql`
  mutation PauseConfiguration($configurationID: ID!) {
    pauseConfiguration(configurationID: $configurationID) {
      status
      error
    }
  }
`;
export type PauseConfigurationMutationFn = Apollo.MutationFunction<
  PauseConfigurationMutation,
  PauseConfigurationMutationVariables
>;

/**
 * __usePauseConfigurationMutation__
 *
 * To run a mutation, you first call `usePauseConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseConfigurationMutation, { data, loading, error }] = usePauseConfigurationMutation({
 *   variables: {
 *      configurationID: // value for 'configurationID'
 *   },
 * });
 */
export function usePauseConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<PauseConfigurationMutation, PauseConfigurationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PauseConfigurationMutation, PauseConfigurationMutationVariables>(
    PauseConfigurationDocument,
    options,
  );
}
export type PauseConfigurationMutationHookResult = ReturnType<typeof usePauseConfigurationMutation>;
export type PauseConfigurationMutationResult = Apollo.MutationResult<PauseConfigurationMutation>;
export type PauseConfigurationMutationOptions = Apollo.BaseMutationOptions<
  PauseConfigurationMutation,
  PauseConfigurationMutationVariables
>;
export const PaginatedConfigurationListDocument = gql`
  query PaginatedConfigurationList($page: Int) {
    paginated: paginatedConfigurationList(page: $page) {
      results {
        ...OpsAudit__Configuration
      }
      pagination {
        ...pagination
      }
    }
  }
  ${OpsAudit__ConfigurationFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedConfigurationListQuery__
 *
 * To run a query within a React component, call `usePaginatedConfigurationListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedConfigurationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedConfigurationListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedConfigurationListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedConfigurationListQuery, PaginatedConfigurationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedConfigurationListQuery, PaginatedConfigurationListQueryVariables>(
    PaginatedConfigurationListDocument,
    options,
  );
}
export function usePaginatedConfigurationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedConfigurationListQuery, PaginatedConfigurationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedConfigurationListQuery, PaginatedConfigurationListQueryVariables>(
    PaginatedConfigurationListDocument,
    options,
  );
}
export type PaginatedConfigurationListQueryHookResult = ReturnType<typeof usePaginatedConfigurationListQuery>;
export type PaginatedConfigurationListLazyQueryHookResult = ReturnType<typeof usePaginatedConfigurationListLazyQuery>;
export type PaginatedConfigurationListQueryResult = Apollo.QueryResult<
  PaginatedConfigurationListQuery,
  PaginatedConfigurationListQueryVariables
>;
export const UnpauseConfigurationDocument = gql`
  mutation UnpauseConfiguration($configurationID: ID!) {
    unpauseConfiguration(configurationID: $configurationID) {
      status
      error
    }
  }
`;
export type UnpauseConfigurationMutationFn = Apollo.MutationFunction<
  UnpauseConfigurationMutation,
  UnpauseConfigurationMutationVariables
>;

/**
 * __useUnpauseConfigurationMutation__
 *
 * To run a mutation, you first call `useUnpauseConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpauseConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpauseConfigurationMutation, { data, loading, error }] = useUnpauseConfigurationMutation({
 *   variables: {
 *      configurationID: // value for 'configurationID'
 *   },
 * });
 */
export function useUnpauseConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<UnpauseConfigurationMutation, UnpauseConfigurationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnpauseConfigurationMutation, UnpauseConfigurationMutationVariables>(
    UnpauseConfigurationDocument,
    options,
  );
}
export type UnpauseConfigurationMutationHookResult = ReturnType<typeof useUnpauseConfigurationMutation>;
export type UnpauseConfigurationMutationResult = Apollo.MutationResult<UnpauseConfigurationMutation>;
export type UnpauseConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UnpauseConfigurationMutation,
  UnpauseConfigurationMutationVariables
>;
export const OrderEventsDocument = gql`
  query OrderEvents($orderID: ID!) {
    order(orderID: $orderID) {
      id
      orderEvents {
        id
        category
        eventName
        timestamp
        image {
          id
          source
          size
        }
      }
      region {
        id
        tz
      }
    }
  }
`;

/**
 * __useOrderEventsQuery__
 *
 * To run a query within a React component, call `useOrderEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEventsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderEventsQuery(baseOptions: Apollo.QueryHookOptions<OrderEventsQuery, OrderEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderEventsQuery, OrderEventsQueryVariables>(OrderEventsDocument, options);
}
export function useOrderEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderEventsQuery, OrderEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderEventsQuery, OrderEventsQueryVariables>(OrderEventsDocument, options);
}
export type OrderEventsQueryHookResult = ReturnType<typeof useOrderEventsQuery>;
export type OrderEventsLazyQueryHookResult = ReturnType<typeof useOrderEventsLazyQuery>;
export type OrderEventsQueryResult = Apollo.QueryResult<OrderEventsQuery, OrderEventsQueryVariables>;
export const OrderLeadTimesDocument = gql`
  query OrderLeadTimes($warehouseID: ID!) {
    orderLeadTimes(warehouseID: $warehouseID) {
      id
      ignoreNonOperatingDays
      orderSubtype
      orderType
      processingDayCutoffTime
      processingDaysRequired
      updatedAt
    }
    warehouse(id: $warehouseID) {
      id
      name
    }
  }
`;

/**
 * __useOrderLeadTimesQuery__
 *
 * To run a query within a React component, call `useOrderLeadTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderLeadTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderLeadTimesQuery({
 *   variables: {
 *      warehouseID: // value for 'warehouseID'
 *   },
 * });
 */
export function useOrderLeadTimesQuery(
  baseOptions: Apollo.QueryHookOptions<OrderLeadTimesQuery, OrderLeadTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderLeadTimesQuery, OrderLeadTimesQueryVariables>(OrderLeadTimesDocument, options);
}
export function useOrderLeadTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderLeadTimesQuery, OrderLeadTimesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderLeadTimesQuery, OrderLeadTimesQueryVariables>(OrderLeadTimesDocument, options);
}
export type OrderLeadTimesQueryHookResult = ReturnType<typeof useOrderLeadTimesQuery>;
export type OrderLeadTimesLazyQueryHookResult = ReturnType<typeof useOrderLeadTimesLazyQuery>;
export type OrderLeadTimesQueryResult = Apollo.QueryResult<OrderLeadTimesQuery, OrderLeadTimesQueryVariables>;
export const OrderLeadTimeUpsertDocument = gql`
  mutation OrderLeadTimeUpsert($id: ID, $input: OrderLeadTimeInput!) {
    result: orderLeadTimeUpsert(id: $id, input: $input) {
      status
      error
      resource {
        id
        ignoreNonOperatingDays
        orderSubtype
        orderType
        processingDayCutoffTime
        processingDaysRequired
        updatedAt
      }
    }
  }
`;
export type OrderLeadTimeUpsertMutationFn = Apollo.MutationFunction<
  OrderLeadTimeUpsertMutation,
  OrderLeadTimeUpsertMutationVariables
>;

/**
 * __useOrderLeadTimeUpsertMutation__
 *
 * To run a mutation, you first call `useOrderLeadTimeUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLeadTimeUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLeadTimeUpsertMutation, { data, loading, error }] = useOrderLeadTimeUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderLeadTimeUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderLeadTimeUpsertMutation, OrderLeadTimeUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderLeadTimeUpsertMutation, OrderLeadTimeUpsertMutationVariables>(
    OrderLeadTimeUpsertDocument,
    options,
  );
}
export type OrderLeadTimeUpsertMutationHookResult = ReturnType<typeof useOrderLeadTimeUpsertMutation>;
export type OrderLeadTimeUpsertMutationResult = Apollo.MutationResult<OrderLeadTimeUpsertMutation>;
export type OrderLeadTimeUpsertMutationOptions = Apollo.BaseMutationOptions<
  OrderLeadTimeUpsertMutation,
  OrderLeadTimeUpsertMutationVariables
>;
export const OrderCurrentCancellationFeeDocument = gql`
  query OrderCurrentCancellationFee($orderID: ID!) {
    fee: orderCurrentCancellationFee(orderID: $orderID)
  }
`;

/**
 * __useOrderCurrentCancellationFeeQuery__
 *
 * To run a query within a React component, call `useOrderCurrentCancellationFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCurrentCancellationFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCurrentCancellationFeeQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderCurrentCancellationFeeQuery(
  baseOptions: Apollo.QueryHookOptions<OrderCurrentCancellationFeeQuery, OrderCurrentCancellationFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderCurrentCancellationFeeQuery, OrderCurrentCancellationFeeQueryVariables>(
    OrderCurrentCancellationFeeDocument,
    options,
  );
}
export function useOrderCurrentCancellationFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderCurrentCancellationFeeQuery,
    OrderCurrentCancellationFeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderCurrentCancellationFeeQuery, OrderCurrentCancellationFeeQueryVariables>(
    OrderCurrentCancellationFeeDocument,
    options,
  );
}
export type OrderCurrentCancellationFeeQueryHookResult = ReturnType<typeof useOrderCurrentCancellationFeeQuery>;
export type OrderCurrentCancellationFeeLazyQueryHookResult = ReturnType<typeof useOrderCurrentCancellationFeeLazyQuery>;
export type OrderCurrentCancellationFeeQueryResult = Apollo.QueryResult<
  OrderCurrentCancellationFeeQuery,
  OrderCurrentCancellationFeeQueryVariables
>;
export const MoveCancelReasonsDocument = gql`
  query MoveCancelReasons {
    reasons: moveCancelReasons {
      id
      kind
    }
  }
`;

/**
 * __useMoveCancelReasonsQuery__
 *
 * To run a query within a React component, call `useMoveCancelReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveCancelReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveCancelReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoveCancelReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<MoveCancelReasonsQuery, MoveCancelReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoveCancelReasonsQuery, MoveCancelReasonsQueryVariables>(MoveCancelReasonsDocument, options);
}
export function useMoveCancelReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MoveCancelReasonsQuery, MoveCancelReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoveCancelReasonsQuery, MoveCancelReasonsQueryVariables>(
    MoveCancelReasonsDocument,
    options,
  );
}
export type MoveCancelReasonsQueryHookResult = ReturnType<typeof useMoveCancelReasonsQuery>;
export type MoveCancelReasonsLazyQueryHookResult = ReturnType<typeof useMoveCancelReasonsLazyQuery>;
export type MoveCancelReasonsQueryResult = Apollo.QueryResult<MoveCancelReasonsQuery, MoveCancelReasonsQueryVariables>;
export const OnboardingCancelReasonsDocument = gql`
  query OnboardingCancelReasons {
    reasons: onboardingCancelReasons {
      id
      kind
    }
  }
`;

/**
 * __useOnboardingCancelReasonsQuery__
 *
 * To run a query within a React component, call `useOnboardingCancelReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCancelReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCancelReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingCancelReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<OnboardingCancelReasonsQuery, OnboardingCancelReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingCancelReasonsQuery, OnboardingCancelReasonsQueryVariables>(
    OnboardingCancelReasonsDocument,
    options,
  );
}
export function useOnboardingCancelReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardingCancelReasonsQuery, OnboardingCancelReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingCancelReasonsQuery, OnboardingCancelReasonsQueryVariables>(
    OnboardingCancelReasonsDocument,
    options,
  );
}
export type OnboardingCancelReasonsQueryHookResult = ReturnType<typeof useOnboardingCancelReasonsQuery>;
export type OnboardingCancelReasonsLazyQueryHookResult = ReturnType<typeof useOnboardingCancelReasonsLazyQuery>;
export type OnboardingCancelReasonsQueryResult = Apollo.QueryResult<
  OnboardingCancelReasonsQuery,
  OnboardingCancelReasonsQueryVariables
>;
export const OrderCancelDocument = gql`
  mutation OrderCancel($input: Order__CancelInput!) {
    orderCancel(input: $input) {
      status
      error
    }
  }
`;
export type OrderCancelMutationFn = Apollo.MutationFunction<OrderCancelMutation, OrderCancelMutationVariables>;

/**
 * __useOrderCancelMutation__
 *
 * To run a mutation, you first call `useOrderCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCancelMutation, { data, loading, error }] = useOrderCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderCancelMutation, OrderCancelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderCancelMutation, OrderCancelMutationVariables>(OrderCancelDocument, options);
}
export type OrderCancelMutationHookResult = ReturnType<typeof useOrderCancelMutation>;
export type OrderCancelMutationResult = Apollo.MutationResult<OrderCancelMutation>;
export type OrderCancelMutationOptions = Apollo.BaseMutationOptions<OrderCancelMutation, OrderCancelMutationVariables>;
export const OrderCompleteDocument = gql`
  mutation OrderComplete($orderID: ID!) {
    orderComplete(orderID: $orderID) {
      status
      error
      order {
        id
        state
        items {
          ...item
        }
      }
    }
  }
  ${ItemFragmentDoc}
`;
export type OrderCompleteMutationFn = Apollo.MutationFunction<OrderCompleteMutation, OrderCompleteMutationVariables>;

/**
 * __useOrderCompleteMutation__
 *
 * To run a mutation, you first call `useOrderCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCompleteMutation, { data, loading, error }] = useOrderCompleteMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderCompleteMutation, OrderCompleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderCompleteMutation, OrderCompleteMutationVariables>(OrderCompleteDocument, options);
}
export type OrderCompleteMutationHookResult = ReturnType<typeof useOrderCompleteMutation>;
export type OrderCompleteMutationResult = Apollo.MutationResult<OrderCompleteMutation>;
export type OrderCompleteMutationOptions = Apollo.BaseMutationOptions<
  OrderCompleteMutation,
  OrderCompleteMutationVariables
>;
export const ModifyAuctionDocument = gql`
  mutation ModifyAuction($id: ID!) {
    modifyAuction(id: $id) {
      error
      status
    }
  }
`;
export type ModifyAuctionMutationFn = Apollo.MutationFunction<ModifyAuctionMutation, ModifyAuctionMutationVariables>;

/**
 * __useModifyAuctionMutation__
 *
 * To run a mutation, you first call `useModifyAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyAuctionMutation, { data, loading, error }] = useModifyAuctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyAuctionMutation, ModifyAuctionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyAuctionMutation, ModifyAuctionMutationVariables>(ModifyAuctionDocument, options);
}
export type ModifyAuctionMutationHookResult = ReturnType<typeof useModifyAuctionMutation>;
export type ModifyAuctionMutationResult = Apollo.MutationResult<ModifyAuctionMutation>;
export type ModifyAuctionMutationOptions = Apollo.BaseMutationOptions<
  ModifyAuctionMutation,
  ModifyAuctionMutationVariables
>;
export const OrderPolicyDocument = gql`
  query OrderPolicy($orderID: ID!) {
    order(orderID: $orderID) {
      id
      type
      subtype
      scheduled
      itemsCount
      simultaneous
      state
      region {
        id
        tz
        parentID
      }
      policy {
        cancel
        uncancel
        completeManually
        dispatch
        modify
        review
        ship
        viewSelfStorageAuction
        createFollowUp
      }
    }
  }
`;

/**
 * __useOrderPolicyQuery__
 *
 * To run a query within a React component, call `useOrderPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPolicyQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderPolicyQuery(baseOptions: Apollo.QueryHookOptions<OrderPolicyQuery, OrderPolicyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderPolicyQuery, OrderPolicyQueryVariables>(OrderPolicyDocument, options);
}
export function useOrderPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderPolicyQuery, OrderPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderPolicyQuery, OrderPolicyQueryVariables>(OrderPolicyDocument, options);
}
export type OrderPolicyQueryHookResult = ReturnType<typeof useOrderPolicyQuery>;
export type OrderPolicyLazyQueryHookResult = ReturnType<typeof useOrderPolicyLazyQuery>;
export type OrderPolicyQueryResult = Apollo.QueryResult<OrderPolicyQuery, OrderPolicyQueryVariables>;
export const OrderAttachmentsDocument = gql`
  query OrderAttachments($orderID: ID!) {
    order(orderID: $orderID) {
      id
      ingestedDocuments {
        id
        filename
        url
      }
    }
  }
`;

/**
 * __useOrderAttachmentsQuery__
 *
 * To run a query within a React component, call `useOrderAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAttachmentsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderAttachmentsQuery, OrderAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderAttachmentsQuery, OrderAttachmentsQueryVariables>(OrderAttachmentsDocument, options);
}
export function useOrderAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderAttachmentsQuery, OrderAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderAttachmentsQuery, OrderAttachmentsQueryVariables>(OrderAttachmentsDocument, options);
}
export type OrderAttachmentsQueryHookResult = ReturnType<typeof useOrderAttachmentsQuery>;
export type OrderAttachmentsLazyQueryHookResult = ReturnType<typeof useOrderAttachmentsLazyQuery>;
export type OrderAttachmentsQueryResult = Apollo.QueryResult<OrderAttachmentsQuery, OrderAttachmentsQueryVariables>;
export const AccountBookingCancellationDisclaimerDocument = gql`
  query AccountBookingCancellationDisclaimer(
    $accountID: ID!
    $orderType: OrderTypeEnum!
    $orderSubtype: OrderSubtypeEnum!
    $orderServiceType: OrderServiceTypeEnum
  ) {
    account(accountID: $accountID) {
      id
      disclaimer: bookingCancellationDisclaimer(
        orderType: $orderType
        orderSubtype: $orderSubtype
        orderServiceType: $orderServiceType
      )
    }
  }
`;

/**
 * __useAccountBookingCancellationDisclaimerQuery__
 *
 * To run a query within a React component, call `useAccountBookingCancellationDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBookingCancellationDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBookingCancellationDisclaimerQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      orderType: // value for 'orderType'
 *      orderSubtype: // value for 'orderSubtype'
 *      orderServiceType: // value for 'orderServiceType'
 *   },
 * });
 */
export function useAccountBookingCancellationDisclaimerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountBookingCancellationDisclaimerQuery,
    AccountBookingCancellationDisclaimerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountBookingCancellationDisclaimerQuery, AccountBookingCancellationDisclaimerQueryVariables>(
    AccountBookingCancellationDisclaimerDocument,
    options,
  );
}
export function useAccountBookingCancellationDisclaimerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountBookingCancellationDisclaimerQuery,
    AccountBookingCancellationDisclaimerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountBookingCancellationDisclaimerQuery,
    AccountBookingCancellationDisclaimerQueryVariables
  >(AccountBookingCancellationDisclaimerDocument, options);
}
export type AccountBookingCancellationDisclaimerQueryHookResult = ReturnType<
  typeof useAccountBookingCancellationDisclaimerQuery
>;
export type AccountBookingCancellationDisclaimerLazyQueryHookResult = ReturnType<
  typeof useAccountBookingCancellationDisclaimerLazyQuery
>;
export type AccountBookingCancellationDisclaimerQueryResult = Apollo.QueryResult<
  AccountBookingCancellationDisclaimerQuery,
  AccountBookingCancellationDisclaimerQueryVariables
>;
export const OrderTermContractsDocument = gql`
  query OrderTermContracts($accountID: ID!) {
    account(accountID: $accountID) {
      terminationAgreement {
        contractDuration
        earlyTerminationFee
        contractEndDate
      }
      latestTermCommitment {
        ...termCommitment
      }
    }
  }
  ${TermCommitmentFragmentDoc}
`;

/**
 * __useOrderTermContractsQuery__
 *
 * To run a query within a React component, call `useOrderTermContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTermContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTermContractsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useOrderTermContractsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderTermContractsQuery, OrderTermContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderTermContractsQuery, OrderTermContractsQueryVariables>(
    OrderTermContractsDocument,
    options,
  );
}
export function useOrderTermContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderTermContractsQuery, OrderTermContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderTermContractsQuery, OrderTermContractsQueryVariables>(
    OrderTermContractsDocument,
    options,
  );
}
export type OrderTermContractsQueryHookResult = ReturnType<typeof useOrderTermContractsQuery>;
export type OrderTermContractsLazyQueryHookResult = ReturnType<typeof useOrderTermContractsLazyQuery>;
export type OrderTermContractsQueryResult = Apollo.QueryResult<
  OrderTermContractsQuery,
  OrderTermContractsQueryVariables
>;
export const FormattedAppointmentLaborAgreementDocument = gql`
  query FormattedAppointmentLaborAgreement($orderId: ID!) {
    formattedAppointmentLaborAgreement(orderId: $orderId)
  }
`;

/**
 * __useFormattedAppointmentLaborAgreementQuery__
 *
 * To run a query within a React component, call `useFormattedAppointmentLaborAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormattedAppointmentLaborAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormattedAppointmentLaborAgreementQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useFormattedAppointmentLaborAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    FormattedAppointmentLaborAgreementQuery,
    FormattedAppointmentLaborAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormattedAppointmentLaborAgreementQuery, FormattedAppointmentLaborAgreementQueryVariables>(
    FormattedAppointmentLaborAgreementDocument,
    options,
  );
}
export function useFormattedAppointmentLaborAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormattedAppointmentLaborAgreementQuery,
    FormattedAppointmentLaborAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormattedAppointmentLaborAgreementQuery, FormattedAppointmentLaborAgreementQueryVariables>(
    FormattedAppointmentLaborAgreementDocument,
    options,
  );
}
export type FormattedAppointmentLaborAgreementQueryHookResult = ReturnType<
  typeof useFormattedAppointmentLaborAgreementQuery
>;
export type FormattedAppointmentLaborAgreementLazyQueryHookResult = ReturnType<
  typeof useFormattedAppointmentLaborAgreementLazyQuery
>;
export type FormattedAppointmentLaborAgreementQueryResult = Apollo.QueryResult<
  FormattedAppointmentLaborAgreementQuery,
  FormattedAppointmentLaborAgreementQueryVariables
>;
export const OrderDetailsDocument = gql`
  query OrderDetails($orderID: ID!) {
    estimationCategories {
      ...estimationCategory
    }
    roomCategories {
      ...roomCategory
    }
    order(orderID: $orderID) {
      services {
        id
        type
        subtype
        estimatedCuft
        ...pickupInventory
        ...returnInventory
      }
    }
  }
  ${EstimationCategoryFragmentDoc}
  ${RoomCategoryFragmentDoc}
  ${PickupInventoryFragmentDoc}
  ${ReturnInventoryFragmentDoc}
`;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export function useOrderDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const OrderLaborClocksDocument = gql`
  query OrderLaborClocks($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      scheduled
      region {
        id
        tz
      }
      laborClocks {
        id
        orderID
        user {
          id
          name
        }
        started
        ended
        role
      }
      dispatch {
        id
        assignments {
          user {
            id
            name
          }
          role
        }
      }
    }
  }
`;

/**
 * __useOrderLaborClocksQuery__
 *
 * To run a query within a React component, call `useOrderLaborClocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderLaborClocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderLaborClocksQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderLaborClocksQuery(
  baseOptions: Apollo.QueryHookOptions<OrderLaborClocksQuery, OrderLaborClocksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderLaborClocksQuery, OrderLaborClocksQueryVariables>(OrderLaborClocksDocument, options);
}
export function useOrderLaborClocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderLaborClocksQuery, OrderLaborClocksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderLaborClocksQuery, OrderLaborClocksQueryVariables>(OrderLaborClocksDocument, options);
}
export type OrderLaborClocksQueryHookResult = ReturnType<typeof useOrderLaborClocksQuery>;
export type OrderLaborClocksLazyQueryHookResult = ReturnType<typeof useOrderLaborClocksLazyQuery>;
export type OrderLaborClocksQueryResult = Apollo.QueryResult<OrderLaborClocksQuery, OrderLaborClocksQueryVariables>;
export const CreateLaborClockDocument = gql`
  mutation CreateLaborClock($input: CreateLaborClockInput!) {
    createLaborClock(input: $input) {
      status
      error
    }
  }
`;
export type CreateLaborClockMutationFn = Apollo.MutationFunction<
  CreateLaborClockMutation,
  CreateLaborClockMutationVariables
>;

/**
 * __useCreateLaborClockMutation__
 *
 * To run a mutation, you first call `useCreateLaborClockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLaborClockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLaborClockMutation, { data, loading, error }] = useCreateLaborClockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLaborClockMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLaborClockMutation, CreateLaborClockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLaborClockMutation, CreateLaborClockMutationVariables>(
    CreateLaborClockDocument,
    options,
  );
}
export type CreateLaborClockMutationHookResult = ReturnType<typeof useCreateLaborClockMutation>;
export type CreateLaborClockMutationResult = Apollo.MutationResult<CreateLaborClockMutation>;
export type CreateLaborClockMutationOptions = Apollo.BaseMutationOptions<
  CreateLaborClockMutation,
  CreateLaborClockMutationVariables
>;
export const DeleteLaborClockDocument = gql`
  mutation DeleteLaborClock($id: ID!) {
    deleteLaborClock(id: $id) {
      status
      error
    }
  }
`;
export type DeleteLaborClockMutationFn = Apollo.MutationFunction<
  DeleteLaborClockMutation,
  DeleteLaborClockMutationVariables
>;

/**
 * __useDeleteLaborClockMutation__
 *
 * To run a mutation, you first call `useDeleteLaborClockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLaborClockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLaborClockMutation, { data, loading, error }] = useDeleteLaborClockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLaborClockMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLaborClockMutation, DeleteLaborClockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLaborClockMutation, DeleteLaborClockMutationVariables>(
    DeleteLaborClockDocument,
    options,
  );
}
export type DeleteLaborClockMutationHookResult = ReturnType<typeof useDeleteLaborClockMutation>;
export type DeleteLaborClockMutationResult = Apollo.MutationResult<DeleteLaborClockMutation>;
export type DeleteLaborClockMutationOptions = Apollo.BaseMutationOptions<
  DeleteLaborClockMutation,
  DeleteLaborClockMutationVariables
>;
export const ModifyLaborClockDocument = gql`
  mutation ModifyLaborClock($id: ID!, $input: LaborClockInput!) {
    modifyLaborClock(id: $id, input: $input) {
      status
      error
    }
  }
`;
export type ModifyLaborClockMutationFn = Apollo.MutationFunction<
  ModifyLaborClockMutation,
  ModifyLaborClockMutationVariables
>;

/**
 * __useModifyLaborClockMutation__
 *
 * To run a mutation, you first call `useModifyLaborClockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLaborClockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLaborClockMutation, { data, loading, error }] = useModifyLaborClockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyLaborClockMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyLaborClockMutation, ModifyLaborClockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyLaborClockMutation, ModifyLaborClockMutationVariables>(
    ModifyLaborClockDocument,
    options,
  );
}
export type ModifyLaborClockMutationHookResult = ReturnType<typeof useModifyLaborClockMutation>;
export type ModifyLaborClockMutationResult = Apollo.MutationResult<ModifyLaborClockMutation>;
export type ModifyLaborClockMutationOptions = Apollo.BaseMutationOptions<
  ModifyLaborClockMutation,
  ModifyLaborClockMutationVariables
>;
export const PaginatedOrdersDocument = gql`
  query PaginatedOrders($subject: OrderSubjectInput!, $page: Int) {
    paginated: paginatedOrders(subject: $subject, page: $page) {
      results {
        id
        accountID
        number
        type
        subtype
        state
        moving
        storage
        maintenance
        serviceType
        scheduled
        window
        formattedArrivalWindow
        region {
          id
          tz
        }
        user {
          id
          name
        }
        bookedAt
        dropOffAppointment {
          id
          expectedAt
          state
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedOrdersQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedOrdersQuery, PaginatedOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedOrdersQuery, PaginatedOrdersQueryVariables>(PaginatedOrdersDocument, options);
}
export function usePaginatedOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedOrdersQuery, PaginatedOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedOrdersQuery, PaginatedOrdersQueryVariables>(PaginatedOrdersDocument, options);
}
export type PaginatedOrdersQueryHookResult = ReturnType<typeof usePaginatedOrdersQuery>;
export type PaginatedOrdersLazyQueryHookResult = ReturnType<typeof usePaginatedOrdersLazyQuery>;
export type PaginatedOrdersQueryResult = Apollo.QueryResult<PaginatedOrdersQuery, PaginatedOrdersQueryVariables>;
export const OrderMapDocument = gql`
  query OrderMap($orderID: ID!) {
    order(orderID: $orderID) {
      id
      address {
        id
        latitude
        longitude
      }
      movingOperation {
        id
        originAddress {
          id
          latitude
          longitude
        }
        destinationAddress {
          id
          latitude
          longitude
        }
      }
      tasks {
        id
        sequenceNumber
        address {
          id
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useOrderMapQuery__
 *
 * To run a query within a React component, call `useOrderMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMapQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderMapQuery(baseOptions: Apollo.QueryHookOptions<OrderMapQuery, OrderMapQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderMapQuery, OrderMapQueryVariables>(OrderMapDocument, options);
}
export function useOrderMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderMapQuery, OrderMapQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderMapQuery, OrderMapQueryVariables>(OrderMapDocument, options);
}
export type OrderMapQueryHookResult = ReturnType<typeof useOrderMapQuery>;
export type OrderMapLazyQueryHookResult = ReturnType<typeof useOrderMapLazyQuery>;
export type OrderMapQueryResult = Apollo.QueryResult<OrderMapQuery, OrderMapQueryVariables>;
export const MovingLongDistanceTransportWeightUpsertDocument = gql`
  mutation MovingLongDistanceTransportWeightUpsert($input: Moving__LongDistance__TransportWeightUpsertInput!) {
    result: movingTransportWeightUpsert(input: $input) {
      status
      error {
        ...error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MovingLongDistanceTransportWeightUpsertMutationFn = Apollo.MutationFunction<
  MovingLongDistanceTransportWeightUpsertMutation,
  MovingLongDistanceTransportWeightUpsertMutationVariables
>;

/**
 * __useMovingLongDistanceTransportWeightUpsertMutation__
 *
 * To run a mutation, you first call `useMovingLongDistanceTransportWeightUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingLongDistanceTransportWeightUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingLongDistanceTransportWeightUpsertMutation, { data, loading, error }] = useMovingLongDistanceTransportWeightUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovingLongDistanceTransportWeightUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MovingLongDistanceTransportWeightUpsertMutation,
    MovingLongDistanceTransportWeightUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MovingLongDistanceTransportWeightUpsertMutation,
    MovingLongDistanceTransportWeightUpsertMutationVariables
  >(MovingLongDistanceTransportWeightUpsertDocument, options);
}
export type MovingLongDistanceTransportWeightUpsertMutationHookResult = ReturnType<
  typeof useMovingLongDistanceTransportWeightUpsertMutation
>;
export type MovingLongDistanceTransportWeightUpsertMutationResult =
  Apollo.MutationResult<MovingLongDistanceTransportWeightUpsertMutation>;
export type MovingLongDistanceTransportWeightUpsertMutationOptions = Apollo.BaseMutationOptions<
  MovingLongDistanceTransportWeightUpsertMutation,
  MovingLongDistanceTransportWeightUpsertMutationVariables
>;
export const MovingLongDistanceTransportWeightUpsertDetailsDocument = gql`
  query MovingLongDistanceTransportWeightUpsertDetails($orderID: ID!) {
    order(orderID: $orderID) {
      id
      subtype
      movingOperation {
        id
        loadedTruckWeightInPounds
        longDistanceTransportationRate {
          id
        }
        assumedEmptyTruckWeightInPounds
        emptyTruckWeightInPounds
        emptyTruckWeightInPoundsSuggestion
      }
    }
  }
`;

/**
 * __useMovingLongDistanceTransportWeightUpsertDetailsQuery__
 *
 * To run a query within a React component, call `useMovingLongDistanceTransportWeightUpsertDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingLongDistanceTransportWeightUpsertDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingLongDistanceTransportWeightUpsertDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingLongDistanceTransportWeightUpsertDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MovingLongDistanceTransportWeightUpsertDetailsQuery,
    MovingLongDistanceTransportWeightUpsertDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MovingLongDistanceTransportWeightUpsertDetailsQuery,
    MovingLongDistanceTransportWeightUpsertDetailsQueryVariables
  >(MovingLongDistanceTransportWeightUpsertDetailsDocument, options);
}
export function useMovingLongDistanceTransportWeightUpsertDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MovingLongDistanceTransportWeightUpsertDetailsQuery,
    MovingLongDistanceTransportWeightUpsertDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MovingLongDistanceTransportWeightUpsertDetailsQuery,
    MovingLongDistanceTransportWeightUpsertDetailsQueryVariables
  >(MovingLongDistanceTransportWeightUpsertDetailsDocument, options);
}
export type MovingLongDistanceTransportWeightUpsertDetailsQueryHookResult = ReturnType<
  typeof useMovingLongDistanceTransportWeightUpsertDetailsQuery
>;
export type MovingLongDistanceTransportWeightUpsertDetailsLazyQueryHookResult = ReturnType<
  typeof useMovingLongDistanceTransportWeightUpsertDetailsLazyQuery
>;
export type MovingLongDistanceTransportWeightUpsertDetailsQueryResult = Apollo.QueryResult<
  MovingLongDistanceTransportWeightUpsertDetailsQuery,
  MovingLongDistanceTransportWeightUpsertDetailsQueryVariables
>;
export const MovingConfigurationDocument = gql`
  query MovingConfiguration($movingConfigurationID: ID!, $movers: Int!) {
    movingConfiguration(movingConfigurationID: $movingConfigurationID) {
      laborRate(movers: $movers) {
        amount
      }
    }
  }
`;

/**
 * __useMovingConfigurationQuery__
 *
 * To run a query within a React component, call `useMovingConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingConfigurationQuery({
 *   variables: {
 *      movingConfigurationID: // value for 'movingConfigurationID'
 *      movers: // value for 'movers'
 *   },
 * });
 */
export function useMovingConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<MovingConfigurationQuery, MovingConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingConfigurationQuery, MovingConfigurationQueryVariables>(
    MovingConfigurationDocument,
    options,
  );
}
export function useMovingConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovingConfigurationQuery, MovingConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingConfigurationQuery, MovingConfigurationQueryVariables>(
    MovingConfigurationDocument,
    options,
  );
}
export type MovingConfigurationQueryHookResult = ReturnType<typeof useMovingConfigurationQuery>;
export type MovingConfigurationLazyQueryHookResult = ReturnType<typeof useMovingConfigurationLazyQuery>;
export type MovingConfigurationQueryResult = Apollo.QueryResult<
  MovingConfigurationQuery,
  MovingConfigurationQueryVariables
>;
export const MovingWalkthroughDeleteDocument = gql`
  mutation MovingWalkthroughDelete($orderID: ID!) {
    result: movingWalkthroughDelete(orderID: $orderID) {
      order {
        ... on Order {
          id
          estimatedDuration
          accountPackages {
            id
            kind
            amount
          }
        }
        ... on Error {
          ...error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MovingWalkthroughDeleteMutationFn = Apollo.MutationFunction<
  MovingWalkthroughDeleteMutation,
  MovingWalkthroughDeleteMutationVariables
>;

/**
 * __useMovingWalkthroughDeleteMutation__
 *
 * To run a mutation, you first call `useMovingWalkthroughDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingWalkthroughDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingWalkthroughDeleteMutation, { data, loading, error }] = useMovingWalkthroughDeleteMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingWalkthroughDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingWalkthroughDeleteMutation, MovingWalkthroughDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingWalkthroughDeleteMutation, MovingWalkthroughDeleteMutationVariables>(
    MovingWalkthroughDeleteDocument,
    options,
  );
}
export type MovingWalkthroughDeleteMutationHookResult = ReturnType<typeof useMovingWalkthroughDeleteMutation>;
export type MovingWalkthroughDeleteMutationResult = Apollo.MutationResult<MovingWalkthroughDeleteMutation>;
export type MovingWalkthroughDeleteMutationOptions = Apollo.BaseMutationOptions<
  MovingWalkthroughDeleteMutation,
  MovingWalkthroughDeleteMutationVariables
>;
export const MovingWalkthroughDetailsDocument = gql`
  query MovingWalkthroughDetails($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      estimatedDuration
      accountPackages {
        id
        kind
        amount
      }
    }
    permissions: movingOperationPolicy {
      submitVirtualWalkthrough
    }
  }
`;

/**
 * __useMovingWalkthroughDetailsQuery__
 *
 * To run a query within a React component, call `useMovingWalkthroughDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingWalkthroughDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingWalkthroughDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingWalkthroughDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<MovingWalkthroughDetailsQuery, MovingWalkthroughDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingWalkthroughDetailsQuery, MovingWalkthroughDetailsQueryVariables>(
    MovingWalkthroughDetailsDocument,
    options,
  );
}
export function useMovingWalkthroughDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovingWalkthroughDetailsQuery, MovingWalkthroughDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingWalkthroughDetailsQuery, MovingWalkthroughDetailsQueryVariables>(
    MovingWalkthroughDetailsDocument,
    options,
  );
}
export type MovingWalkthroughDetailsQueryHookResult = ReturnType<typeof useMovingWalkthroughDetailsQuery>;
export type MovingWalkthroughDetailsLazyQueryHookResult = ReturnType<typeof useMovingWalkthroughDetailsLazyQuery>;
export type MovingWalkthroughDetailsQueryResult = Apollo.QueryResult<
  MovingWalkthroughDetailsQuery,
  MovingWalkthroughDetailsQueryVariables
>;
export const MovingWalkthroughUpdateDocument = gql`
  mutation MovingWalkthroughUpdate($input: Moving__WalkthroughInput!, $orderID: ID!) {
    result: movingWalkthroughUpdate(input: $input, orderID: $orderID) {
      order {
        ... on Order {
          id
          estimatedDuration
          accountPackages {
            id
            kind
            amount
          }
        }
        ... on Error {
          ...error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MovingWalkthroughUpdateMutationFn = Apollo.MutationFunction<
  MovingWalkthroughUpdateMutation,
  MovingWalkthroughUpdateMutationVariables
>;

/**
 * __useMovingWalkthroughUpdateMutation__
 *
 * To run a mutation, you first call `useMovingWalkthroughUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingWalkthroughUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingWalkthroughUpdateMutation, { data, loading, error }] = useMovingWalkthroughUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingWalkthroughUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingWalkthroughUpdateMutation, MovingWalkthroughUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingWalkthroughUpdateMutation, MovingWalkthroughUpdateMutationVariables>(
    MovingWalkthroughUpdateDocument,
    options,
  );
}
export type MovingWalkthroughUpdateMutationHookResult = ReturnType<typeof useMovingWalkthroughUpdateMutation>;
export type MovingWalkthroughUpdateMutationResult = Apollo.MutationResult<MovingWalkthroughUpdateMutation>;
export type MovingWalkthroughUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingWalkthroughUpdateMutation,
  MovingWalkthroughUpdateMutationVariables
>;
export const MultiDayContextDocument = gql`
  query MultiDayContext($orderID: ID!) {
    order(orderID: $orderID) {
      id
      multiDayContext {
        id
        currentDay
        totalDays
        multiDay
      }
      predecessor {
        id
        number
        accountID
      }
      successor {
        id
        number
        accountID
      }
    }
  }
`;

/**
 * __useMultiDayContextQuery__
 *
 * To run a query within a React component, call `useMultiDayContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiDayContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiDayContextQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMultiDayContextQuery(
  baseOptions: Apollo.QueryHookOptions<MultiDayContextQuery, MultiDayContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MultiDayContextQuery, MultiDayContextQueryVariables>(MultiDayContextDocument, options);
}
export function useMultiDayContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MultiDayContextQuery, MultiDayContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MultiDayContextQuery, MultiDayContextQueryVariables>(MultiDayContextDocument, options);
}
export type MultiDayContextQueryHookResult = ReturnType<typeof useMultiDayContextQuery>;
export type MultiDayContextLazyQueryHookResult = ReturnType<typeof useMultiDayContextLazyQuery>;
export type MultiDayContextQueryResult = Apollo.QueryResult<MultiDayContextQuery, MultiDayContextQueryVariables>;
export const OrderEstimatedCuftDocument = gql`
  query OrderEstimatedCUFT($orderID: ID!) {
    order(orderID: $orderID) {
      id
      estimatedCuft
    }
  }
`;

/**
 * __useOrderEstimatedCuftQuery__
 *
 * To run a query within a React component, call `useOrderEstimatedCuftQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEstimatedCuftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEstimatedCuftQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderEstimatedCuftQuery(
  baseOptions: Apollo.QueryHookOptions<OrderEstimatedCuftQuery, OrderEstimatedCuftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderEstimatedCuftQuery, OrderEstimatedCuftQueryVariables>(
    OrderEstimatedCuftDocument,
    options,
  );
}
export function useOrderEstimatedCuftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderEstimatedCuftQuery, OrderEstimatedCuftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderEstimatedCuftQuery, OrderEstimatedCuftQueryVariables>(
    OrderEstimatedCuftDocument,
    options,
  );
}
export type OrderEstimatedCuftQueryHookResult = ReturnType<typeof useOrderEstimatedCuftQuery>;
export type OrderEstimatedCuftLazyQueryHookResult = ReturnType<typeof useOrderEstimatedCuftLazyQuery>;
export type OrderEstimatedCuftQueryResult = Apollo.QueryResult<
  OrderEstimatedCuftQuery,
  OrderEstimatedCuftQueryVariables
>;
export const OrderPickupCompleteDocument = gql`
  mutation OrderPickupComplete($orderID: ID!) {
    automationPickupComplete(orderID: $orderID) {
      order {
        id
        state
      }
    }
  }
`;
export type OrderPickupCompleteMutationFn = Apollo.MutationFunction<
  OrderPickupCompleteMutation,
  OrderPickupCompleteMutationVariables
>;

/**
 * __useOrderPickupCompleteMutation__
 *
 * To run a mutation, you first call `useOrderPickupCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPickupCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPickupCompleteMutation, { data, loading, error }] = useOrderPickupCompleteMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderPickupCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderPickupCompleteMutation, OrderPickupCompleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderPickupCompleteMutation, OrderPickupCompleteMutationVariables>(
    OrderPickupCompleteDocument,
    options,
  );
}
export type OrderPickupCompleteMutationHookResult = ReturnType<typeof useOrderPickupCompleteMutation>;
export type OrderPickupCompleteMutationResult = Apollo.MutationResult<OrderPickupCompleteMutation>;
export type OrderPickupCompleteMutationOptions = Apollo.BaseMutationOptions<
  OrderPickupCompleteMutation,
  OrderPickupCompleteMutationVariables
>;
export const OrderRescheduleDocument = gql`
  mutation OrderReschedule($input: OrderRescheduleInput!) {
    result: orderReschedule(input: $input) {
      error
      status
      order {
        id
        scheduled
        number
        account {
          id
          customer {
            id
            name
          }
          number
        }
        region {
          id
          tz
        }
      }
    }
  }
`;
export type OrderRescheduleMutationFn = Apollo.MutationFunction<
  OrderRescheduleMutation,
  OrderRescheduleMutationVariables
>;

/**
 * __useOrderRescheduleMutation__
 *
 * To run a mutation, you first call `useOrderRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderRescheduleMutation, { data, loading, error }] = useOrderRescheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderRescheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderRescheduleMutation, OrderRescheduleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderRescheduleMutation, OrderRescheduleMutationVariables>(
    OrderRescheduleDocument,
    options,
  );
}
export type OrderRescheduleMutationHookResult = ReturnType<typeof useOrderRescheduleMutation>;
export type OrderRescheduleMutationResult = Apollo.MutationResult<OrderRescheduleMutation>;
export type OrderRescheduleMutationOptions = Apollo.BaseMutationOptions<
  OrderRescheduleMutation,
  OrderRescheduleMutationVariables
>;
export const OrderReturnRouteDocument = gql`
  mutation OrderReturnRoute($orderID: ID!) {
    automationReturnRoute(orderID: $orderID) {
      order {
        id
        state
      }
    }
  }
`;
export type OrderReturnRouteMutationFn = Apollo.MutationFunction<
  OrderReturnRouteMutation,
  OrderReturnRouteMutationVariables
>;

/**
 * __useOrderReturnRouteMutation__
 *
 * To run a mutation, you first call `useOrderReturnRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderReturnRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderReturnRouteMutation, { data, loading, error }] = useOrderReturnRouteMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderReturnRouteMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderReturnRouteMutation, OrderReturnRouteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderReturnRouteMutation, OrderReturnRouteMutationVariables>(
    OrderReturnRouteDocument,
    options,
  );
}
export type OrderReturnRouteMutationHookResult = ReturnType<typeof useOrderReturnRouteMutation>;
export type OrderReturnRouteMutationResult = Apollo.MutationResult<OrderReturnRouteMutation>;
export type OrderReturnRouteMutationOptions = Apollo.BaseMutationOptions<
  OrderReturnRouteMutation,
  OrderReturnRouteMutationVariables
>;
export const OrderTagsDocument = gql`
  query OrderTags($id: ID!) {
    order(orderID: $id) {
      id
      tags
    }
  }
`;

/**
 * __useOrderTagsQuery__
 *
 * To run a query within a React component, call `useOrderTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderTagsQuery(baseOptions: Apollo.QueryHookOptions<OrderTagsQuery, OrderTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderTagsQuery, OrderTagsQueryVariables>(OrderTagsDocument, options);
}
export function useOrderTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderTagsQuery, OrderTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderTagsQuery, OrderTagsQueryVariables>(OrderTagsDocument, options);
}
export type OrderTagsQueryHookResult = ReturnType<typeof useOrderTagsQuery>;
export type OrderTagsLazyQueryHookResult = ReturnType<typeof useOrderTagsLazyQuery>;
export type OrderTagsQueryResult = Apollo.QueryResult<OrderTagsQuery, OrderTagsQueryVariables>;
export const OrderWithSchedulingDocument = gql`
  query OrderWithScheduling($id: ID!) {
    order(orderID: $id) {
      id
      scheduled
      number
      account {
        id
        customer {
          id
          name
        }
        number
      }
      region {
        id
        tz
      }
    }
  }
`;

/**
 * __useOrderWithSchedulingQuery__
 *
 * To run a query within a React component, call `useOrderWithSchedulingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderWithSchedulingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderWithSchedulingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderWithSchedulingQuery(
  baseOptions: Apollo.QueryHookOptions<OrderWithSchedulingQuery, OrderWithSchedulingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderWithSchedulingQuery, OrderWithSchedulingQueryVariables>(
    OrderWithSchedulingDocument,
    options,
  );
}
export function useOrderWithSchedulingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderWithSchedulingQuery, OrderWithSchedulingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderWithSchedulingQuery, OrderWithSchedulingQueryVariables>(
    OrderWithSchedulingDocument,
    options,
  );
}
export type OrderWithSchedulingQueryHookResult = ReturnType<typeof useOrderWithSchedulingQuery>;
export type OrderWithSchedulingLazyQueryHookResult = ReturnType<typeof useOrderWithSchedulingLazyQuery>;
export type OrderWithSchedulingQueryResult = Apollo.QueryResult<
  OrderWithSchedulingQuery,
  OrderWithSchedulingQueryVariables
>;
export const OrdersSelectorDocument = gql`
  query OrdersSelector($accountID: ID!) {
    orders(accountID: $accountID) {
      id
      number
      scheduled
      type
      subtype
      state
    }
  }
`;

/**
 * __useOrdersSelectorQuery__
 *
 * To run a query within a React component, call `useOrdersSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersSelectorQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useOrdersSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<OrdersSelectorQuery, OrdersSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersSelectorQuery, OrdersSelectorQueryVariables>(OrdersSelectorDocument, options);
}
export function useOrdersSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersSelectorQuery, OrdersSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersSelectorQuery, OrdersSelectorQueryVariables>(OrdersSelectorDocument, options);
}
export type OrdersSelectorQueryHookResult = ReturnType<typeof useOrdersSelectorQuery>;
export type OrdersSelectorLazyQueryHookResult = ReturnType<typeof useOrdersSelectorLazyQuery>;
export type OrdersSelectorQueryResult = Apollo.QueryResult<OrdersSelectorQuery, OrdersSelectorQueryVariables>;
export const ResendTodayAppUrlDocument = gql`
  mutation ResendTodayAppUrl($orderId: ID!) {
    result: resendTodayAppUrl(orderId: $orderId) {
      status
    }
  }
`;
export type ResendTodayAppUrlMutationFn = Apollo.MutationFunction<
  ResendTodayAppUrlMutation,
  ResendTodayAppUrlMutationVariables
>;

/**
 * __useResendTodayAppUrlMutation__
 *
 * To run a mutation, you first call `useResendTodayAppUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTodayAppUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTodayAppUrlMutation, { data, loading, error }] = useResendTodayAppUrlMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useResendTodayAppUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendTodayAppUrlMutation, ResendTodayAppUrlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendTodayAppUrlMutation, ResendTodayAppUrlMutationVariables>(
    ResendTodayAppUrlDocument,
    options,
  );
}
export type ResendTodayAppUrlMutationHookResult = ReturnType<typeof useResendTodayAppUrlMutation>;
export type ResendTodayAppUrlMutationResult = Apollo.MutationResult<ResendTodayAppUrlMutation>;
export type ResendTodayAppUrlMutationOptions = Apollo.BaseMutationOptions<
  ResendTodayAppUrlMutation,
  ResendTodayAppUrlMutationVariables
>;
export const OrderShipmentsDocument = gql`
  query OrderShipments($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      shipments {
        id
        carrier
        service
        status
        length
        width
        height
        weight
        rate
        purchased
        labelURL
        trackingURL
        zplURL
        items {
          id
          state
          barcode {
            id
            value
          }
        }
      }
    }
  }
`;

/**
 * __useOrderShipmentsQuery__
 *
 * To run a query within a React component, call `useOrderShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShipmentsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderShipmentsQuery, OrderShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderShipmentsQuery, OrderShipmentsQueryVariables>(OrderShipmentsDocument, options);
}
export function useOrderShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderShipmentsQuery, OrderShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderShipmentsQuery, OrderShipmentsQueryVariables>(OrderShipmentsDocument, options);
}
export type OrderShipmentsQueryHookResult = ReturnType<typeof useOrderShipmentsQuery>;
export type OrderShipmentsLazyQueryHookResult = ReturnType<typeof useOrderShipmentsLazyQuery>;
export type OrderShipmentsQueryResult = Apollo.QueryResult<OrderShipmentsQuery, OrderShipmentsQueryVariables>;
export const OrderCouponUpdateDocument = gql`
  mutation OrderCouponUpdate($orderID: ID!, $couponID: ID) {
    orderCouponUpdate(orderID: $orderID, couponID: $couponID) {
      status
      result: order {
        ... on Order {
          id
          coupon {
            id
            code
            discountDescription
          }
        }
        ... on Error {
          ...error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type OrderCouponUpdateMutationFn = Apollo.MutationFunction<
  OrderCouponUpdateMutation,
  OrderCouponUpdateMutationVariables
>;

/**
 * __useOrderCouponUpdateMutation__
 *
 * To run a mutation, you first call `useOrderCouponUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCouponUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCouponUpdateMutation, { data, loading, error }] = useOrderCouponUpdateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      couponID: // value for 'couponID'
 *   },
 * });
 */
export function useOrderCouponUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderCouponUpdateMutation, OrderCouponUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderCouponUpdateMutation, OrderCouponUpdateMutationVariables>(
    OrderCouponUpdateDocument,
    options,
  );
}
export type OrderCouponUpdateMutationHookResult = ReturnType<typeof useOrderCouponUpdateMutation>;
export type OrderCouponUpdateMutationResult = Apollo.MutationResult<OrderCouponUpdateMutation>;
export type OrderCouponUpdateMutationOptions = Apollo.BaseMutationOptions<
  OrderCouponUpdateMutation,
  OrderCouponUpdateMutationVariables
>;
export const OrderShowDocument = gql`
  query OrderShow($id: ID!) {
    order(orderID: $id) {
      id
      account {
        id
        currentStorageSubscription {
          id
          name
          quantity
          pricing {
            amount
            currency
          }
        }
        customer {
          id
          name
          phone
        }
        customers {
          id
          name
          phone
        }
        number
      }
      address {
        ...detailedAddress
      }
      assessment {
        id
        otherReason
        rating
        reasons
      }
      cancelReason {
        id
        kind
      }
      children {
        id
        number
      }
      contact {
        id
        name
        phone
        kind
      }
      coupon {
        id
        code
        name
        discountDescription
      }
      eta
      dispatch {
        id
        assignments {
          user {
            id
            name
          }
          role
        }
      }
      dropOffAppointment {
        expectedAt
      }
      flaggedAt
      formattedArrivalWindow
      leadUser {
        id
      }
      maintenance
      movers
      moving
      movingOperation {
        id
        destinationAddress {
          ...detailedAddress
        }
        originAddress {
          ...detailedAddress
        }
        quote {
          id
          movingConfiguration {
            id
            travelRateMultiplier
          }
        }
      }
      number
      otherNote
      parent {
        id
        number
      }
      region {
        tz
      }
      review {
        id
        comments
        createdAt
        rating
        updatedAt
      }
      scheduled
      serviceType
      shipmentValuationDeclaration {
        id
        description
      }
      shipped
      slaWindowSize
      state
      status
      predecessor {
        id
      }
      storage
      subtype
      todayApp {
        id
        url
      }
      type
      user {
        id
        name
      }
      walkthroughs {
        id
        images {
          id
          source
          createdAt
        }
        name
      }
    }
  }
  ${DetailedAddressFragmentDoc}
`;

/**
 * __useOrderShowQuery__
 *
 * To run a query within a React component, call `useOrderShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderShowQuery(baseOptions: Apollo.QueryHookOptions<OrderShowQuery, OrderShowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderShowQuery, OrderShowQueryVariables>(OrderShowDocument, options);
}
export function useOrderShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderShowQuery, OrderShowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderShowQuery, OrderShowQueryVariables>(OrderShowDocument, options);
}
export type OrderShowQueryHookResult = ReturnType<typeof useOrderShowQuery>;
export type OrderShowLazyQueryHookResult = ReturnType<typeof useOrderShowLazyQuery>;
export type OrderShowQueryResult = Apollo.QueryResult<OrderShowQuery, OrderShowQueryVariables>;
export const OrderUserUpdateDocument = gql`
  mutation OrderUserUpdate($orderID: ID!, $userID: ID!) {
    orderUserUpdate(orderID: $orderID, userID: $userID) {
      status
      result: order {
        ... on Order {
          id
          user {
            id
            name
          }
        }
        ... on Error {
          ...error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type OrderUserUpdateMutationFn = Apollo.MutationFunction<
  OrderUserUpdateMutation,
  OrderUserUpdateMutationVariables
>;

/**
 * __useOrderUserUpdateMutation__
 *
 * To run a mutation, you first call `useOrderUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderUserUpdateMutation, { data, loading, error }] = useOrderUserUpdateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOrderUserUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderUserUpdateMutation, OrderUserUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderUserUpdateMutation, OrderUserUpdateMutationVariables>(
    OrderUserUpdateDocument,
    options,
  );
}
export type OrderUserUpdateMutationHookResult = ReturnType<typeof useOrderUserUpdateMutation>;
export type OrderUserUpdateMutationResult = Apollo.MutationResult<OrderUserUpdateMutation>;
export type OrderUserUpdateMutationOptions = Apollo.BaseMutationOptions<
  OrderUserUpdateMutation,
  OrderUserUpdateMutationVariables
>;
export const OrdersMissingSignatureDocument = gql`
  query OrdersMissingSignature($accountID: ID!) {
    ordersMissingSignature(accountID: $accountID) {
      id
      type
      subtype
      serviceType
      scheduled
    }
  }
`;

/**
 * __useOrdersMissingSignatureQuery__
 *
 * To run a query within a React component, call `useOrdersMissingSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersMissingSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersMissingSignatureQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useOrdersMissingSignatureQuery(
  baseOptions: Apollo.QueryHookOptions<OrdersMissingSignatureQuery, OrdersMissingSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersMissingSignatureQuery, OrdersMissingSignatureQueryVariables>(
    OrdersMissingSignatureDocument,
    options,
  );
}
export function useOrdersMissingSignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersMissingSignatureQuery, OrdersMissingSignatureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersMissingSignatureQuery, OrdersMissingSignatureQueryVariables>(
    OrdersMissingSignatureDocument,
    options,
  );
}
export type OrdersMissingSignatureQueryHookResult = ReturnType<typeof useOrdersMissingSignatureQuery>;
export type OrdersMissingSignatureLazyQueryHookResult = ReturnType<typeof useOrdersMissingSignatureLazyQuery>;
export type OrdersMissingSignatureQueryResult = Apollo.QueryResult<
  OrdersMissingSignatureQuery,
  OrdersMissingSignatureQueryVariables
>;
export const OrderSignaturesDocument = gql`
  query OrderSignatures($orderID: ID!) {
    order: order(orderID: $orderID) {
      id
      customerSignatureRequests {
        id
        kind
        signedAt
      }
      region {
        id
        tz
      }
      signatures {
        ...signature
      }
      started
    }
  }
  ${SignatureFragmentDoc}
`;

/**
 * __useOrderSignaturesQuery__
 *
 * To run a query within a React component, call `useOrderSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSignaturesQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderSignaturesQuery(
  baseOptions: Apollo.QueryHookOptions<OrderSignaturesQuery, OrderSignaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderSignaturesQuery, OrderSignaturesQueryVariables>(OrderSignaturesDocument, options);
}
export function useOrderSignaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderSignaturesQuery, OrderSignaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderSignaturesQuery, OrderSignaturesQueryVariables>(OrderSignaturesDocument, options);
}
export type OrderSignaturesQueryHookResult = ReturnType<typeof useOrderSignaturesQuery>;
export type OrderSignaturesLazyQueryHookResult = ReturnType<typeof useOrderSignaturesLazyQuery>;
export type OrderSignaturesQueryResult = Apollo.QueryResult<OrderSignaturesQuery, OrderSignaturesQueryVariables>;
export const RegenerateDocumentsDocument = gql`
  mutation RegenerateDocuments($signatureRequestID: ID!) {
    regenerateDocuments(signatureRequestID: $signatureRequestID) {
      error
      status
    }
  }
`;
export type RegenerateDocumentsMutationFn = Apollo.MutationFunction<
  RegenerateDocumentsMutation,
  RegenerateDocumentsMutationVariables
>;

/**
 * __useRegenerateDocumentsMutation__
 *
 * To run a mutation, you first call `useRegenerateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateDocumentsMutation, { data, loading, error }] = useRegenerateDocumentsMutation({
 *   variables: {
 *      signatureRequestID: // value for 'signatureRequestID'
 *   },
 * });
 */
export function useRegenerateDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<RegenerateDocumentsMutation, RegenerateDocumentsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegenerateDocumentsMutation, RegenerateDocumentsMutationVariables>(
    RegenerateDocumentsDocument,
    options,
  );
}
export type RegenerateDocumentsMutationHookResult = ReturnType<typeof useRegenerateDocumentsMutation>;
export type RegenerateDocumentsMutationResult = Apollo.MutationResult<RegenerateDocumentsMutation>;
export type RegenerateDocumentsMutationOptions = Apollo.BaseMutationOptions<
  RegenerateDocumentsMutation,
  RegenerateDocumentsMutationVariables
>;
export const SignatureApprovalCreateDocument = gql`
  mutation SignatureApprovalCreate($orderID: ID!) {
    signatureApprovalCreate(orderId: $orderID) {
      error
      status
    }
  }
`;
export type SignatureApprovalCreateMutationFn = Apollo.MutationFunction<
  SignatureApprovalCreateMutation,
  SignatureApprovalCreateMutationVariables
>;

/**
 * __useSignatureApprovalCreateMutation__
 *
 * To run a mutation, you first call `useSignatureApprovalCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignatureApprovalCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signatureApprovalCreateMutation, { data, loading, error }] = useSignatureApprovalCreateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useSignatureApprovalCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<SignatureApprovalCreateMutation, SignatureApprovalCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignatureApprovalCreateMutation, SignatureApprovalCreateMutationVariables>(
    SignatureApprovalCreateDocument,
    options,
  );
}
export type SignatureApprovalCreateMutationHookResult = ReturnType<typeof useSignatureApprovalCreateMutation>;
export type SignatureApprovalCreateMutationResult = Apollo.MutationResult<SignatureApprovalCreateMutation>;
export type SignatureApprovalCreateMutationOptions = Apollo.BaseMutationOptions<
  SignatureApprovalCreateMutation,
  SignatureApprovalCreateMutationVariables
>;
export const StoragePlansDocument = gql`
  query StoragePlans {
    storagePlans {
      ...plan
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useStoragePlansQuery__
 *
 * To run a query within a React component, call `useStoragePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoragePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoragePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoragePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<StoragePlansQuery, StoragePlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoragePlansQuery, StoragePlansQueryVariables>(StoragePlansDocument, options);
}
export function useStoragePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoragePlansQuery, StoragePlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoragePlansQuery, StoragePlansQueryVariables>(StoragePlansDocument, options);
}
export type StoragePlansQueryHookResult = ReturnType<typeof useStoragePlansQuery>;
export type StoragePlansLazyQueryHookResult = ReturnType<typeof useStoragePlansLazyQuery>;
export type StoragePlansQueryResult = Apollo.QueryResult<StoragePlansQuery, StoragePlansQueryVariables>;
export const StorageSubscriptionsDocument = gql`
  query StorageSubscriptions($accountID: ID!) {
    storageSubscriptions(accountID: $accountID) {
      ...subscription
    }
    inventoryMetrics(accountID: $accountID) {
      ...inventoryMetrics
    }
  }
  ${SubscriptionFragmentDoc}
  ${InventoryMetricsFragmentDoc}
`;

/**
 * __useStorageSubscriptionsQuery__
 *
 * To run a query within a React component, call `useStorageSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSubscriptionsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useStorageSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>(
    StorageSubscriptionsDocument,
    options,
  );
}
export function useStorageSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>(
    StorageSubscriptionsDocument,
    options,
  );
}
export type StorageSubscriptionsQueryHookResult = ReturnType<typeof useStorageSubscriptionsQuery>;
export type StorageSubscriptionsLazyQueryHookResult = ReturnType<typeof useStorageSubscriptionsLazyQuery>;
export type StorageSubscriptionsQueryResult = Apollo.QueryResult<
  StorageSubscriptionsQuery,
  StorageSubscriptionsQueryVariables
>;
export const PaginatedIndexOrdersDocument = gql`
  query PaginatedIndexOrders($page: Int!, $filters: OrderFiltersInput!) {
    paginatedOrders(page: $page, filters: $filters) {
      results {
        id
        scheduled
        maintenance
        dispatched
        movers
        state
        type
        subtype
        serviceType
        number
        flaggedAt
        address {
          id
          aptsuite
          city
          neighborhood
          street
          state
          zip
          map
        }
        account {
          id
          subscriptions {
            id
            name
            quantity
            custom
          }
          customer {
            id
            name
          }
          customers {
            id
            name
          }
        }
        region {
          id
          parentID
          tz
        }
        policy {
          dispatch
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedIndexOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedIndexOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedIndexOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedIndexOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedIndexOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedIndexOrdersQuery, PaginatedIndexOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedIndexOrdersQuery, PaginatedIndexOrdersQueryVariables>(
    PaginatedIndexOrdersDocument,
    options,
  );
}
export function usePaginatedIndexOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedIndexOrdersQuery, PaginatedIndexOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedIndexOrdersQuery, PaginatedIndexOrdersQueryVariables>(
    PaginatedIndexOrdersDocument,
    options,
  );
}
export type PaginatedIndexOrdersQueryHookResult = ReturnType<typeof usePaginatedIndexOrdersQuery>;
export type PaginatedIndexOrdersLazyQueryHookResult = ReturnType<typeof usePaginatedIndexOrdersLazyQuery>;
export type PaginatedIndexOrdersQueryResult = Apollo.QueryResult<
  PaginatedIndexOrdersQuery,
  PaginatedIndexOrdersQueryVariables
>;
export const OrderTasksUpdateDocument = gql`
  mutation OrderTasksUpdate($input: Order__TasksUpdateInput!) {
    result: orderTasksUpdate(input: $input) {
      error
      order {
        tasks {
          ...taskOrder__task
        }
      }
      status
    }
  }
  ${TaskOrder__TaskFragmentDoc}
`;
export type OrderTasksUpdateMutationFn = Apollo.MutationFunction<
  OrderTasksUpdateMutation,
  OrderTasksUpdateMutationVariables
>;

/**
 * __useOrderTasksUpdateMutation__
 *
 * To run a mutation, you first call `useOrderTasksUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTasksUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTasksUpdateMutation, { data, loading, error }] = useOrderTasksUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderTasksUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderTasksUpdateMutation, OrderTasksUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderTasksUpdateMutation, OrderTasksUpdateMutationVariables>(
    OrderTasksUpdateDocument,
    options,
  );
}
export type OrderTasksUpdateMutationHookResult = ReturnType<typeof useOrderTasksUpdateMutation>;
export type OrderTasksUpdateMutationResult = Apollo.MutationResult<OrderTasksUpdateMutation>;
export type OrderTasksUpdateMutationOptions = Apollo.BaseMutationOptions<
  OrderTasksUpdateMutation,
  OrderTasksUpdateMutationVariables
>;
export const TaskOrderContextDocument = gql`
  query TaskOrderContext($accountID: ID!) {
    account(accountID: $accountID) {
      id
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useTaskOrderContextQuery__
 *
 * To run a query within a React component, call `useTaskOrderContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskOrderContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskOrderContextQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useTaskOrderContextQuery(
  baseOptions: Apollo.QueryHookOptions<TaskOrderContextQuery, TaskOrderContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskOrderContextQuery, TaskOrderContextQueryVariables>(TaskOrderContextDocument, options);
}
export function useTaskOrderContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskOrderContextQuery, TaskOrderContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskOrderContextQuery, TaskOrderContextQueryVariables>(TaskOrderContextDocument, options);
}
export type TaskOrderContextQueryHookResult = ReturnType<typeof useTaskOrderContextQuery>;
export type TaskOrderContextLazyQueryHookResult = ReturnType<typeof useTaskOrderContextLazyQuery>;
export type TaskOrderContextQueryResult = Apollo.QueryResult<TaskOrderContextQuery, TaskOrderContextQueryVariables>;
export const TaskOrderCreateDocument = gql`
  mutation TaskOrderCreate($input: Order__TaskOrderInput!) {
    result: orderTaskOrderCreate(input: $input) {
      status
      error
      order {
        id
      }
    }
  }
`;
export type TaskOrderCreateMutationFn = Apollo.MutationFunction<
  TaskOrderCreateMutation,
  TaskOrderCreateMutationVariables
>;

/**
 * __useTaskOrderCreateMutation__
 *
 * To run a mutation, you first call `useTaskOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskOrderCreateMutation, { data, loading, error }] = useTaskOrderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskOrderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<TaskOrderCreateMutation, TaskOrderCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TaskOrderCreateMutation, TaskOrderCreateMutationVariables>(
    TaskOrderCreateDocument,
    options,
  );
}
export type TaskOrderCreateMutationHookResult = ReturnType<typeof useTaskOrderCreateMutation>;
export type TaskOrderCreateMutationResult = Apollo.MutationResult<TaskOrderCreateMutation>;
export type TaskOrderCreateMutationOptions = Apollo.BaseMutationOptions<
  TaskOrderCreateMutation,
  TaskOrderCreateMutationVariables
>;
export const TaskOrderEditDocument = gql`
  query TaskOrderEdit($orderID: ID!) {
    order(orderID: $orderID) {
      id
      estimatedDuration
      movers
      number
      scheduled
      region {
        id
        tz
      }
      tasks {
        ...taskOrder__task
      }
    }
  }
  ${TaskOrder__TaskFragmentDoc}
`;

/**
 * __useTaskOrderEditQuery__
 *
 * To run a query within a React component, call `useTaskOrderEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskOrderEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskOrderEditQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useTaskOrderEditQuery(
  baseOptions: Apollo.QueryHookOptions<TaskOrderEditQuery, TaskOrderEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskOrderEditQuery, TaskOrderEditQueryVariables>(TaskOrderEditDocument, options);
}
export function useTaskOrderEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskOrderEditQuery, TaskOrderEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskOrderEditQuery, TaskOrderEditQueryVariables>(TaskOrderEditDocument, options);
}
export type TaskOrderEditQueryHookResult = ReturnType<typeof useTaskOrderEditQuery>;
export type TaskOrderEditLazyQueryHookResult = ReturnType<typeof useTaskOrderEditLazyQuery>;
export type TaskOrderEditQueryResult = Apollo.QueryResult<TaskOrderEditQuery, TaskOrderEditQueryVariables>;
export const TaskOrderUpdateDocument = gql`
  mutation TaskOrderUpdate($input: Order__Update__Input!) {
    result: orderUpdate(input: $input) {
      status
      error
      order {
        id
        scheduled
        movers
        region {
          id
          name
        }
        estimatedDuration
      }
    }
  }
`;
export type TaskOrderUpdateMutationFn = Apollo.MutationFunction<
  TaskOrderUpdateMutation,
  TaskOrderUpdateMutationVariables
>;

/**
 * __useTaskOrderUpdateMutation__
 *
 * To run a mutation, you first call `useTaskOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskOrderUpdateMutation, { data, loading, error }] = useTaskOrderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskOrderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<TaskOrderUpdateMutation, TaskOrderUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TaskOrderUpdateMutation, TaskOrderUpdateMutationVariables>(
    TaskOrderUpdateDocument,
    options,
  );
}
export type TaskOrderUpdateMutationHookResult = ReturnType<typeof useTaskOrderUpdateMutation>;
export type TaskOrderUpdateMutationResult = Apollo.MutationResult<TaskOrderUpdateMutation>;
export type TaskOrderUpdateMutationOptions = Apollo.BaseMutationOptions<
  TaskOrderUpdateMutation,
  TaskOrderUpdateMutationVariables
>;
export const BuildTestOrderRequestDocument = gql`
  mutation BuildTestOrderRequest($input: Automation__TestOrderRequestInput!) {
    buildTestOrderRequest(input: $input) {
      testOrderRequest {
        id
      }
      status
      error
    }
  }
`;
export type BuildTestOrderRequestMutationFn = Apollo.MutationFunction<
  BuildTestOrderRequestMutation,
  BuildTestOrderRequestMutationVariables
>;

/**
 * __useBuildTestOrderRequestMutation__
 *
 * To run a mutation, you first call `useBuildTestOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildTestOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildTestOrderRequestMutation, { data, loading, error }] = useBuildTestOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildTestOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildTestOrderRequestMutation, BuildTestOrderRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildTestOrderRequestMutation, BuildTestOrderRequestMutationVariables>(
    BuildTestOrderRequestDocument,
    options,
  );
}
export type BuildTestOrderRequestMutationHookResult = ReturnType<typeof useBuildTestOrderRequestMutation>;
export type BuildTestOrderRequestMutationResult = Apollo.MutationResult<BuildTestOrderRequestMutation>;
export type BuildTestOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  BuildTestOrderRequestMutation,
  BuildTestOrderRequestMutationVariables
>;
export const TestOrderRequestsDocument = gql`
  query TestOrderRequests($page: Int) {
    paginated: paginatedTestOrderRequests(page: $page) {
      results {
        id
        state
        type
        error
        subtype
        completed
        order {
          id
          state
          scheduled
          accountID
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useTestOrderRequestsQuery__
 *
 * To run a query within a React component, call `useTestOrderRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestOrderRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestOrderRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTestOrderRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<TestOrderRequestsQuery, TestOrderRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestOrderRequestsQuery, TestOrderRequestsQueryVariables>(TestOrderRequestsDocument, options);
}
export function useTestOrderRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestOrderRequestsQuery, TestOrderRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestOrderRequestsQuery, TestOrderRequestsQueryVariables>(
    TestOrderRequestsDocument,
    options,
  );
}
export type TestOrderRequestsQueryHookResult = ReturnType<typeof useTestOrderRequestsQuery>;
export type TestOrderRequestsLazyQueryHookResult = ReturnType<typeof useTestOrderRequestsLazyQuery>;
export type TestOrderRequestsQueryResult = Apollo.QueryResult<TestOrderRequestsQuery, TestOrderRequestsQueryVariables>;
export const WaitlistRequestsDocument = gql`
  query WaitlistRequests($orderID: ID!) {
    order(orderID: $orderID) {
      id
      waitlistRequests {
        ...waitlistRequest
      }
    }
  }
  ${WaitlistRequestFragmentDoc}
`;

/**
 * __useWaitlistRequestsQuery__
 *
 * To run a query within a React component, call `useWaitlistRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistRequestsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useWaitlistRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<WaitlistRequestsQuery, WaitlistRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WaitlistRequestsQuery, WaitlistRequestsQueryVariables>(WaitlistRequestsDocument, options);
}
export function useWaitlistRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WaitlistRequestsQuery, WaitlistRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WaitlistRequestsQuery, WaitlistRequestsQueryVariables>(WaitlistRequestsDocument, options);
}
export type WaitlistRequestsQueryHookResult = ReturnType<typeof useWaitlistRequestsQuery>;
export type WaitlistRequestsLazyQueryHookResult = ReturnType<typeof useWaitlistRequestsLazyQuery>;
export type WaitlistRequestsQueryResult = Apollo.QueryResult<WaitlistRequestsQuery, WaitlistRequestsQueryVariables>;
export const PalletGradesDocument = gql`
  query PalletGrades($palletID: ID!) {
    palletGrades(palletID: $palletID) {
      ...palletGrade
    }
  }
  ${PalletGradeFragmentDoc}
`;

/**
 * __usePalletGradesQuery__
 *
 * To run a query within a React component, call `usePalletGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletGradesQuery({
 *   variables: {
 *      palletID: // value for 'palletID'
 *   },
 * });
 */
export function usePalletGradesQuery(
  baseOptions: Apollo.QueryHookOptions<PalletGradesQuery, PalletGradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletGradesQuery, PalletGradesQueryVariables>(PalletGradesDocument, options);
}
export function usePalletGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletGradesQuery, PalletGradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletGradesQuery, PalletGradesQueryVariables>(PalletGradesDocument, options);
}
export type PalletGradesQueryHookResult = ReturnType<typeof usePalletGradesQuery>;
export type PalletGradesLazyQueryHookResult = ReturnType<typeof usePalletGradesLazyQuery>;
export type PalletGradesQueryResult = Apollo.QueryResult<PalletGradesQuery, PalletGradesQueryVariables>;
export const ActivePalletRequestsDocument = gql`
  query ActivePalletRequests($warehouseId: ID!, $kind: PalletRequestKindEnum!) {
    activePalletRequests(warehouseId: $warehouseId, kind: $kind) {
      id
      createdAt
      deletedAt
      pallet {
        id
        barcode {
          value
        }
        items {
          account {
            id
            customer {
              id
              name
            }
          }
        }
        palletInventories {
          id
          sku {
            id
            account {
              id
              customer {
                name
              }
            }
          }
        }
        itemsCount
        densityMetrics {
          percentage
        }
        location {
          id
          value
        }
        warehouse {
          id
          tz
        }
      }
      requestor {
        id
        name
      }
    }
  }
`;

/**
 * __useActivePalletRequestsQuery__
 *
 * To run a query within a React component, call `useActivePalletRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePalletRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePalletRequestsQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useActivePalletRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<ActivePalletRequestsQuery, ActivePalletRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivePalletRequestsQuery, ActivePalletRequestsQueryVariables>(
    ActivePalletRequestsDocument,
    options,
  );
}
export function useActivePalletRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivePalletRequestsQuery, ActivePalletRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivePalletRequestsQuery, ActivePalletRequestsQueryVariables>(
    ActivePalletRequestsDocument,
    options,
  );
}
export type ActivePalletRequestsQueryHookResult = ReturnType<typeof useActivePalletRequestsQuery>;
export type ActivePalletRequestsLazyQueryHookResult = ReturnType<typeof useActivePalletRequestsLazyQuery>;
export type ActivePalletRequestsQueryResult = Apollo.QueryResult<
  ActivePalletRequestsQuery,
  ActivePalletRequestsQueryVariables
>;
export const BuildPalletRequestDocument = gql`
  mutation BuildPalletRequest($input: PalletRequestInput!) {
    buildPalletRequest(input: $input) {
      error
      status
    }
  }
`;
export type BuildPalletRequestMutationFn = Apollo.MutationFunction<
  BuildPalletRequestMutation,
  BuildPalletRequestMutationVariables
>;

/**
 * __useBuildPalletRequestMutation__
 *
 * To run a mutation, you first call `useBuildPalletRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildPalletRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildPalletRequestMutation, { data, loading, error }] = useBuildPalletRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildPalletRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildPalletRequestMutation, BuildPalletRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildPalletRequestMutation, BuildPalletRequestMutationVariables>(
    BuildPalletRequestDocument,
    options,
  );
}
export type BuildPalletRequestMutationHookResult = ReturnType<typeof useBuildPalletRequestMutation>;
export type BuildPalletRequestMutationResult = Apollo.MutationResult<BuildPalletRequestMutation>;
export type BuildPalletRequestMutationOptions = Apollo.BaseMutationOptions<
  BuildPalletRequestMutation,
  BuildPalletRequestMutationVariables
>;
export const DeletePalletRequestDocument = gql`
  mutation DeletePalletRequest($id: ID!) {
    deletePalletRequest(id: $id) {
      error
      status
    }
  }
`;
export type DeletePalletRequestMutationFn = Apollo.MutationFunction<
  DeletePalletRequestMutation,
  DeletePalletRequestMutationVariables
>;

/**
 * __useDeletePalletRequestMutation__
 *
 * To run a mutation, you first call `useDeletePalletRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePalletRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePalletRequestMutation, { data, loading, error }] = useDeletePalletRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePalletRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePalletRequestMutation, DeletePalletRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePalletRequestMutation, DeletePalletRequestMutationVariables>(
    DeletePalletRequestDocument,
    options,
  );
}
export type DeletePalletRequestMutationHookResult = ReturnType<typeof useDeletePalletRequestMutation>;
export type DeletePalletRequestMutationResult = Apollo.MutationResult<DeletePalletRequestMutation>;
export type DeletePalletRequestMutationOptions = Apollo.BaseMutationOptions<
  DeletePalletRequestMutation,
  DeletePalletRequestMutationVariables
>;
export const InProgressManualPalletRequestDocument = gql`
  query InProgressManualPalletRequest($palletID: ID!) {
    inProgressManualPalletRequest(palletID: $palletID) {
      id
    }
  }
`;

/**
 * __useInProgressManualPalletRequestQuery__
 *
 * To run a query within a React component, call `useInProgressManualPalletRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useInProgressManualPalletRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInProgressManualPalletRequestQuery({
 *   variables: {
 *      palletID: // value for 'palletID'
 *   },
 * });
 */
export function useInProgressManualPalletRequestQuery(
  baseOptions: Apollo.QueryHookOptions<InProgressManualPalletRequestQuery, InProgressManualPalletRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InProgressManualPalletRequestQuery, InProgressManualPalletRequestQueryVariables>(
    InProgressManualPalletRequestDocument,
    options,
  );
}
export function useInProgressManualPalletRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InProgressManualPalletRequestQuery,
    InProgressManualPalletRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InProgressManualPalletRequestQuery, InProgressManualPalletRequestQueryVariables>(
    InProgressManualPalletRequestDocument,
    options,
  );
}
export type InProgressManualPalletRequestQueryHookResult = ReturnType<typeof useInProgressManualPalletRequestQuery>;
export type InProgressManualPalletRequestLazyQueryHookResult = ReturnType<
  typeof useInProgressManualPalletRequestLazyQuery
>;
export type InProgressManualPalletRequestQueryResult = Apollo.QueryResult<
  InProgressManualPalletRequestQuery,
  InProgressManualPalletRequestQueryVariables
>;
export const BuildPalletTypeDocument = gql`
  mutation BuildPalletType($input: PalletTypeInput!) {
    buildPalletType(input: $input) {
      error
    }
  }
`;
export type BuildPalletTypeMutationFn = Apollo.MutationFunction<
  BuildPalletTypeMutation,
  BuildPalletTypeMutationVariables
>;

/**
 * __useBuildPalletTypeMutation__
 *
 * To run a mutation, you first call `useBuildPalletTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildPalletTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildPalletTypeMutation, { data, loading, error }] = useBuildPalletTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildPalletTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildPalletTypeMutation, BuildPalletTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildPalletTypeMutation, BuildPalletTypeMutationVariables>(
    BuildPalletTypeDocument,
    options,
  );
}
export type BuildPalletTypeMutationHookResult = ReturnType<typeof useBuildPalletTypeMutation>;
export type BuildPalletTypeMutationResult = Apollo.MutationResult<BuildPalletTypeMutation>;
export type BuildPalletTypeMutationOptions = Apollo.BaseMutationOptions<
  BuildPalletTypeMutation,
  BuildPalletTypeMutationVariables
>;
export const ModifyPalletTypeDocument = gql`
  mutation ModifyPalletType($input: PalletTypeInput!, $id: ID!) {
    modifyPalletType(input: $input, id: $id) {
      error
    }
  }
`;
export type ModifyPalletTypeMutationFn = Apollo.MutationFunction<
  ModifyPalletTypeMutation,
  ModifyPalletTypeMutationVariables
>;

/**
 * __useModifyPalletTypeMutation__
 *
 * To run a mutation, you first call `useModifyPalletTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPalletTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPalletTypeMutation, { data, loading, error }] = useModifyPalletTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyPalletTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyPalletTypeMutation, ModifyPalletTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyPalletTypeMutation, ModifyPalletTypeMutationVariables>(
    ModifyPalletTypeDocument,
    options,
  );
}
export type ModifyPalletTypeMutationHookResult = ReturnType<typeof useModifyPalletTypeMutation>;
export type ModifyPalletTypeMutationResult = Apollo.MutationResult<ModifyPalletTypeMutation>;
export type ModifyPalletTypeMutationOptions = Apollo.BaseMutationOptions<
  ModifyPalletTypeMutation,
  ModifyPalletTypeMutationVariables
>;
export const PalletTypeDocument = gql`
  query PalletType($id: ID!) {
    palletType(id: $id) {
      id
      name
      capacity
      itemCategories {
        id
        name
      }
      partCategories {
        id
        name
      }
      materialCategories {
        id
        name
      }
      legacySize
      active
      description
      position
    }
  }
`;

/**
 * __usePalletTypeQuery__
 *
 * To run a query within a React component, call `usePalletTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePalletTypeQuery(baseOptions: Apollo.QueryHookOptions<PalletTypeQuery, PalletTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletTypeQuery, PalletTypeQueryVariables>(PalletTypeDocument, options);
}
export function usePalletTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletTypeQuery, PalletTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletTypeQuery, PalletTypeQueryVariables>(PalletTypeDocument, options);
}
export type PalletTypeQueryHookResult = ReturnType<typeof usePalletTypeQuery>;
export type PalletTypeLazyQueryHookResult = ReturnType<typeof usePalletTypeLazyQuery>;
export type PalletTypeQueryResult = Apollo.QueryResult<PalletTypeQuery, PalletTypeQueryVariables>;
export const PalletTypesDocument = gql`
  query PalletTypes {
    palletTypes {
      id
      name
      capacity
      itemCategories {
        id
        name
      }
      partCategories {
        id
        name
      }
      materialCategories {
        id
        name
      }
      legacySize
      active
      description
      position
    }
  }
`;

/**
 * __usePalletTypesQuery__
 *
 * To run a query within a React component, call `usePalletTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePalletTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePalletTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePalletTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<PalletTypesQuery, PalletTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PalletTypesQuery, PalletTypesQueryVariables>(PalletTypesDocument, options);
}
export function usePalletTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PalletTypesQuery, PalletTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PalletTypesQuery, PalletTypesQueryVariables>(PalletTypesDocument, options);
}
export type PalletTypesQueryHookResult = ReturnType<typeof usePalletTypesQuery>;
export type PalletTypesLazyQueryHookResult = ReturnType<typeof usePalletTypesLazyQuery>;
export type PalletTypesQueryResult = Apollo.QueryResult<PalletTypesQuery, PalletTypesQueryVariables>;
export const PaymentPlanAccountDocument = gql`
  query PaymentPlanAccount($accountID: ID!) {
    account(accountID: $accountID) {
      ...Admin__PaymentPlan__Account
    }
  }
  ${Admin__PaymentPlan__AccountFragmentDoc}
`;

/**
 * __usePaymentPlanAccountQuery__
 *
 * To run a query within a React component, call `usePaymentPlanAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentPlanAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentPlanAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function usePaymentPlanAccountQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentPlanAccountQuery, PaymentPlanAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentPlanAccountQuery, PaymentPlanAccountQueryVariables>(
    PaymentPlanAccountDocument,
    options,
  );
}
export function usePaymentPlanAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentPlanAccountQuery, PaymentPlanAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentPlanAccountQuery, PaymentPlanAccountQueryVariables>(
    PaymentPlanAccountDocument,
    options,
  );
}
export type PaymentPlanAccountQueryHookResult = ReturnType<typeof usePaymentPlanAccountQuery>;
export type PaymentPlanAccountLazyQueryHookResult = ReturnType<typeof usePaymentPlanAccountLazyQuery>;
export type PaymentPlanAccountQueryResult = Apollo.QueryResult<
  PaymentPlanAccountQuery,
  PaymentPlanAccountQueryVariables
>;
export const AcceptPaymentPlanOfferDocument = gql`
  mutation AcceptPaymentPlanOffer(
    $input: PaymentPlan__Offer__SaveInput!
    $sourceID: ID
    $offlineSource: Billing__Invoice__OfflinePaymentChargeInput
  ) {
    acceptOffer: acceptPaymentPlanOffer(input: $input, sourceID: $sourceID, offlineSource: $offlineSource) {
      error
      status
      offer {
        ...Admin__PaymentPlan__Offer
      }
    }
  }
  ${Admin__PaymentPlan__OfferFragmentDoc}
`;
export type AcceptPaymentPlanOfferMutationFn = Apollo.MutationFunction<
  AcceptPaymentPlanOfferMutation,
  AcceptPaymentPlanOfferMutationVariables
>;

/**
 * __useAcceptPaymentPlanOfferMutation__
 *
 * To run a mutation, you first call `useAcceptPaymentPlanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPaymentPlanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPaymentPlanOfferMutation, { data, loading, error }] = useAcceptPaymentPlanOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sourceID: // value for 'sourceID'
 *      offlineSource: // value for 'offlineSource'
 *   },
 * });
 */
export function useAcceptPaymentPlanOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptPaymentPlanOfferMutation, AcceptPaymentPlanOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptPaymentPlanOfferMutation, AcceptPaymentPlanOfferMutationVariables>(
    AcceptPaymentPlanOfferDocument,
    options,
  );
}
export type AcceptPaymentPlanOfferMutationHookResult = ReturnType<typeof useAcceptPaymentPlanOfferMutation>;
export type AcceptPaymentPlanOfferMutationResult = Apollo.MutationResult<AcceptPaymentPlanOfferMutation>;
export type AcceptPaymentPlanOfferMutationOptions = Apollo.BaseMutationOptions<
  AcceptPaymentPlanOfferMutation,
  AcceptPaymentPlanOfferMutationVariables
>;
export const CancelPaymentPlanOfferDocument = gql`
  mutation CancelPaymentPlanOffer($offerID: ID!) {
    cancelOffer: cancelPaymentPlanOffer(offerID: $offerID) {
      error
      status
      offer {
        id
        state
      }
    }
  }
`;
export type CancelPaymentPlanOfferMutationFn = Apollo.MutationFunction<
  CancelPaymentPlanOfferMutation,
  CancelPaymentPlanOfferMutationVariables
>;

/**
 * __useCancelPaymentPlanOfferMutation__
 *
 * To run a mutation, you first call `useCancelPaymentPlanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentPlanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentPlanOfferMutation, { data, loading, error }] = useCancelPaymentPlanOfferMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useCancelPaymentPlanOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelPaymentPlanOfferMutation, CancelPaymentPlanOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPaymentPlanOfferMutation, CancelPaymentPlanOfferMutationVariables>(
    CancelPaymentPlanOfferDocument,
    options,
  );
}
export type CancelPaymentPlanOfferMutationHookResult = ReturnType<typeof useCancelPaymentPlanOfferMutation>;
export type CancelPaymentPlanOfferMutationResult = Apollo.MutationResult<CancelPaymentPlanOfferMutation>;
export type CancelPaymentPlanOfferMutationOptions = Apollo.BaseMutationOptions<
  CancelPaymentPlanOfferMutation,
  CancelPaymentPlanOfferMutationVariables
>;
export const ExtendPaymentPlanOfferDocument = gql`
  mutation ExtendPaymentPlanOffer($input: PaymentPlan__Offer__SaveInput!) {
    extendOffer: extendPaymentPlanOffer(input: $input) {
      error
      status
      offer {
        ...Admin__PaymentPlan__Offer
      }
    }
  }
  ${Admin__PaymentPlan__OfferFragmentDoc}
`;
export type ExtendPaymentPlanOfferMutationFn = Apollo.MutationFunction<
  ExtendPaymentPlanOfferMutation,
  ExtendPaymentPlanOfferMutationVariables
>;

/**
 * __useExtendPaymentPlanOfferMutation__
 *
 * To run a mutation, you first call `useExtendPaymentPlanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendPaymentPlanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendPaymentPlanOfferMutation, { data, loading, error }] = useExtendPaymentPlanOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendPaymentPlanOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtendPaymentPlanOfferMutation, ExtendPaymentPlanOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendPaymentPlanOfferMutation, ExtendPaymentPlanOfferMutationVariables>(
    ExtendPaymentPlanOfferDocument,
    options,
  );
}
export type ExtendPaymentPlanOfferMutationHookResult = ReturnType<typeof useExtendPaymentPlanOfferMutation>;
export type ExtendPaymentPlanOfferMutationResult = Apollo.MutationResult<ExtendPaymentPlanOfferMutation>;
export type ExtendPaymentPlanOfferMutationOptions = Apollo.BaseMutationOptions<
  ExtendPaymentPlanOfferMutation,
  ExtendPaymentPlanOfferMutationVariables
>;
export const SavePaymentPlanOfferDocument = gql`
  mutation SavePaymentPlanOffer($input: PaymentPlan__Offer__SaveInput!) {
    saveOffer: savePaymentPlanOffer(input: $input) {
      error
      status
      offer {
        ...Admin__PaymentPlan__Offer
      }
    }
  }
  ${Admin__PaymentPlan__OfferFragmentDoc}
`;
export type SavePaymentPlanOfferMutationFn = Apollo.MutationFunction<
  SavePaymentPlanOfferMutation,
  SavePaymentPlanOfferMutationVariables
>;

/**
 * __useSavePaymentPlanOfferMutation__
 *
 * To run a mutation, you first call `useSavePaymentPlanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaymentPlanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaymentPlanOfferMutation, { data, loading, error }] = useSavePaymentPlanOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePaymentPlanOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<SavePaymentPlanOfferMutation, SavePaymentPlanOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavePaymentPlanOfferMutation, SavePaymentPlanOfferMutationVariables>(
    SavePaymentPlanOfferDocument,
    options,
  );
}
export type SavePaymentPlanOfferMutationHookResult = ReturnType<typeof useSavePaymentPlanOfferMutation>;
export type SavePaymentPlanOfferMutationResult = Apollo.MutationResult<SavePaymentPlanOfferMutation>;
export type SavePaymentPlanOfferMutationOptions = Apollo.BaseMutationOptions<
  SavePaymentPlanOfferMutation,
  SavePaymentPlanOfferMutationVariables
>;
export const UnforgivenInvoicesDocument = gql`
  query UnforgivenInvoices($accountID: ID!, $statuses: [Billing__Invoice__Status!]) {
    invoices: unforgivenInvoices(accountID: $accountID, statuses: $statuses) {
      ...Admin__Billing__Invoice
    }
  }
  ${Admin__Billing__InvoiceFragmentDoc}
`;

/**
 * __useUnforgivenInvoicesQuery__
 *
 * To run a query within a React component, call `useUnforgivenInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnforgivenInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnforgivenInvoicesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useUnforgivenInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<UnforgivenInvoicesQuery, UnforgivenInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnforgivenInvoicesQuery, UnforgivenInvoicesQueryVariables>(
    UnforgivenInvoicesDocument,
    options,
  );
}
export function useUnforgivenInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnforgivenInvoicesQuery, UnforgivenInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnforgivenInvoicesQuery, UnforgivenInvoicesQueryVariables>(
    UnforgivenInvoicesDocument,
    options,
  );
}
export type UnforgivenInvoicesQueryHookResult = ReturnType<typeof useUnforgivenInvoicesQuery>;
export type UnforgivenInvoicesLazyQueryHookResult = ReturnType<typeof useUnforgivenInvoicesLazyQuery>;
export type UnforgivenInvoicesQueryResult = Apollo.QueryResult<
  UnforgivenInvoicesQuery,
  UnforgivenInvoicesQueryVariables
>;
export const PaymentPlanOfferDocument = gql`
  query PaymentPlanOffer($accountID: ID!, $offerID: ID!) {
    offer: paymentPlanOffer(accountID: $accountID, offerID: $offerID) {
      ...Admin__PaymentPlan__Offer
    }
  }
  ${Admin__PaymentPlan__OfferFragmentDoc}
`;

/**
 * __usePaymentPlanOfferQuery__
 *
 * To run a query within a React component, call `usePaymentPlanOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentPlanOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentPlanOfferQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function usePaymentPlanOfferQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>(PaymentPlanOfferDocument, options);
}
export function usePaymentPlanOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>(PaymentPlanOfferDocument, options);
}
export type PaymentPlanOfferQueryHookResult = ReturnType<typeof usePaymentPlanOfferQuery>;
export type PaymentPlanOfferLazyQueryHookResult = ReturnType<typeof usePaymentPlanOfferLazyQuery>;
export type PaymentPlanOfferQueryResult = Apollo.QueryResult<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>;
export const BuildCustomerTicketFromCallDocument = gql`
  mutation BuildCustomerTicketFromCall($input: CustomerTickets__BuildFromCallInput!) {
    buildCustomerTicketFromCall(input: $input) {
      status
      error
    }
  }
`;
export type BuildCustomerTicketFromCallMutationFn = Apollo.MutationFunction<
  BuildCustomerTicketFromCallMutation,
  BuildCustomerTicketFromCallMutationVariables
>;

/**
 * __useBuildCustomerTicketFromCallMutation__
 *
 * To run a mutation, you first call `useBuildCustomerTicketFromCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildCustomerTicketFromCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildCustomerTicketFromCallMutation, { data, loading, error }] = useBuildCustomerTicketFromCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildCustomerTicketFromCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildCustomerTicketFromCallMutation,
    BuildCustomerTicketFromCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildCustomerTicketFromCallMutation, BuildCustomerTicketFromCallMutationVariables>(
    BuildCustomerTicketFromCallDocument,
    options,
  );
}
export type BuildCustomerTicketFromCallMutationHookResult = ReturnType<typeof useBuildCustomerTicketFromCallMutation>;
export type BuildCustomerTicketFromCallMutationResult = Apollo.MutationResult<BuildCustomerTicketFromCallMutation>;
export type BuildCustomerTicketFromCallMutationOptions = Apollo.BaseMutationOptions<
  BuildCustomerTicketFromCallMutation,
  BuildCustomerTicketFromCallMutationVariables
>;
export const BuildInteractionFromCallDocument = gql`
  mutation BuildInteractionFromCall($input: CustomerTickets__BuildInteractionFromCallInput!) {
    buildInteractionFromCall(input: $input) {
      status
      error
    }
  }
`;
export type BuildInteractionFromCallMutationFn = Apollo.MutationFunction<
  BuildInteractionFromCallMutation,
  BuildInteractionFromCallMutationVariables
>;

/**
 * __useBuildInteractionFromCallMutation__
 *
 * To run a mutation, you first call `useBuildInteractionFromCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildInteractionFromCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildInteractionFromCallMutation, { data, loading, error }] = useBuildInteractionFromCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildInteractionFromCallMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildInteractionFromCallMutation, BuildInteractionFromCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildInteractionFromCallMutation, BuildInteractionFromCallMutationVariables>(
    BuildInteractionFromCallDocument,
    options,
  );
}
export type BuildInteractionFromCallMutationHookResult = ReturnType<typeof useBuildInteractionFromCallMutation>;
export type BuildInteractionFromCallMutationResult = Apollo.MutationResult<BuildInteractionFromCallMutation>;
export type BuildInteractionFromCallMutationOptions = Apollo.BaseMutationOptions<
  BuildInteractionFromCallMutation,
  BuildInteractionFromCallMutationVariables
>;
export const CustomerTicketByCallDocument = gql`
  query CustomerTicketByCall($sid: String!) {
    customerTicketByCall(sid: $sid) {
      id
      category
      subCategory
      priority
      assignee {
        id
        name
      }
      group {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomerTicketByCallQuery__
 *
 * To run a query within a React component, call `useCustomerTicketByCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTicketByCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTicketByCallQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useCustomerTicketByCallQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerTicketByCallQuery, CustomerTicketByCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerTicketByCallQuery, CustomerTicketByCallQueryVariables>(
    CustomerTicketByCallDocument,
    options,
  );
}
export function useCustomerTicketByCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerTicketByCallQuery, CustomerTicketByCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerTicketByCallQuery, CustomerTicketByCallQueryVariables>(
    CustomerTicketByCallDocument,
    options,
  );
}
export type CustomerTicketByCallQueryHookResult = ReturnType<typeof useCustomerTicketByCallQuery>;
export type CustomerTicketByCallLazyQueryHookResult = ReturnType<typeof useCustomerTicketByCallLazyQuery>;
export type CustomerTicketByCallQueryResult = Apollo.QueryResult<
  CustomerTicketByCallQuery,
  CustomerTicketByCallQueryVariables
>;
export const ProcessCustomerTicketFromCallDocument = gql`
  mutation ProcessCustomerTicketFromCall($input: CustomerTickets__ProcessFromCallInput!) {
    processCustomerTicketFromCall(input: $input) {
      status
      error
      customerTicket {
        ...customerTicket
      }
    }
  }
  ${CustomerTicketFragmentDoc}
`;
export type ProcessCustomerTicketFromCallMutationFn = Apollo.MutationFunction<
  ProcessCustomerTicketFromCallMutation,
  ProcessCustomerTicketFromCallMutationVariables
>;

/**
 * __useProcessCustomerTicketFromCallMutation__
 *
 * To run a mutation, you first call `useProcessCustomerTicketFromCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCustomerTicketFromCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCustomerTicketFromCallMutation, { data, loading, error }] = useProcessCustomerTicketFromCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessCustomerTicketFromCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessCustomerTicketFromCallMutation,
    ProcessCustomerTicketFromCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProcessCustomerTicketFromCallMutation, ProcessCustomerTicketFromCallMutationVariables>(
    ProcessCustomerTicketFromCallDocument,
    options,
  );
}
export type ProcessCustomerTicketFromCallMutationHookResult = ReturnType<
  typeof useProcessCustomerTicketFromCallMutation
>;
export type ProcessCustomerTicketFromCallMutationResult = Apollo.MutationResult<ProcessCustomerTicketFromCallMutation>;
export type ProcessCustomerTicketFromCallMutationOptions = Apollo.BaseMutationOptions<
  ProcessCustomerTicketFromCallMutation,
  ProcessCustomerTicketFromCallMutationVariables
>;
export const ConnectPhoneCallDocument = gql`
  mutation ConnectPhoneCall($input: ConnectPhoneCallInput!) {
    connectPhoneCall(input: $input) {
      error
      status
    }
  }
`;
export type ConnectPhoneCallMutationFn = Apollo.MutationFunction<
  ConnectPhoneCallMutation,
  ConnectPhoneCallMutationVariables
>;

/**
 * __useConnectPhoneCallMutation__
 *
 * To run a mutation, you first call `useConnectPhoneCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectPhoneCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectPhoneCallMutation, { data, loading, error }] = useConnectPhoneCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectPhoneCallMutation(
  baseOptions?: Apollo.MutationHookOptions<ConnectPhoneCallMutation, ConnectPhoneCallMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectPhoneCallMutation, ConnectPhoneCallMutationVariables>(
    ConnectPhoneCallDocument,
    options,
  );
}
export type ConnectPhoneCallMutationHookResult = ReturnType<typeof useConnectPhoneCallMutation>;
export type ConnectPhoneCallMutationResult = Apollo.MutationResult<ConnectPhoneCallMutation>;
export type ConnectPhoneCallMutationOptions = Apollo.BaseMutationOptions<
  ConnectPhoneCallMutation,
  ConnectPhoneCallMutationVariables
>;
export const AccountsDocument = gql`
  query Accounts($query: String!, $page: Int) {
    paginated: paginatedAccounts(query: $query, page: $page) {
      results {
        id
        number
        customer {
          id
          name
          phone
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const PredecessorOrderSelectorDocument = gql`
  query PredecessorOrderSelector($accountID: ID!) {
    orders(accountID: $accountID) {
      id
      scheduled
      type
      subtype
      number
    }
  }
`;

/**
 * __usePredecessorOrderSelectorQuery__
 *
 * To run a query within a React component, call `usePredecessorOrderSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredecessorOrderSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredecessorOrderSelectorQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function usePredecessorOrderSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<PredecessorOrderSelectorQuery, PredecessorOrderSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PredecessorOrderSelectorQuery, PredecessorOrderSelectorQueryVariables>(
    PredecessorOrderSelectorDocument,
    options,
  );
}
export function usePredecessorOrderSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PredecessorOrderSelectorQuery, PredecessorOrderSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PredecessorOrderSelectorQuery, PredecessorOrderSelectorQueryVariables>(
    PredecessorOrderSelectorDocument,
    options,
  );
}
export type PredecessorOrderSelectorQueryHookResult = ReturnType<typeof usePredecessorOrderSelectorQuery>;
export type PredecessorOrderSelectorLazyQueryHookResult = ReturnType<typeof usePredecessorOrderSelectorLazyQuery>;
export type PredecessorOrderSelectorQueryResult = Apollo.QueryResult<
  PredecessorOrderSelectorQuery,
  PredecessorOrderSelectorQueryVariables
>;
export const PaginatedPricingUploadsDocument = gql`
  query PaginatedPricingUploads($page: Int) {
    paginated: paginatedPricingUploads(page: $page) {
      results {
        ...Pricing__upload
      }
      pagination {
        ...pagination
      }
    }
  }
  ${Pricing__UploadFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedPricingUploadsQuery__
 *
 * To run a query within a React component, call `usePaginatedPricingUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPricingUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPricingUploadsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedPricingUploadsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedPricingUploadsQuery, PaginatedPricingUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedPricingUploadsQuery, PaginatedPricingUploadsQueryVariables>(
    PaginatedPricingUploadsDocument,
    options,
  );
}
export function usePaginatedPricingUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPricingUploadsQuery, PaginatedPricingUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedPricingUploadsQuery, PaginatedPricingUploadsQueryVariables>(
    PaginatedPricingUploadsDocument,
    options,
  );
}
export type PaginatedPricingUploadsQueryHookResult = ReturnType<typeof usePaginatedPricingUploadsQuery>;
export type PaginatedPricingUploadsLazyQueryHookResult = ReturnType<typeof usePaginatedPricingUploadsLazyQuery>;
export type PaginatedPricingUploadsQueryResult = Apollo.QueryResult<
  PaginatedPricingUploadsQuery,
  PaginatedPricingUploadsQueryVariables
>;
export const PricingUploadDocument = gql`
  query PricingUpload($id: ID!) {
    upload: pricingUpload(id: $id) {
      ...Pricing__upload
    }
  }
  ${Pricing__UploadFragmentDoc}
`;

/**
 * __usePricingUploadQuery__
 *
 * To run a query within a React component, call `usePricingUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePricingUploadQuery(
  baseOptions: Apollo.QueryHookOptions<PricingUploadQuery, PricingUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingUploadQuery, PricingUploadQueryVariables>(PricingUploadDocument, options);
}
export function usePricingUploadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingUploadQuery, PricingUploadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingUploadQuery, PricingUploadQueryVariables>(PricingUploadDocument, options);
}
export type PricingUploadQueryHookResult = ReturnType<typeof usePricingUploadQuery>;
export type PricingUploadLazyQueryHookResult = ReturnType<typeof usePricingUploadLazyQuery>;
export type PricingUploadQueryResult = Apollo.QueryResult<PricingUploadQuery, PricingUploadQueryVariables>;
export const SavePricingUploadDocument = gql`
  mutation SavePricingUpload($input: Pricing__UploadInput!) {
    save: savePricingUpload(input: $input) {
      upload {
        ...Pricing__upload
      }
    }
  }
  ${Pricing__UploadFragmentDoc}
`;
export type SavePricingUploadMutationFn = Apollo.MutationFunction<
  SavePricingUploadMutation,
  SavePricingUploadMutationVariables
>;

/**
 * __useSavePricingUploadMutation__
 *
 * To run a mutation, you first call `useSavePricingUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePricingUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePricingUploadMutation, { data, loading, error }] = useSavePricingUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePricingUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<SavePricingUploadMutation, SavePricingUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavePricingUploadMutation, SavePricingUploadMutationVariables>(
    SavePricingUploadDocument,
    options,
  );
}
export type SavePricingUploadMutationHookResult = ReturnType<typeof useSavePricingUploadMutation>;
export type SavePricingUploadMutationResult = Apollo.MutationResult<SavePricingUploadMutation>;
export type SavePricingUploadMutationOptions = Apollo.BaseMutationOptions<
  SavePricingUploadMutation,
  SavePricingUploadMutationVariables
>;
export const PaginatedPtoRequestGroupsDocument = gql`
  query PaginatedPTORequestGroups($filters: PTO__RequestFiltersInput, $page: Int) {
    paginatedPtoRequestGroups(filters: $filters, page: $page) {
      results {
        id
        requests {
          id
          hours
          requestDate
        }
        createdAt
        endDate
        kind {
          id
          code
          name
        }
        startDate
        state
        totalHours
        user {
          id
          name
          team {
            id
            name
          }
        }
        reviewerNote
      }
      pagination {
        ...pagination
      }
    }
    teams {
      id
      name
    }
    users {
      id
      name
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedPtoRequestGroupsQuery__
 *
 * To run a query within a React component, call `usePaginatedPtoRequestGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPtoRequestGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPtoRequestGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedPtoRequestGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedPtoRequestGroupsQuery, PaginatedPtoRequestGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedPtoRequestGroupsQuery, PaginatedPtoRequestGroupsQueryVariables>(
    PaginatedPtoRequestGroupsDocument,
    options,
  );
}
export function usePaginatedPtoRequestGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPtoRequestGroupsQuery, PaginatedPtoRequestGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedPtoRequestGroupsQuery, PaginatedPtoRequestGroupsQueryVariables>(
    PaginatedPtoRequestGroupsDocument,
    options,
  );
}
export type PaginatedPtoRequestGroupsQueryHookResult = ReturnType<typeof usePaginatedPtoRequestGroupsQuery>;
export type PaginatedPtoRequestGroupsLazyQueryHookResult = ReturnType<typeof usePaginatedPtoRequestGroupsLazyQuery>;
export type PaginatedPtoRequestGroupsQueryResult = Apollo.QueryResult<
  PaginatedPtoRequestGroupsQuery,
  PaginatedPtoRequestGroupsQueryVariables
>;
export const PtoRequestGroupDocument = gql`
  query PTORequestGroup($id: ID!) {
    ptoRequestGroup(id: $id) {
      id
      totalHours
      requests {
        id
        hours
        requestDate
        conflictingShiftAssignmentExists
      }
      user {
        id
        name
      }
      kind {
        id
        name
      }
    }
  }
`;

/**
 * __usePtoRequestGroupQuery__
 *
 * To run a query within a React component, call `usePtoRequestGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePtoRequestGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePtoRequestGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePtoRequestGroupQuery(
  baseOptions: Apollo.QueryHookOptions<PtoRequestGroupQuery, PtoRequestGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PtoRequestGroupQuery, PtoRequestGroupQueryVariables>(PtoRequestGroupDocument, options);
}
export function usePtoRequestGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PtoRequestGroupQuery, PtoRequestGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PtoRequestGroupQuery, PtoRequestGroupQueryVariables>(PtoRequestGroupDocument, options);
}
export type PtoRequestGroupQueryHookResult = ReturnType<typeof usePtoRequestGroupQuery>;
export type PtoRequestGroupLazyQueryHookResult = ReturnType<typeof usePtoRequestGroupLazyQuery>;
export type PtoRequestGroupQueryResult = Apollo.QueryResult<PtoRequestGroupQuery, PtoRequestGroupQueryVariables>;
export const PtoRequestGroupReviewDocument = gql`
  mutation PTORequestGroupReview($input: Workforce__PTORequestGroupReview__Input!) {
    result: ptoRequestGroupReview(input: $input) {
      requestGroup {
        id
        reviewerNote
        state
      }
    }
  }
`;
export type PtoRequestGroupReviewMutationFn = Apollo.MutationFunction<
  PtoRequestGroupReviewMutation,
  PtoRequestGroupReviewMutationVariables
>;

/**
 * __usePtoRequestGroupReviewMutation__
 *
 * To run a mutation, you first call `usePtoRequestGroupReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePtoRequestGroupReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ptoRequestGroupReviewMutation, { data, loading, error }] = usePtoRequestGroupReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePtoRequestGroupReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<PtoRequestGroupReviewMutation, PtoRequestGroupReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PtoRequestGroupReviewMutation, PtoRequestGroupReviewMutationVariables>(
    PtoRequestGroupReviewDocument,
    options,
  );
}
export type PtoRequestGroupReviewMutationHookResult = ReturnType<typeof usePtoRequestGroupReviewMutation>;
export type PtoRequestGroupReviewMutationResult = Apollo.MutationResult<PtoRequestGroupReviewMutation>;
export type PtoRequestGroupReviewMutationOptions = Apollo.BaseMutationOptions<
  PtoRequestGroupReviewMutation,
  PtoRequestGroupReviewMutationVariables
>;
export const GenerateTestPasswordDocument = gql`
  mutation GenerateTestPassword($resourceID: ID!, $resourceType: String!) {
    generate: generateTestPassword(resourceID: $resourceID, resourceType: $resourceType) {
      status
      error
      password
    }
  }
`;
export type GenerateTestPasswordMutationFn = Apollo.MutationFunction<
  GenerateTestPasswordMutation,
  GenerateTestPasswordMutationVariables
>;

/**
 * __useGenerateTestPasswordMutation__
 *
 * To run a mutation, you first call `useGenerateTestPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTestPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTestPasswordMutation, { data, loading, error }] = useGenerateTestPasswordMutation({
 *   variables: {
 *      resourceID: // value for 'resourceID'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useGenerateTestPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateTestPasswordMutation, GenerateTestPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateTestPasswordMutation, GenerateTestPasswordMutationVariables>(
    GenerateTestPasswordDocument,
    options,
  );
}
export type GenerateTestPasswordMutationHookResult = ReturnType<typeof useGenerateTestPasswordMutation>;
export type GenerateTestPasswordMutationResult = Apollo.MutationResult<GenerateTestPasswordMutation>;
export type GenerateTestPasswordMutationOptions = Apollo.BaseMutationOptions<
  GenerateTestPasswordMutation,
  GenerateTestPasswordMutationVariables
>;
export const PaginatedRateAdjustmentSchedulingsDocument = gql`
  query PaginatedRateAdjustmentSchedulings($page: Int, $filters: RateAdjustment__Scheduling__FiltersInput) {
    paginated: paginatedRateAdjustmentSchedulings(page: $page, filters: $filters) {
      results {
        ...RateAdjustment__scheduling
      }
      pagination {
        ...pagination
      }
    }
  }
  ${RateAdjustment__SchedulingFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedRateAdjustmentSchedulingsQuery__
 *
 * To run a query within a React component, call `usePaginatedRateAdjustmentSchedulingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedRateAdjustmentSchedulingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedRateAdjustmentSchedulingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedRateAdjustmentSchedulingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedRateAdjustmentSchedulingsQuery,
    PaginatedRateAdjustmentSchedulingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedRateAdjustmentSchedulingsQuery, PaginatedRateAdjustmentSchedulingsQueryVariables>(
    PaginatedRateAdjustmentSchedulingsDocument,
    options,
  );
}
export function usePaginatedRateAdjustmentSchedulingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedRateAdjustmentSchedulingsQuery,
    PaginatedRateAdjustmentSchedulingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedRateAdjustmentSchedulingsQuery, PaginatedRateAdjustmentSchedulingsQueryVariables>(
    PaginatedRateAdjustmentSchedulingsDocument,
    options,
  );
}
export type PaginatedRateAdjustmentSchedulingsQueryHookResult = ReturnType<
  typeof usePaginatedRateAdjustmentSchedulingsQuery
>;
export type PaginatedRateAdjustmentSchedulingsLazyQueryHookResult = ReturnType<
  typeof usePaginatedRateAdjustmentSchedulingsLazyQuery
>;
export type PaginatedRateAdjustmentSchedulingsQueryResult = Apollo.QueryResult<
  PaginatedRateAdjustmentSchedulingsQuery,
  PaginatedRateAdjustmentSchedulingsQueryVariables
>;
export const ConfirmRateAdjustmentUploadDocument = gql`
  mutation ConfirmRateAdjustmentUpload($id: ID!) {
    confirmRateAdjustmentUpload(id: $id) {
      status
    }
  }
`;
export type ConfirmRateAdjustmentUploadMutationFn = Apollo.MutationFunction<
  ConfirmRateAdjustmentUploadMutation,
  ConfirmRateAdjustmentUploadMutationVariables
>;

/**
 * __useConfirmRateAdjustmentUploadMutation__
 *
 * To run a mutation, you first call `useConfirmRateAdjustmentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRateAdjustmentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRateAdjustmentUploadMutation, { data, loading, error }] = useConfirmRateAdjustmentUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmRateAdjustmentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmRateAdjustmentUploadMutation,
    ConfirmRateAdjustmentUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmRateAdjustmentUploadMutation, ConfirmRateAdjustmentUploadMutationVariables>(
    ConfirmRateAdjustmentUploadDocument,
    options,
  );
}
export type ConfirmRateAdjustmentUploadMutationHookResult = ReturnType<typeof useConfirmRateAdjustmentUploadMutation>;
export type ConfirmRateAdjustmentUploadMutationResult = Apollo.MutationResult<ConfirmRateAdjustmentUploadMutation>;
export type ConfirmRateAdjustmentUploadMutationOptions = Apollo.BaseMutationOptions<
  ConfirmRateAdjustmentUploadMutation,
  ConfirmRateAdjustmentUploadMutationVariables
>;
export const PreviewRateAdjustmentUploadDocument = gql`
  mutation PreviewRateAdjustmentUpload($input: RateAdjustment__UploadInput!) {
    previewRateAdjustmentUpload(input: $input) {
      status
      error
      upload {
        id
      }
      drafts {
        ...RateAdjustment__draft
      }
    }
  }
  ${RateAdjustment__DraftFragmentDoc}
`;
export type PreviewRateAdjustmentUploadMutationFn = Apollo.MutationFunction<
  PreviewRateAdjustmentUploadMutation,
  PreviewRateAdjustmentUploadMutationVariables
>;

/**
 * __usePreviewRateAdjustmentUploadMutation__
 *
 * To run a mutation, you first call `usePreviewRateAdjustmentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewRateAdjustmentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewRateAdjustmentUploadMutation, { data, loading, error }] = usePreviewRateAdjustmentUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewRateAdjustmentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewRateAdjustmentUploadMutation,
    PreviewRateAdjustmentUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PreviewRateAdjustmentUploadMutation, PreviewRateAdjustmentUploadMutationVariables>(
    PreviewRateAdjustmentUploadDocument,
    options,
  );
}
export type PreviewRateAdjustmentUploadMutationHookResult = ReturnType<typeof usePreviewRateAdjustmentUploadMutation>;
export type PreviewRateAdjustmentUploadMutationResult = Apollo.MutationResult<PreviewRateAdjustmentUploadMutation>;
export type PreviewRateAdjustmentUploadMutationOptions = Apollo.BaseMutationOptions<
  PreviewRateAdjustmentUploadMutation,
  PreviewRateAdjustmentUploadMutationVariables
>;
export const PricingSetRateGroupsDocument = gql`
  query PricingSetRateGroups($pricingSetId: ID!) {
    rateGroups: pricingSetRateGroups(pricingSetId: $pricingSetId) {
      ...rateGroup
    }
  }
  ${RateGroupFragmentDoc}
`;

/**
 * __usePricingSetRateGroupsQuery__
 *
 * To run a query within a React component, call `usePricingSetRateGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingSetRateGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingSetRateGroupsQuery({
 *   variables: {
 *      pricingSetId: // value for 'pricingSetId'
 *   },
 * });
 */
export function usePricingSetRateGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<PricingSetRateGroupsQuery, PricingSetRateGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingSetRateGroupsQuery, PricingSetRateGroupsQueryVariables>(
    PricingSetRateGroupsDocument,
    options,
  );
}
export function usePricingSetRateGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingSetRateGroupsQuery, PricingSetRateGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingSetRateGroupsQuery, PricingSetRateGroupsQueryVariables>(
    PricingSetRateGroupsDocument,
    options,
  );
}
export type PricingSetRateGroupsQueryHookResult = ReturnType<typeof usePricingSetRateGroupsQuery>;
export type PricingSetRateGroupsLazyQueryHookResult = ReturnType<typeof usePricingSetRateGroupsLazyQuery>;
export type PricingSetRateGroupsQueryResult = Apollo.QueryResult<
  PricingSetRateGroupsQuery,
  PricingSetRateGroupsQueryVariables
>;
export const StorageTermsDocument = gql`
  query StorageTerms {
    storageTerms: storageTerms {
      ...storageTerm
    }
  }
  ${StorageTermFragmentDoc}
`;

/**
 * __useStorageTermsQuery__
 *
 * To run a query within a React component, call `useStorageTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageTermsQuery(
  baseOptions?: Apollo.QueryHookOptions<StorageTermsQuery, StorageTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorageTermsQuery, StorageTermsQueryVariables>(StorageTermsDocument, options);
}
export function useStorageTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StorageTermsQuery, StorageTermsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorageTermsQuery, StorageTermsQueryVariables>(StorageTermsDocument, options);
}
export type StorageTermsQueryHookResult = ReturnType<typeof useStorageTermsQuery>;
export type StorageTermsLazyQueryHookResult = ReturnType<typeof useStorageTermsLazyQuery>;
export type StorageTermsQueryResult = Apollo.QueryResult<StorageTermsQuery, StorageTermsQueryVariables>;
export const PaginatedReferableAccountsDocument = gql`
  query PaginatedReferableAccounts($query: String!, $referable: Boolean!) {
    paginated: paginatedAccounts(query: $query, referable: $referable) {
      results {
        id
        number
        customer {
          id
          name
          email
          phone
        }
        referable
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedReferableAccountsQuery__
 *
 * To run a query within a React component, call `usePaginatedReferableAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedReferableAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedReferableAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      referable: // value for 'referable'
 *   },
 * });
 */
export function usePaginatedReferableAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedReferableAccountsQuery, PaginatedReferableAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedReferableAccountsQuery, PaginatedReferableAccountsQueryVariables>(
    PaginatedReferableAccountsDocument,
    options,
  );
}
export function usePaginatedReferableAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedReferableAccountsQuery, PaginatedReferableAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedReferableAccountsQuery, PaginatedReferableAccountsQueryVariables>(
    PaginatedReferableAccountsDocument,
    options,
  );
}
export type PaginatedReferableAccountsQueryHookResult = ReturnType<typeof usePaginatedReferableAccountsQuery>;
export type PaginatedReferableAccountsLazyQueryHookResult = ReturnType<typeof usePaginatedReferableAccountsLazyQuery>;
export type PaginatedReferableAccountsQueryResult = Apollo.QueryResult<
  PaginatedReferableAccountsQuery,
  PaginatedReferableAccountsQueryVariables
>;
export const RegionCreateDocument = gql`
  mutation RegionCreate($input: RegionCreate__Input!) {
    result: regionCreate(input: $input) {
      status
      error
      region {
        id
      }
    }
  }
`;
export type RegionCreateMutationFn = Apollo.MutationFunction<RegionCreateMutation, RegionCreateMutationVariables>;

/**
 * __useRegionCreateMutation__
 *
 * To run a mutation, you first call `useRegionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionCreateMutation, { data, loading, error }] = useRegionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<RegionCreateMutation, RegionCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegionCreateMutation, RegionCreateMutationVariables>(RegionCreateDocument, options);
}
export type RegionCreateMutationHookResult = ReturnType<typeof useRegionCreateMutation>;
export type RegionCreateMutationResult = Apollo.MutationResult<RegionCreateMutation>;
export type RegionCreateMutationOptions = Apollo.BaseMutationOptions<
  RegionCreateMutation,
  RegionCreateMutationVariables
>;
export const RegionsDocument = gql`
  query Regions {
    regions(includeInactive: true) {
      id
      active
      name
      tz
      parent {
        name
      }
    }
    warehouses {
      id
      name
    }
  }
`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options);
}
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options);
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>;
export const RegionDocument = gql`
  query Region($regionID: ID!) {
    region(regionID: $regionID) {
      ...region
    }
    warehouses {
      id
      name
    }
  }
  ${RegionFragmentDoc}
`;

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      regionID: // value for 'regionID'
 *   },
 * });
 */
export function useRegionQuery(baseOptions: Apollo.QueryHookOptions<RegionQuery, RegionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
}
export function useRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionQuery, RegionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
}
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = Apollo.QueryResult<RegionQuery, RegionQueryVariables>;
export const ModifyRegionDocument = gql`
  mutation ModifyRegion($input: ModifyRegionInput!, $id: ID!) {
    modifyRegion(input: $input, id: $id) {
      error
      region {
        ...region
      }
    }
  }
  ${RegionFragmentDoc}
`;
export type ModifyRegionMutationFn = Apollo.MutationFunction<ModifyRegionMutation, ModifyRegionMutationVariables>;

/**
 * __useModifyRegionMutation__
 *
 * To run a mutation, you first call `useModifyRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRegionMutation, { data, loading, error }] = useModifyRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyRegionMutation, ModifyRegionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyRegionMutation, ModifyRegionMutationVariables>(ModifyRegionDocument, options);
}
export type ModifyRegionMutationHookResult = ReturnType<typeof useModifyRegionMutation>;
export type ModifyRegionMutationResult = Apollo.MutationResult<ModifyRegionMutation>;
export type ModifyRegionMutationOptions = Apollo.BaseMutationOptions<
  ModifyRegionMutation,
  ModifyRegionMutationVariables
>;
export const RegionEditDocument = gql`
  query RegionEdit($regionID: ID!) {
    region(regionID: $regionID) {
      id
      name
      zipCodes
      storageExtendedServiceAreaPostalCodes
      movingExtendedServiceAreaPostalCodes
    }
  }
`;

/**
 * __useRegionEditQuery__
 *
 * To run a query within a React component, call `useRegionEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionEditQuery({
 *   variables: {
 *      regionID: // value for 'regionID'
 *   },
 * });
 */
export function useRegionEditQuery(baseOptions: Apollo.QueryHookOptions<RegionEditQuery, RegionEditQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionEditQuery, RegionEditQueryVariables>(RegionEditDocument, options);
}
export function useRegionEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionEditQuery, RegionEditQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionEditQuery, RegionEditQueryVariables>(RegionEditDocument, options);
}
export type RegionEditQueryHookResult = ReturnType<typeof useRegionEditQuery>;
export type RegionEditLazyQueryHookResult = ReturnType<typeof useRegionEditLazyQuery>;
export type RegionEditQueryResult = Apollo.QueryResult<RegionEditQuery, RegionEditQueryVariables>;
export const RegionsSelectorDocument = gql`
  query RegionsSelector {
    regions {
      id
      name
      tz
    }
  }
`;

/**
 * __useRegionsSelectorQuery__
 *
 * To run a query within a React component, call `useRegionsSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsSelectorQuery(
  baseOptions?: Apollo.QueryHookOptions<RegionsSelectorQuery, RegionsSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionsSelectorQuery, RegionsSelectorQueryVariables>(RegionsSelectorDocument, options);
}
export function useRegionsSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionsSelectorQuery, RegionsSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionsSelectorQuery, RegionsSelectorQueryVariables>(RegionsSelectorDocument, options);
}
export type RegionsSelectorQueryHookResult = ReturnType<typeof useRegionsSelectorQuery>;
export type RegionsSelectorLazyQueryHookResult = ReturnType<typeof useRegionsSelectorLazyQuery>;
export type RegionsSelectorQueryResult = Apollo.QueryResult<RegionsSelectorQuery, RegionsSelectorQueryVariables>;
export const ModifySelfStorageDefaultSourceDocument = gql`
  mutation ModifySelfStorageDefaultSource(
    $subscriptionID: ID!
    $sourceID: ID
    $billing: SelfStorage__Rental__BillingEnum!
  ) {
    result: modifySelfStorageDefaultSource(subscriptionID: $subscriptionID, sourceID: $sourceID, billing: $billing) {
      subscription {
        id
        billing
        defaultSource {
          ...billing__source
        }
      }
      status
      error
    }
  }
  ${Billing__SourceFragmentDoc}
`;
export type ModifySelfStorageDefaultSourceMutationFn = Apollo.MutationFunction<
  ModifySelfStorageDefaultSourceMutation,
  ModifySelfStorageDefaultSourceMutationVariables
>;

/**
 * __useModifySelfStorageDefaultSourceMutation__
 *
 * To run a mutation, you first call `useModifySelfStorageDefaultSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySelfStorageDefaultSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySelfStorageDefaultSourceMutation, { data, loading, error }] = useModifySelfStorageDefaultSourceMutation({
 *   variables: {
 *      subscriptionID: // value for 'subscriptionID'
 *      sourceID: // value for 'sourceID'
 *      billing: // value for 'billing'
 *   },
 * });
 */
export function useModifySelfStorageDefaultSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifySelfStorageDefaultSourceMutation,
    ModifySelfStorageDefaultSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifySelfStorageDefaultSourceMutation, ModifySelfStorageDefaultSourceMutationVariables>(
    ModifySelfStorageDefaultSourceDocument,
    options,
  );
}
export type ModifySelfStorageDefaultSourceMutationHookResult = ReturnType<
  typeof useModifySelfStorageDefaultSourceMutation
>;
export type ModifySelfStorageDefaultSourceMutationResult =
  Apollo.MutationResult<ModifySelfStorageDefaultSourceMutation>;
export type ModifySelfStorageDefaultSourceMutationOptions = Apollo.BaseMutationOptions<
  ModifySelfStorageDefaultSourceMutation,
  ModifySelfStorageDefaultSourceMutationVariables
>;
export const SelfStorageSubscriptionDocument = gql`
  query SelfStorageSubscription($id: ID!) {
    subscription: selfStorageSubscription(id: $id) {
      ...SelfStorage__subscription
    }
  }
  ${SelfStorage__SubscriptionFragmentDoc}
`;

/**
 * __useSelfStorageSubscriptionQuery__
 *
 * To run a query within a React component, call `useSelfStorageSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageSubscriptionQuery, SelfStorageSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageSubscriptionQuery, SelfStorageSubscriptionQueryVariables>(
    SelfStorageSubscriptionDocument,
    options,
  );
}
export function useSelfStorageSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageSubscriptionQuery, SelfStorageSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageSubscriptionQuery, SelfStorageSubscriptionQueryVariables>(
    SelfStorageSubscriptionDocument,
    options,
  );
}
export type SelfStorageSubscriptionQueryHookResult = ReturnType<typeof useSelfStorageSubscriptionQuery>;
export type SelfStorageSubscriptionLazyQueryHookResult = ReturnType<typeof useSelfStorageSubscriptionLazyQuery>;
export type SelfStorageSubscriptionQueryResult = Apollo.QueryResult<
  SelfStorageSubscriptionQuery,
  SelfStorageSubscriptionQueryVariables
>;
export const SelfStorageRentalPolicyDocument = gql`
  query SelfStorageRentalPolicy {
    permissions: selfStorageRentalPolicy {
      modify
      new
    }
  }
`;

/**
 * __useSelfStorageRentalPolicyQuery__
 *
 * To run a query within a React component, call `useSelfStorageRentalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageRentalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageRentalPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfStorageRentalPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStorageRentalPolicyQuery, SelfStorageRentalPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageRentalPolicyQuery, SelfStorageRentalPolicyQueryVariables>(
    SelfStorageRentalPolicyDocument,
    options,
  );
}
export function useSelfStorageRentalPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageRentalPolicyQuery, SelfStorageRentalPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageRentalPolicyQuery, SelfStorageRentalPolicyQueryVariables>(
    SelfStorageRentalPolicyDocument,
    options,
  );
}
export type SelfStorageRentalPolicyQueryHookResult = ReturnType<typeof useSelfStorageRentalPolicyQuery>;
export type SelfStorageRentalPolicyLazyQueryHookResult = ReturnType<typeof useSelfStorageRentalPolicyLazyQuery>;
export type SelfStorageRentalPolicyQueryResult = Apollo.QueryResult<
  SelfStorageRentalPolicyQuery,
  SelfStorageRentalPolicyQueryVariables
>;
export const SelfStorageRentalDocument = gql`
  query SelfStorageRental($id: ID!) {
    rental: selfStorageRental(id: $id) {
      promotion {
        ...SelfStorage__promotion
      }
      subscriptionItems {
        ...SelfStorage__subscriptionItem
      }
      signatures {
        ...SelfStorage__signature
      }
      subscription {
        id
        account {
          id
        }
      }
      rateAdjustmentSchedulings {
        id
        effectiveAt
        newPrice
        selfStorageRental {
          id
          unit {
            id
            name
          }
        }
      }
    }
  }
  ${SelfStorage__PromotionFragmentDoc}
  ${SelfStorage__SubscriptionItemFragmentDoc}
  ${SelfStorage__SignatureFragmentDoc}
`;

/**
 * __useSelfStorageRentalQuery__
 *
 * To run a query within a React component, call `useSelfStorageRentalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageRentalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageRentalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageRentalQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageRentalQuery, SelfStorageRentalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageRentalQuery, SelfStorageRentalQueryVariables>(SelfStorageRentalDocument, options);
}
export function useSelfStorageRentalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageRentalQuery, SelfStorageRentalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageRentalQuery, SelfStorageRentalQueryVariables>(
    SelfStorageRentalDocument,
    options,
  );
}
export type SelfStorageRentalQueryHookResult = ReturnType<typeof useSelfStorageRentalQuery>;
export type SelfStorageRentalLazyQueryHookResult = ReturnType<typeof useSelfStorageRentalLazyQuery>;
export type SelfStorageRentalQueryResult = Apollo.QueryResult<SelfStorageRentalQuery, SelfStorageRentalQueryVariables>;
export const SelfStorageRentalsWithUnitsDocument = gql`
  query SelfStorageRentalsWithUnits($accountID: ID!) {
    rentals: selfStorageRentals(accountID: $accountID) {
      id
      name
    }
  }
`;

/**
 * __useSelfStorageRentalsWithUnitsQuery__
 *
 * To run a query within a React component, call `useSelfStorageRentalsWithUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageRentalsWithUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageRentalsWithUnitsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useSelfStorageRentalsWithUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageRentalsWithUnitsQuery, SelfStorageRentalsWithUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageRentalsWithUnitsQuery, SelfStorageRentalsWithUnitsQueryVariables>(
    SelfStorageRentalsWithUnitsDocument,
    options,
  );
}
export function useSelfStorageRentalsWithUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageRentalsWithUnitsQuery,
    SelfStorageRentalsWithUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageRentalsWithUnitsQuery, SelfStorageRentalsWithUnitsQueryVariables>(
    SelfStorageRentalsWithUnitsDocument,
    options,
  );
}
export type SelfStorageRentalsWithUnitsQueryHookResult = ReturnType<typeof useSelfStorageRentalsWithUnitsQuery>;
export type SelfStorageRentalsWithUnitsLazyQueryHookResult = ReturnType<typeof useSelfStorageRentalsWithUnitsLazyQuery>;
export type SelfStorageRentalsWithUnitsQueryResult = Apollo.QueryResult<
  SelfStorageRentalsWithUnitsQuery,
  SelfStorageRentalsWithUnitsQueryVariables
>;
export const SelfStorageAddressesDocument = gql`
  query SelfStorageAddresses {
    facilities: selfStorageFacilities {
      id
      name
      address {
        id
        street
        aptsuite
        city
        state
        zip
        latitude
        longitude
        schedulable
      }
    }
  }
`;

/**
 * __useSelfStorageAddressesQuery__
 *
 * To run a query within a React component, call `useSelfStorageAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfStorageAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStorageAddressesQuery, SelfStorageAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageAddressesQuery, SelfStorageAddressesQueryVariables>(
    SelfStorageAddressesDocument,
    options,
  );
}
export function useSelfStorageAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageAddressesQuery, SelfStorageAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageAddressesQuery, SelfStorageAddressesQueryVariables>(
    SelfStorageAddressesDocument,
    options,
  );
}
export type SelfStorageAddressesQueryHookResult = ReturnType<typeof useSelfStorageAddressesQuery>;
export type SelfStorageAddressesLazyQueryHookResult = ReturnType<typeof useSelfStorageAddressesLazyQuery>;
export type SelfStorageAddressesQueryResult = Apollo.QueryResult<
  SelfStorageAddressesQuery,
  SelfStorageAddressesQueryVariables
>;
export const ModifySelfStorageBillingDocument = gql`
  mutation ModifySelfStorageBilling($accountID: ID!, $inputs: [SelfStorage__BillingLineInput!]!) {
    result: modifySelfStorageBilling(accountID: $accountID, inputs: $inputs) {
      status
      error
    }
  }
`;
export type ModifySelfStorageBillingMutationFn = Apollo.MutationFunction<
  ModifySelfStorageBillingMutation,
  ModifySelfStorageBillingMutationVariables
>;

/**
 * __useModifySelfStorageBillingMutation__
 *
 * To run a mutation, you first call `useModifySelfStorageBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySelfStorageBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySelfStorageBillingMutation, { data, loading, error }] = useModifySelfStorageBillingMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useModifySelfStorageBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifySelfStorageBillingMutation, ModifySelfStorageBillingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifySelfStorageBillingMutation, ModifySelfStorageBillingMutationVariables>(
    ModifySelfStorageBillingDocument,
    options,
  );
}
export type ModifySelfStorageBillingMutationHookResult = ReturnType<typeof useModifySelfStorageBillingMutation>;
export type ModifySelfStorageBillingMutationResult = Apollo.MutationResult<ModifySelfStorageBillingMutation>;
export type ModifySelfStorageBillingMutationOptions = Apollo.BaseMutationOptions<
  ModifySelfStorageBillingMutation,
  ModifySelfStorageBillingMutationVariables
>;
export const AccountWithSelfStorageRentalsAndBillingDetailsDocument = gql`
  query AccountWithSelfStorageRentalsAndBillingDetails($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      customer {
        id
        name
      }
      rentals: currentSelfStorageRentals {
        ...SelfStorage__rentalWithBilling
      }
    }
  }
  ${SelfStorage__RentalWithBillingFragmentDoc}
`;

/**
 * __useAccountWithSelfStorageRentalsAndBillingDetailsQuery__
 *
 * To run a query within a React component, call `useAccountWithSelfStorageRentalsAndBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountWithSelfStorageRentalsAndBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountWithSelfStorageRentalsAndBillingDetailsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountWithSelfStorageRentalsAndBillingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountWithSelfStorageRentalsAndBillingDetailsQuery,
    AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountWithSelfStorageRentalsAndBillingDetailsQuery,
    AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables
  >(AccountWithSelfStorageRentalsAndBillingDetailsDocument, options);
}
export function useAccountWithSelfStorageRentalsAndBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountWithSelfStorageRentalsAndBillingDetailsQuery,
    AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountWithSelfStorageRentalsAndBillingDetailsQuery,
    AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables
  >(AccountWithSelfStorageRentalsAndBillingDetailsDocument, options);
}
export type AccountWithSelfStorageRentalsAndBillingDetailsQueryHookResult = ReturnType<
  typeof useAccountWithSelfStorageRentalsAndBillingDetailsQuery
>;
export type AccountWithSelfStorageRentalsAndBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useAccountWithSelfStorageRentalsAndBillingDetailsLazyQuery
>;
export type AccountWithSelfStorageRentalsAndBillingDetailsQueryResult = Apollo.QueryResult<
  AccountWithSelfStorageRentalsAndBillingDetailsQuery,
  AccountWithSelfStorageRentalsAndBillingDetailsQueryVariables
>;
export const PaginatedSelfStorageFacilitiesDocument = gql`
  query PaginatedSelfStorageFacilities($filters: SelfStorage__Facility__FiltersInput, $page: Int) {
    paginated: paginatedSelfStorageFacilities(filters: $filters, page: $page) {
      results {
        ...SelfStorage__facility
      }
      pagination {
        ...pagination
      }
    }
  }
  ${SelfStorage__FacilityFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedSelfStorageFacilitiesQuery__
 *
 * To run a query within a React component, call `usePaginatedSelfStorageFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSelfStorageFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSelfStorageFacilitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedSelfStorageFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedSelfStorageFacilitiesQuery,
    PaginatedSelfStorageFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedSelfStorageFacilitiesQuery, PaginatedSelfStorageFacilitiesQueryVariables>(
    PaginatedSelfStorageFacilitiesDocument,
    options,
  );
}
export function usePaginatedSelfStorageFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedSelfStorageFacilitiesQuery,
    PaginatedSelfStorageFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedSelfStorageFacilitiesQuery, PaginatedSelfStorageFacilitiesQueryVariables>(
    PaginatedSelfStorageFacilitiesDocument,
    options,
  );
}
export type PaginatedSelfStorageFacilitiesQueryHookResult = ReturnType<typeof usePaginatedSelfStorageFacilitiesQuery>;
export type PaginatedSelfStorageFacilitiesLazyQueryHookResult = ReturnType<
  typeof usePaginatedSelfStorageFacilitiesLazyQuery
>;
export type PaginatedSelfStorageFacilitiesQueryResult = Apollo.QueryResult<
  PaginatedSelfStorageFacilitiesQuery,
  PaginatedSelfStorageFacilitiesQueryVariables
>;
export const SelfStorageFacilitiesDocument = gql`
  query SelfStorageFacilities {
    facilities: selfStorageFacilities {
      id
      name
    }
  }
`;

/**
 * __useSelfStorageFacilitiesQuery__
 *
 * To run a query within a React component, call `useSelfStorageFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfStorageFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStorageFacilitiesQuery, SelfStorageFacilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageFacilitiesQuery, SelfStorageFacilitiesQueryVariables>(
    SelfStorageFacilitiesDocument,
    options,
  );
}
export function useSelfStorageFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageFacilitiesQuery, SelfStorageFacilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageFacilitiesQuery, SelfStorageFacilitiesQueryVariables>(
    SelfStorageFacilitiesDocument,
    options,
  );
}
export type SelfStorageFacilitiesQueryHookResult = ReturnType<typeof useSelfStorageFacilitiesQuery>;
export type SelfStorageFacilitiesLazyQueryHookResult = ReturnType<typeof useSelfStorageFacilitiesLazyQuery>;
export type SelfStorageFacilitiesQueryResult = Apollo.QueryResult<
  SelfStorageFacilitiesQuery,
  SelfStorageFacilitiesQueryVariables
>;
export const SelfStorageCurrentFacilityFeesDocument = gql`
  query SelfStorageCurrentFacilityFees($accountID: ID!) {
    account(accountID: $accountID) {
      id
      fees: currentSelfStorageFees {
        ...SelfStorage__fee
      }
    }
  }
  ${SelfStorage__FeeFragmentDoc}
`;

/**
 * __useSelfStorageCurrentFacilityFeesQuery__
 *
 * To run a query within a React component, call `useSelfStorageCurrentFacilityFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageCurrentFacilityFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageCurrentFacilityFeesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useSelfStorageCurrentFacilityFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelfStorageCurrentFacilityFeesQuery,
    SelfStorageCurrentFacilityFeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageCurrentFacilityFeesQuery, SelfStorageCurrentFacilityFeesQueryVariables>(
    SelfStorageCurrentFacilityFeesDocument,
    options,
  );
}
export function useSelfStorageCurrentFacilityFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageCurrentFacilityFeesQuery,
    SelfStorageCurrentFacilityFeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageCurrentFacilityFeesQuery, SelfStorageCurrentFacilityFeesQueryVariables>(
    SelfStorageCurrentFacilityFeesDocument,
    options,
  );
}
export type SelfStorageCurrentFacilityFeesQueryHookResult = ReturnType<typeof useSelfStorageCurrentFacilityFeesQuery>;
export type SelfStorageCurrentFacilityFeesLazyQueryHookResult = ReturnType<
  typeof useSelfStorageCurrentFacilityFeesLazyQuery
>;
export type SelfStorageCurrentFacilityFeesQueryResult = Apollo.QueryResult<
  SelfStorageCurrentFacilityFeesQuery,
  SelfStorageCurrentFacilityFeesQueryVariables
>;
export const SelfStorageFeesDocument = gql`
  query SelfStorageFees($facilityID: ID!) {
    fees: selfStorageFees(facilityID: $facilityID) {
      ...SelfStorage__fee
    }
  }
  ${SelfStorage__FeeFragmentDoc}
`;

/**
 * __useSelfStorageFeesQuery__
 *
 * To run a query within a React component, call `useSelfStorageFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageFeesQuery({
 *   variables: {
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useSelfStorageFeesQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageFeesQuery, SelfStorageFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageFeesQuery, SelfStorageFeesQueryVariables>(SelfStorageFeesDocument, options);
}
export function useSelfStorageFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageFeesQuery, SelfStorageFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageFeesQuery, SelfStorageFeesQueryVariables>(SelfStorageFeesDocument, options);
}
export type SelfStorageFeesQueryHookResult = ReturnType<typeof useSelfStorageFeesQuery>;
export type SelfStorageFeesLazyQueryHookResult = ReturnType<typeof useSelfStorageFeesLazyQuery>;
export type SelfStorageFeesQueryResult = Apollo.QueryResult<SelfStorageFeesQuery, SelfStorageFeesQueryVariables>;
export const ReservableSelfStorageUnitGroupsDocument = gql`
  query ReservableSelfStorageUnitGroups($facilityID: ID!) {
    groups: reservableSelfStorageUnitGroups(facilityID: $facilityID) {
      ...SelfStorage__unitGroup
    }
  }
  ${SelfStorage__UnitGroupFragmentDoc}
`;

/**
 * __useReservableSelfStorageUnitGroupsQuery__
 *
 * To run a query within a React component, call `useReservableSelfStorageUnitGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservableSelfStorageUnitGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservableSelfStorageUnitGroupsQuery({
 *   variables: {
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useReservableSelfStorageUnitGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservableSelfStorageUnitGroupsQuery,
    ReservableSelfStorageUnitGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservableSelfStorageUnitGroupsQuery, ReservableSelfStorageUnitGroupsQueryVariables>(
    ReservableSelfStorageUnitGroupsDocument,
    options,
  );
}
export function useReservableSelfStorageUnitGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservableSelfStorageUnitGroupsQuery,
    ReservableSelfStorageUnitGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservableSelfStorageUnitGroupsQuery, ReservableSelfStorageUnitGroupsQueryVariables>(
    ReservableSelfStorageUnitGroupsDocument,
    options,
  );
}
export type ReservableSelfStorageUnitGroupsQueryHookResult = ReturnType<typeof useReservableSelfStorageUnitGroupsQuery>;
export type ReservableSelfStorageUnitGroupsLazyQueryHookResult = ReturnType<
  typeof useReservableSelfStorageUnitGroupsLazyQuery
>;
export type ReservableSelfStorageUnitGroupsQueryResult = Apollo.QueryResult<
  ReservableSelfStorageUnitGroupsQuery,
  ReservableSelfStorageUnitGroupsQueryVariables
>;
export const SelfStoragePartnersDocument = gql`
  query SelfStoragePartners {
    partners: selfStoragePartners {
      id
      name
      company
    }
  }
`;

/**
 * __useSelfStoragePartnersQuery__
 *
 * To run a query within a React component, call `useSelfStoragePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStoragePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStoragePartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfStoragePartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStoragePartnersQuery, SelfStoragePartnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStoragePartnersQuery, SelfStoragePartnersQueryVariables>(
    SelfStoragePartnersDocument,
    options,
  );
}
export function useSelfStoragePartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStoragePartnersQuery, SelfStoragePartnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStoragePartnersQuery, SelfStoragePartnersQueryVariables>(
    SelfStoragePartnersDocument,
    options,
  );
}
export type SelfStoragePartnersQueryHookResult = ReturnType<typeof useSelfStoragePartnersQuery>;
export type SelfStoragePartnersLazyQueryHookResult = ReturnType<typeof useSelfStoragePartnersLazyQuery>;
export type SelfStoragePartnersQueryResult = Apollo.QueryResult<
  SelfStoragePartnersQuery,
  SelfStoragePartnersQueryVariables
>;
export const SelfStorageProrationDocument = gql`
  query SelfStorageProration($accountID: ID!, $facilityID: ID!) {
    proration: selfStorageProration(accountID: $accountID, facilityID: $facilityID) {
      ratio
      periodEnd
    }
  }
`;

/**
 * __useSelfStorageProrationQuery__
 *
 * To run a query within a React component, call `useSelfStorageProrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageProrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageProrationQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useSelfStorageProrationQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageProrationQuery, SelfStorageProrationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageProrationQuery, SelfStorageProrationQueryVariables>(
    SelfStorageProrationDocument,
    options,
  );
}
export function useSelfStorageProrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageProrationQuery, SelfStorageProrationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageProrationQuery, SelfStorageProrationQueryVariables>(
    SelfStorageProrationDocument,
    options,
  );
}
export type SelfStorageProrationQueryHookResult = ReturnType<typeof useSelfStorageProrationQuery>;
export type SelfStorageProrationLazyQueryHookResult = ReturnType<typeof useSelfStorageProrationLazyQuery>;
export type SelfStorageProrationQueryResult = Apollo.QueryResult<
  SelfStorageProrationQuery,
  SelfStorageProrationQueryVariables
>;
export const SelfStorageProtectionsDocument = gql`
  query SelfStorageProtections($facilityID: ID!) {
    protections: selfStorageProtections(facilityID: $facilityID) {
      id
      name
      price
    }
  }
`;

/**
 * __useSelfStorageProtectionsQuery__
 *
 * To run a query within a React component, call `useSelfStorageProtectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageProtectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageProtectionsQuery({
 *   variables: {
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useSelfStorageProtectionsQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageProtectionsQuery, SelfStorageProtectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageProtectionsQuery, SelfStorageProtectionsQueryVariables>(
    SelfStorageProtectionsDocument,
    options,
  );
}
export function useSelfStorageProtectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageProtectionsQuery, SelfStorageProtectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageProtectionsQuery, SelfStorageProtectionsQueryVariables>(
    SelfStorageProtectionsDocument,
    options,
  );
}
export type SelfStorageProtectionsQueryHookResult = ReturnType<typeof useSelfStorageProtectionsQuery>;
export type SelfStorageProtectionsLazyQueryHookResult = ReturnType<typeof useSelfStorageProtectionsLazyQuery>;
export type SelfStorageProtectionsQueryResult = Apollo.QueryResult<
  SelfStorageProtectionsQuery,
  SelfStorageProtectionsQueryVariables
>;
export const CancelSelfStorageRentalDocument = gql`
  mutation CancelSelfStorageRental($id: ID!, $prorate: Boolean!, $skipDelinquentVerification: Boolean) {
    result: cancelSelfStorageRental(
      id: $id
      prorate: $prorate
      skipDelinquentVerification: $skipDelinquentVerification
    ) {
      status
      error
    }
  }
`;
export type CancelSelfStorageRentalMutationFn = Apollo.MutationFunction<
  CancelSelfStorageRentalMutation,
  CancelSelfStorageRentalMutationVariables
>;

/**
 * __useCancelSelfStorageRentalMutation__
 *
 * To run a mutation, you first call `useCancelSelfStorageRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSelfStorageRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSelfStorageRentalMutation, { data, loading, error }] = useCancelSelfStorageRentalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prorate: // value for 'prorate'
 *      skipDelinquentVerification: // value for 'skipDelinquentVerification'
 *   },
 * });
 */
export function useCancelSelfStorageRentalMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelSelfStorageRentalMutation, CancelSelfStorageRentalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelSelfStorageRentalMutation, CancelSelfStorageRentalMutationVariables>(
    CancelSelfStorageRentalDocument,
    options,
  );
}
export type CancelSelfStorageRentalMutationHookResult = ReturnType<typeof useCancelSelfStorageRentalMutation>;
export type CancelSelfStorageRentalMutationResult = Apollo.MutationResult<CancelSelfStorageRentalMutation>;
export type CancelSelfStorageRentalMutationOptions = Apollo.BaseMutationOptions<
  CancelSelfStorageRentalMutation,
  CancelSelfStorageRentalMutationVariables
>;
export const AccountWithSelfStorageRentalsDocument = gql`
  query AccountWithSelfStorageRentals($accountID: ID!) {
    account(accountID: $accountID) {
      id
      number
      delinquent
      balance
      customer {
        id
        name
      }
      rentals: currentSelfStorageRentals {
        ...SelfStorage__rental
      }
    }
  }
  ${SelfStorage__RentalFragmentDoc}
`;

/**
 * __useAccountWithSelfStorageRentalsQuery__
 *
 * To run a query within a React component, call `useAccountWithSelfStorageRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountWithSelfStorageRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountWithSelfStorageRentalsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountWithSelfStorageRentalsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountWithSelfStorageRentalsQuery, AccountWithSelfStorageRentalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountWithSelfStorageRentalsQuery, AccountWithSelfStorageRentalsQueryVariables>(
    AccountWithSelfStorageRentalsDocument,
    options,
  );
}
export function useAccountWithSelfStorageRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountWithSelfStorageRentalsQuery,
    AccountWithSelfStorageRentalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountWithSelfStorageRentalsQuery, AccountWithSelfStorageRentalsQueryVariables>(
    AccountWithSelfStorageRentalsDocument,
    options,
  );
}
export type AccountWithSelfStorageRentalsQueryHookResult = ReturnType<typeof useAccountWithSelfStorageRentalsQuery>;
export type AccountWithSelfStorageRentalsLazyQueryHookResult = ReturnType<
  typeof useAccountWithSelfStorageRentalsLazyQuery
>;
export type AccountWithSelfStorageRentalsQueryResult = Apollo.QueryResult<
  AccountWithSelfStorageRentalsQuery,
  AccountWithSelfStorageRentalsQueryVariables
>;
export const SelfStorageAccountForRentingDocument = gql`
  query SelfStorageAccountForRenting($accountID: ID!) {
    account(accountID: $accountID) {
      ...SelfStorage__AccountForRenting
    }
  }
  ${SelfStorage__AccountForRentingFragmentDoc}
`;

/**
 * __useSelfStorageAccountForRentingQuery__
 *
 * To run a query within a React component, call `useSelfStorageAccountForRentingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageAccountForRentingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageAccountForRentingQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useSelfStorageAccountForRentingQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageAccountForRentingQuery, SelfStorageAccountForRentingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageAccountForRentingQuery, SelfStorageAccountForRentingQueryVariables>(
    SelfStorageAccountForRentingDocument,
    options,
  );
}
export function useSelfStorageAccountForRentingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageAccountForRentingQuery,
    SelfStorageAccountForRentingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageAccountForRentingQuery, SelfStorageAccountForRentingQueryVariables>(
    SelfStorageAccountForRentingDocument,
    options,
  );
}
export type SelfStorageAccountForRentingQueryHookResult = ReturnType<typeof useSelfStorageAccountForRentingQuery>;
export type SelfStorageAccountForRentingLazyQueryHookResult = ReturnType<
  typeof useSelfStorageAccountForRentingLazyQuery
>;
export type SelfStorageAccountForRentingQueryResult = Apollo.QueryResult<
  SelfStorageAccountForRentingQuery,
  SelfStorageAccountForRentingQueryVariables
>;
export const GenerateSelfStorageGateCodeDocument = gql`
  mutation GenerateSelfStorageGateCode($accountID: ID, $facilityID: ID!) {
    result: generateSelfStorageGateCode(accountID: $accountID, facilityID: $facilityID) {
      value
    }
  }
`;
export type GenerateSelfStorageGateCodeMutationFn = Apollo.MutationFunction<
  GenerateSelfStorageGateCodeMutation,
  GenerateSelfStorageGateCodeMutationVariables
>;

/**
 * __useGenerateSelfStorageGateCodeMutation__
 *
 * To run a mutation, you first call `useGenerateSelfStorageGateCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSelfStorageGateCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSelfStorageGateCodeMutation, { data, loading, error }] = useGenerateSelfStorageGateCodeMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useGenerateSelfStorageGateCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSelfStorageGateCodeMutation,
    GenerateSelfStorageGateCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSelfStorageGateCodeMutation, GenerateSelfStorageGateCodeMutationVariables>(
    GenerateSelfStorageGateCodeDocument,
    options,
  );
}
export type GenerateSelfStorageGateCodeMutationHookResult = ReturnType<typeof useGenerateSelfStorageGateCodeMutation>;
export type GenerateSelfStorageGateCodeMutationResult = Apollo.MutationResult<GenerateSelfStorageGateCodeMutation>;
export type GenerateSelfStorageGateCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateSelfStorageGateCodeMutation,
  GenerateSelfStorageGateCodeMutationVariables
>;
export const RentSelfStorageDocument = gql`
  mutation RentSelfStorage($input: SelfStorage__RentInput!) {
    rent: rentSelfStorage(input: $input) {
      account {
        id
      }
      status
      error
    }
  }
`;
export type RentSelfStorageMutationFn = Apollo.MutationFunction<
  RentSelfStorageMutation,
  RentSelfStorageMutationVariables
>;

/**
 * __useRentSelfStorageMutation__
 *
 * To run a mutation, you first call `useRentSelfStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRentSelfStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rentSelfStorageMutation, { data, loading, error }] = useRentSelfStorageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRentSelfStorageMutation(
  baseOptions?: Apollo.MutationHookOptions<RentSelfStorageMutation, RentSelfStorageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RentSelfStorageMutation, RentSelfStorageMutationVariables>(
    RentSelfStorageDocument,
    options,
  );
}
export type RentSelfStorageMutationHookResult = ReturnType<typeof useRentSelfStorageMutation>;
export type RentSelfStorageMutationResult = Apollo.MutationResult<RentSelfStorageMutation>;
export type RentSelfStorageMutationOptions = Apollo.BaseMutationOptions<
  RentSelfStorageMutation,
  RentSelfStorageMutationVariables
>;
export const OverlockSelfStorageRentalDocument = gql`
  mutation OverlockSelfStorageRental($id: ID!, $overlocked: Boolean!) {
    result: overlockSelfStorageRental(id: $id, overlocked: $overlocked) {
      rental {
        id
        overlocked
      }
    }
  }
`;
export type OverlockSelfStorageRentalMutationFn = Apollo.MutationFunction<
  OverlockSelfStorageRentalMutation,
  OverlockSelfStorageRentalMutationVariables
>;

/**
 * __useOverlockSelfStorageRentalMutation__
 *
 * To run a mutation, you first call `useOverlockSelfStorageRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverlockSelfStorageRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overlockSelfStorageRentalMutation, { data, loading, error }] = useOverlockSelfStorageRentalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      overlocked: // value for 'overlocked'
 *   },
 * });
 */
export function useOverlockSelfStorageRentalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverlockSelfStorageRentalMutation,
    OverlockSelfStorageRentalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OverlockSelfStorageRentalMutation, OverlockSelfStorageRentalMutationVariables>(
    OverlockSelfStorageRentalDocument,
    options,
  );
}
export type OverlockSelfStorageRentalMutationHookResult = ReturnType<typeof useOverlockSelfStorageRentalMutation>;
export type OverlockSelfStorageRentalMutationResult = Apollo.MutationResult<OverlockSelfStorageRentalMutation>;
export type OverlockSelfStorageRentalMutationOptions = Apollo.BaseMutationOptions<
  OverlockSelfStorageRentalMutation,
  OverlockSelfStorageRentalMutationVariables
>;
export const PaginatedSelfStorageRentalsDocument = gql`
  query PaginatedSelfStorageRentals($page: Int, $filters: SelfStorage__RentalFiltersInput) {
    paginated: paginatedSelfStorageRentals(page: $page, filters: $filters) {
      results {
        ...SelfStorage__rental
      }
      pagination {
        ...pagination
      }
    }
  }
  ${SelfStorage__RentalFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedSelfStorageRentalsQuery__
 *
 * To run a query within a React component, call `usePaginatedSelfStorageRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSelfStorageRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSelfStorageRentalsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaginatedSelfStorageRentalsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedSelfStorageRentalsQuery, PaginatedSelfStorageRentalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedSelfStorageRentalsQuery, PaginatedSelfStorageRentalsQueryVariables>(
    PaginatedSelfStorageRentalsDocument,
    options,
  );
}
export function usePaginatedSelfStorageRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedSelfStorageRentalsQuery,
    PaginatedSelfStorageRentalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedSelfStorageRentalsQuery, PaginatedSelfStorageRentalsQueryVariables>(
    PaginatedSelfStorageRentalsDocument,
    options,
  );
}
export type PaginatedSelfStorageRentalsQueryHookResult = ReturnType<typeof usePaginatedSelfStorageRentalsQuery>;
export type PaginatedSelfStorageRentalsLazyQueryHookResult = ReturnType<typeof usePaginatedSelfStorageRentalsLazyQuery>;
export type PaginatedSelfStorageRentalsQueryResult = Apollo.QueryResult<
  PaginatedSelfStorageRentalsQuery,
  PaginatedSelfStorageRentalsQueryVariables
>;
export const GenerateSelfStorageSigningDocument = gql`
  mutation GenerateSelfStorageSigning($channel: ID!, $input: SelfStorage__RentInput!) {
    signing: generateSelfStorageSigning(channel: $channel, input: $input) {
      url
      code
      signatures {
        id
        document {
          ...SelfStorage__document
        }
      }
    }
  }
  ${SelfStorage__DocumentFragmentDoc}
`;
export type GenerateSelfStorageSigningMutationFn = Apollo.MutationFunction<
  GenerateSelfStorageSigningMutation,
  GenerateSelfStorageSigningMutationVariables
>;

/**
 * __useGenerateSelfStorageSigningMutation__
 *
 * To run a mutation, you first call `useGenerateSelfStorageSigningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSelfStorageSigningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSelfStorageSigningMutation, { data, loading, error }] = useGenerateSelfStorageSigningMutation({
 *   variables: {
 *      channel: // value for 'channel'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSelfStorageSigningMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSelfStorageSigningMutation,
    GenerateSelfStorageSigningMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSelfStorageSigningMutation, GenerateSelfStorageSigningMutationVariables>(
    GenerateSelfStorageSigningDocument,
    options,
  );
}
export type GenerateSelfStorageSigningMutationHookResult = ReturnType<typeof useGenerateSelfStorageSigningMutation>;
export type GenerateSelfStorageSigningMutationResult = Apollo.MutationResult<GenerateSelfStorageSigningMutation>;
export type GenerateSelfStorageSigningMutationOptions = Apollo.BaseMutationOptions<
  GenerateSelfStorageSigningMutation,
  GenerateSelfStorageSigningMutationVariables
>;
export const BookSelfStorageReservationDocument = gql`
  mutation BookSelfStorageReservation($input: SelfStorage__Reservation__BuildInput!) {
    result: bookSelfStorageReservation(input: $input) {
      error
      status
      reservation {
        ...SelfStorage__reservation
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
`;
export type BookSelfStorageReservationMutationFn = Apollo.MutationFunction<
  BookSelfStorageReservationMutation,
  BookSelfStorageReservationMutationVariables
>;

/**
 * __useBookSelfStorageReservationMutation__
 *
 * To run a mutation, you first call `useBookSelfStorageReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSelfStorageReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSelfStorageReservationMutation, { data, loading, error }] = useBookSelfStorageReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookSelfStorageReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookSelfStorageReservationMutation,
    BookSelfStorageReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookSelfStorageReservationMutation, BookSelfStorageReservationMutationVariables>(
    BookSelfStorageReservationDocument,
    options,
  );
}
export type BookSelfStorageReservationMutationHookResult = ReturnType<typeof useBookSelfStorageReservationMutation>;
export type BookSelfStorageReservationMutationResult = Apollo.MutationResult<BookSelfStorageReservationMutation>;
export type BookSelfStorageReservationMutationOptions = Apollo.BaseMutationOptions<
  BookSelfStorageReservationMutation,
  BookSelfStorageReservationMutationVariables
>;
export const CancelSelfStorageReservationDocument = gql`
  mutation CancelSelfStorageReservation($id: ID!) {
    result: cancelSelfStorageReservation(id: $id) {
      reservation {
        ...SelfStorage__reservation
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
`;
export type CancelSelfStorageReservationMutationFn = Apollo.MutationFunction<
  CancelSelfStorageReservationMutation,
  CancelSelfStorageReservationMutationVariables
>;

/**
 * __useCancelSelfStorageReservationMutation__
 *
 * To run a mutation, you first call `useCancelSelfStorageReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSelfStorageReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSelfStorageReservationMutation, { data, loading, error }] = useCancelSelfStorageReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSelfStorageReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSelfStorageReservationMutation,
    CancelSelfStorageReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelSelfStorageReservationMutation, CancelSelfStorageReservationMutationVariables>(
    CancelSelfStorageReservationDocument,
    options,
  );
}
export type CancelSelfStorageReservationMutationHookResult = ReturnType<typeof useCancelSelfStorageReservationMutation>;
export type CancelSelfStorageReservationMutationResult = Apollo.MutationResult<CancelSelfStorageReservationMutation>;
export type CancelSelfStorageReservationMutationOptions = Apollo.BaseMutationOptions<
  CancelSelfStorageReservationMutation,
  CancelSelfStorageReservationMutationVariables
>;
export const ModifySelfStorageReservationDocument = gql`
  mutation ModifySelfStorageReservation($id: ID!, $input: SelfStorage__Reservation__ModifyInput!) {
    modify: modifySelfStorageReservation(id: $id, input: $input) {
      status
      error
    }
  }
`;
export type ModifySelfStorageReservationMutationFn = Apollo.MutationFunction<
  ModifySelfStorageReservationMutation,
  ModifySelfStorageReservationMutationVariables
>;

/**
 * __useModifySelfStorageReservationMutation__
 *
 * To run a mutation, you first call `useModifySelfStorageReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySelfStorageReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySelfStorageReservationMutation, { data, loading, error }] = useModifySelfStorageReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifySelfStorageReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifySelfStorageReservationMutation,
    ModifySelfStorageReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifySelfStorageReservationMutation, ModifySelfStorageReservationMutationVariables>(
    ModifySelfStorageReservationDocument,
    options,
  );
}
export type ModifySelfStorageReservationMutationHookResult = ReturnType<typeof useModifySelfStorageReservationMutation>;
export type ModifySelfStorageReservationMutationResult = Apollo.MutationResult<ModifySelfStorageReservationMutation>;
export type ModifySelfStorageReservationMutationOptions = Apollo.BaseMutationOptions<
  ModifySelfStorageReservationMutation,
  ModifySelfStorageReservationMutationVariables
>;
export const PaginatedSelfStorageReservationsDocument = gql`
  query PaginatedSelfStorageReservations($filters: SelfStorage__Reservation__FiltersInput, $page: Int) {
    paginated: paginatedSelfStorageReservations(filters: $filters, page: $page) {
      results {
        ...SelfStorage__reservation
      }
      pagination {
        ...pagination
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedSelfStorageReservationsQuery__
 *
 * To run a query within a React component, call `usePaginatedSelfStorageReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSelfStorageReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSelfStorageReservationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedSelfStorageReservationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedSelfStorageReservationsQuery,
    PaginatedSelfStorageReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedSelfStorageReservationsQuery, PaginatedSelfStorageReservationsQueryVariables>(
    PaginatedSelfStorageReservationsDocument,
    options,
  );
}
export function usePaginatedSelfStorageReservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedSelfStorageReservationsQuery,
    PaginatedSelfStorageReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedSelfStorageReservationsQuery, PaginatedSelfStorageReservationsQueryVariables>(
    PaginatedSelfStorageReservationsDocument,
    options,
  );
}
export type PaginatedSelfStorageReservationsQueryHookResult = ReturnType<
  typeof usePaginatedSelfStorageReservationsQuery
>;
export type PaginatedSelfStorageReservationsLazyQueryHookResult = ReturnType<
  typeof usePaginatedSelfStorageReservationsLazyQuery
>;
export type PaginatedSelfStorageReservationsQueryResult = Apollo.QueryResult<
  PaginatedSelfStorageReservationsQuery,
  PaginatedSelfStorageReservationsQueryVariables
>;
export const SelfStorageReservationPolicyDocument = gql`
  query SelfStorageReservationPolicy($reservationID: ID) {
    permissions: selfStorageReservationPolicy(reservationID: $reservationID) {
      modify
      new
    }
  }
`;

/**
 * __useSelfStorageReservationPolicyQuery__
 *
 * To run a query within a React component, call `useSelfStorageReservationPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageReservationPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageReservationPolicyQuery({
 *   variables: {
 *      reservationID: // value for 'reservationID'
 *   },
 * });
 */
export function useSelfStorageReservationPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStorageReservationPolicyQuery, SelfStorageReservationPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageReservationPolicyQuery, SelfStorageReservationPolicyQueryVariables>(
    SelfStorageReservationPolicyDocument,
    options,
  );
}
export function useSelfStorageReservationPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageReservationPolicyQuery,
    SelfStorageReservationPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageReservationPolicyQuery, SelfStorageReservationPolicyQueryVariables>(
    SelfStorageReservationPolicyDocument,
    options,
  );
}
export type SelfStorageReservationPolicyQueryHookResult = ReturnType<typeof useSelfStorageReservationPolicyQuery>;
export type SelfStorageReservationPolicyLazyQueryHookResult = ReturnType<
  typeof useSelfStorageReservationPolicyLazyQuery
>;
export type SelfStorageReservationPolicyQueryResult = Apollo.QueryResult<
  SelfStorageReservationPolicyQuery,
  SelfStorageReservationPolicyQueryVariables
>;
export const SelfStorageReservationDocument = gql`
  query SelfStorageReservation($id: ID!) {
    reservation: selfStorageReservation(id: $id) {
      ...SelfStorage__reservation
      account {
        id
      }
      shuttleBookingUrl {
        id
        url
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
`;

/**
 * __useSelfStorageReservationQuery__
 *
 * To run a query within a React component, call `useSelfStorageReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageReservationQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageReservationQuery, SelfStorageReservationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageReservationQuery, SelfStorageReservationQueryVariables>(
    SelfStorageReservationDocument,
    options,
  );
}
export function useSelfStorageReservationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageReservationQuery, SelfStorageReservationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageReservationQuery, SelfStorageReservationQueryVariables>(
    SelfStorageReservationDocument,
    options,
  );
}
export type SelfStorageReservationQueryHookResult = ReturnType<typeof useSelfStorageReservationQuery>;
export type SelfStorageReservationLazyQueryHookResult = ReturnType<typeof useSelfStorageReservationLazyQuery>;
export type SelfStorageReservationQueryResult = Apollo.QueryResult<
  SelfStorageReservationQuery,
  SelfStorageReservationQueryVariables
>;
export const SelfStorageCurrentUserTokenDocument = gql`
  query SelfStorageCurrentUserToken {
    user: currentUser {
      id
      token
    }
  }
`;

/**
 * __useSelfStorageCurrentUserTokenQuery__
 *
 * To run a query within a React component, call `useSelfStorageCurrentUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageCurrentUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageCurrentUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfStorageCurrentUserTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfStorageCurrentUserTokenQuery, SelfStorageCurrentUserTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageCurrentUserTokenQuery, SelfStorageCurrentUserTokenQueryVariables>(
    SelfStorageCurrentUserTokenDocument,
    options,
  );
}
export function useSelfStorageCurrentUserTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageCurrentUserTokenQuery,
    SelfStorageCurrentUserTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageCurrentUserTokenQuery, SelfStorageCurrentUserTokenQueryVariables>(
    SelfStorageCurrentUserTokenDocument,
    options,
  );
}
export type SelfStorageCurrentUserTokenQueryHookResult = ReturnType<typeof useSelfStorageCurrentUserTokenQuery>;
export type SelfStorageCurrentUserTokenLazyQueryHookResult = ReturnType<typeof useSelfStorageCurrentUserTokenLazyQuery>;
export type SelfStorageCurrentUserTokenQueryResult = Apollo.QueryResult<
  SelfStorageCurrentUserTokenQuery,
  SelfStorageCurrentUserTokenQueryVariables
>;
export const ModifySelfStorageThirdPartyReservationDocument = gql`
  mutation ModifySelfStorageThirdPartyReservation($id: ID!, $input: SelfStorage__Reservation__ThirdPartyModifyInput!) {
    modify: modifySelfStorageThirdPartyReservation(id: $id, input: $input) {
      status
      error
      reservation {
        ...SelfStorage__reservation
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
`;
export type ModifySelfStorageThirdPartyReservationMutationFn = Apollo.MutationFunction<
  ModifySelfStorageThirdPartyReservationMutation,
  ModifySelfStorageThirdPartyReservationMutationVariables
>;

/**
 * __useModifySelfStorageThirdPartyReservationMutation__
 *
 * To run a mutation, you first call `useModifySelfStorageThirdPartyReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySelfStorageThirdPartyReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySelfStorageThirdPartyReservationMutation, { data, loading, error }] = useModifySelfStorageThirdPartyReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifySelfStorageThirdPartyReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifySelfStorageThirdPartyReservationMutation,
    ModifySelfStorageThirdPartyReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModifySelfStorageThirdPartyReservationMutation,
    ModifySelfStorageThirdPartyReservationMutationVariables
  >(ModifySelfStorageThirdPartyReservationDocument, options);
}
export type ModifySelfStorageThirdPartyReservationMutationHookResult = ReturnType<
  typeof useModifySelfStorageThirdPartyReservationMutation
>;
export type ModifySelfStorageThirdPartyReservationMutationResult =
  Apollo.MutationResult<ModifySelfStorageThirdPartyReservationMutation>;
export type ModifySelfStorageThirdPartyReservationMutationOptions = Apollo.BaseMutationOptions<
  ModifySelfStorageThirdPartyReservationMutation,
  ModifySelfStorageThirdPartyReservationMutationVariables
>;
export const SelfStorageShuttleDocument = gql`
  query SelfStorageShuttle($id: ID!) {
    shuttle: selfStorageShuttle(id: $id) {
      id
      reservation {
        id
        facility {
          id
          name
          address {
            ...address
            map
          }
        }
      }
    }
  }
  ${AddressFragmentDoc}
`;

/**
 * __useSelfStorageShuttleQuery__
 *
 * To run a query within a React component, call `useSelfStorageShuttleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageShuttleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageShuttleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageShuttleQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageShuttleQuery, SelfStorageShuttleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageShuttleQuery, SelfStorageShuttleQueryVariables>(
    SelfStorageShuttleDocument,
    options,
  );
}
export function useSelfStorageShuttleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageShuttleQuery, SelfStorageShuttleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageShuttleQuery, SelfStorageShuttleQueryVariables>(
    SelfStorageShuttleDocument,
    options,
  );
}
export type SelfStorageShuttleQueryHookResult = ReturnType<typeof useSelfStorageShuttleQuery>;
export type SelfStorageShuttleLazyQueryHookResult = ReturnType<typeof useSelfStorageShuttleLazyQuery>;
export type SelfStorageShuttleQueryResult = Apollo.QueryResult<
  SelfStorageShuttleQuery,
  SelfStorageShuttleQueryVariables
>;
export const SelfStorageUpcomingReservationsDocument = gql`
  query SelfStorageUpcomingReservations($facilityID: ID!) {
    reservations: selfStorageUpcomingReservations(facilityID: $facilityID) {
      ...SelfStorage__reservation
      order {
        id
        state
      }
    }
  }
  ${SelfStorage__ReservationFragmentDoc}
`;

/**
 * __useSelfStorageUpcomingReservationsQuery__
 *
 * To run a query within a React component, call `useSelfStorageUpcomingReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageUpcomingReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageUpcomingReservationsQuery({
 *   variables: {
 *      facilityID: // value for 'facilityID'
 *   },
 * });
 */
export function useSelfStorageUpcomingReservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelfStorageUpcomingReservationsQuery,
    SelfStorageUpcomingReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageUpcomingReservationsQuery, SelfStorageUpcomingReservationsQueryVariables>(
    SelfStorageUpcomingReservationsDocument,
    options,
  );
}
export function useSelfStorageUpcomingReservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfStorageUpcomingReservationsQuery,
    SelfStorageUpcomingReservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageUpcomingReservationsQuery, SelfStorageUpcomingReservationsQueryVariables>(
    SelfStorageUpcomingReservationsDocument,
    options,
  );
}
export type SelfStorageUpcomingReservationsQueryHookResult = ReturnType<typeof useSelfStorageUpcomingReservationsQuery>;
export type SelfStorageUpcomingReservationsLazyQueryHookResult = ReturnType<
  typeof useSelfStorageUpcomingReservationsLazyQuery
>;
export type SelfStorageUpcomingReservationsQueryResult = Apollo.QueryResult<
  SelfStorageUpcomingReservationsQuery,
  SelfStorageUpcomingReservationsQueryVariables
>;
export const DestroySelfStoragePromotionBatchDocument = gql`
  mutation DestroySelfStoragePromotionBatch($input: SelfStorage__DestroyPromotionBatchInput!) {
    result: destroySelfStoragePromotionBatch(input: $input) {
      status
    }
  }
`;
export type DestroySelfStoragePromotionBatchMutationFn = Apollo.MutationFunction<
  DestroySelfStoragePromotionBatchMutation,
  DestroySelfStoragePromotionBatchMutationVariables
>;

/**
 * __useDestroySelfStoragePromotionBatchMutation__
 *
 * To run a mutation, you first call `useDestroySelfStoragePromotionBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySelfStoragePromotionBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySelfStoragePromotionBatchMutation, { data, loading, error }] = useDestroySelfStoragePromotionBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySelfStoragePromotionBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestroySelfStoragePromotionBatchMutation,
    DestroySelfStoragePromotionBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DestroySelfStoragePromotionBatchMutation,
    DestroySelfStoragePromotionBatchMutationVariables
  >(DestroySelfStoragePromotionBatchDocument, options);
}
export type DestroySelfStoragePromotionBatchMutationHookResult = ReturnType<
  typeof useDestroySelfStoragePromotionBatchMutation
>;
export type DestroySelfStoragePromotionBatchMutationResult =
  Apollo.MutationResult<DestroySelfStoragePromotionBatchMutation>;
export type DestroySelfStoragePromotionBatchMutationOptions = Apollo.BaseMutationOptions<
  DestroySelfStoragePromotionBatchMutation,
  DestroySelfStoragePromotionBatchMutationVariables
>;
export const SelfStorageUnitDocument = gql`
  query SelfStorageUnit($id: ID!) {
    unit: selfStorageUnit(id: $id) {
      ...SelfStorage__unit
    }
  }
  ${SelfStorage__UnitFragmentDoc}
`;

/**
 * __useSelfStorageUnitQuery__
 *
 * To run a query within a React component, call `useSelfStorageUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageUnitQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageUnitQuery, SelfStorageUnitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageUnitQuery, SelfStorageUnitQueryVariables>(SelfStorageUnitDocument, options);
}
export function useSelfStorageUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageUnitQuery, SelfStorageUnitQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageUnitQuery, SelfStorageUnitQueryVariables>(SelfStorageUnitDocument, options);
}
export type SelfStorageUnitQueryHookResult = ReturnType<typeof useSelfStorageUnitQuery>;
export type SelfStorageUnitLazyQueryHookResult = ReturnType<typeof useSelfStorageUnitLazyQuery>;
export type SelfStorageUnitQueryResult = Apollo.QueryResult<SelfStorageUnitQuery, SelfStorageUnitQueryVariables>;
export const ModifySelfStorageUnitDocument = gql`
  mutation ModifySelfStorageUnit($id: ID!, $input: SelfStorage__UnitInput!) {
    modify: modifySelfStorageUnit(id: $id, input: $input) {
      status
      error
    }
  }
`;
export type ModifySelfStorageUnitMutationFn = Apollo.MutationFunction<
  ModifySelfStorageUnitMutation,
  ModifySelfStorageUnitMutationVariables
>;

/**
 * __useModifySelfStorageUnitMutation__
 *
 * To run a mutation, you first call `useModifySelfStorageUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySelfStorageUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySelfStorageUnitMutation, { data, loading, error }] = useModifySelfStorageUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifySelfStorageUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifySelfStorageUnitMutation, ModifySelfStorageUnitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifySelfStorageUnitMutation, ModifySelfStorageUnitMutationVariables>(
    ModifySelfStorageUnitDocument,
    options,
  );
}
export type ModifySelfStorageUnitMutationHookResult = ReturnType<typeof useModifySelfStorageUnitMutation>;
export type ModifySelfStorageUnitMutationResult = Apollo.MutationResult<ModifySelfStorageUnitMutation>;
export type ModifySelfStorageUnitMutationOptions = Apollo.BaseMutationOptions<
  ModifySelfStorageUnitMutation,
  ModifySelfStorageUnitMutationVariables
>;
export const PaginatedSelfStorageUnitsDocument = gql`
  query PaginatedSelfStorageUnits($filters: SelfStorage__Unit__FiltersInput, $page: Int) {
    paginated: paginatedSelfStorageUnits(filters: $filters, page: $page) {
      results {
        ...SelfStorage__unit
      }
      pagination {
        ...pagination
      }
    }
  }
  ${SelfStorage__UnitFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedSelfStorageUnitsQuery__
 *
 * To run a query within a React component, call `usePaginatedSelfStorageUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedSelfStorageUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedSelfStorageUnitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedSelfStorageUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedSelfStorageUnitsQuery, PaginatedSelfStorageUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedSelfStorageUnitsQuery, PaginatedSelfStorageUnitsQueryVariables>(
    PaginatedSelfStorageUnitsDocument,
    options,
  );
}
export function usePaginatedSelfStorageUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedSelfStorageUnitsQuery, PaginatedSelfStorageUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedSelfStorageUnitsQuery, PaginatedSelfStorageUnitsQueryVariables>(
    PaginatedSelfStorageUnitsDocument,
    options,
  );
}
export type PaginatedSelfStorageUnitsQueryHookResult = ReturnType<typeof usePaginatedSelfStorageUnitsQuery>;
export type PaginatedSelfStorageUnitsLazyQueryHookResult = ReturnType<typeof usePaginatedSelfStorageUnitsLazyQuery>;
export type PaginatedSelfStorageUnitsQueryResult = Apollo.QueryResult<
  PaginatedSelfStorageUnitsQuery,
  PaginatedSelfStorageUnitsQueryVariables
>;
export const ReservableSelfStorageUnitsCountDocument = gql`
  query ReservableSelfStorageUnitsCount($ids: [SelfStorage__ClassificationFacilityInput!]!) {
    count: reservableSelfStorageUnitsCount(ids: $ids)
  }
`;

/**
 * __useReservableSelfStorageUnitsCountQuery__
 *
 * To run a query within a React component, call `useReservableSelfStorageUnitsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservableSelfStorageUnitsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservableSelfStorageUnitsCountQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReservableSelfStorageUnitsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservableSelfStorageUnitsCountQuery,
    ReservableSelfStorageUnitsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservableSelfStorageUnitsCountQuery, ReservableSelfStorageUnitsCountQueryVariables>(
    ReservableSelfStorageUnitsCountDocument,
    options,
  );
}
export function useReservableSelfStorageUnitsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservableSelfStorageUnitsCountQuery,
    ReservableSelfStorageUnitsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservableSelfStorageUnitsCountQuery, ReservableSelfStorageUnitsCountQueryVariables>(
    ReservableSelfStorageUnitsCountDocument,
    options,
  );
}
export type ReservableSelfStorageUnitsCountQueryHookResult = ReturnType<typeof useReservableSelfStorageUnitsCountQuery>;
export type ReservableSelfStorageUnitsCountLazyQueryHookResult = ReturnType<
  typeof useReservableSelfStorageUnitsCountLazyQuery
>;
export type ReservableSelfStorageUnitsCountQueryResult = Apollo.QueryResult<
  ReservableSelfStorageUnitsCountQuery,
  ReservableSelfStorageUnitsCountQueryVariables
>;
export const SaveSelfStoragePromotionBatchDocument = gql`
  mutation SaveSelfStoragePromotionBatch($input: SelfStorage__SavePromotionBatchInput!) {
    result: saveSelfStoragePromotionBatch(input: $input) {
      status
    }
  }
`;
export type SaveSelfStoragePromotionBatchMutationFn = Apollo.MutationFunction<
  SaveSelfStoragePromotionBatchMutation,
  SaveSelfStoragePromotionBatchMutationVariables
>;

/**
 * __useSaveSelfStoragePromotionBatchMutation__
 *
 * To run a mutation, you first call `useSaveSelfStoragePromotionBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSelfStoragePromotionBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSelfStoragePromotionBatchMutation, { data, loading, error }] = useSaveSelfStoragePromotionBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSelfStoragePromotionBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSelfStoragePromotionBatchMutation,
    SaveSelfStoragePromotionBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSelfStoragePromotionBatchMutation, SaveSelfStoragePromotionBatchMutationVariables>(
    SaveSelfStoragePromotionBatchDocument,
    options,
  );
}
export type SaveSelfStoragePromotionBatchMutationHookResult = ReturnType<
  typeof useSaveSelfStoragePromotionBatchMutation
>;
export type SaveSelfStoragePromotionBatchMutationResult = Apollo.MutationResult<SaveSelfStoragePromotionBatchMutation>;
export type SaveSelfStoragePromotionBatchMutationOptions = Apollo.BaseMutationOptions<
  SaveSelfStoragePromotionBatchMutation,
  SaveSelfStoragePromotionBatchMutationVariables
>;
export const SaveSelfStorageUploadDocument = gql`
  mutation SaveSelfStorageUpload($input: SelfStorage__UploadInput!) {
    result: saveSelfStorageUpload(input: $input) {
      status
      error
    }
  }
`;
export type SaveSelfStorageUploadMutationFn = Apollo.MutationFunction<
  SaveSelfStorageUploadMutation,
  SaveSelfStorageUploadMutationVariables
>;

/**
 * __useSaveSelfStorageUploadMutation__
 *
 * To run a mutation, you first call `useSaveSelfStorageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSelfStorageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSelfStorageUploadMutation, { data, loading, error }] = useSaveSelfStorageUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSelfStorageUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveSelfStorageUploadMutation, SaveSelfStorageUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSelfStorageUploadMutation, SaveSelfStorageUploadMutationVariables>(
    SaveSelfStorageUploadDocument,
    options,
  );
}
export type SaveSelfStorageUploadMutationHookResult = ReturnType<typeof useSaveSelfStorageUploadMutation>;
export type SaveSelfStorageUploadMutationResult = Apollo.MutationResult<SaveSelfStorageUploadMutation>;
export type SaveSelfStorageUploadMutationOptions = Apollo.BaseMutationOptions<
  SaveSelfStorageUploadMutation,
  SaveSelfStorageUploadMutationVariables
>;
export const SelfStorageUnitWithRentalsDocument = gql`
  query SelfStorageUnitWithRentals($id: ID!) {
    unit: selfStorageUnit(id: $id) {
      ...SelfStorage__unit
      rentals {
        ...SelfStorage__rental
      }
    }
  }
  ${SelfStorage__UnitFragmentDoc}
  ${SelfStorage__RentalFragmentDoc}
`;

/**
 * __useSelfStorageUnitWithRentalsQuery__
 *
 * To run a query within a React component, call `useSelfStorageUnitWithRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfStorageUnitWithRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfStorageUnitWithRentalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfStorageUnitWithRentalsQuery(
  baseOptions: Apollo.QueryHookOptions<SelfStorageUnitWithRentalsQuery, SelfStorageUnitWithRentalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfStorageUnitWithRentalsQuery, SelfStorageUnitWithRentalsQueryVariables>(
    SelfStorageUnitWithRentalsDocument,
    options,
  );
}
export function useSelfStorageUnitWithRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfStorageUnitWithRentalsQuery, SelfStorageUnitWithRentalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfStorageUnitWithRentalsQuery, SelfStorageUnitWithRentalsQueryVariables>(
    SelfStorageUnitWithRentalsDocument,
    options,
  );
}
export type SelfStorageUnitWithRentalsQueryHookResult = ReturnType<typeof useSelfStorageUnitWithRentalsQuery>;
export type SelfStorageUnitWithRentalsLazyQueryHookResult = ReturnType<typeof useSelfStorageUnitWithRentalsLazyQuery>;
export type SelfStorageUnitWithRentalsQueryResult = Apollo.QueryResult<
  SelfStorageUnitWithRentalsQuery,
  SelfStorageUnitWithRentalsQueryVariables
>;
export const SendGridStatusDocument = gql`
  query SendGridStatus($email: String!) {
    bounces: sendgridSuppressionBounces(email: $email) {
      created
      reason
    }
    blocks: sendgridSuppressionBlocks(email: $email) {
      created
      reason
    }
  }
`;

/**
 * __useSendGridStatusQuery__
 *
 * To run a query within a React component, call `useSendGridStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendGridStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendGridStatusQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendGridStatusQuery(
  baseOptions: Apollo.QueryHookOptions<SendGridStatusQuery, SendGridStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendGridStatusQuery, SendGridStatusQueryVariables>(SendGridStatusDocument, options);
}
export function useSendGridStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendGridStatusQuery, SendGridStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendGridStatusQuery, SendGridStatusQueryVariables>(SendGridStatusDocument, options);
}
export type SendGridStatusQueryHookResult = ReturnType<typeof useSendGridStatusQuery>;
export type SendGridStatusLazyQueryHookResult = ReturnType<typeof useSendGridStatusLazyQuery>;
export type SendGridStatusQueryResult = Apollo.QueryResult<SendGridStatusQuery, SendGridStatusQueryVariables>;
export const SendGridSuppressionResetDocument = gql`
  mutation SendGridSuppressionReset($email: String!) {
    result: sendgridSuppressionReset(email: $email) {
      error
    }
  }
`;
export type SendGridSuppressionResetMutationFn = Apollo.MutationFunction<
  SendGridSuppressionResetMutation,
  SendGridSuppressionResetMutationVariables
>;

/**
 * __useSendGridSuppressionResetMutation__
 *
 * To run a mutation, you first call `useSendGridSuppressionResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGridSuppressionResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGridSuppressionResetMutation, { data, loading, error }] = useSendGridSuppressionResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendGridSuppressionResetMutation(
  baseOptions?: Apollo.MutationHookOptions<SendGridSuppressionResetMutation, SendGridSuppressionResetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendGridSuppressionResetMutation, SendGridSuppressionResetMutationVariables>(
    SendGridSuppressionResetDocument,
    options,
  );
}
export type SendGridSuppressionResetMutationHookResult = ReturnType<typeof useSendGridSuppressionResetMutation>;
export type SendGridSuppressionResetMutationResult = Apollo.MutationResult<SendGridSuppressionResetMutation>;
export type SendGridSuppressionResetMutationOptions = Apollo.BaseMutationOptions<
  SendGridSuppressionResetMutation,
  SendGridSuppressionResetMutationVariables
>;
export const AttestationEventResolveDocument = gql`
  mutation AttestationEventResolve($eventId: ID!, $note: String, $shiftInput: Workforce__ShiftInput) {
    result: attestationEventResolve(eventId: $eventId, note: $note, shiftInput: $shiftInput) {
      error
      status
      attestationEvent {
        id
      }
    }
  }
`;
export type AttestationEventResolveMutationFn = Apollo.MutationFunction<
  AttestationEventResolveMutation,
  AttestationEventResolveMutationVariables
>;

/**
 * __useAttestationEventResolveMutation__
 *
 * To run a mutation, you first call `useAttestationEventResolveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttestationEventResolveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attestationEventResolveMutation, { data, loading, error }] = useAttestationEventResolveMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      note: // value for 'note'
 *      shiftInput: // value for 'shiftInput'
 *   },
 * });
 */
export function useAttestationEventResolveMutation(
  baseOptions?: Apollo.MutationHookOptions<AttestationEventResolveMutation, AttestationEventResolveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttestationEventResolveMutation, AttestationEventResolveMutationVariables>(
    AttestationEventResolveDocument,
    options,
  );
}
export type AttestationEventResolveMutationHookResult = ReturnType<typeof useAttestationEventResolveMutation>;
export type AttestationEventResolveMutationResult = Apollo.MutationResult<AttestationEventResolveMutation>;
export type AttestationEventResolveMutationOptions = Apollo.BaseMutationOptions<
  AttestationEventResolveMutation,
  AttestationEventResolveMutationVariables
>;
export const AttestationEventsPaginatedDocument = gql`
  query AttestationEventsPaginated($filters: AttestationEventFiltersInput, $page: Int) {
    paginated: attestationEventsPaginated(filters: $filters, page: $page) {
      results {
        ...attestationEvent
      }
      pagination {
        ...pagination
      }
    }
  }
  ${AttestationEventFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useAttestationEventsPaginatedQuery__
 *
 * To run a query within a React component, call `useAttestationEventsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttestationEventsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttestationEventsPaginatedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAttestationEventsPaginatedQuery(
  baseOptions?: Apollo.QueryHookOptions<AttestationEventsPaginatedQuery, AttestationEventsPaginatedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttestationEventsPaginatedQuery, AttestationEventsPaginatedQueryVariables>(
    AttestationEventsPaginatedDocument,
    options,
  );
}
export function useAttestationEventsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttestationEventsPaginatedQuery, AttestationEventsPaginatedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttestationEventsPaginatedQuery, AttestationEventsPaginatedQueryVariables>(
    AttestationEventsPaginatedDocument,
    options,
  );
}
export type AttestationEventsPaginatedQueryHookResult = ReturnType<typeof useAttestationEventsPaginatedQuery>;
export type AttestationEventsPaginatedLazyQueryHookResult = ReturnType<typeof useAttestationEventsPaginatedLazyQuery>;
export type AttestationEventsPaginatedQueryResult = Apollo.QueryResult<
  AttestationEventsPaginatedQuery,
  AttestationEventsPaginatedQueryVariables
>;
export const JobCodesDocument = gql`
  query JobCodes {
    jobCodes: workforceJobCodes {
      ...Workforce__jobCode
    }
  }
  ${Workforce__JobCodeFragmentDoc}
`;

/**
 * __useJobCodesQuery__
 *
 * To run a query within a React component, call `useJobCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobCodesQuery(baseOptions?: Apollo.QueryHookOptions<JobCodesQuery, JobCodesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobCodesQuery, JobCodesQueryVariables>(JobCodesDocument, options);
}
export function useJobCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobCodesQuery, JobCodesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobCodesQuery, JobCodesQueryVariables>(JobCodesDocument, options);
}
export type JobCodesQueryHookResult = ReturnType<typeof useJobCodesQuery>;
export type JobCodesLazyQueryHookResult = ReturnType<typeof useJobCodesLazyQuery>;
export type JobCodesQueryResult = Apollo.QueryResult<JobCodesQuery, JobCodesQueryVariables>;
export const ShiftCreateDocument = gql`
  mutation ShiftCreate($input: Workforce__ShiftInput!) {
    result: shiftCreate(input: $input) {
      error
      status
      shift {
        ...Workforce__shift
        shiftAssignment {
          ...Workforce__shiftAssignment
          facility {
            ...Workforce__facility
          }
        }
        workBreaks {
          ...Workforce__work_break
        }
        started {
          ...Workforce__clockin
        }
        ended {
          ...Workforce__clockin
        }
        approver {
          ...user
        }
        deleter {
          ...user
        }
        violations {
          message
          requiresAdjustment
        }
        activities {
          ...Workforce__Shift__activity
        }
      }
    }
  }
  ${Workforce__ShiftFragmentDoc}
  ${Workforce__ShiftAssignmentFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
  ${Workforce__Work_BreakFragmentDoc}
  ${Workforce__ClockinFragmentDoc}
  ${UserFragmentDoc}
  ${Workforce__Shift__ActivityFragmentDoc}
`;
export type ShiftCreateMutationFn = Apollo.MutationFunction<ShiftCreateMutation, ShiftCreateMutationVariables>;

/**
 * __useShiftCreateMutation__
 *
 * To run a mutation, you first call `useShiftCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftCreateMutation, { data, loading, error }] = useShiftCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<ShiftCreateMutation, ShiftCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShiftCreateMutation, ShiftCreateMutationVariables>(ShiftCreateDocument, options);
}
export type ShiftCreateMutationHookResult = ReturnType<typeof useShiftCreateMutation>;
export type ShiftCreateMutationResult = Apollo.MutationResult<ShiftCreateMutation>;
export type ShiftCreateMutationOptions = Apollo.BaseMutationOptions<ShiftCreateMutation, ShiftCreateMutationVariables>;
export const ShiftDeleteDocument = gql`
  mutation ShiftDelete($id: ID!) {
    result: shiftDelete(id: $id) {
      error
      status
      shift {
        ...Workforce__shift
        shiftAssignment {
          ...Workforce__shiftAssignment
          facility {
            ...Workforce__facility
          }
        }
        workBreaks {
          ...Workforce__work_break
        }
        started {
          ...Workforce__clockin
        }
        ended {
          ...Workforce__clockin
        }
        approver {
          ...user
        }
        deleter {
          ...user
        }
        violations {
          message
          requiresAdjustment
        }
        activities {
          ...Workforce__Shift__activity
        }
      }
    }
  }
  ${Workforce__ShiftFragmentDoc}
  ${Workforce__ShiftAssignmentFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
  ${Workforce__Work_BreakFragmentDoc}
  ${Workforce__ClockinFragmentDoc}
  ${UserFragmentDoc}
  ${Workforce__Shift__ActivityFragmentDoc}
`;
export type ShiftDeleteMutationFn = Apollo.MutationFunction<ShiftDeleteMutation, ShiftDeleteMutationVariables>;

/**
 * __useShiftDeleteMutation__
 *
 * To run a mutation, you first call `useShiftDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftDeleteMutation, { data, loading, error }] = useShiftDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShiftDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<ShiftDeleteMutation, ShiftDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShiftDeleteMutation, ShiftDeleteMutationVariables>(ShiftDeleteDocument, options);
}
export type ShiftDeleteMutationHookResult = ReturnType<typeof useShiftDeleteMutation>;
export type ShiftDeleteMutationResult = Apollo.MutationResult<ShiftDeleteMutation>;
export type ShiftDeleteMutationOptions = Apollo.BaseMutationOptions<ShiftDeleteMutation, ShiftDeleteMutationVariables>;
export const ShiftUpdateDocument = gql`
  mutation ShiftUpdate($input: Workforce__ShiftInput!) {
    result: shiftUpdate(input: $input) {
      error
      status
      shift {
        ...Workforce__shift
        shiftAssignment {
          ...Workforce__shiftAssignment
          facility {
            ...Workforce__facility
          }
        }
        workBreaks {
          ...Workforce__work_break
        }
        started {
          ...Workforce__clockin
        }
        ended {
          ...Workforce__clockin
        }
        approver {
          ...user
        }
        deleter {
          ...user
        }
        violations {
          message
          requiresAdjustment
        }
        activities {
          ...Workforce__Shift__activity
        }
      }
    }
  }
  ${Workforce__ShiftFragmentDoc}
  ${Workforce__ShiftAssignmentFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
  ${Workforce__Work_BreakFragmentDoc}
  ${Workforce__ClockinFragmentDoc}
  ${UserFragmentDoc}
  ${Workforce__Shift__ActivityFragmentDoc}
`;
export type ShiftUpdateMutationFn = Apollo.MutationFunction<ShiftUpdateMutation, ShiftUpdateMutationVariables>;

/**
 * __useShiftUpdateMutation__
 *
 * To run a mutation, you first call `useShiftUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftUpdateMutation, { data, loading, error }] = useShiftUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<ShiftUpdateMutation, ShiftUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShiftUpdateMutation, ShiftUpdateMutationVariables>(ShiftUpdateDocument, options);
}
export type ShiftUpdateMutationHookResult = ReturnType<typeof useShiftUpdateMutation>;
export type ShiftUpdateMutationResult = Apollo.MutationResult<ShiftUpdateMutation>;
export type ShiftUpdateMutationOptions = Apollo.BaseMutationOptions<ShiftUpdateMutation, ShiftUpdateMutationVariables>;
export const NewShiftOptionsDocument = gql`
  query NewShiftOptions {
    users: workforceReviewableUsers {
      ...user
      tz
    }
    facilities: workforceFacilities {
      ...Workforce__facility
    }
  }
  ${UserFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
`;

/**
 * __useNewShiftOptionsQuery__
 *
 * To run a query within a React component, call `useNewShiftOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewShiftOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewShiftOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewShiftOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<NewShiftOptionsQuery, NewShiftOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewShiftOptionsQuery, NewShiftOptionsQueryVariables>(NewShiftOptionsDocument, options);
}
export function useNewShiftOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewShiftOptionsQuery, NewShiftOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewShiftOptionsQuery, NewShiftOptionsQueryVariables>(NewShiftOptionsDocument, options);
}
export type NewShiftOptionsQueryHookResult = ReturnType<typeof useNewShiftOptionsQuery>;
export type NewShiftOptionsLazyQueryHookResult = ReturnType<typeof useNewShiftOptionsLazyQuery>;
export type NewShiftOptionsQueryResult = Apollo.QueryResult<NewShiftOptionsQuery, NewShiftOptionsQueryVariables>;
export const ShiftAssignmentDocument = gql`
  query ShiftAssignment($userID: ID!, $date: Date!) {
    shiftAssignment: workforceShiftAssignment(userID: $userID, date: $date) {
      ...Workforce__shiftAssignment
      facility {
        ...Workforce__facility
      }
    }
  }
  ${Workforce__ShiftAssignmentFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
`;

/**
 * __useShiftAssignmentQuery__
 *
 * To run a query within a React component, call `useShiftAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftAssignmentQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useShiftAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<ShiftAssignmentQuery, ShiftAssignmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShiftAssignmentQuery, ShiftAssignmentQueryVariables>(ShiftAssignmentDocument, options);
}
export function useShiftAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShiftAssignmentQuery, ShiftAssignmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShiftAssignmentQuery, ShiftAssignmentQueryVariables>(ShiftAssignmentDocument, options);
}
export type ShiftAssignmentQueryHookResult = ReturnType<typeof useShiftAssignmentQuery>;
export type ShiftAssignmentLazyQueryHookResult = ReturnType<typeof useShiftAssignmentLazyQuery>;
export type ShiftAssignmentQueryResult = Apollo.QueryResult<ShiftAssignmentQuery, ShiftAssignmentQueryVariables>;
export const ShiftDocument = gql`
  query Shift($id: ID!) {
    shift: workforceShift(id: $id) {
      ...Workforce__shift
      shiftAssignment {
        ...Workforce__shiftAssignment
        facility {
          ...Workforce__facility
        }
      }
      workBreaks {
        ...Workforce__work_break
      }
      started {
        ...Workforce__clockin
      }
      ended {
        ...Workforce__clockin
      }
      approver {
        ...user
      }
      deleter {
        ...user
      }
      violations {
        message
        requiresAdjustment
      }
      activities {
        ...Workforce__Shift__activity
      }
    }
  }
  ${Workforce__ShiftFragmentDoc}
  ${Workforce__ShiftAssignmentFragmentDoc}
  ${Workforce__FacilityFragmentDoc}
  ${Workforce__Work_BreakFragmentDoc}
  ${Workforce__ClockinFragmentDoc}
  ${UserFragmentDoc}
  ${Workforce__Shift__ActivityFragmentDoc}
`;

/**
 * __useShiftQuery__
 *
 * To run a query within a React component, call `useShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShiftQuery(baseOptions: Apollo.QueryHookOptions<ShiftQuery, ShiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShiftQuery, ShiftQueryVariables>(ShiftDocument, options);
}
export function useShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShiftQuery, ShiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShiftQuery, ShiftQueryVariables>(ShiftDocument, options);
}
export type ShiftQueryHookResult = ReturnType<typeof useShiftQuery>;
export type ShiftLazyQueryHookResult = ReturnType<typeof useShiftLazyQuery>;
export type ShiftQueryResult = Apollo.QueryResult<ShiftQuery, ShiftQueryVariables>;
export const StorageTreasuresAuctionCancelDocument = gql`
  mutation StorageTreasuresAuctionCancel($tealAuctionID: ID!, $reason: String!) {
    storageTreasuresAuctionCancel(tealAuctionID: $tealAuctionID, reason: $reason) {
      status
      error
    }
  }
`;
export type StorageTreasuresAuctionCancelMutationFn = Apollo.MutationFunction<
  StorageTreasuresAuctionCancelMutation,
  StorageTreasuresAuctionCancelMutationVariables
>;

/**
 * __useStorageTreasuresAuctionCancelMutation__
 *
 * To run a mutation, you first call `useStorageTreasuresAuctionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageTreasuresAuctionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageTreasuresAuctionCancelMutation, { data, loading, error }] = useStorageTreasuresAuctionCancelMutation({
 *   variables: {
 *      tealAuctionID: // value for 'tealAuctionID'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useStorageTreasuresAuctionCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StorageTreasuresAuctionCancelMutation,
    StorageTreasuresAuctionCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StorageTreasuresAuctionCancelMutation, StorageTreasuresAuctionCancelMutationVariables>(
    StorageTreasuresAuctionCancelDocument,
    options,
  );
}
export type StorageTreasuresAuctionCancelMutationHookResult = ReturnType<
  typeof useStorageTreasuresAuctionCancelMutation
>;
export type StorageTreasuresAuctionCancelMutationResult = Apollo.MutationResult<StorageTreasuresAuctionCancelMutation>;
export type StorageTreasuresAuctionCancelMutationOptions = Apollo.BaseMutationOptions<
  StorageTreasuresAuctionCancelMutation,
  StorageTreasuresAuctionCancelMutationVariables
>;
export const StorageTreasuresAuctionListDocument = gql`
  query StorageTreasuresAuctionList($page: Int) {
    paginated: storageTreasuresAuctionsPaginated(page: $page) {
      results {
        id
        auctionedAccount {
          id
          name
        }
        endTime
        startTime
        status
        storageTreasuresID
        storageTreasuresLink
        winner {
          id
          name
        }
        winnerInvoice {
          id
          clutterAmount
        }
        winnerOrder {
          id
          number
        }
      }
      pagination {
        ...pagination
      }
    }
    permission: storageTreasuresAuctionPolicy {
      cancel
      view
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useStorageTreasuresAuctionListQuery__
 *
 * To run a query within a React component, call `useStorageTreasuresAuctionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageTreasuresAuctionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageTreasuresAuctionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStorageTreasuresAuctionListQuery(
  baseOptions?: Apollo.QueryHookOptions<StorageTreasuresAuctionListQuery, StorageTreasuresAuctionListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorageTreasuresAuctionListQuery, StorageTreasuresAuctionListQueryVariables>(
    StorageTreasuresAuctionListDocument,
    options,
  );
}
export function useStorageTreasuresAuctionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StorageTreasuresAuctionListQuery,
    StorageTreasuresAuctionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorageTreasuresAuctionListQuery, StorageTreasuresAuctionListQueryVariables>(
    StorageTreasuresAuctionListDocument,
    options,
  );
}
export type StorageTreasuresAuctionListQueryHookResult = ReturnType<typeof useStorageTreasuresAuctionListQuery>;
export type StorageTreasuresAuctionListLazyQueryHookResult = ReturnType<typeof useStorageTreasuresAuctionListLazyQuery>;
export type StorageTreasuresAuctionListQueryResult = Apollo.QueryResult<
  StorageTreasuresAuctionListQuery,
  StorageTreasuresAuctionListQueryVariables
>;
export const PricingSetFetcherDocument = gql`
  query PricingSetFetcher($zip: String!, $accountID: ID, $source: String) {
    pricingSet(zip: $zip, accountID: $accountID, source: $source) {
      ...pricingSet
    }
  }
  ${PricingSetFragmentDoc}
`;

/**
 * __usePricingSetFetcherQuery__
 *
 * To run a query within a React component, call `usePricingSetFetcherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingSetFetcherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingSetFetcherQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *      accountID: // value for 'accountID'
 *      source: // value for 'source'
 *   },
 * });
 */
export function usePricingSetFetcherQuery(
  baseOptions: Apollo.QueryHookOptions<PricingSetFetcherQuery, PricingSetFetcherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingSetFetcherQuery, PricingSetFetcherQueryVariables>(PricingSetFetcherDocument, options);
}
export function usePricingSetFetcherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingSetFetcherQuery, PricingSetFetcherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingSetFetcherQuery, PricingSetFetcherQueryVariables>(
    PricingSetFetcherDocument,
    options,
  );
}
export type PricingSetFetcherQueryHookResult = ReturnType<typeof usePricingSetFetcherQuery>;
export type PricingSetFetcherLazyQueryHookResult = ReturnType<typeof usePricingSetFetcherLazyQuery>;
export type PricingSetFetcherQueryResult = Apollo.QueryResult<PricingSetFetcherQuery, PricingSetFetcherQueryVariables>;
export const PricingPolicyDocument = gql`
  query PricingPolicy {
    permissions: pricingPolicy {
      modify
    }
  }
`;

/**
 * __usePricingPolicyQuery__
 *
 * To run a query within a React component, call `usePricingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<PricingPolicyQuery, PricingPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingPolicyQuery, PricingPolicyQueryVariables>(PricingPolicyDocument, options);
}
export function usePricingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingPolicyQuery, PricingPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingPolicyQuery, PricingPolicyQueryVariables>(PricingPolicyDocument, options);
}
export type PricingPolicyQueryHookResult = ReturnType<typeof usePricingPolicyQuery>;
export type PricingPolicyLazyQueryHookResult = ReturnType<typeof usePricingPolicyLazyQuery>;
export type PricingPolicyQueryResult = Apollo.QueryResult<PricingPolicyQuery, PricingPolicyQueryVariables>;
export const AccountSubscriptionsDocument = gql`
  query AccountSubscriptions($accountID: ID!) {
    account(accountID: $accountID) {
      id
      subscriptions {
        ...subscription
      }
      currentPaymentPlanSubscriptions {
        ...Admin__PaymentPlan__Subscription
      }
    }
  }
  ${SubscriptionFragmentDoc}
  ${Admin__PaymentPlan__SubscriptionFragmentDoc}
`;

/**
 * __useAccountSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAccountSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionsQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useAccountSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(
    AccountSubscriptionsDocument,
    options,
  );
}
export function useAccountSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(
    AccountSubscriptionsDocument,
    options,
  );
}
export type AccountSubscriptionsQueryHookResult = ReturnType<typeof useAccountSubscriptionsQuery>;
export type AccountSubscriptionsLazyQueryHookResult = ReturnType<typeof useAccountSubscriptionsLazyQuery>;
export type AccountSubscriptionsQueryResult = Apollo.QueryResult<
  AccountSubscriptionsQuery,
  AccountSubscriptionsQueryVariables
>;
export const MarkMessagesReadDocument = gql`
  mutation MarkMessagesRead($ids: [ID!]!) {
    markMessagesRead(ids: $ids) {
      status
    }
  }
`;
export type MarkMessagesReadMutationFn = Apollo.MutationFunction<
  MarkMessagesReadMutation,
  MarkMessagesReadMutationVariables
>;

/**
 * __useMarkMessagesReadMutation__
 *
 * To run a mutation, you first call `useMarkMessagesReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessagesReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessagesReadMutation, { data, loading, error }] = useMarkMessagesReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkMessagesReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkMessagesReadMutation, MarkMessagesReadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkMessagesReadMutation, MarkMessagesReadMutationVariables>(
    MarkMessagesReadDocument,
    options,
  );
}
export type MarkMessagesReadMutationHookResult = ReturnType<typeof useMarkMessagesReadMutation>;
export type MarkMessagesReadMutationResult = Apollo.MutationResult<MarkMessagesReadMutation>;
export type MarkMessagesReadMutationOptions = Apollo.BaseMutationOptions<
  MarkMessagesReadMutation,
  MarkMessagesReadMutationVariables
>;
export const TicketMessagesDocument = gql`
  query TicketMessages($ticketID: ID!) {
    ticket(id: $ticketID) {
      messages {
        id
        body
        createdAt
        readAt
        user {
          ...user
        }
        ticket {
          assignee {
            ...user
          }
          owner {
            ...user
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useTicketMessagesQuery__
 *
 * To run a query within a React component, call `useTicketMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketMessagesQuery({
 *   variables: {
 *      ticketID: // value for 'ticketID'
 *   },
 * });
 */
export function useTicketMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<TicketMessagesQuery, TicketMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketMessagesQuery, TicketMessagesQueryVariables>(TicketMessagesDocument, options);
}
export function useTicketMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketMessagesQuery, TicketMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketMessagesQuery, TicketMessagesQueryVariables>(TicketMessagesDocument, options);
}
export type TicketMessagesQueryHookResult = ReturnType<typeof useTicketMessagesQuery>;
export type TicketMessagesLazyQueryHookResult = ReturnType<typeof useTicketMessagesLazyQuery>;
export type TicketMessagesQueryResult = Apollo.QueryResult<TicketMessagesQuery, TicketMessagesQueryVariables>;
export const BuildTicketDocument = gql`
  mutation BuildTicket($input: BuildTicketInput!) {
    buildTicket(input: $input) {
      error
      status
    }
  }
`;
export type BuildTicketMutationFn = Apollo.MutationFunction<BuildTicketMutation, BuildTicketMutationVariables>;

/**
 * __useBuildTicketMutation__
 *
 * To run a mutation, you first call `useBuildTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildTicketMutation, { data, loading, error }] = useBuildTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildTicketMutation, BuildTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildTicketMutation, BuildTicketMutationVariables>(BuildTicketDocument, options);
}
export type BuildTicketMutationHookResult = ReturnType<typeof useBuildTicketMutation>;
export type BuildTicketMutationResult = Apollo.MutationResult<BuildTicketMutation>;
export type BuildTicketMutationOptions = Apollo.BaseMutationOptions<BuildTicketMutation, BuildTicketMutationVariables>;
export const PaginatedTicketListDocument = gql`
  query PaginatedTicketList($filters: TicketFiltersInput, $page: Int, $numPer: Int) {
    paginatedTicketList(filters: $filters, page: $page, numPer: $numPer) {
      results {
        ...ticket
      }
      pagination {
        ...pagination
      }
    }
  }
  ${TicketFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __usePaginatedTicketListQuery__
 *
 * To run a query within a React component, call `usePaginatedTicketListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTicketListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTicketListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      numPer: // value for 'numPer'
 *   },
 * });
 */
export function usePaginatedTicketListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedTicketListQuery, PaginatedTicketListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedTicketListQuery, PaginatedTicketListQueryVariables>(
    PaginatedTicketListDocument,
    options,
  );
}
export function usePaginatedTicketListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedTicketListQuery, PaginatedTicketListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedTicketListQuery, PaginatedTicketListQueryVariables>(
    PaginatedTicketListDocument,
    options,
  );
}
export type PaginatedTicketListQueryHookResult = ReturnType<typeof usePaginatedTicketListQuery>;
export type PaginatedTicketListLazyQueryHookResult = ReturnType<typeof usePaginatedTicketListLazyQuery>;
export type PaginatedTicketListQueryResult = Apollo.QueryResult<
  PaginatedTicketListQuery,
  PaginatedTicketListQueryVariables
>;
export const InternalTicketGroupsDocument = gql`
  query InternalTicketGroups {
    internalTicketGroups {
      ...ticketGroup
    }
  }
  ${TicketGroupFragmentDoc}
`;

/**
 * __useInternalTicketGroupsQuery__
 *
 * To run a query within a React component, call `useInternalTicketGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalTicketGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalTicketGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalTicketGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<InternalTicketGroupsQuery, InternalTicketGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InternalTicketGroupsQuery, InternalTicketGroupsQueryVariables>(
    InternalTicketGroupsDocument,
    options,
  );
}
export function useInternalTicketGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InternalTicketGroupsQuery, InternalTicketGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InternalTicketGroupsQuery, InternalTicketGroupsQueryVariables>(
    InternalTicketGroupsDocument,
    options,
  );
}
export type InternalTicketGroupsQueryHookResult = ReturnType<typeof useInternalTicketGroupsQuery>;
export type InternalTicketGroupsLazyQueryHookResult = ReturnType<typeof useInternalTicketGroupsLazyQuery>;
export type InternalTicketGroupsQueryResult = Apollo.QueryResult<
  InternalTicketGroupsQuery,
  InternalTicketGroupsQueryVariables
>;
export const MessageCreateDocument = gql`
  mutation MessageCreate($input: Ticket__MessageInput!, $sendPushNotification: Boolean!) {
    messageCreate(input: $input, sendPushNotification: $sendPushNotification) {
      error
      status
      ticketMessage {
        ...ticketMessage
      }
    }
  }
  ${TicketMessageFragmentDoc}
`;
export type MessageCreateMutationFn = Apollo.MutationFunction<MessageCreateMutation, MessageCreateMutationVariables>;

/**
 * __useMessageCreateMutation__
 *
 * To run a mutation, you first call `useMessageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageCreateMutation, { data, loading, error }] = useMessageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendPushNotification: // value for 'sendPushNotification'
 *   },
 * });
 */
export function useMessageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<MessageCreateMutation, MessageCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MessageCreateMutation, MessageCreateMutationVariables>(MessageCreateDocument, options);
}
export type MessageCreateMutationHookResult = ReturnType<typeof useMessageCreateMutation>;
export type MessageCreateMutationResult = Apollo.MutationResult<MessageCreateMutation>;
export type MessageCreateMutationOptions = Apollo.BaseMutationOptions<
  MessageCreateMutation,
  MessageCreateMutationVariables
>;
export const MessageUpdateDocument = gql`
  mutation MessageUpdate($id: ID!, $input: Ticket__MessageInput!) {
    messageUpdate(id: $id, input: $input) {
      error
      status
      ticketMessage {
        ...ticketMessage
      }
    }
  }
  ${TicketMessageFragmentDoc}
`;
export type MessageUpdateMutationFn = Apollo.MutationFunction<MessageUpdateMutation, MessageUpdateMutationVariables>;

/**
 * __useMessageUpdateMutation__
 *
 * To run a mutation, you first call `useMessageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageUpdateMutation, { data, loading, error }] = useMessageUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MessageUpdateMutation, MessageUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MessageUpdateMutation, MessageUpdateMutationVariables>(MessageUpdateDocument, options);
}
export type MessageUpdateMutationHookResult = ReturnType<typeof useMessageUpdateMutation>;
export type MessageUpdateMutationResult = Apollo.MutationResult<MessageUpdateMutation>;
export type MessageUpdateMutationOptions = Apollo.BaseMutationOptions<
  MessageUpdateMutation,
  MessageUpdateMutationVariables
>;
export const TicketCategoriesDocument = gql`
  query TicketCategories {
    ticketCategories
  }
`;

/**
 * __useTicketCategoriesQuery__
 *
 * To run a query within a React component, call `useTicketCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<TicketCategoriesQuery, TicketCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketCategoriesQuery, TicketCategoriesQueryVariables>(TicketCategoriesDocument, options);
}
export function useTicketCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketCategoriesQuery, TicketCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketCategoriesQuery, TicketCategoriesQueryVariables>(TicketCategoriesDocument, options);
}
export type TicketCategoriesQueryHookResult = ReturnType<typeof useTicketCategoriesQuery>;
export type TicketCategoriesLazyQueryHookResult = ReturnType<typeof useTicketCategoriesLazyQuery>;
export type TicketCategoriesQueryResult = Apollo.QueryResult<TicketCategoriesQuery, TicketCategoriesQueryVariables>;
export const TicketsDocument = gql`
  query Tickets($ticketable: Ticket__TicketableInput!) {
    tickets(ticketable: $ticketable) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      ticketable: // value for 'ticketable'
 *   },
 * });
 */
export function useTicketsQuery(baseOptions: Apollo.QueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, options);
}
export function useTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, options);
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<TicketsQuery, TicketsQueryVariables>;
export const TrainingUploadAttestationsPaginatedDocument = gql`
  query TrainingUploadAttestationsPaginated($trainingUploadID: ID!, $userQuery: String, $page: Int) {
    paginated: trainingUploadAttestationsPaginated(
      trainingUploadID: $trainingUploadID
      userQuery: $userQuery
      page: $page
    ) {
      results {
        id
        createdAt
        user {
          id
          name
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useTrainingUploadAttestationsPaginatedQuery__
 *
 * To run a query within a React component, call `useTrainingUploadAttestationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingUploadAttestationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingUploadAttestationsPaginatedQuery({
 *   variables: {
 *      trainingUploadID: // value for 'trainingUploadID'
 *      userQuery: // value for 'userQuery'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTrainingUploadAttestationsPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrainingUploadAttestationsPaginatedQuery,
    TrainingUploadAttestationsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrainingUploadAttestationsPaginatedQuery, TrainingUploadAttestationsPaginatedQueryVariables>(
    TrainingUploadAttestationsPaginatedDocument,
    options,
  );
}
export function useTrainingUploadAttestationsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrainingUploadAttestationsPaginatedQuery,
    TrainingUploadAttestationsPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrainingUploadAttestationsPaginatedQuery,
    TrainingUploadAttestationsPaginatedQueryVariables
  >(TrainingUploadAttestationsPaginatedDocument, options);
}
export type TrainingUploadAttestationsPaginatedQueryHookResult = ReturnType<
  typeof useTrainingUploadAttestationsPaginatedQuery
>;
export type TrainingUploadAttestationsPaginatedLazyQueryHookResult = ReturnType<
  typeof useTrainingUploadAttestationsPaginatedLazyQuery
>;
export type TrainingUploadAttestationsPaginatedQueryResult = Apollo.QueryResult<
  TrainingUploadAttestationsPaginatedQuery,
  TrainingUploadAttestationsPaginatedQueryVariables
>;
export const TrainingUploadCreateDocument = gql`
  mutation TrainingUploadCreate($input: TrainingUpload__Create__Input!) {
    result: trainingUploadCreate(input: $input) {
      status
      error
    }
  }
`;
export type TrainingUploadCreateMutationFn = Apollo.MutationFunction<
  TrainingUploadCreateMutation,
  TrainingUploadCreateMutationVariables
>;

/**
 * __useTrainingUploadCreateMutation__
 *
 * To run a mutation, you first call `useTrainingUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingUploadCreateMutation, { data, loading, error }] = useTrainingUploadCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingUploadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<TrainingUploadCreateMutation, TrainingUploadCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrainingUploadCreateMutation, TrainingUploadCreateMutationVariables>(
    TrainingUploadCreateDocument,
    options,
  );
}
export type TrainingUploadCreateMutationHookResult = ReturnType<typeof useTrainingUploadCreateMutation>;
export type TrainingUploadCreateMutationResult = Apollo.MutationResult<TrainingUploadCreateMutation>;
export type TrainingUploadCreateMutationOptions = Apollo.BaseMutationOptions<
  TrainingUploadCreateMutation,
  TrainingUploadCreateMutationVariables
>;
export const TrainingUploadsPaginatedDocument = gql`
  query TrainingUploadsPaginated($page: Int) {
    paginated: trainingUploadsPaginated(page: $page) {
      results {
        id
        createdAt
        deletedAt
        name
        teams {
          id
          name
        }
        uploader {
          id
          name
        }
        url
      }
      pagination {
        ...pagination
      }
    }
    teams {
      id
      name
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useTrainingUploadsPaginatedQuery__
 *
 * To run a query within a React component, call `useTrainingUploadsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingUploadsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingUploadsPaginatedQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTrainingUploadsPaginatedQuery(
  baseOptions?: Apollo.QueryHookOptions<TrainingUploadsPaginatedQuery, TrainingUploadsPaginatedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrainingUploadsPaginatedQuery, TrainingUploadsPaginatedQueryVariables>(
    TrainingUploadsPaginatedDocument,
    options,
  );
}
export function useTrainingUploadsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrainingUploadsPaginatedQuery, TrainingUploadsPaginatedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrainingUploadsPaginatedQuery, TrainingUploadsPaginatedQueryVariables>(
    TrainingUploadsPaginatedDocument,
    options,
  );
}
export type TrainingUploadsPaginatedQueryHookResult = ReturnType<typeof useTrainingUploadsPaginatedQuery>;
export type TrainingUploadsPaginatedLazyQueryHookResult = ReturnType<typeof useTrainingUploadsPaginatedLazyQuery>;
export type TrainingUploadsPaginatedQueryResult = Apollo.QueryResult<
  TrainingUploadsPaginatedQuery,
  TrainingUploadsPaginatedQueryVariables
>;
export const TrainingUploadStatusUpdateDocument = gql`
  mutation TrainingUploadStatusUpdate($input: TrainingUpload__StatusUpdate__Input!) {
    result: trainingUploadStatusUpdate(input: $input) {
      status
      error
    }
  }
`;
export type TrainingUploadStatusUpdateMutationFn = Apollo.MutationFunction<
  TrainingUploadStatusUpdateMutation,
  TrainingUploadStatusUpdateMutationVariables
>;

/**
 * __useTrainingUploadStatusUpdateMutation__
 *
 * To run a mutation, you first call `useTrainingUploadStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingUploadStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingUploadStatusUpdateMutation, { data, loading, error }] = useTrainingUploadStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingUploadStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrainingUploadStatusUpdateMutation,
    TrainingUploadStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrainingUploadStatusUpdateMutation, TrainingUploadStatusUpdateMutationVariables>(
    TrainingUploadStatusUpdateDocument,
    options,
  );
}
export type TrainingUploadStatusUpdateMutationHookResult = ReturnType<typeof useTrainingUploadStatusUpdateMutation>;
export type TrainingUploadStatusUpdateMutationResult = Apollo.MutationResult<TrainingUploadStatusUpdateMutation>;
export type TrainingUploadStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  TrainingUploadStatusUpdateMutation,
  TrainingUploadStatusUpdateMutationVariables
>;
export const UserActivateDocument = gql`
  mutation UserActivate($id: ID!) {
    result: userActivate(id: $id) {
      status
      error
      user {
        id
        active
      }
    }
  }
`;
export type UserActivateMutationFn = Apollo.MutationFunction<UserActivateMutation, UserActivateMutationVariables>;

/**
 * __useUserActivateMutation__
 *
 * To run a mutation, you first call `useUserActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userActivateMutation, { data, loading, error }] = useUserActivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserActivateMutation, UserActivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserActivateMutation, UserActivateMutationVariables>(UserActivateDocument, options);
}
export type UserActivateMutationHookResult = ReturnType<typeof useUserActivateMutation>;
export type UserActivateMutationResult = Apollo.MutationResult<UserActivateMutation>;
export type UserActivateMutationOptions = Apollo.BaseMutationOptions<
  UserActivateMutation,
  UserActivateMutationVariables
>;
export const AvatarDeleteDocument = gql`
  mutation AvatarDelete($userID: ID!) {
    result: avatarDelete(userID: $userID) {
      user {
        ...UserDefaults
      }
    }
  }
  ${UserDefaultsFragmentDoc}
`;
export type AvatarDeleteMutationFn = Apollo.MutationFunction<AvatarDeleteMutation, AvatarDeleteMutationVariables>;

/**
 * __useAvatarDeleteMutation__
 *
 * To run a mutation, you first call `useAvatarDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvatarDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [avatarDeleteMutation, { data, loading, error }] = useAvatarDeleteMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAvatarDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<AvatarDeleteMutation, AvatarDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AvatarDeleteMutation, AvatarDeleteMutationVariables>(AvatarDeleteDocument, options);
}
export type AvatarDeleteMutationHookResult = ReturnType<typeof useAvatarDeleteMutation>;
export type AvatarDeleteMutationResult = Apollo.MutationResult<AvatarDeleteMutation>;
export type AvatarDeleteMutationOptions = Apollo.BaseMutationOptions<
  AvatarDeleteMutation,
  AvatarDeleteMutationVariables
>;
export const AvatarUploadDocument = gql`
  mutation AvatarUpload($userID: ID!, $url: String!) {
    result: avatarUpload(userID: $userID, url: $url) {
      user {
        ...UserDefaults
      }
    }
  }
  ${UserDefaultsFragmentDoc}
`;
export type AvatarUploadMutationFn = Apollo.MutationFunction<AvatarUploadMutation, AvatarUploadMutationVariables>;

/**
 * __useAvatarUploadMutation__
 *
 * To run a mutation, you first call `useAvatarUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvatarUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [avatarUploadMutation, { data, loading, error }] = useAvatarUploadMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAvatarUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<AvatarUploadMutation, AvatarUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AvatarUploadMutation, AvatarUploadMutationVariables>(AvatarUploadDocument, options);
}
export type AvatarUploadMutationHookResult = ReturnType<typeof useAvatarUploadMutation>;
export type AvatarUploadMutationResult = Apollo.MutationResult<AvatarUploadMutation>;
export type AvatarUploadMutationOptions = Apollo.BaseMutationOptions<
  AvatarUploadMutation,
  AvatarUploadMutationVariables
>;
export const UserCreateDocument = gql`
  mutation UserCreate($input: UserInput!) {
    result: userCreate(input: $input) {
      status
      error
      user {
        ...UserDefaults
      }
    }
  }
  ${UserDefaultsFragmentDoc}
`;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const UserDeleteDocument = gql`
  mutation UserDelete($id: ID!) {
    result: userDelete(id: $id) {
      status
      error
      user {
        id
        active
      }
    }
  }
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
}
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UsersDetailsDocument = gql`
  query UsersDetails($id: ID!) {
    user(id: $id) {
      id
      workdayUserID
      active
      name
      email
      personalEmail
      notificationPreference
      externalGroup
      phone
      roles
      state
      signInCount
      image {
        ...image
      }
      promoCode {
        id
        value
      }
      team {
        id
        name
      }
      ownedTeams {
        id
        name
      }
      policy {
        canForceLeaveCallCenter
      }
      allRolesEditableByManager
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useUsersDetailsQuery__
 *
 * To run a query within a React component, call `useUsersDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUsersDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<UsersDetailsQuery, UsersDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersDetailsQuery, UsersDetailsQueryVariables>(UsersDetailsDocument, options);
}
export function useUsersDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersDetailsQuery, UsersDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersDetailsQuery, UsersDetailsQueryVariables>(UsersDetailsDocument, options);
}
export type UsersDetailsQueryHookResult = ReturnType<typeof useUsersDetailsQuery>;
export type UsersDetailsLazyQueryHookResult = ReturnType<typeof useUsersDetailsLazyQuery>;
export type UsersDetailsQueryResult = Apollo.QueryResult<UsersDetailsQuery, UsersDetailsQueryVariables>;
export const UserForceLeaveCallCenterDocument = gql`
  mutation UserForceLeaveCallCenter($id: ID!) {
    result: userForceLeaveCallCenter(id: $id) {
      user {
        id
        state
      }
    }
  }
`;
export type UserForceLeaveCallCenterMutationFn = Apollo.MutationFunction<
  UserForceLeaveCallCenterMutation,
  UserForceLeaveCallCenterMutationVariables
>;

/**
 * __useUserForceLeaveCallCenterMutation__
 *
 * To run a mutation, you first call `useUserForceLeaveCallCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserForceLeaveCallCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userForceLeaveCallCenterMutation, { data, loading, error }] = useUserForceLeaveCallCenterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserForceLeaveCallCenterMutation(
  baseOptions?: Apollo.MutationHookOptions<UserForceLeaveCallCenterMutation, UserForceLeaveCallCenterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserForceLeaveCallCenterMutation, UserForceLeaveCallCenterMutationVariables>(
    UserForceLeaveCallCenterDocument,
    options,
  );
}
export type UserForceLeaveCallCenterMutationHookResult = ReturnType<typeof useUserForceLeaveCallCenterMutation>;
export type UserForceLeaveCallCenterMutationResult = Apollo.MutationResult<UserForceLeaveCallCenterMutation>;
export type UserForceLeaveCallCenterMutationOptions = Apollo.BaseMutationOptions<
  UserForceLeaveCallCenterMutation,
  UserForceLeaveCallCenterMutationVariables
>;
export const UserCreateFormDocument = gql`
  query UserCreateForm {
    ...UserForm
  }
  ${UserFormFragmentDoc}
`;

/**
 * __useUserCreateFormQuery__
 *
 * To run a query within a React component, call `useUserCreateFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreateFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreateFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreateFormQuery(
  baseOptions?: Apollo.QueryHookOptions<UserCreateFormQuery, UserCreateFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCreateFormQuery, UserCreateFormQueryVariables>(UserCreateFormDocument, options);
}
export function useUserCreateFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCreateFormQuery, UserCreateFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCreateFormQuery, UserCreateFormQueryVariables>(UserCreateFormDocument, options);
}
export type UserCreateFormQueryHookResult = ReturnType<typeof useUserCreateFormQuery>;
export type UserCreateFormLazyQueryHookResult = ReturnType<typeof useUserCreateFormLazyQuery>;
export type UserCreateFormQueryResult = Apollo.QueryResult<UserCreateFormQuery, UserCreateFormQueryVariables>;
export const UserUpdateFormDocument = gql`
  query UserUpdateForm($id: ID!) {
    ...UserForm
    user(id: $id) {
      ...UserDefaults
    }
  }
  ${UserFormFragmentDoc}
  ${UserDefaultsFragmentDoc}
`;

/**
 * __useUserUpdateFormQuery__
 *
 * To run a query within a React component, call `useUserUpdateFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpdateFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserUpdateFormQuery(
  baseOptions: Apollo.QueryHookOptions<UserUpdateFormQuery, UserUpdateFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserUpdateFormQuery, UserUpdateFormQueryVariables>(UserUpdateFormDocument, options);
}
export function useUserUpdateFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserUpdateFormQuery, UserUpdateFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserUpdateFormQuery, UserUpdateFormQueryVariables>(UserUpdateFormDocument, options);
}
export type UserUpdateFormQueryHookResult = ReturnType<typeof useUserUpdateFormQuery>;
export type UserUpdateFormLazyQueryHookResult = ReturnType<typeof useUserUpdateFormLazyQuery>;
export type UserUpdateFormQueryResult = Apollo.QueryResult<UserUpdateFormQuery, UserUpdateFormQueryVariables>;
export const UsersListDocument = gql`
  query UsersList($filters: UserFiltersInput!, $page: Int!) {
    paginated: paginatedUsers(filters: $filters, page: $page) {
      results {
        id
        name
        email
        phone
        roles
        state
        active
        signInCount
        allRolesEditableByManager
      }
      pagination {
        ...pagination
      }
    }
  }
  ${PaginationFragmentDoc}
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;
export const UsersSelectorDocument = gql`
  query UsersSelector($filters: UserFiltersInput) {
    users(filters: $filters) {
      id
      name
    }
  }
`;

/**
 * __useUsersSelectorQuery__
 *
 * To run a query within a React component, call `useUsersSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSelectorQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersSelectorQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersSelectorQuery, UsersSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersSelectorQuery, UsersSelectorQueryVariables>(UsersSelectorDocument, options);
}
export function useUsersSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersSelectorQuery, UsersSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersSelectorQuery, UsersSelectorQueryVariables>(UsersSelectorDocument, options);
}
export type UsersSelectorQueryHookResult = ReturnType<typeof useUsersSelectorQuery>;
export type UsersSelectorLazyQueryHookResult = ReturnType<typeof useUsersSelectorLazyQuery>;
export type UsersSelectorQueryResult = Apollo.QueryResult<UsersSelectorQuery, UsersSelectorQueryVariables>;
export const UserUpdateDocument = gql`
  mutation UserUpdate($id: ID!, $input: UserInput!) {
    result: userUpdate(id: $id, input: $input) {
      status
      error
      user {
        ...UserDefaults
      }
    }
  }
  ${UserDefaultsFragmentDoc}
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const VehicleTypeDocument = gql`
  query VehicleType($id: ID!) {
    vehicleType(id: $id) {
      id
      name
      cuft
      numSeats
      weightInPounds
      fieldDispatchable
      restrictedZipCodes
    }
  }
`;

/**
 * __useVehicleTypeQuery__
 *
 * To run a query within a React component, call `useVehicleTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleTypeQuery(baseOptions: Apollo.QueryHookOptions<VehicleTypeQuery, VehicleTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleTypeQuery, VehicleTypeQueryVariables>(VehicleTypeDocument, options);
}
export function useVehicleTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VehicleTypeQuery, VehicleTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleTypeQuery, VehicleTypeQueryVariables>(VehicleTypeDocument, options);
}
export type VehicleTypeQueryHookResult = ReturnType<typeof useVehicleTypeQuery>;
export type VehicleTypeLazyQueryHookResult = ReturnType<typeof useVehicleTypeLazyQuery>;
export type VehicleTypeQueryResult = Apollo.QueryResult<VehicleTypeQuery, VehicleTypeQueryVariables>;
export const VehicleTypeCreateDocument = gql`
  mutation VehicleTypeCreate($input: VehicleTypeInput!) {
    vehicleTypeCreate(input: $input) {
      status
      error
    }
  }
`;
export type VehicleTypeCreateMutationFn = Apollo.MutationFunction<
  VehicleTypeCreateMutation,
  VehicleTypeCreateMutationVariables
>;

/**
 * __useVehicleTypeCreateMutation__
 *
 * To run a mutation, you first call `useVehicleTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleTypeCreateMutation, { data, loading, error }] = useVehicleTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVehicleTypeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<VehicleTypeCreateMutation, VehicleTypeCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VehicleTypeCreateMutation, VehicleTypeCreateMutationVariables>(
    VehicleTypeCreateDocument,
    options,
  );
}
export type VehicleTypeCreateMutationHookResult = ReturnType<typeof useVehicleTypeCreateMutation>;
export type VehicleTypeCreateMutationResult = Apollo.MutationResult<VehicleTypeCreateMutation>;
export type VehicleTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  VehicleTypeCreateMutation,
  VehicleTypeCreateMutationVariables
>;
export const VehicleTypeUpdateDocument = gql`
  mutation VehicleTypeUpdate($input: VehicleTypeInput!, $id: ID!) {
    vehicleTypeUpdate(input: $input, id: $id) {
      status
      error
    }
  }
`;
export type VehicleTypeUpdateMutationFn = Apollo.MutationFunction<
  VehicleTypeUpdateMutation,
  VehicleTypeUpdateMutationVariables
>;

/**
 * __useVehicleTypeUpdateMutation__
 *
 * To run a mutation, you first call `useVehicleTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleTypeUpdateMutation, { data, loading, error }] = useVehicleTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleTypeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<VehicleTypeUpdateMutation, VehicleTypeUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VehicleTypeUpdateMutation, VehicleTypeUpdateMutationVariables>(
    VehicleTypeUpdateDocument,
    options,
  );
}
export type VehicleTypeUpdateMutationHookResult = ReturnType<typeof useVehicleTypeUpdateMutation>;
export type VehicleTypeUpdateMutationResult = Apollo.MutationResult<VehicleTypeUpdateMutation>;
export type VehicleTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  VehicleTypeUpdateMutation,
  VehicleTypeUpdateMutationVariables
>;
export const VehicleTypesDocument = gql`
  query VehicleTypes {
    vehicleTypes {
      id
      name
      cuft
      numSeats
      weightInPounds
      fieldDispatchable
      restrictedZipCodes
    }
  }
`;

/**
 * __useVehicleTypesQuery__
 *
 * To run a query within a React component, call `useVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<VehicleTypesQuery, VehicleTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(VehicleTypesDocument, options);
}
export function useVehicleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VehicleTypesQuery, VehicleTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(VehicleTypesDocument, options);
}
export type VehicleTypesQueryHookResult = ReturnType<typeof useVehicleTypesQuery>;
export type VehicleTypesLazyQueryHookResult = ReturnType<typeof useVehicleTypesLazyQuery>;
export type VehicleTypesQueryResult = Apollo.QueryResult<VehicleTypesQuery, VehicleTypesQueryVariables>;
export const WarehouseAddressesDocument = gql`
  query WarehouseAddresses {
    warehouses: warehouses {
      id
      name
      address {
        id
        street
        aptsuite
        city
        state
        zip
        latitude
        longitude
        schedulable
        region {
          id
          name
          tz
        }
      }
    }
  }
`;

/**
 * __useWarehouseAddressesQuery__
 *
 * To run a query within a React component, call `useWarehouseAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWarehouseAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<WarehouseAddressesQuery, WarehouseAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WarehouseAddressesQuery, WarehouseAddressesQueryVariables>(
    WarehouseAddressesDocument,
    options,
  );
}
export function useWarehouseAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WarehouseAddressesQuery, WarehouseAddressesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WarehouseAddressesQuery, WarehouseAddressesQueryVariables>(
    WarehouseAddressesDocument,
    options,
  );
}
export type WarehouseAddressesQueryHookResult = ReturnType<typeof useWarehouseAddressesQuery>;
export type WarehouseAddressesLazyQueryHookResult = ReturnType<typeof useWarehouseAddressesLazyQuery>;
export type WarehouseAddressesQueryResult = Apollo.QueryResult<
  WarehouseAddressesQuery,
  WarehouseAddressesQueryVariables
>;
export const BuildWarehouseDocument = gql`
  mutation BuildWarehouse($input: WarehouseInput!) {
    buildWarehouse(input: $input) {
      error
    }
  }
`;
export type BuildWarehouseMutationFn = Apollo.MutationFunction<BuildWarehouseMutation, BuildWarehouseMutationVariables>;

/**
 * __useBuildWarehouseMutation__
 *
 * To run a mutation, you first call `useBuildWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildWarehouseMutation, { data, loading, error }] = useBuildWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildWarehouseMutation, BuildWarehouseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildWarehouseMutation, BuildWarehouseMutationVariables>(BuildWarehouseDocument, options);
}
export type BuildWarehouseMutationHookResult = ReturnType<typeof useBuildWarehouseMutation>;
export type BuildWarehouseMutationResult = Apollo.MutationResult<BuildWarehouseMutation>;
export type BuildWarehouseMutationOptions = Apollo.BaseMutationOptions<
  BuildWarehouseMutation,
  BuildWarehouseMutationVariables
>;
export const ModifyWarehouseDocument = gql`
  mutation ModifyWarehouse($input: WarehouseInput!, $id: ID!) {
    modifyWarehouse(input: $input, id: $id) {
      error
      warehouse {
        ...warehouse
      }
    }
  }
  ${WarehouseFragmentDoc}
`;
export type ModifyWarehouseMutationFn = Apollo.MutationFunction<
  ModifyWarehouseMutation,
  ModifyWarehouseMutationVariables
>;

/**
 * __useModifyWarehouseMutation__
 *
 * To run a mutation, you first call `useModifyWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyWarehouseMutation, { data, loading, error }] = useModifyWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModifyWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyWarehouseMutation, ModifyWarehouseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyWarehouseMutation, ModifyWarehouseMutationVariables>(
    ModifyWarehouseDocument,
    options,
  );
}
export type ModifyWarehouseMutationHookResult = ReturnType<typeof useModifyWarehouseMutation>;
export type ModifyWarehouseMutationResult = Apollo.MutationResult<ModifyWarehouseMutation>;
export type ModifyWarehouseMutationOptions = Apollo.BaseMutationOptions<
  ModifyWarehouseMutation,
  ModifyWarehouseMutationVariables
>;
export const WarehousesSelectorDocument = gql`
  query WarehousesSelector($filters: FacilityFiltersInput) {
    warehouses(filters: $filters) {
      id
      name
    }
  }
`;

/**
 * __useWarehousesSelectorQuery__
 *
 * To run a query within a React component, call `useWarehousesSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesSelectorQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWarehousesSelectorQuery(
  baseOptions?: Apollo.QueryHookOptions<WarehousesSelectorQuery, WarehousesSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WarehousesSelectorQuery, WarehousesSelectorQueryVariables>(
    WarehousesSelectorDocument,
    options,
  );
}
export function useWarehousesSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WarehousesSelectorQuery, WarehousesSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WarehousesSelectorQuery, WarehousesSelectorQueryVariables>(
    WarehousesSelectorDocument,
    options,
  );
}
export type WarehousesSelectorQueryHookResult = ReturnType<typeof useWarehousesSelectorQuery>;
export type WarehousesSelectorLazyQueryHookResult = ReturnType<typeof useWarehousesSelectorLazyQuery>;
export type WarehousesSelectorQueryResult = Apollo.QueryResult<
  WarehousesSelectorQuery,
  WarehousesSelectorQueryVariables
>;
export const WarehouseDocument = gql`
  query Warehouse($id: ID!) {
    warehouse(id: $id) {
      ...warehouse
    }
  }
  ${WarehouseFragmentDoc}
`;

/**
 * __useWarehouseQuery__
 *
 * To run a query within a React component, call `useWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarehouseQuery(baseOptions: Apollo.QueryHookOptions<WarehouseQuery, WarehouseQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WarehouseQuery, WarehouseQueryVariables>(WarehouseDocument, options);
}
export function useWarehouseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WarehouseQuery, WarehouseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WarehouseQuery, WarehouseQueryVariables>(WarehouseDocument, options);
}
export type WarehouseQueryHookResult = ReturnType<typeof useWarehouseQuery>;
export type WarehouseLazyQueryHookResult = ReturnType<typeof useWarehouseLazyQuery>;
export type WarehouseQueryResult = Apollo.QueryResult<WarehouseQuery, WarehouseQueryVariables>;
export const ZendeskUserBulkMergeDocument = gql`
  mutation ZendeskUserBulkMerge($signedID: ID!) {
    result: zendeskUserBulkMerge(signedID: $signedID) {
      status
      error
    }
  }
`;
export type ZendeskUserBulkMergeMutationFn = Apollo.MutationFunction<
  ZendeskUserBulkMergeMutation,
  ZendeskUserBulkMergeMutationVariables
>;

/**
 * __useZendeskUserBulkMergeMutation__
 *
 * To run a mutation, you first call `useZendeskUserBulkMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZendeskUserBulkMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zendeskUserBulkMergeMutation, { data, loading, error }] = useZendeskUserBulkMergeMutation({
 *   variables: {
 *      signedID: // value for 'signedID'
 *   },
 * });
 */
export function useZendeskUserBulkMergeMutation(
  baseOptions?: Apollo.MutationHookOptions<ZendeskUserBulkMergeMutation, ZendeskUserBulkMergeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ZendeskUserBulkMergeMutation, ZendeskUserBulkMergeMutationVariables>(
    ZendeskUserBulkMergeDocument,
    options,
  );
}
export type ZendeskUserBulkMergeMutationHookResult = ReturnType<typeof useZendeskUserBulkMergeMutation>;
export type ZendeskUserBulkMergeMutationResult = Apollo.MutationResult<ZendeskUserBulkMergeMutation>;
export type ZendeskUserBulkMergeMutationOptions = Apollo.BaseMutationOptions<
  ZendeskUserBulkMergeMutation,
  ZendeskUserBulkMergeMutationVariables
>;
export const ValidEmailDocument = gql`
  query ValidEmail($email: String!) {
    valid: validEmail(email: $email)
  }
`;

/**
 * __useValidEmailQuery__
 *
 * To run a query within a React component, call `useValidEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useValidEmailQuery(baseOptions: Apollo.QueryHookOptions<ValidEmailQuery, ValidEmailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidEmailQuery, ValidEmailQueryVariables>(ValidEmailDocument, options);
}
export function useValidEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidEmailQuery, ValidEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidEmailQuery, ValidEmailQueryVariables>(ValidEmailDocument, options);
}
export type ValidEmailQueryHookResult = ReturnType<typeof useValidEmailQuery>;
export type ValidEmailLazyQueryHookResult = ReturnType<typeof useValidEmailLazyQuery>;
export type ValidEmailQueryResult = Apollo.QueryResult<ValidEmailQuery, ValidEmailQueryVariables>;
export const ValidPhoneDocument = gql`
  query ValidPhone($phone: String!) {
    valid: validPhone(phone: $phone)
  }
`;

/**
 * __useValidPhoneQuery__
 *
 * To run a query within a React component, call `useValidPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidPhoneQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useValidPhoneQuery(baseOptions: Apollo.QueryHookOptions<ValidPhoneQuery, ValidPhoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidPhoneQuery, ValidPhoneQueryVariables>(ValidPhoneDocument, options);
}
export function useValidPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidPhoneQuery, ValidPhoneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidPhoneQuery, ValidPhoneQueryVariables>(ValidPhoneDocument, options);
}
export type ValidPhoneQueryHookResult = ReturnType<typeof useValidPhoneQuery>;
export type ValidPhoneLazyQueryHookResult = ReturnType<typeof useValidPhoneLazyQuery>;
export type ValidPhoneQueryResult = Apollo.QueryResult<ValidPhoneQuery, ValidPhoneQueryVariables>;
export const AvailabilitiesDocument = gql`
  query Availabilities($accountID: ID, $input: AvailabilitiesInput!) {
    availabilities(accountID: $accountID, input: $input) {
      ...availability
      appointmentFee
    }
  }
  ${AvailabilityFragmentDoc}
`;

/**
 * __useAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
}
export function useAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
}
export type AvailabilitiesQueryHookResult = ReturnType<typeof useAvailabilitiesQuery>;
export type AvailabilitiesLazyQueryHookResult = ReturnType<typeof useAvailabilitiesLazyQuery>;
export type AvailabilitiesQueryResult = Apollo.QueryResult<AvailabilitiesQuery, AvailabilitiesQueryVariables>;
export const PickupMoverCountDocument = gql`
  query PickupMoverCount($orderID: ID!, $inventory: PickupInventoryInput!) {
    pickupMoverCount(orderID: $orderID, inventory: $inventory)
  }
`;

/**
 * __usePickupMoverCountQuery__
 *
 * To run a query within a React component, call `usePickupMoverCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupMoverCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupMoverCountQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      inventory: // value for 'inventory'
 *   },
 * });
 */
export function usePickupMoverCountQuery(
  baseOptions: Apollo.QueryHookOptions<PickupMoverCountQuery, PickupMoverCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PickupMoverCountQuery, PickupMoverCountQueryVariables>(PickupMoverCountDocument, options);
}
export function usePickupMoverCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PickupMoverCountQuery, PickupMoverCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PickupMoverCountQuery, PickupMoverCountQueryVariables>(PickupMoverCountDocument, options);
}
export type PickupMoverCountQueryHookResult = ReturnType<typeof usePickupMoverCountQuery>;
export type PickupMoverCountLazyQueryHookResult = ReturnType<typeof usePickupMoverCountLazyQuery>;
export type PickupMoverCountQueryResult = Apollo.QueryResult<PickupMoverCountQuery, PickupMoverCountQueryVariables>;
export const PollForInvoiceEventDocument = gql`
  query PollForInvoiceEvent($accountID: ID!, $attemptID: ID!) {
    billing(accountID: $accountID) {
      invoiceEvent(attemptID: $attemptID) {
        completed
        id
      }
    }
  }
`;

/**
 * __usePollForInvoiceEventQuery__
 *
 * To run a query within a React component, call `usePollForInvoiceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollForInvoiceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollForInvoiceEventQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      attemptID: // value for 'attemptID'
 *   },
 * });
 */
export function usePollForInvoiceEventQuery(
  baseOptions: Apollo.QueryHookOptions<PollForInvoiceEventQuery, PollForInvoiceEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PollForInvoiceEventQuery, PollForInvoiceEventQueryVariables>(
    PollForInvoiceEventDocument,
    options,
  );
}
export function usePollForInvoiceEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PollForInvoiceEventQuery, PollForInvoiceEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PollForInvoiceEventQuery, PollForInvoiceEventQueryVariables>(
    PollForInvoiceEventDocument,
    options,
  );
}
export type PollForInvoiceEventQueryHookResult = ReturnType<typeof usePollForInvoiceEventQuery>;
export type PollForInvoiceEventLazyQueryHookResult = ReturnType<typeof usePollForInvoiceEventLazyQuery>;
export type PollForInvoiceEventQueryResult = Apollo.QueryResult<
  PollForInvoiceEventQuery,
  PollForInvoiceEventQueryVariables
>;
export const PricingPerItemFeeDocument = gql`
  query PricingPerItemFee($input: Pricing__PerItemFeeInput!) {
    pricingPerItemFee(input: $input) {
      ...perItemFee
    }
  }
  ${PerItemFeeFragmentDoc}
`;

/**
 * __usePricingPerItemFeeQuery__
 *
 * To run a query within a React component, call `usePricingPerItemFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPerItemFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPerItemFeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePricingPerItemFeeQuery(
  baseOptions: Apollo.QueryHookOptions<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>(PricingPerItemFeeDocument, options);
}
export function usePricingPerItemFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>(
    PricingPerItemFeeDocument,
    options,
  );
}
export type PricingPerItemFeeQueryHookResult = ReturnType<typeof usePricingPerItemFeeQuery>;
export type PricingPerItemFeeLazyQueryHookResult = ReturnType<typeof usePricingPerItemFeeLazyQuery>;
export type PricingPerItemFeeQueryResult = Apollo.QueryResult<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>;
